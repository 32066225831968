import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import PlatformConditional from '../../../components/PlatformConditional';

import Form from './Form';
import { docType, filterDocs, TDocument } from '../utils';

import FVCHistory from './HistoryRowData/FVC';
import NocturnalPolygraphyHistory from './HistoryRowData/NocturnalPolygraphy';
import ENMGHistory from './HistoryRowData/ENMG';
import VEPHistory from './HistoryRowData/VEP';
import SEPHistory from './HistoryRowData/SEP';
import BAEPHistory from './HistoryRowData/BAEP';
import OCTSCANHistory from './HistoryRowData/OCTSCAN';
import ECGHistory from './HistoryRowData/ECG';
import EchocardiographyHistory from './HistoryRowData/Echocardiography';
import BloodPressureHistory from './HistoryRowData/BloodPressure';
import HeartRateHistory from './HistoryRowData/HeartRate';

const ClinicalStudyHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
): React.JSX.Element => {
  const FVCDocs = filterDocs('fvc', documents);
  const ENMGDocs = filterDocs('enmg', documents);
  const VEPDocs = filterDocs('vep', documents);
  const SEPDocs = filterDocs('sep', documents);
  const BAEPDocs = filterDocs('baep', documents);
  const OCTSCANDocs = filterDocs('octscan', documents);
  const ECGDocs = filterDocs('ecg', documents);
  const EchocardiographyDocs = filterDocs('echocardiography', documents);
  const BloodPressureDocs = filterDocs('bloodPressure', documents);
  const HeartRateDocs = filterDocs('heartRate', documents);
  const NocturnalPolygraphyDocs = filterDocs('nocturnalPolygraphy', documents);

  return (
    <React.Fragment>
      <PlatformConditional platform={'ms'}>
        <React.Fragment>
          <VEPHistory documents={VEPDocs as IVEP[]} startEdit={startEdit} />
          <SEPHistory documents={SEPDocs as ISEP[]} startEdit={startEdit} />
          <BAEPHistory documents={BAEPDocs as IBAEP[]} startEdit={startEdit} />
          <OCTSCANHistory documents={OCTSCANDocs as IOCTSCAN[]} startEdit={startEdit} />
          <ECGHistory documents={ECGDocs as IECG[]} startEdit={startEdit} />
          <EchocardiographyHistory documents={EchocardiographyDocs as IEchocardiography[]} startEdit={startEdit} />
          <BloodPressureHistory documents={BloodPressureDocs as IBloodPressure[]} startEdit={startEdit} />
          <HeartRateHistory documents={HeartRateDocs as IHeartRate[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform={['sma', 'dmd']}>
        <React.Fragment>
          <FVCHistory documents={FVCDocs as IFVC[]} startEdit={startEdit} />
          <ENMGHistory documents={ENMGDocs as IENMG[]} startEdit={startEdit} />
          <NocturnalPolygraphyHistory
            documents={NocturnalPolygraphyDocs as INocturnalPolygraphy[]}
            startEdit={startEdit}
          />
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

const ClinicalStudy = ({ documents }: IOwnProps): React.JSX.Element => {
  return (
    <FormEditingHandler name="clinicalstudy" documents={documents}>
      {(editing, startEdit, formData, view): React.JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'clinicalstudy'}
            headerId={
              docType(documents, editing) === 'fvc'
                ? 'clinicalstudy.fvc'
                : docType(documents, editing) === 'nocturnalPolygraphy'
                  ? 'clinicalstudy.nocturnalPolygraphy'
                  : docType(documents, editing) === 'enmg'
                    ? 'clinicalstudy.enmg'
                    : docType(documents, editing) === 'vep'
                      ? 'clinicalstudy.vep'
                      : docType(documents, editing) === 'sep'
                        ? 'clinicalstudy.sep'
                        : docType(documents, editing) === 'baep'
                          ? 'clinicalstudy.baep'
                          : docType(documents, editing) === 'octscan'
                            ? 'clinicalstudy.octscan'
                            : docType(documents, editing) === 'ecg'
                              ? 'clinicalstudy.ecg'
                              : docType(documents, editing) === 'echocardiography'
                                ? 'clinicalstudy.echocardiography'
                                : docType(documents, editing) === 'bloodPressure'
                                  ? 'clinicalstudy.bloodPressure'
                                  : docType(documents, editing) === 'heartRate'
                                    ? 'clinicalstudy.heartRate'
                                    : 'clinicalstudy.title'
            }
            editing={editing}
            editButtons={<div />}
          />
          {editing ? (
            <Form formData={formData} document={documents && documents.find((d: TDocument) => d._id === editing)} />
          ) : null}
          {!editing && !view?.viewing ? ClinicalStudyHistory(documents, startEdit) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default ClinicalStudy;
