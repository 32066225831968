import * as React from 'react';
import { useIntl } from 'react-intl';

import FormRow from '../../../../components/FormRow';
import InputHandler from '../../../../components/InputHandler';
import { examinationsList, localizeCodenames } from 'Routes/Examination/utils';

const opts = examinationsList.map((exa) => exa.codeName);

const ExaminationForm = ({ formData, view }: IOwnProps): React.JSX.Element => {
  const { document, onChange } = formData;

  const onChangeCodename = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (onChange) onChange({ codeName: value, code: null });
  };

  React.useEffect(() => {
    // Roundabout way of doing this, but the code textfield wont update otherwise
    const code = examinationsList.find((ex) => ex.codeName === formData.document.codeName)?.code;
    if (onChange && !formData.document.code && code) onChange({ code: code });
  }, [formData.document.codeName]);

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="examination.codeName">
        <>
          {!view?.viewing ? (
            <InputHandler
              type="AutoCompleteSelect"
              editing={!view?.viewing}
              name="codeName"
              formData={{
                onChange: onChangeCodename,
                document: document,
              }}
              options={opts}
              getOptionLabel={(o: string): string => (o ? localizeCodenames(o, fm) : '')}
              width={23}
              placeholder={'examination.codeName'}
              groupID="group"
            />
          ) : (
            <div style={{ fontWeight: 600 }}>{document.codeName && localizeCodenames(document.codeName, fm)}</div>
          )}
        </>
      </FormRow>
      <FormRow title="examination.code">
        <InputHandler
          type="TextField"
          editing={!view?.viewing}
          name="code"
          formData={{
            document: { code: formData.document.code },
            onChange: onChange,
          }}
          placeholder={'examination.code'}
        />
      </FormRow>
      <FormRow title="examination.value">
        <InputHandler
          type="TextField"
          editing={!view?.viewing}
          name="value"
          formData={formData}
          placeholder={'examination.value'}
        />
      </FormRow>
      <FormRow title="examination.units">
        <InputHandler
          type="TextField"
          editing={!view?.viewing}
          name="units"
          formData={formData}
          placeholder={'examination.units'}
        />
      </FormRow>
      <FormRow title="examination.valueReferenceRange">
        <InputHandler
          type="TextField"
          editing={!view?.viewing}
          name="valueReferenceRange"
          formData={formData}
          placeholder={'examination.valueReferenceRange'}
        />
      </FormRow>
      <FormRow title="examination.abnormalFlags">
        <InputHandler
          type="TextField"
          editing={!view?.viewing}
          name="abnormalFlags"
          formData={formData}
          placeholder={'examination.abnormalFlags'}
        />
      </FormRow>
      <FormRow title="examination.comments">
        <InputHandler
          type="TextField"
          editing={!view?.viewing}
          name="comments"
          formData={formData}
          placeholder={'examination.comments'}
        />
      </FormRow>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IExamination>;
  view?: IView;
}

export default ExaminationForm;
