import * as React from 'react';
import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';
import { fm } from 'Components/FormatMessage';
import colors from '../../../../../config/theme/colors';
import Unit from 'Components/Unit';
import { defaultRTMSTreatmentOperators } from '../../config';
import { subjectOfTreatmentNames } from 'Routes/DoctorsOrders/Document/config';
import {
  convertTreatmentEfficiencyToRatingFormat,
  cTBSFields,
  getRtmsRowData,
  getSessionNumber,
  iTBSFields,
  originalRTMSFields,
  valueDiffersFromDoctorsOrders,
} from 'Utility/ninmtUtil';
import { equals } from 'ramda';
import ToolTip from 'Components/ToolTip';
import { capitalize } from 'Utility/string';
import { partialDateToValue } from 'neuro-utils';
import { ITreatmentEfficiency } from 'neuro-schemas';
import { RTMSContext } from '../..';
import { path } from 'Utility/ramdaReplacement';

const ValueDiv = styled('div')({
  display: 'inline-flex',
  fontWeight: 600,
});

const OperatorDiv = styled('div')({
  display: 'inline-flex',
  margin: '0rem 1rem 0rem 1rem',
});

const TitleContainer = styled(Container)({
  color: colors.darkGray,
  fontWeight: 'bold',
});

const NameContainer = styled(Container)({
  color: colors.tertiaryText,
  padding: '0 0 5px 0',
});

const ValueItem = styled(Item)((props: { index: number }) => ({
  padding: '0.4rem 0 0.4rem 0',
  borderTop: props.index === 0 ? `1px solid ${colors.gray}` : undefined,
  backgroundColor: props.index % 2 === 0 ? colors.lightestGray : undefined,
}));

const TitleItem = styled(ValueItem)({
  color: colors.tertiaryText,
});

const nameSelector = (t: IRTMSTreatment): React.JSX.Element => {
  const name = t.name
    ? subjectOfTreatmentNames.includes(t.name)
      ? fm(`rtms.subjectOfTreatmentNames.${t.name}`)
      : t.name
    : '-';
  const specifier = t.specifier ? ` ${t.specifier}` : '';
  return (
    <span>
      {name}
      {specifier}
    </span>
  );
};

const valueFormatter = (name: string, value?: any, currentSession?: IRTMSSession, currentIndex?: number) => {
  const additionalStyleDefault =
    name !== 'electricFieldStrength' && valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value)
      ? { color: colors.white, backgroundColor: colors.appBlue.default, padding: '0 2px 0 2px', borderRadius: '0.5rem' }
      : undefined;
  switch (name) {
    case 'stimulationType':
      return <ValueDiv>{value ? fm(`rtms.opts.stimulationType.${value}`) : '-'}</ValueDiv>;
    case 'pulseIntensity':
    case 'numberOfPulses':
    case 'electricFieldStrength': {
      return (
        <Unit unit={name !== 'numberOfPulses' ? fm(`rtms.units.${name}`) : ''}>
          <React.Fragment>
            {[null, null].concat(name === 'numberOfPulses' ? [null] : []).map((_, i, arr) => {
              const additionalStyleOther =
                name !== 'electricFieldStrength' &&
                valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i)
                  ? {
                      color: colors.white,
                      backgroundColor: colors.appBlue.default,
                      padding: '0 2px 0 2px',
                      borderRadius: '0.5rem',
                    }
                  : undefined;
              return (
                <React.Fragment key={i}>
                  <ToolTip
                    title={fm('rtms.subjectOfTreatment.valueInDoctorsOrders')}
                    cursor={
                      valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i)
                        ? 'Pointer'
                        : 'Default'
                    }
                    description={
                      <>
                        {valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i, true)
                          ? valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i, true)
                          : '-'}
                      </>
                    }
                    flipTitleAndDescStyle
                    hover={true}
                    disabled={!valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i)}
                    content={
                      <ValueDiv style={additionalStyleOther}>{Array.isArray(value) ? value[i] ?? '-' : '-'}</ValueDiv>
                    }
                  ></ToolTip>
                  {i < arr.length - 1 && <OperatorDiv>{defaultRTMSTreatmentOperators[name]}</OperatorDiv>}
                </React.Fragment>
              );
            })}
            {name === 'numberOfPulses' && (
              <React.Fragment>
                <OperatorDiv>{'='}</OperatorDiv>
                <ValueDiv style={{ fontWeight: 400 }}>
                  {Array.isArray(value)
                    ? value.reduce((p: number, c: number) => (p ?? 0) + (!!c || c === 0 ? c : 0), 0)
                    : ''}
                </ValueDiv>
              </React.Fragment>
            )}
          </React.Fragment>
        </Unit>
      );
    }
    case 'additionalInformation': {
      return <ValueDiv>{value || '-'}</ValueDiv>;
    }
    default: {
      return (
        <Unit unit={fm(`rtms.units.${name}`)}>
          <ToolTip
            title={fm('rtms.subjectOfTreatment.valueInDoctorsOrders')}
            cursor={
              valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value) ? 'Pointer' : 'Default'
            }
            description={
              <>
                {valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value, undefined, true)
                  ? valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value, undefined, true)
                  : '-'}
              </>
            }
            flipTitleAndDescStyle
            hover={true}
            disabled={!valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value)}
            content={<ValueDiv style={additionalStyleDefault}>{value ?? '-'}</ValueDiv>}
          ></ToolTip>
        </Unit>
      );
    }
  }
};

const deleteReasonFormatter = (t: IRTMSTreatment): string | React.JSX.Element =>
  t.deleteReason ? (
    <React.Fragment>
      <div
        style={{
          fontWeight: 600,
          color: t.deleteReason === 'partOfTreatmentProtocol' ? colors.tertiaryText : colors.error.default,
        }}
      >
        {fm(
          `rtms.subjectOfTreatment.${
            t.deleteReason === 'partOfTreatmentProtocol' ? 'accordingToProtocol' : 'contraryToProtocol'
          }`,
        )}
      </div>
      <div style={{ color: colors.tertiaryText, textTransform: 'lowercase' }}>
        {fm('rtms.subjectOfTreatment.noTreatmentGiven')}
      </div>
    </React.Fragment>
  ) : (
    ''
  );

const TreatmentsBySession = ({ event, eventsBeyondThisDoc }: ITreatmentsBySession): React.JSX.Element => {
  const rtmsContext = React.useContext(RTMSContext);
  const { sortedAndMergedMyDocuments } = rtmsContext;

  const treatmentEfficiencyDocs = sortedAndMergedMyDocuments.filter(
    (d) => partialDateToValue(d.date) === partialDateToValue(event.date) && d._type === 'treatmentEfficiency',
  ) as ITreatmentEfficiency[];

  const before = treatmentEfficiencyDocs.find((d) => d.timing === 'before');
  const after = treatmentEfficiencyDocs.find((d) => d.timing === 'after');

  const treatmentEfficiencyRating =
    before && convertTreatmentEfficiencyToRatingFormat(before && after ? [before, after] : before);

  if (!event || !Array.isArray(event.subjectOfTreatment) || event.subjectOfTreatment?.length === 0) return <></>;

  const patientsRating = event.patientsRating ? event.patientsRating : treatmentEfficiencyRating;
  const patientsRatingPeriods = ['beforeTreatment', 'afterTreatment'];
  const patientsRatingNames = ['intensity', 'harm'];

  const treatments = event.subjectOfTreatment;
  const currentIndex = eventsBeyondThisDoc.findIndex((e) => equals(e, event));

  let fields = [];
  if (treatments?.some((t) => t.stimulationType === 'iTBS')) fields = [...iTBSFields];
  else if (treatments?.some((t) => t.stimulationType === 'cTBS')) fields = [...cTBSFields];
  else fields = [...originalRTMSFields];

  if (treatments?.some((t) => t.stimulationType === 'originalRTMS') && !fields.includes('pulseFrequency')) {
    fields.splice(fields.indexOf('pulseFrequency3PulseBurst'), 0, 'pulseFrequency');
  }

  return (
    <React.Fragment>
      {Array.isArray(patientsRating) && patientsRating.length > 0 && (
        <div style={{ margin: '4rem 0 4.5rem 0' }}>
          <TitleContainer style={{ marginBottom: '1.5rem' }}>
            <Item xs={true}>{fm('rtms.patientsRating.title')}</Item>
          </TitleContainer>
          {patientsRating?.map(
            (p, index) =>
              Array.isArray(p?.ratingByLocation) &&
              p.ratingByLocation.map((r, ind) => (
                <React.Fragment key={`${r.location}${ind}`}>
                  <NameContainer>
                    <Item xs={3} style={{ minHeight: 30 }}>
                      {p.symptom === 'other' ? (
                        <div style={{ paddingRight: '1.5rem' }}>
                          {fm('rtms.patientsRating.opts.other')}
                          {r.description && r.description.length > 0 && (
                            <ToolTip
                              cursor={r.description.length > 30 ? 'Pointer' : 'Default'}
                              description={r.description}
                              hover={true}
                              disabled={r.description.length <= 30}
                              content={
                                <span>
                                  {r.description.length > 30
                                    ? `: ${r.description.slice(0, 30)}...`
                                    : `: ${r.description}`}
                                </span>
                              }
                            />
                          )}
                        </div>
                      ) : r.location ? (
                        fm(`rtms.patientsRating.opts.${r.location}`)
                      ) : (
                        ''
                      )}
                    </Item>
                    {index === 0 &&
                      ind === 0 &&
                      patientsRatingPeriods.map((period, i) => (
                        <Item key={`${period}${i}`} xs={2}>
                          {fm(`rtms.patientsRating.${period}`)}
                        </Item>
                      ))}
                  </NameContainer>
                  {patientsRatingNames.map((name, j) => (
                    <Container key={j} style={{ marginBottom: j > 0 ? '1.5rem' : undefined }}>
                      <TitleItem xs={3} index={j}>
                        <span style={{ paddingLeft: '1rem' }}>
                          {fm(
                            `rtms.patientsRating.${p.symptom === 'other' ? 'symptom' : p.symptom}${capitalize(name)}`,
                          )}
                        </span>
                      </TitleItem>
                      {patientsRatingPeriods?.map((period, k) => {
                        const v = path([name, period], r);
                        return (
                          <ValueItem key={`${period}${k}`} xs={2} index={j} style={{ fontWeight: 600 }}>
                            {typeof v === 'number' && !isNaN(v) ? v.toFixed(1) : '-'}
                          </ValueItem>
                        );
                      })}
                    </Container>
                  ))}
                </React.Fragment>
              )),
          )}
        </div>
      )}
      <div style={{ margin: '4rem 0 4.5rem 0' }}>
        <TitleContainer>
          <Item xs={true}>{fm('rtms.subjectOfTreatment.title')}</Item>
        </TitleContainer>
        <NameContainer>
          <Item xs={3} style={{ minHeight: 30 }} />
          {treatments?.map((t, i) => (
            <Item key={i} xs={2}>
              {nameSelector(t)}
            </Item>
          ))}
        </NameContainer>
        {fields?.map((f, i) => (
          <Container key={i}>
            <TitleItem xs={3} index={i}>
              <span style={{ paddingLeft: '1rem' }}>
                {fm(`rtms.subjectOfTreatment.${f === 'additionalInformation' ? `${f}Subject` : f}`)}
              </span>
            </TitleItem>
            {treatments?.map((t, j) => {
              const { name, value, fieldMatchesType } = getRtmsRowData(f as keyof IRTMSTreatmentEvent, t);
              return (
                <ValueItem key={j} xs={2} index={i}>
                  {t.deleted
                    ? f === 'additionalInformation'
                      ? deleteReasonFormatter(t)
                      : ''
                    : f === 'session'
                      ? getSessionNumber(eventsBeyondThisDoc, currentIndex - 1, t) || ''
                      : fieldMatchesType
                        ? valueFormatter(name, value, event, j)
                        : ''}
                </ValueItem>
              );
            })}
          </Container>
        ))}
      </div>
    </React.Fragment>
  );
};

interface ITreatmentsBySession {
  event: IRTMSSession;
  eventsBeyondThisDoc: Array<IRTMSSession>;
}

export default TreatmentsBySession;
