import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistorySection from '../../../../components/HistorySection';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { Container, Item } from '../../../../components/Grid';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import { maxNpiScores, npiFormFields, npiHistoryFields } from '../Form/NPI/definitions';
import { calculateNpiFrequencyAndSeverityScore, calculateNpiStressScore } from '../../utils';
import Unit from '../../../../components/Unit';
import { exists } from 'neuro-utils';
import { omit, path } from 'Utility/ramdaReplacement';

const NPIHistory = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'diagnosticSurvey.npi.title'} />}
      newButton={
        <DocumentCreationButton
          name="npi"
          text={'diagnosticSurvey.npi.newNPI'}
          onClick={startEdit({}, 'npi')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <Item xs={true}>
            <Container>
              {npiHistoryFields.map((f: any) => (
                <Item
                  key={f}
                  xs={2}
                  style={{
                    paddingRight: '0.5rem' /** Bigger padding or smaller screen might need manual word breaking */,
                  }}
                >
                  <FormattedMessage id={`diagnosticSurvey.npi.${f}`} />
                </Item>
              ))}
            </Container>
          </Item>
        }
        contentFormat={(d: INPI): React.JSX.Element => (
          <Item xs={true}>
            <Container>
              {npiFormFields['summary'].map((f: string) => {
                const score = {
                  frequencyAndSeverityTotalScoreFromDelusionsToAbnormalMotoricBehavior:
                    calculateNpiFrequencyAndSeverityScore(omit(['sleepDisorders', 'appetiteAndEatingDisorders'], d)),
                  stressTotalScoreFromDelusionsToAbnormalMotoricBehavior: calculateNpiStressScore(
                    omit(['sleepDisorders', 'appetiteAndEatingDisorders'], d),
                  ),
                  frequencyAndSeverityTotalScoreFromSleepDisorders: calculateNpiFrequencyAndSeverityScore({
                    sleepDisorders: d.sleepDisorders,
                  } as INPI),
                  stressTotalScoreFromSleepDisorders: path(['sleepDisorders', 'stress'], d) as number,
                  frequencyAndSeverityTotalScoreFromEatingAndAppetiteDisorders: calculateNpiFrequencyAndSeverityScore({
                    appetiteAndEatingDisorders: d.appetiteAndEatingDisorders,
                  } as INPI),
                  stressTotalScoreFromEatingAndAppetiteDisorders: path(
                    ['appetiteAndEatingDisorders', 'stress'],
                    d,
                  ) as number,
                }[f];
                return (
                  <Item key={f} xs={2}>
                    <Unit unit={`/ ${maxNpiScores[f]}`} fontWeight={'bold'}>
                      <React.Fragment>{exists(score) ? score : '-'}</React.Fragment>
                    </Unit>
                  </Item>
                );
              })}
            </Container>
          </Item>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: INPI[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default NPIHistory;
