import * as React from 'react';
import { Container, Item } from 'Components/Grid';
import { TabContentContext } from 'Components/TabContent';
import { TDCSContext } from 'Routes/Tdcs/Document';
import { equals } from 'ramda';
import { sortPartialDate } from 'neuro-utils';
import { v4 } from 'uuid';
import colors from '../../../../../../../config/theme/colors';
import {
  createReferenceDocument,
  findPrecedingDoctorsOrder,
  originAndDateOfReference,
  subjectOfTreatmentTitle,
} from 'Utility/ninmtUtil';
import { omit } from 'Utility/ramdaReplacement';

const TabEditor = ({ editIndex }: { editIndex: number }): React.JSX.Element => {
  const tabContext = React.useContext(TabContentContext);
  const tdcsContext = React.useContext(TDCSContext);

  const selected = tabContext.selected;
  const { formData, fm, doctorsOrders, documents } = tdcsContext;
  const { document, onChange } = formData;

  const sessions = document.sessions ?? [];
  const treatments = sessions?.[editIndex]?.subjectOfTreatment;

  const otherSessions = documents.flatMap((d) =>
    d.sessions ? d.sessions.filter((s) => !sessions.some((s2) => s2.id === s.id)) : [],
  );

  const sessionsSorted = sessions
    .concat(otherSessions)
    .slice()
    .sort((s1, s2) => (s2?.createDate ?? 0) - (s1?.createDate ?? 0))
    .sort((s1, s2) => sortPartialDate(s2.date, s1.date));

  const currentIndex = sessionsSorted.findIndex((s) => equals(sessions?.[editIndex], s));

  const thisTreatment: { [key: string]: any } | undefined = treatments?.[selected];

  // Refer to latest subject of treatment (given in doctor's orders)
  const precedingDoctorsOrderWithSOT = findPrecedingDoctorsOrder('tdcs', doctorsOrders, sessionsSorted, currentIndex);

  let referenceDocument: Partial<ITDCSSession> | undefined = undefined;
  if (precedingDoctorsOrderWithSOT) {
    referenceDocument = createReferenceDocument('tdcs', precedingDoctorsOrderWithSOT, sessionsSorted, currentIndex);
  }

  const mapReferenceDocumentsToTreatments = () => {
    const sessionsToUpdate = sessions
      .slice()
      .sort((s1, s2) => (s2?.createDate ?? 0) - (s1?.createDate ?? 0))
      .sort((s1, s2) => sortPartialDate(s2.date, s1.date));
    const updateIndex = sessionsToUpdate.findIndex((s) => equals(sessions?.[editIndex], s));

    const newSession = {
      ...sessionsToUpdate?.[updateIndex],
      subjectOfTreatment: referenceDocument?.subjectOfTreatment?.map((s: ITDCSTreatment) => ({
        ...omit(['id', 'additionalInformation'], s),
        id: v4(),
        name: s.name === 'other' && s.nameOther ? s.nameOther : s.name,
      })),
    };
    const newSessions = JSON.parse(JSON.stringify(document?.sessions ?? []));
    newSessions[editIndex] = newSession;
    onChange && onChange({ sessions: newSessions });
  };

  React.useEffect(() => {
    if (referenceDocument && (!Array.isArray(treatments) || treatments.length === 0)) {
      mapReferenceDocumentsToTreatments();
    }
  });

  return thisTreatment?.deleted ? (
    <React.Fragment />
  ) : (
    <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Item xs={true}>
        <Container style={{ alignItems: 'center', margin: '0.5rem 0 2rem 0' }}>
          <Item xs={5} style={{ color: colors.tertiaryText }}>
            {fm('tdcs.subjectOfTreatment.treatmentName')}
          </Item>
          <Item xs={true} style={{ fontWeight: 600, marginLeft: '0.9rem' }}>
            {subjectOfTreatmentTitle(thisTreatment as ITDCSTreatment, 'tdcs', fm)}
          </Item>
        </Container>
        <Container style={{ alignItems: 'center', marginBottom: '0.5rem' }}>
          <Item xs={5} style={{ color: colors.tertiaryText }}>
            {fm('tdcs.subjectOfTreatment.copyTitle')}
          </Item>
          <Item xs={true} style={{ marginLeft: '0.9rem' }}>
            {originAndDateOfReference(thisTreatment as ITDCSTreatment, 'tdcs', fm)}
          </Item>
        </Container>
      </Item>
    </Container>
  );
};

export default TabEditor;
