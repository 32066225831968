import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

const NightOximetry = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id="clpAndCnpTests.nightOximetry" />}
      newButton={
        <DocumentCreationButton
          name="nightOximetry"
          text="clpAndCnpTests.newNightOximetry"
          onClick={startEdit({}, 'nightOximetry')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id="general.date" />
            </Item>
            <Item xs={10}></Item>
          </>
        }
        contentFormat={(d: INightOximetry): React.JSX.Element => (
          <>
            <Item xs={2} style={{ fontWeight: 600 }}>
              {formatPartialDate(d.date ?? undefined)}
            </Item>
            <Item xs={7}></Item>
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} view={view} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: INightOximetry[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default NightOximetry;
