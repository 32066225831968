import DataTable from 'Components/DataTable';
import { Container, Item } from 'Components/Grid';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import { INeuroStimulus } from 'neuro-data-structures';
import { formatPartialDate, partialDateFromDate, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import { MyServiceContext } from '..';
import ActionButtonRounded from 'Components/ActionButtonRounded';

const InvitesHistory = ({
  invites,
  inviteErrors,
  taskLists,
}: {
  invites: Array<INeuroStimulus>;
  inviteErrors: Array<{ method: 'sms' | 'email'; statusCode: number }>;
  taskLists: Array<ITaskList>;
}): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, setEditingObj } = myServContext;

  const orgs = useSelector((s: IState) => s.session.orgGroupArray);
  const patientContactInfo = useSelector((s: { patient: IPatientStore }) => s.patient.contactInfo);

  const invitesData = invites
    .toSorted((a, b) => b.timestamp - a.timestamp)
    .map((i) => [
      <div key="date" style={{ whiteSpace: 'nowrap', paddingRight: '0.5rem' }}>
        {formatPartialDate(partialDateFromDate(new Date(i.timestamp)))}
      </div>,
      fm(`myService.opts.${i.medium === 'EMAIL' ? 'email' : 'sms'}`),
      i.carrier ? i.carrier : '',
      i.org_id ? orgs?.find((o) => o.orgId === i.org_id)?.displayName || i.org_id : '',
    ]);

  const firstInviteUpcoming = invites.length === 0 && taskLists.length > 0;

  const firstTaskListSendDate = taskLists.toSorted((a, b) => sortPartialDate(b.sendDate, a.sendDate))[0]?.sendDate;

  return (
    <>
      <Container style={{ justifyContent: 'space-between' }}>
        <Item>
          <HistoryRowSubHeader header={fm('myService.invites')} />
        </Item>

        <Item>
          <ActionButtonRounded
            text="myService.newInvite"
            filled={true}
            uppercase={false}
            width={9.6}
            height={3}
            fontSize={16}
            onClick={() => {
              setEditingObj({
                type: 'invite',
                data: null,
              });
            }}
            disabled={firstInviteUpcoming || !!patientContactInfo?.contactNotAllowed}
            disabledTooltip={firstInviteUpcoming ? undefined : <span>{fm('myService.newInviteDisabledTooltip')}</span>}
          />
        </Item>
      </Container>
      {firstInviteUpcoming ? (
        fm('myService.firstInviteUpcomingInfo', {
          date: formatPartialDate(firstTaskListSendDate),
          strong: (chunks) => <span style={{ fontWeight: '600' }}>{chunks}</span>,
        })
      ) : (
        <DataTable
          headers={['sendingDate', 'sendingMedium', 'numberOrAddress', 'sendingOrg']}
          headersFormatter={(h) => h && fm(`myService.${h}`)}
          data={{ rowData: invitesData }}
        />
      )}
      <div style={{ color: 'red', marginTop: '2rem' }}>
        {inviteErrors.map(
          (err) =>
            err.statusCode === 429 && (
              <div key={err.method}>
                {fm('errors.invites.' + err.method)}
                {' - '}
                {fm('errors.invites.' + err.statusCode)}
              </div>
            ),
        )}
      </div>
    </>
  );
};

export default InvitesHistory;
