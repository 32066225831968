import * as React from 'react';
import { Item } from '../../../../../../components/Grid';
import HistoryRow from '../../../../../../components/HistoryRow';
import HistoryRowControls from '../../../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../../../components/HistoryRowListing';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { newButton } from '../../../../utils/functions';
import { styled } from '@mui/system';
import { capitalize } from 'Utility/string';

const StyledTableColumn = styled('td')({
  padding: 0,
});

const getOrphaCodeCell = (d: IEtiology | IEtiologySubClasses): React.JSX.Element => {
  return (
    <StyledTableColumn style={{ verticalAlign: 'top' }}>
      {'orphaCodeMetabolismUnknown' in d && d.orphaCodeMetabolismUnknown ? d.orphaCodeMetabolismUnknown : undefined}
      {'orphaCode' in d && d.orphaCode ? d.orphaCode : undefined}
      {!('orphaCodeMetabolismUnknown' in d) && !('orphaCode' in d) ? '-' : undefined}
    </StyledTableColumn>
  );
};

const getSideCell = (sb: IEtiologySubClasses): React.JSX.Element => {
  return (
    <StyledTableColumn style={{ verticalAlign: 'top' }}>
      <span>
        {sb.side ? fm(`diagnosis.epilepsy.etiology.opts.${sb.side}`) : '-'}
        {': '}
        {sb.field ? fm(`diagnosis.epilepsy.etiology.opts.${sb.field}`) : '-'}
      </span>
    </StyledTableColumn>
  );
};

const getGeneticCellContent = (sb: IEtiologySubClasses, index?: number): React.JSX.Element => {
  const nth = index || index === 0 ? index + 1 : undefined;
  return (
    <>
      {nth ? `${nth}) ` : undefined}
      {nth && sb.subClassGenetic && fm(`diagnosis.epilepsy.etiology.opts.${sb.subClassGenetic}`)}
      {sb.subClassGenetic === 'singleGeneMutation' && (
        <>
          <div>
            {fm('geneTest.gene')}
            {': '}
            {sb.geneInput ?? '-'}
          </div>
          <div>
            {fm('geneTest.mutation')}
            {': '}
            {sb.mutationInput ?? '-'}
          </div>
        </>
      )}
      {sb.subClassGenetic === 'copyNumberVariation' && (
        <>
          <div>
            {fm('diagnosis.epilepsy.etiology.chromosome')}
            {': '}
            {sb.chromosomeInput ?? '-'}
          </div>
          <div>
            {fm('diagnosis.epilepsy.etiology.determiner')}
            {': '}
            {sb.specificationInput ?? '-'}
          </div>
        </>
      )}
      {sb.subClassGenetic === 'copyNumberVariation' ||
        (sb.subClassGenetic === 'singleGeneMutation' && (
          <div>
            {fm('diagnosis.epilepsy.etiology.omimTitleShort')}
            {': '}
            {sb.omimInput ?? '-'}
          </div>
        ))}
      {sb.specification && (
        <span>
          {': '}
          {fm(`diagnosis.epilepsy.etiology.opts.${sb.specification}`)}
        </span>
      )}
      {sb.chromosome && (
        <span>
          {': '}
          {fm(`diagnosis.epilepsy.etiology.opts.${sb.chromosome}`)}
        </span>
      )}
      {sb.otherChromosomeInput && (
        <span>
          {': '}
          {sb.otherChromosomeInput}
        </span>
      )}
      {sb.familialInput && (
        <span>
          {': '}
          {sb.familialInput}
        </span>
      )}
    </>
  );
};

const getEtioCellContent = (sb: IEtiologySubClasses, mainClass?: string, index?: number): React.JSX.Element => {
  const nth = index || index === 0 ? index + 1 : undefined;
  return (
    <>
      {nth ? `${nth}) ` : undefined}
      {mainClass &&
        fm(`diagnosis.epilepsy.etiology.opts.${sb[`subClass${capitalize(mainClass)}` as keyof IEtiologySubClasses]}`)}
      {sb.specificClass && (
        <span>
          {': '}
          {fm(`diagnosis.epilepsy.etiology.opts.${sb.specificClass}`)}
        </span>
      )}
      {sb.superSpecificClass && (
        <span>
          {': '}
          {fm(`diagnosis.epilepsy.etiology.opts.${sb.superSpecificClass}`)}
        </span>
      )}
      {sb.structuralOtherSpecification && (
        <span>
          {': '}
          {sb.structuralOtherSpecification}
        </span>
      )}
      {sb.infectionSpecification && (
        <span>
          {': '}
          {sb.infectionSpecification}
        </span>
      )}
      {sb.antidoteInput && (
        <span>
          {': '}
          {sb.antidoteInput}
        </span>
      )}
    </>
  );
};

const EtiologyHistory = ({ documents = [], startEdit }: IHistoryContext): React.JSX.Element => {
  const etiologyDocuments: IEtiology[] = documents.filter((d: any) => d._type === 'etiology') as IEtiology[];
  return (
    <HistoryRow
      headerText={fm('diagnosis.epilepsy.etiology.title')}
      rowButton={startEdit ? newButton('etiology', startEdit({}, 'etiology'), 'general.new', 15) : undefined}
    >
      <HistoryRowListing
        documents={etiologyDocuments}
        headers={
          <>
            <Item xs={4}>{fm('diagnosis.epilepsy.etiology.title')}</Item>

            <Item xs={3}>{fm('diagnosis.epilepsy.orphaCode')}</Item>

            <Item xs={3}>
              {fm('diagnosis.epilepsy.etiology.side')}
              {': '}
              {fm('diagnosis.epilepsy.etiology.field')}
            </Item>

            <Item xs={2}>{fm('diagnosis.epilepsy.diagnosisDate')}</Item>
          </>
        }
        contentFormat={(d: IEtiology): React.JSX.Element => {
          return (
            <>
              <Item xs={10} style={{ paddingRight: 0 }}>
                <table style={{ width: '100%', borderSpacing: '0' }}>
                  <colgroup>
                    <col span={1} style={{ width: (4 / 12) * 100 + '%' }}></col>
                    <col span={1} style={{ width: (3 / 12) * 100 + '%' }}></col>
                    <col span={1} style={{ width: (3 / 12) * 100 + '%' }}></col>
                  </colgroup>
                  <tbody>
                    {d.subClasses && d.subClasses.length >= 2 ? (
                      <>
                        <tr>
                          <StyledTableColumn>
                            {d.class ? fm(`diagnosis.epilepsy.etiology.opts.${d.class}`) : '-'}
                            {': '}
                          </StyledTableColumn>
                          <StyledTableColumn />
                          <StyledTableColumn />
                        </tr>
                        {d.subClasses.map((sb, i) => {
                          return (
                            <tr key={`${i}-row`}>
                              {d.class === 'genetic'
                                ? getGeneticCellContent(sb, i)
                                : getEtioCellContent(sb, d.class, i)}
                              {getOrphaCodeCell(sb)}
                              {d.class === 'structural' && getSideCell(sb)}
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <StyledTableColumn>
                          {d.class ? fm(`diagnosis.epilepsy.etiology.opts.${d.class}`) : '-'}
                          {d.class && d.subClasses && d.subClasses[0] ? (
                            <>
                              <span>
                                {': '}
                                {fm(
                                  `diagnosis.epilepsy.etiology.opts.${
                                    d.subClasses[0][`subClass${capitalize(d.class)}` as keyof IEtiologySubClasses]
                                  }`,
                                )}
                              </span>
                              {d.class === 'genetic'
                                ? getGeneticCellContent(d.subClasses[0])
                                : getEtioCellContent(d.subClasses[0])}
                            </>
                          ) : (
                            <>
                              {d.class === 'metabolism' || d.class === 'unknown' ? (
                                <span>
                                  {': '}
                                  {d.class === 'metabolism' ? d.metabolismSpecification : d.unknownSpecification}
                                </span>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </StyledTableColumn>
                        {d.orphaCodeMetabolismUnknown
                          ? getOrphaCodeCell(d)
                          : d.subClasses && d.subClasses[0]
                            ? getOrphaCodeCell(d.subClasses[0])
                            : '-'}
                        {d.class === 'structural' && d.subClasses && d.subClasses[0]
                          ? getSideCell(d.subClasses[0])
                          : undefined}
                      </tr>
                    )}
                  </tbody>
                </table>
              </Item>
              <Item xs={2}>{formatPartialDate(d.date)}</Item>
            </>
          );
        }}
        collapse={false} // All etiologies should be shown NEUR-4608
        makeControls
        historyRowControls={(d: IControlProps) =>
          startEdit ? <HistoryRowControls document={d} startEdit={startEdit} /> : <></>
        }
      />
    </HistoryRow>
  );
};

export default withHistoryContext(EtiologyHistory);
