import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistorySection from '../../../../components/HistorySection';
import ToolTip from '../../../../components/ToolTip';
import CollapseElem from '../../../../components/Collapse';
import PlatformConditional from '../../../../components/PlatformConditional';
import HistoryRow from '../../../../components/HistoryRow';
import HistoryRowVerticalItem from '../../../../components/HistoryRowVerticalItem';
import HistoryRowSubHeader from '../../../../components/HistoryRowSubHeader';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { Container, Item } from '../../../../components/Grid';
import HistoryRowSubHeaderBar from '../../../../components/HistoryRowSubHeaderBar';
import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';

import colors from '../../../../config/theme/colors';
import { convertTrendToIcon } from '../../utils';
import { exists, formatPartialDate, sortPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { statementStyle } from '../Components/Statement';
import { EditingLockedContentContext } from 'Containers/EditingLockedContentContext';

const StyledSubHeader2 = ({ title }: IStyledRow): React.JSX.Element => <HistoryRowSubHeader header={title} />;

const StyledRow = ({ title, value, condition, id }: IStyledRow): React.JSX.Element => (
  <React.Fragment>
    <span id={id} />
    {condition && condition === true ? (
      <HistoryRowVerticalItem header={title} value={value ? value : '-'} />
    ) : undefined}
  </React.Fragment>
);

const fm = (id: string, values?: { [key: string]: string | number | null }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const LesionsDataHeader = (): React.JSX.Element => (
  <Container style={{ color: colors.tertiaryText }}>
    <Item xs={3}></Item>
    <Item xs={2}>{fm('imaging.lesions')}</Item>
    <Item xs={2}>
      <ToolTip description={fm('imaging.lesionsDelta')} content={<span>&Delta;</span>} hover={true} />
    </Item>
    <Item xs={2}>
      <ToolTip description={fm('imaging.lesionsMaxDiameter')} content={<span>Ø</span>} hover={true} />
    </Item>
    <Item xs={2}>{fm('imaging.trend')}</Item>
  </Container>
);

const LesionsDataRow = ({ rowTitle, document, lesions }: ILesionsDataRow): React.JSX.Element | null => {
  const inputData = lesions.split('.');
  const lesionsData =
    (document as { [key: string]: any })[inputData[0]] &&
    (document as { [key: string]: any })[inputData[0]][inputData[1]];

  return lesionsData && lesionsData !== null ? (
    <Container style={{ marginTop: '1rem', fontWeight: 600 }} alignItems="center">
      <Item xs={3} style={{ color: colors.tertiaryText, fontWeight: 400 }}>
        {fm(rowTitle)}
      </Item>
      <Item xs={2}>
        {exists(lesionsData.lesions) ? (
          lesionsData.lesions === 'X' ? (
            <ToolTip
              description={fm('imaging.opts.notDoneUnknown.notDone')}
              content={<span>{lesionsData.lesions}</span>}
              hover={true}
            />
          ) : lesionsData.lesions === 'N/A' ? (
            <ToolTip
              description={fm('imaging.opts.notDoneUnknown.unknown')}
              content={<span>{lesionsData.lesions}</span>}
              hover={true}
            />
          ) : (
            lesionsData.lesions
          )
        ) : (
          <ToolTip description={fm('imaging.opts.notEntered')} content={<span>-</span>} hover={true} />
        )}
      </Item>
      <Item xs={2}>
        {exists(lesionsData.lesionsDelta) ? (
          lesionsData.lesionsDelta
        ) : (
          <ToolTip description={fm('imaging.opts.notEntered')} content={<span>-</span>} hover={true} />
        )}
      </Item>
      <Item xs={2}>
        {exists(lesionsData.lesionsMaxDiameter) ? (
          lesionsData.lesionsMaxDiameter
        ) : (
          <ToolTip description={fm('imaging.opts.notEntered')} content={<span>-</span>} hover={true} />
        )}
      </Item>
      <Item xs={2}>
        {lesionsData.lesionsTrend ? (
          convertTrendToIcon(lesionsData.lesionsTrend)
        ) : (
          <ToolTip description={fm('imaging.opts.notEntered')} content={<span>-</span>} hover={true} />
        )}
      </Item>
    </Container>
  ) : null;
};

const MriDataSection = ({ document }: IMriDataSection): React.JSX.Element => {
  return (
    <React.Fragment>
      <PlatformConditional platform={'ms'}>
        <React.Fragment>
          {(document.brainT2Flair || document.brainT1 || exists(document.brainVolume) || document.brainVolumeTrend) && (
            <React.Fragment>
              <HistoryRowSubHeader header={fm('imaging.brain')} />
              {document.brainT2Flair && (
                <React.Fragment>
                  <HistoryRowSubHeaderBar header={fm('imaging.brainT2Flair')} />
                  <LesionsDataHeader />
                  <LesionsDataRow rowTitle="imaging.totalLesions" document={document} lesions={'brainT2Flair.total'} />
                  <LesionsDataRow
                    rowTitle="imaging.infratentorialLesions"
                    document={document}
                    lesions={'brainT2Flair.infratentorial'}
                  />
                  <LesionsDataRow
                    rowTitle="imaging.supratentorialLesions"
                    document={document}
                    lesions={'brainT2Flair.supratentorial'}
                  />
                  <LesionsDataRow
                    rowTitle="imaging.intraAndJuxtacorticalLesions"
                    document={document}
                    lesions={'brainT2Flair.intraAndJuxtacortical'}
                  />
                </React.Fragment>
              )}
              {document.brainT1 && (
                <div style={{ marginTop: document.brainT2Flair && '2rem' }}>
                  <HistoryRowSubHeaderBar header={fm('imaging.brainT1')} />
                  {!document.brainT2Flair && <LesionsDataHeader />}
                  <LesionsDataRow rowTitle="imaging.totalLesions" document={document} lesions={'brainT1.total'} />
                  <LesionsDataRow
                    rowTitle="imaging.gadoliniumEnhancedLesions"
                    document={document}
                    lesions={'brainT1.gadoliniumEnhanced'}
                  />
                </div>
              )}
              {(exists(document.brainVolume) || document.brainVolumeTrend) && (
                <React.Fragment>
                  <Container
                    style={{
                      margin: document.brainT2Flair || document.brainT1 ? '4rem 0 0.5rem 0' : '1rem 0 0.5rem 0',
                    }}
                  >
                    <Item xs={2} style={{ textAlign: 'left', color: colors.tertiaryText }}>
                      {fm('imaging.brainVolume')}
                    </Item>
                  </Container>
                  <Container>
                    <Item
                      xs={1}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontWeight: 600,
                      }}
                    >
                      {document.brainVolume}
                      <span style={{ marginRight: '1.7rem' }}>
                        {convertTrendToIcon(document.brainVolumeTrend, true)}
                      </span>
                    </Item>
                  </Container>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {(document.spinalCordT2 || document.spinalCordT1) && (
            <React.Fragment>
              <HistoryRowSubHeader header={fm('imaging.spinalCord')} />
              {document.spinalCordT2 && (
                <React.Fragment>
                  <HistoryRowSubHeaderBar header={fm('imaging.spinalCordT2')} />
                  <LesionsDataHeader />
                  <LesionsDataRow rowTitle="imaging.totalLesions" document={document} lesions={'spinalCordT2.total'} />
                </React.Fragment>
              )}
              {document.spinalCordT1 && (
                <div style={{ marginTop: document.spinalCordT2 && '2rem' }}>
                  <HistoryRowSubHeaderBar header={fm('imaging.spinalCordT1')} />
                  <LesionsDataRow
                    rowTitle="imaging.gadoliniumEnhancedLesions"
                    document={document}
                    lesions={'spinalCordT1.gadoliniumEnhanced'}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform={'parkinson'}>
        <React.Fragment>
          {(document.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous ||
            document.atrophy ||
            document.otherClinicallyRelevantFindingFound) && (
            <React.Fragment>
              {document.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous && (
                <React.Fragment>
                  <StyledSubHeader2 title={fm('imaging.fazekas')} />
                  <StyledRow
                    id={'microAngiopathicHistory'}
                    title={fm('imaging.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous')}
                    value={
                      document.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous &&
                      fm(
                        `imaging.opts.yesFazekas0.${document.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous}`,
                      )
                    }
                    condition={true}
                  />
                  <StyledRow
                    id={'fazekasClassificationHistory'}
                    title={fm('imaging.wasFazekasClassificationDone')}
                    value={
                      document.wasFazekasClassificationDone &&
                      fm(`imaging.opts.yesNo.${document.wasFazekasClassificationDone}`)
                    }
                    condition={document.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous === 'yes'}
                  />
                  <StyledRow
                    id={'fazekasGradeHistory'}
                    title={fm('imaging.fazekasGrade')}
                    value={document.fazekasGrade && fm(`imaging.opts.fazekas.${document.fazekasGrade}`)}
                    condition={
                      document.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous === 'yes' &&
                      document.wasFazekasClassificationDone === 'yes'
                    }
                  />
                </React.Fragment>
              )}
              {document.atrophy && (
                <React.Fragment>
                  <StyledSubHeader2 title={fm('imaging.atrophy')} />
                  <StyledRow
                    id={'antrophyHistory'}
                    title={fm('imaging.atrophy')}
                    value={document.atrophy && fm(`imaging.opts.atrophy.${document.atrophy}`)}
                    condition={true}
                  />
                  <StyledRow
                    id={'antrophyLocalizationHistory'}
                    title={fm('imaging.atrophyLocalization')}
                    value={document.atrophyLocalization}
                    condition={document.atrophy === 'focal'}
                  />
                </React.Fragment>
              )}
              {document.otherClinicallyRelevantFindingFound && (
                <React.Fragment>
                  <StyledSubHeader2 title={fm('imaging.other')} />
                  <StyledRow
                    id={'otherRelevantHistory'}
                    title={fm('imaging.otherClinicallyRelevantFindingFound')}
                    value={
                      document.otherClinicallyRelevantFindingFound &&
                      fm(`imaging.opts.yesNo.${document.otherClinicallyRelevantFindingFound}`)
                    }
                    condition={true}
                  />
                  <StyledRow
                    title={fm('imaging.whatFinding')}
                    value={document.otherClinicallyRelevantFinding}
                    condition={document.otherClinicallyRelevantFindingFound === 'yes'}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

const Mri = ({ documents, startEdit }: IOwnProps): React.JSX.Element => {
  const showProcedureCodeHeader = documents.some((d) => d.procedureCode && d.procedureCode.length > 0);
  const showStatementHeader = documents.some((d) => d.statement && d.statement.length > 0);

  const showProcedureCode = (d: IMRI) => d.procedureCode && d.procedureCode.length > 0;
  const showStatement = (d: IMRI) => d.statement && d.statement.length > 0;

  const { openEditing } = React.useContext(EditingLockedContentContext);

  return (
    <React.Fragment>
      <PlatformConditional platform={['sma']}>
        <HistorySection
          headerText={<FormattedMessage id={'imaging.mri'} />}
          newButton={
            <DocumentCreationButton
              name="mri"
              text={'imaging.newmri'}
              onClick={startEdit({} as IMRI, 'mri')}
              width={15}
              height={3}
              fontSize={14}
              alternate={true}
            />
          }
        >
          <HistoryRowListing
            documents={documents || []}
            headers={
              <React.Fragment>
                <Item xs={2}>
                  <FormattedMessage id={'general.date'} />
                </Item>
                {showProcedureCodeHeader && (
                  <Item xs={2}>
                    <FormattedMessage id={'imaging.procedureCode'} />
                  </Item>
                )}
                {showStatementHeader && (
                  <Item xs={5}>
                    <FormattedMessage id={'imaging.statement'} />
                  </Item>
                )}
              </React.Fragment>
            }
            contentFormat={(d: IMRI): React.JSX.Element => (
              <React.Fragment>
                <Item xs={2} id={'mriDate'}>
                  {formatPartialDate(d.date)}
                </Item>
                <Item xs={2}>{showProcedureCode(d) ? d.procedureCode : ''}</Item>
                <Item xs={5} style={statementStyle}>
                  {showStatement(d) ? d.statement : ''}
                </Item>

                <Item xs={3}>
                  <HistoryRowControls document={d} startEdit={startEdit} />
                </Item>
              </React.Fragment>
            )}
          />
        </HistorySection>
      </PlatformConditional>
      <PlatformConditional platform={'ms'}>
        <HistorySection
          headerText={<FormattedMessage id={'imaging.mri'} />}
          newButton={
            <DocumentCreationButton
              name="mri"
              text={'imaging.newmri'}
              onClick={startEdit({} as IMRI, 'mri')}
              width={15}
              height={3}
              fontSize={14}
              alternate={true}
            />
          }
          hasHistoryRows={true}
        >
          <React.Fragment>
            {documents &&
              documents
                .sort((n1, n2) => n1._cdate - n2._cdate)
                .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
                .reverse()
                .map((d: IMRI, index) => (
                  <React.Fragment key={d._id}>
                    <HistoryRow
                      headerText={formatPartialDate(d.date)}
                      rowButton={
                        <HistoryRowControls
                          document={d}
                          startEdit={
                            d._lockedFor && d._source !== null
                              ? (doc: IMRI) => () => openEditing(doc, 'imaging.mri')
                              : startEdit
                          }
                        />
                      }
                      controlsMargin={false}
                    >
                      <React.Fragment>
                        <Container>
                          <Item xs={3}>
                            <HistoryRowBasicItem
                              header={<FormattedMessage id={'general.date'} />}
                              value={d.date ? formatPartialDate(d.date) : '-'}
                            />
                          </Item>
                          <Item xs={2}>
                            <HistoryRowBasicItem
                              header={<FormattedMessage id={'imaging.procedureCode'} />}
                              value={d.procedureCode ?? '-'}
                            />
                          </Item>
                          <Item xs={2}>
                            <HistoryRowBasicItem
                              header={<FormattedMessage id={'imaging.tesla'} />}
                              value={d.tesla ?? '-'}
                            />
                          </Item>
                          <Item xs={3}>
                            <HistoryRowBasicItem
                              header={<FormattedMessage id={'imaging.wasGadoliniumGiven'} />}
                              value={
                                d.wasGadoliniumGiven ? fm(`imaging.opts.yesNoUnknown.${d.wasGadoliniumGiven}`) : '-'
                              }
                            />
                          </Item>
                        </Container>
                        {showStatement(d) && (
                          <div style={{ marginTop: '2rem' }}>
                            <StyledRow
                              id={'statement'}
                              title={fm('imaging.statement')}
                              value={d.statement}
                              condition={true}
                            />
                          </div>
                        )}
                        {index === 0 ? (
                          <MriDataSection document={d} />
                        ) : (
                          (d.brainT2Flair ||
                            d.brainT1 ||
                            exists(d.brainVolume) ||
                            d.brainVolumeTrend ||
                            d.spinalCordT2 ||
                            d.spinalCordT1) && (
                            <div style={{ marginTop: '3rem' }}>
                              <CollapseElem
                                localeIDs={{ showMessage: 'imaging.show', hideMessage: 'imaging.hide' }}
                                amount={documents.length}
                              >
                                <div style={{ marginBottom: '1rem' }}>
                                  <MriDataSection document={d} />
                                </div>
                              </CollapseElem>
                            </div>
                          )
                        )}
                      </React.Fragment>
                    </HistoryRow>
                  </React.Fragment>
                ))}
          </React.Fragment>
        </HistorySection>
      </PlatformConditional>
      <PlatformConditional platform={'parkinson'}>
        <HistorySection
          headerText={<FormattedMessage id={'imaging.mri'} />}
          newButton={
            <DocumentCreationButton
              name="mri"
              text={'imaging.newmri'}
              onClick={startEdit({} as IMRI, 'mri')}
              width={15}
              height={3}
              fontSize={14}
              alternate={true}
            />
          }
          hasHistoryRows={true}
        >
          <React.Fragment>
            {documents &&
              documents
                .sort((n1, n2) => n1._cdate - n2._cdate)
                .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
                .reverse()
                .map((d: IMRI, index) => (
                  <React.Fragment key={d._id}>
                    <HistoryRow
                      headerText={formatPartialDate(d.date)}
                      rowButton={
                        <HistoryRowControls
                          document={d}
                          startEdit={
                            d._lockedFor && d._source !== null
                              ? (doc) => () => openEditing(doc, 'imaging.mri')
                              : startEdit
                          }
                        />
                      }
                      controlsMargin={false}
                    >
                      <React.Fragment>
                        {showProcedureCode(d) && (
                          <StyledRow
                            id={'procedureCode'}
                            title={fm('imaging.procedureCode')}
                            value={d.procedureCode}
                            condition={true}
                          />
                        )}
                        {showStatement(d) && (
                          <StyledRow
                            id={'statement'}
                            title={fm('imaging.statementText')}
                            value={d.statement}
                            condition={true}
                          />
                        )}
                        {index === 0 ? (
                          <MriDataSection document={d} />
                        ) : (
                          (d.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous ||
                            d.atrophy ||
                            d.otherClinicallyRelevantFindingFound) && (
                            <CollapseElem
                              localeIDs={{ showMessage: 'imaging.show', hideMessage: 'imaging.hide' }}
                              amount={documents.length}
                            >
                              <div style={{ marginBottom: '1rem' }}>
                                <MriDataSection document={d} />
                              </div>
                            </CollapseElem>
                          )
                        )}
                      </React.Fragment>
                    </HistoryRow>
                  </React.Fragment>
                ))}
          </React.Fragment>
        </HistorySection>
      </PlatformConditional>
      <PlatformConditional platform={'ninmt'}>
        <HistorySection
          headerText={<FormattedMessage id={'imaging.mri'} />}
          newButton={
            <DocumentCreationButton
              name="mri"
              text={'imaging.newmri'}
              onClick={startEdit({} as IMRI, 'mri')}
              width={15}
              height={3}
              fontSize={14}
              alternate={true}
            />
          }
        >
          <HistoryRowListing
            documents={documents || []}
            headers={
              <React.Fragment>
                <Item xs={2}>
                  <FormattedMessage id={'general.date'} />
                </Item>
                {showProcedureCodeHeader && (
                  <Item xs={2}>
                    <FormattedMessage id={'imaging.procedureCode'} />
                  </Item>
                )}
                {showStatementHeader && (
                  <Item xs={5}>
                    <FormattedMessage id={'imaging.statement'} />
                  </Item>
                )}
              </React.Fragment>
            }
            contentFormat={(d: IMRI): React.JSX.Element => (
              <React.Fragment>
                <Item xs={2} id={'mriDate'}>
                  {formatPartialDate(d.date)}
                </Item>
                <Item xs={2}>{showProcedureCode(d) ? d.procedureCode : ''}</Item>
                <Item xs={5} style={statementStyle}>
                  {showStatement(d) ? d.statement : ''}
                </Item>
                <Item xs={3}>
                  <HistoryRowControls document={d} startEdit={startEdit} />
                </Item>
              </React.Fragment>
            )}
          />
        </HistorySection>
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IStyledRow {
  title: string | React.JSX.Element;
  value?: any;
  condition?: boolean;
  id?: string;
}

interface ILesionsDataRow {
  rowTitle: string;
  document: IMRI;
  lesions: string;
}

interface IMriDataSection {
  document: IMRI;
}

interface IOwnProps {
  documents: IMRI[];
  startEdit: (document: IMRI, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default Mri;
