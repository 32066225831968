import { Item } from 'Components/Grid';
import * as React from 'react';
import HistoryRowVerticalItem from '../../../../../components/HistoryRowVerticalItem';
import { IHistoryContext, withHistoryContext } from '../../../../../containers/FormContextHandler';
import { StyledBarHeader, StyledHeader, StyledRowContainerBar } from '../../../utils/styled';

const DMDhistory = ({ document, fm = (): string => '' }: IOwnProps): React.JSX.Element => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      {document.dmdGeneticConfirmation != null && (
        <React.Fragment>
          <StyledHeader>{fm('diagnosis.dmdGeneticConfirmationTitle')}</StyledHeader>
          {document.dmdGeneticConfirmation != null && (
            <>
              <span id={'geneticConfirmation'} />
              <HistoryRowVerticalItem
                header={fm('diagnosis.dmdGeneticConfirmation')}
                value={document.dmdGeneticConfirmation === true ? fm('general.yes') : fm('general.no')}
              />
            </>
          )}
        </React.Fragment>
      )}
      {document.dmdGeneticConfirmation != null &&
        document.dmdGeneticConfirmation === true &&
        Object.keys(document).length > 9 && (
          <React.Fragment>
            <StyledRowContainerBar alignItems="center" style={{ marginBottom: '1rem' }}>
              <Item xs={12}>
                <StyledBarHeader>{fm('diagnosis.dmdGeneticConfirmationTitle')}</StyledBarHeader>
              </Item>
            </StyledRowContainerBar>
            <span id={'dmdMutationName'} />
            <HistoryRowVerticalItem
              header={fm('diagnosis.dmdMutationName')}
              value={![undefined, null, 'c.'].includes(document.dmdMutationName) ? document.dmdMutationName : '-'}
            />
            {document.dmdDeletionOfExons != null && (
              <>
                <span id={'exonsDeletion'} />
                <HistoryRowVerticalItem
                  header={fm('diagnosis.dmdDeletionOfExons')}
                  value={fm('diagnosis.opts.yesNoUnknown.' + document.dmdDeletionOfExons)}
                />
              </>
            )}
            {document.dmdDuplicationOfExons != null && (
              <>
                <span id={'exonsDuplication'} />
                <HistoryRowVerticalItem
                  header={fm('diagnosis.dmdDuplicationOfExons')}
                  value={fm('diagnosis.opts.yesNoUnknown.' + document.dmdDuplicationOfExons)}
                />
              </>
            )}
            {document.dmdBoundariesKnown != null && (
              <>
                <span id={'dmdBoundaries'} />
                <HistoryRowVerticalItem
                  header={fm('diagnosis.dmdBoundariesKnown')}
                  value={fm('diagnosis.opts.yesNoUnknown.' + document.dmdBoundariesKnown)}
                />
              </>
            )}
            {document.dmdExonsSequenced != null && (
              <>
                <span id={'exonsSequenced'} />
                <HistoryRowVerticalItem
                  header={fm('diagnosis.dmdExonsSequenced')}
                  value={fm('diagnosis.opts.yesNoUnknown.' + document.dmdExonsSequenced)}
                />
              </>
            )}
            {document.dmdExonsTestedInMaleRelative != null && (
              <>
                <span id={'exonsTestedMaleRelative'} />
                <HistoryRowVerticalItem
                  header={fm('diagnosis.dmdExonsTestedInMaleRelative')}
                  value={fm('diagnosis.opts.yesNoUnknown.' + document.dmdExonsTestedInMaleRelative)}
                />
              </>
            )}
          </React.Fragment>
        )}
    </div>
  );
};

interface IOwnProps extends IHistoryContext {
  document: IDiagnosis;
}

export default withHistoryContext(DMDhistory);
