import * as React from 'react';
import FormSection from '../../../../../components/FormSection';
import { FormattedMessage } from 'react-intl';
import InputHandler from '../../../../../components/InputHandler';
import FormRow from '../../../../../components/FormRow';

const optFormatter = (name: string | number): React.JSX.Element => (
  <FormattedMessage id={`motorFunctionAndMobilityAid.aids.opts.${name}`} />
);

const AidForm = ({ formData }: IOwnProps): React.JSX.Element => {
  return (
    <FormSection>
      <FormRow title={'motorFunctionAndMobilityAid.aids.aidType'}>
        <InputHandler
          type="Select"
          editing={true}
          name="aidType"
          formData={formData}
          options={['wheelChair', 'walkingStand', 'crutches']}
          optionFormatter={optFormatter}
          placeholder={'motorFunctionAndMobilityAid.aids.aidTypePlaceholder'}
        />
      </FormRow>
      <FormRow title={'motorFunctionAndMobilityAid.aids.useStartDate'}>
        <InputHandler
          type="PartialDate"
          editing={true}
          name="startDate"
          formData={formData}
          dateDefault={'now'}
          isNotCancellable={true}
          dateHook={{ dateHookCeiling: formData.document?.endDate }}
        />
      </FormRow>
      <FormRow title={'motorFunctionAndMobilityAid.aids.useEndDate'}>
        <InputHandler
          type="PartialDate"
          editing={true}
          name="endDate"
          formData={formData}
          dateHook={{ dateHookFloor: formData.document?.startDate }}
        />
      </FormRow>
      <FormRow title={'motorFunctionAndMobilityAid.aids.use'}>
        <InputHandler
          type="Select"
          editing={true}
          name="use"
          formData={formData}
          options={['partTime', 'fullTime']}
          optionFormatter={optFormatter}
          placeholder={'motorFunctionAndMobilityAid.aids.usePlaceholder'}
        />
      </FormRow>
      <FormRow
        title={'motorFunctionAndMobilityAid.aids.wheelChairType'}
        condition={formData.document?.aidType === 'wheelChair'}
      >
        <InputHandler
          type="Select"
          editing={true}
          name="type"
          formData={formData}
          options={['manual', 'electric', 'manualElectric']}
          optionFormatter={optFormatter}
          placeholder={'motorFunctionAndMobilityAid.aids.wheelChairTypePlaceholder'}
        />
      </FormRow>
    </FormSection>
  );
};

interface IOwnProps {
  formData: IFormData<IAid>;
}

export default AidForm;
