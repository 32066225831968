import { isNil } from 'Utility/ramdaReplacement';
import * as React from 'react';
import { OperationContext } from '../../config';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';

const optionFormatter = (s: string | number, p: string, fm?: (id: string) => string): string =>
  fm ? fm(`surgicalTreatment.lesionectomy.${p}.${s}`) : s.toString();

const stepLabelText = (d: ILesionectomyLocation, fm?: (id: string) => string): string =>
  fm
    ? (d.location
        ? fm(`surgicalTreatment.lesionectomy.locations.location.opts.${d.location}`) +
          (d.sideOfRemoval || d.areaOfRemoval ? ', ' : '')
        : '') +
      (d.sideOfRemoval
        ? fm(`surgicalTreatment.lesionectomy.locations.sideOfRemoval.headerOpts.${d.sideOfRemoval}`) + ' '
        : '') +
      (d.areaOfRemoval
        ? fm(`surgicalTreatment.lesionectomy.locations.areaOfRemoval.headerOpts.${d.areaOfRemoval}`)
        : '')
    : '';

const StepContent = ({ d, fm }: { d: ILesionectomyLocation; fm?: (id: string) => string }): React.JSX.Element => {
  const path = 'surgicalTreatment.lesionectomy.locations';
  return (
    <React.Fragment>
      {['location'].map(
        (f) =>
          !!d[f as keyof ILesionectomyLocation] && (
            <StepperHeaderValuePair
              header={<>{fm ? fm(`${path}.${f}.title`) : ''}</>}
              value={fm ? fm(`${path}.${f}.opts.${d[f as keyof ILesionectomyLocation]}`) : '-'}
              key={f}
            />
          ),
      )}
      {d.location && d.location === 'other' ? (
        <StepperHeaderValuePair
          header={<>{fm ? fm(`surgicalTreatment.lesionectomy.locations.location.other`) : ''}</>}
          value={d.otherInfo}
        />
      ) : null}
      {['sideOfRemoval', 'areaOfRemoval'].map(
        (f) =>
          !!d[f as keyof ILesionectomyLocation] && (
            <StepperHeaderValuePair
              header={<>{fm ? fm(`${path}.${f}.title`) : ''}</>}
              value={fm ? fm(`${path}.${f}.opts.${d[f as keyof ILesionectomyLocation]}`) : '-'}
              key={f}
            />
          ),
      )}
    </React.Fragment>
  );
};

const EditingElement = ({
  formData,
  editing,
  fm,
}: {
  formData: IFormData<ILesionectomyLocation>;
  editing: boolean;
  fm?: (id: string) => string;
}): React.JSX.Element => {
  const location = formData.document?.location;

  const path = 'surgicalTreatment.lesionectomy.locations';

  return (
    <React.Fragment>
      <FormRow title={`${path}.location.title`}>
        <InputHandler
          type="Select"
          name="location"
          editing={editing}
          formData={formData}
          options={[
            'forebrainBlockArea',
            'crownBlockArea',
            'backOfTheSkullArea',
            'temporalBlockArea',
            'insularSulcus',
            'cingulateSulcus',
            'amygdala',
            'hippocampus',
            'other',
          ]}
          optionFormatter={(id: string | number): string => optionFormatter(id, 'locations.location.opts', fm)}
          placeholder={`${path}.location.placeholder`}
          dependentFieldsList={(): string[] => ['otherInfo']}
        />
      </FormRow>

      {location === 'other' ? (
        <FormRow title={`${path}.location.other`}>
          <InputHandler
            type="TextArea"
            name="otherInfo"
            editing={editing}
            formData={formData}
            placeholder={`${path}.location.otherPlaceholder`}
          />
        </FormRow>
      ) : undefined}

      {location ? (
        <React.Fragment>
          <FormRow title={`${path}.sideOfRemoval.title`}>
            <InputHandler
              type="Radio"
              name="sideOfRemoval"
              editing={editing}
              formData={formData}
              options={['left', 'right']}
              optionFormatter={(id: string | number): string => optionFormatter(id, 'locations.sideOfRemoval.opts', fm)}
              placeholder={`${path}.sideOfRemoval.placeholder`}
            />
          </FormRow>
          <FormRow title={`${path}.areaOfRemoval.title`}>
            <InputHandler
              type="Radio"
              name="areaOfRemoval"
              editing={editing}
              formData={formData}
              options={['partial', 'full']}
              optionFormatter={(id: string | number): string => optionFormatter(id, 'locations.areaOfRemoval.opts', fm)}
              placeholder={`${path}.areaOfRemoval.placeholder`}
            />
          </FormRow>
        </React.Fragment>
      ) : undefined}
    </React.Fragment>
  );
};

const Lesionectomy = ({ formData, editing, view, fm }: IFormContext): React.JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const { document, onChange } = formData as IFormData<ISurgicalTreatment>;

  const path = 'surgicalTreatment.lesionectomy.locations';

  return (
    <React.Fragment>
      <OperationContext.Consumer>
        {({ operationIndex }): React.JSX.Element => {
          const allOperations = document.operations?.map((o) => o) || [];
          const lesionectomy = allOperations?.[operationIndex ?? 0] as ILesionectomy;
          const locations = lesionectomy.locations;

          const lesionectomyOnChange = (values: TOnChangeValues): void => {
            const value = values['locations'];
            const newLocations = value;
            const changedOperation = { ...lesionectomy, locations: newLocations };
            allOperations[operationIndex ?? 0] = changedOperation;
            onChange && onChange({ operations: allOperations });
          };

          return (
            <EventStepper
              name="locations"
              formData={{
                document: { locations: locations },
                onChange: lesionectomyOnChange,
              }}
              stepLabelText={(d: ILesionectomyLocation): string => stepLabelText(d, fm)}
              stepContent={(d: ILesionectomyLocation): React.JSX.Element => <StepContent d={d} fm={fm} />}
              addNewTextHeader={`${path}.title`}
              addNewTextButton={`${path}.add`}
              previousEventsTextHeader={`${path}.previous`}
              editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => {
                return (
                  <React.Fragment>
                    {formData && !isNil(operationIndex) ? (
                      <EditingElement
                        formData={
                          { document: locations?.[index], onChange: onChange } as {
                            document: ILesionectomyLocation;
                            onChange: IFormData['onChange'];
                          }
                        }
                        editing={editingDoc}
                        fm={fm}
                      />
                    ) : undefined}
                  </React.Fragment>
                );
              }}
            />
          );
        }}
      </OperationContext.Consumer>
    </React.Fragment>
  );
};

export default withFormContext(Lesionectomy);
