import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';

const VEPForm = ({ formData }: IOwnProps): React.JSX.Element => (
  <FormRow title="general.date">
    <InputHandler
      type="PartialDate"
      editing={true}
      name="date"
      formData={formData}
      dateDefault="now"
      isNotCancellable={true}
    />
  </FormRow>
);

interface IOwnProps {
  formData: IFormData<IVEP>;
}

export default VEPForm;
