/*
  Use these components to format diagnosis elements to the same between forms/diagnoses/platforms
*/

import * as React from 'react';

import FormRow from '../../../../components/FormRow';
import colors from '../../../../config/theme/colors';

export const InputlessFormRow = ({
  title,
  element,
  boldValue = false,
  bottomMargin = true,
  darkerTitle = false,
}: {
  title?: string;
  element?: React.JSX.Element;
  boldValue?: boolean;
  bottomMargin?: boolean;
  darkerTitle?: boolean;
}): React.JSX.Element => (
  <FormRow
    title={title}
    bottomMargin={bottomMargin}
    headerStyle={{ color: darkerTitle ? colors.primaryText : undefined }}
  >
    <div style={{ fontWeight: boldValue ? 600 : undefined }}>{element}</div>
  </FormRow>
);
