import * as React from 'react';

import Form from './Form';
import HistoryRowData from './HistoryRowData';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryRow from '../../../components/HistoryRow';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import HistoryRowControls from '../../../components/HistoryRowControls';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../components/DocumentCreationButton';

const GeneTest = ({ documents, name }: IOwnProps): React.JSX.Element => {
  return (
    <FormEditingHandler name="geneTest" documents={documents}>
      {(editing, startEdit, formData): React.JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="geneTest"
            headerId={'geneTest.title'}
            editing={editing}
            editButtons={
              <div>
                <DocumentCreationButton name={name} text={'general.new'} onClick={startEdit({})} />
              </div>
            }
          />
          {!editing && documents
            ? documents
                .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
                .reverse()
                .map((d: IGeneTest) => (
                  <HistoryRow
                    headerText={d.date ? formatPartialDate(d.date) : undefined}
                    key={d._id}
                    rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                    controlsMargin={false}
                  >
                    <HistoryRowData d={d} />
                  </HistoryRow>
                ))
            : undefined}
          {editing ? <Form formData={formData} editing={editing} /> : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents?: IGeneTest[];
  name: string;
}

export default GeneTest;
