import * as React from 'react';
import FormRow from '../../../../../../../components/FormRow';
import InputHandler from '../../../../../../../components/InputHandler';
import MedicationUnit from 'Routes/Medication/utils/medicationUnit';

const unitOptions = [
  'mg',
  'tabletti',
  'ml',
  'µg',
  'g',
  'mg/min',
  'mg/h',
  'µg/h',
  'ml/min',
  'ml/h',
  'gtt',
  'gtt/min',
  'gtt/h',
  'U',
  'IU',
  'MIU',
  'IU/min',
  'IU/h',
  'mmol',
  'kpl',
  'kapseli',
  'ampulla',
  'suppo',
  'peräruiske',
  'emätinpuikko',
  'laastari',
  'pss',
  'annos',
];

const UnitSelect = ({ unitValue, onChange }: IUnitSelect): React.JSX.Element => (
  <FormRow title="medication.doses.unit" headerWidth={3}>
    <InputHandler
      type="Select"
      name="unit"
      editing={true}
      width={19}
      formData={{
        document: { unit: unitValue || '' },
        onChange: onChange,
      }}
      options={unitOptions}
      optionFormatter={(o: string | number): string | React.JSX.Element => <MedicationUnit unit={`${o}`} />}
      placeholder={'medication.chooseUnit'}
    />
  </FormRow>
);

interface IUnitSelect {
  unitValue?: string;
  onChange: IFormData['onChange'];
}

export default UnitSelect;
