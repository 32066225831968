import { Collapse } from '@mui/material';
import { Container, Item } from 'Components/Grid';
import { equals } from 'ramda';
import * as React from 'react';
import { styled } from '@mui/system';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import FormSectionBar from '../../../../../components/FormSectionBar';
import FormSectionHistoryAcceptor from '../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { PPAFormContent as sectionData } from '../../../utils/definitions';
import { fm } from 'Components/FormatMessage';
import { generalCriteriaFulfilled, resultPicker } from '../../../utils/PPA';

import { ReferenceText } from '../../../utils/styled';
import { path } from 'Utility/ramdaReplacement';

const SubHeader = styled('div')({
  margin: '1rem 0 2rem 0',
});

const MakeFormRowCounter = ({
  title,
  description,
  content,
  headerWidth,
}: {
  title: string;
  description?: React.JSX.Element;
  content: React.JSX.Element;
  headerWidth?: number;
}): React.JSX.Element => {
  return (
    <FormRow
      title={title}
      description={description}
      headerWidth={headerWidth ? headerWidth : 6}
      headerStyle={{ fontWeight: 400, color: colors.primaryText }}
    >
      {content}
    </FormRow>
  );
};

const MakeFormRow = ({
  title,
  description,
  section,
  name,
  formData,
  editing,
  viewing,
  bottomMargin = true,
}: {
  title: string;
  description?: React.JSX.Element;
  section: string;
  name: string;
  formData: IFormData<IPPA>;
  editing: boolean;
  viewing: boolean;
  bottomMargin?: boolean;
}): React.JSX.Element => (
  <FormRow title={title} description={description} headerWidth={6} bottomMargin={bottomMargin}>
    {editing ? (
      <InputHandler
        editing={!viewing}
        formData={{
          onChange: formData?.onChange,
          document: {
            [section.split('.')[0]]: {
              [section.split('.')[1]]: {
                [name]: path([section.split('.')[0], section.split('.')[1], name], formData?.document),
              },
            },
          },
        }}
        name={`${section}.${name}`}
        type="RadioButtonRow"
        preset="yesNoUnknown"
        height={3.5}
        width={9}
      />
    ) : (
      <span style={{ fontWeight: viewing ? undefined : 600 }}>
        {fm(
          `diagnosis.opts.yesNoUnknown.${
            name === 'criteriaForClinicalDiagnosisForNfvPPAFulfilled'
              ? resultPicker(formData?.document, 'nonfluentVariantPPA', 'clinicalDiagnosis')
              : name === 'criteriaForClinicalDiagnosisForSemanticDementiaFulfilled'
                ? resultPicker(formData?.document, 'semanticDementia', 'clinicalDiagnosis')
                : name === 'criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled'
                  ? resultPicker(formData?.document, 'logopenicProgressiveAphasia', 'clinicalDiagnosis')
                  : 'unknown'
          }`,
        )}
      </span>
    )}
  </FormRow>
);

const setAllUnknown = (formData: IFormContext['formData'], viewing: boolean): void => {
  if (!viewing) {
    Object.keys(sectionData).forEach((section: string) => {
      Object.keys(sectionData[section]).forEach((subSection: string) => {
        Object.keys(sectionData[section][subSection])
          .filter((key) => ['criteria', 'criteria2'].includes(key))
          .forEach((key: string) => {
            (sectionData[section][subSection][key] as string[]).forEach((key) => {
              const document = formData?.document as { [key: string]: any };
              if (
                (!document[section] || !document[section][subSection] || !document[section][subSection][key]) &&
                ![
                  'criteriaForClinicalDiagnosisForNfvPPAFulfilled',
                  'criteriaForClinicalDiagnosisForSemanticDementiaFulfilled',
                  'criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled',
                ].includes(key)
              ) {
                if (!equals(path([section, subSection, key], document), 'unknown')) {
                  formData?.onChange?.({
                    [section]: {
                      ...((path([section], document) as TAnyObject) ?? {}),
                      [subSection]: {
                        ...((path([section, subSection], document) as TAnyObject) ?? {}),
                        [key]: 'unknown',
                      },
                    },
                  });
                }
              }
            });
          });
      });
    });
  }
};

const PPAForm = ({ documents, formData, editing, view }: IFormContext<IPPA>): React.JSX.Element => {
  const [sectionOpen, setSectionOpen] = React.useState<{ [key: string]: boolean }>({
    nonfluentVariantPPA: true,
    semanticDementia: true,
    logopenicProgressiveAphasia: true,
  });

  const isViewing = !editing && !!view?.viewing;
  const docID = editing ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  const toggleSection = (section: string): void => {
    const sectionState = Object.assign({}, sectionOpen);
    sectionState[section] = !sectionState[section];
    setSectionOpen(sectionState);
  };

  return (
    <React.Fragment>
      {formData ? (
        <React.Fragment>
          <FormRow title="general.date">
            <InputHandler
              type="PartialDate"
              editing={!isViewing}
              name="date"
              formData={formData}
              dateDefault="now"
              isNotCancellable={true}
            />
          </FormRow>

          {Object.keys(sectionData).map((section, index): React.JSX.Element => {
            const sectionContent = Object.keys(sectionData[section])
              .filter((subSection) => subSection !== 'summary')
              .map((subSection): React.JSX.Element => {
                const subHeaders = ['subHeader', 'subHeader2'].map((key: string) =>
                  sectionData[section][subSection][key]
                    ? {
                        [key]: {
                          header: (
                            <SubHeader>
                              {fm(`diagnosis.ppa.${section}.${subSection}.${sectionData[section][subSection][key]}`)}
                            </SubHeader>
                          ),
                        },
                      }
                    : {},
                );
                const criteria = ['criteria', 'criteria2'].map((k: string) =>
                  sectionData[section][subSection][k]
                    ? (sectionData[section][subSection][k] as string[])?.reduce(
                        (current, key) => {
                          current[key] = {
                            element: (
                              <MakeFormRow
                                title={`diagnosis.ppa.${section}.${subSection}.${key}`}
                                section={`${section}.${subSection}`}
                                name={key}
                                formData={formData}
                                editing={
                                  [
                                    'criteriaForClinicalDiagnosisForNfvPPAFulfilled',
                                    'criteriaForClinicalDiagnosisForSemanticDementiaFulfilled',
                                    'criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled',
                                  ].includes(key)
                                    ? false
                                    : true
                                }
                                viewing={isViewing}
                                description={
                                  [
                                    'criteriaForClinicalDiagnosisForNfvPPAFulfilled',
                                    'criteriaForClinicalDiagnosisForSemanticDementiaFulfilled',
                                    'criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled',
                                  ].includes(key)
                                    ? fm(`diagnosis.ppa.autoFillDescription`)
                                    : undefined
                                }
                              />
                            ),
                          };
                          return current;
                        },
                        {} as { [key: string]: TAnyObject },
                      )
                    : {},
                );
                const results = ['result', 'result2'].map((k: string) =>
                  sectionData[section][subSection][k]
                    ? (sectionData[section][subSection][k] as string[])?.reduce(
                        (current, key) => {
                          current[key] = {
                            element: (
                              <MakeFormRowCounter
                                title={`diagnosis.ppa.${section}.${subSection}.${key}`}
                                content={
                                  <div style={{ fontWeight: 600, marginBottom: '4.5rem' }}>
                                    {fm(
                                      `diagnosis.opts.yesNoUnknown.${resultPicker(
                                        formData.document,
                                        section,
                                        subSection,
                                        key,
                                      )}`,
                                    )}
                                  </div>
                                }
                              />
                            ),
                          };
                          return current;
                        },
                        {} as { [key: string]: TAnyObject },
                      )
                    : {},
                );

                const structure = [subHeaders[0], criteria[0], subHeaders[1], criteria[1], results[0]];

                const subSectionContent = Object.assign({}, ...structure);

                return (
                  <React.Fragment key={section + subSection}>
                    <FormSectionHistoryAcceptor
                      name={`${section}.${subSection}`}
                      formData={formData}
                      documentID={docID}
                      documents={documents}
                      optionFormatter={(s: string): React.JSX.Element => fm(`general.${s}`)}
                      header={`diagnosis.ppa.${section}.${subSection}.title`}
                      hideCopyButton={isViewing}
                    >
                      {subSectionContent}
                    </FormSectionHistoryAcceptor>
                  </React.Fragment>
                );
              });
            return (
              <React.Fragment key={section}>
                <div style={{ cursor: index > 0 ? 'pointer' : undefined }} onClick={(): void => toggleSection(section)}>
                  <FormSectionBar header={`diagnosis.ppa.${section}.title`} open={sectionOpen[section]} />
                </div>
                {index > 0 ? (
                  <Collapse in={sectionOpen[section]} collapsedSize={'2rem'}>
                    {sectionContent}
                  </Collapse>
                ) : (
                  sectionContent
                )}
              </React.Fragment>
            );
          })}

          <FormSection header={'diagnosis.ppa.logopenicProgressiveAphasia.summary.title'}>
            {Object.keys(sectionData.logopenicProgressiveAphasia.summary).map((key) => {
              return (
                <React.Fragment key={key}>
                  {typeof sectionData.logopenicProgressiveAphasia.summary[key] === 'string' ? (
                    <SubHeader style={{ fontWeight: 600 }}>
                      {fm(`diagnosis.ppa.logopenicProgressiveAphasia.summary.${key}`)}
                    </SubHeader>
                  ) : (
                    <React.Fragment>
                      {(sectionData.logopenicProgressiveAphasia.summary[key] as string[]).map((k) => {
                        return (
                          <MakeFormRowCounter
                            key={k}
                            title={`diagnosis.ppa.logopenicProgressiveAphasia.summary.${k}`}
                            content={
                              <div style={{ fontWeight: 600 }}>
                                {fm(
                                  `diagnosis.opts.yesNoUnknown.${resultPicker(
                                    formData.document,
                                    'logopenicProgressiveAphasia',
                                    'summary',
                                    k,
                                  )}`,
                                )}
                              </div>
                            }
                            headerWidth={5}
                          />
                        );
                      })}
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            })}
          </FormSection>
          {!['result', 'result2', 'result3'].some((key) => {
            return (
              (sectionData.logopenicProgressiveAphasia.summary[key] as string[]).filter(
                (k: string) => resultPicker(formData.document, 'logopenicProgressiveAphasia', 'summary', k) === 'yes',
              ).length > 0
            );
          }) && (
            <FormRow
              title={'diagnosis.ppa.logopenicProgressiveAphasia.summary.generalCriteriaForPPAFulfilled'}
              headerWidth={5}
              headerStyle={{ fontWeight: 600, color: colors.primaryText, margin: '2rem 0 2rem 0' }}
            >
              <div style={{ fontWeight: 600, margin: '2rem 0 2rem 0' }}>
                {fm(`diagnosis.opts.yesNoUnknown.${generalCriteriaFulfilled(formData.document)}`)}
              </div>
            </FormRow>
          )}

          <Container>
            <Item style={{ padding: '2rem 0' }}>
              <ReferenceText>{fm('diagnosis.ppa.referenceText')}</ReferenceText>
            </Item>
          </Container>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default withFormContext(PPAForm);
