import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../components/FormRow';
import InputHandler from '../../../../components/InputHandler';

interface IAppointmentFormProps {
  data: Partial<IAppointment> | null;
  changeData: (values: TOnChangeValues) => void;
}

const AppointmentForm = ({ data, changeData }: IAppointmentFormProps): React.JSX.Element => {
  return (
    <>
      <FormRow title="appointments.startDate">
        <InputHandler
          type="PartialDate"
          name="startDate"
          editing={true}
          formData={{
            onChange: changeData,
            document: { startDate: data?.startDate },
          }}
        />
      </FormRow>
      <FormRow title="appointments.startTime">
        <InputHandler
          type="TimePicker"
          name="startTime"
          editing={true}
          formData={{
            onChange: changeData,
            document: { startTime: data?.startTime },
          }}
        />
      </FormRow>
      <FormRow title="appointments.status">
        <InputHandler
          type="Select"
          name="status"
          editing={true}
          formData={{
            onChange: changeData,
            document: { status: data?.status },
          }}
          options={['booked', 'canceled', 'no-show', 'completed']}
          optionFormatter={(o): React.JSX.Element => <FormattedMessage id={`appointments.opts.${o}`} />}
          placeholder="appointments.statusPlaceholder"
        />
      </FormRow>
      <FormRow title="appointments.externaAppointmentType">
        <InputHandler
          type="TextField"
          name="externaAppointmentType"
          editing={true}
          formData={{
            onChange: changeData,
            document: { externaAppointmentType: data?.externaAppointmentType },
          }}
          placeholder="appointments.externaAppointmentTypePlaceholder"
        />
      </FormRow>
      <FormRow title="appointments.endDate">
        <InputHandler
          type="PartialDate"
          name="endDate"
          editing={true}
          formData={{
            onChange: changeData,
            document: { endDate: data?.endDate },
          }}
        />
      </FormRow>
      <FormRow title="appointments.endTime">
        <InputHandler
          type="TimePicker"
          name="endTime"
          editing={true}
          formData={{
            onChange: changeData,
            document: { endTime: data?.endTime },
          }}
        />
      </FormRow>
      <FormRow title="appointments.comments">
        <InputHandler
          type="TextArea"
          name="comments"
          editing={true}
          formData={{
            onChange: changeData,
            document: { comments: data?.comments },
          }}
          placeholder="appointments.commentsPlaceholder"
        />
      </FormRow>
    </>
  );
};

export default AppointmentForm;
