import * as React from 'react';
import ActionButton from '../../../../components/ActionButton';
import { fm } from 'Components/FormatMessage';
import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import FormSectionBar from '../../../../components/FormSectionBar';
import InputHandler from '../../../../components/InputHandler';
import { isPartialDate } from 'neuro-utils';
import { discontinuationHandler, getLatestDiscontinuation } from '../../utils';
import { leadsWithOneConnector, leadsWithTwoConnectors } from '../config';
import AdverseEffectEvents from './AdverseEffectEvents';
import DiscontinuationEvents from './DiscontinuationEvents';
import GeneratorEvents from './GeneratorEvents';
import ModeActivationEvents from './ModeActivationEvents';
import SettingEvents from './SettingEvents';
import TechnicalIssueEvents from './TechnicalIssueEvents';
import PostoperativeComplicationEvents from './PostoperativeComplicationEvents';
import { isEmpty, isNil, path } from 'Utility/ramdaReplacement';

const VNSForm = ({ editing, formData, anchor }: IOwnProps): React.JSX.Element => {
  const isMountRender = React.useRef<boolean>(true);
  const [continuationDate, setContinuationDate] = React.useState<PartialDate | undefined>(undefined);
  const { onChange } = formData;
  const status = formData.document.status;
  const hasEnded: boolean = path(['hasEnded', 0], formData.document) ?? false;
  const discontinuations = formData.document.discontinuations;
  const generators = formData.document.generators;

  React.useEffect(() => {
    if (onChange && Array.isArray(generators) && generators.length > 0) {
      discontinuationHandler(onChange, discontinuations, generators, status ?? '', hasEnded);
    }
  }, [onChange, discontinuations, generators, status, hasEnded]);

  // Ref for scrolling to specific part of the form
  const ref = React.createRef() as React.RefObject<HTMLDivElement>;

  React.useEffect(() => {
    // When form is opened, scroll to ref if anchor is set
    if (isMountRender.current === true && anchor && ref.current && !isEmpty(formData.document)) {
      isMountRender.current = false;
      scroll({ top: ref.current.getBoundingClientRect().top - 75, behavior: 'smooth' });
    }
  }, [anchor, formData.document, ref]);

  const handleContinuationDateChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (isPartialDate(value)) {
      setContinuationDate(value as PartialDate);
    }
  };

  const handleContinuation = (): void => {
    const newDiscontinuations = JSON.parse(JSON.stringify(formData.document.discontinuations));
    const targetIndex = newDiscontinuations.indexOf(getLatestDiscontinuation(newDiscontinuations));
    if (
      newDiscontinuations[targetIndex]['type'] === 'generatorSwitchedOff' &&
      (continuationDate || getLatestDiscontinuation(newDiscontinuations)?.date)
    ) {
      newDiscontinuations[targetIndex] = {
        ...newDiscontinuations[targetIndex],
        endDate: continuationDate ?? getLatestDiscontinuation(newDiscontinuations)?.date,
      };
      formData.onChange?.({ discontinuations: newDiscontinuations, status: 'inProgress' });
      setContinuationDate(undefined);
    }
  };

  return (
    <React.Fragment>
      {formData.document.status === 'suspended' &&
        getLatestDiscontinuation(formData.document.discontinuations) &&
        (getLatestDiscontinuation(formData.document.discontinuations)?.type === 'generatorSwitchedOff' ? (
          <FormSection>
            <FormRow title="vns.continueTreatment" description={fm('vns.continueTreatmentDescription')}>
              <InputHandler
                type="PartialDate"
                formData={{
                  onChange: handleContinuationDateChange,
                  document: {},
                }}
                editing={!!editing}
                name="endDate"
                dateDefault={getLatestDiscontinuation(formData.document.discontinuations)?.date}
                isNotCancellable={true}
                dateHook={{ dateHookFloor: getLatestDiscontinuation(formData.document.discontinuations)?.date }}
              />
            </FormRow>
            <FormRow>
              <ActionButton
                text="vns.continueTreatment"
                width={20}
                height={5}
                fontSize={18}
                onClick={handleContinuation}
              />
            </FormRow>
          </FormSection>
        ) : [undefined, 'generatorRemoved', 'generatorAndPartOfLeadRemoved', 'generatorAndLeadRemoved'].includes(
            getLatestDiscontinuation(formData.document.discontinuations)?.type,
          ) ? (
          <FormSection>
            <FormRow title="vns.continueTreatment">
              <div style={{ whiteSpace: 'pre-line' }}>
                {!getLatestDiscontinuation(formData.document.discontinuations)?.type
                  ? fm('vns.continueTreatmentUndefined')
                  : fm('vns.continueTreatmentAfterGeneratorRemoval')}
              </div>
            </FormRow>
          </FormSection>
        ) : null)}
      <FormSection>
        <FormRow title="vns.started">
          <InputHandler
            type="PartialDate"
            formData={formData}
            editing={!!editing}
            name="date"
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title="vns.electrodeMountingLocation">
          <InputHandler
            type="Select"
            formData={formData}
            editing={isNil(formData.document?.generators) || isEmpty(formData.document?.generators)}
            name="electrodeMountingLocation"
            options={['leftVagusNerve', 'rightVagusNerve', 'unknown']}
            placeholder="vns.chooseMountingLocation"
            optionFormatter={(name: string | number): React.JSX.Element =>
              fm(`vns.opts.electrodeMountingLocation.${name}`)
            }
          />
        </FormRow>
        <FormRow title="vns.leadType">
          <InputHandler
            type="SelectByCategory"
            formData={formData}
            editing={isNil(formData.document?.generators) || isEmpty(formData.document?.generators)}
            name="leadType"
            options={[
              { title: 'vns.leadsWithOneConnector', values: leadsWithOneConnector },
              { title: 'vns.leadsWithTwoConnectors', values: leadsWithTwoConnectors },
            ]}
            optionFormatter={(name: string | number) =>
              ['unspecifiedLeadWithOneConnector', 'unspecifiedLeadWithTwoConnectors'].includes(name.toString())
                ? fm(`vns.opts.leadType.${name}`)
                : name.toString()
            }
            placeholder="vns.chooseLeadType"
            fullWidth={true}
            formatTitle={true}
          />
        </FormRow>
        <FormRow title="vns.leadSerialNo">
          <InputHandler
            type="TextField"
            editing={!!editing}
            name="leadSerialNo"
            formData={formData}
            placeholder="vns.enterSerialNo"
          />
        </FormRow>
        <FormRow title="vns.implantationSite">
          <InputHandler
            type="Select"
            editing={!!editing}
            name="implantationSite"
            options={['HYKS', 'TYKS', 'KYS', 'TAYS', 'OYS']}
            formData={formData}
            placeholder="vns.chooseImplantationSite"
          />
        </FormRow>
      </FormSection>
      <FormSectionBar header="vns.generator" />
      <FormSection headerRef={anchor === 'generators' ? ref : undefined}>
        <GeneratorEvents
          formData={formData}
          disabled={!(formData.document?.electrodeMountingLocation && formData.document?.leadType)}
        />
      </FormSection>
      <FormSectionBar header="vns.settings" />
      <FormSection headerRef={anchor === 'settings' ? ref : undefined}>
        <SettingEvents
          formData={formData}
          disabled={!(Array.isArray(formData.document?.generators) && formData.document.generators?.length > 0)}
        />
      </FormSection>
      <FormSectionBar header="vns.magnetModeAndAutoStimModeActivations" />
      <FormSection>
        <ModeActivationEvents formData={formData} name="magnetModeActivation" />
        <ModeActivationEvents formData={formData} name="autoStimModeActivation" />
      </FormSection>
      <FormSectionBar header="vns.events" />
      <FormSection
        header="vns.postoperativeComplications"
        headerRef={anchor === 'postoperativeComplications' ? ref : undefined}
      >
        <PostoperativeComplicationEvents
          formData={formData}
          disabled={!(Array.isArray(formData.document?.generators) && formData.document.generators?.length > 0)}
        />
      </FormSection>
      <FormSection header="vns.adverseEffectEvents" headerRef={anchor === 'adverseEffectEvents' ? ref : undefined}>
        <AdverseEffectEvents
          formData={formData}
          disabled={!(Array.isArray(formData.document?.generators) && formData.document.generators?.length > 0)}
        />
      </FormSection>
      <FormSection header="vns.technicalIssues" headerRef={anchor === 'technicalIssues' ? ref : undefined}>
        <TechnicalIssueEvents
          formData={formData}
          disabled={!(Array.isArray(formData.document?.generators) && formData.document.generators?.length > 0)}
        />
      </FormSection>
      <FormSection header="vns.discontinuation" headerRef={anchor === 'discontinuations' ? ref : undefined}>
        <DiscontinuationEvents
          formData={formData}
          disabled={!(Array.isArray(formData.document?.generators) && formData.document.generators?.length > 0)}
        />
      </FormSection>
      <FormSection header="vns.ending" headerRef={anchor === 'ending' ? ref : undefined}>
        <FormRow title="vns.ending">
          <InputHandler
            type="Checkbox"
            editing={!!editing}
            name="hasEnded"
            formData={{
              onChange: (): void => {
                if (path(['hasEnded', 0], formData.document) === true) {
                  formData.onChange?.({ hasEnded: [] });
                } else {
                  formData.onChange?.({ hasEnded: [true] });
                }
              },
              document: {
                hasEnded: formData.document.hasEnded,
              },
            }}
            options={['true']}
            optionFormatter={(name: string | number): React.JSX.Element => fm(`vns.${name === 'true' ? 'end' : ''}`)}
          />
        </FormRow>
        {path(['hasEnded', 0], formData.document) === true && (
          <React.Fragment>
            <FormRow title="vns.endDate">
              <InputHandler
                type="PartialDate"
                formData={formData}
                editing={!!editing}
                name="endDate"
                dateDefault="now"
                isNotCancellable={false}
              />
            </FormRow>
            <FormRow title="vns.endReason">
              <InputHandler
                type="Radio"
                formData={formData}
                editing={!!editing}
                name="endReason"
                options={['noClinicallyEffectiveResponseToTreatment', 'other']}
                optionFormatter={(name: string | number): React.JSX.Element => fm(`vns.opts.endReason.${name}`)}
              />
            </FormRow>
            <FormRow title="vns.endAdditionalInformation">
              <InputHandler
                type="TextArea"
                formData={formData}
                editing={!!editing}
                name="endAdditionalInformation"
                placeholder="vns.additionalInformation"
              />
            </FormRow>
            <FormRow title="vns.removed">
              <InputHandler
                type="Radio"
                formData={formData}
                editing={!!editing}
                name="removed"
                options={['yes', 'no', 'unknown']}
                optionFormatter={(name: string | number): React.JSX.Element => fm(`vns.opts.yesNoUnknown.${name}`)}
              />
            </FormRow>
            {formData.document.removed === 'yes' && (
              <React.Fragment>
                <FormRow title="vns.removalDate">
                  <InputHandler
                    type="PartialDate"
                    formData={formData}
                    editing={!!editing}
                    name="removalDate"
                    dateDefault="now"
                    isNotCancellable={false}
                  />
                </FormRow>
                <FormRow title="vns.removalReason">
                  <InputHandler
                    type="Radio"
                    formData={formData}
                    editing={!!editing}
                    name="removalReason"
                    options={['infection', 'other']}
                    optionFormatter={(name: string | number): React.JSX.Element => fm(`vns.opts.removalReason.${name}`)}
                  />
                </FormRow>
                <FormRow title="vns.removalType">
                  <InputHandler
                    type="Radio"
                    formData={formData}
                    editing={!!editing}
                    name="removalType"
                    options={[
                      'generatorSwitchedOff',
                      'generatorRemoved',
                      'generatorAndPartOfLeadRemoved',
                      'generatorAndLeadRemoved',
                    ]}
                    optionFormatter={(name: string | number): React.JSX.Element => fm(`vns.opts.removalType.${name}`)}
                  />
                </FormRow>
                {formData.document?.removalType === 'generatorAndPartOfLeadRemoved' && (
                  <FormRow title="vns.lengthOfRemainingLead">
                    <InputHandler
                      type="Radio"
                      editing={true}
                      name="lengthOfRemainingLead"
                      formData={formData}
                      options={['<20mm', '>20mm', 'unknown']}
                      optionFormatter={(name: string | number): React.JSX.Element =>
                        fm(`vns.opts.lengthOfRemainingLead.${name}`)
                      }
                    />
                  </FormRow>
                )}
                <FormRow title="vns.removalAdditionalInformation">
                  <InputHandler
                    type="TextArea"
                    formData={formData}
                    editing={!!editing}
                    name="removalAdditionalInformation"
                    placeholder="vns.additionalInformation"
                  />
                </FormRow>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<IVNS>;
  anchor?: string;
}

export default VNSForm;
