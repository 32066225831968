import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistorySection from '../../../../components/HistorySection';

import { formatPartialDate } from 'neuro-utils';
import { getPatientAgeInMonthsWithOneDecimal } from '../../../../utility/patientInfo';
import { hfmsEScore, showPatientAgeInMonthsOnForm } from '../../utils';
import InfoPopover from '../../../../components/InfoPopover';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { Item } from '../../../../components/Grid';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const HFMSE = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'motor.hfmsE'} />}
      newButton={
        <DocumentCreationButton
          name="hfms_e"
          text={'motor.newHFMSE'}
          onClick={startEdit({}, 'hfms_e')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id={'general.date'} />
            </Item>
            <Item xs={6}>
              <FormattedMessage id={'motor.hfmsEScore'} />
            </Item>
          </>
        }
        contentFormat={(d: IHFMSE): React.JSX.Element => (
          <>
            <Item xs={2} id={'hfmseDate'}>
              {showPatientAgeInMonthsOnForm(d.date) ? (
                <FormattedMessage
                  id="motor.dateAndMonth"
                  values={{
                    date: formatPartialDate(d.date),
                    months: getPatientAgeInMonthsWithOneDecimal(d.date),
                  }}
                />
              ) : (
                formatPartialDate(d.date)
              )}
            </Item>
            <Item xs={6} id={'hfmseScore'}>
              {d.manualScore ? (
                d.manualScore
              ) : hfmsEScore(d) === 'notCounted' ? (
                <InfoPopover text="general.notFilled" color="primary" />
              ) : (
                hfmsEScore(d)
              )}
            </Item>
          </>
        )}
        makeControls
        historyRowControls={(d: IControlProps) => (
          <div id={'hfmseHistoryControl'}>
            <HistoryRowControls document={d} startEdit={startEdit} view={view} />
          </div>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IHFMSE[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default HFMSE;
