const SimpleList = ({
  title,
  listItems,
  listType = 'ul',
}: {
  title?: string;
  listItems: Array<string>;
  listType?: 'ol' | 'ul';
}) => {
  return (
    <div>
      {title}
      {listType === 'ul' ? (
        <ul>
          {listItems.map((item, i) => {
            return <li key={`${item}-${i}`}>{item}</li>;
          })}
        </ul>
      ) : (
        <ol>
          {listItems.map((item, i) => {
            return <li key={`${item}-${i}`}>{item}</li>;
          })}
        </ol>
      )}
    </div>
  );
};

export default SimpleList;
