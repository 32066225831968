import * as React from 'react';

import HistoryRowVerticalItem from '../../../../components/HistoryRowVerticalItem';
import { fm } from 'Components/FormatMessage';

const RelapseSymptomsItem = (relapseSymptoms?: Array<string>): React.JSX.Element => {
  return (
    <HistoryRowVerticalItem
      header={fm('relapse.symptoms.title')}
      value={
        relapseSymptoms?.length &&
        relapseSymptoms.map((item: string) => <div key={item}>{fm(`${'relapse.symptoms.opts.'}${item}`)}</div>)
      }
    />
  );
};

const RelapseSymptomLevelItem = (relapseSymptomLevels?: Array<string>): React.JSX.Element => {
  return (
    <HistoryRowVerticalItem
      header={fm('relapse.symptomLevel.title')}
      value={
        relapseSymptomLevels?.length &&
        relapseSymptomLevels.map((item: string) => <div key={item}>{fm(`${'relapse.symptomLevel.opts.'}${item}`)}</div>)
      }
    />
  );
};

const RelapseOriginOfInformationItem = (originOfInformation?: string): React.JSX.Element => {
  return (
    <HistoryRowVerticalItem
      header={fm('relapse.originOfInformation.title')}
      value={originOfInformation && fm('relapse.originOfInformation.opts.' + originOfInformation)}
    />
  );
};

const RelapseCortisonItem = (wasCortisoneGiven?: TYesNoUnknown): React.JSX.Element => (
  <HistoryRowVerticalItem
    header={fm('relapse.wasCortisoneGiven')}
    value={wasCortisoneGiven && fm('relapse.opts.yesNoUnknown.' + wasCortisoneGiven)}
  />
);

const RelapseCortisonRecordedItem = (cortisoneMedicationRecorded?: TYesNoUnknown): React.JSX.Element => (
  <HistoryRowVerticalItem
    header={fm('relapse.cortisoneMedicationRecorded')}
    value={cortisoneMedicationRecorded && fm('relapse.opts.yesNoUnknown.' + cortisoneMedicationRecorded)}
  />
);

const RelapsePlasmaItem = (wasPlasmaGiven?: TYesNoUnknown): React.JSX.Element => (
  <HistoryRowVerticalItem
    header={fm('relapse.wasPlasmaGiven')}
    value={wasPlasmaGiven && fm('relapse.opts.yesNoUnknown.' + wasPlasmaGiven)}
  />
);

const RelapseHistoryRowData = ({ document }: IOwnProps): React.JSX.Element => (
  <>
    {RelapseSymptomsItem(document?.symptoms)}
    {RelapseSymptomLevelItem(document?.symptomLevel)}
    {RelapseOriginOfInformationItem(document?.originOfInformation)}
    {RelapseCortisonItem(document?.wasCortisoneGiven)}
    {RelapseCortisonRecordedItem(document?.cortisoneMedicationRecorded)}
    {RelapsePlasmaItem(document?.wasPlasmaGiven)}
  </>
);

interface IOwnProps {
  document: IRelapse;
}

export default RelapseHistoryRowData;
