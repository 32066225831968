import * as React from 'react';
import { useIntl } from 'react-intl';
import { BlockerFunction, useBlocker } from 'react-router-dom';

import ConfirmationDialog from 'Components/ConfirmationDialog';

const NavigationBlocker = ({ blocked, children }: INavigationBlocker) => {
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    const relocationEventHandler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return formatMessage({ id: 'general.saveOrCancel' });
    };

    if (blocked) {
      window.addEventListener('beforeunload', relocationEventHandler);
    } else {
      window.removeEventListener('beforeunload', relocationEventHandler);
    }

    return () => window.removeEventListener('beforeunload', relocationEventHandler);
  }, [blocked]);

  const isMountedRef = React.useRef<boolean | null>(null);
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const shouldBlock = React.useCallback<BlockerFunction>(() => {
    blocked && setAlertOpen(true);
    return !!isMountedRef.current && blocked;
  }, [blocked]);

  useBlocker(shouldBlock);

  return (
    <>
      <ConfirmationDialog
        open={alertOpen}
        text={formatMessage({ id: 'general.saveOrCancel' })}
        onlyConfirm
        centeredText
        confirm={{ callback: () => setAlertOpen(false), text: 'general.ok' }}
      />
      {children}
    </>
  );
};

interface INavigationBlocker {
  blocked: boolean;
  children: React.JSX.Element;
}

export default NavigationBlocker;
