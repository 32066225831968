import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): React.JSX.Element => <FormattedMessage id={`pulmonary.${opt}.${name}`} />;

const VentilationForm = ({ formData }: IOwnProps): React.JSX.Element => (
  <FormSection>
    <FormRow title="pulmonary.startDate">
      <InputHandler
        type="PartialDate"
        editing={true}
        name="startDate"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="pulmonary.endDate">
      <InputHandler type="PartialDate" editing={true} name="endDate" formData={formData} />
    </FormRow>

    <FormRow title="pulmonary.ventilationType">
      <InputHandler
        type="Radio"
        editing={true}
        name="type"
        formData={formData}
        options={['invasive', 'nonInvasive']}
        optionFormatter={optionFormatter('opts')}
      />
    </FormRow>
    <FormRow title="pulmonary.ventilationFrequency">
      <InputHandler
        type="Radio"
        editing={true}
        name="frequency"
        formData={formData}
        options={['fullTime', 'partTime', 'unknown']}
        optionFormatter={optionFormatter('opts')}
      />
    </FormRow>
    {formData.document?.frequency === 'partTime' && (
      <FormRow title="pulmonary.hoursPerDay">
        <InputHandler
          type="NumberField"
          editing={true}
          name="hoursPerDay"
          formData={formData}
          placeholder="pulmonary.hoursPerDayPlaceholder"
        />
      </FormRow>
    )}
  </FormSection>
);

interface IOwnProps {
  formData: IFormData<IVentilation>;
}

export default VentilationForm;
