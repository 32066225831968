import * as React from 'react';
import { styled } from '@mui/system';
import ActionButton from '../../../../../../../../components/ActionButton';
import FormRow from '../../../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../../../components/FormSectionHistoryAcceptor';
import { Container, Item } from '../../../../../../../../components/Grid';
import InputHandler from '../../../../../../../../components/InputHandler';
import colors from '../../../../../../../../config/theme/colors';
import { fm } from 'Components/FormatMessage';
import { neuroimagingCharacteristics, numberOfGivenAnswers } from '../../../../../../utils/NMOSD';
import { path } from 'Utility/ramdaReplacement';

const Header = styled('div')({
  fontWeight: 'bold',
  margin: '2rem 0',
});

const SubHeader = styled('div')({
  margin: '1rem 0',
  fontWeight: 400,
});

const GroupStyle = styled('div')({
  padding: '2rem',
});

const GroupElement = (elem?: React.JSX.Element): React.JSX.Element => (
  <GroupStyle
    style={{
      backgroundColor:
        path(['props', 'children', 0, 'props', 'children'], elem) === null ? undefined : colors.lightestGray,
    }}
  >
    {elem}
  </GroupStyle>
);

const generalOptionFormatter = (s?: string | number | boolean): React.JSX.Element => fm(`general.${s || 'empty'}`);

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  leftMargin,
  bottomMargin = true,
}: {
  title: string;
  description?: React.JSX.Element;
  name: string;
  formData: IOwnProps['formData'];
  viewing: boolean;
  leftMargin?: number;
  bottomMargin?: boolean;
}): React.JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={6}
    bottomMargin={bottomMargin}
    headerStyle={{ marginLeft: `${leftMargin ?? 0}rem` }}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="yesNoUnknown"
      height={3.5}
      width={9}
      optionFormatter={generalOptionFormatter}
    />
  </FormRow>
);

const NeuroImaging = ({ documents, formData, view, editingID }: IOwnProps): React.JSX.Element => {
  const [isEditing, setEditing] = React.useState<boolean>(false);

  const isViewing = !editingID && !!view?.viewing;
  const docID = editingID ?? view?.viewing;

  React.useEffect(() => {
    neuroimagingCharacteristics.forEach((name: string) => {
      if (!isViewing && !(formData.document as { [key: string]: any })[name]) {
        formData?.onChange?.({
          [name]: 'unknown',
        });
      }
    });
  }, [formData, isViewing]);

  // Ref for scrolling to top of the section
  const ref = React.createRef() as React.RefObject<HTMLDivElement>;

  const toggleEditing = (): void => {
    setEditing(!isEditing);
    if (isEditing && ref.current) {
      scroll({ top: ref.current.offsetTop - 75, behavior: 'smooth' });
    }
  };

  return (
    <React.Fragment>
      <FormSectionHistoryAcceptor
        formData={formData}
        documentID={docID}
        documents={documents}
        optionFormatter={generalOptionFormatter}
        header="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.title"
        hideCopyButton={isViewing || !isEditing}
        headerRef={ref}
      >
        {{
          edit: {
            header: (
              <Container style={{ marginTop: '-4rem', paddingRight: '2rem' }} justifyContent="flex-end">
                <Item>
                  <ActionButton
                    text={`general.${isViewing ? 'view' : 'edit'}`}
                    onClick={toggleEditing}
                    width={16}
                    height={3}
                    fontSize={16}
                  />
                </Item>
              </Container>
            ),
            condition: !isEditing,
          },
          answerCount: {
            element: (
              <React.Fragment>
                <Container>
                  <Item xs={6} style={{ paddingRight: '1rem' }}>
                    <SubHeader style={{ fontWeight: 600 }}>{fm('diagnosis.nmosdDiagnosticCriteria.answers')}</SubHeader>
                  </Item>
                </Container>
                {['yes', 'no', 'unknown'].map(
                  (answer: string): React.JSX.Element => (
                    <Container key={`${answer}NeuroimagingCharacteristics`} style={{ marginTop: '1rem' }}>
                      <Item xs={6} style={{ paddingLeft: '3rem' }}>
                        {fm(`general.${answer}`)}
                      </Item>
                      <Item xs={true} style={{ fontWeight: 600 }}>
                        {numberOfGivenAnswers(
                          formData.document,
                          neuroimagingCharacteristics,
                          answer as 'yes' | 'no' | 'unknown',
                        )}
                      </Item>
                    </Container>
                  ),
                )}
              </React.Fragment>
            ),
            condition: !isEditing,
          },
          group1: {
            group: {
              condition: isEditing,
              groupElement: GroupElement,
              children: {
                spinalCordMRIAcute: {
                  header: (
                    <Header style={{ marginTop: '0' }}>
                      {fm('diagnosis.nmosdDiagnosticCriteria.Neuroimaging.spinalCordMRIAcute')}
                    </Header>
                  ),
                  condition: isEditing,
                },
                LETMLesionAssociated: {
                  header: (
                    <SubHeader>{fm('diagnosis.nmosdDiagnosticCriteria.Neuroimaging.LETMLesionAssociated')}</SubHeader>
                  ),
                  condition: isEditing,
                },
                increasedSignalOnSagittalT2Weighted: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.increasedSignalOnSagittalT2Weighted"
                      name="increasedSignalOnSagittalT2Weighted"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                centralCordPredominance: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.centralCordPredominance"
                      name="centralCordPredominance"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                gadoliniumEnchancementOfTheLesionsOnT1Seq: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.gadoliniumEnchancementOfTheLesionsOnT1Seq"
                      name="gadoliniumEnchancementOfTheLesionsOnT1Seq"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                otherCharacteristicFeatires: {
                  header: (
                    <SubHeader>
                      {fm('diagnosis.nmosdDiagnosticCriteria.Neuroimaging.otherCharacteristicFeatires')}
                    </SubHeader>
                  ),
                  condition: isEditing,
                },

                rostralExtensionOfTheLesionIntoTheBrainstem: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.rostralExtensionOfTheLesionIntoTheBrainstem"
                      name="rostralExtensionOfTheLesionIntoTheBrainstem"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                cordExpansionSwelling: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.cordExpansionSwelling"
                      name="cordExpansionSwelling"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                decresedSignalOnT1Seq: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.decresedSignalOnT1Seq"
                      name="decresedSignalOnT1Seq"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },

                spinalCordMRIchronic: {
                  header: <Header>{fm('diagnosis.nmosdDiagnosticCriteria.Neuroimaging.spinalCordMRIchronic')}</Header>,
                  condition: isEditing,
                },
                longitudinallyExtensiveCordAtrophy: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.longitudinallyExtensiveCordAtrophy"
                      name="longitudinallyExtensiveCordAtrophy"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },

                opticNerveMRI: {
                  header: <Header>{fm('diagnosis.nmosdDiagnosticCriteria.Neuroimaging.opticNerveMRI')}</Header>,
                  condition: isEditing,
                },
                unilateralOrBilateralIncreasedT2Signal: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.unilateralOrBilateralIncreasedT2Signal"
                      name="unilateralOrBilateralIncreasedT2Signal"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },

                cerebralMRI: {
                  header: <Header>{fm('diagnosis.nmosdDiagnosticCriteria.Neuroimaging.cerebralMRI')}</Header>,
                  condition: isEditing,
                },
                lesionsInvolvingTheDorsalMedulla: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.lesionsInvolvingTheDorsalMedulla"
                      name="lesionsInvolvingTheDorsalMedulla"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                periependymalSurfacesOfTheFourthVentricle: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.periependymalSurfacesOfTheFourthVentricle"
                      name="periependymalSurfacesOfTheFourthVentricle"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                lesionsInvolvingTheHypothalamus: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.lesionsInvolvingTheHypothalamus"
                      name="lesionsInvolvingTheHypothalamus"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                largeConfluentUnilateralOrBilateralLesions: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.largeConfluentUnilateralOrBilateralLesions"
                      name="largeConfluentUnilateralOrBilateralLesions"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                longDiffuseHeterogeneousOrEdematousLesions: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.longDiffuseHeterogeneousOrEdematousLesions"
                      name="longDiffuseHeterogeneousOrEdematousLesions"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                longCoricospinalTractLesions: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.longCoricospinalTractLesions"
                      name="longCoricospinalTractLesions"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                extensivePeripendymalBrainLesions: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.Neuroimaging.extensivePeripendymalBrainLesions"
                      name="extensivePeripendymalBrainLesions"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                accept: {
                  header: (
                    <Container justifyContent="flex-end">
                      <Item>
                        <ActionButton
                          text={`general.${isViewing ? 'close' : 'accept'}`}
                          onClick={toggleEditing}
                          width={16}
                          height={3}
                          fontSize={16}
                        />
                      </Item>
                    </Container>
                  ),
                  condition: isEditing,
                },
              },
            },
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<INMOSDCriteria>;
  formData: IFormData<INMOSDCriteria>;
  view?: IView;
  editingID?: string;
}

export default NeuroImaging;
