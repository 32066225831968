import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import HistorySection from '../../../../components/HistorySection';
import { withHistoryContext, IHistoryContext } from '../../../../containers/FormContextHandler';
import { DocumentCreationButtonWithContext } from '../../utils';
import { TreatmentHistorySection } from './components';

const MADTherapy = ({ documents = [], startEdit, view }: IHistoryContext<IMADTherapy>): React.JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="treatment.madTherapy.title" />}
    newButton={<DocumentCreationButtonWithContext name="madTherapy" text="treatment.madTherapy.newMadTherapy" />}
  >
    <TreatmentHistorySection documents={documents} startEdit={startEdit} type="madTherapy" view={view} />
  </HistorySection>
);

export default withHistoryContext(MADTherapy);
