import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import { formatPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const UHDRSIndependenceScale = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="functionalPerformance.uhdrsIndependenceScale" />}
    newButton={
      <DocumentCreationButton
        name="uhdrsIndependenceScale"
        text="functionalPerformance.newUhdrsIndependenceScale"
        onClick={startEdit({}, 'uhdrsIndependenceScale')}
        width={20}
        height={3}
        fontSize={14}
        alternate={true}
      />
    }
  >
    <HistoryRowListing
      documents={documents}
      headers={
        <>
          <Item xs={2}>
            <FormattedMessage id="general.date" />
          </Item>
          <Item xs={7}>
            <FormattedMessage id="functionalPerformance.uhdrsIndependenceScale" />
          </Item>
        </>
      }
      contentFormat={(d: IUHDRSIndependenceScale): React.JSX.Element => (
        <>
          <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
          <Item xs={7}>
            {d.value ? (
              <>
                {d.value + ': '}
                <FormattedMessage id={`functionalPerformance.opts.uhdrsIndependenceScale.${d.value}`} />
              </>
            ) : (
              '-'
            )}
          </Item>
          <Item xs={3}>
            <HistoryRowControls document={d} startEdit={startEdit} view={view} />
          </Item>
        </>
      )}
    />
  </HistorySection>
);

interface IOwnProps {
  documents: Array<IUHDRSIndependenceScale>;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default UHDRSIndependenceScale;
