import DataTable from 'Components/DataTable';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { copd, ICOPD, isLocaleKey, Task_Progress } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { omitControlProps } from 'Utility/documentHandling';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';

const copdFields: Array<keyof ICOPD> = [
  'coughing',
  'mucusInLungs',
  'anxietyInChest',
  'shortnessOfBreath',
  'householdChoresPerformance',
  'leavingHome',
  'soundSleep',
  'energy',
];

export const Copd = (): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing, setViewingObj, setEditingObj } = myServContext;

  const copdData = (editing?.data || viewing?.data) as ICOPD & IControlProps;
  const copdLocales = copd.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...copdData,
      [field]: value,
    });
  };

  return (
    <>
      {!viewing ? (
        <div style={{ width: '80%', color: colors.tertiaryText, marginBottom: '2rem' }}>
          <p>{copdLocales[useLocale]['surveyInfo']}</p>
          <p>{copdLocales[useLocale]['surveyFillingInfo']}</p>
        </div>
      ) : null}
      <BlockWrapper
        title={viewing ? formatPartialDate(copdData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'copd', data: copdData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'} headerWidth={2}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: copdData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <div style={{ marginBottom: '2rem', marginTop: viewing ? '6rem' : undefined }}>
          <DataTable
            headers={['', '', '']}
            data={{
              rowData: copdFields.map((f) => {
                return [
                  copdLocales[useLocale][f],
                  <InputHandler
                    key={f}
                    name={f}
                    type="NumberField"
                    editing={!!editing}
                    width={8}
                    formData={{
                      document: { [f]: copdData?.[f] },
                      onChange: onChangeSurveyForm,
                    }}
                    placeholder={'0 - 5'}
                    disablePlaceholderFormatting
                    min={0}
                    max={5}
                  />,
                  <div key={`${f}Info`} style={{ marginLeft: '2rem' }}>
                    <span style={{ marginRight: '2rem' }}>0 = {copdLocales[useLocale][`${f}InfoBottom`]}</span>
                    <span>5 = {copdLocales[useLocale][`${f}InfoTop`]}</span>
                  </div>,
                ];
              }),
            }}
          />
        </div>
        <FormRow
          title="myService.sleepApnea.copd.score"
          headerWidth={viewing ? 2.2 : 2}
          headerStyle={{ fontWeight: 600 }}
        >
          <div style={{ color: colors.tertiaryText, fontWeight: 600 }}>
            <span>
              {typeof Task_Progress.calculateProgress('copd', omitControlProps(copdData)).yielded === 'number'
                ? Task_Progress.calculateProgress('copd', omitControlProps(copdData)).yielded
                : '-'}
            </span>
            <span>{` / ${Task_Progress.calculateProgress('copd', omitControlProps(copdData)).ceiling}`}</span>
          </div>
        </FormRow>
      </BlockWrapper>
    </>
  );
};
