import { equals } from 'ramda';
import * as React from 'react';
import EventStepper from '../../../../../components/EventStepper';
import { fm } from 'Components/FormatMessage';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import { formatPartialDate } from 'neuro-utils';
import { GeneratorDetails } from '../../../components';
import { generatorsWithOneConnector, generatorsWithTwoConnectors, leadsWithOneConnector } from '../../config';
import { generatorSettingHandler } from 'Routes/Vns/utils';
import ConfirmationDialog from 'Components/ConfirmationDialog';

type TSettingHandlerProps = {
  generators?: IVNSGenerator[];
  action?: 'generator_deleted' | 'generator_changed' | 'generator_date_changed';
  generator?: IVNSGenerator;
};

const GeneratorEvents = ({ formData, disabled }: IOwnProps): React.JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = React.useState<string>('vns.generatorChangeWarning');
  const [settingHandlerProps, setSettingHandlerProps] = React.useState<TSettingHandlerProps>({
    generators: undefined,
    action: undefined,
    generator: undefined,
  });
  const { onChange, document } = formData;

  const openDialog = (settingHandlerProps: TSettingHandlerProps) => (): void => {
    setSettingHandlerProps(settingHandlerProps);
    setDialogMessage(
      settingHandlerProps.action === 'generator_date_changed'
        ? 'vns.generatorDateChangeWarning'
        : 'vns.generatorChangeWarning',
    );
    setDialogOpen(true);
  };
  const cancelCallback = (): void => {
    settingHandlerProps.generators = formData.document.generators;
    setDialogOpen(false);
  };
  const confirmCallback = (): void => {
    if (settingHandlerProps.action) {
      generatorSettingHandler(formData, settingHandlerProps.action, settingHandlerProps.generator);
      formData.onChange?.({ generators: settingHandlerProps.generators });
    }
    setDialogOpen(false);
  };

  const onChangeGenerator =
    (editIndex: number) =>
    (values: TOnChangeValues): void => {
      const newGenerators = JSON.parse(JSON.stringify(formData.document.generators));
      const valuesName = Object.keys(values)[0]; // = generator
      const value = values[valuesName]; // = generator type (LivaNova etc.)
      newGenerators[editIndex] = { ...newGenerators[editIndex], [valuesName]: value };

      if (valuesName === 'generator') {
        // If a generator is changed, delete the settings linked to it
        if (formData.document.settings?.some((setting) => setting.generator === newGenerators[editIndex].id)) {
          openDialog({ generators: newGenerators, action: 'generator_changed', generator: newGenerators[editIndex] })();
        } else {
          formData.onChange?.({ generators: newGenerators });
        }
      }
    };

  React.useEffect(() => {
    if (document.date && Array.isArray(document.generators) && document.generators.length > 0) {
      const newGenerators = JSON.parse(JSON.stringify(document.generators));
      newGenerators[newGenerators.length - 1] = {
        ...newGenerators[newGenerators.length - 1],
        date: document.date,
        generatorNextChangeDate: Array.isArray(newGenerators[newGenerators.length - 1].generatorNextChangeDate)
          ? newGenerators[newGenerators.length - 1].generatorNextChangeDate.map((d: number | null) => d)
          : null,
      };
      if (!equals(document.generators[document.generators.length - 1], newGenerators[newGenerators.length - 1])) {
        onChange?.({ generators: newGenerators });
      }
    }
  }, [onChange, document.date, document.generators]);

  return (
    <EventStepper
      name="generators"
      formData={formData}
      stepLabelText={(d: IVNSGenerator): string => formatPartialDate(d.date)}
      stepContent={(d: IVNSGenerator): React.JSX.Element => <GeneratorDetails generator={d} />}
      separateLatest={true}
      addNewTextHeader="vns.generator"
      addNewTextButton="vns.newGenerator"
      buttonDisabled={disabled}
      buttonDisabledMessage={fm('vns.chooseElectrodeMountingLocationAndLeadTypeFirst')}
      latestEventTextHeader="vns.currentGenerator"
      previousEventsTextHeader="vns.previousGenerators"
      // If a generator is deleted, also delete the settings linked to it
      deleteExtraAction={(index: number, formData: IFormData<any, any>) => (): void => {
        generatorSettingHandler(formData, 'generator_deleted', formData.document.generators?.[index]);
      }}
      deleteMessage="vns.generatorDeleteWarning"
      editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => {
        const firstGenerator =
          Array.isArray(formData.document.generators) && formData.document.generators.length - 1 === index;
        return (
          <React.Fragment>
            <div style={{ marginBottom: '2rem' }}>
              <InputHandler
                type="PartialDate"
                editing={firstGenerator ? false : true}
                name="date"
                formData={{
                  onChange: onChange,
                  document: {
                    date: formData.document.generators?.[index]?.date || '',
                  },
                }}
                dateDefault={firstGenerator && formData.document.date ? formData.document.date : 'now'}
                isNotCancellable={true}
                dateHook={{
                  dateHookFloor: formData.document.date,
                  dateHookCeiling: formData.document.generators?.[index]?.generatorNextChangeDate,
                }}
              />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <InputHandler
                type="Select"
                editing={true}
                name="generator"
                formData={{
                  onChange: onChangeGenerator(index),
                  document: { generator: formData.document.generators?.[index]?.generator || '' },
                }}
                options={
                  leadsWithOneConnector.includes(formData.document?.leadType ?? '')
                    ? generatorsWithOneConnector
                    : generatorsWithTwoConnectors
                }
                placeholder="vns.chooseGenerator"
              />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <div style={{ color: colors.darkGray, marginBottom: '0.5rem' }}>{fm('vns.generatorSerialNo')}</div>
              <InputHandler
                type="TextField"
                editing={true}
                name="generatorSerialNo"
                formData={{
                  onChange,
                  document: { generatorSerialNo: formData.document.generators?.[index]?.generatorSerialNo || '' },
                }}
                placeholder="vns.enterSerialNo"
              />
            </div>
            <div>
              <div style={{ color: colors.darkGray }}>{fm('vns.generatorNextChangeDate')}</div>
              <InputHandler
                type="PartialDate"
                editing={true}
                name="generatorNextChangeDate"
                formData={{
                  onChange,
                  document: {
                    generatorNextChangeDate: formData.document.generators?.[index]?.generatorNextChangeDate || '',
                  },
                }}
                dateHook={{ dateHookFloor: formData.document.generators?.[index]?.date }}
                addToYearCeiling={10}
              />
            </div>
            <ConfirmationDialog
              open={dialogOpen}
              text={fm(dialogMessage)}
              confirm={{ callback: confirmCallback }}
              cancel={{ callback: cancelCallback }}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IVNS>;
  disabled?: boolean;
}

export default GeneratorEvents;
