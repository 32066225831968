import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

import browserLanguage from '../../../../..//utility/browserLanguage';
import { formatPartialDate, isPartialDate, nowPartialDate, partialDateToValue } from 'neuro-utils';
import EventStepper from '../../../../../components/EventStepper';
import ToolTip from '../../../../../components/ToolTip';
import { Container, Item } from '../../../../../components/Grid';
import colors from '../../../../../config/theme/colors';
import { getDescription, getICD10Codes } from '../../../../../utility/randomUtil';
import PlatformConditional from '../../../../../components/PlatformConditional';
import { equals } from 'ramda';
import { useAppSelector as useSelector } from 'Store/index';
import { isNil } from 'Utility/ramdaReplacement';

const optionFormatter = (name: number | string): React.JSX.Element => (
  <FormattedMessage id={`comorbidity.opts.${name}`} />
);

const ComorbidityForm = ({
  documents,
  editing,
  formData,
  medicationNames,
  diagnosis,
}: IOwnProps): React.JSX.Element => {
  const platform = useSelector((s: { session: ISessionStore }) => s.session.platforms?.selected);
  const uiLanguage = useSelector((state: IState) => state.settings.userSettings.uiLanguage) || browserLanguage;
  const [ICD10List, setICD10List] = React.useState<Array<ICD10Item>>([]);
  const [ICD10SearchString, setICD10SearchString] = React.useState<string>('');
  const [warningOpen, setWarningOpen] = React.useState<boolean>(false);
  const documentsOnMount = React.useRef<Array<IComorbidity>>(documents);
  const sleep = (milliseconds: number): any => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const updateICD10Codes = React.useCallback((): void => {
    const locale = uiLanguage; // 'fi' or 'se' or 'en'
    if (ICD10SearchString && ICD10SearchString?.length > 2) {
      getICD10Codes(ICD10SearchString, locale)
        .then((data) => {
          setICD10List(data);
        })
        .catch(() => {
          return;
        });
    }
  }, [ICD10SearchString]);

  const isDateForbidden = React.useCallback(
    (date?: PartialDate, endDate?: PartialDate): boolean => {
      const comorbiditiesFiltered = documents.filter((comorbidity) => comorbidity._editing === false);

      return comorbiditiesFiltered.length > 0
        ? comorbiditiesFiltered.some((c): boolean => {
            if (
              c.classification &&
              `${c.classification.icd10code} - ${c.classification.icd10description}` === ICD10SearchString
            ) {
              if (partialDateToValue(c.date) === partialDateToValue(date)) {
                return true;
              }
              if (isPartialDate(c.endDate)) {
                return partialDateToValue(c.endDate) > partialDateToValue(date) &&
                  partialDateToValue(c.date) < partialDateToValue(date)
                  ? true
                  : false;
              }
              if (
                partialDateToValue(c.date) > partialDateToValue(date) &&
                (partialDateToValue(endDate) > partialDateToValue(c.date) || partialDateToValue(endDate) === 0)
              ) {
                return true;
              }
              return partialDateToValue(c.date) > partialDateToValue(date) ? false : true;
            }
            return false;
          })
        : false;
    },
    [ICD10SearchString, documents],
  );

  const onChangeICD = (e: React.SyntheticEvent<Element, Event>, d: string): void => {
    d && d !== 'undefined' ? setICD10SearchString(d) : d === '' ? setICD10SearchString('') : undefined;
  };

  React.useEffect(() => {
    warningOpen
      ? sleep(6000)
          .then(() => {
            setWarningOpen(false);
          })
          .catch(() => {
            setWarningOpen(false);
          })
      : undefined;
  });

  React.useEffect(() => {
    // LOad ICD-10 after typing has been stopped
    const timeout = setTimeout(() => {
      updateICD10Codes();
    }, 750);
    if (equals(documents, documentsOnMount.current)) {
      return;
    }
    setWarningOpen(isDateForbidden(formData.document.date, formData.document.endDate) ? true : false);
    return (): any => clearTimeout(timeout);
  }, [documents, formData.document.date, formData.document.endDate, updateICD10Codes, isDateForbidden]);

  React.useEffect(() => {
    let isMounted = true;
    if (formData.document.classification?.icd10code) {
      getDescription(formData.document.classification.icd10code)
        .then((r) => {
          if (isMounted && r !== formData.document.classification?.icd10description) {
            formData.onChange?.({
              classification: {
                icd10code: formData.document.classification?.icd10code,
                icd10description: r ?? formData?.document?.classification?.icd10description,
              },
            });
          }
        })
        .catch(() => {
          return;
        });
    }
    return (): void => {
      isMounted = false;
    };
  }, [formData]);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="comorbidity.diagnosisDate">
          <InputHandler
            type="PartialDate"
            name="date"
            editing={editing}
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
            dateHook={{ dateHookCeiling: formData.document.endDate }}
          />
        </FormRow>
        <FormRow title="comorbidity.recoveryDate" condition={platform !== 'sleepApnea' && platform !== 'ninmt'}>
          <InputHandler
            type="PartialDate"
            name="endDate"
            editing={editing}
            formData={formData}
            dateHook={{ dateHookFloor: formData.document.date }}
          />
        </FormRow>
      </FormSection>
      <FormSection header="comorbidity.ICD-10">
        <FormRow title="comorbidity.chooseClassification">
          <ToolTip
            title={<FormattedMessage id="general.checkDates" />}
            description={<FormattedMessage id={'comorbidity.comorbidityAlreadyExists'} />}
            content={
              <InputHandler
                type="AutoCompleteSelect"
                name="classification"
                editing={editing}
                formData={formData}
                options={ICD10List as any}
                placeholder={'comorbidity.chooseClassification2'}
                labelID={'icd10description'}
                groupID={'icd10code'}
                onInputChange={onChangeICD}
                getOptionLabel={(o: ICD10Item): string =>
                  `${o.icd10code}${o.icd10description ? ' - ' + o.icd10description : ''}`
                }
                width={30}
              />
            }
            open={warningOpen}
          />
        </FormRow>
      </FormSection>
      <PlatformConditional platform="ms">
        <React.Fragment>
          {!isNil(formData.document?.otherComorbidity) && (
            <FormSection header="comorbidity.otherComorbidity">
              <FormRow title="comorbidity.otherComorbidity">
                <InputHandler
                  type="TextField"
                  name="otherComorbidity"
                  editing={editing}
                  formData={formData}
                  placeholder="comorbidity.comorbidity"
                  width={30}
                />
              </FormRow>
            </FormSection>
          )}
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform={['sma', 'dmd']}>
        <FormSection header="comorbidity.adverseEffect">
          <FormRow title="comorbidity.isAdverseEffect">
            <InputHandler
              type="Radio"
              editing={true}
              name="isMedicationAdverseEffect"
              formData={formData}
              preset="yesno"
            />
          </FormRow>
          <FormRow title="comorbidity.whichMedication" condition={formData.document.isMedicationAdverseEffect === true}>
            <InputHandler
              type="Select"
              name="medication"
              editing={editing}
              formData={formData}
              options={medicationNames}
              placeholder="comorbidity.medicationPlaceholder"
            />
          </FormRow>
        </FormSection>
      </PlatformConditional>
      <PlatformConditional platform="ninmt">
        <FormSection>
          <FormRow title="comorbidity.ninmtSymptom">
            <InputHandler
              type="CheckboxSingle"
              editing={true}
              name="ninmtSymptom"
              option="true"
              formData={formData}
              showLabel={false}
            />
          </FormRow>
        </FormSection>
      </PlatformConditional>
      {/* If M41 / M40 - Skolioosi / Kyfoosi */}
      {formData.document.classification &&
        ['M41', 'M41.4', 'M40', 'M40.0', 'M40.1', 'M40.2'].includes(
          formData.document.classification?.icd10code ?? '',
        ) && (
          <FormSection header="comorbidity.backSurgeriesAndTreatments">
            {editing ? (
              <React.Fragment>
                <EventStepper
                  name="corsetTreatments"
                  formData={formData}
                  stepLabelText={(d: ICorsetTreatment): React.JSX.Element => (
                    <span>
                      {formatPartialDate(d.startDate)}
                      {d.endDate && ` - ${formatPartialDate(d.endDate)}`}
                    </span>
                  )}
                  stepContent={(d: ICorsetTreatment): React.JSX.Element => (
                    <Container>
                      <Item xs={3} style={{ color: colors.tertiaryText }}>
                        <FormattedMessage id="comorbidity.corsetType" />
                      </Item>
                      <Item xs={true}>
                        {d?.corsetType ? <FormattedMessage id={`comorbidity.opts.corsetType.${d?.corsetType}`} /> : '-'}
                      </Item>
                    </Container>
                  )}
                  addNewTextHeader="comorbidity.newScoliosisOrKyphosisCorsetTreatment"
                  addNewTextButton="comorbidity.newTreatment"
                  previousEventsTextHeader="comorbidity.previousCorsetTreatments"
                  editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => (
                    <React.Fragment>
                      <div style={{ marginBottom: '2rem' }}>
                        <FormattedMessage id="comorbidity.startedDate" />
                        <InputHandler
                          type="PartialDate"
                          editing={true}
                          name="startDate"
                          formData={{
                            onChange,
                            document: {
                              startDate: formData.document.corsetTreatments?.[index]?.startDate || '',
                            },
                          }}
                          dateDefault={'now'}
                          isNotCancellable={true}
                        />
                      </div>
                      <div style={{ marginBottom: '2rem' }}>
                        <FormattedMessage id="comorbidity.endDate" />
                        <InputHandler
                          type="PartialDate"
                          editing={true}
                          name="endDate"
                          formData={{
                            onChange,
                            document: {
                              endDate: formData.document.corsetTreatments?.[index]?.endDate || '',
                            },
                          }}
                          dateHook={{
                            dateHookFloor: formData.document.corsetTreatments?.[index]?.startDate,
                            dateHookCeiling:
                              nowPartialDate() && nowPartialDate()?.[0]
                                ? [(nowPartialDate()?.[0] as number) + 10, 12, 31]
                                : undefined,
                          }}
                        />
                      </div>
                      <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                        <FormattedMessage id="comorbidity.corsetType" />
                        <InputHandler
                          type="Radio"
                          editing={true}
                          name="corsetType"
                          formData={{
                            onChange,
                            document: {
                              corsetType: formData.document.corsetTreatments?.[index]?.corsetType,
                            },
                          }}
                          options={['hardBrace', 'softBrace']}
                          optionFormatter={(name: string | number): React.JSX.Element => (
                            <FormattedMessage id={`comorbidity.opts.corsetType.${name}`} />
                          )}
                        />
                      </div>
                    </React.Fragment>
                  )}
                />
                <EventStepper
                  name="backSurgeries"
                  formData={formData}
                  stepLabelText={(d: IBackSurgery): string => formatPartialDate(d.date)}
                  stepContent={(d: IBackSurgery): React.JSX.Element => (
                    <React.Fragment>
                      <Container style={{ marginBottom: '1rem' }}>
                        <Item xs={3} style={{ color: colors.tertiaryText }}>
                          <FormattedMessage id="comorbidity.surgeryTechnique" />
                        </Item>
                        <Item xs={true}>
                          {d?.surgeryTechnique ? (
                            <FormattedMessage id={`comorbidity.opts.surgeryTechnique.${d?.surgeryTechnique}`} />
                          ) : (
                            '-'
                          )}
                        </Item>
                      </Container>
                      {d?.surgeryTechnique === 'expandableBackInstrumentation' && (
                        <React.Fragment>
                          <Container style={{ marginBottom: '1rem' }}>
                            <Item xs={3} style={{ color: colors.tertiaryText }}>
                              <FormattedMessage id="comorbidity.instrumentation" />
                            </Item>
                            <Item xs={true}>
                              {d?.instrumentation ? (
                                <FormattedMessage id={`comorbidity.opts.instrumentation.${d?.instrumentation}`} />
                              ) : (
                                '-'
                              )}
                            </Item>
                          </Container>
                          {d?.instrumentation === 'other' && (
                            <Container style={{ marginBottom: '1rem' }}>
                              <Item xs={3} style={{ color: colors.tertiaryText }}>
                                <FormattedMessage id="comorbidity.additionalInformation" />
                              </Item>
                              <Item xs={true}>{d?.instrumentationAdditionalInformation ?? '-'}</Item>
                            </Container>
                          )}
                        </React.Fragment>
                      )}
                      <Container style={{ marginBottom: '1rem' }}>
                        <Item xs={3} style={{ color: colors.tertiaryText }}>
                          <FormattedMessage id="comorbidity.surgeryLevel" />
                        </Item>
                        <Item xs={true}>{d?.surgeryLevel ?? '-'}</Item>
                      </Container>
                    </React.Fragment>
                  )}
                  addNewTextHeader="comorbidity.newBackSurgery"
                  addNewTextButton="comorbidity.newSurgery"
                  previousEventsTextHeader="comorbidity.previousSurgeries"
                  editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => (
                    <React.Fragment>
                      <div style={{ marginBottom: '2rem' }}>
                        <FormattedMessage id="general.date" />
                        <InputHandler
                          type="PartialDate"
                          editing={true}
                          name="date"
                          formData={{
                            onChange,
                            document: {
                              date: formData.document.backSurgeries?.[index]?.date || '',
                            },
                          }}
                          dateDefault={'now'}
                          isNotCancellable={true}
                        />
                      </div>
                      <div style={{ marginBottom: '2rem' }}>
                        <FormattedMessage id="comorbidity.surgeryTechnique" />
                        <InputHandler
                          type="Radio"
                          editing={true}
                          name="surgeryTechnique"
                          formData={{
                            onChange,
                            document: {
                              surgeryTechnique: formData.document.backSurgeries?.[index]?.surgeryTechnique,
                            },
                          }}
                          options={['expandableBackInstrumentation', 'definitiveFusion', 'other']}
                          optionFormatter={(name: string | number): React.JSX.Element => (
                            <FormattedMessage id={`comorbidity.opts.surgeryTechnique.${name}`} />
                          )}
                        />
                      </div>
                      {Array.isArray(formData.document?.backSurgeries) &&
                        ['expandableBackInstrumentation', 'definitiveFusion'].includes(
                          formData.document.backSurgeries?.[index]?.surgeryTechnique ?? '',
                        ) && (
                          <React.Fragment>
                            {formData.document.backSurgeries?.[index]?.surgeryTechnique ===
                              'expandableBackInstrumentation' && (
                              <React.Fragment>
                                <div style={{ marginBottom: '2rem' }}>
                                  <FormattedMessage id="comorbidity.instrumentation" />
                                  <InputHandler
                                    type="Radio"
                                    editing={true}
                                    name="instrumentation"
                                    formData={{
                                      onChange,
                                      document: {
                                        instrumentation: formData.document.backSurgeries?.[index]?.instrumentation,
                                      },
                                    }}
                                    options={['magneticRods', 'shilla', 'other']}
                                    optionFormatter={(name: string | number): React.JSX.Element => (
                                      <FormattedMessage id={`comorbidity.opts.instrumentation.${name}`} />
                                    )}
                                  />
                                </div>
                                {formData.document.backSurgeries?.[index]?.instrumentation === 'other' && (
                                  <div style={{ marginBottom: '2rem' }}>
                                    <div style={{ marginBottom: '0.6rem' }}>
                                      <FormattedMessage id="comorbidity.additionalInformation" />
                                    </div>
                                    <InputHandler
                                      type="TextArea"
                                      editing={true}
                                      name="instrumentationAdditionalInformation"
                                      formData={{
                                        onChange,
                                        document: {
                                          instrumentationAdditionalInformation:
                                            formData.document.backSurgeries?.[index]
                                              ?.instrumentationAdditionalInformation,
                                        },
                                      }}
                                      placeholder="comorbidity.additionalInformation"
                                    />
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                            <div style={{ marginBottom: '2rem' }}>
                              <div style={{ marginBottom: '0.6rem' }}>
                                <FormattedMessage id="comorbidity.surgeryLevel" />
                              </div>
                              <InputHandler
                                type="TextField"
                                editing={true}
                                name="surgeryLevel"
                                formData={{
                                  onChange,
                                  document: {
                                    surgeryLevel: formData.document.backSurgeries?.[index]?.surgeryLevel,
                                  },
                                }}
                                placeholder="comorbidity.surgeryLevel"
                              />
                            </div>
                          </React.Fragment>
                        )}
                    </React.Fragment>
                  )}
                />
              </React.Fragment>
            ) : undefined}
          </FormSection>
        )}
      {/* If Q65 - Synnynnäiset lonkkanivelen epämuotoisuudet */}
      {formData.document.classification && formData.document.classification?.icd10code === 'Q65' && (
        <FormSection header="comorbidity.hipSurgeries">
          {editing ? (
            <EventStepper
              name="hipSurgeries"
              formData={formData}
              stepLabelText={(d: IHipSurgery): string => formatPartialDate(d.date)}
              stepContent={(d: IHipSurgery): React.JSX.Element => (
                <Container>
                  <Item xs={3} style={{ color: colors.tertiaryText }}>
                    <FormattedMessage id="comorbidity.surgeryType" />
                  </Item>
                  <Item xs={true}>
                    {d?.surgeryType ? <FormattedMessage id={`comorbidity.opts.surgeryType.${d?.surgeryType}`} /> : '-'}
                  </Item>
                </Container>
              )}
              addNewTextHeader="comorbidity.newHipSurgery"
              addNewTextButton="comorbidity.newSurgery"
              previousEventsTextHeader="comorbidity.previousSurgeries"
              editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => (
                <React.Fragment>
                  <div style={{ marginBottom: '2rem' }}>
                    <FormattedMessage id="general.date" />
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: {
                          date: formData.document.hipSurgeries?.[index]?.date || '',
                        },
                      }}
                      dateDefault={'now'}
                      isNotCancellable={true}
                    />
                  </div>
                  <div style={{ marginBottom: '2rem' }}>
                    <FormattedMessage id="comorbidity.surgeryType" />
                    <InputHandler
                      type="Radio"
                      editing={true}
                      name="surgeryType"
                      formData={{
                        onChange,
                        document: {
                          surgeryType: formData.document.hipSurgeries?.[index]?.surgeryType,
                        },
                      }}
                      options={['hipVariation', 'pelvicOsteotomy']}
                      optionFormatter={(name: string | number): React.JSX.Element => (
                        <FormattedMessage id={`comorbidity.opts.surgeryType.${name}`} />
                      )}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          ) : undefined}
        </FormSection>
      )}
      {/* If M24.5 - Nivelen kontraktuura */}
      {formData.document.classification && formData.document.classification.icd10code?.substr(0, 5) === 'M24.5' && (
        <FormSection header="comorbidity.contracturesOfJoints">
          <FormRow title="comorbidity.kneeFlexion" id={'kneeFlexion'}>
            <InputHandler
              type="Radio"
              name="kneeFlexion"
              editing={editing}
              formData={formData}
              options={['left', 'right', 'both', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
          <FormRow title="comorbidity.anklePlantarFlexion" id={'anklePlantarFlexion'}>
            <InputHandler
              type="Radio"
              name="anklePlantarFlexion"
              editing={editing}
              formData={formData}
              options={['left', 'right', 'both', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
          <FormRow title="comorbidity.hipAdductor" id={'hipAdductor'}>
            <InputHandler
              type="Radio"
              name="hipAdductor"
              editing={editing}
              formData={formData}
              options={['left', 'right', 'both', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
          <FormRow title="comorbidity.ITBConctracture" id={'ITBContracture'}>
            <InputHandler
              type="Radio"
              name="ITBConctracture"
              editing={editing}
              formData={formData}
              options={['left', 'right', 'both', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
          <FormRow title="comorbidity.shoulderProtraction" id={'shoulderProtraction'}>
            <InputHandler
              type="Radio"
              name="shoulderProtraction"
              editing={editing}
              formData={formData}
              options={['left', 'right', 'both', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
          <FormRow title="comorbidity.elbowFlexion" id={'elbowFlexion'}>
            <InputHandler
              type="Radio"
              name="elbowFlexion"
              editing={editing}
              formData={formData}
              options={['left', 'right', 'both', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
          <FormRow title="comorbidity.neckRotation" id={'neckRotation'}>
            <InputHandler
              type="Radio"
              name="neckRotation"
              editing={editing}
              formData={formData}
              options={['left', 'right', 'bothDirections', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
          <FormRow title="comorbidity.neckLateralFlexion" id={'neckLateralFlexion'}>
            <InputHandler
              type="Radio"
              name="neckLateralFlexion"
              editing={editing}
              formData={formData}
              options={['left', 'right', 'both', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
          <FormRow title="comorbidity.fingerConctracture" id={'fingerConctracture'}>
            <InputHandler
              type="Radio"
              name="fingerConctracture"
              editing={editing}
              formData={formData}
              options={['left', 'right', 'both', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
        </FormSection>
      )}
      {/* If patient has J96.1 / J96.9 - Pitkäaikainen hengitysvajaus / Määrittämätön hengitysvajaus */}
      {diagnosis && (diagnosis.includes('J96.1') || diagnosis.includes('J96.9')) && (
        <FormSection>
          <FormRow title="comorbidity.sicknessEffectOnRespiratoryFailure">
            <InputHandler
              type="Radio"
              name="sicknessEffectOnRespiratoryFailure"
              editing={editing}
              formData={formData}
              options={['respiratoryFailureReason', 'otherSicknessCausingRespiratoryFailure', 'neither']}
              optionFormatter={(name: string | number): React.JSX.Element => (
                <FormattedMessage id={`comorbidity.opts.sicknessEffectOnRespiratoryFailure.${name}`} />
              )}
            />
          </FormRow>
        </FormSection>
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<IComorbidity>;
  editing?: boolean;
  formData: IFormData<IComorbidity>;
  medicationNames: Array<string>;
  diagnosis: Array<string>;
}

export default ComorbidityForm;
