import * as React from 'react';

import FormEditingHandler from '../../../containers/FormEditingHandler';
import DocumentWrapper from '../../../components/DocumentWrapper';
import DocumentHeader from '../../../components/DocumentHeader';
import PlatformConditional from '../../../components/PlatformConditional';
import HistorySection from '../../../components/HistorySection';
import DocumentCreationButton from '../../../components/DocumentCreationButton';

import DiagnosisHistory from './HistoryRowData/DiagnosisHistory';
import MsSymptoms from './HistoryRowData/MSHistory/MsSymptoms';
import ParkinsonSymptoms from './HistoryRowData/ParkinsonHistory/ParkinsonSymptoms';

import Form from './Form';
import { TDocument } from '../utils/definitions';
import { fm } from 'Components/FormatMessage';
import { chooseDocumentHeader } from '../utils/functions';
import MSDiagnosticCriteriaHistory from './HistoryRowData/MSHistory/MsDiagnosticsCriteria/MS';
import NMOSDDiagnosticCriteriaHistory from './HistoryRowData/MSHistory/MsDiagnosticsCriteria/NMOSD';
import { assertCapabilities } from '../../../store/index';
import PlatformCapabilities from '../../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from '../../../containers/CapabilityHandler';
import HuntingtonSymptoms from './HistoryRowData/HuntingtonHistory/HuntingtonSymptoms';
import { StyledHistoryContent } from '../utils/styled';
import { clinicallyEstablishedPD, probablePD } from '../utils';
import {
  checkDiagnosticCriteriaForClinicallyEstablishedMSA,
  checkDiagnosticCriteriaForClinicallyProbableMSA,
  possibleMSA,
  probableMSA,
} from '../utils/MSA';
import { possibleDLB, probableDLB } from '../utils/DLB';
import { PossibleCBDCanBeDiagnosed, ProbableCBDCanBeDiagnosed } from '../utils/CBD';

import {
  FormContextProvider,
  HistoryContextProvider,
  IHistoryContext,
  withHistoryContext,
} from '../../../containers/FormContextHandler';
import HTT from './HistoryRowData/HuntingtonHistory/HTT';
import UHDRS from './HistoryRowData/HuntingtonHistory/UHDRS';
import SeizureTypeHistory from './HistoryRowData/EpilepsyHistory/SeizureType';
import SyndromeHistory from './HistoryRowData/EpilepsyHistory/Syndrome';
import EtiologyHistory from './HistoryRowData/EpilepsyHistory/Etiology';
import FirstVisitSleepApnea from './HistoryRowData/SleepApneaHistory/FirstVisitSleepApnea';
import FirstVisitRespiratory from './HistoryRowData/SleepApneaHistory/FirstVisitRespiratory';
import SleepApneaSeverity from './HistoryRowData/SleepApneaHistory/SleepApneaSeverity';
import PerformanceRating from './HistoryRowData/SleepApneaHistory/PerformanceRating';
import NINMTHistory from './HistoryRowData/NINMTHistory';
import { useAppSelector } from 'Store/index';
import HistoryTabs from 'Components/HistoryTabs';
import { generateDxToPlatfMap } from '../utils/Diagnosis';
import MgravisSymptoms from './HistoryRowData/MgravisHistory/MgravisSymptoms';

const MsHistoryContentComponent = ({ capabilityGroups = {} }: ICapabilityContextProps): React.JSX.Element => (
  <React.Fragment>
    <MsSymptoms />
    <HistorySection headerText={fm('diagnosis.msDiagnosticCriteria.title')} hasHistoryRows={true}>
      <MSDiagnosticCriteriaHistory />
      {assertCapabilities([PlatformCapabilities.NMOSD_CAPABILITES], capabilityGroups) ? (
        <NMOSDDiagnosticCriteriaHistory />
      ) : undefined}
    </HistorySection>
  </React.Fragment>
);

const MsHistoryContent = withCapabilities(MsHistoryContentComponent);

const ParkinsonHistoryContent = (): React.JSX.Element => (
  <React.Fragment>
    <ParkinsonSymptoms />
    <HistorySection headerText={fm('diagnosis.parkinsonDiagnosticCriteria')} hasHistoryRows={true}>
      <StyledHistoryContent type={'pd'} clinicallyEst={clinicallyEstablishedPD} probable={probablePD} />
      <StyledHistoryContent
        type={'msa'}
        probable={probableMSA}
        possible={possibleMSA}
        checkClinicallyEstablishedMSA={checkDiagnosticCriteriaForClinicallyEstablishedMSA}
        checkClinicallyProbableMSA={checkDiagnosticCriteriaForClinicallyProbableMSA}
      />
      <StyledHistoryContent type={'psp'} />
      <StyledHistoryContent type={'dlb'} probable={probableDLB} possible={possibleDLB} />
      <StyledHistoryContent type={'cbd'} probable={ProbableCBDCanBeDiagnosed} possible={PossibleCBDCanBeDiagnosed} />
      <StyledHistoryContent type={'ftd'} />
      <StyledHistoryContent type={'ppa'} />
    </HistorySection>
  </React.Fragment>
);

const HuntingtonHistoryContent = (): React.JSX.Element => (
  <React.Fragment>
    <HuntingtonSymptoms />
    <HTT />
    <UHDRS />
  </React.Fragment>
);

const EpilepsyHistoryContent = (): React.JSX.Element => (
  <React.Fragment>
    <SeizureTypeHistory />
    <SyndromeHistory />
    <EtiologyHistory />
  </React.Fragment>
);

const MgravisHistoryContent = (): React.JSX.Element => (
  <React.Fragment>
    <MgravisSymptoms />
  </React.Fragment>
);

const SleepApneaHistoryContent = ({ documents }: IHistoryContext<IDiagnosis>): React.JSX.Element => {
  const sleepApneaDG = documents?.find((d) => d.diagnosis === 'G47.3');
  const sleepApneaSusDG = documents?.find((d) => d.diagnosis === 'sleepApneaSuspicion');
  const respiratoryDG = documents?.find((d) => d.diagnosis === 'J96.1' || d.diagnosis === 'J96.9');
  const respiratorySusDG = documents?.find((d) => d.diagnosis === 'respiratoryFailureSuspicion');
  return (
    <React.Fragment>
      {(sleepApneaDG || sleepApneaSusDG) && (
        <React.Fragment>
          <FirstVisitSleepApnea />
          {sleepApneaDG && <SleepApneaSeverity />}
        </React.Fragment>
      )}
      {(respiratoryDG || respiratorySusDG) && <FirstVisitRespiratory />}
      <PerformanceRating />
    </React.Fragment>
  );
};

const SleepApneaHistoryContentWithContext = withHistoryContext(SleepApneaHistoryContent);

const NinmtHistoryContent = ({ setTreatmentId }: { setTreatmentId: (id: string) => void }): React.JSX.Element => (
  <NINMTHistory setTreatmentId={setTreatmentId} />
);

const platformsWithDiagnosis: Platform[] = [
  'sma',
  'dmd',
  'parkinson',
  'ms',
  'huntington',
  'epilepsy',
  'sleepApnea',
  'mgravis',
];

const DiagnosisHistoryContent = ({
  documents,
  setTreatmentId,
}: {
  documents: TDocument[];
  setTreatmentId: (id: string) => void;
}): React.JSX.Element => {
  const { selected, available, preferred } = useAppSelector((s: IState) => s.session.platforms) || {
    selected: '',
    available: [],
  };

  const showOthers = available.length > 1 || (preferred || []).filter((p) => p !== selected).length > 0;

  const dgDocs = documents.filter((d) => d._type === 'diagnosis') as IDiagnosis[];
  const platformMap = generateDxToPlatfMap();

  const platformDocs = dgDocs.filter((d) => platformMap[d.diagnosis] === selected);
  const otherDocs = dgDocs.filter((d) => platformMap[d.diagnosis] !== selected);

  return (
    <React.Fragment>
      <PlatformConditional platform={platformsWithDiagnosis}>
        <HistoryTabs>
          {[
            {
              title: `header.platforms.${selected}`,
              content: (
                <>
                  {platformDocs.length === 0 ? (
                    <div style={{ marginBottom: '4rem' }}>{fm('diagnosis.noDiagnoses')}</div>
                  ) : (
                    <DiagnosisHistory documents={platformDocs} />
                  )}
                  <PlatformConditional platform="ms">
                    <MsHistoryContent />
                  </PlatformConditional>
                  <PlatformConditional platform="parkinson">
                    <ParkinsonHistoryContent />
                  </PlatformConditional>
                  <PlatformConditional platform="huntington">
                    <HuntingtonHistoryContent />
                  </PlatformConditional>
                  <PlatformConditional platform="epilepsy">
                    <EpilepsyHistoryContent />
                  </PlatformConditional>
                  <PlatformConditional platform="sleepApnea">
                    <SleepApneaHistoryContentWithContext />
                  </PlatformConditional>
                  <PlatformConditional platform="mgravis">
                    <MgravisHistoryContent />
                  </PlatformConditional>
                </>
              ),
            },
            ...(showOthers
              ? [
                  {
                    title: 'diagnosis.otherDiagnoses',
                    content: <DiagnosisHistory documents={otherDocs} />,
                    count: otherDocs.length,
                    disabled: otherDocs.length === 0,
                  },
                ]
              : []),
          ]}
        </HistoryTabs>
      </PlatformConditional>

      <PlatformConditional platform="ninmt">
        <NinmtHistoryContent setTreatmentId={setTreatmentId} />
      </PlatformConditional>
    </React.Fragment>
  );
};

const DiagnosisDocumentHeader = ({
  name,
  headerId,
  editing,
  editButtons,
}: React.ComponentProps<typeof DocumentHeader>): React.JSX.Element => {
  const props = { name, headerId, editing, editButtons };
  return (
    <React.Fragment>
      <PlatformConditional platform="ninmt">
        <DocumentHeader
          {...props}
          headerId={editing ? props.headerId : 'diagnosis.basicTreatmentInformation'}
          infoText={
            !editing
              ? fm('diagnosis.ninmt.infoText')
              : props.headerId === 'diagnosis.ninmt.contraIndicationsToTreatment.title'
                ? fm('diagnosis.ninmt.contraIndicationsToTreatment.description')
                : props.headerId === 'diagnosis.basicTreatmentInformation'
                  ? fm('diagnosis.ninmt.infoText')
                  : props.headerId === 'diagnosis.ninmt.symptomsAndLocations.title'
                    ? fm('diagnosis.ninmt.symptomsAndLocationsInfoText')
                    : undefined
          }
          editButtons={undefined}
        />
      </PlatformConditional>
      <PlatformConditional platform={platformsWithDiagnosis}>
        <DocumentHeader {...props} infoText={!editing ? fm('diagnosis.infoText') : undefined} />
      </PlatformConditional>
    </React.Fragment>
  );
};

const Diagnosis = ({ documents }: IDiagnosisProps): React.JSX.Element => {
  const [diagnosisSelected, setDiagnosisSelected] = React.useState<boolean>(true);

  // Reference for adding (NINMT) treatment period sub documents, which are bound to treatment periods by treatment id
  const treatmentIdRef = React.useRef<string | null>(null);

  const setTreatmentIdRef = (id: string): void => {
    treatmentIdRef.current = id;
  };

  const consumeTreatmentIdRef = (): string | null => {
    const ret = treatmentIdRef.current;
    treatmentIdRef.current = null;
    return ret;
  };

  const platform = useAppSelector((s: IState) => s.session.platforms?.selected);

  // Find out if the currently being edited NINMT sub document has a parent ID in data
  // In this version it takes a certain amount of time for the ID to appear in the document data so spinner is displayed until that
  const editingNINMTDocumentHasNoParentId = useAppSelector((state) => {
    const editingDocId = Object.keys(state.form.formData)?.[0];
    const editingDoc = (state.documents.sortedAndMergedDocuments || []).find((d) => d._id === editingDocId);
    return (
      [
        'indicationForTreatment',
        'contraIndicationsToTreatment',
        'symptomsDetails',
        'symptomsAndLocations',
        'referringPhysicianAndUnit',
      ].includes(editingDoc?._type || '') && !editingDoc?.treatmentPeriodId
    );
  });

  return (
    <FormEditingHandler
      name="diagnosis"
      documents={documents}
      toolbarProps={{
        saveButtonDisabled: !diagnosisSelected || editingNINMTDocumentHasNoParentId,
        spinnerEnabled: editingNINMTDocumentHasNoParentId,
      }}
    >
      {(editing, startEdit, formData, view, saveDraft): React.JSX.Element => {
        const docID = editing ?? view?.viewing;
        const editingNinmtTreatmentPeriod = !!documents.find(
          (d) => d._id === editing && d._type === 'ninmtTreatmentPeriod',
        );
        return (
          <DocumentWrapper>
            <DiagnosisDocumentHeader
              name="diagnosis"
              headerId={`diagnosis.${chooseDocumentHeader(documents, editing ?? view?.viewing)}`}
              editing={docID}
              editButtons={
                <DocumentCreationButton
                  name="diagnosis"
                  text="general.new"
                  onClick={(e: React.MouseEvent): void => {
                    // In case a new diagnosis document is created, disable save button until diagnosis is set
                    setDiagnosisSelected(false);
                    startEdit({}, 'diagnosis')(e);
                  }}
                />
              }
            />
            {!docID || (platform === 'ninmt' && editingNinmtTreatmentPeriod) ? (
              <HistoryContextProvider context={{ documents: documents, startEdit: startEdit, view: view }}>
                <FormContextProvider
                  context={{
                    editing: editing,
                    formData: formData,
                    saveDraft: saveDraft,
                  }}
                >
                  <DiagnosisHistoryContent documents={documents} setTreatmentId={setTreatmentIdRef} />
                </FormContextProvider>
              </HistoryContextProvider>
            ) : null}
            {docID ? (
              <FormContextProvider
                context={{
                  documents: documents,
                  editing: editing,
                  formData: formData,
                  view: view,
                }}
              >
                <Form setDiagnosisSelected={setDiagnosisSelected} consumeTreatmentId={consumeTreatmentIdRef} />
              </FormContextProvider>
            ) : null}
          </DocumentWrapper>
        );
      }}
    </FormEditingHandler>
  );
};

interface IDiagnosisProps {
  documents: Array<TDocument>;
}

export default Diagnosis;
