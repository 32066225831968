import * as React from 'react';

const svgImage = (): React.JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,26.8735,776.513)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-75.3416,698.374)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-111.823,578.473)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-130.062,777.811)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-127.595,673.048)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-128.175,583.25)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(2.8135e-17,-0.459479,0.678184,4.15268e-17,-230.638,651.147)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-182.091,786.714)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(1.58359e-17,-0.25862,0.25862,1.58359e-17,-12.7505,473.07)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(-1.28108,-0.00736728,0.00318561,-0.553938,1088.57,617.931)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(-0.0426463,-1.15119,0.149342,-0.00553242,100.912,923.918)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(-0.391232,-1.27599,0.810708,-0.248571,-125.696,1160.64)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(-0.00439901,-0.843164,0.84327,-0.00439957,-433.421,693.702)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(-1.08175,-0.781692,0.507994,-0.702991,612.187,1269.15)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
  </svg>
);

export default svgImage;
