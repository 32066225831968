import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import OptionChooser from '../../../../../components/OptionChooser';

import { fm } from 'Components/FormatMessage';
import { ReferenceText } from 'Routes/Diagnosis/utils/styled';

const MGFAForm = ({ formData, viewing }: IOwnProps): React.JSX.Element => (
  <FormSection>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="functionalPerformanceMgravis.classification" bottomMargin={true}>
      <OptionChooser
        formData={formData}
        name="classification"
        opts={['I', 'II', 'IIa', 'IIb', 'III', 'IIIa', 'IIIb', 'IV', 'IVa', 'IVb', 'V']}
        optionFormatter={(opt: string | number) => fm(`functionalPerformanceMgravis.opts.mgfa.${opt}`)}
        viewing={viewing}
        isSecondaryOption={(opt: string | number): boolean =>
          typeof opt === 'string' && (opt.includes('a') || opt.includes('b'))
        }
        allInstructionsOpenAtOnce={true}
      />
    </FormRow>
    <ReferenceText>
      Jaretzki A, Barohn RJ, Ernstoff RM, et al. Myasthenia Gravis: Recommendations for Clinical Research Standards.
      Neurology. 2000;55(1):16-23. doi:10.1212/WNL.55.1.16.
    </ReferenceText>
  </FormSection>
);

interface IOwnProps {
  formData: IFormData<IMGFA>;
  viewing: boolean;
}

export default MGFAForm;
