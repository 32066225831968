import * as React from 'react';
import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import colors from '../../../../../../config/theme/colors';
import { DoctorsOrdersContext } from 'Routes/DoctorsOrders/Document';

const StyledTitle = styled('div')({
  color: colors.primary,
  fontWeight: 600,
  fontSize: 16,
  marginBottom: '1.5rem',
});

const StyledSubTitle = styled('div')({
  color: colors.tertiaryText,
  fontSize: 14,
  marginBottom: '0.5rem',
});

const StyledDiv = styled('div')({
  marginBottom: '1.0rem',
});

const AddTabEditor = ({
  title,
  onChange,
  options,
  optionFormatter,
  selectedOptions,
  disabledOptions,
}: IAddTabEditor): React.JSX.Element => {
  const doctorsOrdersContext = React.useContext(DoctorsOrdersContext);
  const { fm } = doctorsOrdersContext;

  return (
    <Container style={{ margin: '0.5rem 0 2.5rem 0' }}>
      <Item xs={true}>
        {title && <StyledTitle>{title}</StyledTitle>}
        <StyledDiv style={{ marginBottom: options.length > 0 ? undefined : 0 }}>
          <InputHandler
            type="Checkbox"
            name="selectedOptions"
            formData={{
              document: { selectedOptions: selectedOptions },
              onChange: (value: TOnChangeValues) => value && onChange && onChange(value[Object.keys(value)[0]]),
            }}
            editing={true}
            options={['new']}
            disabledOptions={disabledOptions}
            optionFormatter={(name: string | number) => fm(`doctorsOrders.${name}`)}
          />
        </StyledDiv>
        {options.map((o, i, arr) => (
          <StyledDiv key={i} style={{ marginBottom: i < arr.length - 1 ? undefined : 0 }}>
            {o.title && <StyledSubTitle>{o.title}</StyledSubTitle>}
            <InputHandler
              type="Checkbox"
              name="selectedOptions"
              formData={{
                document: { selectedOptions: selectedOptions },
                onChange: (value: TOnChangeValues) => value && onChange && onChange(value[Object.keys(value)[0]]),
              }}
              editing={true}
              options={o.values}
              disabledOptions={disabledOptions}
              optionFormatter={optionFormatter}
            />
          </StyledDiv>
        ))}
      </Item>
    </Container>
  );
};

interface IAddTabEditor {
  title?: string;
  onChange?: (value: TFieldValue) => void;
  options: Array<{ title?: string; values: string[] }>;
  optionFormatter?: (id: string | number) => string;
  selectedOptions?: Array<string>;
  disabledOptions?: Array<string>;
}

export default AddTabEditor;
