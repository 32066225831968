import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

const ScoliosisForm = ({ formData, disabledFields = [] }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!disabledFields.includes('date')}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormSection header="imaging.coronalProfile">
      <FormRow title="imaging.proximalThoracicCobb">
        <InputHandler
          type="NumberField"
          editing={true}
          name="proximalThoracicCobb"
          formData={formData}
          placeholder="imaging.coronalSagittalProfilePlaceholder"
          width={5.2}
          height={3}
          maxLength={5}
          min={0}
          max={360}
          precision={2}
        />
      </FormRow>
      <FormRow title="imaging.thoracicCobb">
        <InputHandler
          type="NumberField"
          editing={true}
          name="thoracicCobb"
          formData={formData}
          placeholder="imaging.coronalSagittalProfilePlaceholder"
          width={5.2}
          height={3}
          maxLength={5}
          min={0}
          max={360}
          precision={2}
        />
      </FormRow>
      <FormRow title="imaging.thoracolumbarCobb">
        <InputHandler
          type="NumberField"
          editing={true}
          name="thoracolumbarCobb"
          formData={formData}
          placeholder="imaging.coronalSagittalProfilePlaceholder"
          width={5.2}
          height={3}
          maxLength={5}
          min={0}
          max={360}
          precision={2}
        />
      </FormRow>
    </FormSection>
    <FormSection header="imaging.sagittalProfile">
      <FormRow title="imaging.kyphosist2t12">
        <InputHandler
          type="NumberField"
          editing={true}
          name="kyphosist2t12"
          formData={formData}
          placeholder="imaging.coronalSagittalProfilePlaceholder"
          width={5.2}
          height={3}
          maxLength={5}
          min={0}
          max={360}
          precision={2}
        />
      </FormRow>
      <FormRow title="imaging.kyphosist5t12">
        <InputHandler
          type="NumberField"
          editing={true}
          name="kyphosist5t12"
          formData={formData}
          placeholder="imaging.coronalSagittalProfilePlaceholder"
          width={5.2}
          height={3}
          maxLength={5}
          min={0}
          max={360}
          precision={2}
        />
      </FormRow>
      <FormRow title="imaging.lordosist12s1">
        <InputHandler
          type="NumberField"
          editing={true}
          name="lordosist12s1"
          formData={formData}
          placeholder="imaging.coronalSagittalProfilePlaceholder"
          width={5.2}
          height={3}
          maxLength={5}
          min={0}
          max={360}
          precision={2}
        />
      </FormRow>
    </FormSection>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IScoliosisRontgen>;
  disabledFields?: string[];
}

export default ScoliosisForm;
