import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps } from '@mui/material';
import { fm } from 'Components/FormatMessage';

import { dialogActions, dialogCancel, dialogContent, dialogTitle } from '../../config/theme/componentTheme';
import { actions } from '../../store/documents/actions';

import ActionButton from '../ActionButton';
import FormRow from '../FormRow';
import InputHandler from '../InputHandler';
import { useAppDispatch } from 'Store/index';

const DialogPaper = (props: PaperProps): React.JSX.Element => <Paper square={true} {...props} />;

const MedicationStartDialog = ({ document, openState }: IMedicationStartDialogProps): React.JSX.Element => {
  const [open, setOpen] = openState;

  const [thisFormData, setThisFormData] = React.useState<{ usageStartDate?: IMedication['usageStartDate'] }>({
    usageStartDate: document.usageStartDate,
  });

  const onChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name] as string[];
    setThisFormData({
      ...thisFormData,
      [name]: value,
    });
  };

  const dispatch = useAppDispatch();
  const saveHandler = (): void => {
    if (document._id) {
      actions.modifyField(
        { name: 'medication', id: document._id },
        document,
        { usageStartDate: thisFormData.usageStartDate },
        true,
      )(dispatch);
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} maxWidth={'md'} fullWidth={true} PaperComponent={DialogPaper}>
      <DialogTitle style={dialogTitle}>{fm('medication.startDate')}</DialogTitle>
      <DialogContent style={dialogContent}>
        <FormRow title="medication.startDate">
          <InputHandler
            type="PartialDate"
            editing={false}
            name="startDate"
            formData={{ document: { startDate: document.startDate }, onChange: onChange }}
          />
        </FormRow>
        <FormRow title="medication.startTime">
          <InputHandler
            type="TimePicker"
            editing={false}
            name="startTime"
            formData={{
              document: { startTime: document.startTime },
              onChange: onChange,
            }}
          />
        </FormRow>
        <FormRow title="medication.usageStartDate">
          <InputHandler
            type="PartialDate"
            editing={true}
            name="usageStartDate"
            formData={{ document: thisFormData, onChange: onChange }}
          />
        </FormRow>
      </DialogContent>
      <DialogActions style={dialogActions}>
        <div style={dialogCancel} onClick={(): void => setOpen(false)}>
          {fm('general.cancel')}
        </div>
        <ActionButton text="general.save" onClick={saveHandler} width={15} height={4} fontSize={16} />
      </DialogActions>
    </Dialog>
  );
};

interface IMedicationStartDialogProps {
  document: IMedication;
  openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export default MedicationStartDialog;
