import * as React from 'react';
import { Container, Item } from 'Components/Grid';
import { formatPartialDate, isPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { styled } from '@mui/system';
import colors from '../../../../../config/theme/colors';
import InputHandler from 'Components/InputHandler';
import Unit from 'Components/Unit';
import { defaultTDCSTreatmentUnits } from '../../config';
import { StyledTableControls } from 'Components/EventStepper/components';
import { subjectOfTreatmentNames } from 'Routes/DoctorsOrders/Document/config';
import { equals } from 'ramda';
import {
  convertTreatmentEfficiencyToRatingFormat,
  createSubjectOfTreatmentNameObj as createSOTNameObj,
  getSessionNumber,
  getTdcsRowData,
  tdcsFields,
  TSOTNameObj,
  valueDiffersFromDoctorsOrders,
} from 'Utility/ninmtUtil';
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps } from '@mui/material';
import { dialogActions, dialogContent, dialogTitle } from '../../../../../config/theme/componentTheme';
import ActionButton from 'Components/ActionButton';
import ToolTip from 'Components/ToolTip';
import { capitalize } from 'Utility/string';
import { TDCSContext } from '../..';

const StyledEventContainer = styled(Container)({
  width: 'auto !important',
  margin: '0 -2.5rem 3rem -2.5rem',
  padding: '0 2.5rem',
});

const StyledBarHeaderContainer = styled(StyledEventContainer)({
  backgroundColor: `${colors.defaultBackground}`,
  height: '5rem',
});

const StyledTableContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'arrLength',
})(({ index, arrLength }: { index: number; arrLength: number }) => ({
  alignItems: 'stretch',
  marginBottom: index < arrLength - 1 ? undefined : '2.5rem',
  padding: '0.4rem 0 0.4rem 0',
  borderTop: index === 0 ? `1px solid ${colors.gray}` : undefined,
  backgroundColor: index % 2 === 0 ? colors.lightestGray : undefined,
}));

const StyledTableItem = styled(Item, {
  shouldForwardProp: (prop) => prop !== 'firstCell',
})(({ firstCell }: { firstCell: boolean }) => ({
  wordBreak: firstCell ? undefined : ('normal' as const),
  wordWrap: 'normal' as const,
  color: firstCell ? colors.tertiaryText : undefined,
  paddingLeft: firstCell ? '1rem' : undefined,
  paddingRight: '1rem',
}));

const StyledTableSecondaryItem = styled(Item, {
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'highlightColor',
})(({ index, highlightColor }: { index: number; highlightColor?: boolean }) => ({
  backgroundColor:
    index % 2 === 0
      ? highlightColor
        ? colors.highlight.light
        : colors.lightestGray
      : highlightColor
        ? colors.highlight.lightest
        : undefined,
  margin: '-0.4rem 1rem -0.4rem -1rem',
  padding: '0.4rem 0 0.4rem 1rem',
}));

const StyledTitleBox = styled('div')({
  width: '1rem',
  height: '1.9rem',
  border: `1px solid ${colors.warning.default}`,
  backgroundColor: colors.warning.lightest,
  marginRight: '1rem',
});

const StyledTitleItem = styled(Item)({
  fontSize: '1.6rem',
  fontWeight: 'bold',
  color: colors.darkGray,
});

const StyledSelectButton = styled('span')({
  '&:hover': {
    cursor: 'Pointer',
    textDecoration: 'underline',
  },
});

const NameContainer = styled(Container)({
  color: colors.tertiaryText,
  padding: '0 0 5px 0',
});

const DialogPaper = (props: PaperProps): React.JSX.Element => <Paper square={true} {...props} />;

const nameFormatter = (t: TSOTNameObj): React.JSX.Element => {
  const name =
    t.name && t.name !== 'other'
      ? subjectOfTreatmentNames.includes(t.name)
        ? fm(`tdcs.subjectOfTreatmentNames.${t.name}`)
        : t.name
      : t.nameOther
        ? t.nameOther
        : '-';
  const specifier = t.specifier ? ` ${t.specifier}` : '';
  return (
    <span>
      {name}
      {specifier}
    </span>
  );
};

const FormattedPatientsRatingValue = ({
  ratingByLocation,
  name,
}: {
  ratingByLocation: TTDCSRatingByLocation;
  name: 'intensity' | 'harm';
}): React.JSX.Element => {
  const beforeValue = ratingByLocation[name as 'intensity' | 'harm']?.beforeTreatment;
  const afterValue = ratingByLocation[name as 'intensity' | 'harm']?.afterTreatment;
  return (
    <Container>
      <Item xs={2} style={{ fontWeight: 600 }}>
        <ToolTip
          cursor={!beforeValue ? 'Default' : 'Pointer'}
          description={fm('tdcs.patientsRating.beforeTreatment')}
          hover={true}
          disabled={!beforeValue}
          content={<span>{beforeValue || beforeValue === 0 ? beforeValue.toFixed(1) : '-'}</span>}
        />
      </Item>
      <Item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
        {'➞'}
      </Item>
      <Item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 600 }}>
        <ToolTip
          cursor={!afterValue ? 'Default' : 'Pointer'}
          description={fm('tdcs.patientsRating.afterTreatment')}
          hover={true}
          disabled={!afterValue}
          content={<span>{afterValue || afterValue === 0 ? afterValue.toFixed(1) : '-'}</span>}
        />
      </Item>
    </Container>
  );
};

const FormattedTDCSTreatmentValue = ({
  name,
  value,
  currentSession,
  currentIndex,
}: {
  name: string;
  value: any;
  currentSession?: ITDCSSession;
  currentIndex?: number;
}): React.JSX.Element => {
  const additionalStyle =
    name !== 'additionalInformation' && valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value)
      ? { color: colors.white, backgroundColor: colors.appBlue.default, padding: '0 2px 0 2px', borderRadius: '0.5rem' }
      : undefined;
  return (
    <Unit unit={name === 'current' || name === 'currentDuration' ? defaultTDCSTreatmentUnits[name] : undefined}>
      <ToolTip
        title={fm('tdcs.subjectOfTreatment.valueInDoctorsOrders')}
        cursor={valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value) ? 'Pointer' : 'Default'}
        description={
          <>
            {valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value, undefined, true)
              ? valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value, undefined, true)
              : '-'}
          </>
        }
        flipTitleAndDescStyle
        hover={true}
        disabled={!valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value)}
        content={
          <span
            style={{
              fontWeight: 600,
              ...additionalStyle,
            }}
          >
            {['type', 'cathode', 'anode'].includes(name) && value
              ? fm(`tdcs.opts.subjectOfTreatment.${value}`)
              : value && `${value}`.length > 0
                ? (value as string | number)
                : '-'}
          </span>
        }
      ></ToolTip>
    </Unit>
  );
};

const deleteReasonFormatter = (t: ITDCSTreatment): string | React.JSX.Element =>
  t.deleteReason ? (
    <React.Fragment>
      <div
        style={{
          fontWeight: 600,
          color: t.deleteReason === 'partOfTreatmentProtocol' ? colors.tertiaryText : colors.error.default,
        }}
      >
        {fm(
          `tdcs.subjectOfTreatment.${
            t.deleteReason === 'partOfTreatmentProtocol' ? 'accordingToProtocol' : 'contraryToProtocol'
          }`,
        )}
      </div>
      <div style={{ color: colors.tertiaryText, textTransform: 'lowercase' }}>
        {fm('tdcs.subjectOfTreatment.noTreatmentGiven')}
      </div>
    </React.Fragment>
  ) : (
    ''
  );

const TreatmentsByProtocol = ({
  events,
  secondaryEvents = [],
  headers = [...tdcsFields],
  eventsBeyondThisDoc,
}: ITreatmentsByProtocol): React.JSX.Element => {
  const tdcsContext = React.useContext(TDCSContext);
  const { sortedAndMergedMyDocuments } = tdcsContext;

  // Used to change the range of events displayed in table view
  const [eventRange, setEventRange] = React.useState<[number, number]>([0, 3]);

  const changeEventRange = (change: number) => (): void => {
    let newRange: React.SetStateAction<[number, number]> = [...eventRange];
    newRange = [newRange[0] + change, newRange[1] + change];
    setEventRange(newRange);
  };

  // Dialog to select fields filtered by infoFilter
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const openCloseDialog = () => (): void => {
    !dialogOpen ? setDialogOpen(true) : setDialogOpen(false);
  };

  let allEventsSorted: Array<ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent> = [];
  allEventsSorted = (
    [...(events ?? []), ...(secondaryEvents ?? [])] as Array<ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent>
  )
    .sort((s1, s2) => (s2?.createDate ?? 0) - (s1?.createDate ?? 0))
    .sort((s1, s2) => sortPartialDate(s2.date, s1.date));
  const allEvents = allEventsSorted.length > 0 ? allEventsSorted : events;

  // Docs are ordered oldest --> newest in table view
  const docs = allEvents?.slice().reverse();

  type TSymptomAndLocation = {
    symptom: ISymptomsAndLocations['symptom'];
    location?: ISymptomsAndLocations['symptomLocation'];
    description?: ISymptomsAndLocations['symptomDescription'];
  };
  const patientsRatingLocations: Array<TSymptomAndLocation> = [];

  // Get unique name combinations (to be treated as individual protocols)
  const namesOfSOT: TSOTNameObj[] = [];

  if (Array.isArray(events)) {
    events.forEach((d) => {
      const treatmentEfficiencyDocs = sortedAndMergedMyDocuments.filter(
        (d2) => partialDateToValue(d2.date) === partialDateToValue(d.date) && d2._type === 'treatmentEfficiency',
      ) as ITreatmentEfficiency[];

      const before = treatmentEfficiencyDocs.find((d) => d.timing === 'before');
      const after = treatmentEfficiencyDocs.find((d) => d.timing === 'after');

      const treatmentEfficiencyRating =
        before && convertTreatmentEfficiencyToRatingFormat(before && after ? [before, after] : before);

      const usedRating = treatmentEfficiencyRating ? treatmentEfficiencyRating : d.patientsRating;

      usedRating?.forEach((p) => {
        p.ratingByLocation?.forEach((r) => {
          const sl = { symptom: p.symptom, location: r.location, description: r.description };
          if (!patientsRatingLocations.some((prl) => equals(prl, sl))) patientsRatingLocations.push(sl);
        });
      });

      d.subjectOfTreatment?.forEach((s) => {
        const sot = createSOTNameObj(s);
        if (sot && sot.name && !namesOfSOT.some((name) => equals(sot, name))) namesOfSOT.push(sot);
      });
    });
  }

  if (patientsRatingLocations.length > 0 && !headers.includes('patientsRating')) {
    headers.unshift('patientsRating');
  }

  const [headersFiltered, setHeadersFiltered] = React.useState<typeof headers>(headers);

  const onChangeHeadersFiltered = (value: TOnChangeValues): void => {
    setHeadersFiltered(headers.filter((h) => (value[Object.keys(value)[0]] as typeof headers).includes(h)));
  };

  // Make date headers
  const dates = docs?.map((d) => (isPartialDate(d.date) ? formatPartialDate(d.date) : ''));

  // If the length of sessions is more than 4, set eventRange to the "end" of sessions by default
  React.useEffect(() => {
    Array.isArray(docs) &&
      docs.length > 4 &&
      !equals(eventRange, [docs.length - 4, docs.length - 1]) &&
      setEventRange([docs.length - 4, docs.length - 1]);
  }, [Array.isArray(docs) && docs.length]);

  return (
    <React.Fragment>
      <Dialog
        open={dialogOpen}
        fullWidth={true}
        PaperComponent={DialogPaper}
        sx={{ '& .MuiDialog-container': { '& .MuiPaper-root': { maxWidth: '494px' } } }}
      >
        <DialogTitle style={dialogTitle}>
          <Container style={{ alignItems: 'baseline' }}>
            <Item xs={7}>{fm('tdcs.selectInformation')}</Item>
            <Item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1.4rem', fontWeight: 400 }}>
              <StyledSelectButton onClick={() => setHeadersFiltered(headers)}>
                {fm('tdcs.selectAll')}
              </StyledSelectButton>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <StyledSelectButton onClick={() => setHeadersFiltered([])}>{fm('tdcs.deselectAll')}</StyledSelectButton>
            </Item>
          </Container>
        </DialogTitle>
        <DialogContent style={dialogContent}>
          {dialogOpen && (
            <InputHandler
              name="headersFiltered"
              type="Checkbox"
              editing={true}
              formData={{ document: { headersFiltered: headersFiltered }, onChange: onChangeHeadersFiltered }}
              options={headers}
              optionFormatter={(id: string | number | boolean) =>
                id === 'patientsRating'
                  ? fm('tdcs.patientsRating.title')
                  : fm(`tdcs.subjectOfTreatment.${id}${id === 'additionalInformation' ? 'Subject' : ''}`)
              }
            />
          )}
        </DialogContent>
        <DialogActions style={dialogActions}>
          <ActionButton text="general.accept" onClick={openCloseDialog()} width={12} height={3} fontSize={16} />
        </DialogActions>
      </Dialog>
      <StyledBarHeaderContainer alignItems="center" justifyContent="space-between">
        <Item xs={2}>
          <InputHandler
            name="headersFilter"
            type="RadioButtonRow"
            editing={true}
            formData={{
              document: { headersFilter: headersFiltered.length === headers.length ? 'all' : 'selected' },
              onChange: (values: TOnChangeValues) => {
                const value = values['headersFilter'];
                if (value === 'selected' || (!equals(headersFiltered, headers) && value === null)) openCloseDialog()();
                else setHeadersFiltered(headers);
              },
            }}
            options={['all', 'selected']}
            optionFormatter={(id: string | number | boolean) => fm(`tdcs.${id}Information`)}
          />
        </Item>
        <Item xs={true} style={{ marginRight: '-2.5rem' }}>
          {(docs?.length ?? 0) > 1 && (
            <StyledTableControls eventRange={eventRange} tableEvents={docs ?? []} changeEventRange={changeEventRange} />
          )}
        </Item>
      </StyledBarHeaderContainer>
      <React.Fragment>
        <Container
          style={{
            marginBottom:
              patientsRatingLocations.length > 0 && headersFiltered.includes('patientsRating') ? '1.5rem' : 0,
            fontWeight: 'bold',
          }}
        >
          <StyledTableItem xs={3} firstCell />
          {dates?.slice(eventRange[0], eventRange[1] + 1).map((d, i) => (
            <Item key={`${d} ${i}`} xs={2}>
              {d}
            </Item>
          ))}
        </Container>
        <Container>
          <StyledTableItem xs={3} firstCell={true} style={{ paddingLeft: 0 }}>
            <Container>
              <Item xs={true}>{fm('tdcs.session')}</Item>
            </Container>
          </StyledTableItem>
          {docs
            ?.slice(eventRange[0], eventRange[1] + 1)
            .map((e: ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent, i: number) => {
              let sessionType;
              if ('type' in e) {
                sessionType = fm(`tdcs.opts.eventTypes.${e.type}`);
              } else if (secondaryEvents?.includes(e)) {
                sessionType = fm('tdcs.opts.eventTypes.unusedSession');
              } else if (events?.includes(e)) {
                sessionType = fm('tdcs.opts.eventTypes.session');
              } else sessionType = '';
              return (
                <StyledTableItem key={i} firstCell={false} xs={2} style={{ fontWeight: 600 }}>
                  {sessionType}
                </StyledTableItem>
              );
            })}
        </Container>
        <Container
          style={{
            marginBottom:
              patientsRatingLocations.length > 0 && headersFiltered.includes('patientsRating') ? '1.5rem' : 0,
            fontWeight: 'bold',
          }}
        >
          <StyledTitleItem xs={3}>
            {patientsRatingLocations.length > 0 &&
              headersFiltered.includes('patientsRating') &&
              fm('tdcs.patientsRating.title')}
          </StyledTitleItem>
        </Container>
        {patientsRatingLocations.length > 0 &&
          headersFiltered.includes('patientsRating') &&
          patientsRatingLocations.map((sl, index, slArr) => (
            <React.Fragment key={index}>
              <NameContainer>
                <Item xs={3} style={{ minHeight: 30 }}>
                  {sl.symptom === 'other' ? (
                    <div style={{ paddingRight: '1.5rem' }}>
                      {fm('tdcs.patientsRating.opts.other')}
                      {sl.description && sl.description.length > 0 && (
                        <ToolTip
                          cursor={sl.description.length > 30 ? 'Pointer' : 'Default'}
                          description={sl.description}
                          hover={true}
                          disabled={sl.description.length <= 30}
                          content={
                            <span>
                              {sl.description.length > 30
                                ? `: ${sl.description.slice(0, 30)}...`
                                : `: ${sl.description}`}
                            </span>
                          }
                        />
                      )}
                    </div>
                  ) : sl.location ? (
                    fm(`tdcs.patientsRating.opts.${sl.location}`)
                  ) : (
                    ''
                  )}
                </Item>
              </NameContainer>
              {['intensity', 'harm'].map((name, ind, arr) => {
                return (
                  <StyledTableContainer
                    key={ind}
                    index={ind}
                    arrLength={arr.length}
                    style={{ marginBottom: index === slArr.length - 1 || ind < arr.length - 1 ? 0 : '1.5rem' }}
                  >
                    <StyledTableItem xs={3} firstCell={true}>
                      {fm(`tdcs.patientsRating.${sl.symptom === 'other' ? 'symptom' : sl.symptom}${capitalize(name)}`)}
                    </StyledTableItem>
                    {docs?.slice(eventRange[0], eventRange[1] + 1).map((e: ITDCSSession, i: number) => {
                      const treatmentEfficiencyDocs = sortedAndMergedMyDocuments.filter(
                        (d2) =>
                          partialDateToValue(d2.date) === partialDateToValue(e.date) &&
                          d2._type === 'treatmentEfficiency',
                      ) as ITreatmentEfficiency[];

                      const before = treatmentEfficiencyDocs.find((d) => d.timing === 'before');
                      const after = treatmentEfficiencyDocs.find((d) => d.timing === 'after');

                      const treatmentEfficiencyRating =
                        before && convertTreatmentEfficiencyToRatingFormat(before && after ? [before, after] : before);

                      const usedRating = treatmentEfficiencyRating ? treatmentEfficiencyRating : e.patientsRating;

                      const patientsRating = usedRating?.find(
                        (p) => p.ratingByLocation?.some((r) => r.location === sl.location),
                      );
                      const ratingByLocation = patientsRating?.ratingByLocation?.find(
                        (r) => r.location === sl.location,
                      );
                      return secondaryEvents?.includes(e) ? (
                        <StyledTableSecondaryItem key={i} index={ind} xs={2} highlightColor={!('type' in e)}>
                          <span style={{ visibility: 'hidden' }}>{'-'}</span>
                        </StyledTableSecondaryItem>
                      ) : (
                        <StyledTableItem key={i} firstCell={false} xs={2}>
                          {ratingByLocation && (
                            <FormattedPatientsRatingValue
                              ratingByLocation={ratingByLocation}
                              name={name as 'intensity' | 'harm'}
                            />
                          )}
                        </StyledTableItem>
                      );
                    })}
                  </StyledTableContainer>
                );
              })}
            </React.Fragment>
          ))}
      </React.Fragment>
      <Container style={{ padding: '1.6rem 0 1.6rem 0', marginBottom: '1.3rem' }}>
        <StyledTableItem xs={3} firstCell={true} style={{ paddingLeft: 0 }}>
          <Container>
            <Item xs={true}>{fm('tdcs.additionalInformationSession')}</Item>
          </Container>
        </StyledTableItem>
        {docs
          ?.slice(eventRange[0], eventRange[1] + 1)
          .map((e: ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent, i: number) => {
            return (
              <StyledTableItem key={i} firstCell={false} xs={2} style={{ fontWeight: 600 }}>
                {'additionalInformation' in e ? e.additionalInformation : 'details' in e ? e.details : ''}
              </StyledTableItem>
            );
          })}
      </Container>
      {namesOfSOT.map((nameObj, arrayIndex) => (
        <React.Fragment key={arrayIndex}>
          <Container style={{ marginBottom: '2rem', marginTop: arrayIndex > 0 ? '2.5rem' : undefined }}>
            <StyledTitleItem xs={3}>{nameFormatter(nameObj)}</StyledTitleItem>
          </Container>
          {Array.isArray(headersFiltered) &&
            headersFiltered
              .filter((field) => tdcsFields.includes(field))
              .map((field, index, arr) => {
                const getHeader = (f: string): React.JSX.Element => {
                  const localizationPath = 'tdcs.subjectOfTreatment.';
                  if (f === 'additionalInformation') {
                    return fm(localizationPath + 'additionalInformationSubject');
                  }
                  return fm(localizationPath + f);
                };

                return (
                  <StyledTableContainer
                    key={index}
                    index={index}
                    arrLength={arr.length}
                    style={{ marginBottom: index === arr.length - 1 ? 0 : undefined }}
                  >
                    <StyledTableItem xs={3} firstCell={true}>
                      {getHeader(field)}
                    </StyledTableItem>
                    {docs?.slice(eventRange[0], eventRange[1] + 1).map((e: ITDCSSession, i: number, arr) => {
                      if (secondaryEvents?.includes(e)) {
                        return (
                          <StyledTableSecondaryItem key={i} index={index} xs={2} highlightColor={!('type' in e)}>
                            <span style={{ visibility: 'hidden' }}>{'-'}</span>
                          </StyledTableSecondaryItem>
                        );
                      } else {
                        const treatments = e.subjectOfTreatment?.filter((s) => equals(createSOTNameObj(s), nameObj));
                        const treatment = treatments?.find((t) => equals(createSOTNameObj(t), nameObj));

                        const { name, value, fieldMatchesType } = getTdcsRowData(
                          field as keyof ITDCSTreatmentEvent,
                          treatment ?? { id: '', name: '' },
                        );

                        const allSessions = eventsBeyondThisDoc?.slice().reverse() ?? [];
                        const sessionIndex = allSessions.findIndex((d) => equals(d, e));
                        const treatmentIndex = e.subjectOfTreatment?.findIndex((s) => equals(s, treatment)) ?? 0;

                        const sessionNumber = getSessionNumber(
                          allSessions,
                          sessionIndex,
                          treatment ?? ({} as ITDCSTreatment),
                          true,
                        );

                        return (
                          <StyledTableItem key={i} firstCell={false} xs={index === 0 && i === arr.length - 1 ? 1 : 2}>
                            {treatment?.deleted ? (
                              field === 'additionalInformation' ? (
                                deleteReasonFormatter(treatment)
                              ) : (
                                ''
                              )
                            ) : field === 'session' ? (
                              sessionNumber || ''
                            ) : fieldMatchesType ? (
                              treatment ? (
                                <FormattedTDCSTreatmentValue
                                  name={name}
                                  value={value}
                                  currentSession={e}
                                  currentIndex={treatmentIndex}
                                />
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </StyledTableItem>
                        );
                      }
                    })}
                  </StyledTableContainer>
                );
              })}
        </React.Fragment>
      ))}
      {secondaryEvents.length > 0 && (
        <Container style={{ padding: '1.6rem 0 1.6rem 0' }}>
          <StyledTableItem xs={3} firstCell={true}>
            <Container>
              <Item xs={1} style={{ display: 'flex', alignSelf: 'center' }}>
                <StyledTitleBox />
              </Item>
              <Item xs={true}>{fm('tdcs.unusedSessionAndReason')}</Item>
            </Container>
          </StyledTableItem>
          {docs
            ?.slice(eventRange[0], eventRange[1] + 1)
            .map((e: ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent, i: number) => {
              if (secondaryEvents?.includes(e) && !('type' in e)) {
                const reason = (e as ITDCSUnusedSession).reason;
                const reasonDetails = (e as ITDCSUnusedSession).reasonDetails;
                const reasonDetailsOther = reasonDetails === 'other' && (e as ITDCSUnusedSession).reasonDetailsOther;
                return (
                  <StyledTableSecondaryItem
                    key={i}
                    index={0}
                    xs={2}
                    style={{
                      margin: '-1.6rem 1rem -1.6rem -1rem',
                      padding: '1.6rem 0 1.6rem 1rem',
                      backgroundColor: headersFiltered.length % 2 ? colors.highlight.lightest : colors.highlight.light,
                      fontWeight: 600,
                    }}
                  >
                    <div>{reason ? fm(`tdcs.opts.unusedSessionReason.${reason}`) : '-'}</div>
                    {reason && (
                      <div>
                        {reasonDetails ? fm(`tdcs.opts.unusedSessionReasonDetails.${reasonDetails}`) : '-'}
                        {reasonDetails === 'other' &&
                          reasonDetailsOther &&
                          reasonDetailsOther.length > 0 &&
                          `: ${reasonDetailsOther}`}
                      </div>
                    )}
                  </StyledTableSecondaryItem>
                );
              } else {
                return <StyledTableItem key={i} firstCell={false} xs={2} />;
              }
            })}
        </Container>
      )}
    </React.Fragment>
  );
};

interface ITreatmentsByProtocol {
  events?: Array<ITDCSSession>;
  secondaryEvents?: Array<ITDCSSession>;
  headers?: Array<string>;
  eventsBeyondThisDoc?: Array<ITDCSSession>;
}

export default TreatmentsByProtocol;
