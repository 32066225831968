import * as React from 'react';
import InputHandler from 'Components/InputHandler';
import { MyServiceContext } from '../../..';
import { isLocaleKey, ninmtPreInquiry } from 'neuro-schemas';
import { sleepFields } from './config';
import TableContent from '../components/TableContent';
import Unit from 'Components/Unit';

const SleepWindow = ({
  parentDocument,
  document = {},
}: {
  parentDocument: ININMTPreInquiry & IControlProps;
  document: ININMTPreInquiry['sleep'];
}): React.JSX.Element => {
  const ninmtPreInquiryLocales = ninmtPreInquiry.localizations;
  const myServiceContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing } = myServiceContext;

  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const fmNINMTPreInquiry = (id: string) => ninmtPreInquiryLocales[useLocale][id];

  const handleFieldChange = (e: TOnChangeValues): void => {
    const name = Object.keys(e)[0];
    const value = e[name];
    setEditingData?.({ ...parentDocument, sleep: { ...document, [name]: value } });
  };

  const isEditing = !viewing && !!editing;

  return (
    <TableContent>
      {[
        ...(sleepFields.map((field) => ({
          title: {
            content:
              fmNINMTPreInquiry(`sleep.${field.name}`) +
              (field.name === 'sleepQuantity' ? ` ${fm('myService.ninmt.averageInADay').toLocaleLowerCase()}` : '') +
              ` ${fm('myService.ninmt.withinAWeek').toLocaleLowerCase()}`,
          },
          content: {
            content: (
              <Unit unit={field.name === 'sleepQuantity' ? fm('general.hours', { N: 2 }).split(' ')[1] : undefined}>
                <InputHandler
                  type={field.type as any}
                  name={field.name}
                  editing={isEditing}
                  formData={{
                    onChange: (e) => handleFieldChange(e),
                    document: { [field.name]: document[field.name as keyof typeof document] },
                  }}
                  options={field.options}
                  optionFormatter={
                    field.name === 'sleepQuantity'
                      ? (id: string | number): string => fm('general.hours', { N: id })
                      : undefined
                  }
                  width={field.name === 'sleepQuantity' ? 6 : undefined}
                  placeholder={field.placeholder}
                  disablePlaceholderFormatting
                />
              </Unit>
            ),
          },
        })) as []),
        {
          title: {
            content: `0 = ${fm('myService.ninmt.ninmtPreInquiry.sleepMinInfo')} 10 = ${fm(
              'myService.ninmt.ninmtPreInquiry.sleepMaxInfo',
            )} (NRS)`,
            isInfo: true,
          },
          content: [
            {
              content: '',
            },
          ],
        },
      ]}
    </TableContent>
  );
};

export default SleepWindow;
