import * as React from 'react';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext } from '../../../../../containers/FormContextHandler';

export const steps = [
  'difficultyToFallAsleep',
  'timeToFallAsleep',
  'wakingUpAtNightRegularity',
  'wakeupToUrinateRegularity',
  'wakingUpAtNightAmount',
  'wakeupToUrinateAmount',
  'prescriptionSleepMedicationPast3Months',
  'veryTiredAfterWaking',
  'tiredDuringDay',
  'sleepyDuringDay',
  'fightAgainstFallingAsleepDuringWeek',
  'fightAgainstFallingAsleepDuringWeekend',
  'restlessLegsSymptomsPast3months',
  'restlessLegsDiagnosed',
] as Array<string>;

export const getStepsLocalesGetter = (fm: IFormContext['fm']) => (step: string | number) =>
  fm('diagnosticSurvey.bnsq.' + step);

const dayRegularity: Array<TBNSQDayRegularity> = [
  'neverOrLessThanOncePerMonth',
  'lessThanOncePerWeek',
  '1to2DaysPerWeek',
  '3to5DaysPerWeek',
  'dailyOrAlmostDaily',
];

const nightRegularity: Array<TBNSQNightRegularity> = [
  'neverOrLessThanOncePerMonth',
  'lessThanOncePerWeek',
  '1to2NightsPerWeek',
  '3to5NightsPerWeek',
  'everyNightOrAlmostEveryNight',
];

export const getStepContentGetter =
  (fm: IFormContext['fm'], viewing = false) =>
  (
    stepName: string,
  ): {
    opts: string[];
    optsLocale: (o: number | string) => string;
    height?: number;
    instruction?: React.JSX.Element;
    customElements?: (formData: IFormData<IBNSQ>) => React.JSX.Element;
    nextButton?: boolean;
  } => {
    switch (stepName) {
      case 'difficultyToFallAsleep':
        return {
          opts: dayRegularity,
          optsLocale: (o: string | number) =>
            fm('diagnosticSurvey.bnsq.opts.' + o + `${o === 'neverOrLessThanOncePerMonth' ? 'B' : ''}`),
        };
      case 'timeToFallAsleep':
        return {
          opts: ['over40Minutes', '31-40minutes', '21-30minutes', '10-20minutes', 'under10minutes'],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
        };
      case 'wakingUpAtNightRegularity':
      case 'wakeupToUrinateRegularity':
        return {
          opts: nightRegularity,
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
        };
      case 'wakingUpAtNightAmount':
        return {
          opts: ['dontWakeUpAtNight', 'onceANight', 'twiceANight', '3-4TimesANight', 'atLeast5TimesANight'],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
        };
      case 'wakeupToUrinateAmount':
        return {
          opts: ['notOnce', 'onceANight', 'twiceANight', '3-4TimesANight', 'atLeast5TimesANight'],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          instruction: <>{fm('diagnosticSurvey.bnsq.wakeupToUrinateAmount')}</>,
        };
      case 'prescriptionSleepMedicationPast3Months':
        return {
          opts: dayRegularity,
          optsLocale: (o: string | number) =>
            fm('diagnosticSurvey.bnsq.opts.' + o + `${o === 'neverOrLessThanOncePerMonth' ? 'C' : ''}`),
          customElements: (formData) => (
            <FormRow title="diagnosticSurvey.bnsq.prescriptionSleepMedicationPast3MonthsWhich">
              <InputHandler
                name="prescriptionSleepMedicationPast3MonthsWhich"
                type="TextArea"
                formData={formData}
                editing={!viewing}
              />
            </FormRow>
          ),
          nextButton: true,
        };
      case 'veryTiredAfterWaking':
      case 'tiredDuringDay':
      case 'sleepyDuringDay':
        return {
          opts: dayRegularity,
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
        };
      case 'fightAgainstFallingAsleepDuringWeek':
        return {
          opts: dayRegularity,
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          instruction: <>{fm('diagnosticSurvey.bnsq.fightAgainstFallingAsleepDuringWeek')}</>,
        };
      case 'fightAgainstFallingAsleepDuringWeekend':
        return {
          opts: dayRegularity,
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          instruction: <>{fm('diagnosticSurvey.bnsq.fightAgainstFallingAsleepDuringWeekend')}</>,
        };
      case 'restlessLegsSymptomsPast3months':
        return {
          opts: [
            'neverOrLessThanOncePerMonth',
            'lessThanOncePerWeek',
            '1to2EveningsOrNightsPerWeek',
            '3to5EveningsOrNightsPerWeek',
            'everyEveningOrNightOrAlmostEveryEveningOrNight',
          ],
          optsLocale: (o: string | number) =>
            fm('diagnosticSurvey.bnsq.opts.' + o + `${o === 'neverOrLessThanOncePerMonth' ? 'D' : ''}`),
          instruction: <>{fm('diagnosticSurvey.bnsq.restlessLegsSymptomsPast3monthsInfotext')}</>,
        };
      case 'restlessLegsDiagnosed':
        return {
          opts: ['no', 'yes'],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
        };

      default:
        return { opts: [], optsLocale: () => '' };
    }
  };
