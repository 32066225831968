import { isNil } from 'Utility/ramdaReplacement';
import * as React from 'react';
import { OperationContext } from '../../config';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';

const optionFormatter = (s: string | number, p: string, fm?: (id: string) => string): string =>
  fm ? fm(`surgicalTreatment.localResection.${p}.${s}`) : s.toString();

const stepLabelText = (d: ILocalResectionLocation, fm?: (id: string) => string): string =>
  fm
    ? (d.location
        ? fm(`surgicalTreatment.localResection.locations.location.opts.${d.location}`) +
          (d.sideOfRemoval || d.areaOfRemoval ? ', ' : '')
        : '') +
      (d.sideOfRemoval
        ? fm(`surgicalTreatment.localResection.locations.sideOfRemoval.headerOpts.${d.sideOfRemoval}`) + ' '
        : '') +
      (d.areaOfRemoval
        ? fm(`surgicalTreatment.localResection.locations.areaOfRemoval.headerOpts.${d.areaOfRemoval}`)
        : '')
    : '';

const StepContent = ({ d, fm }: { d: ILocalResectionLocation; fm?: (id: string) => string }): React.JSX.Element => {
  const path = 'surgicalTreatment.localResection.locations';

  return (
    <React.Fragment>
      {['location', 'sideOfRemoval', 'areaOfRemoval'].map(
        (f) =>
          !!d[f as keyof ILocalResectionLocation] && (
            <StepperHeaderValuePair
              header={<>{fm ? fm(`${path}.${f}.title`) : ''}</>}
              value={fm ? fm(`${path}.${f}.opts.${d[f as keyof ILocalResectionLocation]}`) : '-'}
              key={f}
            />
          ),
      )}
      {d.location && d.location === 'other' ? (
        <StepperHeaderValuePair header={<>{fm ? fm(`${path}.location.other`) : ''}</>} value={d.otherInfo} />
      ) : null}
    </React.Fragment>
  );
};

const EditingElement = ({
  formData,
  editing,
  fm,
}: {
  formData: IFormData<ILocalResectionLocation>;
  editing: boolean;
  fm?: (id: string) => string;
}): React.JSX.Element => {
  const location = formData.document?.location;

  const path = 'surgicalTreatment.localResection.locations';

  return (
    <React.Fragment>
      <FormRow title={`${path}.location.title`}>
        <InputHandler
          type="Select"
          name="location"
          editing={editing}
          formData={formData}
          options={[
            'temporalFrontalResection',
            'temporalFrontalResectionHippocampus',
            'temporalFrontalResectionAmygdala',
            'amygdalaHippocampusSelectiveResection',
            'forebrainBlockArea',
            'crownBlockArea',
            'backOfTheSkullArea',
            'multipleBlockResection',
            'cingulateSulcus',
            'insularSulcus',
            'other',
          ]}
          optionFormatter={(id: string | number): string => optionFormatter(id, 'locations.location.opts', fm)}
          placeholder={`${path}.location.placeholder`}
          dependentFieldsList={(): string[] => ['otherInfo', 'sideOfRemoval', 'areaOfRemoval']}
        />
      </FormRow>
      {location && location !== 'other' ? (
        <React.Fragment>
          <FormRow title={`${path}.sideOfRemoval.title`}>
            <InputHandler
              type="Radio"
              name="sideOfRemoval"
              editing={editing}
              formData={formData}
              options={['left', 'right']}
              optionFormatter={(id: string | number): string => optionFormatter(id, 'locations.sideOfRemoval.opts', fm)}
              placeholder={`${path}.sideOfRemoval.placeholder`}
            />
          </FormRow>
          <FormRow title={`${path}.areaOfRemoval.title`}>
            <InputHandler
              type="Radio"
              name="areaOfRemoval"
              editing={editing}
              formData={formData}
              options={['partial', 'full']}
              optionFormatter={(id: string | number): string => optionFormatter(id, 'locations.areaOfRemoval.opts', fm)}
              placeholder={`${path}.areaOfRemoval.placeholder`}
            />
          </FormRow>
        </React.Fragment>
      ) : undefined}
      {location === 'other' ? (
        <FormRow title={`${path}.location.other`}>
          <InputHandler
            type="TextArea"
            name="otherInfo"
            editing={editing}
            formData={formData}
            placeholder={`${path}.location.otherPlaceholder`}
          />
        </FormRow>
      ) : undefined}
    </React.Fragment>
  );
};

const LocalResection = ({ formData, editing, view, fm }: IFormContext): React.JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const { document, onChange } = formData as IFormData<ISurgicalTreatment>;

  const path = 'surgicalTreatment.localResection.locations';

  return (
    <React.Fragment>
      <OperationContext.Consumer>
        {({ operationIndex }): React.JSX.Element => {
          const allOperations = document.operations?.map((o) => o) || [];
          const localResection = allOperations?.[operationIndex ?? 0] as ILocalResection;
          const locations = localResection.locations;

          const localResectionOnChange = (values: TOnChangeValues): void => {
            const value = values['locations'];
            const newLocations = value;
            const changedOperation = { ...localResection, locations: newLocations };
            allOperations[operationIndex ?? 0] = changedOperation;
            onChange && onChange({ operations: allOperations });
          };

          return (
            <EventStepper
              name="locations"
              formData={{
                document: { locations: locations },
                onChange: localResectionOnChange,
              }}
              stepLabelText={(d: ILocalResectionLocation): string => stepLabelText(d, fm)}
              stepContent={(d: ILocalResectionLocation): React.JSX.Element => <StepContent d={d} fm={fm} />}
              addNewTextHeader={`${path}.title`}
              addNewTextButton={`${path}.add`}
              previousEventsTextHeader={`${path}.previous`}
              editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => {
                return (
                  <React.Fragment>
                    {!isNil(operationIndex) ? (
                      <EditingElement
                        formData={
                          { document: locations?.[index], onChange: onChange } as {
                            document: ILocalResectionLocation;
                            onChange: IFormData['onChange'];
                          }
                        }
                        editing={editingDoc}
                        fm={fm}
                      />
                    ) : undefined}
                  </React.Fragment>
                );
              }}
            />
          );
        }}
      </OperationContext.Consumer>
    </React.Fragment>
  );
};

export default withFormContext(LocalResection);
