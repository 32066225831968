import DataTable from 'Components/DataTable';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { nmsq, INMSQ, isLocaleKey } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import { Theme, useMediaQuery } from '@mui/material';
import { fm as formatterFM } from 'Components/FormatMessage';

const nmsqFields: Array<keyof INMSQ> = [
  'salivaDribbling',
  'lossOfSmellOrTaste',
  'difficultySwallowing',
  'vomitingOrNausea',
  'constipation',
  'bowelIncontinence',
  'unableToFullyEmptyBowel',
  'urgeToUrinate',
  'wakeUpDuringNightToUrinate',
  'unexplainedPains',
  'unexplainedChangesInWeight',
  'problemsRemembering',
  'lossOfInterestInEnvironmentAndActivities',
  'seeingOrHearingThingsThatAreNotReal',
  'difficultyConcentrating',
  'sadness',
  'anxiety',
  'changesInInterestInSexualActivities',
  'difficultyEngagingInSexualActivities',
  'dizziness',
  'falling',
  'difficultyStayingAwake',
  'difficultyFallingAsleep',
  'intenseDreams',
  'talkingInSleep',
  'urgeToMoveAround',
  'swellingOfFeet',
  'excessiveSweating',
  'doubleVision',
  'believeSomethingHappenedThatDidNot',
];

export const NMSQ = (): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing, setViewingObj, setEditingObj } = myServContext;
  const nmsqData = (editing?.data || viewing?.data) as INMSQ & IControlProps;
  const nmsqLocales = nmsq.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';
  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...nmsqData,
      [field]: value,
    });
  };

  // Track page width and breakpoints
  const mdOrSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down(1401));

  return (
    <>
      {!viewing ? (
        <div style={{ width: mdOrSmaller ? '100%' : '80%', color: colors.tertiaryText, marginBottom: '3rem' }}>
          <p>{nmsqLocales[useLocale]['surveyInfo1']}</p>
          <p>
            {formatterFM('myService.parkinson.nmsqSurveyInfo2', {
              strong: (chunks) => <span style={{ fontWeight: 600 }}>{chunks}</span>,
            })}
          </p>
        </div>
      ) : null}
      <BlockWrapper
        title={viewing ? formatPartialDate(nmsqData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'nmsq', data: nmsqData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: nmsqData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <div>
          <p style={{ color: colors.tertiaryText, fontSize: '2.2rem', fontWeight: 600 }}>
            {nmsqLocales[useLocale]['question']}
          </p>
        </div>
        <div
          style={{
            width: mdOrSmaller ? '100%' : '60%',
            marginBottom: '2rem',
            marginTop: viewing ? '6rem' : undefined,
          }}
        >
          <DataTable
            headers={['', '']}
            data={{
              rowData: nmsqFields.map((f) => {
                const rowSet = [
                  nmsqLocales[useLocale][f],
                  <div key={f} style={viewing ? { width: '15rem' } : { width: '20rem' }}>
                    <InputHandler
                      name={f}
                      type="Radio"
                      editing={!!editing}
                      formData={{
                        document: { [f]: nmsqData?.[f] },
                        onChange: onChangeSurveyForm,
                      }}
                      options={['yes', 'no']}
                      optionFormatter={(o) => nmsqLocales[useLocale][`opts.${o}`]}
                      horizontal
                    />
                  </div>,
                ];
                return rowSet;
              }),
              rowStyle: viewing ? undefined : nmsqFields.map(() => ({ rowAlign: 'top' })),
            }}
          />
        </div>
      </BlockWrapper>
      <div style={{ color: colors.tertiaryText }}>
        {fm('myService.parkinson.nmsqReferenceText').length > 1 && <p>{fm('myService.parkinson.nmsqReferenceText')}</p>}
        <p>{nmsqLocales[useLocale]['coverScreenDesc']}</p>
        <p>{nmsqLocales[useLocale]['copyright']}</p>
      </div>
    </>
  );
};
