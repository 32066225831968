/**
 * Creates a single header-value pair for history listing.
 * Vertical items are stacked vertically and a single item uses most of the space horizontally
 *
 * * Array typed values are prefixed with a Bullet
 */
import * as React from 'react';
import { styled } from '@mui/system';

import { Container, Item } from 'Components/Grid';

import colors from '../../config/theme/colors';
import { appendIDs } from '../../utility/appendIDs';
import { historyHeader, historyValue, historyValuePadding } from '../../config/theme/componentTheme';

const StyledWrapper = styled(Container)({
  '* > &:not(:last-of-type)': {
    marginBottom: '2rem',
  },
});

const StyledList = styled('div')({
  '& > div:not(:last-of-type)': {
    marginBottom: '1rem',
  },
});

const Bullet = styled('div')({
  width: '0.8rem',
  height: '0.8rem',
  borderRadius: '1rem',
  backgroundColor: colors.primary,
  marginRight: '2rem',
});

const DescriptionText = styled('div')({
  fontSize: '14px',
  padding: '1rem 10rem 0 0',
  color: colors.darkGray,
});

const displayValue = (value: IOwnProps['value'], noBullet: boolean): React.JSX.Element => {
  if (Array.isArray(value) && value.length > 1) {
    return (
      <StyledList>
        {appendIDs(value).map((val: { value: string | number | React.JSX.Element; id: string }) => (
          <React.Fragment key={val.id}>
            <Container alignItems="center">
              {!noBullet && (
                <Item>
                  <Bullet />
                </Item>
              )}
              <Item xs={true}>
                <div style={historyValue}>{val.value}</div>
              </Item>
            </Container>
          </React.Fragment>
        ))}
      </StyledList>
    );
  }
  return <div style={historyValue}>{Array.isArray(value) ? value[0] : value}</div>;
};

const HistoryRowVerticalItem = ({
  header,
  description,
  condition = true,
  value,
  headerWidth = 5, // Same as FormRow
  noBullet = false,
  noDash = false,
}: IOwnProps): React.JSX.Element | null =>
  condition ? (
    <StyledWrapper>
      <Container>
        <Item xs={headerWidth}>
          <div style={{ ...historyHeader, padding: historyValuePadding }}>{header}</div>
          {description && <DescriptionText>{description}</DescriptionText>}
        </Item>
        <Item xs={true} style={{ whiteSpace: 'pre-line' }}>
          {value || value === 0 ? displayValue(value, noBullet) : noDash ? undefined : '-'}
        </Item>
      </Container>
    </StyledWrapper>
  ) : null;

interface IOwnProps {
  header: string | number | React.JSX.Element;
  description?: string | React.JSX.Element;
  condition?: boolean;
  value?: string | number | React.JSX.Element | Array<string | number | React.JSX.Element> | null | false;
  headerWidth?: number | boolean;
  noBullet?: boolean;
  noDash?: boolean;
}

export default HistoryRowVerticalItem;
