import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';
import FormSection from '../../../../../components/FormSection';

import { steps as rulmSteps, getStepContent as getRULMSteps } from '../rulmSettings';
import { getPatientAgeInMonthsWithOneDecimal } from '../../../../../utility/patientInfo';
import { getMissingRulmSections, rulmScore, showPatientAgeInMonthsOnForm } from '../../../utils';
import InfoPopover from '../../../../../components/InfoPopover';

import { styled } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import colors from '../../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';

const StyledTitle = styled('b')({
  fontSize: '1.6rem',
  fontWeight: 600,
  color: colors.primary,
  textTransform: 'uppercase',
  marginRight: '2rem',
  marginLeft: '2lem',
});

const RULMForm = ({ formData, viewing }: IOwnProps): React.JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="motor.ageMonth" condition={showPatientAgeInMonthsOnForm(formData.document.date)}>
        <InputHandler
          type="TextField"
          editing={false}
          name="age"
          formData={{
            onChange: formData.onChange,
            document: {
              age: formData.document.date ? getPatientAgeInMonthsWithOneDecimal(formData.document.date) : '',
            },
          }}
          disabled={true}
        />
      </FormRow>
      <FormRow title="general.time" id={'time'}>
        <InputHandler type="TimePicker" editing={!viewing} name="startTime" formData={formData} timeDefault="now" />
      </FormRow>
      <FormRow title="motor.thoracolumbosacralOrthosis">
        <InputHandler
          type="Radio"
          editing={!viewing}
          name="thoracolumbosacralOrthosis"
          formData={formData}
          preset="yesno"
        />
      </FormRow>
      <FormRow
        title="motor.thoracolumbosacralOrthosisType"
        condition={formData.document?.thoracolumbosacralOrthosis === true}
      >
        <InputHandler type="TextField" editing={!viewing} name="thoracolumbosacralOrthosisType" formData={formData} />
      </FormRow>
      <FormRow title="motor.additionalInformation">
        <InputHandler type="TextField" editing={!viewing} name="additionalInformation" formData={formData} />
      </FormRow>
      <FormSection>
        <QuestionStepper
          viewing={viewing}
          formData={formData}
          pageType={'motor'}
          docType={'rulm'}
          steps={rulmSteps}
          getStepContent={getRULMSteps}
        />
      </FormSection>
      <FormSection>
        <FormRow>
          <Container>
            <Item xs={6}>
              <StyledTitle>
                <FormattedMessage id="motor.right" />
              </StyledTitle>
            </Item>
            <Item xs={6}>
              <StyledTitle>
                <FormattedMessage id="motor.left" />
              </StyledTitle>
            </Item>
          </Container>
        </FormRow>
        <FormRow title="motor.automaticScore">
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            <Item xs={6}>
              {rulmScore(formData.document, 'Right', 'notManual') === 'notCounted' ? (
                <InfoPopover
                  text="general.notFilledInformative"
                  color="primary"
                  values={getMissingRulmSections('Right', formData.document)}
                />
              ) : (
                rulmScore(formData.document, 'Right', 'notManual')
              )}
            </Item>
            <Item xs={6}>
              {rulmScore(formData.document, 'Left', 'notManual') === 'notCounted' ? (
                <InfoPopover
                  text="general.notFilledInformative"
                  color="primary"
                  values={getMissingRulmSections('Left', formData.document)}
                />
              ) : (
                rulmScore(formData.document, 'Left', 'notManual')
              )}
            </Item>
          </Container>
        </FormRow>
        <FormRow title="motor.manualScore">
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            <Item xs={6}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="manualScoreRight"
                formData={formData}
                width={4}
                height={3}
                maxLength={2}
              />
            </Item>
            <Item xs={6}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="manualScoreLeft"
                formData={formData}
                width={4}
                height={3}
                maxLength={2}
              />
            </Item>
          </Container>
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IRULM>;
  viewing: boolean;
}

export default RULMForm;
