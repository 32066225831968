import * as React from 'react';
import CollapseElem from 'Components/Collapse';
import { Container, Item } from 'Components/Grid';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { styled } from '@mui/system';
import colors from '../../../../../config/theme/colors';
import { capitalize } from '../../../../../utility/string';
import TreatmentsByProtocol from './TreatmentsByProtocol';
import TreatmentsBySession from './TreatmentsBySession';
import { findPrecedingDoctorsOrder, TRTMSEvent } from 'Utility/ninmtUtil';
import { RTMSContext } from '../..';
import { equals } from 'ramda';
import { FormattedDoctorsOrderValue } from '../../functions';
import TitleValueItem from 'Components/TitleValueItem';

const StyledEventContainer = styled(Container)({
  width: 'auto !important',
  margin: '0 -2.5rem 3rem -2.5rem',
  padding: '0 2.5rem',
});

const StyledBarHeaderContainer = styled(StyledEventContainer)({
  backgroundColor: `${colors.defaultBackground}`,
  height: '5rem',
});

const StyledBarHeader = styled('div')({
  fontWeight: 600,
  marginBottom: '-0.3rem',
});

export const StyledViewSelector = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active: boolean }) => ({
  color: colors.primary,
  userSelect: 'none' as const,
  textDecoration: active ? 'none' : 'underline',
  fontSize: '1.6rem',
  fontWeight: active ? 'bold' : 'normal',
  cursor: active ? 'default' : 'pointer',
  marginLeft: '2rem',
}));

const HeaderBar = ({ date, secondary }: { date?: PartialDate; secondary?: boolean }): React.JSX.Element => (
  <StyledBarHeaderContainer
    alignItems="center"
    style={{ backgroundColor: secondary ? colors.highlight.light : undefined }}
  >
    <Item>
      <StyledBarHeader>{formatPartialDate(date)}</StyledBarHeader>
    </Item>
  </StyledBarHeaderContainer>
);

const Event = ({
  events,
  event,
  type,
  secondary,
  eventsBeyondThisDoc,
}: {
  events: Array<TRTMSEvent>;
  event: TRTMSEvent;
  type: string;
  secondary?: boolean;
  eventsBeyondThisDoc: Array<TRTMSEvent>;
}): React.JSX.Element => (
  <RTMSContext.Consumer>
    {({ allDoctorsOrders, ninmtTreatmentPeriods }): React.JSX.Element => {
      const currentIndex = events?.findIndex((s) => equals(s, event));
      // For Device/RMT value
      const precedingDoctorsOrder = (reqFields?: ('device' | 'rmt')[]) =>
        findPrecedingDoctorsOrder(
          'rtms',
          allDoctorsOrders,
          events ?? [],
          currentIndex ?? 0,
          reqFields,
          ninmtTreatmentPeriods,
        );

      return (
        <React.Fragment>
          <HeaderBar date={event.date} secondary={secondary} />
          {secondary ? (
            <StyledEventContainer>
              {type === 'sessions' &&
                ['reason', 'reasonDetails'].map((key, ind) => {
                  const e = event as { [key: string]: any };
                  let value = e?.[key];
                  value = value ? (
                    <React.Fragment>
                      {fm(`rtms.opts.unusedSession${capitalize(key)}.${value}`)}
                      {key === 'reasonDetails' &&
                        value === 'other' &&
                        e?.reasonDetailsOther &&
                        e?.reasonDetailsOther.length > 0 &&
                        `: ${e.reasonDetailsOther}`}
                    </React.Fragment>
                  ) : (
                    '-'
                  );
                  return (
                    <Item xs={3} key={ind}>
                      <TitleValueItem title={`rtms.unusedSession${capitalize(key)}`} value={value ?? '-'} />
                    </Item>
                  );
                })}
            </StyledEventContainer>
          ) : (
            <StyledEventContainer>
              {(type === 'sessions'
                ? ['device', 'rmt', 'additionalInformation']
                : ['complications', 'adverseEffects'].includes(type)
                  ? ['complications', 'adverseEffects', 'additionalInformation'].filter((k) =>
                      Object.keys(event).includes(k),
                    )
                  : Object.keys(event)
              )
                .filter((key) => !['id', 'date', 'subjectOfTreatment', 'backupSubjectOfTreatment'].includes(key))
                .map((key, ind) => {
                  let value = (event as { [key: string]: any })?.[key];
                  if (['device', 'rmt'].includes(key)) {
                    value = (
                      <FormattedDoctorsOrderValue
                        name={key as 'device' | 'rmt'}
                        precedingDoctorsOrder={precedingDoctorsOrder([key as 'device' | 'rmt'])}
                      />
                    );
                  }
                  if (Array.isArray(value)) {
                    value =
                      value?.length < 1 ? undefined : (
                        <React.Fragment>
                          {value.map((item: string, i: number) => (
                            <div key={`${key}${i}`} style={{ fontWeight: 600 }}>
                              {fm(`rtms.opts.${key}.${item}`)}
                            </div>
                          ))}
                        </React.Fragment>
                      );
                  }
                  if (typeof value === 'string' && !['device', 'additionalInformation'].includes(key)) {
                    value = fm(`rtms.opts.${key}.${value}`);
                  }
                  return (
                    <Item xs={3} key={ind}>
                      <TitleValueItem title={`rtms.${key}`} value={value ?? '-'} />
                    </Item>
                  );
                })}
            </StyledEventContainer>
          )}
          {type === 'sessions' && (event as IRTMSSession).subjectOfTreatment && (
            <StyledEventContainer style={{ marginTop: '-4.5rem' }}>
              <Item xs={12}>
                <TreatmentsBySession event={event} eventsBeyondThisDoc={eventsBeyondThisDoc} />
              </Item>
            </StyledEventContainer>
          )}
        </React.Fragment>
      );
    }}
  </RTMSContext.Consumer>
);

export const EventListing = ({
  type,
  events,
  secondaryEvents,
  eventsBeyondThisDoc,
}: {
  type: string;
  events: Array<TRTMSEvent>;
  secondaryEvents?: Array<TRTMSEvent>;
  eventsBeyondThisDoc?: Array<TRTMSEvent>;
}): React.JSX.Element => {
  let allEventsSorted: Array<TRTMSEvent> = [];
  if (type === 'sessions') {
    allEventsSorted = ([...events, ...(secondaryEvents ?? [])] as Array<IRTMSSession | IRTMSUnusedSession>)
      .sort((s1, s2) => (s2?.createDate ?? 0) - (s1?.createDate ?? 0))
      .sort((s1, s2) => sortPartialDate(s2.date, s1.date));
  }
  const allEvents = allEventsSorted.length > 0 ? allEventsSorted : events;
  return (
    <React.Fragment>
      {Array.isArray(allEvents) &&
        allEvents.length > 0 &&
        (type !== 'sessions' ||
          (events.length === 0 && Array.isArray(secondaryEvents) && secondaryEvents.length > 0) ||
          (Array.isArray(eventsBeyondThisDoc) && eventsBeyondThisDoc.length > 0)) && (
          <React.Fragment>
            <Event
              events={allEvents}
              event={allEvents[0]}
              type={type}
              secondary={secondaryEvents?.includes(allEvents[0])}
              eventsBeyondThisDoc={eventsBeyondThisDoc ?? []}
            />
            {allEvents.length > 1 && (
              <CollapseElem
                localeIDs={{ showMessage: `rtms.show${capitalize(type)}`, hideMessage: `rtms.hide${capitalize(type)}` }}
                amount={allEvents.length - 1}
              >
                {allEvents.slice(1).map((event, index) => (
                  <Event
                    key={index}
                    events={allEvents}
                    event={event}
                    type={type}
                    secondary={secondaryEvents?.includes(event)}
                    eventsBeyondThisDoc={eventsBeyondThisDoc ?? []}
                  />
                ))}
              </CollapseElem>
            )}
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export const EventTable = ({
  type,
  events,
  secondaryEvents,
  eventsBeyondThisDoc,
}: {
  type: string;
  events: Array<TRTMSEvent>;
  secondaryEvents: Array<TRTMSEvent>;
  eventsBeyondThisDoc: Array<TRTMSEvent>;
}): React.JSX.Element => {
  switch (type) {
    case 'sessions': {
      return (
        <TreatmentsByProtocol
          events={events}
          secondaryEvents={secondaryEvents}
          eventsBeyondThisDoc={eventsBeyondThisDoc}
        />
      );
    }
    // Implement more tables if needed
    default: {
      return <React.Fragment />;
    }
  }
};
