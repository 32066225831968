import * as React from 'react';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import OptionChooser from '../../../../../components/OptionChooser';
import { optFormatter, opts } from '../modifiedRankinScaleSettings';

const ModifiedRankinScaleForm = ({ formData, viewing }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="functionalPerformance.rating" bottomMargin={true}>
      <OptionChooser
        formData={formData}
        name="rating"
        opts={opts}
        optionFormatter={optFormatter}
        viewing={viewing}
        allInstructionsOpenAtOnce={true}
      />
    </FormRow>
    <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
      Farrell, B., Godwin, J., Richards, S., & Warlow, C. (1991). The United Kingdom transient ischaemic attack (UK-TIA)
      aspirin trial: final results. Journal of neurology, neurosurgery, and psychiatry, 54(12), 1044–1054.
    </span>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IModifiedRankinScale>;
  viewing?: boolean;
}

export default ModifiedRankinScaleForm;
