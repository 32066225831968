import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import HistorySection from '../../../../components/HistorySection';
import { IHistoryContext, withHistoryContext } from '../../../../containers/FormContextHandler';
import { DocumentCreationButtonWithContext } from '../../utils';
import { TreatmentHistorySection } from './components';
import HistoryRow from 'Components/HistoryRow';
import { PAPTherapyContext } from '..';

/** For ResMed integration status */
const ConditionalHistoryRow = ({
  condition,
  headerText,
  children,
}: {
  condition: boolean;
  headerText: React.JSX.Element;
  children: React.JSX.Element;
}): React.JSX.Element =>
  condition ? (
    <HistoryRow headerText={headerText} key="resMedIntegration" rowButton={<></>} controlsMargin={false}>
      {children}
    </HistoryRow>
  ) : (
    children
  );

const PAPTherapy = ({ documents = [], startEdit, view }: IHistoryContext<IPAPTherapy>): React.JSX.Element => {
  const papTherapyContext = React.useContext(PAPTherapyContext);
  const { resMedIntegrationAvailable, resMedIntegrationEnabled } = papTherapyContext;

  return (
    <HistorySection
      headerText={<FormattedMessage id="treatment.papTherapy.title" />}
      newButton={<DocumentCreationButtonWithContext name="papTherapy" text="treatment.papTherapy.newPapTherapy" />}
      hasHistoryRows={resMedIntegrationAvailable}
    >
      <ConditionalHistoryRow
        condition={resMedIntegrationAvailable}
        headerText={
          <span>
            <FormattedMessage id="treatment.papTherapy.resMedIntegration" />
            <span style={{ textTransform: 'lowercase' }}>
              {' '}
              <FormattedMessage id={`treatment.papTherapy.resMed${resMedIntegrationEnabled ? 'InUse' : 'NotInUse'}`} />
            </span>
          </span>
        }
      >
        <TreatmentHistorySection documents={documents} startEdit={startEdit} type="papTherapy" view={view} />
      </ConditionalHistoryRow>
    </HistorySection>
  );
};

export default withHistoryContext(PAPTherapy);
