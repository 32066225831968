import DataTable from 'Components/DataTable';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { deps, IDEPS, isLocaleKey, Task_Progress } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { omitControlProps } from 'Utility/documentHandling';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';

const depsFields: Array<keyof Omit<IDEPS, 'date'>> = [
  'insomnia',
  'melancholy',
  'effort',
  'lowEnergy',
  'lonely',
  'hopelessFuture',
  'enjoyLife',
  'worthless',
  'joyOfLife',
  'melancholyWithFamily',
];

export const Deps = (): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing, setViewingObj, setEditingObj } = myServContext;

  const depsData = (editing?.data || viewing?.data) as IDEPS & IControlProps;
  const depsLocales = deps.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...depsData,
      [field]: value,
    });
  };

  const optionScorePairs: Record<NonNullable<IDEPS['insomnia']>, number> = {
    none: 0,
    little: 1,
    quiteMuch: 2,
    veryMuch: 3,
  };

  return (
    <>
      {!viewing ? (
        <div style={{ width: '80%', color: colors.tertiaryText, marginBottom: '2rem' }}>
          <p>{depsLocales[useLocale]['surveyInfo']}</p>
          <p>{depsLocales[useLocale]['surveyFillingInfo']}</p>
        </div>
      ) : null}
      <BlockWrapper
        title={viewing ? formatPartialDate(depsData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'deps', data: depsData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'} headerWidth={5.63}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: depsData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <div style={{ width: viewing ? '65%' : '60%', marginBottom: '2rem', marginTop: viewing ? '6rem' : undefined }}>
          <DataTable
            headers={['', viewing ? <div key={`answer-header`} style={{ width: '15rem' }} /> : ''].concat(
              viewing ? [<div key={`score-header`} style={{ width: '5rem' }} />] : [],
            )}
            data={{
              rowData: depsFields.map((f) => {
                const value = depsData?.[f];
                return [
                  depsLocales[useLocale][f],
                  <InputHandler
                    key={f}
                    name={f}
                    type="Radio"
                    editing={!!editing}
                    formData={{
                      document: { [f]: value },
                      onChange: onChangeSurveyForm,
                    }}
                    options={['none', 'little', 'quiteMuch', 'veryMuch']}
                    optionFormatter={(o) => depsLocales[useLocale][`opts.${o}`]}
                  />,
                ].concat(
                  viewing
                    ? [
                        <span key={`${f}-score`} style={{ fontWeight: 600 }}>
                          {value ? optionScorePairs[value] : '-'}
                        </span>,
                      ]
                    : [],
                );
              }),
              rowStyle: viewing ? undefined : depsFields.map(() => ({ rowAlign: 'top' })),
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: viewing ? '65%' : '60%',
            color: colors.tertiaryText,
            marginBottom: '0.5rem',
          }}
        >
          <span style={{ fontWeight: 600 }}>{fm('myService.sleepApnea.deps.score')}</span>
          <span style={{ fontWeight: 600, width: viewing ? '5.5rem' : '16rem', paddingLeft: '0.5rem' }}>
            <span>
              {typeof Task_Progress.calculateProgress('deps', omitControlProps(depsData)).yielded === 'number'
                ? Task_Progress.calculateProgress('deps', omitControlProps(depsData)).yielded
                : '-'}
            </span>
            <span>{` / ${Task_Progress.calculateProgress('deps', omitControlProps(depsData)).ceiling}`}</span>
          </span>
        </div>
        <div style={{ color: colors.tertiaryText, width: '60%' }}>{fm('myService.sleepApnea.deps.scoreInfo')}</div>
      </BlockWrapper>
      <div style={{ color: colors.tertiaryText, fontStyle: 'italic' }}>
        <p>{fm('myService.sleepApnea.deps.reference')}</p>
      </div>
    </>
  );
};
