import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps } from '@mui/material';
import { remove, clone, pick, mergeRight, indexOf } from 'ramda';
import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';

import ActionButton from '../../../../../components/ActionButton';

import colors from '../../../../../config/theme/colors';
import InputHandler from '../../../../../components/InputHandler';

import { unitConfiguration, defaultUnits } from '../../config';
import LeadClicker from '../LeadClicker';
import GeneratorSetting from '../GeneratorSetting';
import { formatPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import { dialogActions, dialogCancel, dialogContent, dialogTitle } from '../../../../../config/theme/componentTheme';
import { createID } from '../../../../../utility/appendIDs';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';
import ToolTip from '../../../../../components/ToolTip';
import {
  filterPlusMinusFields,
  filterUndefinedFields,
  findNextGenerator,
  generatorsExist,
  generatorsUnavailable,
  hasLfpRegistration,
  mergeLeadSettings,
} from '../../../utils';
import { SettingSelectMenu, StyledTab, StyledTabArea } from '../../../components';
import PlatformConditional from '../../../../../components/PlatformConditional';
import FormSection from '../../../../../components/FormSection';
import ClearIcon from '@mui/icons-material/Clear';
import { Container as GridContainer, Item as GridItem } from 'Components/Grid';
import { isEmpty, isNil, omit, path } from 'Utility/ramdaReplacement';

const DialogPaper = (props: PaperProps): React.JSX.Element => <Paper square={true} {...props} />;

const Container = styled(
  ({ justifyContent, ...other }: React.ComponentProps<typeof GridContainer>) => (
    <GridContainer justifyContent={justifyContent ? justifyContent : 'space-around'} {...other} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'justify',
  },
)({});

const Item = styled(GridItem)({});

const StyledText = styled('div')({
  fontSize: '1.6rem',
});

const StyledBoldText = styled('div')({
  color: colors.primary,
  fontSize: '2rem',
  fontWeight: 600,
});

const StyledColorBox = styled('div')({
  minWidth: '4rem',
  height: '2rem',
});

const StyledInput = styled('input')({
  fontFamily: 'Titillium Web',
  fontSize: '1.6rem',
  fontWeight: 600,
  cursor: 'pointer',
  border: 'none',
  backgroundColor: 'transparent',
  textAlign: 'center',
  '&:focus': {
    outline: 'none',
  },
});

const StyledNewRowButton = styled('div')({
  fontSize: '1.5rem',
  marginTop: '1rem',
  '&:hover': {
    cursor: 'pointer',
    color: colors.primary,
  },
});

const StyledClearIcon = styled(ClearIcon)({
  display: 'block',
  width: '3rem',
  color: colors.secondaryText,
  cursor: 'pointer',
  position: 'relative',
  left: '8rem',
  '&:hover': {
    color: 'red',
  },
});

const sortByDateAndSave = (formData: IOwnProps['formData'], settings: IDBSSetting[]): void => {
  let events = [...settings] as IDBSSetting[];
  events = events
    ? events.sort((n1, n2) => sortPartialDate(n1.generatorAdjustedDate, n2.generatorAdjustedDate)).reverse()
    : [];
  formData.onChange && formData.onChange({ settings: events });
};

const settingsInfo = (lfpRegistration?: boolean, programName?: string): React.JSX.Element => (
  <Container
    style={{
      padding: '2rem',
      color: colors.primary,
      backgroundColor: colors.infoboxBackground,
      border: `1px solid ${colors.infoboxBorder}`,
      width: '96.8%',
    }}
    alignItems="center"
  >
    <Item>
      <InfoIcon style={{ fontSize: '3rem', color: colors.infoboxIcon, display: 'block', marginRight: '2rem' }} />
    </Item>
    <Item xs={true}>
      <PlatformConditional platform="parkinson">
        <FormattedMessage id="dbs.electrodeInfo" />
      </PlatformConditional>
      <PlatformConditional platform="epilepsy">
        <React.Fragment>
          {lfpRegistration === true ? (
            <FormattedMessage
              id="dbs.electrodeInfoLfp"
              values={{ n: <span style={{ fontWeight: 600 }}>{programName ?? '-'}</span> }}
            />
          ) : (
            <FormattedMessage id="dbs.electrodeInfo" />
          )}
        </React.Fragment>
      </PlatformConditional>
    </Item>
  </Container>
);

const settingsDescription = (lfpRegistration?: boolean): React.JSX.Element => (
  <React.Fragment>
    <Container justifyContent="flex-start" style={{ marginTop: '2rem' }}>
      <Item xs={2}>
        <StyledColorBox style={{ backgroundColor: colors.dbsUndefined }}>&nbsp;</StyledColorBox>
      </Item>
      <Item style={{ marginLeft: '2rem' }}>
        <StyledText>
          <FormattedMessage id="dbs.notInUse" />
        </StyledText>
      </Item>
    </Container>
    <Container justifyContent="flex-start" style={{ marginTop: '2rem' }}>
      <Item xs={2}>
        <StyledColorBox style={{ backgroundColor: colors.dbsPositive }}>&nbsp;</StyledColorBox>
      </Item>
      <Item style={{ marginLeft: '2rem' }}>
        <StyledText>
          <FormattedMessage id="dbs.positive" />
        </StyledText>
      </Item>
    </Container>
    <Container
      justifyContent="flex-start"
      style={{ marginTop: '2rem', marginBottom: lfpRegistration ? '2rem' : undefined }}
    >
      <Item xs={2}>
        <StyledColorBox style={{ backgroundColor: colors.dbsNegative }}>&nbsp;</StyledColorBox>
      </Item>
      <Item style={{ marginLeft: '2rem' }}>
        <StyledText>
          <FormattedMessage id="dbs.negative" />
        </StyledText>
      </Item>
    </Container>
    {lfpRegistration === true && (
      <React.Fragment>
        <Container
          style={{
            padding: '2rem 3rem 2rem 2rem',
            color: colors.primary,
            backgroundColor: colors.infoboxBackground,
            border: `1px solid ${colors.infoboxBorder}`,
            width: '96.8%',
          }}
          alignItems="center"
        >
          <Item>
            <div style={{ display: 'block', marginRight: '5rem' }} />
          </Item>
          <Item xs={true}>
            <FormattedMessage id="dbs.electrodeInfoRegistration" />
          </Item>
        </Container>
        <Container justifyContent="flex-start" style={{ marginTop: '2rem' }}>
          <Item xs={2}>
            <StyledColorBox>
              <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 600 }}>R</div>
            </StyledColorBox>
          </Item>
          <Item style={{ marginLeft: '2rem' }}>
            <StyledText>
              <FormattedMessage id="dbs.registering" />
            </StyledText>
          </Item>
        </Container>
      </React.Fragment>
    )}
  </React.Fragment>
);

const optionFormatter = (name: string | number): React.JSX.Element => <FormattedMessage id={'dbs.opts.' + name} />;

class SettingDialog extends React.Component<IOwnProps, IOwnState> {
  private formatMessage: (message: { id: string; values?: { [key: string]: string } }) => string;
  constructor(props: IOwnProps) {
    super(props);
    // deepcode ignore ReactStateFromProps: Needs rework to fix
    this.state = {
      dialogOpen: false,
      editIndex: props.editIndex || props.editIndex === 0 ? props.editIndex : undefined,
      selectedProgram: 0,
      selectedProgramInput: { initial: true, selected: undefined },
      editingDocument: {
        id: '',
        generatorAdjustedDate: undefined,
        programs: [
          {
            id: '',
            programName: '',
            rightSettings: {}, // These can change depending on the type of lead. eg : { 1: 'plus', 2: 'minus', 3: 'neutral/undefined', 4: 'plus' }
            leftSettings: {},
            amplitudeUnit: '',
            rightGeneratorSetting: '',
            rightAmplitude: '', // eg. mA
            rightPulseWidth: '', // µS (Siemens)
            rightRate: '', // Hz
            leftGeneratorSetting: '',
            leftAmplitude: '',
            leftPulseWidth: '',
            leftRate: '',
            interlacing: false,
            cyclicONTime: '',
            cyclicOFFTime: '',
            lfpRegistration: false,
          },
        ],
      },
      editingOld: false,
      settingMenuOpen: null,
      deleteDialogOpen: false,
      deleteProgramDialogOpen: false,
    };
    this.formatMessage = props.intl.formatMessage;
  }

  // Push new empty object to setting array
  private createSettingItem = (): void => {
    const { formData } = this.props;
    const programs: Array<{ id: string; programName: string; lfpRegistration?: boolean }> = [
      { id: createID(), programName: '' },
    ];
    if (formData.document.generators?.[0]?.generator === 'Medtronic Percept PC') {
      programs.push({
        id: createID(),
        programName: this.formatMessage({ id: 'dbs.opts.defaultProgramNames.registration' }),
        lfpRegistration: true,
      });
    }
    if (!formData.document.settings || isEmpty(formData.document.settings)) {
      formData.onChange &&
        formData.onChange({
          settings: [{ id: createID(), programs: programs }],
        });
      this.setState({ selectedProgram: 0 });
    } else {
      const settings = [...formData.document.settings];
      settings.unshift({ id: createID(), programs: programs } as IDBSSetting);
      formData.onChange && formData.onChange({ settings: settings });
    }
    this.setState({
      editIndex: 0,
    });
  };

  toggleSettingMenu = (e: React.MouseEvent<SVGSVGElement | HTMLElement>): void => {
    this.setState({ settingMenuOpen: !this.state.settingMenuOpen ? e.currentTarget : null });
  };

  private deleteSetting = (id: number) => (): void => {
    const { formData } = this.props;
    let settings = [...formData.document.settings];
    settings = remove(id, 1, settings);
    formData.onChange && formData.onChange({ settings: settings });
  };

  openDeleteDialog = (deleteProgramDialog?: boolean) => (): void => {
    if (deleteProgramDialog) {
      this.setState({ deleteProgramDialogOpen: true });
    }
    this.setState({ deleteDialogOpen: true });
  };
  deleteCancelCallback = (): void => {
    this.setState({ deleteProgramDialogOpen: false });
    this.setState({ deleteDialogOpen: false });
  };
  deleteConfirmCallback = (): void => {
    if (this.state.deleteProgramDialogOpen) {
      this.deleteProgram();
      this.setState({ deleteProgramDialogOpen: false });
    } else {
      (this.state.editIndex || this.state.editIndex === 0) && this.deleteSetting(this.state.editIndex)();
    }
    this.setState({ deleteDialogOpen: false });
  };

  private openCloseDialog = (newSetting?: boolean, cancel?: boolean, edit?: boolean) => (): void => {
    const { formData } = this.props;
    const { editIndex } = this.state;

    const mapProgramNames = (settingsFD: IDBSSetting[]): IProgram[] => {
      const settings = this.state.editingOld ? this.state.editingDocument : settingsFD[editIndex ?? 0];
      return Array.isArray(settings.programs)
        ? settings.programs.map((program: IProgram, index: number): IProgram => {
            const programName = isEmpty(program.programName)
              ? this.formatMessage({ id: `dbs.opts.defaultProgramNames.${this.defaultProgramNames[index]}` })
              : program.programName;
            return Object.assign({}, program, { programName: programName }) as IProgram;
          })
        : [];
    };

    // file deepcode ignore ReactNextState: Needs rework to fix
    this.setState({ dialogOpen: !this.state.dialogOpen, selectedProgram: 0 });
    // If no valid index, then create new setting
    if (!editIndex && editIndex !== 0) {
      this.createSettingItem();
    }
    if (newSetting == true && cancel == true) {
      // Delete setting when new setting is cancelled
      this.deleteSetting(0)();
      this.setState({ editIndex: undefined });
    } else if (newSetting) {
      if (Object.keys(formData.document.settings[0]).length < 2) {
        // If the setting is empty (has only the date that cant be empty) when adding new, delete it
        this.deleteSetting(0)();
      } else {
        const settings = JSON.parse(JSON.stringify(formData.document.settings));
        settings[editIndex ?? 0].programs = mapProgramNames(settings);
        sortByDateAndSave(formData, settings);
      }
      // When closing the dialog, if new setting was added, clear the index so that new setting can be added again
      this.setState({ editIndex: undefined });
    }

    // When clicking "edit", set formData to this.state.editingdocument and change state "editingold" to true. Otherwise when clicking anything else, set "editingOld" to false
    if (edit === true && (editIndex || editIndex === 0)) {
      this.setState({
        editingDocument: {
          ...formData.document.settings[editIndex],
        },
        editingOld: true,
      });
    } else {
      this.setState({
        editingOld: false,
      });
    }
    // Close editing by clicking 'save'
    if (newSetting === false && cancel === false && (editIndex || editIndex === 0)) {
      // Apply changes from state
      const settings = JSON.parse(JSON.stringify(formData.document.settings));
      settings[editIndex] = this.state.editingDocument;
      settings[editIndex].programs = mapProgramNames(settings);
      formData.onChange && formData.onChange({ settings: settings });
      // Sort events by date
      sortByDateAndSave(formData, settings);
    }
  };

  private setting = (): IDBSSetting =>
    this.state.editingOld
      ? this.state.editingDocument
      : this.props.formData.document.settings
        ? this.props.formData.document.settings?.[this.state.editIndex ?? 0]
        : [];

  private settingsOnChange =
    (editingOld?: boolean) =>
    (values: TOnChangeValues): void => {
      const { formData } = this.props;
      const { editIndex } = this.state;

      const name = Object.keys(values)[0];
      const value = values[name];

      if (editingOld) {
        if (editIndex || editIndex === 0) {
          const setting = this.state.editingDocument;
          if (!['id', 'generator', 'generatorAdjustedDate', 'programs'].includes(name)) {
            const programs = JSON.parse(JSON.stringify(setting.programs)) as IProgram[];
            programs[this.state.selectedProgram] = {
              ...programs[this.state.selectedProgram],
              [name]: value,
            };
            // If program A is changed, update LFP registration settings accordingly
            if (
              hasLfpRegistration(setting) &&
              this.state.selectedProgram === 0 &&
              ['rightSettings', 'leftSettings'].includes(name)
            ) {
              const lfpRegistrationProgram = (programs[programs.length - 1] ?? {}) as IProgram;
              if (lfpRegistrationProgram[name as keyof IProgram]) {
                const updated = Object.keys(value ?? {}).reduce(
                  (current, key) => {
                    if (
                      lfpRegistrationProgram[name as keyof IProgram]?.[
                        key as keyof IProgram['rightSettings' | 'leftSettings']
                      ]
                    ) {
                      (current[key as keyof IProgram['rightSettings' | 'leftSettings']] as string | undefined) =
                        undefined;
                    }
                    return current;
                  },
                  Object.assign({}, lfpRegistrationProgram[name as keyof IProgram]) ?? {},
                );

                programs[programs.length - 1] = {
                  ...programs[programs.length - 1],
                  [name]: updated ?? {},
                  rightGeneratorSetting: programs[this.state.selectedProgram].rightGeneratorSetting,
                  leftGeneratorSetting: programs[this.state.selectedProgram].leftGeneratorSetting,
                };
              }
            }
            setting.programs = programs;
            // Set changes to state
            this.setState((s) => ({
              editingDocument: { ...s.editingDocument, programs: setting.programs },
            }));
          } else {
            // Set changes to state
            this.setState((s) => ({
              editingDocument: { ...s.editingDocument, [name]: value },
            }));
          }
        }
      } else {
        if (formData.onChange && (editIndex || editIndex === 0)) {
          const settings = [...formData.document.settings] as IDBSSetting[];
          if (!['id', 'generator', 'generatorAdjustedDate', 'programs'].includes(name)) {
            const programs = JSON.parse(JSON.stringify(settings[editIndex].programs)) as IProgram[];
            programs[this.state.selectedProgram] = {
              ...programs[this.state.selectedProgram],
              [name]: value,
            };
            // If program A is changed, update LFP registration settings accordingly
            if (
              hasLfpRegistration(settings[editIndex]) &&
              this.state.selectedProgram === 0 &&
              ['rightSettings', 'leftSettings'].includes(name)
            ) {
              const lfpRegistrationProgram = (programs[programs.length - 1] ?? {}) as IProgram;
              if (lfpRegistrationProgram[name as keyof IProgram]) {
                const updated = Object.keys(value ?? {}).reduce(
                  (current, key) => {
                    if (
                      lfpRegistrationProgram[name as keyof IProgram]?.[
                        key as keyof IProgram['rightSettings' | 'leftSettings']
                      ]
                    ) {
                      (current[key as keyof IProgram['rightSettings' | 'leftSettings']] as string | undefined) =
                        undefined;
                    }
                    return current;
                  },
                  Object.assign({}, lfpRegistrationProgram[name as keyof IProgram]) ?? {},
                );

                programs[programs.length - 1] = {
                  ...programs[programs.length - 1],
                  [name]: updated ?? {},
                  rightGeneratorSetting: programs[this.state.selectedProgram].rightGeneratorSetting,
                  leftGeneratorSetting: programs[this.state.selectedProgram].leftGeneratorSetting,
                };
              }
            }
            settings[editIndex] = { ...settings[editIndex], programs: programs };
          } else {
            settings[editIndex] = {
              ...settings[editIndex],
              [name]: value,
            };
          }
          formData.onChange({ settings: settings });
        }
      }
    };

  public copySettings = (setting: { setting: string; program: number }): void => {
    const { formData } = this.props;
    const settings = JSON.parse(JSON.stringify(formData.document.settings)) ?? [];
    const currentSettings = this.state.editingOld ? this.state.editingDocument : settings[this.state.editIndex ?? 0];

    const latestPrevious =
      Array.isArray(settings) &&
      settings
        .filter((_: IDBSSetting, index: number) => index !== this.state.editIndex)
        .sort((s1: IDBSSetting, s2: IDBSSetting) => sortPartialDate(s1.generatorAdjustedDate, s2.generatorAdjustedDate))
        .reverse()[0];

    const newPrograms =
      setting.setting === 'current'
        ? JSON.parse(JSON.stringify(this.programs()))
        : setting.setting === 'previous'
          ? latestPrevious && latestPrevious.programs
          : [];

    const currentPrograms = currentSettings.programs;
    currentPrograms[this.state.selectedProgram] = mergeRight(
      pick(['id', 'programName', 'lfpRegistration'], currentPrograms[this.state.selectedProgram]),
      clone(omit(['id', 'programName', 'lfpRegistration'], newPrograms[setting.program])),
    );

    // If program A is copied from another program, update LFP registration settings accordingly
    if (hasLfpRegistration(currentSettings) && this.state.selectedProgram === 0) {
      const lfpRegistrationProgram = (currentPrograms[currentPrograms.length - 1] ?? {}) as IProgram;
      ['rightSettings', 'leftSettings'].forEach((name: string): void => {
        if (lfpRegistrationProgram[name as keyof IProgram]) {
          const updated = Object.keys(currentPrograms[this.state.selectedProgram][name] ?? {}).reduce(
            (current, key) => {
              if (
                lfpRegistrationProgram[name as keyof IProgram]?.[
                  key as keyof IProgram['rightSettings' | 'leftSettings']
                ]
              ) {
                (current[key as keyof IProgram['rightSettings' | 'leftSettings']] as string | undefined) = undefined;
              }
              return current;
            },
            Object.assign({}, lfpRegistrationProgram[name as keyof IProgram]) ?? {},
          );

          currentPrograms[currentPrograms.length - 1] = {
            ...currentPrograms[currentPrograms.length - 1],
            [name]: updated ?? {},
            rightGeneratorSetting: currentPrograms[this.state.selectedProgram].rightGeneratorSetting,
            leftGeneratorSetting: currentPrograms[this.state.selectedProgram].leftGeneratorSetting,
          };
        }
      });
    }
    // If LFP registration settings are copied from previous settings, update them accordingly based on program A
    if (hasLfpRegistration(currentSettings) && this.state.selectedProgram === currentPrograms.length - 1) {
      const merged = mergeLeadSettings(currentPrograms[currentPrograms.length - 1], currentPrograms[0]);
      currentPrograms[currentPrograms.length - 1] = {
        ...currentPrograms[currentPrograms.length - 1],
        rightSettings: filterUndefinedFields(filterPlusMinusFields(merged.rightSettings ?? {})),
        rightGeneratorSetting: currentPrograms[0].rightGeneratorSetting,
        leftSettings: filterUndefinedFields(filterPlusMinusFields(merged.leftSettings ?? {})),
        leftGeneratorSetting: currentPrograms[0].leftGeneratorSetting,
      };
    }

    this.settingsOnChange(this.state.editingOld)({
      programs: currentPrograms,
    });
  };

  private formData = (editIndex?: number, editingOld?: boolean, editPrograms = true): IOwnProps['formData'] => ({
    document:
      editIndex || editIndex === 0
        ? path(
            editPrograms ? ['programs', `${this.state.selectedProgram}`] : [],
            path(
              editingOld ? ['state', 'editingDocument'] : ['props', 'formData', 'document', 'settings', `${editIndex}`],
              this,
            ),
          ) ?? {}
        : {},
    onChange: this.settingsOnChange(editingOld),
  });

  private defaultProgramNames = ['programA', 'programB', 'programC', 'programD'];

  private programs = (): IProgram[] => {
    return this.state.editingOld
      ? this.state.editingDocument?.programs
      : this.props.formData.document.settings?.[this.state.editIndex ?? 0]?.programs;
  };

  private selectedGenerator = (): IDBSGenerator =>
    Array.isArray(this.props.formData.document.generators) &&
    this.props.formData.document.generators.find(
      (generator: IDBSGenerator) =>
        generator.id === this.formData(this.state.editIndex, this.state.editingOld, false).document.generator,
    );

  private nextGenerator = (): IDBSGenerator | undefined =>
    findNextGenerator(this.selectedGenerator(), this.props.formData.document as IDBS);

  private lfpRegistrationProgramSelected = (): boolean =>
    hasLfpRegistration(this.setting()) &&
    Array.isArray(this.programs()) &&
    this.state.selectedProgram === this.programs().length - 1;

  private programsOnChange = (): void => {
    const { formData } = this.props;
    const substraction = hasLfpRegistration(formData.document.settings?.[this.state.editIndex ?? 0]) ? 1 : 0;

    const newPrograms = JSON.parse(JSON.stringify(this.programs()));
    newPrograms.splice(newPrograms.length - substraction, 0, { id: createID(), programName: '' });

    this.formData(this.state.editIndex, this.state.editingOld).onChange?.({
      programs: newPrograms,
    });
    // file deepcode ignore ReactNextState: Needs rework to fix
    this.setState({
      selectedProgram: this.state.editingOld
        ? (this.state.editingDocument.programs as IProgram[]).length - substraction
        : formData.document.settings[this.state.editIndex ?? 0].programs.length - substraction,
    });
  };

  private programNameOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newPrograms = JSON.parse(JSON.stringify(this.programs()));
    this.setState((s) => ({ selectedProgramInput: { ...s.selectedProgramInput, initial: false } }));
    newPrograms[this.state.selectedProgram].programName = e.currentTarget.value;

    this.formData(this.state.editIndex, this.state.editingOld).onChange?.({
      programs: newPrograms,
    });
  };

  private eventRegistrationEventsOnChange =
    (index?: number, clear?: boolean) =>
    (values?: TOnChangeValues): void => {
      const events = path(['events'], this.formData(this.state.editIndex, this.state.editingOld).document) ?? [];
      const newEvents = JSON.parse(JSON.stringify(events)) ?? [];
      if (values) {
        const name = Object.keys(values)[0];
        const value = values[name];
        newEvents[index ?? 0] = value;
      } else {
        if (clear) {
          newEvents.splice(index, 1);
        } else {
          newEvents.push('');
        }
      }
      this.formData(this.state.editIndex, this.state.editingOld).onChange?.({
        events: isEmpty(events) ? [''].concat(newEvents) : newEvents,
      });
    };

  private deleteProgram = (): void => {
    const newPrograms = JSON.parse(JSON.stringify(this.programs()));
    newPrograms.splice(this.state.selectedProgram, 1);

    this.formData(this.state.editIndex, this.state.editingOld).onChange?.({
      programs: newPrograms,
    });
    this.setState({ selectedProgram: this.state.selectedProgram !== 0 ? this.state.selectedProgram - 1 : 0 });
  };

  public leadFormData = (editIndex?: number, editingOld?: boolean, referenceData?: boolean): IOwnProps['formData'] => {
    const document = referenceData
      ? editingOld
        ? path(['state', 'editingDocument', 'programs', 0], this)
        : path(['props', 'formData', 'document', 'settings', editIndex ?? 0, 'programs', 0], this)
      : this.formData(editIndex, editingOld).document;

    return {
      onChange: this.settingsOnChange(editingOld),
      document:
        (editIndex || editIndex === 0) && document
          ? {
              ...this.props.formData.document,
              rightGeneratorSetting: path(['rightGeneratorSetting'], document),
              rightSettings: path(['rightSettings'], document),
              leftGeneratorSetting: path(['leftGeneratorSetting'], document),
              leftSettings: path(['leftSettings'], document),
            }
          : {},
    };
  };

  componentDidUpdate = (): void => {
    const { formData } = this.props;
    const dateHookFloor = this.selectedGenerator() && this.selectedGenerator().generatorDate;
    const dateHookCeiling = this.nextGenerator() ? this.nextGenerator()?.generatorDate : undefined;
    const currentDate: PartialDate | undefined = this.formData(this.state.editIndex, this.state.editingOld, false)
      .document.generatorAdjustedDate;

    // If selected generator is changed and the new dateHook causes setting date to be off limits, change it to be within limits
    if (currentDate && dateHookFloor && partialDateToValue(currentDate) < partialDateToValue(dateHookFloor)) {
      this.formData(this.state.editIndex, this.state.editingOld, false).onChange?.({
        generatorAdjustedDate: dateHookFloor,
      });
    }
    if (currentDate && dateHookCeiling && partialDateToValue(currentDate) > partialDateToValue(dateHookCeiling)) {
      this.formData(this.state.editIndex, this.state.editingOld, false).onChange?.({
        generatorAdjustedDate: dateHookCeiling,
      });
    }

    // Automatically delete LFP registration program if selected generator doesn't have it
    if (
      this.selectedGenerator() &&
      this.selectedGenerator().generator !== 'Medtronic Percept PC' &&
      this.programs().some((program) => program.lfpRegistration)
    ) {
      const programs = JSON.parse(JSON.stringify(this.programs()));
      programs.splice(programs.length - 1, 1);

      this.formData(this.state.editIndex, this.state.editingOld).onChange?.({
        programs: programs,
      });
      this.setState({ selectedProgram: this.state.selectedProgram !== 0 ? this.state.selectedProgram - 1 : 0 });
    }
    // Automatically add LFP registration program if selected generator should have it
    if (
      this.selectedGenerator() &&
      this.selectedGenerator().generator === 'Medtronic Percept PC' &&
      !this.programs().some((program) => program.lfpRegistration)
    ) {
      const programs = JSON.parse(JSON.stringify(this.programs()));
      programs.push({
        id: createID(),
        programName: this.formatMessage({ id: 'dbs.opts.defaultProgramNames.registration' }),
        lfpRegistration: true,
      });

      this.formData(this.state.editIndex, this.state.editingOld).onChange?.({
        programs: programs,
      });
    }
    // Automatically set amplitude unit to mA if there are no other options
    if (
      (!Array.isArray(unitConfiguration[formData.document.generators?.[0]?.generator]) ||
        unitConfiguration[formData.document.generators?.[0]?.generator].length < 2) &&
      this.programs() &&
      this.programs()[this.state.selectedProgram] &&
      !this.programs()[this.state.selectedProgram].amplitudeUnit &&
      !this.lfpRegistrationProgramSelected()
    ) {
      const programs = JSON.parse(JSON.stringify(this.programs() ?? []));
      programs[this.state.selectedProgram] = { ...programs[this.state.selectedProgram], amplitudeUnit: 'mA' };
      this.formData(this.state.editIndex, this.state.editingOld).onChange?.({
        programs: programs,
      });
    }
  };

  public render(): React.JSX.Element {
    const { formData, editIndex, platform } = this.props;

    const setting = this.setting();
    const previousSettings =
      Array.isArray(formData.document.settings) &&
      JSON.parse(JSON.stringify(formData.document.settings))
        .sort((s1: IDBSSetting, s2: IDBSSetting) => sortPartialDate(s1.generatorAdjustedDate, s2.generatorAdjustedDate))
        .reverse()
        .filter((_: IDBSSetting, index: number, arr: IDBSSetting[]) => {
          return (
            index >
            indexOf(
              formData.document.settings.find((_: IDBSSetting, i: number) => i === this.state.editIndex),
              arr,
            )
          );
        });
    const programs = this.programs();

    const lfpRegistrationProgramSelected = this.lfpRegistrationProgramSelected();
    const onClickHandler = (currentIndex: number) => (): void => {
      this.setState({ selectedProgram: currentIndex });
    };

    const onDblClickHandler = (currentIndex: number) => (): void => {
      this.setState({ selectedProgramInput: { initial: true, selected: currentIndex } });
    };

    // Caret handling for IE textfield
    const inputRef = React.createRef() as React.RefObject<HTMLInputElement>;
    const onFocusProgramname = (value: any) => (): void => {
      inputRef?.current?.setSelectionRange(value.length, value.length);
    };

    return (
      <React.Fragment>
        {this.state.editIndex || this.state.editIndex === 0 ? (
          <Dialog open={this.state.dialogOpen} fullWidth={false} maxWidth="md" PaperComponent={DialogPaper}>
            <DialogTitle style={dialogTitle}>
              <Container>
                <Item xs={6}>
                  <FormattedMessage id="dbs.setting" />
                </Item>
                <Item xs={6} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  {((Array.isArray(previousSettings) && previousSettings.length > 0) ||
                    (Array.isArray(programs) && programs.length > 1)) && (
                    <React.Fragment>
                      <ActionButton
                        text={'dbs.copySettings'}
                        onClick={this.toggleSettingMenu as (e: React.MouseEvent<Element, MouseEvent>) => void}
                        width={20}
                        height={3}
                        fontSize={14}
                      />
                      <SettingSelectMenu
                        settings={previousSettings}
                        currentPrograms={
                          (Array.isArray(programs) ? JSON.parse(JSON.stringify(programs)) : []) as IProgram[]
                        }
                        selectedProgram={this.state.selectedProgram}
                        toggleSettingMenu={this.toggleSettingMenu}
                        showMenu={this.state.settingMenuOpen}
                        onClickSetting={this.copySettings}
                      />
                    </React.Fragment>
                  )}
                  {Array.isArray(programs) &&
                    programs.length > (hasLfpRegistration(setting) ? 2 : 1) &&
                    !(this.state.selectedProgram === programs.length - 1 && hasLfpRegistration(setting)) && (
                      <div style={{ marginRight: '1rem' }}>
                        <ActionButton
                          text={'dbs.deleteProgram'}
                          onClick={this.openDeleteDialog(true)}
                          width={20}
                          height={3}
                          fontSize={14}
                        />
                      </div>
                    )}
                </Item>
              </Container>
            </DialogTitle>
            <DialogContent style={dialogContent}>
              <React.Fragment>
                <Container style={{ marginBottom: '4.5rem' }}>
                  <Item xs={4}>
                    <StyledText>
                      <FormattedMessage id="dbs.generator" />
                    </StyledText>
                  </Item>
                  <Item xs={8}>
                    <InputHandler
                      type="Select"
                      editing={true}
                      name="generator"
                      formData={this.formData(this.state.editIndex, this.state.editingOld, false)}
                      options={formData.document.generators?.map((generator: IDBSGenerator) => generator.id ?? '')}
                      optionFormatter={(name: string | number) => {
                        const generator = formData.document.generators?.find(
                          (generator: IDBSGenerator) => generator.id === name,
                        );
                        return `${generator?.generator} - ${formatPartialDate(generator?.generatorDate)}`;
                      }}
                      placeholder={<FormattedMessage id="dbs.chooseGenerator" />}
                      width={38}
                      autoSelect
                    />
                  </Item>
                </Container>
                <Container>
                  <Item xs={4}>
                    <StyledText>
                      <FormattedMessage id="general.date" />
                    </StyledText>
                  </Item>
                  <Item xs={8}>
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="generatorAdjustedDate"
                      formData={this.formData(this.state.editIndex, this.state.editingOld, false)}
                      dateDefault="now"
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: this.selectedGenerator()
                          ? this.selectedGenerator().generatorDate
                          : formData.document.date,
                        dateHookCeiling: this.nextGenerator()
                          ? this.nextGenerator()?.generatorDate
                          : formData.document.hasEnded?.[0] &&
                            formData.document.endDate?.every((n: number | null) => n) &&
                            formData.document.endDate,
                      }}
                    />
                  </Item>
                </Container>
                <Container style={{ margin: '4.5rem 0 4.5rem 0' }}>
                  <Item xs={12}>
                    <StyledTabArea>
                      <Container alignItems="stretch">
                        {Array.isArray(programs) &&
                          programs.map(
                            (program: any, index: number): React.JSX.Element => (
                              <React.Fragment key={program.programName + index}>
                                <StyledTab
                                  xs={true}
                                  style={{
                                    backgroundColor:
                                      this.state.selectedProgram === index ? 'rgba(4, 90, 139, 0.28)' : undefined,
                                    minHeight: '2.6rem',
                                  }}
                                  onClick={onClickHandler(index)}
                                >
                                  {this.state.selectedProgramInput?.selected !== index ||
                                  (this.state.selectedProgram === programs.length - 1 &&
                                    hasLfpRegistration(setting)) ? (
                                    <span onDoubleClick={onDblClickHandler(index)}>
                                      {isEmpty(program.programName)
                                        ? this.formatMessage({
                                            id: `dbs.opts.defaultProgramNames.${this.defaultProgramNames[index]}`,
                                          })
                                        : program.programName}
                                    </span>
                                  ) : (
                                    <StyledInput
                                      ref={inputRef}
                                      key={`input${index}`}
                                      type="text"
                                      name="programName"
                                      value={
                                        isEmpty(program.programName) && this.state.selectedProgramInput.initial
                                          ? this.formatMessage({
                                              id: `dbs.opts.defaultProgramNames.${this.defaultProgramNames[index]}`,
                                            })
                                          : program.programName
                                      }
                                      onChange={this.programNameOnChange}
                                      onBlur={(): void =>
                                        this.setState({ selectedProgramInput: { initial: true, selected: undefined } })
                                      }
                                      onFocus={onFocusProgramname(
                                        isEmpty(program.programName) && this.state.selectedProgramInput.initial
                                          ? this.formatMessage({
                                              id: `dbs.opts.defaultProgramNames.${this.defaultProgramNames[index]}`,
                                            })
                                          : program.programName,
                                      )}
                                      autoFocus
                                    />
                                  )}
                                </StyledTab>
                                {index === programs.length - 1 && index < (hasLfpRegistration(setting) ? 4 : 3) && (
                                  <StyledTab
                                    xs={true}
                                    style={{ textTransform: 'uppercase' }}
                                    onClick={this.programsOnChange}
                                  >
                                    <FormattedMessage id="dbs.addProgram" />
                                  </StyledTab>
                                )}
                              </React.Fragment>
                            ),
                          )}
                      </Container>
                    </StyledTabArea>
                  </Item>
                </Container>
                <Container>
                  <Item xs={4}>&nbsp;</Item>
                  <Item xs={4} style={{ textAlign: 'center' }}>
                    <StyledBoldText>
                      <FormattedMessage id="general.right" />
                    </StyledBoldText>
                  </Item>
                  <Item xs={4} style={{ textAlign: 'center' }}>
                    <StyledBoldText>
                      <FormattedMessage id="general.left" />
                    </StyledBoldText>
                  </Item>
                </Container>
                <Container style={{ marginTop: '4rem' }}>
                  <Item xs={4}>
                    <StyledText>
                      <FormattedMessage id="dbs.generator" />
                    </StyledText>
                  </Item>
                  <Item xs={4}>
                    <Container justifyContent="center">
                      <Item id="rightGeneratorSetting">
                        <GeneratorSetting
                          editable={!lfpRegistrationProgramSelected}
                          name="rightGeneratorSetting"
                          formData={this.leadFormData(this.state.editIndex, this.state.editingOld)}
                          referenceData={
                            lfpRegistrationProgramSelected
                              ? this.leadFormData(this.state.editIndex, this.state.editingOld, true)
                              : undefined
                          }
                        />
                      </Item>
                    </Container>
                  </Item>
                  <Item xs={4}>
                    <Container justifyContent="center">
                      <Item id="leftGeneratorSetting">
                        <GeneratorSetting
                          editable={!lfpRegistrationProgramSelected}
                          name="leftGeneratorSetting"
                          formData={this.leadFormData(this.state.editIndex, this.state.editingOld)}
                        />
                      </Item>
                    </Container>
                  </Item>
                </Container>
                <Container style={{ marginTop: '2rem' }}>
                  <Item xs={4}>
                    <Container>
                      <Item xs={12}>
                        {settingsInfo(
                          lfpRegistrationProgramSelected,
                          !isNil(programs) && !isEmpty(programs)
                            ? isNil(programs[0].programName) || isEmpty(programs[0].programName)
                              ? this.formatMessage({ id: 'dbs.opts.defaultProgramNames.programA' })
                              : programs[0].programName
                            : undefined,
                        )}
                      </Item>
                      <Item xs={12}>{settingsDescription(lfpRegistrationProgramSelected)}</Item>
                    </Container>
                  </Item>
                  <Item xs={4} style={{ minWidth: '300px', minHeight: '370px', maxWidth: '100%', maxHeight: '100%' }}>
                    <LeadClicker
                      editable={!lfpRegistrationProgramSelected ? 'true' : 'registration'}
                      name={'rightSettings'}
                      formData={this.leadFormData(this.state.editIndex, this.state.editingOld)}
                      referenceData={this.leadFormData(this.state.editIndex, this.state.editingOld, true)}
                    />
                  </Item>
                  <Item xs={4} style={{ minWidth: '300px', minHeight: '370px', maxWidth: '100%', maxHeight: '100%' }}>
                    <LeadClicker
                      editable={!lfpRegistrationProgramSelected ? 'true' : 'registration'}
                      name={'leftSettings'}
                      formData={this.leadFormData(this.state.editIndex, this.state.editingOld)}
                      referenceData={this.leadFormData(this.state.editIndex, this.state.editingOld, true)}
                    />
                  </Item>
                  {!lfpRegistrationProgramSelected && (
                    <React.Fragment>
                      <Container style={{ marginTop: '2rem' }}>
                        <Item xs={4}>
                          <StyledText>
                            <FormattedMessage id="dbs.amplitudeSetting" />
                          </StyledText>
                        </Item>
                        <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                          <InputHandler
                            type="Select"
                            editing={
                              !Array.isArray(unitConfiguration[formData.document.generators?.[0]?.generator]) ||
                              unitConfiguration[formData.document.generators?.[0]?.generator].length < 2
                                ? false
                                : true
                            }
                            name="amplitudeUnit"
                            formData={this.formData(this.state.editIndex, this.state.editingOld)}
                            options={
                              formData.document.generators?.[0]?.generator
                                ? unitConfiguration[formData.document.generators?.[0]?.generator]
                                : ['mA']
                            }
                            optionFormatter={optionFormatter}
                            placeholder={'dbs.unit'}
                            width={8.8}
                          />
                        </Item>
                        <Item xs={4}>&nbsp;</Item>
                      </Container>
                      <Container style={{ marginTop: '2rem' }}>
                        <Item xs={4}>
                          <StyledText>
                            <FormattedMessage id="dbs.amplitude" />
                          </StyledText>
                        </Item>
                        <Item xs={4} style={{ textAlign: 'center', paddingLeft: '2.8rem' }}>
                          <div style={{ display: 'inline-flex' }}>
                            <InputHandler
                              type="NumberField"
                              editing={true}
                              name="rightAmplitude"
                              formData={this.formData(this.state.editIndex, this.state.editingOld)}
                              placeholder="dbs.amplitudePlaceholder"
                              width={7}
                              height={3}
                              maxLength={7}
                              min={0}
                              max={999}
                              precision={2}
                            />
                          </div>
                          &nbsp;{' '}
                          <div style={{ display: 'inline-flex', width: '2rem' }}>
                            {this.formData(this.state.editIndex, this.state.editingOld).document?.amplitudeUnit === 'V'
                              ? 'V'
                              : this.formData(this.state.editIndex, this.state.editingOld).document?.amplitudeUnit ===
                                  'mA'
                                ? 'mA'
                                : ''}
                          </div>
                        </Item>
                        <Item xs={4} style={{ textAlign: 'center', paddingLeft: '2.8rem' }}>
                          <div style={{ display: 'inline-flex' }}>
                            <InputHandler
                              type="NumberField"
                              editing={true}
                              name="leftAmplitude"
                              formData={this.formData(this.state.editIndex, this.state.editingOld)}
                              placeholder="dbs.amplitudePlaceholder"
                              width={7}
                              height={3}
                              maxLength={7}
                              min={0}
                              max={999}
                              precision={2}
                            />
                          </div>
                          &nbsp;{' '}
                          <div style={{ display: 'inline-flex', width: '2rem' }}>
                            {this.formData(this.state.editIndex, this.state.editingOld).document?.amplitudeUnit === 'V'
                              ? 'V'
                              : this.formData(this.state.editIndex, this.state.editingOld).document?.amplitudeUnit ===
                                  'mA'
                                ? 'mA'
                                : ''}
                          </div>
                        </Item>
                      </Container>
                      <Container style={{ marginTop: '2rem' }}>
                        <Item xs={4}>
                          <StyledText>
                            <FormattedMessage id="dbs.pulseWidth" />
                          </StyledText>
                        </Item>
                        <Item xs={4} style={{ textAlign: 'center', paddingLeft: '2.8rem' }}>
                          <div style={{ display: 'inline-flex' }}>
                            <InputHandler
                              type="NumberField"
                              editing={true}
                              name="rightPulseWidth"
                              formData={this.formData(this.state.editIndex, this.state.editingOld)}
                              placeholder="dbs.pulseWidthPlaceholder"
                              width={7}
                              height={3}
                              maxLength={7}
                              min={0}
                              max={999}
                              precision={2}
                            />
                          </div>
                          &nbsp; <div style={{ display: 'inline-flex', width: '2rem' }}>{defaultUnits.pulseWidth}</div>
                        </Item>
                        <Item xs={4} style={{ textAlign: 'center', paddingLeft: '2.8rem' }}>
                          <div style={{ display: 'inline-flex' }}>
                            <InputHandler
                              type="NumberField"
                              editing={true}
                              name="leftPulseWidth"
                              formData={this.formData(this.state.editIndex, this.state.editingOld)}
                              placeholder="dbs.pulseWidthPlaceholder"
                              width={7}
                              height={3}
                              maxLength={7}
                              min={0}
                              max={999}
                              precision={2}
                            />
                          </div>
                          &nbsp; <div style={{ display: 'inline-flex', width: '2rem' }}>{defaultUnits.pulseWidth}</div>
                        </Item>
                      </Container>
                      <Container style={{ marginTop: '2rem' }}>
                        <Item xs={4}>
                          <StyledText>
                            <FormattedMessage id="dbs.rate" />
                          </StyledText>
                        </Item>
                        <Item xs={4} style={{ textAlign: 'center', paddingLeft: '2.8rem' }}>
                          <div style={{ display: 'inline-flex' }}>
                            <InputHandler
                              type="NumberField"
                              editing={true}
                              name="rightRate"
                              formData={this.formData(this.state.editIndex, this.state.editingOld)}
                              placeholder="dbs.ratePlaceholder"
                              width={7}
                              height={3}
                              maxLength={7}
                              min={0}
                              max={9999}
                              precision={2}
                            />
                          </div>
                          &nbsp; <div style={{ display: 'inline-flex', width: '2rem' }}>{defaultUnits.rate}</div>
                        </Item>
                        <Item xs={4} style={{ textAlign: 'center', paddingLeft: '2.8rem' }}>
                          <div style={{ display: 'inline-flex' }}>
                            <InputHandler
                              type="NumberField"
                              editing={true}
                              name="leftRate"
                              formData={this.formData(this.state.editIndex, this.state.editingOld)}
                              placeholder="dbs.ratePlaceholder"
                              width={7}
                              height={3}
                              maxLength={7}
                              min={0}
                              max={9999}
                              precision={2}
                            />
                          </div>
                          &nbsp; <div style={{ display: 'inline-flex', width: '2rem' }}>{defaultUnits.rate}</div>
                        </Item>
                      </Container>
                      <PlatformConditional platform="parkinson">
                        <Container style={{ marginTop: '2rem' }}>
                          <Item xs={4}>
                            <StyledText>
                              <FormattedMessage id="dbs.interlacing" />
                            </StyledText>
                          </Item>
                          <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                            <InputHandler
                              type="Radio"
                              editing={true}
                              name="interlacing"
                              formData={this.formData(this.state.editIndex, this.state.editingOld)}
                              preset="yesno"
                            />
                          </Item>
                          <Item xs={4} />
                        </Container>
                      </PlatformConditional>
                      <PlatformConditional platform="epilepsy">
                        <React.Fragment>
                          <Container style={{ marginTop: '3.5rem' }}>
                            <Item xs={4}>
                              <StyledText>
                                <FormattedMessage id="dbs.mode" />
                              </StyledText>
                            </Item>
                            <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                              <div style={{ marginLeft: '10.5rem' }}>
                                <InputHandler
                                  type="Radio"
                                  editing={true}
                                  name="mode"
                                  formData={this.formData(this.state.editIndex, this.state.editingOld)}
                                  options={['continuousStimulation', 'cyclicStimulation']}
                                  optionFormatter={(name: string | number): React.JSX.Element => (
                                    <FormattedMessage id={`dbs.opts.${name}`} />
                                  )}
                                />
                              </div>
                            </Item>
                            <Item xs={4} />
                          </Container>
                          {path(['mode'], this.formData(this.state.editIndex, this.state.editingOld).document) ===
                            'cyclicStimulation' && (
                            <React.Fragment>
                              <Container style={{ marginTop: '2rem' }}>
                                <Item xs={4}>
                                  <StyledText>
                                    <FormattedMessage id="dbs.cyclicONTime" />
                                  </StyledText>
                                </Item>
                                <Item xs={4} style={{ textAlign: 'center', paddingLeft: '2.8rem' }}>
                                  <div style={{ display: 'inline-flex' }}>
                                    <InputHandler
                                      type="NumberField"
                                      editing={true}
                                      name="cyclicONTime"
                                      formData={this.formData(this.state.editIndex, this.state.editingOld)}
                                      placeholder="dbs.timePlaceholder"
                                      width={7}
                                      height={3}
                                    />
                                  </div>
                                  &nbsp;{' '}
                                  <div style={{ display: 'inline-flex', width: '2rem' }}>
                                    {defaultUnits.cyclicONTime}
                                  </div>
                                </Item>
                                <Item xs={4} />
                              </Container>
                              <Container style={{ marginTop: '2rem' }}>
                                <Item xs={4}>
                                  <StyledText>
                                    <FormattedMessage id="dbs.cyclicOFFTime" />
                                  </StyledText>
                                </Item>
                                <Item xs={4} style={{ textAlign: 'center', paddingLeft: '2.8rem' }}>
                                  <div style={{ display: 'inline-flex' }}>
                                    <InputHandler
                                      type="NumberField"
                                      editing={true}
                                      name="cyclicOFFTime"
                                      formData={this.formData(this.state.editIndex, this.state.editingOld)}
                                      placeholder="dbs.timePlaceholder"
                                      width={7}
                                      height={3}
                                    />
                                  </div>
                                  &nbsp;{' '}
                                  <div style={{ display: 'inline-flex', width: '2rem' }}>
                                    {defaultUnits.cyclicOFFTime}
                                  </div>
                                </Item>
                                <Item xs={4} />
                              </Container>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      </PlatformConditional>
                    </React.Fragment>
                  )}
                </Container>
                {lfpRegistrationProgramSelected && (
                  <React.Fragment>
                    <FormSection
                      header="dbs.automaticRegistration"
                      style={{ border: 'none', padding: '0 -3rem 0 -3rem', margin: '0 -3rem 0 -3rem' }}
                    >
                      <Container style={{ marginTop: '2rem' }}>
                        <Item xs={4}>
                          <StyledText>
                            <FormattedMessage id="dbs.rate" />
                          </StyledText>
                        </Item>
                        <Item xs={4} style={{ textAlign: 'center', paddingLeft: '2.8rem' }}>
                          <div style={{ display: 'inline-flex' }}>
                            <InputHandler
                              type="NumberField"
                              editing={true}
                              name="automaticRegistrationRate"
                              formData={this.formData(this.state.editIndex, this.state.editingOld)}
                              placeholder="dbs.ratePlaceholder"
                              width={7}
                              height={3}
                              maxLength={7}
                              min={0}
                              max={9999}
                              precision={2}
                            />
                          </div>
                          &nbsp; <div style={{ display: 'inline-flex', width: '2rem' }}>{defaultUnits.rate}</div>
                        </Item>
                        <Item xs={4} />
                      </Container>
                    </FormSection>
                    <FormSection
                      header="dbs.eventRegistration"
                      style={{ border: 'none', padding: '0 -3rem 0 -3rem', margin: '0 -3rem 0 -3rem' }}
                    >
                      <Container style={{ marginTop: '2rem' }}>
                        <Item xs={4}>
                          <StyledText>
                            <FormattedMessage id="dbs.eventRegistrationEvents" />
                          </StyledText>
                        </Item>
                        <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                          <div style={{ marginLeft: '4.6rem' }}>
                            <InputHandler
                              type="Radio"
                              editing={true}
                              name="eventRegistration"
                              formData={this.formData(this.state.editIndex, this.state.editingOld)}
                              options={['inUse', 'notInUse']}
                              optionFormatter={(name: string | number): React.JSX.Element => (
                                <FormattedMessage id={`dbs.opts.inUseNotInUse.${name}`} />
                              )}
                            />
                          </div>
                        </Item>
                        <Item xs={4} />
                      </Container>
                      {path(
                        ['eventRegistration'],
                        this.formData(this.state.editIndex, this.state.editingOld).document,
                      ) === 'inUse' && (
                        <Container style={{ marginTop: '3.5rem' }}>
                          <Item xs={4}>
                            <StyledText>
                              <FormattedMessage id="dbs.eventRegistrationEvent" />
                            </StyledText>
                          </Item>
                          <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginLeft: '17.5rem' }}>
                              <Container>
                                <Item xs={10}>
                                  <InputHandler
                                    type="TextField"
                                    editing={true}
                                    name="events"
                                    formData={{
                                      onChange: this.eventRegistrationEventsOnChange(0),
                                      document: {
                                        events: path(
                                          ['events', 0],
                                          this.formData(this.state.editIndex, this.state.editingOld).document,
                                        ),
                                      },
                                    }}
                                    placeholder="dbs.eventRegistrationEvent"
                                  />
                                </Item>
                                <Item xs={2} />
                              </Container>
                              {Array.isArray(
                                path(['events'], this.formData(this.state.editIndex, this.state.editingOld).document),
                              ) &&
                                (
                                  path(
                                    ['events'],
                                    this.formData(this.state.editIndex, this.state.editingOld).document,
                                  ) as []
                                ).length > 1 &&
                                (
                                  path(
                                    ['events'],
                                    this.formData(this.state.editIndex, this.state.editingOld).document,
                                  ) as []
                                )
                                  .slice(1)
                                  .map((_, index) => (
                                    <Container key={index} style={{ marginTop: '0.5rem' }}>
                                      <Item xs={10}>
                                        <InputHandler
                                          type="TextField"
                                          editing={true}
                                          name="events"
                                          formData={{
                                            onChange: this.eventRegistrationEventsOnChange(index + 1),
                                            document: {
                                              events: path(
                                                ['events', index + 1],
                                                this.formData(this.state.editIndex, this.state.editingOld).document,
                                              ),
                                            },
                                          }}
                                          placeholder="dbs.eventRegistrationEvent"
                                        />
                                      </Item>
                                      <Item xs={2}>
                                        <StyledClearIcon
                                          onClick={(): void => {
                                            this.eventRegistrationEventsOnChange(index + 1, true)();
                                          }}
                                        />
                                      </Item>
                                    </Container>
                                  ))}
                              {(Array.isArray(
                                path(['events'], this.formData(this.state.editIndex, this.state.editingOld).document),
                              )
                                ? (
                                    path(
                                      ['events'],
                                      this.formData(this.state.editIndex, this.state.editingOld).document,
                                    ) as []
                                  ).length < 4
                                : true) && (
                                <StyledNewRowButton
                                  onClick={(): void => {
                                    this.eventRegistrationEventsOnChange()();
                                  }}
                                >
                                  <FormattedMessage id="dbs.newEventRegistrationEvent" />
                                </StyledNewRowButton>
                              )}
                            </div>
                          </Item>
                          <Item xs={4} />
                        </Container>
                      )}
                    </FormSection>
                  </React.Fragment>
                )}
              </React.Fragment>
            </DialogContent>
            <DialogActions style={dialogActions}>
              <div style={dialogCancel} onClick={this.openCloseDialog(!editIndex && editIndex !== 0, true)}>
                <FormattedMessage id="general.cancel" />
              </div>
              <ActionButton
                text={'general.accept'}
                onClick={this.openCloseDialog(!editIndex && editIndex !== 0, false)}
                width={12}
                height={3}
                fontSize={16}
              />
            </DialogActions>
          </Dialog>
        ) : null}
        {!this.state.editIndex && this.state.editIndex !== 0 ? (
          <Container justifyContent="flex-start">
            <Item>
              <ToolTip
                title={<FormattedMessage id="dbs.addGeneratorFirst" />}
                content={
                  <span id={'newSettingButton'}>
                    <ActionButton
                      text={'dbs.newSetting'}
                      onClick={this.openCloseDialog()}
                      width={20}
                      height={5}
                      fontSize={18}
                      disabled={
                        generatorsUnavailable(formData.document, platform) || !generatorsExist(formData.document)
                      }
                    />
                  </span>
                }
                hover={generatorsUnavailable(formData.document, platform) || !generatorsExist(formData.document)}
                placement="top-start"
                arrow={false}
              />
            </Item>
          </Container>
        ) : null}
        {this.state.editIndex || this.state.editIndex === 0 ? (
          <Container justifyContent="flex-end">
            <Item>
              <ActionButton
                text={'general.edit'}
                onClick={this.openCloseDialog(undefined, undefined, true)}
                width={10}
                height={3}
                fontSize={14}
              />
            </Item>
            <Item style={{ margin: '0 0 0 2rem' }}>
              <ActionButton
                text={'general.delete'}
                onClick={this.openDeleteDialog()}
                width={10}
                height={3}
                fontSize={14}
              />
            </Item>
          </Container>
        ) : null}
        <ConfirmationDialog
          open={this.state.deleteDialogOpen}
          text={<FormattedMessage id="general.reallyWantToDelete" />}
          confirm={{ callback: this.deleteConfirmCallback }}
          cancel={{ callback: this.deleteCancelCallback }}
        />
      </React.Fragment>
    );
  }
}

interface IOwnState {
  dialogOpen: boolean;
  editIndex?: number;
  selectedProgram: number;
  selectedProgramInput?: { initial: boolean; selected?: number };
  editingDocument: IDBSSetting;
  editingOld: boolean;
  settingMenuOpen: SVGSVGElement | HTMLElement | null;
  deleteDialogOpen: boolean;
  deleteProgramDialogOpen: boolean;
}

interface IOwnProps {
  formData: IFormData;
  editIndex?: number; // Editing an old setting
  platform: string;
  intl: IntlShape;
}

export default injectIntl(SettingDialog);
