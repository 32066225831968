import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../components/FormRow';
import InputHandler from '../../../../components/InputHandler';
import InputHistoryAcceptor from '../../../../components/InputHistoryAcceptor';
import { TParkinsonSharedLifestyle } from 'neuro-schemas';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): React.JSX.Element => {
    return <FormattedMessage id={`lifestyle.${opt}.${name}`} />;
  };

const LifestyleFormDynamic = ({ formData, documents }: IOwnProps): React.JSX.Element => {
  const onlyMobileFields = formData.document._type === 'lifestyleMobile';
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          name="date"
          editing={true}
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="lifestyle.physicalExercise.title">
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="physicalExercise"
          editing={true}
          formData={formData}
          options={[
            'daily',
            '4-6timesPerWeek',
            '2-3timesPerWeek',
            'oncePerWeek',
            '2-3timesPerMonth',
            'severalTimesPerYearOrLessFrequently',
            ...(onlyMobileFields ? [] : ['unknown']),
          ]}
          optionFormatter={optionFormatter('physicalExercise')}
        />
      </FormRow>
      <FormRow title="lifestyle.mealsPerDay.title" condition={!onlyMobileFields}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="mealsPerDay"
          editing={true}
          formData={formData}
          options={[
            'twoMealsPerDay',
            '1-2mealsPerDay',
            'oneMealPerDay',
            'oneMealNearlyDaily',
            'oneMealTwicePerWeekOrLessFrequently',
            'unknown',
          ]}
          optionFormatter={optionFormatter('mealsPerDay')}
        />
      </FormRow>
      <FormRow title="lifestyle.hasSpecialDiets.title" condition={!onlyMobileFields}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="hasSpecialDiets"
          editing={true}
          formData={formData}
          options={['yes', 'no', 'unknown']}
          optionFormatter={optionFormatter('hasSpecialDiets')}
          dependentFieldsList={() => ['specialDiets']}
        />
      </FormRow>
      <FormRow
        title="lifestyle.specialDiets.title"
        condition={
          !onlyMobileFields && 'hasSpecialDiets' in formData.document && formData.document?.hasSpecialDiets === 'yes'
        }
      >
        <InputHistoryAcceptor
          documents={documents}
          type="Checkbox"
          name="specialDiets"
          editing={true}
          formData={formData}
          options={[
            'vegetarian',
            'vegan',
            'lactoseFree',
            'glutenFree',
            'diabetic',
            'lowCholesterol',
            'weightLoss',
            'foodAllergy',
            'other',
          ]}
          optionFormatter={optionFormatter('specialDiets')}
          dependentFieldsList={() => [
            'meatEatenLastTime',
            'doesVegetarianDietIncludeEggAndMilk',
            'whichFoodAllergies',
            'whichOtherDiet',
          ]}
        />
      </FormRow>
      {'specialDiets' in formData.document &&
        Array.isArray(formData.document.specialDiets) &&
        formData.document.specialDiets?.includes('vegetarian') && (
          <>
            <FormRow title="lifestyle.meatEatenLastTime.title" id={'meatLastTime'}>
              <InputHistoryAcceptor
                documents={documents}
                type="PartialDate"
                name="meatEatenLastTime"
                editing={true}
                formData={formData}
                isNotCancellable={true}
              />
            </FormRow>
            <FormRow title="lifestyle.doesVegetarianDietIncludeEggAndMilk.title" id={'eggAndMilk'}>
              <InputHistoryAcceptor
                documents={documents}
                type="Radio"
                name="doesVegetarianDietIncludeEggAndMilk"
                editing={true}
                formData={formData}
                options={['yes', 'no', 'unknown']}
                optionFormatter={optionFormatter('doesVegetarianDietIncludeEggAndMilk')}
              />
            </FormRow>
          </>
        )}
      {'specialDiets' in formData.document &&
        Array.isArray(formData.document.specialDiets) &&
        formData.document.specialDiets?.includes('foodAllergy') && (
          <FormRow title="lifestyle.whichFoodAllergies.title">
            <InputHistoryAcceptor
              documents={documents}
              type="TextField"
              name="whichFoodAllergies"
              editing={true}
              formData={formData}
            />
          </FormRow>
        )}
      {'specialDiets' in formData.document &&
        Array.isArray(formData.document.specialDiets) &&
        formData.document.specialDiets?.includes('other') && (
          <FormRow title="lifestyle.whichOtherDiet.title">
            <InputHistoryAcceptor
              documents={documents}
              type="TextField"
              name="whichOtherDiet"
              editing={true}
              formData={formData}
            />
          </FormRow>
        )}
      <FormRow title="lifestyle.coffeeDrinking.title" id={'coffee'} condition={!onlyMobileFields}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="coffeeDrinking"
          editing={true}
          formData={formData}
          options={['drinks', 'drankEarlier', 'doesntDrink', 'unknown']}
          optionFormatter={optionFormatter('coffeeDrinking')}
          dependentFieldsList={() => ['howMuchCoffee', 'coffeeDrinkenLastTime']}
        />
      </FormRow>
      {'coffeeDrinking' in formData.document &&
        (formData.document.coffeeDrinking === 'drinks' || formData.document.coffeeDrinking === 'drankEarlier') && (
          <FormRow title="lifestyle.howMuchCoffee.title" id={'coffeeAmount'}>
            <InputHistoryAcceptor
              documents={documents}
              type="Radio"
              name="howMuchCoffee"
              editing={true}
              formData={formData}
              options={['<6dl', '6-12dl', '>12dl', 'unknown']}
              optionFormatter={optionFormatter('howMuchCoffee')}
            />
          </FormRow>
        )}
      {'coffeeDrinking' in formData.document && formData.document.coffeeDrinking === 'drankEarlier' && (
        <FormRow title="lifestyle.coffeeDrinkenLastTime.title">
          <InputHistoryAcceptor
            documents={documents}
            type="PartialDate"
            name="coffeeDrinkenLastTime"
            editing={true}
            formData={formData}
            isNotCancellable={true}
          />
        </FormRow>
      )}
      <FormRow title="lifestyle.teaDrinking.title" id={'tea'} condition={!onlyMobileFields}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="teaDrinking"
          editing={true}
          formData={formData}
          options={['drinks', 'drankEarlier', 'doesntDrink', 'unknown']}
          optionFormatter={optionFormatter('teaDrinking')}
          dependentFieldsList={() => ['howMuchTea', 'teaDrinkenLastTime']}
        />
      </FormRow>
      {'teaDrinking' in formData.document &&
        (formData.document.teaDrinking === 'drinks' || formData.document.teaDrinking === 'drankEarlier') && (
          <FormRow title="lifestyle.howMuchTea.title" id={'teaAmount'}>
            <InputHistoryAcceptor
              documents={documents}
              type="Radio"
              name="howMuchTea"
              editing={true}
              formData={formData}
              options={['<6dl', '6-12dl', '>12dl', 'unknown']}
              optionFormatter={optionFormatter('howMuchTea')}
            />
          </FormRow>
        )}
      {'teaDrinking' in formData.document && formData.document.teaDrinking === 'drankEarlier' && (
        <FormRow title="lifestyle.teaDrinkenLastTime.title">
          <InputHistoryAcceptor
            documents={documents}
            type="PartialDate"
            name="teaDrinkenLastTime"
            editing={true}
            formData={formData}
            isNotCancellable={true}
          />
        </FormRow>
      )}
      <FormRow title="lifestyle.energyDrinkDrinking.title" id={'energyDrink'} condition={!onlyMobileFields}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="energyDrinkDrinking"
          editing={true}
          formData={formData}
          options={['drinks', 'drankEarlier', 'doesntDrink', 'unknown']}
          optionFormatter={optionFormatter('energyDrinkDrinking')}
          dependentFieldsList={() => ['howMuchEnergyDrinks', 'energyDrinksDrinkenLastTime']}
        />
      </FormRow>
      {'energyDrinkDrinking' in formData.document &&
        (formData.document.energyDrinkDrinking === 'drinks' ||
          formData.document.energyDrinkDrinking === 'drankEarlier') && (
          <FormRow title="lifestyle.howMuchEnergyDrinks.title">
            <InputHistoryAcceptor
              documents={documents}
              type="Radio"
              name="howMuchEnergyDrinks"
              editing={true}
              formData={formData}
              options={['<6dl', '6-12dl', '>12dl', 'unknown']}
              optionFormatter={optionFormatter('howMuchEnergyDrinks')}
            />
          </FormRow>
        )}
      {'energyDrinkDrinking' in formData.document && formData.document.energyDrinkDrinking === 'drankEarlier' && (
        <FormRow title="lifestyle.energyDrinksDrinkenLastTime.title">
          <InputHistoryAcceptor
            documents={documents}
            type="PartialDate"
            name="energyDrinksDrinkenLastTime"
            editing={true}
            formData={formData}
            isNotCancellable={true}
          />
        </FormRow>
      )}
      <FormRow title="lifestyle.smoking.title">
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="smoking"
          editing={true}
          formData={formData}
          options={['smokes', 'smokedEarlier', 'doesntSmoke', ...(onlyMobileFields ? [] : ['unknown'])]}
          optionFormatter={optionFormatter('smoking')}
          dependentFieldsList={() => ['smokingStarted', 'cigarettesPerDay', 'smokedLastTime']}
        />
      </FormRow>
      {(formData.document.smoking === 'smokes' || formData.document.smoking === 'smokedEarlier') && (
        <>
          <FormRow title="lifestyle.smokingStarted.title">
            <InputHistoryAcceptor
              documents={documents}
              type="PartialDate"
              name="smokingStarted"
              editing={true}
              formData={formData}
              isNotCancellable={true}
            />
          </FormRow>
          <FormRow title="lifestyle.cigarettesPerDay.title">
            <InputHistoryAcceptor
              documents={documents}
              type="NumberField"
              name="cigarettesPerDay"
              editing={true}
              formData={formData}
            />
          </FormRow>
        </>
      )}
      {formData.document.smoking === 'smokedEarlier' && (
        <FormRow title="lifestyle.smokedLastTime.title">
          <InputHistoryAcceptor
            documents={documents}
            type="PartialDate"
            name="smokedLastTime"
            editing={true}
            formData={formData}
            isNotCancellable={true}
          />
        </FormRow>
      )}
      <FormRow title="lifestyle.snus.title" id={'snus'}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="snus"
          editing={true}
          formData={formData}
          options={['uses', 'usedEarlier', 'doesntUse', ...(onlyMobileFields ? [] : ['unknown'])]}
          optionFormatter={optionFormatter('snus')}
          dependentFieldsList={() => ['usingSnusStarted', 'snusPortionsPerDay', 'snusUsedLastTime']}
        />
      </FormRow>
      {(formData.document.snus === 'uses' || formData.document.snus === 'usedEarlier') && (
        <>
          <FormRow title="lifestyle.usingSnusStarted.title">
            <InputHistoryAcceptor
              documents={documents}
              type="PartialDate"
              name="usingSnusStarted"
              editing={true}
              formData={formData}
              isNotCancellable={true}
            />
          </FormRow>
          <FormRow title="lifestyle.snusPortionsPerDay.title">
            <InputHistoryAcceptor
              documents={documents}
              type="NumberField"
              name="snusPortionsPerDay"
              editing={true}
              formData={formData}
            />
          </FormRow>
        </>
      )}
      {formData.document.snus === 'usedEarlier' && (
        <FormRow title="lifestyle.snusUsedLastTime.title">
          <InputHistoryAcceptor
            documents={documents}
            type="PartialDate"
            name="snusUsedLastTime"
            editing={true}
            formData={formData}
            isNotCancellable={true}
          />
        </FormRow>
      )}
      <FormRow title="lifestyle.nicotineProducts.title" id={'nicotine'}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="nicotineProducts"
          editing={true}
          formData={formData}
          options={['uses', 'usedEarlier', 'doesntUse', ...(onlyMobileFields ? [] : ['unknown'])]}
          optionFormatter={optionFormatter('nicotineProducts')}
          dependentFieldsList={() => [
            'usingNicotineProductsStarted',
            'howMuchNicotineProductsUsed',
            'nicotineProductsUsedLastTime',
          ]}
        />
      </FormRow>
      {(formData.document.nicotineProducts === 'uses' || formData.document.nicotineProducts === 'usedEarlier') && (
        <>
          <FormRow title="lifestyle.usingNicotineProductsStarted.title">
            <InputHistoryAcceptor
              documents={documents}
              type="PartialDate"
              name="usingNicotineProductsStarted"
              editing={true}
              formData={formData}
              isNotCancellable={true}
            />
          </FormRow>
          <FormRow title="lifestyle.howMuchNicotineProductsUsed.title">
            <InputHistoryAcceptor
              documents={documents}
              type="TextArea"
              name="howMuchNicotineProductsUsed"
              editing={true}
              formData={formData}
            />
          </FormRow>
        </>
      )}
      {formData.document.nicotineProducts === 'usedEarlier' && (
        <FormRow title="lifestyle.nicotineProductsUsedLastTime.title">
          <InputHistoryAcceptor
            documents={documents}
            type="PartialDate"
            name="nicotineProductsUsedLastTime"
            editing={true}
            formData={formData}
            isNotCancellable={true}
          />
        </FormRow>
      )}
      <FormRow title="lifestyle.alcoholDrinking.title">
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="alcoholDrinking"
          editing={true}
          formData={formData}
          options={[
            'never',
            'lessFrequentlyThanOncePerMonth',
            '1-2timesPerMonth',
            '3-4timesPerMonth',
            '2-3timesPerWeek',
            '4timesOrMoreFrequentlyPerWeek',
            ...(onlyMobileFields ? [] : ['unknown']),
          ]}
          optionFormatter={optionFormatter('alcoholDrinking')}
          dependentFieldsList={() => ['alcoholDrinkingMoreThan6portions']}
        />
      </FormRow>
      {formData.document.alcoholDrinking && formData.document.alcoholDrinking != 'never' && (
        <FormRow title="lifestyle.alcoholDrinkingMoreThan6portions.title" id={'moreThan6'}>
          <InputHistoryAcceptor
            documents={documents}
            type="Radio"
            name="alcoholDrinkingMoreThan6portions"
            editing={true}
            formData={formData}
            options={[
              'never',
              'lessFrequentlyThanOncePerMonth',
              'lessFrequentlyThanOncePerWeek',
              'oncePerWeek',
              'daily',
              ...(onlyMobileFields ? [] : ['unknown']),
            ]}
            optionFormatter={optionFormatter('alcoholDrinkingMoreThan6portions')}
          />
        </FormRow>
      )}
      <FormRow title="lifestyle.otherIntoxicants.title" id={'otherIntoxicants'}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="otherIntoxicants"
          editing={true}
          formData={formData}
          options={['yes', 'no', ...(onlyMobileFields ? [] : ['unknown'])]}
          optionFormatter={optionFormatter('otherIntoxicants')}
          dependentFieldsList={() => [
            'cannabis',
            'otherIntoxicantThanCannabis',
            'whichOtherIntoxicant',
            'otherIntoxicantUse',
          ]}
        />
      </FormRow>
      {formData.document.otherIntoxicants === 'yes' && (
        <FormRow title="lifestyle.cannabis.title" id={'cannabis'} condition={!onlyMobileFields}>
          <InputHistoryAcceptor
            documents={documents}
            type="Radio"
            name="cannabis"
            editing={true}
            formData={formData}
            options={[
              'never',
              'lessFrequentlyThanOncePerMonth',
              'lessFrequentlyThanOncePerWeek',
              'oncePerWeek',
              '2-3timesPerWeek',
              '4timesOrMoreFrequentlyPerWeek',
              'unknown',
            ]}
            optionFormatter={optionFormatter('cannabis')}
            dependentFieldsList={() => ['otherIntoxicantThanCannabis', 'whichOtherIntoxicant', 'otherIntoxicantUse']}
          />
        </FormRow>
      )}
      {'cannabis' in formData.document && formData.document.cannabis && formData.document.cannabis !== 'never' ? (
        <FormRow title="lifestyle.otherIntoxicantThanCannabis.title" id={'otherThanCannabis'}>
          <InputHistoryAcceptor
            documents={documents}
            type="Radio"
            name="otherIntoxicantThanCannabis"
            editing={true}
            formData={formData}
            options={['yes', 'no', 'unknown']}
            optionFormatter={optionFormatter('otherIntoxicantThanCannabis')}
            dependentFieldsList={() => ['whichOtherIntoxicant', 'otherIntoxicantUse']}
          />
        </FormRow>
      ) : null}
      {formData.document.otherIntoxicants === 'yes' &&
        (('cannabis' in formData.document && formData.document.cannabis === 'never') ||
          ('otherIntoxicantThanCannabis' in formData.document &&
            formData.document.otherIntoxicantThanCannabis === 'yes')) && (
          <>
            <FormRow title="lifestyle.whichOtherIntoxicant.title">
              <InputHistoryAcceptor
                documents={documents}
                type="TextArea"
                name="whichOtherIntoxicant"
                editing={true}
                formData={formData}
              />
            </FormRow>
            <FormRow title="lifestyle.otherIntoxicantUse.title">
              <InputHistoryAcceptor
                documents={documents}
                type="Radio"
                name="otherIntoxicantUse"
                editing={true}
                formData={formData}
                options={[
                  'never',
                  'lessFrequentlyThanOncePerMonth',
                  'lessFrequentlyThanOncePerWeek',
                  'oncePerWeek',
                  '2-3timesPerWeek',
                  '4timesOrMoreFrequentlyPerWeek',
                  'unknown',
                ]}
                optionFormatter={optionFormatter('otherIntoxicantUse')}
              />
            </FormRow>
          </>
        )}
      <FormRow title="lifestyle.senseOfSmellWeakened.title" id={'senseOfSmell'} condition={!onlyMobileFields}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="senseOfSmellWeakened"
          editing={true}
          formData={formData}
          options={['yes', 'no', 'unknown']}
          optionFormatter={optionFormatter('senseOfSmellWeakened')}
          dependentFieldsList={() => ['sinceWhenHasSenseOfSmellWeakened']}
        />
      </FormRow>
      {'senseOfSmellWeakened' in formData.document && formData.document.senseOfSmellWeakened === 'yes' && (
        <FormRow title="lifestyle.sinceWhenHasSenseOfSmellWeakened.title">
          <InputHistoryAcceptor
            documents={documents}
            type="PartialDate"
            name="sinceWhenHasSenseOfSmellWeakened"
            editing={true}
            formData={formData}
            isNotCancellable={true}
          />
        </FormRow>
      )}
      <FormRow title="lifestyle.livingAtCountryside.title" id={'countryside'} condition={!onlyMobileFields}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="livingAtCountryside"
          editing={true}
          formData={formData}
          options={['yes', 'no', 'unknown']}
          optionFormatter={optionFormatter('livingAtCountryside')}
          dependentFieldsList={() => [
            'sinceWhenHasLivedOnCountryside',
            'forHowManyYearsHasLivedOnCountryside',
            'hasDrinkenMainlyWellWater',
          ]}
        />
      </FormRow>
      {'livingAtCountryside' in formData.document && formData.document.livingAtCountryside === 'yes' && (
        <>
          <FormRow title="lifestyle.sinceWhenHasLivedOnCountryside.title">
            <InputHistoryAcceptor
              documents={documents}
              type="PartialDate"
              name="sinceWhenHasLivedOnCountryside"
              editing={true}
              formData={formData}
              isNotCancellable={true}
            />
          </FormRow>
          <FormRow title="lifestyle.forHowManyYearsHasLivedOnCountryside.title">
            <InputHistoryAcceptor
              documents={documents}
              type="NumberField"
              name="forHowManyYearsHasLivedOnCountryside"
              editing={true}
              formData={formData}
            />
          </FormRow>
          <FormRow title="lifestyle.hasDrinkenMainlyWellWater.title" id={'wellwater'}>
            <InputHistoryAcceptor
              documents={documents}
              type="Radio"
              name="hasDrinkenMainlyWellWater"
              editing={true}
              formData={formData}
              options={['yes', 'no', 'unknown']}
              optionFormatter={optionFormatter('hasDrinkenMainlyWellWater')}
            />
          </FormRow>
        </>
      )}
      <FormRow title="lifestyle.exposedToSolvents.title" id={'exposedToSolvents'} condition={!onlyMobileFields}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="exposedToSolvents"
          editing={true}
          formData={formData}
          options={['yes', 'no', 'unknown']}
          optionFormatter={optionFormatter('exposedToSolvents')}
        />
      </FormRow>
      <FormRow title="lifestyle.accomplishedDreams.title" id={'dreams'} condition={!onlyMobileFields}>
        <InputHistoryAcceptor
          documents={documents}
          type="Radio"
          name="accomplishedDreams"
          editing={true}
          formData={formData}
          options={['yes', 'no', 'unknown']}
          optionFormatter={optionFormatter('accomplishedDreams')}
        />
      </FormRow>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<ILifestyle | (TParkinsonSharedLifestyle & IControlProps)>;
  documents: Array<ILifestyle | (TParkinsonSharedLifestyle & IControlProps)>;
}

export default LifestyleFormDynamic;
