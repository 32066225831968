import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import History from './HistoryRowData';
import Form from './Form';
import { docType, TDocument } from '../utils';

const Imaging = ({ documents }: IOwnProps): React.JSX.Element => (
  <FormEditingHandler name="imaging" documents={documents}>
    {(editing, startEdit, formData, view): React.JSX.Element => {
      const getHeaderId = () => {
        switch (docType(documents, editing)) {
          case 'cmap':
            return 'imaging.cmap';
          case 'dexa':
            return 'imaging.dexa';
          case 'muscleImaging':
            return 'imaging.muscleImaging';
          case 'echocardiography':
            return 'imaging.echocardiography';
          case 'scoliosis':
            return 'imaging.scoliosis';
          case 'pelvis':
            return 'imaging.pelvis';
          case 'mri':
            return 'imaging.mri';
          case 'tt':
            return 'imaging.tt';
          case 'scintigraphy':
            return 'imaging.scintigraphy';
          case 'thymusCt':
            return 'imaging.thymusCt';
          case 'thymusMri':
            return 'imaging.thymusMri';
          default:
            return 'imaging.title';
        }
      };
      return (
        <DocumentWrapper>
          <DocumentHeader name={'imaging'} headerId={getHeaderId()} editing={editing} editButtons={<div />} />
          {editing ? (
            <Form formData={formData} document={documents && documents.find((d: TDocument) => d._id === editing)} />
          ) : null}
          {!editing && !view?.viewing ? <History documents={documents} startEdit={startEdit} /> : undefined}
        </DocumentWrapper>
      );
    }}
  </FormEditingHandler>
);

interface IOwnProps {
  documents: Array<TDocument>;
}

export default Imaging;
