import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/system';

import { StandAloneHeader, StandAloneContent, StepperHeaderFormInputPair } from 'Components/EventStepper/components';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import ActionButton from 'Components/ActionButton';
import ConfirmationDialog from 'Components/ConfirmationDialog';

import { IGrantFormData } from '.';
import colors from '../../../../../config/theme/colors';

const StyledMenuIcon = styled(({ ...other }) => <MoreVert {...other} />)<{
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}>({
  display: 'block !important',
  color: '#6c96ae',
  cursor: 'pointer',
});

const StyledCancel = styled('a')({
  fontSize: '1.6rem',
  fontWeight: 600,
  cursor: 'pointer',
  color: colors.primary,
  textTransform: 'uppercase',
  marginRight: '2rem',
});

const EditingGrant = ({
  save,
  cancel,
  grant,
  onChange,
  fm,
  editing,
  newGrant,
  omittedOrgs,
  organizations,
}: {
  save: () => void;
  cancel: () => void;
  grant?: IGrantFormData | null;
  onChange: (values: TOnChangeValues) => void;
  fm: (id: string) => string;
  editing: boolean;
  newGrant: boolean;
  omittedOrgs?: string[];
  organizations: IOrg[];
}) => {
  const listedOrgs: string[] = [];
  organizations
    .map((o) => o.orgId)
    .forEach((org) => {
      if (omittedOrgs?.includes(org)) return;
      listedOrgs.push(org);
    });

  return (
    <div style={{ backgroundColor: colors.lightestGray, padding: '2rem 2rem 2rem 5rem' }}>
      <>
        {newGrant && (
          <StepperHeaderFormInputPair
            header={fm('grants.granteeOrg')}
            input={
              <InputHandler
                type="Select"
                editing={editing}
                name="granteeOrg"
                formData={{
                  onChange,
                  document: { granteeOrg: grant?.granteeOrg },
                }}
                options={listedOrgs}
                optionFormatter={(id: string | number): string => {
                  const granteeOrg = organizations.find((o) => id === o.orgId);
                  return granteeOrg?.displayName ?? '';
                }}
                placeholder={'grants.chooseOrg'}
              />
            }
          />
        )}
        <StepperHeaderFormInputPair
          header={fm('grants.patientGrants')}
          input={
            <InputHandler
              type="Radio"
              editing={editing}
              name="allow_read"
              formData={{
                onChange,
                document: { allow_read: grant?.allow_read },
              }}
              options={['*']}
              optionFormatter={(): string => fm('general.yes')}
            />
          }
        />
      </>
      {editing && (
        <Container alignItems="center" justifyContent="flex-end">
          <Item>{cancel && <StyledCancel onClick={cancel}>{fm('general.cancel')}</StyledCancel>}</Item>
          <Item>
            <ActionButton
              text={'general.accept'}
              onClick={save}
              width={12}
              height={3}
              fontSize={16}
              disabled={!grant?.allow_read || !grant?.granteeOrg}
            />
          </Item>
        </Container>
      )}
    </div>
  );
};

const GrantItem = ({
  save,
  cancel,
  thisGrant,
  onChange,
  editingGrant,
  fm,
  onDelete,
  omittedOrgs,
  organizations,
}: IGrantItem) => {
  // Where the menu will spawn
  const [anchor, setAnchor] = React.useState<null | { anchor: HTMLElement; id: string }>(null);
  const toggleMenu =
    (id: string) =>
    (e: React.MouseEvent<HTMLElement>): void => {
      //e.stopPropagation();
      setAnchor(!anchor ? { anchor: e.currentTarget, id } : null);
    };

  // Set index for deletion
  const [deleteID, setDeleteID] = React.useState<string | null>(null);
  const openDeleteDialog =
    (id: string) =>
    (e: React.MouseEvent): void => {
      e.stopPropagation();
      setDeleteID(id);
      setAnchor(null);
    };

  const newItem = !!(editingGrant && !thisGrant);

  return (
    <>
      {thisGrant && (
        <StandAloneHeader>
          <Container alignItems="center" style={{ height: '100%' }}>
            <Item xs={6}>
              {organizations.find((o) => thisGrant.granteeOrg === o.orgId)?.displayName || thisGrant.granteeOrg}
            </Item>
            <Item xs={6}>
              <Container justifyContent="flex-end" alignItems="center">
                {!newItem && (
                  <>
                    <Item style={{ marginRight: '2rem' }}>
                      <StyledMenuIcon onClick={toggleMenu(thisGrant.id)} />
                    </Item>

                    <Menu
                      anchorEl={anchor?.id === thisGrant.id ? anchor?.anchor : null}
                      open={Boolean(anchor?.id === thisGrant.id ? anchor?.anchor : null)}
                      onClose={toggleMenu(thisGrant.id)}
                    >
                      <MenuItem onClick={openDeleteDialog(thisGrant.id)}>{fm('general.deletionTitle')}</MenuItem>
                    </Menu>
                  </>
                )}
              </Container>
            </Item>
          </Container>
        </StandAloneHeader>
      )}
      <StandAloneContent editing={true}>
        {editingGrant || thisGrant ? (
          <EditingGrant
            save={save}
            cancel={cancel}
            grant={editingGrant?.id === thisGrant?.id ? editingGrant : newItem ? editingGrant : thisGrant}
            onChange={onChange}
            fm={fm}
            editing={editingGrant?.id === thisGrant?.id || !thisGrant}
            newGrant={newItem}
            omittedOrgs={omittedOrgs}
            organizations={organizations}
          />
        ) : null}
      </StandAloneContent>
      <ConfirmationDialog
        open={!!deleteID}
        text={fm('general.reallyWantToDelete')}
        confirm={{
          callback: () => {
            onDelete && deleteID && onDelete(deleteID);
            setDeleteID(null);
          },
        }}
        cancel={{ callback: () => setDeleteID(null) }}
      />
    </>
  );
};

export interface IOrg {
  orgId: string;
  displayName: string;
}

interface IGrantItem {
  save: () => void;
  cancel: () => void;
  setEditing: (id: string) => () => void;
  thisGrant?: IGrantFormData;
  onChange: (values: TOnChangeValues) => void;
  fm: (id: string) => string;
  editingGrant: IGrantFormData | null;
  onDelete?: (id: string) => void;
  omittedOrgs?: string[];
  organizations: IOrg[];
}

export default GrantItem;
