import { Dialog, DialogActions, DialogTitle, Paper, PaperProps } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/system';

import colors from '../../../../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { subjectOfTreatmentTitle } from 'Utility/ninmtUtil';
import { TDCSContext } from 'Routes/Tdcs/Document';

const StyledContainer = styled(Container)({
  alignContent: 'flex-start',
  justifyItems: 'center',
  flexDirection: 'column',
  fontSize: '1.6rem',
  rowGap: 0,
  color: colors.darkGray,
});

const StyledTitleItem = styled(Item)({
  fontSize: '2.2rem',
  fontWeight: 600,
  marginBottom: '0.5rem',
  color: colors.primary,
});

const StyledNameItem = styled(Item)({
  fontWeight: 600,
  marginTop: '-0.5rem',
});

const DialogPaper = (props: PaperProps): React.JSX.Element => <Paper square={true} {...props} />;

const TreatmentDeleteDialog = ({
  open,
  editIndex,
  selected,
  cancel,
  confirm,
  width = 'xs',
}: ITreatmentDeleteDialog): React.JSX.Element => {
  const [treatmentDeleteReason, setTreatmentDeleteReason] = React.useState<string | undefined>(undefined);

  const tdcsContext = React.useContext(TDCSContext);
  const { formData, fm } = tdcsContext;

  const treatment = formData.document.sessions?.[editIndex]?.subjectOfTreatment?.[selected];

  return (
    <Dialog open={open} maxWidth={width} fullWidth={true} PaperComponent={DialogPaper}>
      <DialogTitle style={{ padding: '3rem' }}>
        <StyledContainer>
          <StyledTitleItem>{fm('tdcs.subjectOfTreatment.treatmentDeleteTitle')}</StyledTitleItem>
          <Item>{fm('tdcs.subjectOfTreatment.treatmentDeleteInfo')}:</Item>
          <StyledNameItem>{subjectOfTreatmentTitle(treatment ?? ({} as ITDCSTreatment), 'tdcs', fm)}</StyledNameItem>
          <Item>{fm('tdcs.subjectOfTreatment.treatmentDeleteReason')}:</Item>
          <Item>
            <InputHandler
              type="Radio"
              name="treatmentDeleteReason"
              editing={true}
              formData={{
                document: { treatmentDeleteReason: treatmentDeleteReason },
                onChange: (values: TOnChangeValues) => {
                  const value = values[Object.keys(values)[0]];
                  setTreatmentDeleteReason(typeof value === 'string' ? value : undefined);
                },
              }}
              options={['partOfTreatmentProtocol', 'otherReason']}
              optionFormatter={(id: string | number) => fm(`tdcs.opts.treatmentDeleteReason.${id}`)}
              compact
            />
          </Item>
        </StyledContainer>
      </DialogTitle>
      <DialogActions style={{ padding: '0rem 3rem 3rem 3rem' }}>
        <Container justifyContent="space-between">
          <Item>
            <ActionButtonRounded
              text="general.cancel"
              onClick={cancel?.callback || undefined}
              width={10}
              height={4}
              fontSize={16}
              loading={confirm.loading}
              colorScheme="cancel"
            />
          </Item>
          <Item>
            <ActionButtonRounded
              text="general.delete"
              onClick={confirm?.callback ? () => confirm.callback?.(treatmentDeleteReason) : undefined}
              width={8}
              height={4}
              fontSize={16}
              loading={confirm.loading}
              disabled={!treatmentDeleteReason}
            />
          </Item>
        </Container>
      </DialogActions>
    </Dialog>
  );
};

interface ITreatmentDeleteDialog {
  open: boolean;
  editIndex: number;
  selected: number;
  cancel?: {
    callback: (() => void) | null;
    text?: string | React.JSX.Element; // Locale element or any string
  };
  confirm: {
    callback: ((deleteReason?: string) => void) | null;
    text?: string; // Locale string
    loading?: boolean;
  };
  width?: 'xs' | 'sm' | 'md' | 'lg';
}

export default TreatmentDeleteDialog;
