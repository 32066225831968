import * as React from 'react';
import { IFormContext, withFormContext } from '../../../../containers/FormContextHandler';
import SeizureForm from '../Form/SeizureForm';
import SeizureFreePeriodForm from '../Form/SeizureFreePeriodForm';
import ConfirmedSeizures from './ConfirmedSeizures';
import SeizureFreePeriods from './SeizureFreePeriods';
import HistoryTabs from 'Components/HistoryTabs';
import PatientReportedSeizuresHistory from './PatientReportedSeizures';
import { useSelector } from 'react-redux';

const SeizureHistory = ({
  props,
  setPatRepSeizFormOpen,
  openTab,
  setOpenTab,
}: {
  props: IFormContext;
  setPatRepSeizFormOpen: React.Dispatch<
    React.SetStateAction<
      | {
          doc: IPatientReportedSeizure & IControlProps;
          type: 'edit' | 'view';
        }
      | undefined
    >
  >;
  openTab: number;
  setOpenTab: React.Dispatch<React.SetStateAction<number>>;
}): React.JSX.Element => {
  const { documents, editing } = props;

  const sortedAndMergedDocs = useSelector((s: IState) => s.myapp.sortedAndMergedDocuments);
  const patientReportedSeizureDocs = sortedAndMergedDocs?.filter((d) => d._type === 'patientReportedSeizure') ?? [];

  const editingDoc = documents?.find((d) => d._id === editing) as ISeizureDoc | undefined;
  const viewDoc = documents?.find((d) => d._id === props.view?.viewing) as ISeizureDoc | undefined;

  const seizureDocs = documents?.filter((d) => d._type === 'seizure' && d._id !== editing) || [];
  const seizureFreeDocs = documents?.filter((d) => d._type === 'seizureFreePeriod' && d._id !== editing) || [];

  return editingDoc || viewDoc?._type === 'seizure' || viewDoc?._type === 'seizureFreePeriod' ? (
    <>
      {(editingDoc?._type === 'seizure' || viewDoc?._type === 'seizure') && <SeizureForm />}
      {(editingDoc?._type === 'seizureFreePeriod' || viewDoc?._type === 'seizureFreePeriod') && (
        <SeizureFreePeriodForm
          documents={[...(documents ?? []), ...patientReportedSeizureDocs.filter((d) => d.status === 'accepted')]}
        />
      )}
    </>
  ) : (
    <HistoryTabs indexSelectionTools={{ index: openTab, changeFunction: (index) => setOpenTab(index) }}>
      {[
        {
          title: 'seizure.reportedSeizures',
          content: <PatientReportedSeizuresHistory documents={patientReportedSeizureDocs} />,
        },
        {
          title: 'seizure.confirmed',
          content: (
            <ConfirmedSeizures
              documents={seizureDocs}
              patientReportedDocs={patientReportedSeizureDocs}
              setPatRepSeizFormOpen={setPatRepSeizFormOpen}
            />
          ),
        },
        {
          title: 'seizure.seizureFreePeriods',
          content: <SeizureFreePeriods documents={seizureFreeDocs} />,
        },
      ]}
    </HistoryTabs>
  );
};

export default withFormContext(SeizureHistory);
