import * as React from 'react';
import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';
import { fm } from 'Components/FormatMessage';
import colors from '../../../../../config/theme/colors';
import Unit from 'Components/Unit';
import {
  cTBSFields,
  getRtmsRowData,
  getTdcsRowData,
  iTBSFields,
  originalRTMSFields,
  tdcsFields,
} from 'Utility/ninmtUtil';

const ValueDiv = styled('div')({
  display: 'inline-flex',
  fontWeight: 600,
});

const OperatorDiv = styled('div')({
  display: 'inline-flex',
  margin: '0rem 1rem 0rem 1rem',
});

const TitleContainer = styled(Container)({
  color: colors.darkGray,
  fontWeight: 'bold',
});

const NameContainer = styled(Container)({
  color: colors.tertiaryText,
  padding: '0 0 5px 0',
});

const ValueItem = styled(Item)((props: { index: number }) => ({
  padding: '0.4rem 0 0.4rem 0',
  borderTop: props.index === 0 ? `1px solid ${colors.gray}` : undefined,
  backgroundColor: props.index % 2 === 0 ? colors.lightestGray : undefined,
}));

const TitleItem = styled(ValueItem)({
  color: colors.tertiaryText,
});

const nameSelector = (t: IRTMSTreatment | ITDCSTreatment): React.JSX.Element => {
  const name = t.name
    ? t.name !== 'other'
      ? fm(`doctorsOrders.subjectOfTreatmentNames.${t.name}`)
      : t.name === 'other' && t.nameOther
        ? t.nameOther
        : '-'
    : '-';
  const specifier = t.specifier ? ` ${t.specifier}` : '';
  return (
    <span>
      {name}
      {specifier}
    </span>
  );
};

// Repeats stuff that's done in the form - combine?
const valueFormatter = (treatmentType: string, treatmentName: string, name: string, value?: any) => {
  switch (name) {
    case 'stimulationType': {
      return (
        <ValueDiv>{value ? fm(`doctorsOrders.rtms.subjectOfTreatment.stimulationType.opts.${value}`) : '-'}</ValueDiv>
      );
    }
    case 'pulseIntensity':
    case 'numberOfPulses': {
      return (
        <Unit
          unit={name === 'pulseIntensity' ? fm(`doctorsOrders.${treatmentType}.${treatmentName}.${name}.unit`) : ''}
        >
          <React.Fragment>
            {[null, null].concat(name === 'numberOfPulses' ? [null] : []).map((_, i, arr) => (
              <React.Fragment key={i}>
                <ValueDiv style={{ fontWeight: 600 }}>{Array.isArray(value) ? value[i] ?? '-' : '-'}</ValueDiv>
                {i < arr.length - 1 && (
                  <OperatorDiv>{fm(`doctorsOrders.${treatmentType}.${treatmentName}.${name}.operator`)}</OperatorDiv>
                )}
              </React.Fragment>
            ))}
            {name === 'numberOfPulses' && (
              <React.Fragment>
                <OperatorDiv>{'='}</OperatorDiv>
                <ValueDiv style={{ fontWeight: 400 }}>
                  {Array.isArray(value)
                    ? value.reduce((p: number, c: number) => (p ?? 0) + (!!c || c === 0 ? c : 0), 0)
                    : ''}
                </ValueDiv>
              </React.Fragment>
            )}
          </React.Fragment>
        </Unit>
      );
    }
    default: {
      return (
        <Unit
          unit={
            !['type', 'anode', 'anodeOther', 'cathode', 'cathodeOther'].includes(name)
              ? fm(`doctorsOrders.${treatmentType}.${treatmentName}.${name}.unit`)
              : ''
          }
        >
          <ValueDiv>
            {value && ['type', 'anode', 'cathode'].includes(name)
              ? fm(`doctorsOrders.${treatmentType}.${treatmentName}.${name}.opts.${value}`)
              : value ?? '-'}
          </ValueDiv>
        </Unit>
      );
    }
  }
};

const TreatmentsTable = ({ document, treatmentType, treatmentName }: ITreatmentsTable): React.JSX.Element => {
  switch (treatmentType) {
    case 'rtms': {
      if (!document.rtms || !Array.isArray(document.rtms[treatmentName]) || document.rtms[treatmentName]?.length === 0)
        return <></>;
      const treatments = document.rtms[treatmentName];

      let fields = [];
      if (treatments?.some((t) => t['stimulationType' as keyof typeof t] === 'iTBS')) fields = [...iTBSFields];
      else if (treatments?.some((t) => t['stimulationType' as keyof typeof t] === 'cTBS')) fields = [...cTBSFields];
      else fields = [...originalRTMSFields];

      if (
        treatments?.some((t) => t['stimulationType' as keyof typeof t] === 'originalRTMS') &&
        !fields.includes('pulseFrequency')
      ) {
        fields.splice(fields.indexOf('pulseFrequency3PulseBurst'), 0, 'pulseFrequency');
      }

      return (
        <div style={{ margin: '4rem 0 4.5rem 0' }}>
          <TitleContainer>
            <Item xs={true}>{fm(`doctorsOrders.rtms.${treatmentName}.titleShort`)}</Item>
          </TitleContainer>
          <NameContainer>
            <Item xs={3} style={{ minHeight: 30 }} />
            {treatments?.map((t, i) => (
              <Item key={i} xs={2}>
                {nameSelector(t)}
              </Item>
            ))}
          </NameContainer>
          {fields
            .filter((f) => !['session', 'electricFieldStrength', 'additionalInformation'].includes(f))
            ?.map((f, i) => (
              <Container key={i}>
                <TitleItem xs={3} index={i}>
                  <span style={{ paddingLeft: '1rem' }}>{fm(`doctorsOrders.rtms.${treatmentName}.${f}.title`)}</span>
                </TitleItem>
                {treatments?.map((t, j) => {
                  const { name, value, fieldMatchesType } = getRtmsRowData(f as keyof IRTMSTreatmentEvent, t);
                  return (
                    <ValueItem key={j} xs={2} index={i}>
                      {fieldMatchesType ? valueFormatter('rtms', treatmentName, name, value) : ''}
                    </ValueItem>
                  );
                })}
              </Container>
            ))}
        </div>
      );
    }
    case 'tdcs': {
      if (!document.tdcs || !Array.isArray(document.tdcs[treatmentName]) || document.tdcs[treatmentName]?.length === 0)
        return <></>;
      const treatments = document.tdcs[treatmentName];
      return (
        <div style={{ margin: '4rem 0 4.5rem 0' }}>
          <TitleContainer>
            <Item xs={true}>{fm(`doctorsOrders.tdcs.${treatmentName}.titleShort`)}</Item>
          </TitleContainer>
          <NameContainer>
            <Item xs={3} style={{ minHeight: 30 }} />
            {treatments?.map((t, i) => (
              <Item key={i} xs={2}>
                {nameSelector(t)}
              </Item>
            ))}
          </NameContainer>
          {['explanation', ...tdcsFields]
            .filter((f) => !['session', 'additionalInformation'].includes(f))
            .map((f, i) => (
              <Container key={i}>
                <TitleItem xs={3} index={i}>
                  <span style={{ paddingLeft: '1rem' }}>{fm(`doctorsOrders.tdcs.${treatmentName}.${f}.title`)}</span>
                </TitleItem>
                {treatments?.map((t, j) => {
                  const { name, value, fieldMatchesType } = getTdcsRowData(f as keyof ITDCSTreatmentEvent, t);
                  return (
                    <ValueItem key={j} xs={2} index={i}>
                      {treatmentName === 'subjectOfTreatment' && f === 'explanation' ? (
                        <ValueDiv style={{ paddingRight: '1rem' }}>
                          {t.name === 'other'
                            ? (t as ITDCSTreatmentEvent).explanationOther || '-'
                            : fm(`doctorsOrders.subjectOfTreatmentNameExplanations.${t.name}`)}
                        </ValueDiv>
                      ) : fieldMatchesType ? (
                        valueFormatter('tdcs', treatmentName, name, value)
                      ) : (
                        ''
                      )}
                    </ValueItem>
                  );
                })}
              </Container>
            ))}
        </div>
      );
    }
    default: {
      return <></>;
    }
  }
};

interface ITreatmentsTable {
  document: IDoctorsOrder;
  treatmentType: 'rtms' | 'tdcs';
  treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment';
}

export default TreatmentsTable;
