import * as React from 'react';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { formatPartialDate } from 'neuro-utils';
import HistoryHeader from 'Components/_NewElements/HistoryHeader';
import { fm } from 'Components/FormatMessage';

const FormattedClassification = ({ classification }: { classification?: string }): React.JSX.Element => {
  if (typeof classification !== 'string') return <span>{'-'}</span>;

  return (
    <span>
      {classification}
      {' - '}
      {(classification.includes('a') || classification.includes('b')) &&
        fm(`functionalPerformanceMgravis.opts.mgfa.${classification.substring(0, classification.length - 1)}`)}{' '}
      {fm(`functionalPerformanceMgravis.opts.mgfa.${classification}`)}
    </span>
  );
};

const MGFAHistory = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    <HistoryHeader
      headerText={fm('functionalPerformanceMgravis.mgfa')}
      buttons={[{ text: 'functionalPerformanceMgravis.newMGFA', onClick: startEdit({}, 'mgfa') }]}
    />
    <HistoryRowListing
      documents={documents}
      headers={
        <React.Fragment>
          <Item xs={2}>{fm('general.date')}</Item>
          <Item xs={7}>{fm('functionalPerformanceMgravis.classification')}</Item>
          <Item xs={3}>&nbsp;</Item>
        </React.Fragment>
      }
      contentFormat={(d: IMGFA): React.JSX.Element => (
        <React.Fragment>
          <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
          <Item xs={7}>
            <FormattedClassification classification={d.classification} />
          </Item>
          <Item xs={3}>
            <HistoryRowControls document={d} startEdit={startEdit} view={view} />
          </Item>
        </React.Fragment>
      )}
    />
  </React.Fragment>
);

interface IOwnProps {
  documents: Array<IMGFA>;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default MGFAHistory;
