/*
 * Leadclicker. Click on a lead and the 'zone' changes color.
 * This needs a complete overhaul as distinguising ids has become a substr-hell.
 * Idea: give every lead a UUID which is used to mark clickable zone ids
 * It's also possible that there a better way to change zone colors, than getElementById
 */

import * as React from 'react';

import LeadImage from '../../Assets/directed';
import { filterPlusMinusFields, filterUndefinedFields, mergeLeadSettings } from '../../../utils';
import { path } from 'Utility/ramdaReplacement';

class LeadClicker extends React.Component<IOwnProps> {
  // Cycle to the next value when clicked
  public nextValue = (
    cur?: 'plus' | 'minus' | 'registering' | undefined,
    side?: string,
    registration?: boolean,
  ): 'plus' | 'minus' | 'registering' | undefined => {
    if (registration) {
      return cur === 'registering' ? undefined : 'registering';
    } else {
      if (path([`${side}GeneratorSetting`], this.props.formData.document) === 'plus') {
        return cur === 'minus' ? undefined : 'minus';
      } else {
        return cur === 'plus' ? 'minus' : cur === 'minus' ? undefined : 'plus';
      }
    }
  };

  // Save new status on each click
  public svgClick =
    (editable?: 'true' | 'registration' | 'false') =>
    (e: React.MouseEvent<SVGSVGElement>): void => {
      const target = e.target as HTMLDivElement;
      const targetID = target.getAttribute('id');
      const side = /^right/.test(targetID ?? '') ? 'right' : 'left';

      // Distinguish svg clickable zones by form field name and document id and the string 'zone'
      if (targetID?.substr(0, this.props.name.length + 4) === this.props.name + 'zone') {
        // Old data from props
        const document =
          this.props.editable === 'registration' && this.props.referenceData
            ? mergeLeadSettings(this.props.formData.document, this.props.referenceData.document)
            : this.props.formData.document;
        let data = path([this.props.name], document) as {
          [key: string]: 'plus' | 'minus' | 'registering' | undefined;
        };

        const zoneNumber = targetID.substr(this.props.name.length + 4);
        // New data
        if (editable === 'true') {
          data = { ...data, [zoneNumber]: this.nextValue(data ? data[zoneNumber] : undefined, side) };
        }
        if (editable === 'registration') {
          if ([undefined, 'registering'].includes(data ? data[zoneNumber] : undefined)) {
            data = { ...data, [zoneNumber]: this.nextValue(data ? data[zoneNumber] : undefined, side, true) };
          }
        }
        const filteredData = filterUndefinedFields(editable === 'registration' ? filterPlusMinusFields(data) : data);
        this.props.formData.onChange && this.props.formData.onChange({ [this.props.name]: filteredData });
      }
    };

  public render(): React.JSX.Element {
    return (
      <LeadImage
        width={430}
        height={530}
        onClick={
          ['true', 'registration'].includes(this.props.editable)
            ? (e: React.MouseEvent<SVGSVGElement>): void => this.svgClick(this.props.editable)(e)
            : undefined
        }
        name={this.props.name}
        xOffSet={150}
        lead={
          this.props.editable === 'registration' && this.props.referenceData
            ? mergeLeadSettings(this.props.formData.document, this.props.referenceData.document)
            : this.props.formData.document
        }
      />
    );
  }
}

export interface ILeadClickerType extends IDBS {
  rightGeneratorSetting?: string;
  leftGeneratorSetting?: string;
  rightSettings?: { [key: string]: 'plus' | 'minus' | 'registering' | undefined };
  leftSettings?: { [key: string]: 'plus' | 'minus' | 'registering' | undefined };
}

interface IOwnProps {
  editable: 'true' | 'registration' | 'false';
  name: string;
  formData: IFormData<ILeadClickerType>;
  referenceData?: IOwnProps['formData'];
  edit?: string;
}

export default LeadClicker;
