import * as React from 'react';

import FormEditingHandler from '../../../containers/FormEditingHandler';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryRow from '../../../components/HistoryRow';
import CollapseElem from '../../../components/Collapse';
import HistoryRowControls from '../../../components/HistoryRowControls';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';

import HistoryRowData from './HistoryRowData';
import LifestyleFormDynamic from './FormDynamic';
import DocumentCreationButton from '../../../components/DocumentCreationButton';
import { useDispatch, useSelector } from 'react-redux';
import { TParkinsonSharedLifestyle } from 'neuro-schemas';
import { actions } from 'Store/myapp/actions';
import StandAloneFormEditingHandler from 'Containers/StandAloneFormEditingHandler';

const Lifestyle = ({ documents }: IOwnProps): React.JSX.Element => {
  const lifestyleMobiles =
    useSelector((s: IState) => s.myapp.sortedAndMergedDocuments)?.filter((d) => d._type === 'lifestyleMobile') ?? [];

  const sortedAllLifestyles = [...(documents ?? []), ...(lifestyleMobiles ?? [])].sort((n1, n2) =>
    sortPartialDate(n2.date, n1.date),
  );
  const first = sortedAllLifestyles?.[0] && sortedAllLifestyles[0];
  const restArr = sortedAllLifestyles && sortedAllLifestyles.slice(1);

  const isLifestyleMobile = (d: ILifestyle): boolean => {
    if (lifestyleMobiles.some((lifestyleMobile) => lifestyleMobile._id === d._id)) return true;
    return false;
  };

  const dispatch = useDispatch();

  // My document handling
  const [lifestyleMobileOpen, setLifestyleMobileOpen] = React.useState<
    { doc: TParkinsonSharedLifestyle & IControlProps; type: 'edit' | 'view' } | undefined
  >(undefined);
  const [tempLifestyleMobile, setTempLifestyleMobile] = React.useState<
    (TParkinsonSharedLifestyle & IControlProps) | undefined
  >(undefined);

  const [saving, setSaving] = React.useState<boolean>(false);

  const handleMyLifestyleDelete = async (myDocId: string) => {
    await actions.deleteDocument('lifestyleMobile', myDocId, dispatch);
  };

  const saveMyLifestyle = async () => {
    setSaving(true);
    if (lifestyleMobileOpen?.doc._type && lifestyleMobileOpen?.doc._id) {
      await actions.putNewCommit(
        lifestyleMobileOpen?.doc._type,
        lifestyleMobileOpen?.doc._id,
        tempLifestyleMobile,
        dispatch,
      );
    }
    setSaving(false);
    setLifestyleMobileOpen(undefined);
  };

  const handleLifestyleMobileFormChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (name) {
      setTempLifestyleMobile((prev) => ({
        ...(prev as TParkinsonSharedLifestyle & IControlProps),
        [name]: value,
      }));
    }
  };

  React.useEffect(() => {
    if (lifestyleMobileOpen && lifestyleMobileOpen.doc) {
      setTempLifestyleMobile(structuredClone(lifestyleMobileOpen.doc));
    } else if (!lifestyleMobileOpen) {
      setTempLifestyleMobile(undefined);
    }
  }, [lifestyleMobileOpen]);

  return (
    <React.Fragment>
      {lifestyleMobileOpen ? (
        <StandAloneFormEditingHandler
          currentPageName="lifestyle"
          headerLocaleId={'lifestyle.title'}
          loading={saving}
          cancelEditingAction={() => {
            setLifestyleMobileOpen(undefined);
            setTempLifestyleMobile(undefined);
          }}
          saveAction={() => {
            setSaving(true);
            saveMyLifestyle();
          }}
          viewing={lifestyleMobileOpen?.type === 'view'}
          endViewingAction={() => setLifestyleMobileOpen(undefined)}
        >
          {tempLifestyleMobile ? (
            <LifestyleFormDynamic
              formData={{ document: tempLifestyleMobile, onChange: handleLifestyleMobileFormChange }}
              documents={sortedAllLifestyles}
            />
          ) : (
            <></>
          )}
        </StandAloneFormEditingHandler>
      ) : (
        <FormEditingHandler name="lifestyle" documents={sortedAllLifestyles}>
          {(editing, startEdit, formData): React.JSX.Element => {
            const startEditFunc = (document: any) =>
              isLifestyleMobile(document)
                ? () =>
                    setLifestyleMobileOpen({
                      doc: document,
                      type: 'edit',
                    })
                : startEdit(document, 'lifestyle');

            return (
              <DocumentWrapper>
                <DocumentHeader
                  name={'lifestyle'}
                  headerId={'lifestyle.title'}
                  editing={editing}
                  editButtons={
                    <div>
                      <DocumentCreationButton name="lifestyle" text={'general.new'} onClick={startEdit({})} />
                    </div>
                  }
                />

                {editing ? <LifestyleFormDynamic formData={formData} documents={sortedAllLifestyles} /> : undefined}

                {!editing && sortedAllLifestyles && sortedAllLifestyles.length > 0 ? (
                  <>
                    <HistoryRow
                      headerText={first.date ? formatPartialDate(first.date) : undefined}
                      key={first._id}
                      rowButton={
                        <HistoryRowControls
                          document={first}
                          startEdit={startEditFunc}
                          enableButtons={isLifestyleMobile(first)}
                          deleteHandler={
                            isLifestyleMobile(first) ? () => handleMyLifestyleDelete(first._id) : undefined
                          }
                        />
                      }
                      controlsMargin={false}
                    >
                      <HistoryRowData d={first} docs={sortedAllLifestyles} startEdit={startEdit} />
                    </HistoryRow>
                    {sortedAllLifestyles.length > 1 && (
                      <CollapseElem
                        localeIDs={{
                          showMessage: 'lifestyle.showPastDocuments',
                          hideMessage: 'lifestyle.hidePastDocuments',
                        }}
                        amount={restArr.length}
                      >
                        {restArr.map((d: ILifestyle) => (
                          <HistoryRow
                            headerText={d.date ? formatPartialDate(d.date) : undefined}
                            key={d._id}
                            rowButton={
                              <HistoryRowControls
                                document={d}
                                startEdit={startEdit}
                                enableButtons={isLifestyleMobile(d)}
                                deleteHandler={isLifestyleMobile(d) ? () => handleMyLifestyleDelete(d._id) : undefined}
                              />
                            }
                            controlsMargin={false}
                          >
                            <HistoryRowData d={d} docs={sortedAllLifestyles} startEdit={startEdit} />
                          </HistoryRow>
                        ))}
                      </CollapseElem>
                    )}
                  </>
                ) : undefined}
              </DocumentWrapper>
            );
          }}
        </FormEditingHandler>
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: ILifestyle[];
}

export default Lifestyle;
