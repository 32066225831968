import * as React from 'react';
import colors from '../../../../../config/theme/colors';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import FormSection from '../../../../../components/FormSection';
import { styled } from '@mui/system';

/**
 * Styled components
 */
export const StyledUPDRSArea = styled('div', {
  shouldForwardProp: (prop) => prop !== 'filled',
})(({ filled }: { filled?: boolean }) => ({
  padding: '1rem 2rem',
  border: `0.2rem solid ${filled ? colors.primary : colors.gray}`,
  borderRadius: '0.6rem',
}));

export const StyledScore = styled('b')({
  fontSize: '1.8rem',
  fontWeight: 600,
  color: `${colors.primary}`,
  textTransform: 'uppercase',
  marginRight: '2rem',
  marginLeft: '2lem',
});

export const StyledCancel = styled('a')({
  fontSize: '1.6rem',
  fontWeight: 600,
  cursor: 'pointer',
  color: colors.primary,
  textTransform: 'uppercase',
  marginRight: '2rem',
  paddingRight: '2.5rem',
});

/**
 * UPDRSIII
 */
export const updrsIIITestReferenceInfo = (
  <div
    style={{
      fontSize: '1.6rem',
      fontStyle: 'italic',
      color: colors.darkGray,
      marginTop: '4rem',
      marginBottom: '2rem',
    }}
  >
    Fahn S, Elton R, Members of the UPDRS Development Committee. In: Fahn S, Marsden CD, Calne DB, Goldstein M, eds.
    Recent Developments in Parkinson’s Disease. Vol.2. Florham Park, NJ. Macmillan Health Care Information 1987,
    pp153163, 293¬304
  </div>
);

/**
 * MDS - UPDRSIII
 */
export const mdsUpdrsIIITestDescription = (
  <div
    style={{
      fontStyle: 'italic',
      color: colors.darkGray,
      margin: '2rem 0 3rem 0',
      fontSize: '1.6rem',
    }}
  >
    MDS-Unified Parkinson’s Disease Rating Scale (MDS-UPDRS). The MDS-UPDRS is protected by U.S. Copyright law.
    Permission to use and develop the MDS-UPDRS into an electronic format was granted by MDS, the copyright holder. All
    requests to use the scale within research must be approved by MDS. To request study specific licensing permissions
    for the MDS-UPDRS please contact ratingscales@movementdisorders.org. Licensing fees may apply.
  </div>
);

export const mdsUpdrsIIITestReferenceInfo = (
  <div
    style={{
      fontSize: '1.6rem',
      fontStyle: 'italic',
      color: colors.darkGray,
      marginTop: '5rem',
    }}
  >
    Copyright © 2008 International Parkinson and Movement Disorder Society (MDS). All Rights Reserved. Used with
    permission of MDS. This scale will not be copied, distributed or otherwise used in whole or in part without prior
    written consent of MDS.
  </div>
);

/**
 * Other components
 */
export const scoreText = (
  <StyledScore>
    <FormattedMessage id="updrs.score" />
  </StyledScore>
);

export const notFilledText = <FormattedMessage id="updrs.notFilled" />;

export const dyskinesiaQuestions = (editing: boolean, formData: IFormData<IUPDRSIII>): React.JSX.Element => (
  <div style={{ margin: '3rem 1rem 0 1rem' }}>
    <FormSection header="updrs.dyskinesiaImpact">
      <FormRow title="updrs.dyskinesiaPresent">
        <InputHandler
          type="Radio"
          editing={editing}
          name="dyskinesiaPresent"
          formData={formData}
          preset="yesnoForceEn"
        />
      </FormRow>
      <FormRow title="updrs.dyskinesiaInterference">
        <InputHandler
          type="Radio"
          editing={editing}
          name="dyskinesiaInterference"
          formData={formData}
          preset="yesnoForceEn"
        />
      </FormRow>
    </FormSection>
  </div>
);

/**
 * Interfaces
 */

export interface IEditingDocument {
  id: string;
  name: string;
}
