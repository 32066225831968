import * as React from 'react';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import PlatformConditional from '../../../components/PlatformConditional';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import { filterDocs, TDocument, getHeaderId } from '../utils';
import Form from './Form';

import MOCAHistory from './HistoryRowData/MOCA';
import MMSEHistory from './HistoryRowData/MMSE';
import TMTHistory from './HistoryRowData/TMT';
import SDMTHistory from './HistoryRowData/SDMT';
import CDRHistory from './HistoryRowData/CDR';

const CognitionHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
): React.JSX.Element => {
  const MOCADocs = filterDocs('moca', documents);
  const MMSEDocs = filterDocs('mmse', documents);
  const TMTDocs = filterDocs('tmt', documents);
  const SDMTDocs = filterDocs('sdmt', documents);
  const CDRDocs = filterDocs('cdr', documents);

  return (
    <React.Fragment>
      <PlatformConditional platform={'parkinson'}>
        <React.Fragment>
          <MOCAHistory documents={MOCADocs as IMOCA[]} startEdit={startEdit} view={view} />
          <MMSEHistory documents={MMSEDocs as IMMSE[]} startEdit={startEdit} view={view} />
          <TMTHistory documents={TMTDocs as ITMT[]} startEdit={startEdit} view={view} />
          <CDRHistory documents={CDRDocs as ICDR[]} startEdit={startEdit} view={view} />
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform={['parkinson', 'ms', 'huntington']}>
        <SDMTHistory documents={SDMTDocs as ISDMT[]} startEdit={startEdit} view={view} />
      </PlatformConditional>
    </React.Fragment>
  );
};

const Cognition = ({ documents }: IOwnProps): React.JSX.Element => {
  return (
    <FormEditingHandler name="cognition" documents={documents}>
      {(editing, startEdit, formData, view): React.JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'cognition'}
            headerId={getHeaderId(documents, editing, view?.viewing)}
            editing={editing}
            editButtons={<div />}
          />
          {editing ? (
            <Form formData={formData} document={documents && documents.find((d: TDocument) => d._id === editing)} />
          ) : null}
          {view?.viewing ? (
            <Form
              formData={{ document: view.document, onChange: (): string => '' }}
              document={documents && documents.find((d: TDocument) => d._id === view?.viewing)}
              viewing={!!view.viewing}
            />
          ) : null}
          {!editing && !view?.viewing ? CognitionHistory(documents, startEdit, view) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default Cognition;
