import * as React from 'react';
import { styled } from '@mui/system';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import Unit from 'Components/Unit';
import { DoctorsOrdersContext } from 'Routes/DoctorsOrders/Document';
import { exists } from 'neuro-utils';
import { getBasicDefaultValueRTMS, getOrgDefaultValueRTMS } from 'Routes/DoctorsOrders/Document/utils';
import { useAppSelector as useSelector } from 'Store/index';
import { calculateValue } from 'Routes/Rtms/Document/functions';
import { cTBSFields, iTBSFields, originalRTMSFields } from 'Utility/ninmtUtil';

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const InputContainer = styled('div')({
  fontWeight: 600,
  margin: '0 0 2.5rem 0',
});

const fieldsWithDefaultValues: Array<
  | 'pulseFrequency'
  | 'pulseFrequency3PulseBurst'
  | 'frequencyBetweenBursts'
  | 'amountOfBurstsInSet'
  | 'amountOfSetsInSequence'
  | 'timeBetweenBurstsets'
  | 'pulseIntensity'
  | 'numberOfPulses'
  | 'pauseLength'
> = [
  'pulseFrequency',
  'pulseFrequency3PulseBurst',
  'frequencyBetweenBursts',
  'amountOfBurstsInSet',
  'amountOfSetsInSequence',
  'timeBetweenBurstsets',
  'pulseIntensity',
  'numberOfPulses',
  'pauseLength',
];

const Treatment = ({
  treatmentName,
  subjectOfTreatmentName,
  document,
  onChange,
  onChangeArray,
}: ITreatment): React.JSX.Element => {
  const effectiveSettings = useSelector((s: IState) => s.settings.userSettings);

  const defaultValueUpdateRef = React.useRef<Pick<IRTMSTreatment, 'name' | 'stimulationType'>>({
    name: subjectOfTreatmentName,
    stimulationType: document.stimulationType,
  });

  React.useEffect(() => {
    if (document.stimulationType && subjectOfTreatmentName) {
      // Map fields with default values if not yet mapped or if stimulationType or name change
      if (
        defaultValueUpdateRef.current.stimulationType !== document.stimulationType ||
        defaultValueUpdateRef.current.name !== subjectOfTreatmentName
      ) {
        defaultValueUpdateRef.current = {
          name: subjectOfTreatmentName,
          stimulationType: document.stimulationType,
        };
        let defaultValues = {};
        fieldsWithDefaultValues.forEach(
          (
            field:
              | 'pulseFrequency'
              | 'pulseFrequency3PulseBurst'
              | 'frequencyBetweenBursts'
              | 'amountOfBurstsInSet'
              | 'amountOfSetsInSequence'
              | 'timeBetweenBurstsets'
              | 'pulseIntensity'
              | 'numberOfPulses'
              | 'pauseLength',
          ) => {
            const defaultVal =
              getOrgDefaultValueRTMS(document.stimulationType, subjectOfTreatmentName, field, effectiveSettings) ??
              getBasicDefaultValueRTMS(document.stimulationType, subjectOfTreatmentName, field);

            if (Array.isArray(defaultVal)) {
              defaultValues = { ...defaultValues, [field]: defaultVal.slice() };
            } else defaultValues = { ...defaultValues, [field]: defaultVal };
          },
        );
        onChange && onChange(defaultValues);
      }
    }
  }, [document.stimulationType, subjectOfTreatmentName]);

  const fieldsByStimulationType = {
    iTBS: iTBSFields,
    cTBS: cTBSFields,
    originalRTMS: originalRTMSFields,
  };

  return (
    <DoctorsOrdersContext.Consumer>
      {({ isEditing, view, fm }) => {
        const placeholder = isEditing ? '' : view?.viewing ? undefined : '-';
        return (
          <div style={{ padding: '2rem 0 0 0' }}>
            {fieldsByStimulationType[document.stimulationType ?? 'originalRTMS'].map((field) => {
              switch (field) {
                case 'stimulationType': {
                  return (
                    <FormRow
                      key={field}
                      title={`doctorsOrders.rtms.${treatmentName}.${field}.title`}
                      headerWidth={5}
                      bottomMargin={false}
                    >
                      <InputContainer>
                        <InputHandler
                          type="Radio"
                          name={field}
                          editing={isEditing}
                          options={['originalRTMS', 'iTBS', 'cTBS']}
                          optionFormatter={(o) => fm(`doctorsOrders.rtms.${treatmentName}.${field}.opts.${o}`)}
                          formData={{
                            document: {
                              [field]: exists(document[field as keyof IRTMSTreatment])
                                ? document[field as keyof IRTMSTreatment]
                                : placeholder,
                            },
                            onChange: onChange,
                          }}
                          dependentFieldsList={() => [
                            'pulseFrequency',
                            'pulseFrequency3PulseBurst',
                            'frequencyBetweenBursts',
                            'amountOfBurstsInSet',
                            'amountOfSetsInSequence',
                            'timeBetweenBurstsets',
                          ]}
                        />
                      </InputContainer>
                    </FormRow>
                  );
                }
                case 'pulseIntensity':
                case 'numberOfPulses': {
                  return (
                    document.stimulationType && (
                      <FormRow
                        key={field}
                        title={`doctorsOrders.rtms.${treatmentName}.${field}.title`}
                        headerWidth={5}
                        bottomMargin={false}
                      >
                        <InputContainer>
                          <Unit
                            unit={
                              field === 'pulseIntensity' ? fm(`doctorsOrders.rtms.${treatmentName}.${field}.unit`) : ''
                            }
                          >
                            <React.Fragment>
                              {[null, null]
                                .concat(field === 'numberOfPulses' ? [null] : [])
                                .map((_: null, index: number, arr: null[]) => (
                                  <React.Fragment key={`${field}${index}`}>
                                    <InlineDiv>
                                      <InputHandler
                                        type="NumberField"
                                        name={field}
                                        editing={isEditing}
                                        formData={{
                                          document: {
                                            [field]: exists(document[field]?.[index])
                                              ? document[field]?.[index]
                                              : placeholder,
                                          },
                                          onChange: onChangeArray && onChangeArray(index, field, arr),
                                        }}
                                        width={6}
                                      />
                                    </InlineDiv>
                                    {index < arr.length - 1 && (
                                      <InlineDiv style={{ margin: '0rem 1rem 0rem 1rem' }}>
                                        {fm(`doctorsOrders.rtms.${treatmentName}.${field}.operator`)}
                                      </InlineDiv>
                                    )}
                                  </React.Fragment>
                                ))}
                              {field === 'numberOfPulses' && (
                                <React.Fragment>
                                  <InlineDiv style={{ margin: '0rem 1rem 0rem 1rem' }}>{'='}</InlineDiv>
                                  <InlineDiv>{calculateValue(document, field)}</InlineDiv>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          </Unit>
                        </InputContainer>
                      </FormRow>
                    )
                  );
                }
                default: {
                  if (['session', 'electricFieldStrength', 'additionalInformation'].includes(field)) {
                    return undefined;
                  }
                  return (
                    <FormRow
                      key={field}
                      title={`doctorsOrders.rtms.${treatmentName}.${field}.title`}
                      headerWidth={5}
                      bottomMargin={false}
                    >
                      <InputContainer>
                        <Unit unit={fm(`doctorsOrders.rtms.${treatmentName}.${field}.unit`)}>
                          <InputHandler
                            type="NumberField"
                            name={field}
                            editing={isEditing}
                            formData={{
                              document: {
                                [field]: exists(document[field as keyof IRTMSTreatment])
                                  ? document[field as keyof IRTMSTreatment]
                                  : placeholder,
                              },
                              onChange: onChange,
                            }}
                            width={6}
                            precision={field === 'peelingDepth' ? 1 : undefined}
                          />
                        </Unit>
                      </InputContainer>
                    </FormRow>
                  );
                }
              }
            })}
          </div>
        );
      }}
    </DoctorsOrdersContext.Consumer>
  );
};

interface ITreatment {
  treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment';
  subjectOfTreatmentName: string;
  document: IRTMSTreatment;
  onChange: (value: TOnChangeValues) => void;
  onChangeArray?: (
    i: number,
    k: 'pulseIntensity' | 'numberOfPulses',
    initial: Array<null | number>,
  ) => (value: TOnChangeValues) => void;
}

export default Treatment;
