import FormRow from 'Components/FormRow';
import FormSectionBar from 'Components/FormSectionBar';
import InputHandler from 'Components/InputHandler';
import * as React from 'react';
import { DoctorsOrdersContext } from '../..';

const OtherForm = ({ subjectOfOrder }: { subjectOfOrder: Array<string> }): React.JSX.Element => (
  <DoctorsOrdersContext.Consumer>
    {({ isEditing, formData, fm }) => {
      const { document } = formData;

      return (
        subjectOfOrder.filter((s) => ['rmt', 'nextInterview', 'ninmtMeeting', 'requestFollowUp'].includes(s)).length >
          0 && (
          <React.Fragment>
            <FormSectionBar header="doctorsOrders.other.header" />
            <div style={{ marginBottom: '5rem' }} />
            {subjectOfOrder.includes('rmt') && (
              <FormRow title="doctorsOrders.other.nextRMT.title">
                <InputHandler
                  type="PartialDate"
                  name="nextRMT"
                  editing={isEditing}
                  formData={formData}
                  hideDatePicker
                  hideDaySelection
                />
              </FormRow>
            )}
            {subjectOfOrder.includes('nextInterview') && (
              <FormRow title="doctorsOrders.other.nextInterview.title">
                <InputHandler
                  type="TextArea"
                  name="nextInterview"
                  editing={isEditing}
                  formData={formData}
                  placeholder="doctorsOrders.other.nextInterview.placeholder"
                />
              </FormRow>
            )}
            {subjectOfOrder.includes('ninmtMeeting') && (
              <React.Fragment>
                <FormRow title="doctorsOrders.other.ninmtMeeting.title">
                  <InputHandler
                    type="Radio"
                    name="ninmtMeeting"
                    editing={isEditing}
                    formData={formData}
                    options={['yes', 'no']}
                    optionFormatter={(o: string | number): string => fm(`doctorsOrders.other.ninmtMeeting.opts.${o}`)}
                  />
                </FormRow>
                {document.ninmtMeeting && document.ninmtMeeting === 'yes' && (
                  <FormRow title="doctorsOrders.other.addedToAgenda.title">
                    <InputHandler
                      type="Radio"
                      name="addedToAgenda"
                      editing={isEditing}
                      formData={formData}
                      options={['yes', 'no']}
                      optionFormatter={(o: string | number): string =>
                        fm(`doctorsOrders.other.addedToAgenda.opts.${o}`)
                      }
                    />
                  </FormRow>
                )}
              </React.Fragment>
            )}
            {subjectOfOrder.includes('requestFollowUp') && (
              <FormRow title="doctorsOrders.other.requestFollowUp.title">
                <InputHandler
                  type="Radio"
                  name="requestFollowUp"
                  editing={isEditing}
                  formData={formData}
                  options={['yes', 'no']}
                  optionFormatter={(o: string | number): string => fm(`doctorsOrders.other.requestFollowUp.opts.${o}`)}
                />
              </FormRow>
            )}
          </React.Fragment>
        )
      );
    }}
  </DoctorsOrdersContext.Consumer>
);

export default OtherForm;
