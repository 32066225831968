import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import PlatformConditional from '../../../../components/PlatformConditional';
import { nowPartialDate } from 'neuro-utils';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): React.JSX.Element => {
    return <FormattedMessage id={`therapy.${opt}.${name}`} />;
  };

const isOther = (therapyTypes: string | undefined): boolean | undefined =>
  therapyTypes ? therapyTypes === 'others' : undefined;

/** Therapy type options for platform SMA, DMD. */
const therapyTypesSmdDmd: TTherapyTypesSMADMD[] = [
  'physiotherapySessions',
  'respiratoryPhysiotherapySessions',
  'ridingTherapy',
  'homeProgramme',
  'hydroTherapyWaterBasedTherapy',
  'ManagementOfContracturesUsingOrthotics',
  'brace',
  'OccupationalTherapySessions',
  'SpeechLanguageTherapySessions',
  'others',
];

/** Therapy type options of class `outpatient` for platform MS. */
const therapyTypesMsOutpatient: TTherapyTypesMSOutpatient[] = [
  'physiotherapyIndividual',
  'physiotherapyGroup',
  'lymphaticTherapy',
  'pelvicFloorPhysiotherapy',
  'poolTherapyIndividual',
  'poolTherapyGroup',
  'occupationalTherapyIndividual',
  'occupationalTherapyGroup',
  'neuropsychologicalRehabilitationIndividual',
  'neuropsychologicalRehabilitationGroup',
  'speechTherapyIndividual',
  'speechTherapyGroup',
  'multiProfessionalTherapy',
  'diseaseInformationGroup',
  'adaptationTraining',
  'ridingTherapy',
  'evaluationOfRehabilitationNeeds',
  'otherOutpatientTherapy',
];

/** Therapy type options of class `institutional` for platform MS. */
const therapyTypesMsInstitutional: TTherapyTypesMSInstitutional[] = [
  'adaptationTraining',
  'inpatientRehabilitation',
  'daytimeRehabilitation',
  'otherInpatientRehabilitation',
];

/** Therapy type options of class `occupational` for platform MS. */
const therapyTypesMsOccupational: TTherapyTypesMSOccupational[] = [
  'evaluationOfRehabilitationNeeds',
  'rehabilitationResearch',
  'vocationalInpatientRehabilitationPeriod',
  'otherVocationalRehabilitation',
];

const TherapyForm = ({ editing, formData }: IOwnProps): React.JSX.Element => {
  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="therapy.therapyStartDate">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="date"
            formData={formData}
            dateDefault={'now'}
            isNotCancellable={true}
            dateHook={{ dateHookCeiling: formData.document?.endDate }}
          />
        </FormRow>
        <PlatformConditional platform={['sma', 'dmd']}>
          <React.Fragment>
            <FormRow title="therapy.therapyTypes">
              <InputHandler
                type="Select"
                editing={!!editing}
                name="therapyTypes"
                formData={formData}
                options={therapyTypesSmdDmd}
                optionFormatter={optionFormatter('opts')}
                placeholder={'therapy.typePlaceholder'}
              />
            </FormRow>
            {isOther(formData?.document?.therapyTypes) ? (
              <FormRow title="therapy.otherTherapyType">
                <InputHandler
                  type="TextField"
                  editing={!!editing}
                  name="otherTherapyType"
                  formData={formData}
                  placeholder={'therapy.giveOtherTherapyType'}
                />
              </FormRow>
            ) : undefined}
          </React.Fragment>
        </PlatformConditional>
        {editing && formData.document?.date ? (
          <FormRow title="therapy.therapyStopDate">
            <InputHandler
              type="PartialDate"
              editing={!!editing}
              name="endDate"
              formData={formData}
              dateHook={{
                dateHookFloor: formData.document?.date,
                dateHookCeiling:
                  nowPartialDate() && nowPartialDate()?.[0]
                    ? [(nowPartialDate()?.[0] as number) + 10, 12, 31]
                    : undefined,
              }}
            />
          </FormRow>
        ) : undefined}
        <PlatformConditional platform={'ms'}>
          <React.Fragment>
            <FormRow title="therapy.therapyClassification.title">
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="therapyClassification"
                formData={formData}
                options={['outpatient', 'institutional', 'occupational']}
                optionFormatter={optionFormatter('therapyClassification.opts')}
              />
            </FormRow>
            {formData.document?.therapyClassification === 'outpatient' ? (
              <FormRow title="therapy.therapyTypes">
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="therapyTypes"
                  formData={formData}
                  options={therapyTypesMsOutpatient}
                  optionFormatter={optionFormatter('opts')}
                />
              </FormRow>
            ) : undefined}
            {formData.document?.therapyClassification === 'institutional' ? (
              <FormRow title="therapy.therapyTypes">
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="therapyTypes"
                  formData={formData}
                  options={therapyTypesMsInstitutional}
                  optionFormatter={optionFormatter('opts')}
                />
              </FormRow>
            ) : undefined}
            {formData.document?.therapyClassification === 'occupational' ? (
              <FormRow title="therapy.therapyTypes">
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="therapyTypes"
                  formData={formData}
                  options={therapyTypesMsOccupational}
                  optionFormatter={optionFormatter('opts')}
                />
              </FormRow>
            ) : undefined}
          </React.Fragment>
        </PlatformConditional>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<ITherapy>;
  document?: ITherapy;
}

export default TherapyForm;
