import * as React from 'react';
import { styled } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { Container, Item } from 'Components/Grid';

const StyledLabel = styled('div')({
  fontWeight: 600,
});

const InstructionElement = ({
  locale,
  values,
}: {
  locale: string;
  values?: {
    [key: string]:
      | string
      | number
      | null
      | React.JSX.Element
      | ((parts: React.ReactNode[]) => string | React.JSX.Element | React.JSX.Element[]);
  };
}): React.JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '0', fontSize: '1.6rem' }}>
      <Item xs={2}>
        <StyledLabel>
          <FormattedMessage id="updrs.instruction" />
        </StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic' }}>
        <FormattedMessage id={'updrs.labels.iii.' + locale} values={values} />
      </Item>
    </Container>
  </React.Fragment>
);

export const steps = [
  'speech',
  'facialExpression',
  { rigidity: ['rigidityNeck', 'rigidityRUE', 'rigidityLUE', 'rigidityRLE', 'rigidityLLE'] },
  { fingerTapping: ['fingerTappingRight', 'fingerTappingLeft'] },
  { handMovements: ['handMovementsRight', 'handMovementsLeft'] },
  { pronationSupination: ['pronationSupinationRight', 'pronationSupinationLeft'] },
  { toeTapping: ['toeTappingRight', 'toeTappingLeft'] },
  { legAgility: ['legAgilityRight', 'legAgilityLeft'] },
  'arisingFromChair',
  'gait',
  'freezingOfGait',
  'posturalStability',
  'posture',
  'globalSpontaneityOfMovement',
  { posturalTremorOfHands: ['posturalTremorOfHandsRight', 'posturalTremorOfHandsLeft'] },
  { kineticTremorOfHands: ['kineticTremorOfHandsRight', 'kineticTremorOfHandsLeft'] },
  {
    restTremorAmplitude: [
      'restTremorAmplitudeRUE',
      'restTremorAmplitudeLUE',
      'restTremorAmplitudeRLE',
      'restTremorAmplitudeLLE',
      'restTremorAmplitudeLipJaw',
    ],
  },
  'constancyOfRestTremor',
] as Array<string | { [key: string]: string[] }>;

export const getStepContent = (
  stepName: string,
): { opts: Array<number | string>; optsLocale: string; instruction?: React.JSX.Element; height?: number } => {
  switch (stepName) {
    case 'speech':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'speech',
        instruction: <InstructionElement locale="speechInstruction" />,
      };
    case 'facialExpression':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'facialExpression',
        instruction: <InstructionElement locale="facialExpressionInstruction" />,
      };
    case 'rigidityNeck':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'rigidity',
        instruction: <InstructionElement locale="rigidityInstruction" />,
      };
    case 'rigidityRUE':
    case 'rigidityLUE':
    case 'rigidityRLE':
    case 'rigidityLLE':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'rigidity' };
    case 'fingerTappingRight':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'fingerTapping',
        instruction: <InstructionElement locale="fingerTappingInstruction" />,
        height: 17,
      };
    case 'fingerTappingLeft':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'fingerTapping', height: 17 };
    case 'handMovementsRight':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'handMovements',
        instruction: <InstructionElement locale="handMovementsInstruction" />,
        height: 17,
      };
    case 'handMovementsLeft':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'handMovements', height: 17 };
    case 'pronationSupinationRight':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'pronationSupination',
        instruction: <InstructionElement locale="pronationSupinationInstruction" />,
        height: 17,
      };
    case 'pronationSupinationLeft':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'pronationSupination', height: 17 };
    case 'toeTappingRight':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'toeTapping',
        instruction: <InstructionElement locale="toeTappingInstruction" />,
        height: 17,
      };
    case 'toeTappingLeft':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'toeTapping', height: 17 };
    case 'legAgilityRight':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'legAgility',
        instruction: <InstructionElement locale="legAgilityInstruction" />,
        height: 17,
      };
    case 'legAgilityLeft':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'legAgility', height: 17 };
    case 'arisingFromChair':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'arisingFromChair',
        instruction: <InstructionElement locale="arisingFromChairInstruction" />,
      };
    case 'gait':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'gait',
        instruction: <InstructionElement locale="gaitInstruction" />,
      };
    case 'freezingOfGait':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'freezingOfGait',
        instruction: <InstructionElement locale="freezingOfGaitInstruction" />,
      };
    case 'posturalStability':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'posturalStability',
        instruction: (
          <InstructionElement
            locale="posturalStabilityInstruction"
            values={{
              u: (chunks) =>
                chunks?.map((c, i) => (
                  <span key={`${c}-${i}`} style={{ textDecoration: 'underline' }}>
                    {c}
                  </span>
                )),
            }}
          />
        ),
      };
    case 'posture':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'posture',
        instruction: <InstructionElement locale="postureInstruction" />,
      };
    case 'globalSpontaneityOfMovement':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'globalSpontaneityOfMovement',
        instruction: <InstructionElement locale="globalSpontaneityOfMovementInstruction" />,
      };
    case 'posturalTremorOfHandsRight':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'posturalTremorOfHands',
        instruction: <InstructionElement locale="posturalTremorOfHandsInstruction" />,
      };
    case 'posturalTremorOfHandsLeft':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'posturalTremorOfHands' };
    case 'kineticTremorOfHandsRight':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'kineticTremorOfHands',
        instruction: <InstructionElement locale="kineticTremorOfHandsInstruction" />,
      };
    case 'kineticTremorOfHandsLeft':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'kineticTremorOfHands' };
    case 'restTremorAmplitudeRUE':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'restTremorAmplitude',
        instruction: <InstructionElement locale="restTremorAmplitudeInstruction" values={{ ln: <br /> }} />,
      };
    case 'restTremorAmplitudeLUE':
    case 'restTremorAmplitudeRLE':
    case 'restTremorAmplitudeLLE':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'restTremorAmplitude' };
    case 'restTremorAmplitudeLipJaw':
      return { opts: ['UR', 0, 1, 2, 3, 4], optsLocale: 'restTremorAmplitudeLipJaw' };
    case 'constancyOfRestTremor':
      return {
        opts: ['UR', 0, 1, 2, 3, 4],
        optsLocale: 'constancyOfRestTremor',
        instruction: <InstructionElement locale="constancyOfRestTremorInstruction" />,
      };

    default:
      return { opts: [0], optsLocale: '' };
  }
};
