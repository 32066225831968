/**
 * Container to render form editing view without using the form editing handler container
 * Used to implement custom forms that might have their own saving functions and other hooks etc
 * Creates the toolbar, documentheader and wraps the page correctly
 */

import DocumentHeader from 'Components/DocumentHeader';
import DocumentWrapper from 'Components/DocumentWrapper';
import Toolbar from 'Components/Toolbar';
import NavigationBlocker from 'Containers/FormEditingHandler/NavigationBlocker';
import * as React from 'react';

const StandAloneFormEditingHandler = ({
  currentPageName,
  headerLocaleId,
  viewing,
  loading,
  navigationBlocked,

  cancelEditingAction,
  endViewingAction,
  saveAction,

  children,
}: IStandAloneFormEditingHandler) => {
  return (
    <NavigationBlocker blocked={!!navigationBlocked}>
      <>
        <Toolbar
          current={currentPageName}
          editing={viewing ? undefined : 'editing'}
          view={viewing ? { viewing: 'true', endView: () => () => endViewingAction?.() } : undefined}
          cancelDraft={() => () => cancelEditingAction()}
          saveDraft={() => () => saveAction()}
          spinnerEnabled={loading}
        />
        <DocumentWrapper>
          <DocumentHeader headerId={headerLocaleId} />
          {children}
        </DocumentWrapper>
      </>
    </NavigationBlocker>
  );
};

interface IStandAloneFormEditingHandler {
  currentPageName: string;
  headerLocaleId: string;
  viewing?: boolean;
  loading?: boolean;
  navigationBlocked?: boolean;

  cancelEditingAction: () => void;
  endViewingAction?: () => void;
  saveAction: () => void;

  children: React.JSX.Element | React.JSX.Element[];
}

export default StandAloneFormEditingHandler;
