import { fm } from 'Components/FormatMessage';
import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { styled } from '@mui/system';

import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
import colors from '../../../../config/theme/colors';

const StyledHistoryValue = styled('span')({
  fontWeight: 600,
});

const StyledValue = styled('div')({});

const StyledHeader = styled('div')({
  color: colors.primary,
  marginBottom: '0.5rem',
});

const StyledTitle = styled('div')({
  color: colors.primary,
  fontWeight: 600,
  fontSize: '2rem',
  marginBottom: '0.5rem',
});

const StyledListItem = styled('div')({});

const boxStyle = { margin: '0 -2rem', padding: '1rem 2rem' };

const HeaderValueComponent = ({ header, value }: IInputProps): React.JSX.Element => (
  <React.Fragment>
    <StyledHeader>{header ? header : ''}</StyledHeader>
    <StyledValue>{value ? value : ''}</StyledValue>
  </React.Fragment>
);

interface IInputProps {
  header?: string | React.JSX.Element;
  value?: string | React.JSX.Element;
}

const GeneTestHistoryRowData = ({ d }: IOwnProps): React.JSX.Element => (
  <>
    <Container>
      <Item xs={9}>
        <Container>
          <Item xs={6}>
            <HistoryRowBasicItem
              header={fm('geneTest.geneTestType')}
              value={d.geneTestType && <StyledHistoryValue>{fm(`geneTest.opts.${d.geneTestType}`)}</StyledHistoryValue>}
            />
          </Item>
          <Item xs={3}>
            {d.geneTestType === 'singleGeneTest' ? (
              <HistoryRowBasicItem
                header={fm('geneTest.gene')}
                value={<StyledHistoryValue>{d.gene ? fm(`geneTest.opts.${d.gene}`) : '-'}</StyledHistoryValue>}
              />
            ) : d.geneTestType === 'genePanelResearch' ? (
              <HistoryRowBasicItem
                header={fm('geneTest.genePanel')}
                value={<StyledHistoryValue>{d.genePanel ? d.genePanel : '-'}</StyledHistoryValue>}
              />
            ) : d.geneTestType === 'wes' ? (
              <HistoryRowBasicItem
                header={fm('geneTest.whereWasResearchMade')}
                value={<StyledHistoryValue>{d.whereWasResearchMade ? d.whereWasResearchMade : '-'}</StyledHistoryValue>}
              />
            ) : undefined}
          </Item>
          <Item xs={3} />
        </Container>
      </Item>
      <Item xs={3}></Item>
    </Container>
    {d.geneTestType === 'singleGeneTest' ? (
      <React.Fragment>
        <Container style={{ marginTop: '4rem' }}>
          <Item xs={9}>
            <Container alignItems="baseline">
              <Item xs={6}>
                <HeaderValueComponent
                  header={fm('geneTest.mutationFound')}
                  value={
                    d.mutationFound && d.mutationFound === true ? (
                      <StyledHistoryValue>{fm('general.yes')}</StyledHistoryValue>
                    ) : d.mutationFound || d.mutationFound === false ? (
                      fm('general.no')
                    ) : (
                      ''
                    )
                  }
                />
              </Item>
              <Item xs={6}>
                {d.mutationFound && d.mutationFound === true && d.mutations && d.mutations.length > 0 && (
                  <Container>
                    <Item xs={12}>
                      <StyledTitle>{fm('geneTest.wesGeneMutationPairs')}</StyledTitle>
                    </Item>
                    <Container style={{ marginBottom: '1rem', ...boxStyle }}>
                      <Item xs={5} style={{ color: colors.secondaryText }}>
                        {fm('geneTest.gene')}
                      </Item>
                      <Item xs={2} />
                      <Item xs={5} style={{ color: colors.secondaryText }}>
                        {fm('geneTest.mutation')}
                      </Item>
                    </Container>
                    {d.mutations.map((item: any, index: number) => (
                      <Container
                        key={'vus' + index}
                        alignItems="center"
                        style={{
                          backgroundColor: !(index % 2 === 1) ? colors.lightestGray : undefined,
                          ...boxStyle,
                        }}
                      >
                        <Item xs={5}>
                          <StyledHistoryValue>
                            {d.gene && d.gene === 'other' ? (
                              <span>
                                {fm(`geneTest.opts.${d.gene}`)}
                                {d.whichGene ? ': ' + d.whichGene : ''}
                              </span>
                            ) : d.gene ? (
                              fm(`geneTest.opts.${d.gene}`)
                            ) : (
                              ''
                            )}
                          </StyledHistoryValue>
                        </Item>
                        <Item xs={2} />
                        <Item xs={5}>
                          <StyledHistoryValue>{item ? item : ''}</StyledHistoryValue>
                        </Item>
                      </Container>
                    ))}
                  </Container>
                )}
              </Item>
              <Item xs={3} />
            </Container>
          </Item>
        </Container>
      </React.Fragment>
    ) : d.geneTestType === 'genePanelResearch' ? (
      <React.Fragment>
        <Container style={{ marginTop: '4rem' }}>
          <Item xs={9}>
            <HeaderValueComponent
              header={fm('geneTest.genesIncludedInPanel')}
              value={
                <StyledHistoryValue>
                  {d.genesIncluded &&
                    d.genesIncluded.map((item: any, index: number) => (
                      <StyledListItem key={'genesIncluded' + index}>{fm(`geneTest.opts.${item}`)}</StyledListItem>
                    ))}
                </StyledHistoryValue>
              }
            />
          </Item>
        </Container>
        <Container style={{ marginTop: '4rem' }}>
          <Item xs={9}>
            <Container alignItems="baseline">
              <Item xs={6}>
                <HeaderValueComponent
                  header={fm('geneTest.mutationFound')}
                  value={
                    <StyledHistoryValue>
                      {d.mutationFound && d.mutationFound === true
                        ? fm('general.yes')
                        : d.mutationFound || d.mutationFound === false
                          ? fm('general.no')
                          : ''}
                    </StyledHistoryValue>
                  }
                />
              </Item>
              <Item xs={6}>
                {d.mutationFound &&
                  d.mutationFound === true &&
                  d.panelGeneMutationPairs &&
                  d.panelGeneMutationPairs.length > 0 && (
                    <Container>
                      <Item xs={12}>
                        <StyledTitle>{fm('geneTest.wesGeneMutationPairs')}</StyledTitle>
                      </Item>
                      <Container style={{ marginBottom: '1rem', ...boxStyle }}>
                        <Item xs={5} style={{ color: colors.secondaryText }}>
                          {fm('geneTest.gene')}
                        </Item>
                        <Item xs={2} />
                        <Item xs={5} style={{ color: colors.secondaryText }}>
                          {fm('geneTest.mutation')}
                        </Item>
                      </Container>
                      {d.panelGeneMutationPairs.map((item: any, index: number) => (
                        <Container
                          key={'vus' + index}
                          alignItems="center"
                          style={{
                            backgroundColor: !(index % 2 === 1) ? colors.lightestGray : undefined,
                            ...boxStyle,
                          }}
                        >
                          <Item xs={5}>
                            <StyledHistoryValue>
                              {item.gene && item.gene === 'other' ? (
                                <span>
                                  {fm(`geneTest.opts.${item.gene}`)}
                                  {item.otherGene ? ': ' + item.otherGene : ''}
                                </span>
                              ) : item.gene ? (
                                fm(`geneTest.opts.${item.gene}`)
                              ) : (
                                ''
                              )}
                            </StyledHistoryValue>
                          </Item>
                          <Item xs={2} />
                          <Item xs={5}>
                            <StyledHistoryValue>{item.mutation ? item.mutation : ''}</StyledHistoryValue>
                          </Item>
                        </Container>
                      ))}
                    </Container>
                  )}
              </Item>
              <Item xs={3} />
            </Container>
          </Item>
        </Container>
      </React.Fragment>
    ) : d.geneTestType === 'wes' ? (
      <React.Fragment>
        <Container style={{ marginTop: '4rem' }}>
          <Item xs={9}>
            <Container alignItems="baseline">
              <Item xs={6}>
                <HeaderValueComponent
                  header={fm('geneTest.mutationFound')}
                  value={
                    <StyledHistoryValue>
                      {d.mutationFound && d.mutationFound === true
                        ? fm('general.yes')
                        : d.mutationFound || d.mutationFound === false
                          ? fm('general.no')
                          : ''}
                    </StyledHistoryValue>
                  }
                />
              </Item>
              <Item xs={6}>
                {d.mutationFound &&
                  d.mutationFound === true &&
                  d.wesGeneMutationPairs &&
                  d.wesGeneMutationPairs.length > 0 && (
                    <Container>
                      <Item xs={12}>
                        <StyledTitle>{fm('geneTest.wesGeneMutationPairs')}</StyledTitle>
                      </Item>
                      <Container style={{ marginBottom: '1rem', ...boxStyle }}>
                        <Item xs={5} style={{ color: colors.secondaryText }}>
                          {fm('geneTest.gene')}
                        </Item>
                        <Item xs={2} />
                        <Item xs={5} style={{ color: colors.secondaryText }}>
                          {fm('geneTest.mutation')}
                        </Item>
                      </Container>
                      {d.wesGeneMutationPairs.map((item: any, index: number) => (
                        <Container
                          key={'vus' + index}
                          alignItems="center"
                          style={{
                            backgroundColor: !(index % 2 === 1) ? colors.lightestGray : undefined,
                            ...boxStyle,
                          }}
                        >
                          <Item xs={5}>
                            <StyledHistoryValue>
                              {item.gene && item.gene === 'other' ? (
                                <span>
                                  {fm(`geneTest.opts.${item.gene}`)}
                                  {item.otherGene ? ': ' + item.otherGene : ''}
                                </span>
                              ) : item.gene ? (
                                fm(`geneTest.opts.${item.gene}`)
                              ) : (
                                ''
                              )}
                            </StyledHistoryValue>
                          </Item>
                          <Item xs={2} />
                          <Item xs={5}>
                            <StyledHistoryValue>{item.mutation ? item.mutation : ''}</StyledHistoryValue>
                          </Item>
                        </Container>
                      ))}
                    </Container>
                  )}
              </Item>
              <Item xs={3} />
            </Container>
          </Item>
        </Container>
        <Container style={{ marginTop: '4rem' }}>
          <Item xs={9}>
            <Container alignItems="baseline">
              <Item xs={6}>
                <HeaderValueComponent
                  header={fm('geneTest.vusFindingFound')}
                  value={
                    <StyledHistoryValue>
                      {d.vusFindingFound && d.vusFindingFound === true
                        ? fm('general.yes')
                        : d.vusFindingFound || d.vusFindingFound === false
                          ? fm('general.no')
                          : ''}
                    </StyledHistoryValue>
                  }
                />
              </Item>
              <Item xs={6}>
                {d.vusFindingFound &&
                  d.vusFindingFound === true &&
                  d.wesGeneVusFindingPairs &&
                  d.wesGeneVusFindingPairs.length > 0 && (
                    <Container>
                      <Item xs={12}>
                        <StyledTitle>{fm('geneTest.wesGeneVusFindingPairs')}</StyledTitle>
                      </Item>
                      <Container style={{ marginBottom: '1rem', ...boxStyle }}>
                        <Item xs={5} style={{ color: colors.secondaryText }}>
                          {fm('geneTest.gene')}
                        </Item>
                        <Item xs={2} />
                        <Item xs={5} style={{ color: colors.secondaryText }}>
                          {fm('geneTest.vusFinding')}
                        </Item>
                      </Container>
                      {d.wesGeneVusFindingPairs &&
                        d.wesGeneVusFindingPairs.map((item: any, index: number) => (
                          <Container
                            key={'vus' + index}
                            alignItems="center"
                            style={{
                              backgroundColor: !(index % 2 === 1) ? colors.lightestGray : undefined,
                              ...boxStyle,
                            }}
                          >
                            <Item xs={5}>
                              <StyledHistoryValue>{item.gene ? item.gene : ''}</StyledHistoryValue>
                            </Item>
                            <Item xs={2} />
                            <Item xs={5}>
                              <StyledHistoryValue>{item.vusFinding ? item.vusFinding : ''}</StyledHistoryValue>
                            </Item>
                          </Container>
                        ))}
                    </Container>
                  )}
              </Item>
              <Item xs={3} />
            </Container>
          </Item>
        </Container>
      </React.Fragment>
    ) : undefined}
  </>
);

interface IOwnProps {
  d: IGeneTest;
}

export default GeneTestHistoryRowData;
