import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { fm } from 'Components/FormatMessage';
import colors from '../../../../../config/theme/colors';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): React.JSX.Element => {
    return fm(`imaging.${opt}.${name}`);
  };

const ThymusMRIForm = ({ formData }: IOwnProps): React.JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={true}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="imaging.finding">
        <InputHandler
          type="Radio"
          editing={true}
          name="finding"
          formData={formData}
          options={['normal']}
          optionFormatter={optionFormatter('opts.finding')}
        />
        <div style={{ color: colors.tertiaryText, marginBottom: '1.25rem', textTransform: 'uppercase' }}>
          {fm('general.or')}
        </div>
        <InputHandler
          type="Checkbox"
          editing={true}
          name="finding"
          formData={formData}
          options={['hyperplasia', 'thymoma']}
          optionFormatter={optionFormatter('opts.finding')}
        />
      </FormRow>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IThymusMRI>;
}

export default ThymusMRIForm;
