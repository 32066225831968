import { equals, insert, remove } from 'ramda';
import * as React from 'react';
import { fm } from 'Components/FormatMessage';
import FormRow from '../../../../../../components/FormRow';
import FormSection from '../../../../../../components/FormSection';
import { Container, Item } from '../../../../../../components/Grid';
import InputHandler from '../../../../../../components/InputHandler';
import colors from '../../../../../../config/theme/colors';
import ClearIcon from '@mui/icons-material/Clear';
import { capitalize } from '../../../../../../utility/string';
import ActionButton from '../../../../../../components/ActionButton';
import { columnHasNumberField, findNextGenerator } from '../../../../utils';
import {
  defaultUnits,
  generatorsWithAutoStimNightAndScheduledSettings,
  generatorsWithAutoStimSettings,
} from '../../../config';
import { exists, formatPartialDate, partialDateToValue } from 'neuro-utils';
import { vnsSettingColumns, vnsSettingRows } from './definitions';
import { styled } from '@mui/system';
import { isEmpty, path } from 'Utility/ramdaReplacement';

const StyledBoldText = styled('div')({
  color: colors.primary,
  fontSize: '2rem',
  fontWeight: 600,
});

const StyledButton = styled('div')({
  cursor: 'pointer',
  '&:hover': {
    color: colors.primary,
  },
});

const StyledRowContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'editing',
})(({ editing }: { editing?: boolean }) => ({
  marginTop: '1rem',
  alignItems: 'center',
  padding: editing ? '' : '0.5rem 0 0.5rem 0',
}));

const ClearIconStyled = styled(ClearIcon)({
  display: 'block',
  width: '3rem',
  color: colors.secondaryText,
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

export const SettingFields = ({
  formData,
  editIndex,
  editing,
  openCloseDialog,
  openDeleteDialog,
}: ISettingFields): React.JSX.Element => {
  const selectedGenerator =
    (formData.document.generators ?? []).find(
      (generator) => generator.id === path(['settings', editIndex, 'generator'], formData.document),
    ) ?? ({} as IVNSGenerator);
  const nextGenerator = findNextGenerator(selectedGenerator, formData.document);

  const separateNightSettings = path(['settings', editIndex, 'separateNightSettings'], formData.document);
  const tachycardiaDetection = path(['settings', editIndex, 'tachycardiaDetection'], formData.document);
  const scheduledProgramming = path(['settings', editIndex, 'scheduledProgramming'], formData.document);

  // For some generators, set separateNightSettings and/or tachycardiaDetection and/or scheduledProgramming to OFF/no by default
  React.useEffect(() => {
    const newSettings = JSON.parse(JSON.stringify(formData.document.settings));
    const autoSet = (fields: string[]) =>
      fields.forEach((field) => {
        newSettings[editIndex][field] = field === 'tachycardiaDetection' ? 'OFF' : 'no';
      });
    if (
      ['LivaNova Model 106 AspireSR', 'LivaNova Model 1000 SenTiva', 'LivaNova Model 1000-D SenTiva DUO'].includes(
        selectedGenerator.generator ?? '',
      )
    ) {
      if (!separateNightSettings && !tachycardiaDetection && !scheduledProgramming) {
        // separateNightSettings and scheduledProgramming exist only for LivaNova Model 1000 SenTiva / LivaNova Model 1000-D SenTiva DUO
        autoSet([
          'tachycardiaDetection',
          ...(selectedGenerator.generator !== 'LivaNova Model 106 AspireSR'
            ? ['separateNightSettings', 'scheduledProgramming']
            : []),
        ]);
      } else if (
        selectedGenerator.generator === 'LivaNova Model 106 AspireSR' &&
        (separateNightSettings || scheduledProgramming)
      ) {
        // Reset since LivaNova Model 106 does not support separateNightSettings and scheduledProgramming
        autoSet(['separateNightSettings', 'scheduledProgramming']);
      }
      // Reset if selected generator does no support any of said settings
    } else if (separateNightSettings || tachycardiaDetection || scheduledProgramming) {
      autoSet(['tachycardiaDetection', 'separateNightSettings', 'scheduledProgramming']);
    }
    if (!equals(formData.document.settings, newSettings)) {
      formData.onChange?.({ settings: newSettings });
    }
  }, [separateNightSettings, tachycardiaDetection, scheduledProgramming, selectedGenerator, formData, editIndex]);

  // Disable scheduledProgramming if it is enabled when separateNightSettings is enabled
  React.useEffect(() => {
    if (separateNightSettings === 'yes' && scheduledProgramming === 'yes') {
      const newSettings = JSON.parse(JSON.stringify(formData.document.settings));
      newSettings[editIndex]['scheduledProgramming'] = 'no';
      if (!equals(formData.document.settings, newSettings)) {
        formData.onChange?.({ settings: newSettings });
      }
    }
  }, [separateNightSettings, scheduledProgramming, formData, editIndex]);

  // Note: separateNightSettings, tachycardiaDetection and scheduledProgramming are also dependent, but they are handled in the above useEffects
  const generatorDependentFields = ['outputCurrent', 'rate', 'pulseWidth', 'onTime', 'offTime', 'autoStimThreshold'];
  const generatorDependentFieldsPaths = generatorDependentFields.reduce((current, key) => {
    (current as { [key: string]: any })[key] = path(['settings', editIndex, key], formData.document);
    return current;
  }, {});

  const dateHookFloor = selectedGenerator.date;
  const dateHookCeiling = nextGenerator ? nextGenerator.date : undefined;

  // If selected generator is changed and the new dateHook causes setting date to be off limits, change it to be within limits
  React.useEffect(() => {
    const currentDate: PartialDate | undefined = path(['settings', editIndex, 'date'], formData.document);
    const newSettings = JSON.parse(JSON.stringify(formData.document.settings));

    if (dateHookFloor && partialDateToValue(currentDate) < partialDateToValue(dateHookFloor)) {
      newSettings[editIndex] = { ...newSettings[editIndex], date: dateHookFloor };
    }
    if (dateHookCeiling && partialDateToValue(currentDate) > partialDateToValue(dateHookCeiling)) {
      newSettings[editIndex] = { ...newSettings[editIndex], date: dateHookCeiling };
    }
    !equals(newSettings, formData.document.settings) && formData.onChange?.({ settings: newSettings });
  }, [selectedGenerator.generator]);

  const onChangeSettings = (values: TOnChangeValues): void => {
    const newSettings = JSON.parse(JSON.stringify(formData.document.settings));
    Object.keys(values).forEach((key) => {
      const valuesName = key;
      const value = values[valuesName];
      if (formData.onChange) {
        if (valuesName.indexOf('.') === -1) {
          if (valuesName === 'scheduledProgramming' && value === 'yes') {
            if (!path([editIndex, 'outputCurrent'], newSettings)) {
              newSettings[editIndex]['outputCurrent'] = { scheduled: [{}] };
            }
            if (!path([editIndex, 'outputCurrent', 'scheduled'], newSettings)) {
              newSettings[editIndex]['outputCurrent']['scheduled'] = [{}];
            }
          }
          newSettings[editIndex] = { ...newSettings[editIndex], [valuesName]: value };
          formData.onChange({ settings: newSettings });
          return;
        }
        const name = valuesName.split('.');

        if (name[1] !== 'scheduled') {
          newSettings[editIndex][name[0]] = {
            ...path([editIndex, name[0]], newSettings),
            [name[1]]: { ...(path([editIndex, name[0], name[1]], newSettings) as TAnyObject), [name[2]]: value },
          };
        }
        if (name[1] === 'scheduled') {
          const steps = JSON.parse(JSON.stringify(path([editIndex, name[0], name[1]], newSettings) ?? []));
          if (isEmpty(steps) || !path([name[2]], steps)) {
            steps.push({ [name[3]]: value });
          } else {
            steps[name[2]] = { ...steps[name[2]], [name[3]]: value };
          }
          newSettings[editIndex][name[0]] = { ...newSettings[editIndex][name[0]], [name[1]]: steps };
        }
        formData.onChange({ settings: newSettings });
      }
    });
  };

  const onChangeSteps = (row: string, action: 'add' | 'delete', step?: number) => (): void => {
    const newSettings = JSON.parse(JSON.stringify(formData.document.settings));
    const steps = JSON.parse(JSON.stringify(path([editIndex, row, 'scheduled'], newSettings) ?? []));
    if (action === 'add') {
      steps.push({});
      newSettings[editIndex][row]['scheduled'] = steps;
    }
    if (action === 'delete' && step) {
      const newSteps = remove(step, 1, steps);
      newSettings[editIndex][row]['scheduled'] = newSteps;
    }
    formData.onChange?.({ settings: newSettings });
  };

  const onTimeDefault = path(['settings', editIndex, 'onTime', 'default', 'normalMode'], formData.document);
  const offTimeDefault = path(['settings', editIndex, 'offTime', 'default', 'normalMode'], formData.document);
  const onTimeNight = path(['settings', editIndex, 'onTime', 'night', 'normalMode'], formData.document);
  const offTimeNight = path(['settings', editIndex, 'offTime', 'night', 'normalMode'], formData.document);

  // TODO: Move these calculations to a calc function
  const efficiencyCycleDefault =
    typeof onTimeDefault !== 'number' || typeof offTimeDefault !== 'number'
      ? 0
      : Math.round(
          (((onTimeDefault as number) + 4) / ((onTimeDefault as number) + (offTimeDefault as number) * 60)) * 100,
        );
  const efficiencyCycleNight =
    typeof onTimeNight !== 'number' || typeof offTimeNight !== 'number'
      ? 0
      : Math.round((((onTimeNight as number) + 4) / ((onTimeNight as number) + (offTimeNight as number) * 60)) * 100);

  React.useEffect(() => {
    const newSettings = JSON.parse(JSON.stringify(formData.document.settings));
    if (newSettings[editIndex] && newSettings[editIndex]['date']) {
      newSettings[editIndex]['efficiencyCycle'] = {
        ...newSettings[editIndex]['efficiencyCycle'],
        default: { normalMode: efficiencyCycleDefault },
      };
      if (separateNightSettings === 'yes') {
        newSettings[editIndex]['efficiencyCycle'] = {
          ...newSettings[editIndex]['efficiencyCycle'],
          night: { normalMode: efficiencyCycleNight },
        };
      }
      if (!equals(formData.document.settings, newSettings)) {
        formData.onChange?.({ settings: newSettings });
      }
    }
  }, [efficiencyCycleDefault, efficiencyCycleNight, separateNightSettings, formData, editIndex]);

  const columns = vnsSettingColumns((column) =>
    column === 'autoStimMode' && tachycardiaDetection !== 'ON'
      ? false
      : column === 'initialization' && scheduledProgramming !== 'yes'
        ? false
        : true,
  );
  const rows = vnsSettingRows((row) => (row === 'autoStimThreshold' && tachycardiaDetection !== 'ON' ? false : true));
  const placeholder = editing ? '' : '-';

  return (
    <React.Fragment>
      <FormRow title="vns.generator" headerWidth={3} headerStyle={{ color: colors.primaryText }}>
        <InputHandler
          type="Select"
          editing={editing}
          name="generator"
          formData={{
            onChange: onChangeSettings,
            document: {
              generator: path(['settings', editIndex, 'generator'], formData.document) || '',
              ...generatorDependentFieldsPaths,
            },
          }}
          options={formData.document.generators?.map((generator) => generator.id ?? '')}
          optionFormatter={(name: string | number) => {
            const generator = formData.document.generators?.find((generator) => generator.id === name);
            return `${generator?.generator} - ${formatPartialDate(generator?.date)}`;
          }}
          dependentFieldsList={(): string[] => generatorDependentFields}
          dependentFieldsRemovalWarning={true}
          placeholder={fm('vns.chooseGenerator')}
          width={38}
        />
      </FormRow>
      {editing && (
        <FormRow title="general.date" headerWidth={3} headerStyle={{ color: colors.primaryText }}>
          <InputHandler
            type="PartialDate"
            editing={true}
            name="date"
            formData={{
              onChange: onChangeSettings,
              document: { date: path(['settings', editIndex, 'date'], formData.document) || '' },
            }}
            isNotCancellable={true}
            dateHook={{
              dateHookFloor: dateHookFloor,
              dateHookCeiling: dateHookCeiling,
            }}
          />
        </FormRow>
      )}
      {generatorsWithAutoStimNightAndScheduledSettings.includes(selectedGenerator.generator ?? '') && (
        <FormSection
          header="vns.nightTime"
          style={{ border: editing ? undefined : 'none', padding: '0 -3rem 0 -3rem', marginRight: '-3rem' }}
        >
          <FormRow title="vns.separateNightSettings" headerWidth={3} headerStyle={{ color: colors.primaryText }}>
            <InputHandler
              type="Radio"
              editing={editing}
              name="separateNightSettings"
              formData={{
                onChange: onChangeSettings,
                document: {
                  separateNightSettings:
                    path(['settings', editIndex, 'separateNightSettings'], formData.document) || placeholder,
                },
              }}
              options={['yes', 'no']}
              optionFormatter={
                path(['settings', editIndex, 'separateNightSettings'], formData.document) || editing
                  ? (name: string | number): React.JSX.Element => fm(`vns.opts.yesNo.${name}`)
                  : undefined
              }
            />
          </FormRow>
          <React.Fragment>
            {path(['settings', editIndex, 'separateNightSettings'], formData.document) === 'yes' &&
              ['Start', 'End'].map((name: string, index: number) => (
                <FormRow
                  key={`nightTime${name}${index}`}
                  title={`vns.nightTime${name}`}
                  headerWidth={3}
                  headerStyle={{ color: colors.primaryText }}
                >
                  <InputHandler
                    type="TimePicker"
                    editing={editing}
                    name={`nightTime${name}`}
                    formData={{
                      onChange: onChangeSettings,
                      document: {
                        [`nightTime${name}`]:
                          path(['settings', editIndex, `nightTime${name}`], formData.document) || placeholder,
                      },
                    }}
                  />
                </FormRow>
              ))}
          </React.Fragment>
        </FormSection>
      )}
      {generatorsWithAutoStimNightAndScheduledSettings
        .concat(generatorsWithAutoStimSettings)
        .includes(selectedGenerator.generator ?? '') && (
        <FormSection
          header="vns.autoStim"
          style={{ border: editing ? undefined : 'none', padding: '0 -3rem 0 -3rem', marginRight: '-3rem' }}
        >
          <FormRow title="vns.tachycardiaDetection" headerWidth={3} headerStyle={{ color: colors.primaryText }}>
            <InputHandler
              type="Radio"
              editing={editing}
              name="tachycardiaDetection"
              formData={{
                onChange: onChangeSettings,
                document: {
                  tachycardiaDetection:
                    path(['settings', editIndex, 'tachycardiaDetection'], formData.document) || placeholder,
                },
              }}
              options={['ON', 'OFF']}
            />
          </FormRow>
          {path(['settings', editIndex, 'tachycardiaDetection'], formData.document) === 'ON' && (
            <FormRow title="vns.heartRateDetectionSetting" headerWidth={3} headerStyle={{ color: colors.primaryText }}>
              <InputHandler
                type="Select"
                editing={editing}
                name="heartRateDetectionSetting"
                formData={{
                  onChange: onChangeSettings,
                  document: {
                    heartRateDetectionSetting:
                      path(['settings', editIndex, 'heartRateDetectionSetting'], formData.document) || placeholder,
                  },
                }}
                options={[1, 2, 3, 4, 5]}
              />
            </FormRow>
          )}
        </FormSection>
      )}
      {separateNightSettings !== 'yes' &&
        generatorsWithAutoStimNightAndScheduledSettings.includes(selectedGenerator.generator ?? '') && (
          <FormSection
            header="vns.scheduledProgramming"
            style={{ border: editing ? undefined : 'none', padding: '0 -3rem 0 -3rem', marginRight: '-3rem' }}
          >
            <FormRow title="vns.scheduledProgramming" headerWidth={3} headerStyle={{ color: colors.primaryText }}>
              <InputHandler
                type="Radio"
                editing={editing}
                name="scheduledProgramming"
                formData={{
                  onChange: onChangeSettings,
                  document: {
                    scheduledProgramming:
                      path(['settings', editIndex, 'scheduledProgramming'], formData.document) || placeholder,
                  },
                }}
                options={['yes', 'no']}
                optionFormatter={
                  path(['settings', editIndex, 'scheduledProgramming'], formData.document) || editing
                    ? (name: string | number): React.JSX.Element => fm(`vns.opts.yesNo.${name}`)
                    : undefined
                }
              />
            </FormRow>
          </FormSection>
        )}
      <FormSection style={{ border: editing ? undefined : 'none', padding: '0 -3rem 0 -3rem', marginRight: '-3rem' }}>
        <Container style={{ marginTop: editing ? '1rem' : undefined }}>
          <Item xs={3} />
          {columns.map(
            (column: string, index: number): React.JSX.Element => (
              <Item key={`${column}${index}`} xs={2}>
                <StyledBoldText style={{ fontSize: editing ? undefined : '1.65rem' }}>
                  {fm(`vns.${column}`)}
                </StyledBoldText>
              </Item>
            ),
          )}
        </Container>
        <React.Fragment>
          {rows.map(
            (row: string, index: number): React.JSX.Element => (
              <React.Fragment key={`${row}${index}`}>
                <StyledRowContainer editing={editing ? true : false} style={{ marginTop: '4rem' }}>
                  <Item xs={3}>{fm(`vns.${row}`)}</Item>
                  {columns.map((column) =>
                    columnHasNumberField(column, row) ? (
                      <Item key={`${row}${capitalize(column)}`} xs={2}>
                        <div style={{ display: 'inline-flex' }}>
                          <InputHandler
                            type="NumberField"
                            editing={row !== 'efficiencyCycle' ? editing : false}
                            name={`${row}.default.${column}`}
                            formData={{
                              onChange: onChangeSettings,
                              document: {
                                [row]: {
                                  default: {
                                    [column]: exists(
                                      path(['settings', editIndex, row, 'default', column], formData.document),
                                    )
                                      ? path(['settings', editIndex, row, 'default', column], formData.document)
                                      : placeholder,
                                  },
                                },
                              },
                            }}
                            precision={row === 'outputCurrent' ? 3 : 2}
                            width={7}
                            height={3}
                            placeholder={`vns.${row}Placeholder`}
                          />
                        </div>
                        &nbsp;{' '}
                        <div style={{ display: 'inline-flex', width: '2rem', fontWeight: !editing ? 600 : undefined }}>
                          {editing || exists(path(['settings', editIndex, row, 'default', column], formData.document))
                            ? defaultUnits[row]
                            : ''}
                        </div>
                      </Item>
                    ) : (
                      <Item xs={3} key={`${row}${capitalize(column)}`} />
                    ),
                  )}
                </StyledRowContainer>
                {!(scheduledProgramming === 'yes' && row === 'outputCurrent') && separateNightSettings === 'yes' && (
                  <div
                    style={{
                      backgroundColor: !editing ? colors.lightestGray : undefined,
                      marginLeft: '-2rem',
                      paddingLeft: '2rem',
                    }}
                  >
                    <StyledRowContainer key={`night${row}${index}`} editing={editing ? true : false}>
                      <Item xs={3}>- {fm('vns.night')}</Item>
                      {columns.map((column) =>
                        columnHasNumberField(column, row, true) ? (
                          <Item key={`${row}${capitalize(column)}Night`} xs={2}>
                            <div style={{ display: 'inline-flex' }}>
                              <InputHandler
                                type="NumberField"
                                editing={row !== 'efficiencyCycle' ? editing : false}
                                name={`${row}.night.${column}`}
                                formData={{
                                  onChange: onChangeSettings,
                                  document: {
                                    [row]: {
                                      night: {
                                        [column]: exists(
                                          path(['settings', editIndex, row, 'night', column], formData.document),
                                        )
                                          ? path(['settings', editIndex, row, 'night', column], formData.document)
                                          : placeholder,
                                      },
                                    },
                                  },
                                }}
                                precision={row === 'outputCurrent' ? 3 : 2}
                                width={7}
                                height={3}
                                placeholder={`vns.${row}Placeholder`}
                              />
                            </div>
                            &nbsp;{' '}
                            <div
                              style={{ display: 'inline-flex', width: '2rem', fontWeight: !editing ? 600 : undefined }}
                            >
                              {editing || exists(path(['settings', editIndex, row, 'night', column], formData.document))
                                ? defaultUnits[row]
                                : ''}
                            </div>
                          </Item>
                        ) : (
                          <Item xs={3} key={`${row}${capitalize(column)}Night`} />
                        ),
                      )}
                    </StyledRowContainer>
                  </div>
                )}
                {row === 'outputCurrent' && scheduledProgramming === 'yes' && (
                  // Scheduled programming is only enabled for outputCurrent but this implementation supports all rows
                  <React.Fragment>
                    {((path(['settings', editIndex, row, 'scheduled'], formData.document) as []) ?? [{}]).map(
                      (_: TAnyObject, i: number, arr) => (
                        <div
                          key={`${row}Step${i}`}
                          style={{
                            backgroundColor: !editing && i % 2 === 0 ? colors.lightestGray : undefined,
                            marginLeft: '-2rem',
                            paddingLeft: '2rem',
                          }}
                        >
                          <StyledRowContainer editing={editing ? true : false}>
                            <Item xs={1}>
                              - {fm('vns.step')}
                              {` ${i + 1}`}
                            </Item>
                            <Item xs={2}>
                              {editing && i > 0 && (
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <ClearIconStyled onClick={onChangeSteps(row, 'delete', i)} />
                                </div>
                              )}
                            </Item>
                            {columns.map((column) =>
                              columnHasNumberField(column, row) ? (
                                <Item key={`${row}${capitalize(column)}Scheduled`} xs={2}>
                                  <div style={{ display: 'inline-flex' }}>
                                    <InputHandler
                                      type="NumberField"
                                      editing={editing}
                                      name={`${row}.scheduled.${i}.${column}`}
                                      formData={{
                                        onChange: onChangeSettings,
                                        document: {
                                          [row]: {
                                            scheduled: insert(
                                              i,
                                              {
                                                [column]: exists(
                                                  path(
                                                    ['settings', editIndex, row, 'scheduled', i, column],
                                                    formData.document,
                                                  ),
                                                )
                                                  ? path(
                                                      ['settings', editIndex, row, 'scheduled', i, column],
                                                      formData.document,
                                                    )
                                                  : placeholder,
                                              },
                                              path(['settings', editIndex, row, 'scheduled'], formData.document) ?? [],
                                            ),
                                          },
                                        },
                                      }}
                                      precision={row === 'outputCurrent' ? 3 : 2}
                                      width={7}
                                      height={3}
                                      placeholder={`vns.${row}Placeholder`}
                                    />
                                  </div>
                                  &nbsp;{' '}
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      width: '2rem',
                                      fontWeight: !editing ? 600 : undefined,
                                    }}
                                  >
                                    {editing ||
                                    exists(
                                      path(['settings', editIndex, row, 'scheduled', i, column], formData.document),
                                    )
                                      ? defaultUnits[row]
                                      : ''}
                                  </div>
                                </Item>
                              ) : column === 'initialization' ? (
                                <Item xs={3} key={`${row}${capitalize(column)}Scheduled`}>
                                  <Container>
                                    <Item xs={7}>
                                      <InputHandler
                                        type="PartialDate"
                                        editing={editing}
                                        name={`${row}.scheduled.${i}.initializationDate`}
                                        formData={{
                                          onChange: onChangeSettings,
                                          document: {
                                            [row]: {
                                              scheduled: insert(
                                                i,
                                                {
                                                  initializationDate:
                                                    path(
                                                      [
                                                        'settings',
                                                        editIndex,
                                                        row,
                                                        'scheduled',
                                                        i,
                                                        'initializationDate',
                                                      ],
                                                      formData.document,
                                                    ) || placeholder,
                                                },
                                                path(['settings', editIndex, row, 'scheduled'], formData.document) ??
                                                  [],
                                              ),
                                            },
                                          },
                                        }}
                                        dateDefault="now"
                                        isNotCancellable={true}
                                        monthsAsNumbers
                                        hideDatePicker
                                      />
                                    </Item>
                                    <Item xs={5}>
                                      <div style={{ marginLeft: !editing ? '-7rem' : undefined }}>
                                        <InputHandler
                                          type="TimePicker"
                                          editing={editing}
                                          name={`${row}.scheduled.${i}.initializationTime`}
                                          formData={{
                                            onChange: onChangeSettings,
                                            document: {
                                              [row]: {
                                                scheduled: insert(
                                                  i,
                                                  {
                                                    initializationTime:
                                                      path(
                                                        [
                                                          'settings',
                                                          editIndex,
                                                          row,
                                                          'scheduled',
                                                          i,
                                                          'initializationTime',
                                                        ],
                                                        formData.document,
                                                      ) || placeholder,
                                                  },
                                                  path(['settings', editIndex, row, 'scheduled'], formData.document) ??
                                                    [],
                                                ),
                                              },
                                            },
                                          }}
                                          width={11}
                                          placeholder="vns.timePlaceholder"
                                          isNotCancellable={true}
                                        />
                                      </div>
                                    </Item>
                                  </Container>
                                </Item>
                              ) : column ? (
                                <Item xs={2} key={`${row}${capitalize(column)}Scheduled`} />
                              ) : undefined,
                            )}
                          </StyledRowContainer>
                          {editing && i === arr.length - 1 && (
                            <StyledRowContainer editing={editing ? true : false}>
                              <Item>
                                <StyledButton onClick={onChangeSteps(row, 'add')}>{fm('vns.addStep')}</StyledButton>
                              </Item>
                            </StyledRowContainer>
                          )}
                        </div>
                      ),
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ),
          )}
        </React.Fragment>
      </FormSection>
      {!editing && openCloseDialog && openDeleteDialog && (
        <Container justifyContent="flex-end" style={{ marginTop: '4rem' }}>
          <Item>
            <ActionButton
              text={'general.edit'}
              onClick={openCloseDialog(editIndex)}
              width={10}
              height={3}
              fontSize={14}
            />
          </Item>
          <Item style={{ margin: '0 0 0 2rem' }}>
            <ActionButton
              text={'general.delete'}
              onClick={openDeleteDialog(editIndex)}
              width={10}
              height={3}
              fontSize={14}
            />
          </Item>
        </Container>
      )}
    </React.Fragment>
  );
};

interface ISettingFields {
  formData: IFormData<IVNS>;
  editIndex: number;
  editing: boolean;
  openCloseDialog?: (settingIndex?: number, cancel?: boolean) => () => void;
  openDeleteDialog?: (settingIndex?: number) => () => void;
}
