import * as React from 'react';
import { styled } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import colors from '../../config/theme/colors';

import { Container, Item } from 'Components/Grid';

const ClearIconStyled = styled(ClearIcon)({
  display: 'block',
  width: '3rem',
  color: colors.secondaryText,
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

const StyledRowControls = ({ clickHandler, name, index }: IStyledRowControlProps): React.JSX.Element => (
  <ClearIconStyled onClick={(): void => clickHandler(name, index)} />
);

export const SimpleRowInput = ({
  clickHandler,
  name,
  index,
  firstItem,
  style,
  controlStyle,
  hideControls,
}: IStyledRowProps): React.JSX.Element => {
  return (
    <Container style={style ? style : undefined}>
      <Item xs={name === 'mutations' ? 5 : 9}>
        <Container>
          {firstItem && (
            <Item key={'firstItem'} xs={12}>
              {firstItem ? firstItem : <div>&nbsp;</div>}
            </Item>
          )}
        </Container>
      </Item>
      <Item xs={1} style={controlStyle ?? { marginLeft: '-2rem' }}>
        {clickHandler && name && !hideControls && (
          <StyledRowControls clickHandler={clickHandler} name={name} index={index || index === 0 ? index : -1} />
        )}
      </Item>
    </Container>
  );
};

interface IStyledRowProps {
  clickHandler?: any;
  name?: string;
  index?: number;
  firstItem?: React.JSX.Element | string | number;
  secondItem?: React.JSX.Element | string | number;
  thirdItem?: React.JSX.Element | string | number;
  style?: any;
  controlStyle?: any;
  hideControls?: boolean;
}

interface IStyledRowControlProps {
  clickHandler: any;
  name: string;
  index: number;
}
