import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import OptionChooser from '../../../../../components/OptionChooser';

import colors from '../../../../../config/theme/colors';

import { optFormatter, opts } from '../updrsVSettings';

const UPDRSVForm = ({ formData, viewing }: IOwnProps): React.JSX.Element => (
  <FormSection>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="updrs.updrsV" bottomMargin={true}>
      <OptionChooser formData={formData} name={'value'} opts={opts} optionFormatter={optFormatter} viewing={viewing} />
    </FormRow>
    <span
      style={{
        fontStyle: 'italic',
        color: colors.darkGray,
      }}
    >
      Fahn S, Elton R, Members of the UPDRS Development Committee. In: Fahn S, Marsden CD, Calne DB, Goldstein M, eds.
      Recent Developments in Parkinson’s Disease. Vol.2. Florham Park, NJ. Macmillan Health Care Information 1987, pp
      153-163, 293-304
    </span>
  </FormSection>
);

interface IOwnProps {
  formData: IFormData<IUPDRSV>;
  viewing: boolean;
}

export default UPDRSVForm;
