import * as React from 'react';
import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import FormSectionBar from '../../../../components/FormSectionBar';
import { Item } from '../../../../components/Grid';
import InputHandler from '../../../../components/InputHandler';
import { historyValue } from '../../../../config/theme/componentTheme';
import { IFormContext, withFormContext } from '../../../../containers/FormContextHandler';
import { formatRowData, getLastSeizureDate } from '../../utils';
import { calculateIntervalYMD, isPartialDate, nowPartialDate } from 'neuro-utils';

const NewSeizureFreePeriod = ({ fm, documents, formData, editing }: IOwnProps): React.JSX.Element | null => {
  // fetch latest confirmed seizure date
  const lastSeizureDate = getLastSeizureDate(documents);
  const startDate = isPartialDate(formData.document.startDate) ? formData.document.startDate : lastSeizureDate;

  const prefix = 'seizure.'; // for localization

  const noData: React.ReactNode = fm && fm('general.noData');
  const endDate = formData.document.endDate ? formData.document.endDate : nowPartialDate();
  const diff = startDate && calculateIntervalYMD(startDate, endDate);

  return (
    <>
      <FormSectionBar header={prefix + 'newSeizureFreePeriod'} />
      <FormSection>
        <FormRow title={prefix + 'startDate'}>
          <InputHandler
            type="PartialDate"
            name="startDate"
            formData={formData}
            editing={!!editing}
            dateDefault={lastSeizureDate ?? undefined}
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title={prefix + 'endDate'}>
          <InputHandler type="PartialDate" name="endDate" formData={formData} editing={!!editing} />
        </FormRow>
        <FormRow title={prefix + 'seizureFreePeriodLength'}>
          {lastSeizureDate ? (
            <Item style={historyValue}>{formatRowData('seizureFreePeriodLength', diff)}</Item>
          ) : (
            <>{noData}</>
          )}
        </FormRow>
        <FormRow title={prefix + 'seizureFreePeriodDetails'}>
          <InputHandler
            type="TextArea"
            name="seizureFreePeriodDetails"
            formData={formData}
            editing={!!editing}
            placeholder={prefix + 'detailsPlaceholder'}
          />
        </FormRow>
      </FormSection>
    </>
  );
};

type IOwnProps = IFormContext<ISeizureFreePeriod>;

export default withFormContext(NewSeizureFreePeriod);
