import { indexBy, prop } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import FormSectionHistoryAcceptor from '../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../components/InputHandler';
import { rowData, TPulmonaryRowDataKey } from './rowData';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): React.JSX.Element => {
    return <FormattedMessage id={`pulmonary.${opt}.${name}`} />;
  };

const conditions = (name: string, formData: IOwnProps['formData']): boolean => {
  return name !== 'assistanceInAirwayClearanceorSecretionMobilisation' &&
    formData.document?.assistanceInAirwayClearanceorSecretionMobilisation !== 'yes'
    ? false
    : name === 'ippvHoursPerDay' && formData.document?.ippv !== 'daily'
      ? false
      : name === 'otherAssistance' && [undefined, null, 'no'].includes(formData.document?.other)
        ? false
        : true;
};

const MakeFormRow = ({
  name,
  type,
  formData,
  options,
  optionFormat,
  bottomMargin = true,
}: {
  name: string;
  type: 'Radio' | 'TextField';
  formData: IOwnProps['formData'];
  options?: string[];
  optionFormat?: string;
  bottomMargin?: boolean;
}): React.JSX.Element => {
  return (
    <FormRow title={`pulmonary.${name}`} bottomMargin={bottomMargin} headerWidth={6} id={`${name}`}>
      <InputHandler
        editing={true}
        formData={formData}
        name={name}
        type={type}
        options={options}
        optionFormatter={
          type === 'Radio'
            ? optionFormat
              ? optionFormatter(`opts.${optionFormat}`)
              : optionFormatter(`opts.${name}`)
            : undefined
        }
      />
    </FormRow>
  );
};

const PulmonaryForm = ({ formData, documents, editing }: IOwnProps): React.JSX.Element => {
  const elements = Object.keys(rowData).map((name) => ({
    name,
    element: (
      <MakeFormRow
        name={name}
        type={rowData[name as TPulmonaryRowDataKey].type}
        formData={formData}
        options={rowData[name as TPulmonaryRowDataKey].options}
        optionFormat={rowData[name as TPulmonaryRowDataKey].optionFormat}
      />
    ),
    optionFormatter:
      rowData[name as TPulmonaryRowDataKey].type === 'Radio'
        ? rowData[name as TPulmonaryRowDataKey].optionFormat
          ? optionFormatter(`opts.${rowData[name as TPulmonaryRowDataKey].optionFormat}`)
          : optionFormatter(`opts.${name}`)
        : undefined,
    condition: conditions(name, formData),
  }));

  return (
    <>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            name="date"
            editing={true}
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
      </FormSection>
      <FormSectionHistoryAcceptor documents={documents} formData={formData} documentID={editing}>
        {indexBy(prop('name'), elements)}
      </FormSectionHistoryAcceptor>
    </>
  );
};

interface IOwnProps {
  formData: IFormData<IPulmonary>;
  editing: string;
  documents: IPulmonary[];
}

export default PulmonaryForm;
