import NumberField from 'Components/InputHandler/components/NumberField';
import { IFormInputRow } from '.';

export type RowDataProps = Partial<IFormInputRow>;
export type RowDataPropsNested = { [key in keyof Partial<IBackground>]: RowDataProps };
export type RowDataOther = {
  otherProps?: {
    min?: number;
    max?: number;
    addToYearCeiling?: number;
    hideDaySelection?: boolean;
    hideDatePicker?: boolean;
    dependentFieldsList?: (value: TFieldValue, prevValue: TFieldValue) => string[];
    info?: string;
  };
};

const huntingtonImpairmentLevels: Readonly<Array<THuntingtonImpairment>> = [
  'normal',
  'impaired',
  'significantlyImpaired',
  'almostUnable',
  'unable',
];

export const rowData: {
  [key in Platform]: { [key: string]: any } & (RowDataProps | RowDataPropsNested | RowDataOther);
} = {
  sma: {
    dominantArm: {
      type: 'Radio',
      options: ['left', 'right', 'ambidextrous'],
      optionFormat: 'dominantArm',
    },
    pregnancyWeeksAtBirth: {
      type: 'NumberField',
      placeholder: 'background.pregnancyWeeksAtBirth',
      otherProps: {
        min: 0,
        max: 100,
      },
    },
    familyMemberDiagnosedSMA: {
      type: 'Radio',
      options: ['yes', 'no', 'unknown'],
      optionFormat: 'yesNoUnknown',
    },
    familyMembers: {
      type: 'Checkbox',
      options: [
        'mother',
        'father',
        'daughter',
        'son',
        'brother',
        'halfBrother',
        'sister',
        'halfSister',
        'niece',
        'nephew',
        'maternalUncle',
        'paternalUncle',
        'maternalAunt',
        'paternalAunt',
        'maternalCousin',
        'paternalCousin',
        'maternalGrandFather',
        'paternalGrandFather',
        'maternalGrandMother',
        'paternalGrandMother',
        'grandDaughter',
        'grandson',
      ],
      optionFormat: 'relatives',
    },
  },
  dmd: {
    dominantArm: {
      type: 'Radio',
      options: ['left', 'right', 'ambidextrous'],
      optionFormat: 'dominantArm',
    },
    pregnancyWeeksAtBirth: {
      type: 'NumberField',
      placeholder: 'background.pregnancyWeeksAtBirth',
      otherProps: {
        min: 0,
        max: 100,
      },
    },
    familyMemberDiagnosedDMD: {
      type: 'Radio',
      options: ['yes', 'no', 'unknown'],
      optionFormat: 'yesNoUnknown',
    },
    familyMembers: {
      type: 'Checkbox',
      options: [
        'mother',
        'father',
        'daughter',
        'son',
        'brother',
        'halfBrother',
        'sister',
        'halfSister',
        'niece',
        'nephew',
        'maternalUncle',
        'paternalUncle',
        'maternalAunt',
        'paternalAunt',
        'maternalCousin',
        'paternalCousin',
        'maternalGrandFather',
        'paternalGrandFather',
        'maternalGrandMother',
        'paternalGrandMother',
        'grandDaughter',
        'grandson',
      ],
      optionFormat: 'relatives',
    },
  },
  parkinson: {
    basicInformation: {
      familyPrevalenceParkinson: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
      },
      familyPrevalenceDisease: {
        type: 'TextField',
      },
      familyMembersParkinson: {
        type: 'Checkbox',
        options: [
          'siblings',
          'twinSibling',
          'mother',
          'father',
          'maternalGrandparents',
          'paternalGrandparents',
          'children',
          'other',
        ],
        optionFormat: 'familyMembers',
      },
      siblingDiagnosisAge: {
        type: 'NumberField',
        placeholder: 'background.age',
      },
      maritalStatus: {
        type: 'Radio',
        options: ['single', 'marriage', 'relationship', 'divorced', 'widow'],
      },
      typeOfLiving: {
        type: 'Radio',
        options: ['independentAtHome', 'withHelpAtHome', 'shelteredHome', 'longTermCare'],
      },
      educationYears: {
        type: 'NumberField',
        placeholder: 'background.educationYears',
      },
      employment: {
        type: 'Checkbox',
        options: [
          'fullTime',
          'partTime',
          'student',
          'sickLeave',
          'unemployed',
          'retired',
          'militaryService',
          'stayAtHome',
          'unknown',
        ],
      },
      occupation: {
        type: 'Radio',
        options: ['employee', 'entrepreneur', 'farmer', 'freelancer', 'noProperOccupation', 'unknown', 'other'],
      },
      partTimeHours: {
        type: 'Radio',
        options: ['-', '<20', '20-30', '31-35', '36-40', '>40', 'unknown'],
      },
      sickLeaveDays: {
        type: 'NumberField',
        placeholder: 'background.days',
      },
      unemploymentDays: {
        type: 'NumberField',
        placeholder: 'background.days',
      },
      retirementType: {
        type: 'Radio',
        options: ['disabilityPension', 'partialPension', 'pension', 'unemploymentPension', 'unknown'],
      },
    },
    drivingHealth: {
      driversLicense: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
        otherProps: {
          dependentFieldsList: () => ['driversLicenseGroup'],
        },
      },
      driversLicenseGroup: {
        type: 'Checkbox',
        options: ['R1', 'R2'],
      },
    },
    earlierHeadInjury: {
      earlierHeadInjury: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
      },
      earlierHeadInjuryYear: {
        title: 'background.earlierHeadInjuryYear',
        type: 'SingleValueList',
        placeholder: 'background.enterYear',
        otherProps: {
          component: (props: IInputBasics & INumberField) => <NumberField maxLength={4} {...props} />,
          sortMethod: (a: number, b: number) => {
            return a - b;
          },
        },
      },
    },
    powerOfAttorney: {
      powerOfAttorney: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
      },
      powerOfAttorneyStart: {
        type: 'PartialDate',
        otherProps: { hideDatePicker: true, hideDaySelection: true },
      },
    },
    careAllowance: {
      careAllowance: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
      },
      careAllowanceClass: {
        type: 'Radio',
        options: ['basic', 'raised', 'highest'],
      },
    },
    firearmsLicense: {
      firearmsLicense: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
      },
    },
  },
  ms: {
    smoking: {
      type: 'Radio',
      options: ['currentSmoker', 'formerSmoker', 'notCurrently', 'never', 'unknown'],
    },
    familyAnamnesisMS: {
      type: 'Radio',
      options: ['yes', 'no', 'unknown'],
      optionFormat: 'yesNoUnknown',
    },
    familyMembersAnamnesisMS: {
      type: 'Checkbox',
      options: ['parents', 'children', 'siblings', 'twinSister', 'twinBrother', 'other'],
      optionFormat: 'familyMembers',
    },
    education: {
      type: 'Checkbox',
      options: [
        'comprehensiveSchool',
        'vocationalQualification',
        'universityOfAppliedSciencesDegree',
        'postSecondaryQualification',
        'upperSecondarySchool',
        'universityDegree',
      ],
    },
    employment: {
      type: 'Checkbox',
      options: [
        'employed',
        'fullTime',
        'partTime',
        'sickLeave',
        'unemployed',
        'stayAtHome',
        'student',
        'militaryService',
        'disabilityPension',
        'partTimePension',
        'fixedTermDisabilityPension',
        'pension',
        'other',
      ],
    },
    employmentOther: {
      type: 'TextArea',
    },
    maritalStatus: {
      type: 'Radio',
      options: ['single', 'marriageOrRelationship', 'divorced', 'widow'],
    },
    childCount: {
      type: 'NumberField',
      placeholder: 'general.count',
    },
    pregnancy: {
      type: 'Radio',
      options: ['no', 'planningAPregnancy', 'pregnant'],
    },
    pregnancyDueDate: {
      type: 'PartialDate',
    },
    nursing: {
      type: 'Radio',
      options: ['yes', 'no'],
      optionFormat: 'yesNoUnknown',
    },
    hormonalTherapy: {
      type: 'Radio',
      options: [
        'no',
        'infertility',
        'contraceptivePill',
        'hormoneReplacementTherapy',
        'hormonalIntrauterineDevice',
        'contraceptiveImplant',
      ],
    },
    sterilization: {
      type: 'Radio',
      options: ['yes', 'no'],
      optionFormat: 'yesNoUnknown',
    },
    typeOfLiving: {
      type: 'Radio',
      options: ['independentAtHome', 'withHelpAtHome', 'shelteredHome', 'longTermCare', 'unknown'],
    },
    reimbursementValidUntil: {
      type: 'PartialDate',
      otherProps: { addToYearCeiling: 5 },
    },
    allowance: {
      type: 'Radio',
      options: ['none', 'basic', 'elevated', 'special'],
    },
    familyCarer: {
      type: 'Radio',
      options: ['yes', 'no'],
      optionFormat: 'yesNoUnknown',
    },
    personalAssistant: {
      type: 'Radio',
      options: ['yes', 'no'],
      optionFormat: 'yesNoUnknown',
    },
  },
  huntington: {
    familyAnamnesisHuntington: {
      type: 'Radio',
      options: ['yes', 'no', 'unknown'],
      optionFormat: 'yesNoUnknown',
    },
    familyMembersAnamnesisHuntington: {
      type: 'Checkbox',
      options: ['mother', 'father', 'sister', 'brother', 'daughter', 'son', 'relativeDegree2'],
      optionFormat: 'relatives',
    },
    relativeDegree2FreeText: {
      type: 'TextField',
    },
    maritalStatus: {
      type: 'Radio',
      options: ['single', 'marriageOrRelationship', 'divorced', 'widow'],
    },
    childCount: {
      type: 'NumberField',
      placeholder: 'general.count',
    },
    typeOfLiving: {
      type: 'Radio',
      options: ['independentAtHome', 'withHelpAtHome', 'shelteredHome', 'longTermCare', 'unknown'],
    },
    education: {
      type: 'Checkbox',
      options: [
        'comprehensiveSchool',
        'vocationalQualification',
        'universityOfAppliedSciencesDegree',
        'postSecondaryQualification',
        'upperSecondarySchool',
        'universityDegree',
      ],
    },
    educationYears: {
      type: 'NumberField',
      placeholder: 'background.educationYears',
    },
    employment: {
      type: 'Checkbox',
      options: [
        'fullTime',
        'partTime',
        'sickLeave',
        'unemployed',
        'stayAtHome',
        'student',
        'militaryService',
        'partTimePension',
        'disabilityPension',
        'pension',
      ],
    },
    retirementDate: {
      type: 'PartialDate',
    },
    householdChoresAbility: {
      type: 'Radio',
      options: [...huntingtonImpairmentLevels],
      optionFormat: 'huntingtonImpairment',
    },
    financeHandlingAbility: {
      type: 'Radio',
      options: [...huntingtonImpairmentLevels],
      optionFormat: 'huntingtonImpairment',
    },
    dailyActivitiesAbility: {
      type: 'Radio',
      options: [...huntingtonImpairmentLevels],
      optionFormat: 'huntingtonImpairment',
    },
    contactPermit: {
      type: 'Radio',
      options: ['yes', 'no', 'unknown'],
      optionFormat: 'yesNoUnknown',
    },
  },
  epilepsy: {
    basicInformation: {
      childCount: {
        type: 'NumberField',
        placeholder: 'background.childCount',
      },
      typeOfLiving: {
        type: 'Radio',
        options: ['independentAtHome', 'withHelpAtHome', 'shelteredHome', 'longTermCare', 'unknown'],
      },
      employment: {
        type: 'Checkbox',
        options: [
          'fullTime',
          'partTime',
          'sickLeave',
          'unemployed',
          'stayAtHome',
          'student',
          'militaryService',
          'retired',
          'unknown',
        ],
      },
      occupation: {
        type: 'Radio',
        options: ['employee', 'entrepreneur', 'farmer', 'freelancer', 'noProperOccupation'],
      },
      partTimeHours: {
        type: 'Radio',
        options: ['-', '<20', '20-30', '31-35', '36-40', '>40', 'unknown'],
      },
      sickLeaveDays: {
        type: 'NumberField',
        placeholder: 'background.days',
      },
      unemploymentDays: {
        type: 'NumberField',
        placeholder: 'background.days',
      },
      retirementType: {
        type: 'Radio',
        options: ['disabilityPension', 'partialPension', 'pension', 'unemploymentPension'],
      },
      retirementStart: {
        type: 'NumberField',
        placeholder: 'general.year',
      },
    },
    drivingHealth: {
      driversLicense: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
        otherProps: {
          dependentFieldsList: () => ['driversLicenseGroup'],
        },
      },
      driversLicenseGroup: {
        type: 'Checkbox',
        options: ['R1', 'R2'],
      },
    },
    birthAndEarlyStages: {
      pregnancyTime: {
        type: 'NumberField',
        placeholder: 'background.pregnancyTime',
      },
      weightBirth: {
        type: 'NumberField',
        placeholder: 'background.weightBirth',
      },
      heightBirth: {
        type: 'NumberField',
        placeholder: 'background.heightBirth',
      },
      headCircumferenceBirth: {
        type: 'NumberField',
        placeholder: 'background.headCircumferenceBirth',
      },
      apgar1min: {
        type: 'Select',
        options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        placeholder: 'background.apgar1min',
      },
      apgar5min: {
        type: 'Select',
        options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        placeholder: 'background.apgar5min',
      },
      apgar10min: {
        type: 'Select',
        options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        placeholder: 'background.apgar10min',
      },
      perinatalComplications: {
        type: 'Radio',
        options: ['yes', 'no'],
        optionFormat: 'yesNoUnknown',
      },
      complicationInfo: {
        type: 'TextField',
        placeholder: 'background.complicationInfo',
      },
      feverCramps: {
        type: 'Radio',
        options: ['yes', 'no'],
        optionFormat: 'yesNoUnknown',
      },
      feverCrampsInfo: {
        type: 'TextField',
        placeholder: 'background.feverCrampsInfo',
      },
      disabledDiagnosis: {
        type: 'Radio',
        options: ['yes', 'no'],
        optionFormat: 'yesNoUnknown',
      },
      severity: {
        type: 'Radio',
        options: ['mild', 'moderate', 'severe', 'unknown'],
        optionFormat: 'severity',
      },
      handedness: {
        type: 'Radio',
        options: ['right', 'left'],
      },
    },
    familyAnamnesisEpilepsy: {
      epilepsyFirstDegreeRelative: {
        type: 'Radio',
        options: ['no', 'yes', 'unknown'],
        optionFormat: 'yesNoUnknown',
      },
      relatives: {
        type: 'Checkbox',
        options: ['mother', 'father', 'sibling', 'child'],
        optionFormat: 'relatives',
      },
      relativityInfo: {
        type: 'TextField',
        placeholder: 'background.relativityInfo',
      },
      otherFamilyAnamnesis: {
        type: 'TextArea',
        placeholder: 'background.otherFamilyAnamnesis',
      },
    },
  },
  sleepApnea: {
    drivingHealth: {
      driversLicense: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
        otherProps: {
          dependentFieldsList: () => ['annualDrivingKM', 'driversLicenseGroup', 'professionalDriving'],
        },
      },
      annualDrivingKM: {
        type: 'NumberField',
        placeholder: 'background.annualDrivingKM',
      },
      driversLicenseGroup: {
        type: 'Checkbox',
        options: ['R1', 'R2'],
      },
      professionalDriving: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
      },
      drivingTestDate: {
        type: 'PartialDate',
      },
    },
    smoking: {
      smoker: {
        type: 'Radio',
        options: ['currentSmoker', 'formerSmoker', 'no', 'unknown'],
        otherProps: {
          dependentFieldsList: () => ['smokingStartYear', 'cigaretteAmountPerDay', 'smokingEndYear'],
        },
      },
      smokingStartYear: {
        type: 'NumberField',
        placeholder: 'general.year',
      },
      cigaretteAmountPerDay: {
        type: 'NumberField',
        placeholder: 'background.cigaretteAmount',
      },
      packYears: {
        placeholder: 'background.packYears',
        info: 'background.packYearsInfo',
      },
      smokingEndYear: {
        type: 'NumberField',
        placeholder: 'general.year',
      },
    },
    snus: {
      snus: {
        type: 'Radio',
        options: ['yes', 'earlier', 'no', 'unknown'],
        otherProps: {
          dependentFieldsList: () => ['snusStart', 'snusPortions', 'snusEnd'],
        },
      },
      snusStart: {
        type: 'PartialDate',
        placeholder: 'general.year',
        otherProps: {
          hideDaySelection: true,
        },
      },
      snusPortions: {
        type: 'NumberField',
        placeholder: 'background.snusPortionsPlaceholder',
      },
      snusEnd: {
        type: 'PartialDate',
        placeholder: 'general.year',
        otherProps: {
          hideDaySelection: true,
        },
      },
    },
    intoxicantUsage: {
      averageWeeklyAlcohol: {
        type: 'NumberField',
        placeholder: 'background.drinkAmount',
      },
      otherIntoxicants: {
        type: 'Radio',
        options: ['yes', 'no', 'unknown'],
        optionFormat: 'yesNoUnknown',
        otherProps: {
          dependentFieldsList: () => ['whichIntoxicants'],
        },
      },
      whichIntoxicants: {
        type: 'Checkbox',
        options: ['streetDrugs', 'cannabis', 'amphetamine', 'heroin'],
      },
    },
    employment: {
      employment: {
        type: 'Checkbox',
        options: [
          'fullTime',
          'partTime',
          'student',
          'sickLeave',
          'unemployed',
          'retired',
          'militaryService',
          'stayAtHome',
          'unknown',
        ],
      },
    },
  },
  ninmt: {},
  mgravis: {
    smoking: {
      smoking: {
        type: 'Radio',
        options: ['currentSmoker', 'formerSmoker', 'notCurrently', 'never', 'unknown'],
        otherProps: {
          dependentFieldsList: () => ['smokingStartYear', 'smokingEndYear'],
        },
      },
      smokingStartYear: {
        type: 'NumberField',
        placeholder: 'general.year',
      },
      cigaretteAmountPerDay: {
        type: 'NumberField',
        placeholder: 'background.cigaretteAmount',
      },
      smokingEndYear: {
        type: 'NumberField',
        placeholder: 'general.year',
      },
    },
    snus: {
      snus: {
        type: 'Radio',
        options: ['yes', 'earlier', 'no', 'unknown'],
        otherProps: {
          dependentFieldsList: () => ['snusStart', 'snusPortions', 'snusEnd'],
        },
      },
      snusStart: {
        type: 'PartialDate',
        placeholder: 'general.year',
        otherProps: {
          hideDaySelection: true,
        },
      },
      snusPortions: {
        type: 'NumberField',
        placeholder: 'background.snusPortionsPlaceholder',
      },
      snusEnd: {
        type: 'PartialDate',
        placeholder: 'general.year',
        otherProps: {
          hideDaySelection: true,
        },
      },
    },
    employment: {
      employment: {
        type: 'Checkbox',
        options: [
          'fullTime',
          'partTime',
          'student',
          'sickLeave',
          'unemployed',
          'retired',
          'militaryService',
          'stayAtHome',
          'unknown',
        ],
        otherProps: {
          dependentFieldsList: () => [
            'occupation',
            'partTimeHours',
            'sickLeaveDays',
            'unemploymentDays',
            'retirementType',
            'workAbilityIndex.selfAssessmentAbilityToWorkIn2Years',
            'wantsOccupationalHealthContact',
          ],
        },
      },
      occupation: {
        type: 'Radio',
        options: ['employee', 'entrepreneur', 'farmer', 'freelancer', 'noProperOccupation', 'unknown'],
      },
      partTimeHours: {
        type: 'Radio',
        options: ['-', '<20', '20-30', '31-35', '36-40', '>40', 'unknown'],
      },
      sickLeaveDays: {
        type: 'NumberField',
        placeholder: 'background.days',
      },
      unemploymentDays: {
        type: 'NumberField',
        placeholder: 'background.days',
      },
      retirementType: {
        type: 'Radio',
        options: ['disabilityPension', 'partialPension', 'pension', 'unemploymentPension'],
        dependentFieldsList: () => ['workAbilityScore.workAbilityComparedToLifetimeBest'],
      },
    },
    workAbility: {
      'workAbilityScore.workAbilityComparedToLifetimeBest': {
        type: 'NumberField',
        placeholder: '0-10',
        otherProps: {
          min: 0,
          max: 10,
        },
      },
      'workAbilityScore.reductionReason': {
        type: 'TextArea',
      },
      'workAbilityScore.reductionType': {
        type: 'Radio',
        options: ['temporary', 'permanent', 'unknown'],
      },
      'workAbilityIndex.selfAssessmentAbilityToWorkIn2Years': {
        type: 'Radio',
        options: ['1', '4', '7'],
      },
      wantsOccupationalHealthContact: {
        type: 'Radio',
        options: ['yes', 'no'],
      },
    },
  },
};
