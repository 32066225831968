import * as React from 'react';

const svgImage = (): React.JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,8.04449,795.736)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-57.1901,677.327)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-101.469,556.918)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-145.269,799.018)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-101.796,707.218)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-82.1507,620.051)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(2.8135e-17,-0.459479,0.678184,4.15268e-17,-245.845,672.354)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-197.298,807.921)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(1.58359e-17,-0.25862,0.25862,1.58359e-17,-27.9579,494.277)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(-1.04411,-0.46713,0.226224,-0.505648,774,943.854)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.432646,-0.797745,0.131369,0.071246,-209.616,586.826)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(-0.46093,-1.2525,0.745338,-0.27429,-17.7896,1143.88)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.186255,-0.822347,0.735165,0.166509,-441.949,595.013)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(-0.659772,-1.16014,0.746197,-0.424362,167.185,1313.69)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
  </svg>
);

export default svgImage;
