import * as React from 'react';

const svgImage = (): React.JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-22.2181,785.972)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-84.9831,680.977)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-37.5563,656.797)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-26.0611,563.705)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,39.0258,556.46)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-175.532,789.254)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-74.5722,754.419)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-120.368,674.528)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(2.8135e-17,-0.459479,0.678184,4.15268e-17,-276.107,662.59)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(3.87162e-17,-0.632284,0.632284,3.87162e-17,-227.561,798.156)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(1.58359e-17,-0.25862,0.25862,1.58359e-17,-58.2204,484.513)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.494762,1.47706,0.525263,-0.175945,-527.836,-650.413)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(-0.559452,1.03156,0.131369,0.071246,573.712,-713.285)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(-0.900111,0.584391,0.0813789,0.125344,921.746,-442.386)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.593812,-1.19525,0.800231,0.397564,-718.284,629.88)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.791063,-1.07492,0.642087,0.472532,-676.248,479.242)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(-0.429597,-0.725529,0.900311,-0.533088,-166.911,1089.43)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(-0.657936,-1.16118,0.852367,-0.482959,57.387,1352.22)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(-0.195227,-1.32027,0.843949,-0.124794,-210.777,1182.83)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
  </svg>
);

export default svgImage;
