import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { styled } from '@mui/system';
import { formatPartialDate, ssnAge } from 'neuro-utils';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector as useSelector } from 'Store/index';

import { fieldNameToCodeObject, makeMeasurementsData, getFields } from 'Routes/Background/utils';

import colors from '../../../../config/theme/colors';
import ValueCell from './ValueCell';
import EditDialog from './EditDialog';
import InfoPopover from 'Components/InfoPopover';
import { IParkinsonMobileBackground, IMgravisBackground } from 'neuro-schemas';

const Table = styled('table')(({ theme }) => ({
  width: '100%',
  fontSize: '1.4rem',
  color: theme.palette.grey[700],
  border: 0,
  borderCollapse: 'collapse' as const,
}));
const TableBody = styled('tbody')(({ theme }) => ({
  'tr:nth-of-type(odd)': {
    backgroundColor: theme?.palette.grey[500] + 22,
  },
}));
const TableRow = styled('tr')({});
const TableHeaderCell = styled('td', {
  shouldForwardProp: (prop) => prop !== 'isHeader' && prop !== 'bottomBorder',
})(({ isHeader, bottomBorder, theme }: { isHeader?: boolean; bottomBorder?: boolean; theme?: Theme }) => ({
  width: isHeader ? '20%' : '16%', //
  textAlign: 'left' as const,
  fontWeight: 400,
  border: 0,
  borderBottom: bottomBorder ? `1px solid ${theme?.palette.grey[700]}` : 0,
  padding: '0.6rem',
}));
const TableCell = styled('td')(() => ({
  width: '20%',
  padding: '0.6rem',
}));

const Arrow = ({
  direction,
  disabled = false,
  onClick,
}: {
  direction: 'left' | 'right';
  disabled: boolean;
  onClick: () => void;
}) => (
  <div
    style={{ display: 'flex', justifyContent: 'center', width: '2rem', ...(!disabled ? { cursor: 'pointer' } : {}) }}
    onClick={!disabled ? onClick : undefined}
  >
    {direction === 'left' ? (
      <ArrowLeft style={{ color: disabled ? colors.lightGray : colors.darkGray }} fontSize="medium" />
    ) : (
      <ArrowRight style={{ color: disabled ? colors.lightGray : colors.darkGray }} fontSize="medium" />
    )}
  </div>
);

const CareTableHistory = ({ documents, myDocuments }: ICareTableHistory) => {
  const platform = useSelector((s: IState) => s.session.platforms?.selected);

  const { formatMessage } = useIntl();
  const fm = (id?: string, values?: Record<string, string>) => (id ? formatMessage({ id }, values) : '');

  const [startIndex, setStartIndex] = useState<number>(0);
  const [editingDialogDocuments, setEditingDialogDocuments] = useState<
    | Partial<IMeasurement>
    | IMeasurement[]
    | ((IParkinsonMobileBackground | ISRMobileBackground | IMgravisBackground) & IControlProps)
    | Array<(IParkinsonMobileBackground | ISRMobileBackground | IMgravisBackground) & IControlProps>
    | null
  >(null);

  const cancelEditing = () => setEditingDialogDocuments(null);
  const setDialogDocs = (
    documents:
      | IMeasurement[]
      | Partial<IMeasurement>
      | ((IParkinsonMobileBackground | ISRMobileBackground | IMgravisBackground) & IControlProps)
      | null,
  ) => {
    setEditingDialogDocuments(documents);
  };

  const data = makeMeasurementsData(documents, myDocuments, platform || '');

  const numberOfDocsThatCanFit = 5;
  const inScopeDocs = data.slice(startIndex, startIndex + numberOfDocsThatCanFit);
  const inScopeDates = inScopeDocs.map((d) => d.date);

  const canGoForward = data.length > numberOfDocsThatCanFit && startIndex <= data.length - 1 - numberOfDocsThatCanFit;
  const canGoBackwards = startIndex > 0;

  const ssn = useSelector((s: IState) => s.patient.data?.ssn);
  const patientAgeInYears = ssn ? ssnAge(ssn) ?? 0 : 0;
  if (!platform) return null;

  return (
    <>
      <Table>
        <thead>
          <TableRow>
            {/* Empty cell with arrow */}
            <TableHeaderCell isHeader bottomBorder>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Arrow direction="left" disabled={!canGoBackwards} onClick={() => setStartIndex(startIndex - 1)} />
              </div>
            </TableHeaderCell>
            {/* Dates */}
            <TableHeaderCell bottomBorder>{formatPartialDate(inScopeDates[0])}</TableHeaderCell>
            <TableHeaderCell bottomBorder>{formatPartialDate(inScopeDates[1])}</TableHeaderCell>
            <TableHeaderCell bottomBorder>{formatPartialDate(inScopeDates[2])}</TableHeaderCell>
            <TableHeaderCell bottomBorder>{formatPartialDate(inScopeDates[3])}</TableHeaderCell>
            <TableHeaderCell bottomBorder>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>{formatPartialDate(inScopeDates[4])}</div>
                <Arrow direction="right" disabled={!canGoForward} onClick={() => setStartIndex(startIndex + 1)} />
              </div>
            </TableHeaderCell>
          </TableRow>
        </thead>

        <TableBody>
          {getFields(platform).map((field) => (
            <TableRow key={field}>
              {/* Row headers */}
              <TableCell style={{ fontSize: '1.3rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {`${fm('background.' + fieldNameToCodeObject(field)?.name)}`}
                  {fieldNameToCodeObject(field)?.unit && ` (${fieldNameToCodeObject(field)?.unit})`}
                  {patientAgeInYears >= 18 && fieldNameToCodeObject(field)?.name === 'bmi' ? (
                    <span style={{ marginLeft: '1rem' }}>
                      <InfoPopover
                        color="primary"
                        text={
                          <FormattedMessage
                            id={'background.bmiInfo'}
                            values={{
                              strong: (chunks) => <div style={{ fontWeight: '600' }}>{chunks}</div>,
                              br: <br />,
                            }}
                          />
                        }
                      />
                    </span>
                  ) : null}
                </div>
              </TableCell>
              {/* Values */}
              <ValueCell
                index={0}
                inScopeDates={inScopeDates}
                fieldData={fieldNameToCodeObject(field)}
                data={data}
                setDialogDocs={setDialogDocs}
              />
              <ValueCell
                index={1}
                inScopeDates={inScopeDates}
                fieldData={fieldNameToCodeObject(field)}
                data={data}
                setDialogDocs={setDialogDocs}
              />
              <ValueCell
                index={2}
                inScopeDates={inScopeDates}
                fieldData={fieldNameToCodeObject(field)}
                data={data}
                setDialogDocs={setDialogDocs}
              />
              <ValueCell
                index={3}
                inScopeDates={inScopeDates}
                fieldData={fieldNameToCodeObject(field)}
                data={data}
                setDialogDocs={setDialogDocs}
              />
              <ValueCell
                index={4}
                inScopeDates={inScopeDates}
                fieldData={fieldNameToCodeObject(field)}
                data={data}
                setDialogDocs={setDialogDocs}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditDialog
        editingDialogDocuments={editingDialogDocuments}
        cancelEditing={cancelEditing}
        confirmEdit={setDialogDocs}
      />
    </>
  );
};

interface ICareTableHistory {
  documents: Array<IMeasurement>;
  myDocuments: Array<(IParkinsonMobileBackground | ISRMobileBackground | IMgravisBackground) & IControlProps>;
}

export default CareTableHistory;
