import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { styled } from '@mui/system';

import FormRow from '../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import {
  numberOfSupprotiveBiomarkers,
  numberOfSupprotiveClinicalFeatures,
  possibleDLBCanBeDiagnosed,
  probableDLBCanBeDiagnosed,
  setPossibleDLBAnswerA,
  setPossibleDLBAnswerB,
  setProbableDLBAnswerA,
  setProbableDLBAnswerB,
} from '../../../utils/DLB';
import { fm as formatterFM } from 'Components/FormatMessage';
import { ReferenceText, SubHeader } from '../../../utils/styled';

const optionFormatter = (s: string): React.JSX.Element => formatterFM('general.' + s);

const OrHeader = styled('div')({
  fontStyle: 'italic',
  fontSize: '1.8rem',
  margin: '2rem 0',
});

const formFields = [
  'fluctuatingCognitionWithPronouncedVariationsInAttentionAndAlertness',
  'recurrentVisualHallucinationsThatAreTypicallyWellFormedAndDetailed',
  'remSleepBehaviourDisorderWhichMayPrecedeCognitiveDecline',
  'oneOrMoreSpontaneousCardinalFeaturesOfParkinsonism',
  'reducedDopamineTransporterUptakeInBasalGangliaDemonstratedBySPECTorPET',
  'abnormalIodineMIBGMyocardialScintigraphy',
  'polysomnographicConfirmationOfREMsleepWithoutAtonia',
  'severeSensitivityToAntipsycoticAgents',
  'posturalInstability',
  'repeatedFalls',
  'syncopeOrOtherTransientEpisodesOfUnresponsiveness',
  'severeAutonomicDysfunction',
  'hypersomnia',
  'hyposmia',
  'hallucinationsInOtherModalities',
  'systematizedDelusions',
  'apathyAnxietyAndDepression',
  'relativePreservationOfMedicalTemporalLobeStructuresOnCTMRIScan',
  'generalizedLowUptakeOnSPECTPETperfusionMetabolismScanWithReducedOccipitalActivity',
  'prominentPosteriorSlowWaveActivityOnEEGwithPeriodicFluctuationsInThePreAlphaThetaRange',
  'inThePresenceOfAnyOtherPhysicalIllnessOrBrainDisorderIncludingCerebrovascularDisease',
  'ifParkinsonianFeaturesAreTheOnlyCoreClinicalFeatureAndAppear',
];

const setAllUnknown = (formData: IFormContext['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData?.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  bottomMargin = true,
}: {
  title: string;
  description?: React.JSX.Element;
  name: string;
  formData: IFormContext['formData'];
  viewing: boolean;
  bottomMargin?: boolean;
}): React.JSX.Element => (
  <FormRow title={title} description={description} headerWidth={6} bottomMargin={bottomMargin}>
    <div id={name + 'Cypress'}>
      <InputHandler
        editing={!viewing}
        formData={formData}
        name={name}
        type="RadioButtonRow"
        preset="yesNoUnknown"
        height={3.5}
        width={9}
      />
    </div>
  </FormRow>
);

const DLBForm = ({
  documents,
  formData,
  editing,
  view,
  fm = (): string => '',
}: IFormContext<IDLB>): React.JSX.Element => {
  const isViewing = !editing && !!view?.viewing;
  const docID = editing ?? view?.viewing;

  const [probableAanswer, setProbableAanswer] = React.useState<string>('unknown');
  const [probableBanswer, setprobableBanswer] = React.useState<string>('unknown');
  const [possibleAanswer, setPossibleAanswer] = React.useState<string>('unknown');
  const [possibleBanswer, setPossibleBanswer] = React.useState<string>('unknown');
  const [probable, setProbable] = React.useState<string>('unknown');
  const [possible, setPossible] = React.useState<string>('unknown');
  const [numberOfSupprotiveClinicalFeaturesYes, setNumberOfSupprotiveClinicalFeaturesYes] = React.useState<number>(0);
  const [numberOfSupprotiveClinicalFeaturesNo, setNumberOfSupprotiveClinicalFeaturesNo] = React.useState<number>(0);
  const [numberOfSupprotiveClinicalFeaturesUnknown, setNumberOfSupprotiveClinicalFeaturesUnknown] =
    React.useState<number>(0);
  const [numberOfSupprotiveBiomarkersYes, setNumberOfSupprotiveBiomarkersYes] = React.useState<number>(0);
  const [numberOfSupprotiveBiomarkersNo, setNumberOfSupprotiveBiomarkersNo] = React.useState<number>(0);
  const [numberOfSupprotiveBiomarkersUnknown, setNumberOfSupprotiveBiomarkersUnknown] = React.useState<number>(0);

  React.useEffect(() => {
    const doc = formData?.document;
    setProbableAanswer(setProbableDLBAnswerA(doc));
    setprobableBanswer(setProbableDLBAnswerB(doc));
    setPossibleAanswer(setPossibleDLBAnswerA(doc));
    setPossibleBanswer(setPossibleDLBAnswerB(doc));
    setProbable(probableDLBCanBeDiagnosed(doc));
    setPossible(possibleDLBCanBeDiagnosed(doc));
    setNumberOfSupprotiveClinicalFeaturesYes(numberOfSupprotiveClinicalFeatures(doc, 'yes'));
    setNumberOfSupprotiveClinicalFeaturesNo(numberOfSupprotiveClinicalFeatures(doc, 'no'));
    setNumberOfSupprotiveClinicalFeaturesUnknown(numberOfSupprotiveClinicalFeatures(doc, 'unknown'));
    setNumberOfSupprotiveBiomarkersYes(numberOfSupprotiveBiomarkers(doc, 'yes'));
    setNumberOfSupprotiveBiomarkersNo(numberOfSupprotiveBiomarkers(doc, 'no'));
    setNumberOfSupprotiveBiomarkersUnknown(numberOfSupprotiveBiomarkers(doc, 'unknown'));
  }, [formData?.document]);

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      {formData ? (
        <React.Fragment>
          <FormRow title="general.date">
            <InputHandler
              type="PartialDate"
              editing={!isViewing}
              name="date"
              formData={formData}
              dateDefault="now"
              isNotCancellable={true}
            />
          </FormRow>

          <Container>
            <Item>
              {fm('diagnosis.dlb.Essential')}
              {fm('diagnosis.dlb.description')}
            </Item>
          </Container>

          <div style={{ paddingBottom: '3rem' }} />

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={optionFormatter}
            header="diagnosis.dlb.coreClinicalFeatures.title"
            hideCopyButton={isViewing}
          >
            {{
              headerCoreClinicalFeature: {
                header: <SubHeader>{fm('diagnosis.dlb.coreClinicalFeatures.headerCoreClinicalFeature')}</SubHeader>,
              },
              fluctuatingCognitionWithPronouncedVariationsInAttentionAndAlertness: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.coreClinicalFeatures.fluctuatingCognitionWithPronouncedVariationsInAttentionAndAlertness"
                    name="fluctuatingCognitionWithPronouncedVariationsInAttentionAndAlertness"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              recurrentVisualHallucinationsThatAreTypicallyWellFormedAndDetailed: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.coreClinicalFeatures.recurrentVisualHallucinationsThatAreTypicallyWellFormedAndDetailed"
                    name="recurrentVisualHallucinationsThatAreTypicallyWellFormedAndDetailed"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              remSleepBehaviourDisorderWhichMayPrecedeCognitiveDecline: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.coreClinicalFeatures.remSleepBehaviourDisorderWhichMayPrecedeCognitiveDecline"
                    name="remSleepBehaviourDisorderWhichMayPrecedeCognitiveDecline"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              oneOrMoreSpontaneousCardinalFeaturesOfParkinsonism: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.coreClinicalFeatures.oneOrMoreSpontaneousCardinalFeaturesOfParkinsonism"
                    name="oneOrMoreSpontaneousCardinalFeaturesOfParkinsonism"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={optionFormatter}
            header="diagnosis.dlb.indicativeBiomarkers.title"
            hideCopyButton={isViewing}
          >
            {{
              reducedDopamineTransporterUptakeInBasalGangliaDemonstratedBySPECTorPET: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.indicativeBiomarkers.reducedDopamineTransporterUptakeInBasalGangliaDemonstratedBySPECTorPET"
                    name="reducedDopamineTransporterUptakeInBasalGangliaDemonstratedBySPECTorPET"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              abnormalIodineMIBGMyocardialScintigraphy: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.indicativeBiomarkers.abnormalIodineMIBGMyocardialScintigraphy"
                    name="abnormalIodineMIBGMyocardialScintigraphy"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              polysomnographicConfirmationOfREMsleepWithoutAtonia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.indicativeBiomarkers.polysomnographicConfirmationOfREMsleepWithoutAtonia"
                    name="polysomnographicConfirmationOfREMsleepWithoutAtonia"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={optionFormatter}
            header="diagnosis.dlb.probableDLB.title"
            hideCopyButton={isViewing}
          >
            {{
              twoOrMoreClinicalFeaturesOfDLBarePresentWithOrWithoutThePresenceOfIndicativeBiomarkers: {
                header: (
                  <Container id={'probableDLBa'}>
                    <Item xs={5} style={{ paddingRight: '2rem' }}>
                      {fm(
                        'diagnosis.dlb.probableDLB.twoOrMoreClinicalFeaturesOfDLBarePresentWithOrWithoutThePresenceOfIndicativeBiomarkers',
                      )}
                    </Item>
                    <Item style={{ fontWeight: 600 }}>{fm(`general.${probableAanswer}`)}</Item>
                  </Container>
                ),
              },
              or: {
                header: <OrHeader>{fm('diagnosis.dlb.or')}</OrHeader>,
              },
              onlyOneCoreClinicalFeaturesIsPresent: {
                header: (
                  <Container style={{ marginBottom: '4.5rem' }} id={'probableDLBb'}>
                    <Item xs={5} style={{ paddingRight: '2rem' }}>
                      {fm('diagnosis.dlb.probableDLB.onlyOneCoreClinicalFeaturesIsPresent')}
                    </Item>
                    <Item style={{ fontWeight: 600 }}>{fm(`general.${probableBanswer}`)}</Item>
                  </Container>
                ),
              },
              probableDLBbiomarkers: {
                header: (
                  <Container>
                    <Item style={{ fontWeight: 600 }}>{fm('diagnosis.dlb.probableDLBbiomarkers')}</Item>
                  </Container>
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={optionFormatter}
            header="diagnosis.dlb.possibleDLB.title"
            hideCopyButton={isViewing}
          >
            {{
              onlyOneCoreClinicalFeatureOfDLBisPresent: {
                header: (
                  <Container id={'possibleDLBa'}>
                    <Item xs={5} style={{ paddingRight: '2rem' }}>
                      {fm('diagnosis.dlb.possibleDLB.onlyOneCoreClinicalFeatureOfDLBisPresent')}
                    </Item>
                    <Item style={{ fontWeight: 600 }}>{fm(`general.${possibleAanswer}`)}</Item>
                  </Container>
                ),
              },
              or: {
                header: <OrHeader>{fm('diagnosis.dlb.or')}</OrHeader>,
              },
              oneOrMoreIndicativeBiomarkersIsPresentButThereIsNoCoreClinicalFeatures: {
                header: (
                  <Container id={'possibleDLBb'}>
                    <Item xs={5} style={{ paddingRight: '2rem' }}>
                      {fm(
                        'diagnosis.dlb.possibleDLB.oneOrMoreIndicativeBiomarkersIsPresentButThereIsNoCoreClinicalFeatures',
                      )}
                    </Item>
                    <Item style={{ fontWeight: 600 }}>{fm(`general.${possibleBanswer}`)}</Item>
                  </Container>
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={optionFormatter}
            header="diagnosis.dlb.supportiveClinicalFeature.title"
            hideCopyButton={isViewing}
          >
            {{
              severeSensitivityToAntipsycoticAgents: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.severeSensitivityToAntipsycoticAgents"
                    name="severeSensitivityToAntipsycoticAgents"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              posturalInstability: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.posturalInstability"
                    name="posturalInstability"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              repeatedFalls: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.repeatedFalls"
                    name="repeatedFalls"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              syncopeOrOtherTransientEpisodesOfUnresponsiveness: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.syncopeOrOtherTransientEpisodesOfUnresponsiveness"
                    name="syncopeOrOtherTransientEpisodesOfUnresponsiveness"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              severeAutonomicDysfunction: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.severeAutonomicDysfunction"
                    name="severeAutonomicDysfunction"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              hypersomnia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.hypersomnia"
                    name="hypersomnia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              hyposmia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.hyposmia"
                    name="hyposmia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              hallucinationsInOtherModalities: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.hallucinationsInOtherModalities"
                    name="hallucinationsInOtherModalities"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              systematizedDelusions: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.systematizedDelusions"
                    name="systematizedDelusions"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              apathyAnxietyAndDepression: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveClinicalFeature.apathyAnxietyAndDepression"
                    name="apathyAnxietyAndDepression"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={optionFormatter}
            header="diagnosis.dlb.supportiveBiomarkers.title"
            hideCopyButton={isViewing}
          >
            {{
              relativePreservationOfMedicalTemporalLobeStructuresOnCTMRIScan: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveBiomarkers.relativePreservationOfMedicalTemporalLobeStructuresOnCTMRIScan"
                    name="relativePreservationOfMedicalTemporalLobeStructuresOnCTMRIScan"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              generalizedLowUptakeOnSPECTPETperfusionMetabolismScanWithReducedOccipitalActivity: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveBiomarkers.generalizedLowUptakeOnSPECTPETperfusionMetabolismScanWithReducedOccipitalActivity"
                    name="generalizedLowUptakeOnSPECTPETperfusionMetabolismScanWithReducedOccipitalActivity"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              prominentPosteriorSlowWaveActivityOnEEGwithPeriodicFluctuationsInThePreAlphaThetaRange: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.supportiveBiomarkers.prominentPosteriorSlowWaveActivityOnEEGwithPeriodicFluctuationsInThePreAlphaThetaRange"
                    name="prominentPosteriorSlowWaveActivityOnEEGwithPeriodicFluctuationsInThePreAlphaThetaRange"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={optionFormatter}
            header="diagnosis.dlb.DLBisLessLikely.title"
            hideCopyButton={isViewing}
          >
            {{
              inThePresenceOfAnyOtherPhysicalIllnessOrBrainDisorderIncludingCerebrovascularDisease: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.DLBisLessLikely.inThePresenceOfAnyOtherPhysicalIllnessOrBrainDisorderIncludingCerebrovascularDisease"
                    name="inThePresenceOfAnyOtherPhysicalIllnessOrBrainDisorderIncludingCerebrovascularDisease"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
              or: {
                header: <OrHeader>{fm('diagnosis.dlb.or')}</OrHeader>,
              },
              ifParkinsonianFeaturesAreTheOnlyCoreClinicalFeatureAndAppear: {
                element: (
                  <MakeFormRow
                    title="diagnosis.dlb.DLBisLessLikely.ifParkinsonianFeaturesAreTheOnlyCoreClinicalFeatureAndAppear"
                    name="ifParkinsonianFeaturesAreTheOnlyCoreClinicalFeatureAndAppear"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <Container>
            <Item style={{ marginBottom: '3rem' }}>{fm('diagnosis.dlb.dlbShouldBeDiagnosed')}</Item>
          </Container>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={optionFormatter}
            header="diagnosis.dlb.summary.title"
            hideCopyButton={true}
          >
            {{
              probableDLBCanBeDiagnosed: {
                header: (
                  <Container id={'probableDLBcanBeDiagnosed'}>
                    <Item xs={5} style={{ fontWeight: 400, paddingRight: '1rem' }}>
                      {fm('diagnosis.dlb.summary.probableDLBCanBeDiagnosed')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', fontWeight: 600 }}>{fm(`general.${probable}`)}</Item>
                  </Container>
                ),
              },
              possibleDLBCanBeDiagnosed: {
                header: (
                  <Container id={'possibleDLBcanBeDiagnosed'}>
                    <Item xs={5} style={{ fontWeight: 400, paddingRight: '1rem' }}>
                      {fm('diagnosis.dlb.summary.possibleDLBCanBeDiagnosed')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', fontWeight: 600 }}>{fm(`general.${possible}`)}</Item>
                  </Container>
                ),
              },
              numberOfSupportiveClinicalFeatures: {
                header: (
                  <Container>
                    <Item xs={5} style={{ paddingRight: '1rem' }}>
                      <SubHeader>{fm('diagnosis.dlb.summary.numberOfSupportiveClinicalFeatures')}</SubHeader>
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportiveClinicalFeaturesYes: {
                element: (
                  <Container id={'numberOfSupportiveClinicalFeaturesYes'}>
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.yes')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfSupprotiveClinicalFeaturesYes + ' / 10'}
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportiveClinicalFeaturesNo: {
                element: (
                  <Container id={'numberOfSupportiveClinicalFeaturesNo'}>
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.no')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfSupprotiveClinicalFeaturesNo + ' / 10'}
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportiveClinicalFeaturesUnknown: {
                element: (
                  <Container id={'numberOfSupportiveClinicalFeaturesUnknown'}>
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.unknown')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfSupprotiveClinicalFeaturesUnknown + ' / 10'}
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportiveBiomarkers: {
                header: (
                  <Container>
                    <Item xs={5} style={{ paddingRight: '1rem' }}>
                      <SubHeader>{fm('diagnosis.dlb.summary.numberOfSupportiveBiomarkers')}</SubHeader>
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportiveBiomarkersYes: {
                element: (
                  <Container id={'numberOfSupportiveBiomarkersYes'}>
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.yes')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfSupprotiveBiomarkersYes + ' / 3'}
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportiveBiomarkersNo: {
                element: (
                  <Container id={'numberOfSupportiveBiomarkersNo'}>
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.no')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfSupprotiveBiomarkersNo + ' / 3'}
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportiveBiomarkersUnknown: {
                element: (
                  <Container id={'numberOfSupportiveBiomarkersUnknown'}>
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.unknown')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfSupprotiveBiomarkersUnknown + ' / 3'}
                    </Item>
                  </Container>
                ),
              },
              dbsIsLessLikelySummary: {
                header: (
                  <Container>
                    <Item xs={5} style={{ paddingRight: '1rem' }}>
                      <SubHeader>{fm('diagnosis.dlb.summary.DLBIsLessLikely.title')}</SubHeader>
                    </Item>
                  </Container>
                ),
              },
              becauseOfThePresenceOfOtherPhysicalIllness: {
                element: (
                  <Container id={'becauseOfThePresenceOfOtherPhysicalIllness'}>
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('diagnosis.dlb.summary.DLBIsLessLikely.becauseOfThePresenceOfOtherPhysicalIllness')}
                    </Item>
                    <Item style={{ marginLeft: '6rem', fontWeight: 600 }}>
                      {formData.document
                        .inThePresenceOfAnyOtherPhysicalIllnessOrBrainDisorderIncludingCerebrovascularDisease
                        ? fm(
                            `general.${formData.document.inThePresenceOfAnyOtherPhysicalIllnessOrBrainDisorderIncludingCerebrovascularDisease}`,
                          )
                        : '-'}
                    </Item>
                  </Container>
                ),
              },
              becauseParkinsonianFeaturesAreTheOnlyCoreClinicalFeature: {
                element: (
                  <Container id={'becauseParkinsonianFeaturesAreTheOnlyCoreClinicalFeature'}>
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem', marginTop: '2rem' }}>
                      {fm(
                        'diagnosis.dlb.summary.DLBIsLessLikely.becauseParkinsonianFeaturesAreTheOnlyCoreClinicalFeature',
                      )}
                    </Item>
                    <Item style={{ paddingTop: '2rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {formData.document.ifParkinsonianFeaturesAreTheOnlyCoreClinicalFeatureAndAppear
                        ? fm(
                            `general.${formData.document.ifParkinsonianFeaturesAreTheOnlyCoreClinicalFeatureAndAppear}`,
                          )
                        : '-'}
                    </Item>
                  </Container>
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <Container>
            <Item style={{ padding: '2rem 0' }}>
              <ReferenceText>{fm('diagnosis.dlb.references')}</ReferenceText>
            </Item>
          </Container>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default withFormContext(DLBForm);
