import * as React from 'react';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { optionFormatter } from '../../../utils/functions';

const SMAform = ({ editing, formData }: IOwnProps): React.JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="diagnosis.symptomOnsetDate">
        <InputHandler
          type="PartialDate"
          editing={!!editing}
          name="symptomOnsetDate"
          formData={formData}
          dateDefault="now"
        />
      </FormRow>
      <FormSection header="diagnosis.sma">
        <FormRow title="diagnosis.smaType">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="smaType"
            formData={formData}
            options={['1', '2', '3']}
            disabledOptions={(formData.document as IDiagnosis)?.diagnosis === 'G12.0' ? ['2', '3'] : ['1']}
          />
        </FormRow>
        <FormRow title="diagnosis.smaGeneticConfirmation">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="smaGeneticConfirmation"
            formData={formData}
            preset={'yesno'}
          />
        </FormRow>
      </FormSection>
      <FormSection
        header="diagnosis.smaGeneticConfirmationTitle"
        condition={(formData.document as IDiagnosis).smaGeneticConfirmation === true}
      >
        <FormRow title="diagnosis.smaGeneticScreened" id={'geneticScreened'}>
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="smaGeneticScreened"
            formData={formData}
            preset={'yesno'}
          />
        </FormRow>
        <FormRow title="diagnosis.smaGeneticDate">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="smaGeneticDate"
            formData={formData}
            dateDefault="now"
          />
        </FormRow>
        <FormRow title="diagnosis.smaSMN1MutationName.title">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="smaSMN1MutationName"
            formData={formData}
            options={['SMN1homozygousdeletion', 'SMN1heterozygousdeletion', 'Compoundheterozygous']}
            optionFormatter={optionFormatter('smaSMN1MutationName.opts')}
          />
        </FormRow>
        <FormRow title="diagnosis.smaSMN1TestingMethod.title">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="smaSMN1TestingMethod"
            formData={formData}
            options={['RFLP', 'HRM', 'MLPA', 'LuminexGenotyping', 'qrtPCR', 'ddPCR', 'Other', 'Unknown']}
            optionFormatter={optionFormatter('smaSMN1TestingMethod.opts')}
          />
        </FormRow>
        <FormRow
          title="diagnosis.smaTestingMethodOther"
          condition={(formData.document as IDiagnosis).smaSMN1TestingMethod === 'Other'}
        >
          <InputHandler
            type="TextField"
            editing={!!editing}
            name="smaSMN1TestingMethodOther"
            formData={formData}
            placeholder="diagnosis.smaTestingMethodOtherPlaceholder"
          />
        </FormRow>
        <FormRow title="diagnosis.smaSMN2CopyNumberTested" id={'smn2Tested'}>
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="smaSMN2CopyNumberTested"
            formData={formData}
            preset={'yesno'}
          />
        </FormRow>
        {(formData.document as IDiagnosis).smaSMN2CopyNumberTested === true ? (
          <React.Fragment>
            <FormRow title="diagnosis.smaSMN2TestingMethod.title" id={'smn2TestingMethod'}>
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="smaSMN2TestingMethod"
                formData={formData}
                options={['RFLP', 'HRM', 'MLPA', 'LuminexGenotyping', 'qrtPCR', 'ddPCR', 'Other', 'Unknown']}
                optionFormatter={optionFormatter('smaSMN2TestingMethod.opts')}
              />
            </FormRow>
            <FormRow
              title="diagnosis.smaTestingMethodOther"
              condition={(formData.document as IDiagnosis).smaSMN2TestingMethod === 'Other'}
            >
              <InputHandler
                type="TextField"
                editing={!!editing}
                name="smaSMN2TestingMethodOther"
                formData={formData}
                placeholder="diagnosis.smaTestingMethodOtherPlaceholder"
              />
            </FormRow>
            <FormRow title="diagnosis.smaSMN2CopyNumber" id={'smn2CopyNumber'}>
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="smaSMN2CopyNumber"
                formData={formData}
                options={['0', '1', '2', '3', '4+']}
              />
            </FormRow>
          </React.Fragment>
        ) : undefined}
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<IDiagnosis>;
}

export default SMAform;
