import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import { mmseConfig, mmseTotalScore } from '../../../utils';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): React.JSX.Element => {
    return <FormattedMessage id={`cognition.mmse.${opt}.${name}`} />;
  };

const MMSEForm = ({ formData, viewing }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="cognition.unableToPerform">
      <InputHandler
        type="CheckboxSingle"
        editing={!viewing}
        name="unableToPerform"
        formData={formData}
        option={'true'}
        showLabel={false}
        optionFormatter={optionFormatter('unableToPerform')}
        dependentFieldsList={(): string[] => [
          'unableToPerformReason',
          'totalScoreOnly',
          'totalScore',
          'year',
          'season',
          'dayOfMonth',
          'dayOfWeek',
          'month',
          'country',
          'region',
          'city',
          'place',
          'floor',
          'words1',
          'substraction',
          'words2',
          'items',
          'repeatSentence',
          'paper',
          'text',
          'writeSentence',
          'draw',
        ]}
      />
    </FormRow>
    <FormRow title="general.reason" condition={formData.document.unableToPerform === true}>
      <InputHandler
        type="TextArea"
        name="unableToPerformReason"
        formData={formData}
        placeholder="general.reason"
        editing={!viewing}
        width={40}
      />
    </FormRow>
    {formData.document?.unableToPerform !== true ? (
      <>
        <FormRow title="cognition.totalScoreOnly">
          <InputHandler
            type="Checkbox"
            editing={!viewing}
            name="totalScoreOnly"
            formData={formData}
            options={['totalScoreOnly']}
            optionFormatter={(name: number | string): React.JSX.Element => (
              <FormattedMessage id={`cognition.${name}`} />
            )}
          />
        </FormRow>
        <FormRow title="cognition.download">
          <a href="/files/MMSE-Test-Finnish.pdf" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="cognition.downloadHere" />
          </a>
        </FormRow>
        {formData.document?.totalScoreOnly?.[0] === 'totalScoreOnly' ? (
          <FormSection>
            <FormRow title="cognition.totalScore">
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="totalScore"
                formData={formData}
                placeholder="cognition.totalScore"
                staticValue={formData.document.totalScore}
                min={0}
                max={30}
              />
            </FormRow>
          </FormSection>
        ) : (
          <>
            <FormSection>
              {mmseConfig.map((obj: { name: string; options: string[]; description: boolean }) => (
                <FormRow
                  title={`cognition.mmse.${obj.name}.title`}
                  description={
                    obj.description === true ? (
                      <FormattedMessage id={`cognition.mmse.${obj.name}.description`} />
                    ) : undefined
                  }
                  key={obj.name}
                >
                  <InputHandler
                    type="Checkbox"
                    editing={!viewing}
                    name={obj.name}
                    formData={formData}
                    options={obj.options}
                    optionFormatter={optionFormatter(obj.name)}
                  />
                </FormRow>
              ))}
            </FormSection>
            <FormSection>
              <FormRow title="cognition.totalScore">
                <div
                  style={{
                    fontSize: '2rem',
                    fontWeight: 600,
                    color: colors.primary,
                    marginRight: '2rem',
                    marginLeft: '2lem',
                  }}
                >
                  {mmseTotalScore(formData.document)}
                </div>
              </FormRow>
            </FormSection>
          </>
        )}
      </>
    ) : undefined}
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IMMSE>;
  viewing?: boolean;
}

export default MMSEForm;
