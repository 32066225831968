import FormRow from 'Components/FormRow';
import FormSectionBar from 'Components/FormSectionBar';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import * as React from 'react';
import { DoctorsOrdersContext } from '../..';
import Treatments from './Treatments';
import InputHistoryAcceptor from 'Components/InputHistoryAcceptor';
import { sortPartialDate } from 'neuro-utils';

const TDCSForm = ({ subjectOfOrder }: { subjectOfOrder: Array<string> }): React.JSX.Element => {
  const doctorsOrdersContext = React.useContext(DoctorsOrdersContext);
  const { formData, documents, isEditing, fm, ninmtTreatmentPeriods } = doctorsOrdersContext;

  const { document, onChange } = formData;
  const latesNinmtTreatmentPeriod = ninmtTreatmentPeriods?.sort((a, b) => sortPartialDate(b.date, a.date))[0];
  const doctorsOrdersDocsInCurrentTreatmentPeriod = documents.filter(
    (d) => sortPartialDate(d.date, latesNinmtTreatmentPeriod?.date) >= 0,
  );

  const isMountRender = React.useRef<boolean>(true);

  // Map subjectOfOrder values to "legacy" form display control values
  React.useEffect(() => {
    if (isMountRender.current) isMountRender.current = false;
    onChange?.({
      tdcs: {
        ...document.tdcs,
        ...{
          doesNotApplyToSubjectOfTreatment: !subjectOfOrder.includes('treatmentProtocolAndBackupSubjectsOfTreatment'),
          endTDCS: subjectOfOrder.includes('endTreatment'),
          backupSubjectOfTreatment: !subjectOfOrder.includes('treatmentProtocolAndBackupSubjectsOfTreatment')
            ? null
            : document.tdcs?.backupSubjectOfTreatment,
        },
      },
    });
  }, [subjectOfOrder]);

  return (
    <React.Fragment>
      <FormSectionBar header="doctorsOrders.tdcs.header" />

      <FormSection>
        {!document.tdcs?.endTDCS ? (
          <React.Fragment>
            {subjectOfOrder.includes('treatmentFrequency') && (
              <FormRow title="doctorsOrders.tdcs.treatmentFrequency.title">
                <InputHistoryAcceptor
                  documents={doctorsOrdersDocsInCurrentTreatmentPeriod}
                  type="TextArea"
                  name="tdcs.treatmentFrequency"
                  editing={isEditing}
                  formData={formData}
                  placeholder="doctorsOrders.tdcs.treatmentFrequency.placeholder"
                  customConfirmText="doctorsOrders.copyPrevFrequency"
                />
              </FormRow>
            )}
            {subjectOfOrder.includes('treatmentMonitoringFrequency') && (
              <FormRow title="doctorsOrders.tdcs.treatmentMonitoringFrequency.title">
                <InputHandler
                  type="Radio"
                  name="tdcs.treatmentMonitoringFrequency"
                  editing={isEditing}
                  formData={formData}
                  options={['1Week', '2Weeks', '4Weeks']}
                  optionFormatter={(o: string | number) =>
                    fm(`doctorsOrders.tdcs.treatmentMonitoringFrequency.opts.${o}`)
                  }
                />
              </FormRow>
            )}

            {!isMountRender.current && document.tdcs?.doesNotApplyToSubjectOfTreatment === false && (
              <Treatments formData={formData} />
            )}

            <FormRow
              title="doctorsOrders.tdcs.additionalInformation.title"
              description={fm('doctorsOrders.tdcs.additionalInformation.description')}
              condition={!document.tdcs?.doesNotApplyToSubjectOfTreatment}
            >
              <InputHandler
                type="TextArea"
                name="tdcs.additionalInformation"
                editing={isEditing}
                formData={formData}
                placeholder="doctorsOrders.tdcs.additionalInformation.placeholder"
              />
            </FormRow>
          </React.Fragment>
        ) : (
          <div style={{ marginBottom: '3.5rem', fontWeight: 600 }}>{fm('doctorsOrders.tdcs.endTDCS.title')}</div>
        )}

        {subjectOfOrder.includes('other') && (
          <FormRow title="doctorsOrders.tdcs.otherOrder.title">
            <InputHandler
              type="TextArea"
              name="tdcs.otherOrder"
              editing={isEditing}
              formData={formData}
              placeholder="doctorsOrders.tdcs.otherOrder.placeholder"
            />
          </FormRow>
        )}
      </FormSection>
    </React.Fragment>
  );
};

export default TDCSForm;
