import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import HistorySection from '../../../../components/HistorySection';
import { IHistoryContext, withHistoryContext } from '../../../../containers/FormContextHandler';
import { DocumentCreationButtonWithContext } from '../../utils';
import { TreatmentHistorySection } from './components';

const PatientDoesNotWantRespiratorySupportTherapy = ({
  documents = [],
  startEdit,
  view,
}: IHistoryContext<IPatientDoesNotWantRespiratorySupportTherapy>): React.JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="treatment.patientDoesNotWantRespiratorySupportTherapy.title" />}
    newButton={
      <DocumentCreationButtonWithContext
        name="patientDoesNotWantRespiratorySupportTherapy"
        text="treatment.patientDoesNotWantRespiratorySupportTherapy.newPatientDoesNotWantRespiratorySupportTherapy"
      />
    }
  >
    <TreatmentHistorySection
      documents={documents}
      startEdit={startEdit}
      type="patientDoesNotWantRespiratorySupportTherapy"
      view={view}
    />
  </HistorySection>
);

export default withHistoryContext(PatientDoesNotWantRespiratorySupportTherapy);
