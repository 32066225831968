import * as React from 'react';
import { Container, Item } from 'Components/Grid';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import InputHandler from 'Components/InputHandler';
import { styled } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import colors from '../../../../../../../../config/theme/colors';
import DosageFormat from '../DosageFormat';

const ClearIconStyled = styled(ClearIcon)({
  display: 'block',
  width: '3rem',
  color: colors.secondaryText,
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

/**
 * Component for drawing an upkeep dose elements.
 *
 *  Element contains input handlers for:
 *   - Upkeep dose value
 *   - Upkeep dose from time value (will be initialized with previous "upkeep dose to" time value)
 *   - Upkeep dose to time value
 *
 * @param { IUpkeepDoseProps } Properties
 * @returns { React.JSX.Element } Upkeep dose element
 */
const UpkeepDose = ({ d, i, onChange, deleteDosage, prevTime, intl, str }: IUpkeepDoseProps): React.JSX.Element => {
  const fm = (id: string): string => intl.formatMessage({ id: id });

  const onChangeDosesMlh = (values: TOnChangeValues): void => {
    const key = Object.keys(values)[0];
    if (typeof values[key] === 'number') (values[key] as number) *= str ?? 1;
    onChange(i)(values);
  };

  const transformToMlhValue = (mgh?: number): number | undefined =>
    mgh ? mgh / (typeof str === 'number' && str !== 0 ? str : 1) : undefined;

  return (
    <DosageFormat
      header={<FormattedMessage id="medication.regimenUpkeepDose" />}
      content={
        <Container alignItems="center">
          <Item xs={2}>
            <Container>
              <Item>
                <InputHandler
                  type="NumberField"
                  editing={true}
                  name="upkeepDose"
                  formData={{
                    onChange: onChangeDosesMlh,
                    document: { upkeepDose: transformToMlhValue(d.upkeepDose) || '' },
                  }}
                  width={7}
                  precision={2}
                />
              </Item>
              <Item style={{ padding: '0 1rem' }}>{fm('medication.regimenInfusion_mlPerHour')}</Item>
            </Container>
          </Item>
          <Item xs={1}>=</Item>
          <Item xs={2}>
            <Container>
              <Item>
                <InputHandler
                  type="NumberField"
                  editing={true}
                  name="upkeepDose"
                  formData={{
                    onChange: onChange(i),
                    document: { upkeepDose: d.upkeepDose || '' },
                  }}
                  width={7}
                  precision={2}
                />
              </Item>
              <Item xs={1} style={{ padding: '0 1rem' }}>
                {fm('medication.regimenInfusion_mgPerHour')}
              </Item>
            </Container>
          </Item>
          <Item style={{ padding: '0 1rem' }}>{fm('general.clock')}</Item>
          <Item>
            <InputHandler
              type="TimePicker"
              editing={true}
              name="upkeepDoseFrom"
              formData={{
                onChange: onChange(i),
                document: { upkeepDoseFrom: d.upkeepDoseFrom || '' },
              }}
              width={11}
              placeholder="general.space"
              isNotCancellable={true}
              timeDefault={i > 0 && prevTime ? prevTime : undefined}
            />
          </Item>
          <Item style={{ padding: '0 1rem' }}>{fm('medication.regimenInfusion_mgPerHourStarting')}</Item>
          <Item>
            <InputHandler
              type="TimePicker"
              editing={true}
              name="upkeepDoseTo"
              formData={{
                onChange: onChange(i),
                document: { upkeepDoseTo: d.upkeepDoseTo || '' },
              }}
              width={11}
              placeholder="general.space"
              isNotCancellable={true}
            />
          </Item>
          <Item style={{ padding: '0 1rem' }}>{fm('medication.regimenInfusion_mgPerHourEnding')}</Item>
          <Item>
            <ClearIconStyled onClick={() => deleteDosage(i)} />
          </Item>
        </Container>
      }
    />
  );
};

interface IUpkeepDoseProps {
  d: { upkeepDose?: number; upkeepDoseFrom?: Time; upkeepDoseTo?: Time };
  i: number;
  onChange: (i: number) => (values: TOnChangeValues) => void;
  deleteDosage: (i: number) => void;
  prevTime?: Time;
  intl: IntlShape;
  str?: number;
}

export default injectIntl(UpkeepDose);
