import * as React from 'react';
import {
  IContraIndicationsInquiry,
  isLocaleKey,
  contraIndicationsInquiry as ninmtContraIndicationsInquiry,
} from 'neuro-schemas';
import { MyServiceContext } from '../..';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import colors from '../../../../../config/theme/colors';

export const ContraIndicationsInquiry = (): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing } = myServContext;

  const formData = (editing?.data || viewing?.data) as IContraIndicationsInquiry & IControlProps;

  const contraIndicationsLocales = ninmtContraIndicationsInquiry.localizations;
  const contraIndicationsQuestions = ninmtContraIndicationsInquiry.surveyGenerator.questions();

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    if (field === 'medicalOperations' && (value === 'yes' || value === 'no' || value === undefined)) {
      setEditingData?.({
        ...formData,
        medicalOperations: value,
        rtmsContraIndications: undefined,
        tdcsContraIndications: undefined,
        healthChangeDetails: undefined,
      });
    } else if (field === 'rtmsContraIndications' && !formData.tdcsContraIndications?.includes('noneOfThese')) {
      setEditingData?.({
        ...formData,
        rtmsContraIndications: value as IContraIndicationsInquiry['rtmsContraIndications'],
        healthChangeDetails: undefined,
      });
    } else if (field === 'tdcsContraIndications' && !formData.rtmsContraIndications?.includes('noneOfThese')) {
      setEditingData?.({
        ...formData,
        tdcsContraIndications: value as IContraIndicationsInquiry['tdcsContraIndications'],
        healthChangeDetails: undefined,
      });
    } else {
      setEditingData?.({
        ...formData,
        [field]: value,
      });
    }
  };
  const useLocale = isLocaleKey(locale) ? locale : 'fi';
  const isEditing = !viewing && !!editing;

  return (
    <React.Fragment>
      <FormRow title={'general.date'} headerWidth={!editing ? 1 : undefined}>
        <InputHandler
          name="date"
          type="PartialDate"
          editing={isEditing}
          isNotCancellable={true}
          formData={{
            document: { date: formData?.date },
            onChange: onChangeSurveyForm,
          }}
          dateDefault="now"
        />
      </FormRow>
      {contraIndicationsQuestions.map((q, i) => {
        return (
          <>
            <FormRow
              title={
                q.id === 'medicalOperations'
                  ? fm('myService.ninmt.changesQuestionClinical')
                  : q.id === 'healthChangeDetails'
                    ? fm('myService.ninmt.healthChangeDetailsQuestionClinical')
                    : contraIndicationsLocales[useLocale][q.id]
              }
              formatTitle={false}
              key={`${q.id}-${i}`}
              condition={
                q.id === 'medicalOperations'
                  ? true
                  : formData.medicalOperations === 'yes'
                    ? q.id === 'healthChangeDetails'
                      ? formData.rtmsContraIndications?.includes('noneOfThese') ||
                        formData.tdcsContraIndications?.includes('noneOfThese')
                        ? true
                        : false
                      : undefined
                    : false
              }
            >
              <InputHandler
                name={q.id}
                type={q.type as 'Checkbox' | 'Radio' | 'TextArea'}
                options={
                  q.options && (q.type === 'Checkbox' || q.type === 'Radio') ? q.options.map((o) => o.id) : undefined
                }
                optionFormatter={
                  q.options && q.type === 'Checkbox'
                    ? (o: string | number) => contraIndicationsLocales[useLocale][`opts.${o}`]
                    : q.id === 'medicalOperations'
                      ? (o: string | number) => fm(`general.${o}`)
                      : undefined
                }
                formData={{
                  document: { [q.id]: formData?.[q.id as keyof IContraIndicationsInquiry] },
                  onChange: onChangeSurveyForm,
                }}
                editing={isEditing}
                placeholder={
                  q.id === 'healthChangeDetails'
                    ? contraIndicationsLocales[useLocale][q.questionLocale ?? q.id]
                    : undefined
                }
                maxLength={q.id === 'healthChangeDetails' ? 500 : undefined}
                disabledOptions={
                  q.id === 'rtmsContraIndications'
                    ? formData.rtmsContraIndications?.includes('noneOfThese')
                      ? q.options?.filter((o) => o.value !== 'noneOfThese').map((opt) => opt.value)
                      : formData.rtmsContraIndications && formData.rtmsContraIndications?.length > 0
                        ? ['noneOfThese']
                        : undefined
                    : q.id === 'tdcsContraIndications'
                      ? formData.tdcsContraIndications?.includes('noneOfThese')
                        ? q.options?.filter((o) => o.value !== 'noneOfThese').map((opt) => opt.value)
                        : formData.tdcsContraIndications && formData.tdcsContraIndications?.length > 0
                          ? ['noneOfThese']
                          : undefined
                      : undefined
                }
              />
            </FormRow>
            {formData.medicalOperations === 'yes' && q.id == 'medicalOperations' ? (
              <div>
                <p style={{ color: colors.tertiaryText }}>{fm(`myService.ninmt.contraIndicationsQuestionClinical`)}</p>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      })}
    </React.Fragment>
  );
};
