import * as React from 'react';

import Form from './Form';
import HistoryRowData from './HistoryRowData';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryRow from '../../../components/HistoryRow';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import HistoryRowControls from '../../../components/HistoryRowControls';
import DocumentCreationButton from '../../../components/DocumentCreationButton';

const NeurologicalStatusAndEDSS = ({ documents }: IOwnProps): React.JSX.Element => {
  return (
    <FormEditingHandler name="neurologicalStatusAndEDSS" documents={documents}>
      {(editing, startEdit, formData, view): React.JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="neurologicalStatusAndEDSS"
            headerId={'neurologicalStatusAndEDSS.title'}
            editing={editing}
            editButtons={
              view?.viewing ? (
                <div />
              ) : (
                <div>
                  <DocumentCreationButton
                    name="neurologicalStatusAndEDSS"
                    text={'general.new'}
                    onClick={startEdit({})}
                  />
                </div>
              )
            }
          />

          {editing ? <Form documents={documents} formData={formData} /> : null}

          {view?.viewing ? (
            <Form
              documents={documents}
              formData={{ document: view.document, onChange: (): string => '' }}
              viewing={!!view.viewing}
            />
          ) : null}

          {!editing && !view?.viewing && documents
            ? documents
                .sort((n1, n2) => n1._cdate - n2._cdate)
                .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
                .reverse()
                .map((d: INeurologicalStatusAndEDSS) => (
                  <HistoryRow
                    headerText={d.date ? formatPartialDate(d.date) : undefined}
                    key={d._id}
                    rowButton={<HistoryRowControls document={d} startEdit={startEdit} view={view} />}
                    controlsMargin={false}
                  >
                    <HistoryRowData d={d} />
                  </HistoryRow>
                ))
            : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents?: INeurologicalStatusAndEDSS[];
}

export default NeurologicalStatusAndEDSS;
