import * as React from 'react';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import OptionChooser from '../../../../../components/OptionChooser';
import { optFormatter, opts } from '../independenceScaleSettings';
import { FormattedMessage } from 'react-intl';

const UHDRSIndependenceScaleForm = ({ formData, viewing }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    <div style={{ fontStyle: 'italic', color: colors.darkGray, margin: '0rem 0 3rem 0' }}>
      The Unified Huntington Disease Rating Scale and revised UHDR® &apos;99 and accompanying manual (collectively, the
      “UHDRS™”) are the copyrighted intellectual property of the Huntington Study Group, Ltd. (“HSG”). Organizations
      seeking to use these tools and/or materials must obtain prior written permission from the HSG. The request form
      can be found in https://huntingtonstudygroup.org/.
    </div>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow
      title="functionalPerformance.uhdrsIndependenceScale"
      description={<FormattedMessage id="functionalPerformance.uhdrsIndependenceScaleDescription" />}
      bottomMargin={true}
    >
      <OptionChooser
        formData={formData}
        name="value"
        opts={opts}
        optionFormatter={optFormatter}
        viewing={viewing}
        isSecondaryOption={(opt: string | number): boolean => (parseInt(opt.toString()) % 10 !== 0 ? true : false)}
        allInstructionsOpenAtOnce={true}
      />
    </FormRow>
    <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
      The unified Huntington&apos;s disease rating scale &apos;99: Motor examination. Huntington study group 2005.
      Examination guidelines for the modified unified Huntington&apos;s disease rating scale &apos;99 (UHDRS &apos;99).
      Huntington study group 2002.
    </span>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IUHDRSIndependenceScale>;
  viewing?: boolean;
}

export default UHDRSIndependenceScaleForm;
