import * as React from 'react';
import EventStepper from '../../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../../components/EventStepper/components';
import FormSection from '../../../../../../components/FormSection';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { formatTime, formatPartialDate, nowPartialDate } from 'neuro-utils';
import MedicationUnit from 'Routes/Medication/utils/medicationUnit';

const unitOptions = [
  'mg',
  'µg',
  'g',
  'mg/min',
  'mg/h',
  'µg/h',
  'ml',
  'ml/min',
  'ml/h',
  'gtt',
  'gtt/min',
  'gtt/h',
  'U',
  'IU',
  'MIU',
  'IU/min',
  'IU/h',
  'mmol',
  'kpl',
  'tabletti',
  'kapseli',
  'ampulla',
  'suppo',
  'peräruiske',
  'emätinpuikko',
  'laastari',
  'pss',
  'annos',
];

const EditingElement = ({ onChange, index, formData, fm }: IEditingElement): React.JSX.Element => {
  const doc = formData?.document.administration?.[index] as IAdministration | undefined;

  return (
    <React.Fragment>
      <div style={{ marginBottom: '2rem' }}>
        <div>{fm('medication.administration.date')}</div>
        <InputHandler
          type="PartialDate"
          editing={true}
          name="date"
          formData={{ onChange: onChange, document: { date: doc?.date || '' } }}
          isNotCancellable={true}
        />
      </div>
      <div style={{ marginBottom: '2rem' }}>
        <div>{fm('medication.administration.endDate')}</div>
        <InputHandler
          type="PartialDate"
          editing={true}
          name="endDate"
          formData={{ onChange: onChange, document: { endDate: doc?.endDate || '' } }}
          dateHook={{ dateHookFloor: doc?.date }}
        />
      </div>

      <div style={{ marginBottom: '2rem' }}>
        <div>{fm('medication.administration.startTime')}</div>
        <InputHandler
          type="TimePicker"
          editing={true}
          name="startTime"
          formData={{ onChange: onChange, document: { startTime: doc?.startTime } }}
          /* TIMEPICKERS bugged with eventstepper so initialization is disabled */
        />
      </div>
      {doc?.startTime ? (
        <div style={{ marginBottom: '2rem' }}>
          <div>{fm('medication.administration.endTime')}</div>

          <InputHandler
            type="TimePicker"
            editing={true}
            name="endTime"
            formData={{ onChange: onChange, document: { endTime: doc?.endTime } }}
          />
        </div>
      ) : (
        ''
      )}
      <div style={{ marginBottom: '2rem' }}>
        <div>{fm('medication.administration.doseQuantity')}</div>
        <InputHandler
          type="TextField"
          editing={true}
          name="doseQuantity"
          formData={{ onChange: onChange, document: { doseQuantity: doc?.doseQuantity || '' } }}
          placeholder="medication.administration.doseQuantity"
        />
      </div>
      <div style={{ marginBottom: '2rem' }}>
        <div>{fm('medication.administration.doseQuantityUnit')}</div>
        <InputHandler
          type="Select"
          name="doseQuantityUnit"
          editing={true}
          width={19}
          formData={{ onChange: onChange, document: { doseQuantityUnit: doc?.doseQuantityUnit || '' } }}
          options={unitOptions}
          optionFormatter={(o: string | number) => <MedicationUnit unit={`${o}`} />}
          placeholder={'medication.chooseUnit'}
        />
      </div>
      <div style={{ marginBottom: '2rem' }}>
        <div>{fm('medication.administration.isExtraDose')}</div>
        <InputHandler
          type="Checkbox"
          editing={true}
          name="isExtraDose"
          options={['true']}
          formData={{
            onChange: onChange,
            document: { isExtraDose: doc?.isExtraDose },
          }}
          optionFormatter={(): string => fm('medication.administration.extraDoseTrue')}
        />
      </div>
      <div style={{ marginBottom: '2rem' }}>
        <div>{fm('medication.administration.additionalInformation')}</div>
        <InputHandler
          type="TextField"
          editing={true}
          name="additionalInformation"
          formData={{
            onChange: onChange,
            document: { additionalInformation: doc?.additionalInformation },
          }}
          placeholder="medication.administration.additionalInformation"
        />
      </div>
    </React.Fragment>
  );
};

interface IEditingElement extends IFormContext {
  onChange: IFormData['onChange'];
  index: number;
  fm: (id: string) => string;
}

const stepContent = (d: IAdministration, fm: (id: string) => string): React.JSX.Element => (
  <>
    <StepperHeaderValuePair
      header={<>{fm('medication.administration.date')}</>}
      value={d.date && formatPartialDate(d.date)}
    />
    <StepperHeaderValuePair
      header={<>{fm('medication.administration.startTime')}</>}
      value={d.startTime && formatTime(d.startTime)}
    />
    <StepperHeaderValuePair
      header={<>{fm('medication.administration.endDate')}</>}
      value={d.endDate && formatPartialDate(d.endDate)}
    />
    <StepperHeaderValuePair
      header={<>{fm('medication.administration.endTime')}</>}
      value={d.endTime && formatTime(d.endTime)}
    />
    <StepperHeaderValuePair
      header={<>{fm('medication.administration.doseQuantity')}</>}
      value={
        <>
          {d.doseQuantity || null}
          <MedicationUnit unit={d.doseQuantityUnit} />
        </>
      }
    />
    <StepperHeaderValuePair
      header={<>{fm('medication.administration.isExtraDose')}</>}
      value={d.isExtraDose?.[0] === true && fm('medication.administration.extraDoseTrue')}
    />
    <StepperHeaderValuePair
      header={<>{fm('medication.administration.additionalInformation')}</>}
      value={d.additionalInformation || null}
    />
  </>
);

const Administration = ({ formData, fm }: IFormContext): React.JSX.Element => {
  return (
    <FormSection header="medication.administration.header">
      <EventStepper
        name="administration"
        formData={formData as IFormData}
        stepLabelText={(d: IAdministration): string => formatPartialDate(d.date)}
        stepContent={(d: IAdministration): React.JSX.Element => stepContent(d, fm)}
        addNewTextHeader="medication.administration.addNewAdministration"
        addNewTextButton="medication.administration.newAdministration"
        previousEventsTextHeader="medication.administration.previousAdministration"
        editingElements={(i: number, onChange: IFormData['onChange']): React.JSX.Element => (
          <EditingElement onChange={onChange} index={i} fm={fm} formData={formData} />
        )}
        defaultValues={{ date: nowPartialDate() }}
      />
    </FormSection>
  );
};

export default withFormContext(Administration);
