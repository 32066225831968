import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { isPartialDate, partialDateToValue } from 'neuro-utils';
import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import { TDocument } from '../../utils';

const DietTherapyForm = ({ editing, formData, documents }: IOwnProps): React.JSX.Element => {
  const [initialWarning, setInitialWarning] = React.useState<boolean>(true);
  const [warningOpen, setWarningOpen] = React.useState<boolean>(false);
  const sleep = (milliseconds: number): any => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const isDateForbidden = React.useCallback(
    (date?: PartialDate): boolean => {
      const documentsFiltered =
        documents
          ?.filter((d) => d._editing === false)
          .filter((doc) => {
            return doc._id !== formData.document._id;
          }) || [];

      return documentsFiltered.length > 0
        ? documentsFiltered.some((d): boolean => {
            if (partialDateToValue(d.startDate) === partialDateToValue(date)) {
              return true;
            }
            if (isPartialDate(d.endDate)) {
              return partialDateToValue(d.endDate) > partialDateToValue(date) &&
                partialDateToValue(d.startDate) < partialDateToValue(date)
                ? true
                : false;
            }
            return partialDateToValue(d.startDate) > partialDateToValue(date) ? false : true;
          })
        : false;
    },
    [formData.document._id, documents],
  );

  React.useEffect(() => {
    initialWarning ? setWarningOpen(isDateForbidden(formData.document.startDate)) : undefined;
  }, [formData.document.startDate, initialWarning, isDateForbidden]);

  React.useEffect(() => {
    warningOpen
      ? sleep(6000)
          .then(() => {
            setWarningOpen(false);
            setInitialWarning(false);
          })
          .catch(() => {
            setWarningOpen(false);
            setInitialWarning(false);
          })
      : undefined;
  });

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="dietTherapy.startDate">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="startDate"
            formData={formData}
            isNotCancellable={true}
            showMessage={isDateForbidden}
            warningMessage={'dietTherapy.dietTherapyAlreadyExists'}
            dateDefault="now"
          />
        </FormRow>
        <FormRow title="dietTherapy.endDate" condition={formData.document.startDate ? true : false}>
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="endDate"
            formData={formData}
            isNotCancellable={true}
            dateHook={{ dateHookFloor: formData.document.startDate }}
          />
        </FormRow>
        <FormRow title="dietTherapy.reason">
          <InputHandler
            type="Checkbox"
            name="reason"
            editing={!!editing}
            formData={formData}
            options={['difficultEpilepsy', 'dravetSyndrome', 'glut1ds', 'patientsChoice']}
            optionFormatter={(name: string | number): React.JSX.Element => (
              <FormattedMessage id={`dietTherapy.opts.${name}`} />
            )}
          />
        </FormRow>
        <FormRow title="dietTherapy.diet">
          <InputHandler
            type="Radio"
            name="diet"
            editing={!!editing}
            formData={formData}
            options={['ketogenicDiet', 'modifiedKetogenicDiet']}
            optionFormatter={(name: string | number): React.JSX.Element => (
              <FormattedMessage id={`dietTherapy.opts.${name}`} />
            )}
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<IDietTherapy>;
  document?: IDietTherapy;
  viewing?: boolean;
  documents?: Array<TDocument>;
}

export default DietTherapyForm;
