import * as React from 'react';

import HistoryRow from '../../../../../../components/HistoryRow';
import HistoryRowVerticalItem from '../../../../../../components/HistoryRowVerticalItem';

import { newButton } from '../../../../utils/functions';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import { calculateDaysDifference, formatPartialDate } from 'neuro-utils';

const FirstVisitRespiratoryFailureHistory = ({ documents, startEdit, fm }: IHistoryContext): React.JSX.Element => {
  const fvrf: IRespiratoryFirstVisit = documents?.filter((d) => d._type === 'respiratoryFirstVisit')[0];

  const daysDifference = calculateDaysDifference(fvrf?.dateOfReferral, fvrf?.dateOfFirstVisit);

  return (
    <HistoryRow
      headerText={fm('diagnosis.sleepApnea.respiratoryFirstVisit.title')}
      rowButton={
        startEdit
          ? newButton(
              'respiratoryFirstVisit',
              startEdit(fvrf ?? {}, 'respiratoryFirstVisit'),
              'general.edit',
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              fvrf,
            )
          : undefined
      }
    >
      <React.Fragment>
        <HistoryRowVerticalItem
          header={fm('diagnosis.sleepApnea.respiratoryFirstVisit.referralOrAcute')}
          value={
            fvrf?.referralOrAcute && fm('diagnosis.sleepApnea.respiratoryFirstVisit.opts.' + fvrf?.referralOrAcute)
          }
        />
        {fvrf?.referralOrAcute === 'referral' && (
          <>
            <HistoryRowVerticalItem
              header={fm('diagnosis.sleepApnea.respiratoryFirstVisit.dateOfReferral')}
              value={fvrf?.dateOfReferral && formatPartialDate(fvrf?.dateOfReferral)}
            />
            <HistoryRowVerticalItem
              header={fm('diagnosis.sleepApnea.respiratoryFirstVisit.dateOfFirstVisit')}
              value={fvrf?.dateOfFirstVisit && formatPartialDate(fvrf?.dateOfFirstVisit)}
            />
            <HistoryRowVerticalItem
              header={fm('diagnosis.sleepApnea.respiratoryFirstVisit.delayFromArrivalToFirstVisit')}
              value={daysDifference && daysDifference + ' ' + fm('diagnosis.sleepApnea.days')}
            />
          </>
        )}
      </React.Fragment>
    </HistoryRow>
  );
};

export default withHistoryContext(FirstVisitRespiratoryFailureHistory);
