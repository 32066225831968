import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import CollapseElem from '../../../../components/Collapse';
import HistoryRow from '../../../../components/HistoryRow';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowVerticalItem from '../../../../components/HistoryRowVerticalItem';
import HistorySection from '../../../../components/HistorySection';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { EditingLockedContentContext } from 'Containers/EditingLockedContentContext';

const StyledRow = ({ title, value, condition, id }: IStyledRow): React.JSX.Element => (
  <React.Fragment>
    <span id={id} />
    {condition && condition === true ? (
      <HistoryRowVerticalItem header={title} value={value ? value : '-'} />
    ) : undefined}
  </React.Fragment>
);

const fm = (id: string, values?: { [key: string]: string | number | null }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const ScintigraphyDataSection = ({ document }: IScintigraphyDataSection): React.JSX.Element => {
  const showProcedureCode = document.procedureCode && document.procedureCode.length > 0;
  const showStatement = document.statement && document.statement.length > 0;

  return (
    <React.Fragment>
      {showProcedureCode && (
        <StyledRow
          id={'procedureCode'}
          title={fm('imaging.procedureCode')}
          value={document.procedureCode}
          condition={true}
        />
      )}
      {showStatement && (
        <StyledRow id={'statement'} title={fm('imaging.statementText')} value={document.statement} condition={true} />
      )}
      {(document.imagingType || document.result) && (
        <React.Fragment>
          <StyledRow
            id={'imagingTypeHistory'}
            title={fm('imaging.imagingType')}
            value={
              document.imagingType && document.imagingType !== 'other'
                ? fm(`imaging.opts.imagingType.${document.imagingType}`)
                : document.imagingType && document.otherType && document.otherType.length > 0
                  ? document.otherType
                  : document.imagingType && fm(`imaging.opts.imagingType.${document.imagingType}`)
            }
            condition={true}
          />
          {document.imagingType && document.imagingType === 'pet' && (
            <StyledRow
              id={'tracerHistory'}
              title={fm('imaging.tracer')}
              value={
                document.tracer && document.tracer === 'FDG'
                  ? fm(`imaging.opts.tracer.${document.tracer}`)
                  : document.tracer === 'other' && document.otherTracer && document.otherTracer.length > 0
                    ? document.otherTracer
                    : document.tracer && fm(`imaging.opts.tracer.${document.tracer}`)
              }
              condition={document.imagingType ? true : false}
            />
          )}
          <StyledRow
            id={'resultHistory'}
            title={fm('imaging.result')}
            value={document.result && fm(`imaging.opts.result.${document.result}`)}
            condition={true}
          />
          <StyledRow
            id={'symmetryHistory'}
            title={fm('imaging.symmetryOfFinding')}
            value={document.symmetryOfFinding && fm(`imaging.opts.symmetricAsymmetric.${document.symmetryOfFinding}`)}
            condition={document.result && document.result !== 'normal'}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Scintigraphy = ({ documents, startEdit }: IOwnProps): React.JSX.Element => {
  const { openEditing } = React.useContext(EditingLockedContentContext);

  return (
    <HistorySection
      headerText={fm('imaging.scintigraphy')}
      newButton={
        <DocumentCreationButton
          name="scintigraphy"
          text={'imaging.newScintigraphy'}
          onClick={startEdit({} as IScintigraphy, 'scintigraphy')}
          width={15}
          height={3}
          fontSize={12}
          alternate={true}
        />
      }
      hasHistoryRows={true}
    >
      {documents &&
        documents
          .sort((n1, n2) => n1._cdate - n2._cdate)
          .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
          .reverse()
          .map((d: IScintigraphy, index) => (
            <React.Fragment key={d._id}>
              <HistoryRow
                headerText={formatPartialDate(d.date)}
                rowButton={
                  <HistoryRowControls
                    document={d}
                    startEdit={
                      d._lockedFor && d._source !== null
                        ? (doc) => () => openEditing(doc, 'imaging.scoliosis')
                        : startEdit
                    }
                  />
                }
                controlsMargin={false}
              >
                <>
                  {index === 0 ? (
                    <ScintigraphyDataSection document={d} />
                  ) : (
                    d.result && (
                      <CollapseElem
                        localeIDs={{ showMessage: 'imaging.show', hideMessage: 'imaging.hide' }}
                        amount={documents.length}
                      >
                        <div style={{ marginBottom: '1rem' }}>
                          <ScintigraphyDataSection document={d} />
                        </div>
                      </CollapseElem>
                    )
                  )}
                </>
              </HistoryRow>
            </React.Fragment>
          ))}
    </HistorySection>
  );
};

interface IScintigraphyDataSection {
  document: IScintigraphy;
}

interface IStyledRow {
  title: string | React.JSX.Element;
  value?: any;
  condition?: boolean;
  id?: string;
}

interface IOwnProps {
  documents: IScintigraphy[];
  startEdit: (document: IScintigraphy, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default Scintigraphy;
