import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';

const CarbonDioxideMeasurementForm = ({ formData, viewing }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    <FormRow title="clpAndCnpTests.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="clpAndCnpTests.tcCO2max">
      <Unit unit={<FormattedMessage id="clpAndCnpTests.kPa" />}>
        <InputHandler
          type="NumberField"
          editing={!viewing}
          name="tcCO2max"
          formData={formData}
          precision={2}
          min={-999999}
          max={999999}
          placeholder="clpAndCnpTests.tcCO2max"
        />
      </Unit>
    </FormRow>
    <FormRow title="clpAndCnpTests.tcCO2mean">
      <Unit unit={<FormattedMessage id="clpAndCnpTests.kPa" />}>
        <InputHandler
          type="NumberField"
          editing={!viewing}
          name="tcCO2mean"
          formData={formData}
          precision={2}
          min={-999999}
          max={999999}
          placeholder="clpAndCnpTests.tcCO2mean"
        />
      </Unit>
    </FormRow>
    <FormRow title="clpAndCnpTests.tcCO2min">
      <Unit unit={<FormattedMessage id="clpAndCnpTests.kPa" />}>
        <InputHandler
          type="NumberField"
          editing={!viewing}
          name="tcCO2min"
          formData={formData}
          precision={2}
          min={-999999}
          max={999999}
          placeholder="clpAndCnpTests.tcCO2min"
        />
      </Unit>
    </FormRow>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<ICarbonDioxideMeasurement>;
  viewing?: boolean;
}

export default CarbonDioxideMeasurementForm;
