import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistorySection from '../../../../components/HistorySection';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRow from '../../../../components/HistoryRow';
import { Container, Item } from '../../../../components/Grid';
import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';

import { formatTheDates } from '../../utils';
import { isPartialDate, sortPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const fm = (id: string, values?: { [key: string]: string | number | null | React.JSX.Element }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const aid = (aid: IAid): React.JSX.Element => (
  <>
    <Item xs={2}>
      <HistoryRowBasicItem
        header={fm('motorFunctionAndMobilityAid.aids.aid')}
        value={aid.aidType ? fm('motorFunctionAndMobilityAid.aids.opts.' + aid.aidType) : '-'}
      />
    </Item>
    <Item xs={2}>
      <HistoryRowBasicItem
        header={fm('motorFunctionAndMobilityAid.aids.useFrequency')}
        value={aid.use ? fm('motorFunctionAndMobilityAid.aids.opts.' + aid.use) : '-'}
      />
    </Item>
    {aid.aidType === 'wheelChair' ? (
      <Item xs={6}>
        <HistoryRowBasicItem
          header={fm('motorFunctionAndMobilityAid.aids.wheelChairType')}
          value={aid.type ? fm('motorFunctionAndMobilityAid.aids.opts.' + aid.type) : '-'}
        />
      </Item>
    ) : (
      <Item xs={6}></Item>
    )}
  </>
);

const MobilityAidHistoryForm = ({ documents, startEdit }: IOwnProps): React.JSX.Element => {
  const isInUse = (doc: IAid): boolean => {
    const endDate = doc.endDate;
    if (!isPartialDate(endDate)) return true;
    if (
      endDate &&
      new Date(endDate[0] || 0, endDate[1] ? endDate[1] - 1 : 0, endDate[2] === null ? undefined : endDate[2]) >
        new Date()
    )
      return true;
    return false;
  };

  return (
    <HistorySection
      headerText={fm('motorFunctionAndMobilityAid.aids.title')}
      newButton={
        <DocumentCreationButton
          name="mobilityAid"
          text={'general.new'}
          onClick={startEdit({}, 'mobilityAid')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
      hasHistoryRows={true}
      sectionInfoKey={
        Array.isArray(documents) && documents.length > 0 ? 'motorFunctionAndMobilityAid.aids.info' : undefined
      }
    >
      <React.Fragment>
        {documents &&
          documents
            .filter((d) => isInUse(d)) // Aids in use
            .sort((n1, n2) => sortPartialDate(n1.startDate, n2.startDate))
            .reverse()
            .map((d: IAid) => (
              <HistoryRow
                headerText={d.startDate ? formatTheDates(d.startDate, d.endDate) : undefined}
                key={d._id}
                active={isInUse(d)}
                rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                controlsMargin={false}
              >
                <Container alignItems="center">{aid(d)}</Container>
              </HistoryRow>
            ))}
        {documents &&
          documents
            .filter((d) => !isInUse(d)) // Aids not in use
            .sort((n1, n2) => sortPartialDate(n1.startDate, n2.startDate))
            .reverse()
            .map((d: IAid) => (
              <HistoryRow
                headerText={d.startDate ? formatTheDates(d.startDate, d.endDate) : undefined}
                key={d._id}
                active={isInUse(d)}
                rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                controlsMargin={false}
              >
                <Container alignItems="center">{aid(d)}</Container>
              </HistoryRow>
            ))}
      </React.Fragment>
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IAid[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default MobilityAidHistoryForm;
