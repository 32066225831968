import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
import { Container, Item } from '../../../../components/Grid';

const fm = (id: string, values?: { [key: string]: string | number | null }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const SickLeaveHistoryRowData = ({ d }: IOwnProps): React.JSX.Element => (
  <Container alignItems="flex-start">
    <Item>
      <HistoryRowBasicItem
        header={fm('comorbidity.reasonForSickLeave')}
        value={d?.reasonForSickLeave ? fm(`comorbidity.opts.reasonForSickLeave.${d.reasonForSickLeave}`) : '-'}
      />
    </Item>
  </Container>
);

interface IOwnProps {
  d: ISickLeave;
}

export default SickLeaveHistoryRowData;
