import { isRecord, calculateDaysDifference, formatPartialDate, exists } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import {
  checkIfAcuteTreatmentStart,
  checkIfCurrentDocumentIsTheFirstTreatment,
  getDateOfReferral,
} from '../../../utils';
import { EndForm } from '../components';
import { FieldClearanceWarning } from './clearanceWarning';

const ControlStepContent = ({ d }: { d: IMADControl }): React.JSX.Element => {
  const locPath = 'treatment.madTherapy.controls';

  return (
    <>
      <StepperHeaderValuePair
        header={<FormattedMessage id={`${locPath}.controlType`} />}
        value={d.controlType && <FormattedMessage id={`${locPath}.opts.${d.controlType}`} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id={`${locPath}.madUsageFrequency`} />}
        value={
          d.madUsageFrequency && (
            <>
              {d.madUsageFrequency} <FormattedMessage id={`${locPath}.madUsageFrequencyPlaceholder`} />
            </>
          )
        }
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id={`${locPath}.fatiqueDuringTheDay`} />}
        value={d.fatiqueDuringTheDay && <FormattedMessage id={`${locPath}.opts.${d.fatiqueDuringTheDay}`} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id={`${locPath}.adverseEffects`} />}
        value={
          Array.isArray(d.adverseEffects) && (
            <>
              {d.adverseEffects.map((e: string, i: number) => (
                <span key={e}>
                  {i > 0 ? ', ' : ''}
                  <FormattedMessage id={`${locPath}.opts.${e}`} />
                </span>
              ))}
            </>
          )
        }
      />
      <StepperHeaderValuePair header={<FormattedMessage id={`${locPath}.details`} />} value={d.details} />
      <StepperHeaderValuePair
        header={<FormattedMessage id={`${locPath}.deviceAdjustment`} />}
        value={d.deviceAdjustment && <FormattedMessage id={`${locPath}.opts.${d.deviceAdjustment}`} />}
      />
    </>
  );
};

const ControlFormContent = ({
  allControls,
  thisControl,
  onChange,
  mainDate,
}: {
  allControls?: IMADControl[];
  thisControl?: IMADControl;
  onChange: IFormData['onChange'];
  mainDate?: PartialDate;
}): React.JSX.Element => {
  const locPath = 'treatment.madTherapy.controls';

  return (
    <>
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`general.date`} />}
        input={
          <InputHandler
            type="PartialDate"
            editing={true}
            name="date"
            formData={{
              onChange,
              document: { date: thisControl ? thisControl?.date : undefined },
            }}
            dateDefault={allControls && allControls.length === 1 ? mainDate : 'now'}
            dateHook={{ dateHookFloor: mainDate }}
            isNotCancellable={true}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.controlType`} />}
        input={
          <InputHandler
            type="Radio"
            editing={true}
            name="controlType"
            formData={{
              onChange,
              document: { controlType: thisControl?.controlType },
            }}
            options={['visit', 'call', 'videocall']}
            optionFormatter={(o): string | React.JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.madUsageFrequency`} />}
        input={
          <InputHandler
            type="NumberField"
            editing={true}
            name="madUsageFrequency"
            formData={{
              onChange,
              document: { madUsageFrequency: thisControl?.madUsageFrequency },
            }}
            placeholder={`${locPath}.madUsageFrequencyPlaceholder`}
            min={0}
            max={7}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.fatiqueDuringTheDay`} />}
        input={
          <InputHandler
            type="Radio"
            editing={true}
            name="fatiqueDuringTheDay"
            formData={{
              onChange,
              document: { fatiqueDuringTheDay: thisControl?.fatiqueDuringTheDay },
            }}
            options={['noFatique', 'onlyStationaryNotDaily', 'dailyLowActivity', 'dailyHighActivity']}
            optionFormatter={(o): string | React.JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.adverseEffects`} />}
        input={
          <InputHandler
            type="Checkbox"
            editing={true}
            name="adverseEffects"
            formData={{
              onChange,
              document: { adverseEffects: thisControl?.adverseEffects },
            }}
            options={[
              'jawPain',
              'constrictedOpening',
              'occlusionChange',
              'toothDamage',
              'sleepDisturbance',
              'increasedSalivation',
              'other',
            ]}
            optionFormatter={(o): string | React.JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.details`} />}
        input={
          <InputHandler
            type="TextField"
            editing={true}
            name="details"
            formData={{
              onChange,
              document: { details: thisControl?.details },
            }}
            placeholder={`${locPath}.detailsPlaceholder`}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.deviceAdjustment`} />}
        input={
          <InputHandler
            type="Radio"
            editing={true}
            name="deviceAdjustment"
            formData={{
              onChange,
              document: { deviceAdjustment: thisControl?.deviceAdjustment },
            }}
            options={['done', 'notDone']}
            optionFormatter={(o): string | React.JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
          />
        }
      />
    </>
  );
};
const locPath = 'treatment.madTherapy';

const isTSleepStudy = (n: unknown): n is ISleepPolygraphy | ISleepStudy =>
  isRecord(n) && typeof n._type === 'string' && ['sleepPolygraphy', 'sleepStudy'].includes(n._type);

/**
 * Nullify fields related to "controls" and "end" when treatment is set to
 * not be started.
 */
const nullifyOnSetNotStarted: IInputBasics['dependentFieldsList'] = (startTreatment) => {
  if (startTreatment === false) {
    return ['hasEnded', 'endDate', 'endReason', 'endReasonOther', 'controls'];
  } else return [];
};

const MADTherapy = ({ formData, view, editing, documents = [] }: IFormContext<IMADTherapy, any>): React.JSX.Element => {
  const thisDocument = formData.document as IMADTherapy;
  const started = thisDocument.isTreatmentStarted === true;
  const notStarted = thisDocument.isTreatmentStarted === false;

  const referralDate = getDateOfReferral(documents);
  const isAcute = checkIfAcuteTreatmentStart(documents);
  const isFirstDoc = checkIfCurrentDocumentIsTheFirstTreatment(documents, view, editing);

  // Get sleepstudy docs and get date
  const sleepStudyDocs = (documents ?? [])?.filter(isTSleepStudy);
  const sleepStudyDate = Array.isArray(sleepStudyDocs) && sleepStudyDocs?.[0] ? sleepStudyDocs?.[0].date : undefined;

  // When changing isTreatmentStarted to true, set empty list into "reasonForNotStartingTreatment"
  React.useEffect(() => {
    if (
      formData.document.isTreatmentStarted === true &&
      formData.document.reasonForNotStartingTreatment &&
      formData.document.reasonForNotStartingTreatment.length > 0
    ) {
      // Asetetaan tässä formData.document.reasonForNotStartingTreatment tyhjäksi listaksi?
    }
  }, [formData.document.isTreatmentStarted, formData.document.reasonForNotStartingTreatment]);

  React.useEffect(() => {
    if (!('isTreatmentStarted' in formData.document)) {
      // määritetään hoito oletuksena ei-aloitetuksi?
    }
  }, [formData.document]);

  /** Whether treatment is set to start. */
  const isSetToStart = formData.document.isTreatmentStarted === true;

  const delayFromSuspicionToTreatmentStart = calculateDaysDifference(referralDate, thisDocument.treatmentDecisionDate);

  return (
    <>
      <FormRow title={`${locPath}.treatmentDecisionDate`}>
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="treatmentDecisionDate"
          formData={formData}
          dateDefault="now"
          dateHook={{ dateHookCeiling: formData.document.date }}
        />
      </FormRow>
      <FormRow title={`${locPath}.delaySuspicionToTreatment`} condition={isFirstDoc && !isAcute}>
        <Unit unit={<FormattedMessage id="treatment.daysPlaceholder" />} fontWeight={view?.viewing ? 'bold' : 'normal'}>
          {exists(delayFromSuspicionToTreatmentStart) ? (delayFromSuspicionToTreatmentStart as number) : '-'}
        </Unit>
      </FormRow>
      <FormRow title={`${locPath}.isTreatmentStarted`}>
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="isTreatmentStarted"
          formData={formData}
          preset="yesno"
          dependentFieldsList={nullifyOnSetNotStarted}
          dependentFieldsRemovalWarning
          dependentFieldsRemovalWarningText={
            <FieldClearanceWarning
              threatenedFieldsLocIds={[
                // "kontrollikäynnit"
                `${locPath}.controls.title`,
                // "hoidon lopetus"
                'treatment.treatmentEndedTitle',
              ]}
            />
          }
        />
      </FormRow>
      <FormRow title={`${locPath}.reasonForNotStartingTreatment`} condition={notStarted}>
        <InputHandler
          type="Checkbox"
          editing={!view?.viewing}
          name="reasonForNotStartingTreatment"
          formData={formData}
          options={[
            'openOcclusion',
            'crossOcclusion',
            'notEnoughTeeth',
            'untreatedDenture',
            'drynessOfMouth',
            'jawJointAilments',
            'problemsWithMachineInMouth',
            'diseasesWithSwallowingOrAspirationRisk',
          ]}
          optionFormatter={(o): string | React.JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
        />
      </FormRow>
      <FormRow title={`${locPath}.treatmentStartLocation`} condition={started}>
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="treatmentStartLocation"
          formData={formData}
          options={['outpatientClinic', 'primaryHealthCare', 'privateSector']}
          optionFormatter={(o): string | React.JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
        />
      </FormRow>
      <FormRow title={`${locPath}.date`} condition={started}>
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          dateHook={{
            dateHookFloor: formData.document.treatmentDecisionDate,
            dateHookCeiling: formData.document.endDate,
          }}
        />
      </FormRow>
      <FormRow title={`${locPath}.delayTreatmentToStart`} condition={started}>
        <Unit unit={<FormattedMessage id={`${locPath + '.days'}`} />} fontWeight={view?.viewing ? 'bold' : 'normal'}>
          {calculateDaysDifference(thisDocument.treatmentDecisionDate, thisDocument.date) ?? '-'}
        </Unit>
      </FormRow>
      <FormRow title={`${locPath}.delayFromPolygraphy`} condition={started && isFirstDoc}>
        <Unit unit={<FormattedMessage id="treatment.daysPlaceholder" />} fontWeight={view?.viewing ? 'bold' : 'normal'}>
          {calculateDaysDifference(sleepStudyDate, thisDocument.date) ?? '-'}
        </Unit>
      </FormRow>
      {isSetToStart && (
        <>
          <FormSection header={`${locPath}.controls.title`}>
            <EventStepper
              name="controls"
              formData={formData}
              stepLabelText={(d: IMADControl): string => formatPartialDate(d ? d.date : undefined)}
              stepContent={(d: IMADControl): React.JSX.Element => <ControlStepContent d={d} />}
              addNewTextHeader={locPath + '.controls.newControl'}
              addNewTextButton={locPath + '.controls.newControlButton'}
              previousEventsTextHeader={locPath + '.controls.previousControls'}
              noPreviousEventsTextHeader={locPath + '.controls.noPreviousControls'}
              editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => {
                const thisControl = thisDocument.controls?.[index];
                const allControls = thisDocument.controls;
                return (
                  <ControlFormContent
                    allControls={allControls}
                    thisControl={thisControl}
                    onChange={onChange}
                    mainDate={thisDocument.date}
                  />
                );
              }}
              viewing={!!view?.viewing}
            />
          </FormSection>
          <EndForm formData={formData} edit={!view?.viewing} type="madTherapy" />
        </>
      )}
    </>
  );
};

export default withFormContext(MADTherapy);
