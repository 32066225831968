import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ActionButton from '../../../../components/ActionButton';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import { exists, formatPartialDate } from 'neuro-utils';

const ModifiedChildrensGlobalAssessmentScale = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="functionalPerformance.modifiedChildrensGlobalAssessmentScale" />}
    newButton={
      <ActionButton
        text="functionalPerformance.newModifiedChildrensGlobalAssessmentScale"
        onClick={startEdit({}, 'modifiedChildrensGlobalAssessmentScale')}
        width={20}
        height={3}
        fontSize={14}
        alternate={true}
      />
    }
  >
    <HistoryRowListing
      documents={documents}
      headers={
        <React.Fragment>
          <Item xs={2}>
            <FormattedMessage id="general.date" />
          </Item>
          <Item xs={7}>
            <FormattedMessage id="functionalPerformance.rating" />
          </Item>
        </React.Fragment>
      }
      contentFormat={(d: IModifiedChildrensGlobalAssessmentScale): React.JSX.Element => (
        <>
          <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
          <Item xs={7}>
            {exists(d.rating) ? (
              <React.Fragment>
                {`${d.rating} - `}
                <FormattedMessage
                  id={`functionalPerformance.opts.modifiedChildrensGlobalAssessmentScale.rating.${d.rating}`}
                />
              </React.Fragment>
            ) : (
              '-'
            )}
          </Item>
          <Item xs={3}>
            <HistoryRowControls document={d} startEdit={startEdit} view={view} />
          </Item>
        </>
      )}
    />
  </HistorySection>
);

interface IOwnProps {
  documents: Array<IModifiedChildrensGlobalAssessmentScale>;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default ModifiedChildrensGlobalAssessmentScale;
