import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistorySection from '../../../../components/HistorySection';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import HistoryRowControls from '../../../../components/HistoryRowControls';

const BNSQHistory = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'diagnosticSurvey.bnsq.title'} />}
      newButton={
        <DocumentCreationButton
          name="bnsq"
          text={'diagnosticSurvey.bnsq.newBNSQ'}
          onClick={startEdit({}, 'bnsq')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={<></>}
        contentFormat={(): React.JSX.Element => <></>}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IBNSQ[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default BNSQHistory;
