import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/system';

import { Container, Item } from '../../../../../components/Grid';

import colors from '../../../../../config/theme/colors';
import { exists, formatPartialDate } from 'neuro-utils';

import { resolvePath, rootOf } from '../../utils';
import { fm } from 'Components/FormatMessage';

const StyledButton = styled('a')({
  fontWeight: 600,
  cursor: 'pointer',
  marginRight: '2rem',
  display: 'block',
  lineHeight: '2.5rem',
  '&:hover': {
    color: colors.primary,
  },
});

interface IDisplaySelectOptionProps {
  optionInput: React.JSX.Element;
  viewing: boolean | undefined;
  option: number | string | undefined;
  tooltips: Array<{ index: number; message: React.JSX.Element }>;
  options?: Array<number | string>;
}

export const DisplaySelectedOption = ({
  optionInput,
  viewing,
  option,
  tooltips,
  options,
}: IDisplaySelectOptionProps): React.JSX.Element => {
  const [visible, setVisible] = React.useState<boolean>(true);
  const [previousOption, setPreviousOption] = React.useState<number | string | undefined>('');

  React.useEffect(() => {
    previousOption !== option && setVisible(true);
  }, [option, previousOption]);

  const toggleVisibility = (): void => {
    setVisible(!visible);
    setPreviousOption(option);
  };

  return visible &&
    ((!viewing && options?.indexOf('notDone') !== option && options?.indexOf('unknown') !== option) ||
      (viewing && exists(option))) &&
    Math.sign(Number(option)) !== -1 ? (
    <div style={{ marginRight: '2rem' }}>
      <StyledButton
        onClick={viewing ? (): string => '' : toggleVisibility}
        style={viewing ? { color: 'black', cursor: 'default' } : undefined}
      >
        {options &&
          Array.isArray(options) &&
          exists(options?.[Number(option)]) &&
          typeof options[Number(option)] === 'number' && <span>{`${options[Number(option)]} - `}</span>}
        {Array.isArray(tooltips) && tooltips?.[Number(option)] ? tooltips[Number(option)].message : option}
      </StyledButton>
    </div>
  ) : (
    optionInput
  );
};

const DocumentMenuContents = (
  documents: INeurologicalStatusAndEDSS[] | undefined,
  toggleDocumentMenu: (e: React.MouseEvent<SVGSVGElement | HTMLElement>) => void,
  onClickDocument: (target: string, value: string | number | TAnyObject) => () => void,
  target: string,
): React.JSX.Element | undefined => {
  const menuItem = (text: string, onClick: (e: React.MouseEvent<HTMLElement>) => void): React.JSX.Element => (
    <MenuItem onClick={onClick}>{text}</MenuItem>
  );

  return Array.isArray(documents) && documents.length > 0 ? (
    documents.length === 1 ? (
      <MenuItem style={{ outline: 'none' }}>{fm('neurologicalStatusAndEDSS.noPreviousDocuments')}</MenuItem>
    ) : (
      <div>
        {documents
          .filter((document) => !document._editing)
          .sort((a, b) => {
            return new Date(formatPartialDate(b.date)).getTime() - new Date(formatPartialDate(a.date)).getTime();
          })
          .map((document) => (
            <div key={document._id}>
              {menuItem(formatPartialDate(document.date), (e: React.MouseEvent<HTMLElement>): void => {
                onClickDocument(
                  rootOf(target),
                  target === 'super' ? document : resolvePath(rootOf(target), document),
                )();
                toggleDocumentMenu(e);
              })}
            </div>
          ))}
      </div>
    )
  ) : undefined;
};

export const DocumentSelectMenu = (
  documents: INeurologicalStatusAndEDSS[] | undefined,
  toggleDocumentMenu: (e: React.MouseEvent<SVGSVGElement | HTMLElement>) => void,
  showMenu: SVGSVGElement | HTMLElement | null,
  onClickDocument: (target: string, value: string | number | TAnyObject) => () => void,
  target: string,
): React.JSX.Element => (
  <Menu
    open={!!showMenu}
    anchorEl={showMenu}
    onClose={toggleDocumentMenu}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    PaperProps={{ style: { width: 'max-content', border: '0px' } }}
  >
    {DocumentMenuContents(documents, toggleDocumentMenu, onClickDocument, target)}
  </Menu>
);

export const RightLeftTitleRow = (): React.JSX.Element => (
  <Container style={{ color: colors.tertiaryText }}>
    <Item xs={true}>{fm('general.right')}</Item>
    <Item xs={true}>{fm('general.left')}</Item>
  </Container>
);
