import ActionButtonRounded from 'Components/ActionButtonRounded';
import { createEvent } from 'Components/EventStepper/utils';
import FormSection from 'Components/FormSection';
import { Container, Item } from 'Components/Grid';
import { sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { DoctorsOrdersContext } from 'Routes/DoctorsOrders/Document';
import DoctorsOrdersHistory from 'Routes/DoctorsOrders/Document/HistoryRowData';
import { TDCSContext } from 'Routes/Tdcs/Document';

const ContinueButton = ({ add, setDoctorsOrdersReview }: IButtonProps): React.JSX.Element => {
  return (
    <React.Fragment>
      <ActionButtonRounded
        text="tdcs.doctorsOrdersRead"
        onClick={() => {
          add?.();
          setDoctorsOrdersReview?.({ active: false, completed: true });
        }}
        width={22.5}
        height={4.5}
        fontSize={16}
        colorScheme="default"
        filled
      />
    </React.Fragment>
  );
};

const CancelButton = ({ setDoctorsOrdersReview }: IButtonProps): React.JSX.Element => {
  return (
    <React.Fragment>
      <ActionButtonRounded
        text="general.cancel"
        onClick={() => {
          setDoctorsOrdersReview?.({ active: false, completed: false });
        }}
        width={8}
        height={4.5}
        fontSize={16}
        colorScheme="cancel"
      />
    </React.Fragment>
  );
};

interface IButtonProps {
  add?: () => void;
  cancel?: (id?: number) => () => void;
  setDoctorsOrdersReview?: React.Dispatch<
    React.SetStateAction<{
      active: boolean;
      completed: boolean;
    }>
  >;
}

const DoctorsOrdersReview = (): React.JSX.Element => {
  const tdcsContext = React.useContext(TDCSContext);
  const {
    fm,
    isEditing,
    formData,
    doctorsOrders,
    otherDoctorsOrders,
    startEdit,
    doctorsOrdersReview,
    setDoctorsOrdersReview,
  } = tdcsContext;

  // Add session createDate for more accurate sorting
  const creationMutator = (event: ITDCSSession): ITDCSSession => {
    return { ...event, createDate: Date.now() };
  };

  const addNew = (): void => {
    createEvent('sessions', formData, creationMutator)();
  };

  const doctorsOrdersToReview = [...doctorsOrders, ...otherDoctorsOrders].sort((a, b) =>
    sortPartialDate(b.date, a.date),
  );

  // Doctors orders to review include those that are not completed
  const uncompletedDoctorsOrdersIds = doctorsOrdersToReview.filter((d) => !d.completed).map((d) => d._id);

  // Ref for doctors orders completion status
  const doctorsOrdersToReviewIdsRef = React.useRef<string[]>(uncompletedDoctorsOrdersIds);

  // Update doctors orders completion status when starting/ending review and filter accordingly
  React.useEffect(() => {
    doctorsOrdersToReviewIdsRef.current = uncompletedDoctorsOrdersIds;
  }, [doctorsOrdersReview.active]);

  return (
    <DoctorsOrdersContext.Provider
      value={{
        fm: fm,
        isEditing: isEditing,
        formData: formData,
        documents: doctorsOrdersToReview.filter((d) => doctorsOrdersToReviewIdsRef.current.includes(d._id)),
        startEdit: startEdit,
      }}
    >
      <DoctorsOrdersHistory startEdit={startEdit} mode="review" />
      <FormSection>
        <Container style={{ justifyContent: 'space-between' }}>
          <Item xs={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <CancelButton setDoctorsOrdersReview={setDoctorsOrdersReview} />
          </Item>
          <Item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ContinueButton add={addNew} setDoctorsOrdersReview={setDoctorsOrdersReview} />
          </Item>
        </Container>
      </FormSection>
    </DoctorsOrdersContext.Provider>
  );
};

export default DoctorsOrdersReview;
