import * as React from 'react';
import { formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import EventStepper from '../../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../../components/EventStepper/components';
import { capitalize } from '../../../../../../utility/string';
import { mappedDeviceFields } from '../config';
import { PAPTherapyContext } from 'Routes/Treatment/Document';
import { fetchAvxPatientInfo } from '../functions';
import { RenderAlerts } from 'Components/Alerts';
import { Container, Item } from 'Components/Grid';
import { TSessionData } from '../interfaces';
import { styled } from '@mui/system';
import { isEmpty } from 'Utility/ramdaReplacement';

const StyledTableRow = styled('div')({ marginTop: '1rem', display: 'grid', gridTemplateColumns: '1.5fr 0.15fr 2fr' });

const TableRow = ({ title, value }: { title: string; value?: string }): React.JSX.Element => (
  <StyledTableRow>
    <div>{fm(title)}</div>
    <div />
    <div style={{ fontWeight: 600 }}>{value ?? '-'}</div>
  </StyledTableRow>
);

const getPrescriptionDeviceOverLatest = async (
  ecn: string | undefined,
  latestDevice: IPapDevice,
): Promise<NonNullable<TSessionData['deviceID']>['device'] | undefined> => {
  const mappedLatestDevice: { [key: string]: string | undefined } = {
    serialNo: latestDevice.resMedId,
    deviceType: latestDevice.type,
    deviceTypeDesc: latestDevice.deviceModel,
  };

  if (ecn) {
    try {
      const res = await fetchAvxPatientInfo(ecn);
      if (res && typeof res === 'object' && !isEmpty(res)) {
        const prescriptionDevice = res.prescription?.device;

        const keys: (keyof typeof prescriptionDevice)[] = ['serialNo', 'deviceType', 'deviceTypeDesc'];
        const devicesAreEqual = keys.every((f) => prescriptionDevice[f] === mappedLatestDevice[f]);

        return devicesAreEqual ? undefined : prescriptionDevice;
      }
    } catch {
      return undefined;
    }
  }

  return undefined;
};

const ResMedDevices = ({ devices }: IOwnProps): React.JSX.Element => {
  const papTherapyContext = React.useContext(PAPTherapyContext);
  const { ecn } = papTherapyContext;

  const [prescriptionDevice, setPrescriptionDevice] = React.useState<
    NonNullable<TSessionData['deviceID']>['device'] | undefined
  >(undefined);

  React.useEffect(() => {
    if (Array.isArray(devices) && devices.length > 0) {
      getPrescriptionDeviceOverLatest(ecn, devices[0]).then((device) => {
        setPrescriptionDevice(device);
      });
    }
  }, [ecn, devices]);

  return (
    <React.Fragment>
      {prescriptionDevice && (
        <Container style={{ marginBottom: '2.5rem' }}>
          <Item xs={5} />
          <Item xs={true}>
            <RenderAlerts
              alerts={[
                {
                  id: 'resMedDeviceMismatchAlert',
                  textID: '',
                  customText: (
                    <React.Fragment>
                      <div style={{ fontWeight: 'bold' }}>{fm(`treatment.papTherapy.deviceMismatchMessage`)}</div>
                      <TableRow title="treatment.papTherapy.resMedId" value={prescriptionDevice.serialNo} />
                      <TableRow title="treatment.papTherapy.deviceModel" value={prescriptionDevice.deviceTypeDesc} />
                      <TableRow title="treatment.papTherapy.deviceNumber" value={prescriptionDevice.deviceType} />
                    </React.Fragment>
                  ),
                  severity: 'info',
                },
              ]}
              elevation={0}
              width={80}
            />
          </Item>
        </Container>
      )}
      <EventStepper
        name="devices"
        formData={{ onChange: () => '', document: {} }}
        stepLabelText={(d: IPapDevice): string => formatPartialDate(d.date)}
        stepContent={(d: IPapDevice): React.JSX.Element => (
          <React.Fragment>
            {['resMedId', 'deviceModel', 'type', 'mode']
              .filter((field) => Object.values(mappedDeviceFields).includes(field as keyof IPapDevice))
              .map((field, index) => {
                const value = d[field as keyof IPapDevice];
                const k = !['resMedId', 'deviceModel', 'mode'].includes(field) ? `device${capitalize(field)}` : field;
                return (
                  <StepperHeaderValuePair
                    key={`${field}${index}`}
                    header={fm(`treatment.papTherapy.${k === 'deviceType' ? 'deviceNumber' : k}`)}
                    value={value}
                  />
                );
              })}
          </React.Fragment>
        )}
        addNewTextHeader="treatment.papTherapy.newDevice"
        addNewTextButton="treatment.papTherapy.newDevice"
        previousEventsTextHeader="treatment.papTherapy.devices"
        noPreviousEventsTextHeader="treatment.papTherapy.noDevices"
        editingElements={() => <></>}
        viewing={true}
        deleteMessage="treatment.papTherapy.deleteDevice"
        readOnlyEvents={devices}
      />
    </React.Fragment>
  );
};

interface IOwnProps {
  devices?: Array<IPapDevice>;
}

export default ResMedDevices;
