import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistorySection from '../../../../components/HistorySection';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { Container, Item } from '../../../../components/Grid';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import { calculateAdlTotal } from 'Routes/DiagnosticSurvey/utils';

const ADLHistory = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'diagnosticSurvey.adl.title'} />}
      newButton={
        <DocumentCreationButton
          name="adl"
          text={'diagnosticSurvey.adl.newADL'}
          onClick={startEdit({}, 'adl')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <Item xs={true}>
            <Container>
              {(['0', '1', '2', '3', 'unknown'] as TADLLevel[]).map((level, index) => (
                <Item key={level + index} xs={2} style={{ paddingRight: '2rem' }}>
                  <FormattedMessage id={`diagnosticSurvey.adl.levels.${level}`} />
                </Item>
              ))}
            </Container>
          </Item>
        }
        contentFormat={(d: IADL): React.JSX.Element => (
          <Item xs={true}>
            <Container>
              {(['0', '1', '2', '3', 'unknown'] as TADLLevel[]).map((level, index) => (
                <Item key={level + index} xs={2}>
                  {calculateAdlTotal(d, level) ?? '-'}
                </Item>
              ))}
            </Container>
          </Item>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IADL[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default ADLHistory;
