import { formatPartialDate, isPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

import { withFormContext, IFormContext } from '../../../../../containers/FormContextHandler';
import { endingReasons } from '../Medication';
import EventStepper from 'Components/EventStepper';
import { StepperHeaderValuePair } from 'Components/EventStepper/components';

const EndingForm = ({ formData, fm }: { formData: IFormData; fm: IFormContext['fm'] }): React.JSX.Element => {
  const platform = useSelector((s: { session?: ISessionStore }) => s.session?.platforms?.selected);
  return (
    <React.Fragment>
      {formData && (
        <FormSection header="medication.otherTreatment.ended">
          <FormRow title="medication.otherTreatment.stopTreatment">
            <InputHandler
              type="Checkbox"
              editing={true}
              name="hasEnded"
              formData={formData}
              options={['true']}
              optionFormatter={(): string => fm('medication.otherTreatment.stopTreatment')}
            />
          </FormRow>
          <FormRow title="medication.endedDate" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler
              type="PartialDate"
              editing={true}
              name="endDate"
              formData={formData}
              dateDefault="now"
              dateHook={{ dateHookFloor: formData.document.startDate }}
            />
          </FormRow>
          <FormRow title="medication.otherTreatment.endedReason" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler
              type="Checkbox"
              editing={true}
              name="endReason"
              formData={formData}
              options={endingReasons(formData.document.endReason, platform)}
              optionFormatter={(n: string | number): string => fm(`medication.opts.${n}`)}
            />
          </FormRow>

          <FormRow title="medication.endedReasonOther" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler
              type="TextArea"
              editing={true}
              name="endReasonOther"
              formData={formData}
              placeholder={'medication.pausationAdditionalInfoPlaceholder'}
            />
          </FormRow>
        </FormSection>
      )}
    </React.Fragment>
  );
};

const VitaminDForm = ({ formData, fm }: IFormContext<IOtherTreatmentBasics & IVitaminD>): React.JSX.Element => {
  const { document } = formData;
  const locPath = 'medication.otherTreatment';

  return (
    <>
      <FormSection header={`${locPath}.opts.${document.type}`}>
        <EventStepper
          name="regimen"
          formData={formData}
          addNewTextHeader="medication.regimen"
          addNewTextButton="general.new"
          previousEventsTextHeader=""
          stepLabelText={(d: IVitaminRegimen) => <span>{d.date ? formatPartialDate(d.date) : ''}</span>}
          stepContent={(d: IVitaminRegimen): React.JSX.Element => {
            return (
              <React.Fragment>
                <StepperHeaderValuePair key={`dosage`} header={fm(`${locPath}.dosage`)} value={d.dosage} />
                <StepperHeaderValuePair
                  key={`frequency`}
                  header={fm(`${locPath}.frequency`)}
                  value={d.frequency ? fm(`${locPath}.opts.${d.frequency}`) : '-'}
                />

                {d.frequency === 'other' && (
                  <StepperHeaderValuePair
                    key={`otherFrequency`}
                    header={fm(`${locPath}.otherFrequency`)}
                    value={d.otherFrequency}
                  />
                )}
              </React.Fragment>
            );
          }}
          editingElements={(index: number, onChange: IFormData['onChange']) => (
            <>
              <div style={{ marginBottom: '2rem' }}>
                {fm(`general.date`)}
                <InputHandler
                  type="PartialDate"
                  name="date"
                  editing={true}
                  isNotCancellable={true}
                  dateDefault={formData.document.regimen?.length === 1 ? formData.document.startDate : 'now'}
                  formData={{
                    onChange: onChange,
                    document: {
                      date: formData.document?.regimen?.[index]?.date,
                    },
                  }}
                  dateHook={{ dateHookFloor: formData.document.startDate }}
                />
              </div>
              <div style={{ marginBottom: '2rem' }}>
                {fm(`${locPath}.dosage`)}
                <InputHandler
                  type="Select"
                  editing={true}
                  name="dosage"
                  formData={{
                    onChange: onChange,
                    document: {
                      dosage: formData.document?.regimen?.[index]?.dosage,
                    },
                  }}
                  options={['10µg', '25µg', '50µg', '60µg', '75µg', '100µg', '125µg', '150µg']}
                  placeholder={`${locPath}.dosagePlaceholder`}
                />
              </div>
              <div style={{ marginBottom: '2rem' }}>
                {fm(`${locPath}.frequency`)}
                <InputHandler
                  type="Radio"
                  editing={true}
                  name="frequency"
                  formData={{
                    onChange: onChange,
                    document: {
                      frequency: formData.document?.regimen?.[index]?.frequency,
                    },
                  }}
                  options={['onceADay', 'twiceADay', 'other']}
                  optionFormatter={(o: string | number): string => fm(`${locPath}.opts.${o}`)}
                />
              </div>
              {formData.document.regimen?.[index]?.frequency === 'other' && (
                <div style={{ marginBottom: '2rem' }}>
                  <div>{fm(`${locPath}.otherFrequency`)}</div>
                  <InputHandler
                    type="TextField"
                    editing={true}
                    name="otherFrequency"
                    formData={{
                      onChange: onChange,
                      document: {
                        otherFrequency: formData.document?.regimen?.[index]?.otherFrequency,
                      },
                    }}
                    placeholder={`${locPath}.otherFrequencyPlaceholder`}
                  />
                </div>
              )}
            </>
          )}
        />
      </FormSection>
      <EndingForm formData={formData} fm={fm} />
    </>
  );
};

const OtherTreatmentForm = ({
  editing,
  formData,
  fm,
  documents,
}: IFormContext<IMedicationOtherTreatment>): React.JSX.Element => {
  const { document } = formData;
  const thisEditingDocument = documents?.[0];
  return (
    <>
      {thisEditingDocument._type === 'otherTreatment' && (
        <>
          <FormSection>
            <FormRow title="medication.startDate">
              <InputHandler
                type="PartialDate"
                editing={!!editing}
                name="startDate"
                formData={formData}
                dateDefault="now"
                isNotCancellable={true}
                dateHook={{
                  dateHookCeiling:
                    'endDate' in document && isPartialDate(document.endDate) ? document.endDate : undefined,
                }}
              />
            </FormRow>
          </FormSection>
          <VitaminDForm formData={formData as IFormData<IOtherTreatmentBasics & IVitaminD>} fm={fm} />
        </>
      )}
      {thisEditingDocument._type === 'procedure' && (
        <FormSection>
          <FormRow title="general.date">
            <InputHandler
              type="PartialDate"
              editing={!!editing}
              name="date"
              formData={formData}
              dateDefault="now"
              isNotCancellable={true}
            />
          </FormRow>
        </FormSection>
      )}
    </>
  );
};

export default withFormContext(OtherTreatmentForm);
