import * as React from 'react';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import { path } from 'Utility/ramdaReplacement';

type TData = { path?: string; options?: { [key: string]: Array<string> } };
type TEntry = [string, Array<string>];

const DataContext: React.Context<TData> = React.createContext({});

const StepContent = ({ d, fm }: { d: IEngelClassification; fm?: (id: string) => string }): React.JSX.Element => (
  <React.Fragment>
    <DataContext.Consumer>
      {({ path: locPath, options }): React.JSX.Element => {
        return (
          <React.Fragment>
            {options
              ? Object.entries(options).map((entry: TEntry) => {
                  const pathWithAffix = `${locPath}.${entry[0]}`;
                  const value = path([entry[0] === 'timeFromOperation' ? entry[0] : 'classification'], d);
                  return (
                    <React.Fragment key={entry[0]}>
                      {!!value && options[entry[0]].includes(value) && (
                        <StepperHeaderValuePair
                          header={<>{fm ? fm(`${pathWithAffix}.title`) : ''}</>}
                          value={fm ? fm(`${pathWithAffix}.opts.${value}`) : ''}
                        />
                      )}
                    </React.Fragment>
                  );
                })
              : ''}
          </React.Fragment>
        );
      }}
    </DataContext.Consumer>
  </React.Fragment>
);

const EditingElement = ({
  formData,
  editing,
  fm,
}: {
  formData: IFormData<IEngelClassification>;
  editing?: boolean;
  fm?: (id: string) => string;
}): React.JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler type="PartialDate" name="date" editing={editing} formData={formData} dateDefault="now" />
    </FormRow>
    <DataContext.Consumer>
      {({ path, options }): React.JSX.Element => {
        return (
          <React.Fragment>
            {!!options &&
              Object.entries(options).map((entry: TEntry): React.JSX.Element => {
                const pathWithAffix = `${path}.${entry[0]}`;
                return (
                  <FormRow title={`${pathWithAffix}.title`} key={entry[0]}>
                    <InputHandler
                      type="Radio"
                      name={entry[0] === 'timeFromOperation' ? entry[0] : 'classification'}
                      editing={editing}
                      formData={formData}
                      options={entry[1]}
                      optionFormatter={(id: string | number): string => (fm ? fm(`${pathWithAffix}.opts.${id}`) : '')}
                    />
                  </FormRow>
                );
              })}

            <FormRow title={`surgicalTreatment.engelClassification.ref`} headerWidth={7}>
              {null}
            </FormRow>
          </React.Fragment>
        );
      }}
    </DataContext.Consumer>
  </React.Fragment>
);

const EngelClassification = ({ formData, editing, view, fm }: IFormContext): React.JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const document = formData?.document as ISurgicalTreatment;

  const pathAffix: TData = { path: 'surgicalTreatment.engelClassification' };

  const initialOptions: TData = {
    options: {
      timeFromOperation: ['1', '2', '3', '4', '5'],
      classI: ['aI', 'bI', 'cI', 'dI'],
      classII: ['aII', 'bII', 'cII', 'dII'],
      classIII: ['aIII', 'bIII'],
      classIV: ['aIV', 'bIV', 'cIV'],
    },
  };

  return (
    <React.Fragment>
      <DataContext.Provider value={{ ...pathAffix, ...initialOptions }}>
        <EventStepper
          name="engelClassification"
          formData={formData as IFormData<ISurgicalTreatment>}
          stepLabelText={(d: IEngelClassification): string => formatPartialDate(d.date)}
          stepContent={(d: IEngelClassification): React.JSX.Element => <StepContent d={d} fm={fm} />}
          addNewTextHeader={`${pathAffix.path}.new`}
          addNewTextButton={`${pathAffix.path}.add`}
          previousEventsTextHeader={`${pathAffix.path}.previous`}
          editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => {
            const engelClassification = document.engelClassification?.[index];
            return (
              <React.Fragment>
                {engelClassification ? (
                  <EditingElement
                    formData={{ document: engelClassification, onChange: onChange }}
                    editing={editingDoc}
                    fm={fm}
                  />
                ) : undefined}
              </React.Fragment>
            );
          }}
        />
      </DataContext.Provider>
    </React.Fragment>
  );
};

export default withFormContext(EngelClassification);
