import * as React from 'react';

import DocumentCreationButton from '../../../components/DocumentCreationButton';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import { FormContextProvider, HistoryContextProvider } from '../../../containers/FormContextHandler';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import SurgicalTreatmentForm from './Form';
import SurgicalTreatmentHistoryRow from './HistoryRowData';

const SurgicalTreatment = ({ documents }: ISurgicalTreatmentProps): React.JSX.Element => {
  const surgicalTreatmentDocuments = (documents ?? []).filter((d) => d._type === 'surgicalTreatment');

  return (
    <FormEditingHandler name="surgicalTreatment" documents={surgicalTreatmentDocuments}>
      {(editing, startEdit, formData, view): React.JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="surgicalTreatment"
            headerId="surgicalTreatment.title"
            editing={editing}
            editButtons={
              <DocumentCreationButton
                name="surgicalTreatment"
                text={'surgicalTreatment.add'}
                onClick={startEdit({}, 'surgicalTreatment')}
              />
            }
          />
          <FormContextProvider context={{ formData: formData, editing: editing, view: view }}>
            {editing || view?.viewing ? <SurgicalTreatmentForm /> : null}
          </FormContextProvider>
          <HistoryContextProvider context={{ startEdit: startEdit, view: view, documents: surgicalTreatmentDocuments }}>
            {!editing && !view?.viewing ? <SurgicalTreatmentHistoryRow /> : null}
          </HistoryContextProvider>
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface ISurgicalTreatmentProps {
  documents: Array<ISurgicalTreatment>;
}

export default SurgicalTreatment;
