import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import { docType, filterDocs, TDocument } from '../utils';

import PulmonaryForm from './Form';

import ChestCircumferenceHistory from './HistoryRowData/ChestCircumference';
import PulmonaryHistory from './HistoryRowData/Pulmonary';
import VentilationHistory from './HistoryRowData/Ventilation';

const History = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
): React.JSX.Element => {
  const chestCircumferenceDocs = filterDocs('chestCircumference', documents);
  const ventilationDocs = filterDocs('ventilationAid', documents);
  const pulmonaryDocs = filterDocs('pulmonary', documents);

  return (
    <React.Fragment>
      <ChestCircumferenceHistory documents={chestCircumferenceDocs as IChestCircumference[]} startEdit={startEdit} />
      <VentilationHistory documents={ventilationDocs as IVentilation[]} startEdit={startEdit} />
      <PulmonaryHistory documents={pulmonaryDocs as IPulmonary[]} startEdit={startEdit} />
    </React.Fragment>
  );
};

const Pulmonary = ({ documents }: IOwnProps): React.JSX.Element => {
  return (
    <FormEditingHandler name="pulmonary" documents={documents}>
      {(editing, startEdit, formData, view): React.JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'pulmonary'}
            headerId={
              docType(documents, editing) === 'chestCircumference'
                ? 'pulmonary.chestCircumference'
                : docType(documents, editing) === 'ventilationAid'
                  ? 'pulmonary.ventilation'
                  : docType(documents, editing) === 'pulmonary'
                    ? 'pulmonary.airwayClearanceorSecretionMobilisation'
                    : 'pulmonary.title'
            }
            editing={editing}
            editButtons={<div />}
          />
          {editing ? <PulmonaryForm formData={formData} documents={documents} editing={editing} /> : null}
          {!editing && !view?.viewing ? History(documents, startEdit) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default Pulmonary;
