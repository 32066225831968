import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import HistorySection from '../../../../components/HistorySection';
import { IHistoryContext, withHistoryContext } from '../../../../containers/FormContextHandler';
import { DocumentCreationButtonWithContext } from '../../utils';
import { TreatmentHistorySection } from './components';

const HFNCTherapy = ({ documents = [], startEdit, view }: IHistoryContext<IHFNCTherapy>): React.JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="treatment.hfncTherapy.title" />}
    newButton={<DocumentCreationButtonWithContext name="hfncTherapy" text="treatment.hfncTherapy.newHfncTherapy" />}
  >
    <TreatmentHistorySection documents={documents} startEdit={startEdit} type="hfncTherapy" view={view} />
  </HistorySection>
);

export default withHistoryContext(HFNCTherapy);
