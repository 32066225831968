import * as React from 'react';

import HistorySection from '../../../../../../components/HistorySection';
import HistoryRowVerticalItem from '../../../../../../components/HistoryRowVerticalItem';

import { formatPartialDate } from 'neuro-utils';
import { newButton } from '../../../../utils/functions';
import { isNil } from 'Utility/ramdaReplacement';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';

const MgravisSymptoms = ({ documents, startEdit, fm = (): string => '' }: IHistoryContext): React.JSX.Element => {
  const mgravisSymptoms: IMGravisSymptoms = documents?.filter(
    (d) => d._type === 'mgravisSymptoms',
  )[0] as IMGravisSymptoms;

  return (
    <HistorySection
      headerText={fm('diagnosis.mgravis.symptoms.title')}
      hasHistoryRows={false}
      newButton={
        startEdit
          ? newButton(
              'mgravisSymptoms',
              startEdit(mgravisSymptoms ?? {}, 'mgravisSymptoms'),
              'general.edit',
              undefined,
              true,
              !isNil(((mgravisSymptoms ?? {}) as IControlProps)._lockedFor),
              undefined,
              undefined,
              mgravisSymptoms,
            )
          : undefined
      }
    >
      <React.Fragment>
        <HistoryRowVerticalItem
          header={fm('general.date')}
          value={
            mgravisSymptoms?.date ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>{formatPartialDate(mgravisSymptoms?.date)}</div>
            ) : (
              ' - '
            )
          }
        />

        <HistoryRowVerticalItem header={fm('diagnosis.mgravis.symptoms.age')} value={mgravisSymptoms?.age} />

        <HistoryRowVerticalItem
          header={fm('diagnosis.mgravis.symptoms.location')}
          value={
            mgravisSymptoms?.symptomLocation?.length
              ? mgravisSymptoms?.symptomLocation.map((item: string) => fm('diagnosis.mgravis.symptoms.' + item))
              : ' - '
          }
        />
      </React.Fragment>
    </HistorySection>
  );
};

export default withHistoryContext(MgravisSymptoms);
