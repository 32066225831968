import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
import { Container, Item } from '../../../../components/Grid';

const StyledHistory = styled('div')({});

const fm = (id: string, values?: { [key: string]: string | number | null }): React.JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const TherapyHistoryRowData = ({ d }: IOwnProps): React.JSX.Element => (
  <StyledHistory>
    <Container alignItems="center">
      <Item xs={3}>
        {d.therapyClassification ? (
          <HistoryRowBasicItem
            header={fm('therapy.therapyClassification.title')}
            value={
              <div style={{ fontWeight: 600 }}>
                {fm(`therapy.therapyClassification.opts.${d.therapyClassification}`)}
              </div>
            }
          />
        ) : (
          d.therapyTypes && (
            <HistoryRowBasicItem
              header={<div id={'therapyTypeTitle'}>{fm('therapy.therapyTypes')}</div>}
              value={
                <div style={{ fontWeight: 600 }} id={'therapyType'}>
                  {d.therapyTypes === 'others'
                    ? d.otherTherapyType ?? ''
                    : d.therapyTypes && fm(`therapy.opts.${d.therapyTypes}`)}
                </div>
              }
            />
          )
        )}
      </Item>
      <Item xs={5}>
        {d.therapyClassification && d.therapyTypes ? (
          <HistoryRowBasicItem
            header={fm('therapy.therapyTypes')}
            value={<div style={{ fontWeight: 600 }}>{fm(`therapy.opts.${d.therapyTypes}`)}</div>}
          />
        ) : undefined}
      </Item>
    </Container>
  </StyledHistory>
);

interface IOwnProps {
  d: ITherapy;
}

export default TherapyHistoryRowData;
