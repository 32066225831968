import * as React from 'react';
import FormRow from 'Components/FormRow';
import FormSectionBar from 'Components/FormSectionBar';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import { DoctorsOrdersContext } from '../..';
import Treatments from './Treatments';
import Unit from 'Components/Unit';
import InputHistoryAcceptor from 'Components/InputHistoryAcceptor';
import { sortPartialDate } from 'neuro-utils';

const RTMSForm = ({ subjectOfOrder }: { subjectOfOrder: Array<string> }): React.JSX.Element => {
  const doctorsOrdersContext = React.useContext(DoctorsOrdersContext);
  const { formData, documents, isEditing, fm, ninmtTreatmentPeriods } = doctorsOrdersContext;

  const { document, onChange } = formData;
  const latesNinmtTreatmentPeriod = ninmtTreatmentPeriods?.sort((a, b) => sortPartialDate(b.date, a.date))[0];
  const doctorsOrdersDocsInCurrentTreatmentPeriod = documents.filter(
    (d) => sortPartialDate(d.date, latesNinmtTreatmentPeriod?.date) >= 0,
  );

  const isMountRender = React.useRef<boolean>(true);

  // Map subjectOfOrder values to "legacy" form display control values
  React.useEffect(() => {
    if (isMountRender.current) isMountRender.current = false;
    onChange?.({
      rtms: {
        ...document.rtms,
        ...{
          doesNotApplyToRMT: !subjectOfOrder.includes('rmt'),
          doesNotApplyToSubjectOfTreatment: !subjectOfOrder.includes('treatmentProtocolAndBackupSubjectsOfTreatment'),
          endRTMS: subjectOfOrder.includes('endTreatment'),
          backupSubjectOfTreatment: !subjectOfOrder.includes('treatmentProtocolAndBackupSubjectsOfTreatment')
            ? null
            : document.rtms?.backupSubjectOfTreatment,
        },
      },
    });
  }, [subjectOfOrder]);

  return (
    <React.Fragment>
      <FormSectionBar header="doctorsOrders.rtms.header" />
      <FormSection>
        {!subjectOfOrder.includes('endTreatment') ? (
          <React.Fragment>
            {subjectOfOrder.includes('treatmentFrequency') && (
              <FormRow title="doctorsOrders.rtms.treatmentFrequency.title">
                <InputHistoryAcceptor
                  documents={doctorsOrdersDocsInCurrentTreatmentPeriod}
                  type="TextArea"
                  name="rtms.treatmentFrequency"
                  editing={isEditing}
                  formData={formData}
                  placeholder="doctorsOrders.rtms.treatmentFrequency.placeholder"
                  customConfirmText="doctorsOrders.copyPrevFrequency"
                />
              </FormRow>
            )}
            <FormRow title="doctorsOrders.rtms.device.title" condition={!document.rtms?.doesNotApplyToRMT}>
              <InputHandler
                type="Radio"
                name="rtms.device"
                editing={isEditing}
                formData={formData}
                options={['Nexstim', 'Axilum']}
                optionFormatter={(o: string | number) => fm(`doctorsOrders.rtms.device.opts.${o}`)}
              />
            </FormRow>
            <FormRow title="doctorsOrders.rtms.rmt.title" condition={!document.rtms?.doesNotApplyToRMT}>
              <Unit unit={fm('doctorsOrders.rtms.rmt.unit')}>
                <InputHandler
                  type="NumberField"
                  name="rtms.rmt"
                  editing={isEditing}
                  formData={formData}
                  width={6}
                  disabled={!document.rtms?.device}
                />
              </Unit>
            </FormRow>
            <FormRow title="doctorsOrders.rtms.peelingDepth.title" condition={!document.rtms?.doesNotApplyToRMT}>
              <Unit unit={fm('doctorsOrders.rtms.peelingDepth.unit')}>
                <InputHandler
                  type="NumberField"
                  name="rtms.peelingDepth"
                  editing={isEditing}
                  formData={formData}
                  width={6}
                  precision={1}
                  disabled={!document.rtms?.device}
                />
              </Unit>
            </FormRow>
            <FormRow title="doctorsOrders.rtms.rmtLocation.title" condition={!document.rtms?.doesNotApplyToRMT}>
              <InputHandler
                type="Radio"
                name="rtms.rmtLocation"
                editing={isEditing}
                formData={formData}
                options={['right', 'left']}
                optionFormatter={(o: string | number) => fm(`doctorsOrders.rtms.rmtLocation.opts.${o}`)}
                disabledOptions={!document.rtms?.device ? ['right', 'left'] : undefined}
              />
            </FormRow>

            {!isMountRender.current && document.rtms?.doesNotApplyToSubjectOfTreatment === false && (
              <Treatments formData={formData} />
            )}

            <FormRow
              title="doctorsOrders.rtms.additionalInformation.title"
              description={fm('doctorsOrders.rtms.additionalInformation.description')}
              condition={!document.rtms?.doesNotApplyToSubjectOfTreatment}
            >
              <InputHandler
                type="TextArea"
                name="rtms.additionalInformation"
                editing={isEditing}
                formData={formData}
                placeholder="doctorsOrders.rtms.additionalInformation.placeholder"
              />
            </FormRow>
          </React.Fragment>
        ) : (
          <div style={{ marginBottom: '3.5rem', fontWeight: 600 }}>{fm('doctorsOrders.rtms.endRTMS.title')}</div>
        )}

        {subjectOfOrder.includes('other') && (
          <FormRow title="doctorsOrders.rtms.otherOrder.title">
            <InputHandler
              type="TextArea"
              name="rtms.otherOrder"
              editing={isEditing}
              formData={formData}
              placeholder="doctorsOrders.rtms.otherOrder.placeholder"
            />
          </FormRow>
        )}
      </FormSection>
    </React.Fragment>
  );
};

export default RTMSForm;
