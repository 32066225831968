import FormRow from 'Components/FormRow';
import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { Container, Item } from 'Components/Grid';
import { otherOnChange } from '../../utils';
import DosageFormat from '../DosageFormat';
import InputHandler from 'Components/InputHandler';
import ActionButton from 'Components/ActionButton';
import UpkeepDoses from './UpkeepDoses';

const getStrengthValue = (s?: Array<IStrengths>): number =>
  !s || s.length === 0 ? 1 : parseInt(s[0][Object.keys(s[0])[0]]);

const ParkinsonInfusion = ({ type, index, formData, intl }: IInfusionProps): React.JSX.Element => {
  const fm = (id: string): string => intl.formatMessage({ id: id });

  const regimen = formData?.document?.regimen?.[index] as IRegimenBasics & IRegimenCustom;

  const onChangeDoses = (values: TOnChangeValues): void => {
    otherOnChange(
      formData.onChange,
      index,
      regimen,
      formData?.document?.regimen,
    )({ infusionDoses: { ...regimen.infusionDoses, ...values } });
  };

  const onChangeDosesMlh = (values: TOnChangeValues): void => {
    const key = Object.keys(values)[0];
    if (typeof values[key] === 'number') (values[key] as number) *= getStrengthValue(regimen.strengths);
    onChangeDoses(values);
  };

  const transformToMlhValue = (mgh?: number): number | undefined =>
    mgh ? mgh / getStrengthValue(regimen.strengths) : undefined;

  const addUpkeepDose = (): void => {
    const doses = regimen.infusionDoses?.upkeepDoses ? [...regimen.infusionDoses.upkeepDoses] : [];
    doses.push({
      upkeepDose: undefined,
      upkeepDoseFrom: undefined,
      upkeepDoseTo: undefined,
    });
    otherOnChange(
      formData.onChange,
      index,
      regimen,
      formData?.document?.regimen,
    )({ infusionDoses: { ...regimen.infusionDoses, upkeepDoses: doses } });
  };

  return (
    <React.Fragment>
      <FormRow title="medication.regimenDosage" headerWidth={3} description={fm('medication.infusionDosageInfo')}>
        <>
          {type === 'gel' && (
            <DosageFormat
              header={fm('medication.regimenMorningDose')}
              content={
                <Container alignItems="center">
                  <Item xs={2}>
                    <Container>
                      <Item>
                        <InputHandler
                          type="NumberField"
                          editing={true}
                          name="morningDose"
                          formData={{
                            onChange: onChangeDosesMlh,
                            document: { morningDose: transformToMlhValue(regimen?.infusionDoses?.morningDose) || '' },
                          }}
                          width={7}
                          precision={2}
                        />
                      </Item>
                      <Item style={{ padding: '0 1rem' }}>{fm('medication.regimenInfusion_ml')}</Item>
                    </Container>
                  </Item>
                  <Item xs={1}>=</Item>
                  <Item>
                    <InputHandler
                      type="NumberField"
                      editing={true}
                      name="morningDose"
                      formData={{
                        onChange: onChangeDoses,
                        document: { morningDose: regimen?.infusionDoses?.morningDose || '' },
                      }}
                      width={7}
                      precision={2}
                    />
                  </Item>
                  <Item style={{ padding: '0 1rem' }}>{fm('medication.regimenInfusion_mg')}</Item>
                </Container>
              }
            />
          )}
          {/** Upkeep dose input table */}
          <UpkeepDoses index={index} formData={formData} str={getStrengthValue(regimen.strengths)} />
          {/** Upkeep dose add button */}
          <Container>
            <Item style={{ marginBottom: '2rem' }}>
              <ActionButton
                text={'medication.doses.addDosage'}
                onClick={addUpkeepDose}
                width={16}
                height={3}
                fontSize={16}
              />
            </Item>
          </Container>
          <DosageFormat
            header={fm('medication.regimenAdditionalDose')}
            content={
              <Container alignItems="center">
                <Item xs={2}>
                  <Container>
                    <Item>
                      <InputHandler
                        type="NumberField"
                        editing={true}
                        name="additionalDose"
                        formData={{
                          onChange: onChangeDosesMlh,
                          document: {
                            additionalDose: transformToMlhValue(regimen?.infusionDoses?.additionalDose) || '',
                          },
                        }}
                        width={7}
                        precision={2}
                      />
                    </Item>
                    <Item style={{ padding: '0 1rem' }}>{fm('medication.regimenInfusion_ml')}</Item>
                  </Container>
                </Item>
                <Item xs={1}>=</Item>
                <Item xs={2}>
                  <Container>
                    <Item>
                      <InputHandler
                        type="NumberField"
                        editing={true}
                        name="additionalDose"
                        formData={{
                          onChange: onChangeDoses,
                          document: { additionalDose: regimen?.infusionDoses?.additionalDose || '' },
                        }}
                        width={7}
                        precision={2}
                      />
                    </Item>
                    <Item style={{ padding: '0 1rem' }}>{fm('medication.regimenInfusion_mg')}</Item>
                  </Container>
                </Item>
                <Item>
                  <Container>
                    <Item style={{ padding: '0 0 0 4rem' }}>
                      <InputHandler
                        type="NumberField"
                        editing={true}
                        name="additionalDoseLimit"
                        formData={{
                          onChange: onChangeDoses,
                          document: { additionalDoseLimit: regimen?.infusionDoses?.additionalDoseLimit || '' },
                        }}
                        width={7}
                        precision={2}
                      />
                    </Item>
                    <Item style={{ padding: '0 1rem' }}>{fm('medication.regimenInfusion_timesPerDay')}</Item>
                  </Container>
                </Item>
              </Container>
            }
          />
        </>
      </FormRow>
    </React.Fragment>
  );
};

interface IInfusionProps {
  type: 'infusion' | 'gel' | false;
  index: number;
  formData: IFormData<IMedication>;
  intl: IntlShape;
}

export default injectIntl(ParkinsonInfusion);
