import { useIntl } from 'react-intl';
import * as React from 'react';
import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';

const StyledLabel = styled('div')({
  fontWeight: 600,
});

const Instruction = ({ locale }: { locale: string }): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  return (
    <React.Fragment>
      <Container style={{ marginBottom: '-2.5rem' }}>
        <Item xs={2}>
          <StyledLabel>{fm('interviewSurveys.labels.madrs.questions')}</StyledLabel>
        </Item>
        <Item xs={10} style={{ fontStyle: 'italic', paddingLeft: '1rem' }}>
          {fm(`interviewSurveys.labels.madrs.${locale}`)}
        </Item>
      </Container>
    </React.Fragment>
  );
};

export const steps = [
  'notedMelancholy',
  'reportedMelancholy',
  'feelingsOfAnxiety',
  'decreasedSleep',
  'decreasedHunger',
  'troubleToConcentrate',
  'lackOfInitiative',
  'lackOfFeelings',
  'pessimisticThoughts',
  'suicidalThoughts',
] as Array<string | { [key: string]: string[] }>;

export const getStepContent = (
  stepName: string,
): {
  opts: (number | string)[];
  optsLocale: string;
  instruction?: React.JSX.Element;
} => ({
  opts: [0, 1, 2, 3, 4, 5, 6],
  optsLocale: stepName,
  instruction: <Instruction locale={`${stepName}Instruction`} />,
});
