import { isNil } from 'Utility/ramdaReplacement';
import * as React from 'react';
import HistoryRow from '../../../../../components/HistoryRow';
import HistoryRowSubHeader from '../../../../../components/HistoryRowSubHeader';
import HistoryRowVerticalItem from '../../../../../components/HistoryRowVerticalItem';
import { IHistoryContext, withHistoryContext } from '../../../../../containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { newButton } from '../../../utils/functions';

const HTT = ({ documents = [], startEdit }: IHistoryContext): React.JSX.Element => {
  const httDocument: IHTT = documents.filter((d) => d._type === 'htt')[0];
  return (
    <React.Fragment>
      <HistoryRow
        headerText={fm('diagnosis.huntington.htt.title')}
        rowButton={
          startEdit
            ? newButton(
                'htt',
                startEdit(httDocument ?? {}, 'htt'),
                'general.edit',
                undefined,
                undefined,
                !isNil(((httDocument ?? {}) as IControlProps)._lockedFor),
                undefined,
                undefined,
                httDocument,
              )
            : undefined
        }
      >
        <React.Fragment>
          <HistoryRowVerticalItem
            header={fm('general.date')}
            value={httDocument?.date ? formatPartialDate(httDocument.date) : ' - '}
          />
          <HistoryRowVerticalItem
            header={fm('diagnosis.huntington.htt.title')}
            value={
              httDocument?.status
                ? fm(`diagnosis.huntington.htt.opts.doneNotDoneUnknown.${httDocument?.status}`)
                : ' - '
            }
          />
          {httDocument?.status === 'done' && (
            <React.Fragment>
              <HistoryRowSubHeader header={fm('diagnosis.huntington.htt.cagRepeatLengths')} />
              <HistoryRowVerticalItem
                header={fm('diagnosis.huntington.htt.allele', { alleleId: '1' })}
                value={httDocument.cagRepeatLengths?.[0].cagLength ?? '-'}
              />
              <HistoryRowVerticalItem
                header={fm('diagnosis.huntington.htt.whoseAllele')}
                value={
                  httDocument.cagRepeatLengths?.[0].lineage
                    ? fm(`diagnosis.huntington.htt.${httDocument.cagRepeatLengths?.[0].lineage}Allele`)
                    : '-'
                }
              />
              <HistoryRowVerticalItem
                header={fm('diagnosis.huntington.htt.allele', { alleleId: '2' })}
                value={httDocument.cagRepeatLengths?.[1].cagLength ?? '-'}
              />
              <HistoryRowSubHeader header={fm('diagnosis.huntington.htt.httHaplotype')} />
              <HistoryRowVerticalItem
                header={fm('diagnosis.huntington.htt.httHaplotype')}
                value={
                  httDocument?.httHaplotype
                    ? httDocument?.httHaplotype === 'other' && httDocument?.httHaplotypeOther
                      ? httDocument?.httHaplotypeOther
                      : fm(`diagnosis.huntington.htt.opts.httHaplotype.${httDocument?.httHaplotype}`)
                    : ' - '
                }
              />
              <HistoryRowVerticalItem
                header={fm('diagnosis.huntington.htt.httHaplotypeAdditionalInformation')}
                value={
                  httDocument?.httHaplotypeAdditionalInformation
                    ? httDocument?.httHaplotypeAdditionalInformation
                    : ' - '
                }
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </HistoryRow>
    </React.Fragment>
  );
};

export default withHistoryContext(HTT);
