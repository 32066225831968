import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styled } from '@mui/system';
import { equals, uniqBy } from 'ramda';
import { Checkbox } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { formatPartialDate } from 'neuro-utils';

import HistoryRowControls from '../../../../components/HistoryRowControls';
import ACSearch from '../../../../components/ACSearch';
import { Container, Item } from '../../../../components/Grid';

import colors from '../../../../config/theme/colors';

import {
  sortDocuments,
  filterByText,
  cumulateOptions,
  TSortableFields,
  TSortDirection,
  localizeCodenames,
} from '../../utils';
import PagingWrapper from 'Components/PagingWrapper';
import { sortDocuments as sortDocumentsUtility } from 'Utility/randomUtil';

const AscIcon = styled(({ ...other }) => <ArrowDropUp fontSize="large" {...other} />)({
  display: 'block !important',
  marginTop: '0.3rem',
  marginLeft: '0.5rem',
  cursor: 'pointer',
});

const DescIcon = styled(({ ...other }) => <ArrowDropDown fontSize="large" {...other} />)({
  display: 'block !important',
  marginTop: '0.3rem',
  marginLeft: '0.5rem',
  cursor: 'pointer',
});

const SortableColumn = styled('div')({
  textDecoration: 'underline',
  color: colors.primary,
  cursor: 'pointer',
});

const StyledHeaderItem = styled(({ ...other }: React.ComponentProps<typeof Item>) => <Item xs={true} {...other} />)({
  color: colors.tertiaryText,
  fontWeight: 'normal',
  padding: '0.5rem',
});

const StyledCellItem = styled(({ ...other }: React.ComponentProps<typeof Item>) => <Item xs={true} {...other} />)({
  fontWeight: 600,
  padding: '0.5rem',
});

const NameField = ({ name }: { name: string }): React.JSX.Element => {
  return (
    <Container alignItems="center">
      <Item xs={true}>{name}</Item>
    </Container>
  );
};

const SortedFields = ({
  documents,
  view,
  startEdit,
  sortDirection,
  sortField,
  filterText,
  headers,
  showLatest,
}: {
  documents: IOwnProps['documents'];
  view: IOwnProps['view'];
  startEdit: IOwnProps['startEdit'];
  sortDirection: TSortDirection;
  sortField: 'date' | 'codeName';
  filterText: string;
  headers: React.JSX.Element;
  showLatest?: boolean;
}) => {
  let sortedDocuments = documents;
  if (showLatest) {
    // Sort by date and then pick only uniq documents by codeName
    sortedDocuments = sortedDocuments.sort((d1, d2) =>
      sortDocumentsUtility([{ type: 'date', sortField: 'date' }])(d1, d2),
    );
    sortedDocuments = uniqBy((d) => d.codeName, sortedDocuments);
  }

  sortedDocuments = sortDocuments(filterByText(filterText, ['codeName'], sortedDocuments), sortField, sortDirection);

  const showHeader = (d: IExamination, i: number): boolean => {
    const docIndex = sortedDocuments.findIndex((fid) => fid._id === d._id);

    if (i === 0) return true;
    if (sortField === 'date') return !equals(sortedDocuments[docIndex - 1]['date'], d.date);
    else {
      return !equals(sortedDocuments[docIndex - 1]['codeName'], d.codeName);
    }
  };

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  return (
    <PagingWrapper
      list={sortedDocuments}
      listItemComponent={(d, i) => (
        <Container key={d._id} alignItems="baseline">
          <StyledCellItem xs={2} style={{ color: colors.primary, fontSize: '1.8rem' }}>
            {sortField === 'codeName' && showHeader(d, i) && d.codeName && (
              <NameField name={localizeCodenames(d.codeName, fm)} />
            )}
            {sortField === 'date' && showHeader(d, i) && formatPartialDate(d.date)}
          </StyledCellItem>
          <>
            <StyledCellItem>
              {sortField === 'codeName' && d.date && formatPartialDate(d.date)}
              {sortField === 'date' && d.codeName && <NameField name={localizeCodenames(d.codeName, fm)} />}
            </StyledCellItem>
            <StyledCellItem>{d.value && `${d.value} ${d.units ? d.units : ''}`}</StyledCellItem>
            <StyledCellItem>{d.valueReferenceRange || null}</StyledCellItem>
            <Item xs={3}>{<HistoryRowControls view={view} document={d} startEdit={startEdit} />}</Item>
          </>
        </Container>
      )}
      limitOfItemsInPage={50}
      headerComponent={headers}
      bottomControls
    />
  );
};

const ExaminationHistory = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element | null => {
  const [sortField, setSortField] = React.useState<TSortableFields>('date');
  const [sortDirection, setSortDirection] = React.useState<TSortDirection>('descending');
  const [filterText, setFilterText] = React.useState<string>('');
  const [showLatest, setShowLatest] = React.useState<boolean>(false);

  const setSortingField = (field: TSortableFields) => (): void => {
    setSortField(field);
    if (field === 'date') {
      setSortDirection('descending');
    } else {
      setSortDirection('ascending');
    }
  };

  const setSortingDirection = (): void => {
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
  };

  const showLatestOnChange = (): void => setShowLatest(!showLatest);

  const acOptions: Array<string> = documents ? cumulateOptions(documents, ['codeName']) : [];

  return documents ? (
    <React.Fragment>
      <Container alignItems="center" style={{ height: '5rem' }}>
        <Item xs={8}>
          <Container>
            <Item style={{ paddingLeft: '0.5rem', paddingBottom: '0.3rem', marginRight: '2rem' }}>
              <ACSearch acOptions={acOptions} setFilter={setFilterText} />
            </Item>
            <Item>
              {sortField === 'codeName' && (
                <Container onClick={showLatestOnChange} alignItems="center">
                  <Item>
                    <Checkbox checked={showLatest} color={'primary'} />
                  </Item>
                  <Item>
                    <div
                      style={{
                        cursor: 'pointer',
                        color: colors.primaryText,
                      }}
                    >
                      <FormattedMessage id="examination.showLatest" />
                    </div>
                  </Item>
                </Container>
              )}
            </Item>
          </Container>
        </Item>
      </Container>
      <div style={{ marginTop: '1rem' }}>
        {/* Map documents under headers */}
        <SortedFields
          documents={documents}
          view={view}
          startEdit={startEdit}
          sortDirection={sortDirection}
          sortField={sortField}
          filterText={filterText}
          showLatest={showLatest}
          headers={
            <Container alignItems="center">
              {/* Make headers */}
              <StyledHeaderItem xs={2}>
                <Container alignItems="center">
                  <Item>
                    <FormattedMessage id={sortField === 'codeName' ? 'examination.codeName' : 'general.date'} />
                  </Item>
                  <Item onClick={setSortingDirection}>
                    {sortDirection === 'descending' ? <DescIcon /> : <AscIcon />}
                  </Item>
                </Container>
              </StyledHeaderItem>
              <>
                <StyledHeaderItem>
                  <SortableColumn onClick={setSortingField(sortField === 'codeName' ? 'date' : 'codeName')}>
                    <FormattedMessage id={sortField === 'codeName' ? 'general.date' : 'examination.codeName'} />
                  </SortableColumn>
                </StyledHeaderItem>
                <StyledHeaderItem>
                  <FormattedMessage id={'examination.value'} />
                </StyledHeaderItem>
                <StyledHeaderItem>
                  <FormattedMessage id={'examination.valueReferenceRange'} />
                </StyledHeaderItem>
                <Item xs={3}>{/* Column for controls */}</Item>
              </>
            </Container>
          }
        />
      </div>
    </React.Fragment>
  ) : null;
};

interface IOwnProps {
  documents: IExamination[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default ExaminationHistory;
