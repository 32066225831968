import * as React from 'react';
import EventStepper from '../../../../../../components/EventStepper';
import { formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import InputHandler from '../../../../../../components/InputHandler';
import FormSection from '../../../../../../components/FormSection';

const MGragisFormofDisease = ({ editing, formData, newDiagnosis }: IOwnProps): React.JSX.Element => {
  return (
    <React.Fragment>
      <FormSection header="diagnosis.mgravis.formOfDisease.formOfDisease">
        {editing ? (
          <EventStepper
            name="formOfDisease"
            formData={formData}
            stepLabelText={(d: IMGravisFormOfDisease): React.JSX.Element => (
              <span>{d.date ? formatPartialDate(d.date) : ''}</span>
            )}
            stepContent={(d: IMGravisFormOfDisease): React.JSX.Element => {
              return <div>{d.formOfDisease ? fm(`diagnosis.mgravis.formOfDisease.${d.formOfDisease}`) : ''}</div>;
            }}
            addNewTextHeader=""
            addNewTextButton="general.new"
            previousEventsTextHeader=""
            buttonDisabled={
              formData.document.formOfDisease?.length === 1 && !formData.document.formOfDisease?.[0].formOfDisease
                ? true
                : false
            }
            buttonDisabledMessage={fm(`diagnosis.mgravis.formOfDisease.newDisabledMessage`)}
            editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => (
              <React.Fragment>
                <div style={{ marginBottom: '2rem' }}>
                  {fm('general.date')}
                  <InputHandler
                    type="PartialDate"
                    name="date"
                    dateDefault="now"
                    formData={{
                      onChange: onChange,
                      document: {
                        date: formData.document?.formOfDisease?.[index]?.date,
                      },
                    }}
                    editing={!!editing}
                  />
                </div>
                <div style={{ marginBottom: '2rem' }}>
                  {fm('diagnosis.mgravis.formOfDisease.formOfDisease')}
                  <InputHandler
                    type="Select"
                    name="formOfDisease"
                    formData={{
                      onChange: onChange,
                      document: {
                        formOfDisease: formData.document?.formOfDisease?.[index]?.formOfDisease,
                      },
                    }}
                    options={['ocular', 'generalized', 'unknown']}
                    optionFormatter={(s: string | number): React.JSX.Element =>
                      fm(`diagnosis.mgravis.formOfDisease.${s}`)
                    }
                    editing={!!editing}
                    placeholder={'diagnosis.mgravis.formOfDisease.chooseForm'}
                  />
                </div>
              </React.Fragment>
            )}
            editingModeOn={
              newDiagnosis &&
              ((Array.isArray(formData.document.formOfDisease) && formData.document.formOfDisease.length < 2) ||
                formData.document.formOfDisease === undefined)
            }
          />
        ) : undefined}
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<IDiagnosis>;
  newDiagnosis: boolean;
}

export default MGragisFormofDisease;
