import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import MotorFunctionHistory from './HistoryRowData/MotorFunction';
import MobilityAidHistory from './HistoryRowData/Aid';
import Form from './Form';

const docType = (documents: Array<TDocument>, editing: string, view?: IView): string | undefined =>
  documents && editing
    ? documents.find((d: TDocument) => d._id === editing)?._type || undefined
    : documents && view?.viewing
      ? documents.find((d: TDocument) => d._id === view?.viewing)?._type || undefined
      : undefined;

const MotorFunction = ({ documents }: IOwnProps): React.JSX.Element => {
  const motorfunctionDocs = documents && documents.filter((d) => d._type === 'motorfunction');
  const mobilityAidDocs = documents && documents.filter((d) => d._type === 'mobilityAid');

  return (
    <FormEditingHandler name="motorFunctionAndMobilityAid" documents={documents}>
      {(editing, startEdit, formData, view): React.JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="motorFunctionAndMobilityAid"
            headerId={
              docType(documents, editing, view) === 'motorfunction'
                ? 'motorFunctionAndMobilityAid.motorfunction.title'
                : docType(documents, editing, view) === 'mobilityAid'
                  ? 'motorFunctionAndMobilityAid.aids.title'
                  : 'motorFunctionAndMobilityAid.title'
            }
            editing={editing}
            editButtons={<div />}
          />

          {editing ? (
            <Form formData={formData} document={documents && documents.find((d: TDocument) => d._id === editing)} />
          ) : null}

          {!editing ? (
            <>
              <MotorFunctionHistory documents={motorfunctionDocs} startEdit={startEdit} />
              <MobilityAidHistory documents={mobilityAidDocs} startEdit={startEdit} />
            </>
          ) : null}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

type TDocument = IMotorFunction | IAid;

interface IOwnProps {
  documents: Array<TDocument>;
}

export default MotorFunction;
