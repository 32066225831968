import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import { exists, formatPartialDate } from 'neuro-utils';

import { mocaTotalScore } from '../../utils/index';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const MOCA = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id="cognition.moca.moca" />}
      newButton={
        <DocumentCreationButton
          name="moca"
          text={'cognition.moca.newMoca'}
          onClick={startEdit({}, 'moca')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id="general.date" />
            </Item>
            <Item xs={3}>
              <FormattedMessage id="cognition.moca.moca" />
            </Item>
            <Item xs={5}>
              {documents.some((d) => d.unableToPerform === true) ? <FormattedMessage id="general.reasonOther" /> : ''}
            </Item>
          </>
        }
        contentFormat={(d: IMOCA): React.JSX.Element => (
          <>
            <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
            <Item xs={3}>
              {d.unableToPerform ? (
                <FormattedMessage id="cognition.unableToPerform" />
              ) : d.totalScoreOnly?.[0] ? (
                d.totalScore
              ) : (
                mocaTotalScore(d)
              )}
            </Item>
            <Item xs={4}>
              {d.unableToPerform ? (exists(d.unableToPerformReason) ? d.unableToPerformReason : '-') : ''}
            </Item>
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} view={view} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IMOCA[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default MOCA;
