import * as React from 'react';
import { TDocument } from '../../utils';
import UHDRSFunctionalAssessmentForm from './UHDRSFunctionalAssessment';
import UHDRSFunctionalCapacityForm from './UHDRSFunctionalCapacity';
import UHDRSIndependenceScaleForm from './UHDRSIndependenceScale';
import ModifiedRankinScaleForm from './ModifiedRankinScale';
import ModifiedChildrensGlobalAssessmentScaleForm from './ModifiedChildrensGlobalAssessmentScale';

const FunctionalPerformanceForm = ({ formData, document, viewing = false }: IOwnProps): React.JSX.Element | null =>
  document?._type === 'uhdrsFunctionalAssessment' ? (
    <UHDRSFunctionalAssessmentForm formData={formData as IFormData<IUHDRSFunctionalAssessment>} viewing={viewing} />
  ) : document?._type === 'uhdrsIndependenceScale' ? (
    <UHDRSIndependenceScaleForm formData={formData as IFormData<IUHDRSIndependenceScale>} viewing={viewing} />
  ) : document?._type === 'uhdrsFunctionalCapacity' ? (
    <UHDRSFunctionalCapacityForm formData={formData as IFormData<IUHDRSFunctionalCapacity>} viewing={viewing} />
  ) : document?._type === 'modifiedRankinScale' ? (
    <ModifiedRankinScaleForm formData={formData as IFormData<IModifiedRankinScale>} viewing={viewing} />
  ) : document?._type === 'modifiedChildrensGlobalAssessmentScale' ? (
    <ModifiedChildrensGlobalAssessmentScaleForm
      formData={formData as IFormData<IModifiedChildrensGlobalAssessmentScale>}
      viewing={viewing}
    />
  ) : null;

interface IOwnProps {
  formData: IFormData<TDocument>;
  document?: TDocument;
  viewing?: boolean;
}

export default FunctionalPerformanceForm;
