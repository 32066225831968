import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistorySection from '../../../components/HistorySection';
import HistoryRow from '../../../components/HistoryRow';
import HistoryRowControls from '../../../components/HistoryRowControls';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import HistoryRowData from './HistoryRowData';
import Form from './Form';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../components/DocumentCreationButton';
import { isEnded } from '../../../utility/isEnded';

const activeTherapy = (
  documents: ITherapy[],
  startEdit: (document: TAnyObject) => (e: React.MouseEvent) => void,
): React.JSX.Element | undefined => {
  return documents.length > 0 ? (
    <HistorySection headerText={<FormattedMessage id="therapy.activeTherapies" />} hasHistoryRows={true}>
      {documents
        .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
        .reverse()
        .map((d: ITherapy, i: number) => (
          <HistoryRow
            headerText={
              <div id={'therapyDate'}>
                {d.date ? (
                  <FormattedMessage id="therapy.startingDate" values={{ date: formatPartialDate(d.date) }} />
                ) : undefined}
              </div>
            }
            key={i + 'active'}
            rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
            controlsMargin={false}
          >
            <HistoryRowData d={d} />
          </HistoryRow>
        ))}
    </HistorySection>
  ) : undefined;
};

const endedTherapy = (
  documents: ITherapy[],
  startEdit: (document: TAnyObject) => (e: React.MouseEvent) => void,
): React.JSX.Element | undefined => {
  return documents.length > 0 ? (
    <HistorySection
      headerText={<FormattedMessage id="therapy.inActiveTherapies" />}
      inactive={true}
      hasHistoryRows={true}
    >
      {documents
        .sort((n1, n2) => sortPartialDate(n1.endDate, n2.endDate))
        .reverse()
        .map((d: ITherapy, i: number) => (
          <HistoryRow
            headerText={
              d.date ? formatPartialDate(d.date) + ' - ' + (d.endDate ? formatPartialDate(d.endDate) : '') : undefined
            }
            key={i + 'active'}
            rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
            controlsMargin={false}
          >
            <HistoryRowData d={d} />
          </HistoryRow>
        ))}
    </HistorySection>
  ) : undefined;
};

const Therapy = ({ documents }: IOwnProps): React.JSX.Element => (
  <FormEditingHandler name="therapy" documents={documents}>
    {(editing, startEdit, formData): React.JSX.Element => (
      <DocumentWrapper>
        <DocumentHeader
          name="therapy"
          headerId={'therapy.title'}
          editing={editing}
          editButtons={
            <div>
              <DocumentCreationButton name="therapy" text={'general.new'} onClick={startEdit({})} />
            </div>
          }
        />
        {!editing && documents ? (
          <div>
            {activeTherapy(
              documents.filter((d: ITherapy) => !isEnded(d.endDate)),
              startEdit,
            )}
            {endedTherapy(
              documents.filter((d: ITherapy) => isEnded(d.endDate)),
              startEdit,
            )}
          </div>
        ) : undefined}
        {editing ? (
          <Form
            formData={formData}
            editing={editing}
            document={documents && documents.find((d: ITherapy) => d._id === editing)}
          />
        ) : undefined}
      </DocumentWrapper>
    )}
  </FormEditingHandler>
);

interface IOwnProps {
  documents?: ITherapy[];
}

export default Therapy;
