import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate } from 'neuro-utils';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';

const GeneratorDetails = ({ generator }: IOwnProps): React.JSX.Element => {
  const makeElementFromValue = (generatorName: string): React.JSX.Element => {
    switch (generatorName) {
      case 'Other rechargeable':
        return <FormattedMessage id="dbs.otherRechargeable" />;
      case 'Other battery':
        return <FormattedMessage id="dbs.otherPrimaryCell" />;
      default:
        return <>{generatorName}</>;
    }
  };

  return (
    <>
      <StepperHeaderValuePair
        header={<FormattedMessage id={'dbs.generator'} />}
        value={generator.generator && makeElementFromValue(generator.generator)}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id={'dbs.nextChange'} />}
        value={generator.generatorNextChangeDate && formatPartialDate(generator.generatorNextChangeDate)}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id={'dbs.generatorSerialNo'} />}
        value={generator.generatorSerialNo ?? undefined}
      />
    </>
  );
};

interface IOwnProps {
  generator: IDBSGenerator;
}

export default GeneratorDetails;
