import DataTable from 'Components/DataTable';
import HistoryRowControls from 'Components/HistoryRowControls';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';
import colors from '../../../../config/theme/colors';
import InputHandler from 'Components/InputHandler';

const MgravisHospitalizationHistory = ({ documents, startEdit }: IOwnProps): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [historyFilter, setHistoryFilter] = React.useState<'all' | 'hospitalWard' | 'intensiveCare'>('all');
  const [shownDocuments, setShownDocuments] = React.useState(documents);

  const onChangeHistoryFilter = (values: TOnChangeValues) => {
    const name = Object.keys(values)[0];
    const value = values[name];
    setHistoryFilter(value as 'all' | 'hospitalWard' | 'intensiveCare');
  };

  React.useEffect(() => {
    if (historyFilter === 'all') {
      setShownDocuments(documents);
    } else if (historyFilter === 'hospitalWard') {
      const hospWardDocs = documents?.filter((d) => d?.department === 'hospitalWard');
      setShownDocuments(hospWardDocs);
    } else if (historyFilter === 'intensiveCare') {
      const intensityCareDocs = documents?.filter((d) => d?.department === 'intensiveCare');
      setShownDocuments(intensityCareDocs);
    }
  }, [historyFilter, documents]);

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '5.6rem',
          backgroundColor: colors.appBlue.lightest,
          borderRadius: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '6rem' }}>
          <span style={{ color: colors.appBlue.default, paddingLeft: '2rem', marginRight: '1rem' }}>
            {fm('comorbidity.mgravis.show')}
          </span>
          <div>
            <InputHandler
              type="Select"
              name="historyFilter"
              editing={true}
              options={['all', 'hospitalWard', 'intensiveCare']}
              optionFormatter={(o) => fm(`comorbidity.mgravis.filter.${o}`)}
              formData={{
                onChange: onChangeHistoryFilter,
                document: { historyFilter: historyFilter },
              }}
            />
          </div>
        </div>
      </div>
      <DataTable
        headers={[
          fm('comorbidity.mgravis.startDate'),
          fm('comorbidity.mgravis.endDate'),
          fm('comorbidity.mgravis.period'),
          '',
        ]}
        data={
          shownDocuments && {
            rowData: shownDocuments.map((d) => [
              formatPartialDate(d?.date),
              formatPartialDate(d?.endDate),
              fm(`comorbidity.mgravis.${d?.department}`),
              startEdit ? <HistoryRowControls key={d._id} document={d} startEdit={startEdit} newStyle /> : '',
            ]),
          }
        }
      />
    </>
  );
};

interface IOwnProps {
  documents?: Array<IHospitalization>;
  startEdit?: (
    document: {
      _id?: string | undefined;
    },
    name?: string | undefined,
  ) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default MgravisHospitalizationHistory;
