import * as React from 'react';
import FormRow from '../../../../../../components/FormRow';
import InputHandler from '../../../../../../components/InputHandler';
import OptionChooser from '../../../../../../components/OptionChooser';
import colors from '../../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';

const PerformanceRatingForm = ({ editing, formData, fm }: IFormContext<IPerformanceRating>): React.JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!!editing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="diagnosis.sleepApnea.performanceRating.rating" bottomMargin>
      <OptionChooser
        formData={formData}
        name="rating"
        opts={[0, 1, 2, 3, 4]}
        optionFormatter={(name: string | number): React.JSX.Element => (
          <span>{fm(`diagnosis.sleepApnea.performanceRating.opts.${name}`)}</span>
        )}
      />
    </FormRow>
    <span
      style={{
        fontStyle: 'italic',
        color: colors.darkGray,
      }}
    >
      Oken M, Creech R, Tormey D, et al. Toxicity and response criteria of the Eastern Cooperative Oncology Group. Am J
      Clin Oncol. 1982;5:649-655.
    </span>
  </React.Fragment>
);

export default withFormContext(PerformanceRatingForm);
