import * as React from 'react';
import { OperationContext } from '../../config';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';

const IntracranialImaging = ({ formData, editing, view, fm }: IFormContext): React.JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const { document, onChange } = formData as IFormData<ISurgicalTreatment>;

  return (
    <React.Fragment>
      <OperationContext.Consumer>
        {({ operationIndex }): React.JSX.Element => {
          const operations = document.operations?.map((o) => o) || [];
          const imaging = operations?.[operationIndex ?? 0] as IIntracranialImaging;

          const imagingOnChange = (values: TOnChangeValues): void => {
            const changedOperation = { ...imaging, ...values };
            operations[operationIndex ?? 0] = changedOperation;
            onChange && onChange({ operations: operations });
          };

          return (
            <>
              <FormRow title="surgicalTreatment.operation.typeOfOperation.opts.intracranialImaging">
                <InputHandler
                  type="Radio"
                  name="imagingType"
                  editing={editingDoc}
                  formData={{ document: { imagingType: imaging.imagingType }, onChange: imagingOnChange }}
                  options={['stereoEEG', 'grid', 'strip']}
                  optionFormatter={(opt: string | number): string =>
                    fm('surgicalTreatment.intracranialImaging.opts.' + opt)
                  }
                />
              </FormRow>
              <FormRow title="surgicalTreatment.stereoEEG.electrodeNumber.title">
                <InputHandler
                  type="NumberField"
                  name="electrodeNumber"
                  editing={editingDoc}
                  formData={{ document: { electrodeNumber: imaging.electrodeNumber }, onChange: imagingOnChange }}
                  placeholder={'surgicalTreatment.stereoEEG.electrodeNumber.placeholder'}
                  precision={0}
                  max={100}
                />
              </FormRow>
              <FormRow title="surgicalTreatment.stereoEEG.electrodeLocation.title">
                <InputHandler
                  type="Radio"
                  name="electrodeLocation"
                  editing={editingDoc}
                  formData={{ document: { electrodeLocation: imaging.electrodeLocation }, onChange: imagingOnChange }}
                  options={['left', 'right', 'both']}
                  optionFormatter={(opt: string | number): string =>
                    fm('surgicalTreatment.stereoEEG.electrodeLocation.opts.' + opt)
                  }
                />
              </FormRow>
            </>
          );
        }}
      </OperationContext.Consumer>
    </React.Fragment>
  );
};

export default withFormContext(IntracranialImaging);
