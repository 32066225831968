import * as React from 'react';
import { exists, formatPartialDate, isPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { defaultPapTreatmentResponseUnits } from '../../../config';
import { StepperHeaderValuePair } from '../../../../../../components/EventStepper/components';
import { mappedTreatmentResponseFields } from '../config';
import EventViewer from 'Components/EventViewer';

type TValueTypes = string | number | PartialDate;

const FormattedValue = ({ name, value }: { name: string; value?: TValueTypes }): React.JSX.Element => (
  <React.Fragment>
    {exists(value) ? (
      name === 'startDateOfPeriodUnderReview' && isPartialDate(value) ? (
        formatPartialDate(value)
      ) : name === 'targetVentilationAchievedInPlusMinus10PercentPrecision' ? (
        fm(`treatment.opts.yesNoUnknown.${value}`)
      ) : (
        <span style={{ fontWeight: 600 }}>
          {value}{' '}
          {['day', 'inhalationsPerMinute'].includes(defaultPapTreatmentResponseUnits[name])
            ? fm(`treatment.papTherapy.${defaultPapTreatmentResponseUnits[name]}`)
            : defaultPapTreatmentResponseUnits[name] ?? ''}
        </span>
      )
    ) : (
      '-'
    )}
  </React.Fragment>
);

const ResMedTreatmentResponses = ({ treatmentResponses = [] }: IOwnProps): React.JSX.Element => {
  const dates = treatmentResponses
    ?.map((treatmentResponse) => treatmentResponse.date)
    .filter((date) => isPartialDate(date)) as PartialDate[];

  return (
    <EventViewer
      name="treatmentResponses"
      formData={{ onChange: () => '', document: { treatmentResponses: treatmentResponses } }}
      dateFieldName="date"
      content={(d: IPapTreatmentResponse): React.JSX.Element => {
        const fields = Object.values(mappedTreatmentResponseFields);
        fields.splice(fields.indexOf('maskLeakage95PercentilePerSecond'), 0, 'maskLeakage95PercentilePerMinute');
        return (
          <React.Fragment key={d.id}>
            {['patientHoursOfUsePerDay' /**, 'daysWithLessThan4HoursOfUse', 'daysWithLessThan4HoursOfUsePercentage'*/]
              .concat(fields)
              .map((field, index) => {
                const value = d[field as keyof IPapTreatmentResponse];
                return exists(value) ? (
                  <StepperHeaderValuePair
                    key={`${field}${index}`}
                    header={fm(`treatment${field === 'startDateOfPeriodUnderReview' ? '.' : '.papTherapy.'}${field}`)}
                    value={<FormattedValue name={field} value={value} />}
                  />
                ) : null;
              })}
          </React.Fragment>
        );
      }}
      header="treatment.papTherapy.treatmentResponses"
      dateDefault={dates[0]}
      enabledDatePickerDates={dates}
      noEventsTextHeader="treatment.papTherapy.noTreatmentResponses"
    />
  );
};

interface IOwnProps {
  formData: IFormData<IPAPTherapy>;
  treatmentResponses?: Array<IPapTreatmentResponse>;
}

export default ResMedTreatmentResponses;
