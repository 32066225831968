import { flatten } from 'ramda';
import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

import UPDRSDialog from '../UPDRSDialog/updrsDialog';

import { calculateScore, isUpdrsComplete } from '../../../utils';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { Container, Item } from '../../../../../components/Grid';
import colors from '../../../../../config/theme/colors';
import { path } from 'Utility/ramdaReplacement';

const beforeDoc = (documents: IOwnProps['documents'], formData: IOwnProps['formData']): IUPDRSIII | undefined =>
  (documents.find((d) => path(['document', 'updrsIIIBeforeID'], formData) === d._id) as IUPDRSIII) || undefined;

const afterDoc = (documents: IOwnProps['documents'], formData: IOwnProps['formData']): IUPDRSIII | undefined =>
  (documents.find((d) => path(['document', 'updrsIIIAfterID'], formData) === d._id) as IUPDRSIII) || undefined;

const calculateDBS = (documents: IOwnProps['documents'], formData: IOwnProps['formData']): number =>
  beforeDoc(documents, formData) && afterDoc(documents, formData)
    ? calculateScore(beforeDoc(documents, formData), afterDoc(documents, formData))
    : 0;

interface IPrograms {
  id: string;
  name: string;
}
interface IDateSettingPair {
  settingDate?: PartialDate;
  programs?: Array<IPrograms>;
}

interface ISettingOptions {
  title: string;
  values: Array<string>;
}

const DBSEffectForm = ({ formData, documents, viewing, setSubDocuments }: IOwnProps): React.JSX.Element => {
  const { document, onChange } = formData;

  const commitsLength = documents.find((d) => d._editing === true)?._commitsLength;
  const beforeCommitsLength = beforeDoc(documents, formData)?._commitsLength;
  const afterCommitsLength = afterDoc(documents, formData)?._commitsLength;

  const dbs = React.useMemo(() => {
    return documents.filter((d) => d._type === 'dbs') as Array<IDBS>;
  }, [documents]);

  const findDbsSettings = (dbs: Array<IDBS>): Array<IDateSettingPair> => {
    const settings = flatten(dbs ? dbs.map((d) => d.settings) : []);
    const dateProgramPairs =
      settings.length > 0
        ? settings
            .map((s) => ({
              settingDate: s?.generatorAdjustedDate,
              programs: s?.programs
                ? (s.programs.map((p) => ({ id: p.id, name: p.programName })) as Array<IPrograms>)
                : [],
            }))
            .sort((a, b) => sortPartialDate(a.settingDate, b.settingDate))
            .reverse()
        : [];
    return dateProgramPairs;
  };

  const [dbsSettings, setDbsSettings] = React.useState<Array<IDateSettingPair>>([]);
  React.useEffect(() => {
    if (dbs.length > 0) setDbsSettings(findDbsSettings(dbs));
  }, [dbs]);

  const findDbsSettingOptions = (settings: Array<IDateSettingPair>): Array<ISettingOptions> => {
    const options = flatten(
      settings.map((s) => ({
        title: formatPartialDate(s.settingDate),
        values: s.programs ? s.programs.map((p) => p.id) : [],
      })),
    );
    return options;
  };

  const settingOptionFormatter = (name: string | number, settings: Array<IDateSettingPair>): string =>
    flatten(settings.map((s) => s.programs)).find((p) => p?.id === name)?.name ?? '';

  //const [dbsSettingOptions, setDbsSettingOptions] = React.useState<Array<ISettingOptions>>([]);
  const dbsSettingOptions = React.useRef<Array<ISettingOptions> | false>(false);
  React.useEffect(() => {
    if (dbsSettings.length > 0) dbsSettingOptions.current = findDbsSettingOptions(dbsSettings);
  }, [dbsSettings]);

  React.useEffect(() => {
    // Make here the decision of which test is rendered and which test is the alternative one
    let temp: 'UPDRS' | 'MDSUPDRS';

    if (documents && documents.length > 0) {
      if (
        documents.filter(
          (doc) =>
            doc._type === 'dbsEffect' && (doc as IDBSEffect).testType === 'UPDRS' && (doc as IDBSEffect).type === 'dbs',
        ).length > 0
      ) {
        temp = 'UPDRS';
      } else {
        temp = 'MDSUPDRS';
      }
    } else {
      temp = 'MDSUPDRS';
    }

    if (!document.testType) {
      onChange &&
        onChange({
          testType: temp,
        });
    }
  }, [document.testType]);

  React.useEffect(() => {
    const subDocuments = [];
    if (!viewing && commitsLength) {
      // Clear updrsIIIBeforeID or/and updrsIIIAfterID if those documents no longer exist
      if (document.updrsIIIBeforeID && !documents.find((d) => d._id === document.updrsIIIBeforeID)) {
        onChange && onChange({ updrsIIIBeforeID: undefined });
      }
      if (document.updrsIIIAfterID && !documents.find((d) => d._id === document.updrsIIIAfterID)) {
        onChange && onChange({ updrsIIIAfterID: undefined });
      }

      // Make subdocuments list from IDs
      if (beforeCommitsLength && beforeCommitsLength >= commitsLength && document.updrsIIIBeforeID) {
        subDocuments.push({ name: 'updrs_iii', id: document.updrsIIIBeforeID });
      }
      if (afterCommitsLength && afterCommitsLength >= commitsLength && document.updrsIIIAfterID) {
        subDocuments.push({ name: 'updrs_iii', id: document.updrsIIIAfterID });
      }
      setSubDocuments && setSubDocuments(subDocuments);
    }
  }, [commitsLength, beforeCommitsLength, afterCommitsLength]);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!viewing}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title="updrs.updrsOrMdsupdrs">
          <InputHandler
            type="Radio"
            name="testType"
            editing={!viewing}
            formData={formData}
            options={['UPDRS', 'MDSUPDRS']}
            optionFormatter={(n: string | number): React.JSX.Element => (
              <FormattedMessage id={`updrs.${n === 'UPDRS' ? 'updrsIII' : 'mds'}`} />
            )}
            disabledOptions={document.updrsIIIBeforeID || document.updrsIIIAfterID ? ['UPDRS', 'MDSUPDRS'] : []}
          />
        </FormRow>
        <FormRow title="updrs.dbsOldSettings" condition={!!dbsSettingOptions.current || viewing}>
          <Container>
            <Item style={{ justifyContent: 'flex-start', marginRight: '2rem' }}>
              <InputHandler
                type="SelectByCategory"
                editing={!viewing}
                name="dbsOldSettings"
                formData={formData}
                options={Array.isArray(dbsSettingOptions.current) ? dbsSettingOptions.current : []}
                optionFormatter={(name: string | number): string => settingOptionFormatter(name, dbsSettings)}
                placeholder="updrs.chooseDbsSettings"
                fullWidth={true}
              />
            </Item>
            <Item style={{ justifyContent: 'flex-start', marginTop: '0.5rem', color: `${colors.secondaryText}` }}>
              {`${
                dbsSettings.filter((s) =>
                  (s.programs ? s.programs.map((p) => p.id) : []).includes(document.dbsOldSettings || 'undefinedID'),
                )[0]?.settingDate
                  ? formatPartialDate(
                      dbsSettings.filter((s) =>
                        (s.programs ? s.programs.map((p) => p.id) : []).includes(
                          document.dbsOldSettings || 'undefinedID',
                        ),
                      )[0]?.settingDate,
                    )
                  : ''
              }`}
            </Item>
          </Container>
        </FormRow>
        <FormRow title="updrs.updrsWithoutDBS">
          <UPDRSDialog
            name="updrsIIIBeforeID"
            parentDate={document?.date}
            onChange={onChange}
            document={beforeDoc(documents, formData)}
            viewing={viewing}
            context={'dbs'}
            testType={document.testType || 'MDSUPDRS'}
          />
        </FormRow>
        <FormRow title="updrs.dbsNewSettings" condition={!!dbsSettingOptions.current || viewing}>
          <Container>
            <Item style={{ justifyContent: 'flex-start', marginRight: '2rem' }}>
              <InputHandler
                type="SelectByCategory"
                editing={!viewing}
                name="dbsNewSettings"
                formData={formData}
                options={Array.isArray(dbsSettingOptions.current) ? dbsSettingOptions.current : []}
                optionFormatter={(name: string | number): string => settingOptionFormatter(name, dbsSettings)}
                placeholder="updrs.chooseDbsSettings"
              />
            </Item>
            <Item style={{ justifyContent: 'flex-start', marginTop: '0.5rem', color: `${colors.secondaryText}` }}>
              {`${
                dbsSettings.filter((s) =>
                  (s.programs ? s.programs.map((p) => p.id) : []).includes(document.dbsNewSettings || 'undefinedID'),
                )[0]?.settingDate
                  ? formatPartialDate(
                      dbsSettings.filter((s) =>
                        (s.programs ? s.programs.map((p) => p.id) : []).includes(
                          document.dbsNewSettings || 'undefinedID',
                        ),
                      )[0]?.settingDate,
                    )
                  : ''
              }`}
            </Item>
          </Container>
        </FormRow>
        <FormRow title="updrs.updrsWithDBS">
          <UPDRSDialog
            name="updrsIIIAfterID"
            parentDate={document?.date}
            onChange={onChange}
            document={afterDoc(documents, formData)}
            viewing={viewing}
            context={'dbs'}
            retro={beforeDoc(documents, formData)?.retrospectiveData?.[0] === true}
            testType={document.testType || 'MDSUPDRS'}
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <FormRow title="updrs.dbsEffectScore">
          {/* (Before - After) / Before * 100% */}
          {beforeDoc(documents, formData) &&
          afterDoc(documents, formData) &&
          isUpdrsComplete(beforeDoc(documents, formData), document.testType) &&
          isUpdrsComplete(afterDoc(documents, formData), document.testType)
            ? Math.round(calculateDBS(documents, formData) * 100) / 100 + ' %'
            : '-'}
        </FormRow>
        <FormRow title="updrs.DBStestScoreManual">
          <InputHandler
            type="NumberField"
            editing={!viewing}
            name="manualScore"
            formData={formData}
            width={4.7}
            height={3}
            maxLength={5}
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IDBSEffect>;
  documents: Array<IUPDRSIII | IDBSEffect | IDBS>;
  viewing?: boolean;
  setSubDocuments?: (subDocuments: { name: string; id: string }[]) => void;
}

export default DBSEffectForm;
