import * as React from 'react';
import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import { Container, Item } from 'Components/Grid';
import colors from '../../../../../../config/theme/colors';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';

const TreatmentPeriodForm = ({
  classicForm = true,
  formData,
  editing,
  saveDraft,
  fm,
}: IFormContext & IOwnProps): React.JSX.Element => {
  if (classicForm) {
    return (
      <FormSection>
        <FormRow title="diagnosis.ninmt.treatmentDecisionDate">
          <InputHandler
            type="PartialDate"
            name="date"
            formData={formData}
            editing={!!editing}
            dateDefault={formData.document.date ? undefined : 'now'}
          />
        </FormRow>
      </FormSection>
    );
  }
  return (
    <Container
      style={{ paddingLeft: 0, marginBottom: '4.8rem', marginTop: '4rem', paddingTop: '5rem', display: 'flex' }}
    >
      <Item xs={3} style={{ color: colors.tertiaryText }}>
        {fm('diagnosis.ninmt.treatmentDecisionDate')}
      </Item>
      <Item xs={5} md={3} lg={3}>
        <InputHandler type="PartialDate" name="date" formData={formData} editing={!!editing} dateDefault="now" />
      </Item>
      {editing && (
        <Item style={{ marginRight: '2.5rem' }}>
          <ActionButtonRounded
            text={'general.save'}
            onClick={saveDraft && saveDraft(editing)}
            width={7}
            height={3}
            fontSize={16}
            uppercase={false}
          />
        </Item>
      )}
    </Container>
  );
};

interface IOwnProps {
  classicForm?: boolean;
}

export default withFormContext(TreatmentPeriodForm);
