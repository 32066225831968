import * as React from 'react';

import { Item } from 'Components/Grid';
import HistoryRowControls from 'Components/HistoryRowControls';
import HistoryRowListing from 'Components/HistoryRowListing';
import HistorySection from 'Components/HistorySection';

import DocumentCreationButton from 'Components/DocumentCreationButton';
import { fm } from 'Components/FormatMessage';

const ThymusMRI = ({ documents, startEdit }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={fm('imaging.thymusMri')}
      newButton={
        <DocumentCreationButton
          name="thymusMri"
          text={'imaging.newThymusMri'}
          onClick={startEdit({} as IThymusMRI, 'thymusMri')}
          width={15}
          height={3}
          fontSize={12}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <React.Fragment>
            <Item xs={4}>{fm('imaging.finding')}</Item>
          </React.Fragment>
        }
        contentFormat={(d: IThymusCT): React.JSX.Element => (
          <Item xs={4}>
            {Array.isArray(d.finding) && d.finding.length > 0
              ? d.finding.map((f, i, arr) => (
                  <span key={i}>
                    {fm(`imaging.opts.finding.${f}`)}
                    {i < arr.length - 1 ? ', ' : ''}
                  </span>
                ))
              : d.finding
                ? fm(`imaging.opts.finding.${d.finding}`)
                : '-'}
          </Item>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IThymusMRI[];
  startEdit: (document: IThymusMRI, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default ThymusMRI;
