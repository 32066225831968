import { isNil } from 'Utility/ramdaReplacement';
import * as React from 'react';
import { OperationContext } from '../../config';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';

const stepLabelText = (d: ICyberKnifeArea, fm?: (id: string) => string): string =>
  fm ? fm(`surgicalTreatment.cyberKnife.areas.area.opts.${d.area}`) : '';

const StepContent = ({ d, fm }: { d: ICyberKnifeArea; fm?: (id: string) => string }): React.JSX.Element => {
  const locPath = 'surgicalTreatment.cyberKnife.areas';
  return (
    <React.Fragment>
      {d.side && (
        <StepperHeaderValuePair
          header={<>{fm ? fm(`${locPath}.side.title`) : ''}</>}
          value={fm ? fm(`${locPath}.side.opts.${d.side}`) : ''}
        />
      )}
      {d.other && <StepperHeaderValuePair header={<>{fm ? fm(`${locPath}.other.title`) : ''}</>} value={d.other} />}
    </React.Fragment>
  );
};

const optionFormatter = (id: string | number, p: string, fm?: (id: string) => string): string =>
  fm ? fm(`surgicalTreatment.cyberKnife.${p}.${id}`) : '';

const EditingElement = ({
  formData,
  editing,
  fm,
}: {
  formData: IFormData<ICyberKnifeArea>;
  editing: boolean;
  fm?: (id: string) => string;
}): React.JSX.Element => {
  const area = formData.document?.area;

  return (
    <React.Fragment>
      <FormRow title="surgicalTreatment.cyberKnife.areas.area.title">
        <InputHandler
          type="Select"
          name="area"
          editing={editing}
          formData={formData}
          options={[
            'hypothalamus',
            'frontalBlock',
            'temporalBlock',
            'crownBlock',
            'backOfTheSkullBlock',
            'subcorticalStructs',
            'cerebellum',
            'other',
          ]}
          optionFormatter={(id: string | number): string => optionFormatter(id, 'areas.area.opts', fm)}
          placeholder="surgicalTreatment.cyberKnife.areas.area.placeholder"
          dependentFieldsList={(): string[] => ['side', 'other']}
        />
      </FormRow>
      {area === 'hypothalamus' && (
        <FormRow title="surgicalTreatment.cyberKnife.areas.side.title">
          <InputHandler
            type="Radio"
            name="side"
            editing={editing}
            formData={formData}
            options={['left', 'right']}
            optionFormatter={(id: string | number): string => optionFormatter(id, 'areas.side.opts', fm)}
            placeholder="surgicalTreatment.cyberKnife.areas.side.placeholder"
          />
        </FormRow>
      )}
      {area === 'other' && (
        <FormRow title="surgicalTreatment.cyberKnife.areas.other.title">
          <InputHandler
            type="TextArea"
            name="other"
            editing={editing}
            formData={formData}
            placeholder="surgicalTreatment.cyberKnife.areas.other.placeholder"
          />
        </FormRow>
      )}
    </React.Fragment>
  );
};

const CyberKnife = ({ formData, editing, view, fm }: IFormContext): React.JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const { document, onChange } = formData as IFormData<ISurgicalTreatment>;

  return (
    <React.Fragment>
      <OperationContext.Consumer>
        {({ operationIndex }): React.JSX.Element => {
          const allOperations = document.operations?.map((o) => o) || [];
          const cyberKnife = allOperations?.[operationIndex ?? 0] as ICyberKnife;
          const areas = cyberKnife.areas;

          const cyberKnifeOnChange = (values: TOnChangeValues): void => {
            const value = values['areas'];
            const newAreas = value;
            const changedOperation = { ...cyberKnife, areas: newAreas };
            allOperations[operationIndex ?? 0] = changedOperation;
            onChange && onChange({ operations: allOperations });
          };

          return (
            <EventStepper
              name="areas"
              formData={{ document: { areas: areas }, onChange: cyberKnifeOnChange }}
              stepLabelText={(d: ICyberKnifeArea): string => stepLabelText(d, fm)}
              stepContent={(d: ICyberKnifeArea): React.JSX.Element => <StepContent d={d} fm={fm} />}
              addNewTextHeader="surgicalTreatment.cyberKnife.areas.title"
              addNewTextButton="surgicalTreatment.cyberKnife.areas.add"
              previousEventsTextHeader="surgicalTreatment.cyberKnife.areas.previous"
              editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => {
                return (
                  <React.Fragment>
                    {!isNil(operationIndex) ? (
                      <EditingElement
                        formData={
                          { document: areas?.[index], onChange: onChange } as {
                            document: ICyberKnifeArea;
                            onChange: IFormData['onChange'];
                          }
                        }
                        editing={editingDoc}
                        fm={fm}
                      />
                    ) : undefined}
                  </React.Fragment>
                );
              }}
            />
          );
        }}
      </OperationContext.Consumer>
    </React.Fragment>
  );
};

export default withFormContext(CyberKnife);
