import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import { bdi, isLocaleKey, Task_Progress } from 'neuro-schemas';
import * as React from 'react';
import { IMyServiceContext, MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import { bdiIncludeOnlyNumbers } from 'Routes/MyService/util';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { formatPartialDate } from 'neuro-utils';
import DataTable from 'Components/DataTable';
import QuestionAnswerPair from 'Components/_NewElements/QuestionAnswerPair';

const BDIScore = ({
  bdiData,
  fm,
}: {
  bdiData: IBDI & IControlProps;
  fm: IMyServiceContext['fm'];
}): React.JSX.Element => {
  const score = Task_Progress.calculateProgress('bdi', bdiData).yielded;
  const localization = score
    ? fm(`myService.ninmt.bdiTotalScoreDescOpts.${bdi.calculators.bdiDepressionDesc(score)}`)
    : null;
  return (
    <div>
      <span style={{ fontWeight: 600 }}>{score ? score + ' ' : '-'}</span>
      <span>{localization}</span>
    </div>
  );
};

export const Bdi = (): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing, setViewingObj, setEditingObj } = myServContext;

  const bdiData = (editing?.data || viewing?.data || {}) as IBDI & IControlProps;

  const bdiQuestions = bdi.surveyGenerator.questions(bdiData?.['19'] ? { question19Answer: bdiData['19'] } : undefined);
  const bdiLocales = bdi.localizations;

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...bdiData,
      [field]: field !== 'losingWeightOnPurpose' && typeof value === 'string' ? parseInt(value) : value,
    });
  };
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const isEditing = !viewing && !!editing;

  const bdiQuestion = (id: string) =>
    bdiQuestions.find((bdiq) => bdiq.id === id)?.options?.[(bdiData as Record<string, any>)[id]];

  const questionLocalized = (qid: string) => {
    const localeString = bdiQuestion(qid)?.optionLocale;
    return localeString && bdiLocales[useLocale][localeString];
  };

  if (viewing) {
    return (
      <>
        <BlockWrapper
          title={formatPartialDate(bdiData.date)}
          buttons={[
            {
              title: 'general.edit',
              onClick: () => {
                setViewingObj(null);
                setEditingObj({ type: 'bdi', data: bdiData });
              },
            },
          ]}
        >
          <div style={{ width: '80%', marginBottom: '2rem' }}>
            <DataTable
              headers={['question', 'answer', 'score']}
              headersFormatter={(s: string) => fm('myService.' + s)}
              data={{
                rowData: Object.keys(bdiIncludeOnlyNumbers(bdiData))
                  .filter((q) => q !== 'date')
                  .map((q) => [
                    q,
                    <span key={q + 'long'} style={{ fontWeight: 600, color: colors.black }}>{`${questionLocalized(
                      q,
                    )}`}</span>,
                    <span key={q + 'short'} style={{ fontWeight: 600, color: colors.black }}>{`${bdiQuestion(q)
                      ?.value}`}</span>,
                  ]),
              }}
            />
          </div>
          <QuestionAnswerPair question={fm('myService.score')} answer={<BDIScore bdiData={bdiData} fm={fm} />} />
        </BlockWrapper>
      </>
    );
  }

  return (
    <>
      <div style={{ fontStyle: 'italic', marginBottom: '2rem', color: colors.tertiaryText }}>
        <span style={{ fontWeight: 600 }}>{`${bdiLocales[useLocale][
          bdiQuestions[0].questionLocale ?? '-'
        ].toUpperCase()}: `}</span>
        <span>{bdiLocales[useLocale][bdiQuestions[0].infoTextLocale ?? '-']}</span>
      </div>
      <FormSection>
        <>
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              editing={isEditing}
              isNotCancellable={true}
              formData={{
                document: { date: bdiData?.date },
                onChange: onChangeSurveyForm,
              }}
              dateDefault="now"
            />
          </FormRow>
          {bdiQuestions.map((q, i) => {
            if (i === 0) return;
            return (
              <FormRow
                key={`${q.id}-${i}`}
                title={
                  q.id === 'losingWeightOnPurpose' ? bdiLocales[useLocale]['questions.losingWeightOnPurpose'] : q.text
                }
                formatTitle={false}
              >
                <InputHandler
                  type="Radio"
                  name={q.id}
                  options={q.options?.map((o) => o.value)}
                  editing={isEditing}
                  optionFormatter={(o) =>
                    q.id === 'losingWeightOnPurpose'
                      ? bdiLocales[useLocale][`options.losingWeightOnPurpose.${o}`]
                      : bdiLocales[useLocale][`options.${q.id}.${o}`]
                  }
                  formData={{
                    document: { [q.id]: bdiData?.[q.id as keyof IBDI] },
                    onChange: onChangeSurveyForm,
                  }}
                />
              </FormRow>
            );
          })}
          <FormRow
            title={'myService.ninmt.score'}
            description={fm('myService.ninmt.bdiTotalScoreDescription', { br: <br /> })}
          >
            <BDIScore bdiData={bdiData} fm={fm} />
          </FormRow>
        </>
      </FormSection>
    </>
  );
};
