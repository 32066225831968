import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';
import { isEnded } from '../../../../Treatment/utils';

type TFieldConfig = (
  | {
      type: 'PartialDate';
      name: string;
      title?: string | undefined;
      titleDescription?: React.JSX.Element | string | undefined;
      dateDefault?: 'now' | PartialDate | undefined;
      isNotCancellable?: boolean;
    }
  | {
      type: 'NumberField';
      name: string;
      title?: string | undefined;
      titleDescription?: React.JSX.Element | string | undefined;
      precision?: number;
      min?: number;
      max?: number;
      placeholder?: string;
      unit?: string | React.JSX.Element;
    }
  | {
      type: 'Radio';
      name: string;
      title?: string | undefined;
      titleDescription?: string | React.JSX.Element | undefined;
      options: Array<string | number>;
      optFormatter?: (name: string | number) => React.JSX.Element;
    }
)[];

const fieldConfig: TFieldConfig = [
  {
    type: 'PartialDate',
    name: 'date',
    title: 'clpAndCnpTests.date',
    titleDescription: undefined,
    isNotCancellable: true,
  },
  {
    type: 'Radio',
    name: 'madTherapyInUse',
    title: 'clpAndCnpTests.madTherapyInUse',
    options: ['yes', 'no', 'unknown'],
    optFormatter: (name: string | number): React.JSX.Element => (
      <FormattedMessage id={`clpAndCnpTests.opts.sleepPolygraphy.${name}`} />
    ),
  },
  {
    type: 'NumberField',
    name: 'ahi',
    title: 'clpAndCnpTests.ahi',
    titleDescription: <FormattedMessage id="clpAndCnpTests.ahiDescription" />,
    precision: 0,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.ahi',
    unit: <FormattedMessage id="clpAndCnpTests.perHour" />,
  },
  {
    type: 'NumberField',
    name: 'ahiSupine',
    title: 'clpAndCnpTests.ahiSupine',
    precision: 0,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.ahiSupine',
    unit: <FormattedMessage id="clpAndCnpTests.perHour" />,
  },
  {
    type: 'NumberField',
    name: 'odi3',
    title: 'clpAndCnpTests.odi3',
    titleDescription: <FormattedMessage id="clpAndCnpTests.odi3Description" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.odi3',
    unit: <FormattedMessage id="clpAndCnpTests.perHour" />,
  },
  {
    type: 'NumberField',
    name: 'cai',
    title: 'clpAndCnpTests.cai',
    titleDescription: <FormattedMessage id="clpAndCnpTests.caiDescription" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.cai',
    unit: <FormattedMessage id="clpAndCnpTests.perHour" />,
  },
  {
    type: 'NumberField',
    name: 'averageSpO2',
    title: 'clpAndCnpTests.averageSpO2',
    titleDescription: <FormattedMessage id="clpAndCnpTests.averageSpO2Description" />,
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.averageSpO2',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'spO2Below90',
    title: 'clpAndCnpTests.spO2Below90',
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.spO2Below90',
    unit: <FormattedMessage id="clpAndCnpTests.percentsOfNight" />,
  },
  {
    type: 'NumberField',
    name: 'lowestSpO2',
    title: 'clpAndCnpTests.lowestSpO2',
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.lowestSpO2',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'respiratoryRate',
    title: 'clpAndCnpTests.respiratoryRate',
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.respiratoryRate',
    unit: <FormattedMessage id="clpAndCnpTests.perMinute" />,
  },
  {
    type: 'NumberField',
    name: 'snoring',
    title: 'clpAndCnpTests.snoring',
    titleDescription: <FormattedMessage id="clpAndCnpTests.snoringDescription" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.snoring',
    unit: <FormattedMessage id="clpAndCnpTests.snoringUnit" />,
  },
  {
    type: 'Radio',
    name: 'incidenceOfLongTermStenosis',
    title: 'clpAndCnpTests.incidenceOfLongTermStenosis',
    options: ['yes', 'no', 'unknown'],
    optFormatter: (name: string | number): React.JSX.Element => (
      <FormattedMessage id={`clpAndCnpTests.opts.sleepPolygraphy.${name}`} />
    ),
  },
  {
    type: 'NumberField',
    name: 'tib',
    title: 'clpAndCnpTests.tib',
    titleDescription: <FormattedMessage id="clpAndCnpTests.tibDescription" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.tib',
    unit: <FormattedMessage id="clpAndCnpTests.minutes" />,
  },
  {
    type: 'NumberField',
    name: 'tst',
    title: 'clpAndCnpTests.tst',
    titleDescription: <FormattedMessage id="clpAndCnpTests.tstDescription" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.tst',
    unit: <FormattedMessage id="clpAndCnpTests.minutes" />,
  },
  {
    type: 'NumberField',
    name: 'se',
    title: 'clpAndCnpTests.se',
    titleDescription: <FormattedMessage id="clpAndCnpTests.seDescription" />,
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.se',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'waso',
    title: 'clpAndCnpTests.waso',
    titleDescription: <FormattedMessage id="clpAndCnpTests.wasoDescription" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.waso',
    unit: <FormattedMessage id="clpAndCnpTests.minutes" />,
  },
  {
    type: 'NumberField',
    name: 'sl',
    title: 'clpAndCnpTests.sl',
    titleDescription: <FormattedMessage id="clpAndCnpTests.slDescription" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.sl',
    unit: <FormattedMessage id="clpAndCnpTests.minutes" />,
  },
  {
    type: 'NumberField',
    name: 'numberOfSleepCycles',
    title: 'clpAndCnpTests.numberOfSleepCycles',
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.numberOfSleepCycles',
    unit: <FormattedMessage id="clpAndCnpTests.perNight" />,
  },
  {
    type: 'NumberField',
    name: 'soremp',
    title: 'clpAndCnpTests.soremp',
    titleDescription: <FormattedMessage id="clpAndCnpTests.sorempDescription" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.soremp',
  },
  {
    type: 'NumberField',
    name: 'n1',
    title: 'clpAndCnpTests.n1',
    titleDescription: <FormattedMessage id="clpAndCnpTests.n1Description" />,
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.n1',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'n2',
    title: 'clpAndCnpTests.n2',
    titleDescription: <FormattedMessage id="clpAndCnpTests.n2Description" />,
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.n2',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'n3',
    title: 'clpAndCnpTests.n3',
    titleDescription: <FormattedMessage id="clpAndCnpTests.n3Description" />,
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.n3',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'rem',
    title: 'clpAndCnpTests.rem',
    titleDescription: <FormattedMessage id="clpAndCnpTests.remDescription" />,
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.rem',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'wakeUpIndex',
    title: 'clpAndCnpTests.wakeUpIndex',
    titleDescription: <FormattedMessage id="clpAndCnpTests.wakeUpIndexDescription" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.wakeUpIndex',
    unit: <FormattedMessage id="clpAndCnpTests.perHour" />,
  },
  {
    type: 'NumberField',
    name: 'plmi',
    title: 'clpAndCnpTests.plmi',
    titleDescription: <FormattedMessage id="clpAndCnpTests.plmiDescription" />,
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.plmi',
    unit: <FormattedMessage id="clpAndCnpTests.perHour" />,
  },
  {
    type: 'NumberField',
    name: 'plm',
    title: 'clpAndCnpTests.plm',
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.plm',
    unit: '%',
  },
];

const SleepStudyForm = ({ formData, documents, viewing }: IOwnProps): React.JSX.Element => {
  const madTherapyDocs = documents?.filter((d) => d._type === 'madTherapy');
  const onGoingMADTherapies = madTherapyDocs.filter((m) => !isEnded(m.hasEnded, m.endDate));
  const madTherapyOngoing = onGoingMADTherapies.length > 0;
  return (
    <React.Fragment>
      {fieldConfig.map((item) => {
        // Only show this question if madTherapy is ongoing
        if (item.name === 'madTherapyInUse' && !madTherapyOngoing) return null;
        return (
          <FormRow key={item.name} title={item.title} description={item.titleDescription}>
            {item.type === 'PartialDate' ? (
              <InputHandler
                type={item.type}
                editing={!viewing}
                name={item.name}
                formData={formData}
                dateDefault={item.dateDefault ?? 'now'}
                isNotCancellable={item.isNotCancellable}
              />
            ) : item.type === 'NumberField' ? (
              <Unit unit={item.unit}>
                <InputHandler
                  type={item.type}
                  editing={!viewing}
                  name={item.name}
                  formData={formData}
                  precision={item.precision}
                  min={item.min}
                  max={item.max}
                  placeholder={item.placeholder}
                />
              </Unit>
            ) : item.type === 'Radio' ? (
              <InputHandler
                type="Radio"
                editing={!viewing}
                name={item.name}
                formData={formData}
                options={item.options}
                optionFormatter={item.optFormatter}
              />
            ) : null}
          </FormRow>
        );
      })}
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<ISleepStudy>;
  viewing?: boolean;
  documents: Array<any>;
}

export default SleepStudyForm;
