import * as React from 'react';
import { capitalize } from '../../../../../utility/string';
import { HistoryEventListItem } from 'Components/_NewElements/HistoryEvents';

const AdverseEventListItem = ({ adverseEvent }: IAdverseEventItemProps): React.JSX.Element => {
  return (
    <>
      {adverseEvent.adverseEffects && (
        <HistoryEventListItem
          title={'medication.adverseEffects'}
          value={
            <>
              {adverseEvent.adverseEffects?.map((a: IAdverseEffect, i: number) =>
                a.eventName === 'Muu haittavaikutus' ? (
                  <React.Fragment key={a.eventName + i}>
                    <span>
                      {a.eventName && `${capitalize(a.eventName)} (${adverseEvent.otherAdverseEffect || ''})`}
                    </span>
                    {i !== (adverseEvent.adverseEffects?.length as number) - 1 && ', '}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={`${a.eventName || ''}${i}`}>
                    <span>{a.eventName && capitalize(a.eventName)}</span>
                    {i !== (adverseEvent.adverseEffects?.length as number) - 1 && ', '}
                  </React.Fragment>
                ),
              )}
            </>
          }
        />
      )}
      {adverseEvent.eventDetails && (
        <HistoryEventListItem title={'medication.eventDetails'} value={adverseEvent.eventDetails} />
      )}
    </>
  );
};

interface IAdverseEventItemProps {
  adverseEvent: IMedicationAdverseEvent;
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
}

export default AdverseEventListItem;
