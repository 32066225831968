import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import { formatPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const FVC = ({ documents, startEdit }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'clinicalstudy.fvc'} />}
      newButton={
        <DocumentCreationButton
          name="fvc"
          text={'clinicalstudy.newfvc'}
          onClick={startEdit({}, 'fvc')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id={'general.date'} />
            </Item>
            <Item xs={2}>
              <FormattedMessage id={'clinicalstudy.litrePlaceholder'} />
            </Item>
            <Item xs={2}>
              <FormattedMessage id={'clinicalstudy.fvcPredictedPercentage'} />
            </Item>
            <Item xs={6}>
              <FormattedMessage id={'clinicalstudy.sitOrLieDown'} />
            </Item>
          </>
        }
        contentFormat={(d: IFVC): React.JSX.Element => (
          <>
            <Item xs={2} style={{ fontWeight: '600' }}>
              {formatPartialDate(d.date ?? undefined)}
            </Item>
            <Item xs={2}>{d.litres ?? '-'}</Item>
            <Item xs={2}>{d.predicted ?? '-'}</Item>
            <Item xs={3}>
              {d.sittingOrLyingDown ? <FormattedMessage id={'clinicalstudy.opts.' + d.sittingOrLyingDown} /> : '-'}
            </Item>
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IFVC[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default FVC;
