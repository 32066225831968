import { mergeAll } from 'ramda';
import * as React from 'react';
import { useIntl } from 'react-intl';
import FormRow from '../../../../../../../components/FormRow';
import InputHandler from '../../../../../../../components/InputHandler';

const routeOptionsHead = [
  // Most used
  'MR026', // Suun kautta
  'MR028', // Ihon alle
  'MR012', // Lihakseen
  'MR038', // Suoleen
  'MR019', // Laskimoon
  'MR006', // Valtimoon
];

const routeOptionsTail = [
  // Rest
  'MR001',
  'MR002',
  'MR003',
  'MR004',
  'MR005',
  'MR007',
  'MR008',
  'MR009',
  'MR010',
  'MR011',
  'MR013',
  'MR014',
  'MR015',
  'MR016',
  'MR017',
  'MR018',
  'MR020',
  'MR021',
  'MR022',
  'MR023',
  'MR024',
  'MR025',
  'MR027',
  'MR029',
  'MR030',
  'MR031',
  'MR032',
  'MR033',
  'MR034',
  'MR035',
  'MR036',
  'MR037',
  'MR039',
  'MR040',
  'MR041',
  'MR042',
  'MR043',
  'MR044',
  'MR045',
  'MR046',
  'MR047',
  'MR048',
  'MR049',
  'MR050',
  'MR051',
  'MR052',
  'MR053',
  'MR054',
  'MR055',
  'MR056',
  'MR057',
  'MR058',
  'MR059',
  'MR101',
  'MR102',
  'MR103',
  'MR104',
  'MR105',
  'MR106',
  'MR107',
  'MR108',
  'MR109',
  'MR110',
  'MR111',
  'MR112',
  'MR113',
  'MR114',
  'MR115',
];

const sortOptionsBasedOnLocale = (options: string[], fm: ({ id }: { id: string }) => string): string[] => {
  const optionsWithLocales = options.map((o) => ({ [o]: fm({ id: 'medication.opts.mr.' + o }) }));

  optionsWithLocales.sort((o1, o2) => {
    const firstField = o1[Object.keys(o1)[0]];
    const secondField = o2[Object.keys(o2)[0]];
    return firstField > secondField ? 1 : secondField > firstField ? -1 : 0;
  });

  return Object.keys(mergeAll(optionsWithLocales));
};

const RouteOfAdministration = ({ routeOfAdministration, onChange }: IRouteOfAdministration): React.JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <FormRow title="medication.routeOfAdministration" headerWidth={3}>
      <InputHandler
        type="Select"
        name="routeOfAdministration"
        editing={true}
        formData={{
          document: { routeOfAdministration: routeOfAdministration || '' },
          onChange: onChange,
        }}
        options={[...routeOptionsHead, ...sortOptionsBasedOnLocale(routeOptionsTail, formatMessage)]}
        optionFormatter={(s: number | string): string => formatMessage({ id: `medication.opts.mr.${s}` })}
        placeholder={'medication.chooseRouteOfAdministration'}
      />
    </FormRow>
  );
};

interface IRouteOfAdministration {
  routeOfAdministration?: string;
  onChange: (e: TOnChangeValues) => void;
}

export default RouteOfAdministration;
