import * as React from 'react';
import FormRow from '../../../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../../../../components/InputHandler';
import { fm } from 'Components/FormatMessage';

const generalOptionFormatter = (s?: string | number | boolean): React.JSX.Element => fm(`general.${s || 'empty'}`);

const formFields = ['AQP4IgGResult'];

const setAllUnknown = (formData: IOwnProps['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  leftMargin,
  bottomMargin = true,
}: {
  title: string;
  description?: React.JSX.Element;
  name: string;
  formData: IOwnProps['formData'];
  viewing: boolean;
  leftMargin?: number;
  bottomMargin?: boolean;
}): React.JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={6}
    bottomMargin={bottomMargin}
    headerStyle={{ marginLeft: `${leftMargin ?? 0}rem` }}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="positiveNegativeUnknown"
      height={3.5}
      width={9}
      optionFormatter={generalOptionFormatter}
    />
  </FormRow>
);

const AQP4 = ({ documents, formData, view, editingID }: IOwnProps): React.JSX.Element => {
  const isViewing = !editingID && !!view?.viewing;
  const docID = editingID ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      <FormSectionHistoryAcceptor
        formData={formData}
        documentID={docID}
        documents={documents}
        optionFormatter={generalOptionFormatter}
        header="diagnosis.nmosdDiagnosticCriteria.aqp4.title"
        hideCopyButton={isViewing}
      >
        {{
          AQP4IgGResult: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.aqp4.AQP4IgGResult.title"
                name="AQP4IgGResult"
                formData={formData}
                viewing={isViewing}
                bottomMargin={false}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<INMOSDCriteria>;
  formData: IFormData<INMOSDCriteria>;
  view?: IView;
  editingID?: string;
}

export default AQP4;
