import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';
import FormSection from '../../../../../components/FormSection';

import { steps as nsaaSteps, getStepContent as getNSAASteps } from '../nsaaSettings';
import { getPatientAgeInMonthsWithOneDecimal } from '../../../../../utility/patientInfo';
import { nsaaAutomaticScore, showPatientAgeInMonthsOnForm } from '../../../utils';
import InfoPopover from '../../../../../components/InfoPopover';
import colors from '../../../../../config/theme/colors';
import { Container } from 'Components/Grid';

const NSAAForm = ({ formData, viewing }: IOwnProps): React.JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="motor.ageMonth" condition={showPatientAgeInMonthsOnForm(formData.document.date)}>
        <InputHandler
          type="TextField"
          editing={false}
          name="age"
          formData={{
            onChange: formData.onChange,
            document: {
              age: formData.document.date ? getPatientAgeInMonthsWithOneDecimal(formData.document.date) : '',
            },
          }}
          disabled={true}
        />
      </FormRow>
      <FormSection>
        <QuestionStepper
          viewing={viewing}
          formData={formData}
          pageType={'motor'}
          docType={'nsaa'}
          steps={nsaaSteps}
          getStepContent={getNSAASteps}
        />
      </FormSection>
      <FormSection>
        <FormRow title="motor.automaticScore">
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            {nsaaAutomaticScore(formData.document) === 'notCounted' ? (
              <InfoPopover text="general.notFilled" color="primary" />
            ) : (
              nsaaAutomaticScore(formData.document)
            )}
          </Container>
        </FormRow>
        <FormRow title="motor.manualScore">
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            <InputHandler
              type="NumberField"
              editing={!viewing}
              name="manualScore"
              formData={formData}
              height={3}
              width={12}
              placeholder="motor.nsaaScore"
              maxLength={2}
              min={0}
              max={34}
            />
          </Container>
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<INSAA>;
  viewing: boolean;
}

export default NSAAForm;
