import { Step, StepContent, Stepper } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/system';

import FormRow from '../../../../components/FormRow';
import InputHandler from '../../../../components/InputHandler';
import { Container, Item } from '../../../../components/Grid';
import ActionButton from '../../../../components/ActionButton';
import { Connector, IconChooser, StyledStepLabel } from '../../../../components/EventStepper/components';

import colors from '../../../../config/theme/colors';

import { options, tooltips } from './optionsAndTooltips';
import normalValues from './normalValues';
import calculateEdss from './calculateEdss';
import { resolvePath, rootOf, endOf, mapUndefinedToNotDone } from '../utils';
import { ScoreSelectRow } from './Components/ScoreSelectRow';
import { SubScoreSelectRow } from './Components/SubScoreSelectRow';
import { RightLeftTitleRow } from './Components';
import { exists } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { isEmpty } from 'Utility/ramdaReplacement';

const headerSpacing = 28; // rem

const StyledValue = styled('div')({
  fontWeight: 600,
  cursor: 'default',
});

const StyledButtonRowContainer = styled('div')({});

const StyledScoreControls = styled('div')({});

const StyledStepButton = styled('a')({
  color: colors.tertiaryText,
  '&:hover': {
    color: colors.primary,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const StyledStepSection = styled('div')({
  backgroundColor: 'white',
  padding: '2rem 0',
});

const StyledSubSectionHeader = styled('div')({
  color: colors.primary,
  fontSize: '2rem',
  fontWeight: 600,
});

const StyledScore = styled('span')({
  color: colors.primary,
  fontSize: '2rem',
  fontWeight: 600,
});

const StyledSubSection = ({
  header,
  margin = true,
  children,
}: {
  header?: React.JSX.Element;
  margin?: boolean;
  children: React.JSX.Element;
}): React.JSX.Element => (
  <Container style={{ marginTop: margin ? '4.5rem' : undefined }}>
    <Item style={{ width: headerSpacing + 'rem' }}>
      <StyledSubSectionHeader>{header || null}</StyledSubSectionHeader>
    </Item>
    <Item xs={true}>{children}</Item>
  </Container>
);

const edssOptionFormatter = (n: string | number | boolean): React.JSX.Element =>
  n !== 0 && n !== 10 ? <span>{Number(n).toFixed(1)}</span> : <span>{n}</span>;

// Make references for steps which are used with automatic scrolling
const makeRefsForSteps = (steps: string[]): React.RefObject<HTMLDivElement | null>[] =>
  steps.map(() => React.useRef(null));

// Note: this can only work after transition is over
const elementInViewport = (el: HTMLDivElement): boolean => {
  const bb = el.getBoundingClientRect();
  const lowerBound = 80;
  return (
    bb.top >= lowerBound && bb.top <= ((window.innerHeight || document.documentElement.clientHeight) - lowerBound) / 3
  );
};

const NeurologicalStatusAndEDSSForm = ({ documents, formData, viewing = false }: IOwnProps): React.JSX.Element => {
  const [activeStep, setActiveStep] = React.useState<number>(-1);
  const [newEdss, setNewEdss] = React.useState<number | undefined>(undefined);
  const [isFetching, setIsFetching] = React.useState<boolean>(true);
  const isMountRender = React.useRef(true);
  const revert1 = React.useRef('');
  const revert2 = React.useRef('');

  React.useEffect(() => {
    if (!isEmpty(formData.document)) {
      setIsFetching(false);
    }
  }, [formData.document]);

  React.useEffect(() => {
    !viewing && isFetching === false && mapUndefinedToNotDone(formData);
    // eslint-disable-next-line
  }, [isFetching]);

  React.useEffect(() => {
    if (revert2.current.length > 0) {
      formData.onChange?.({
        [revert2.current]: endOf(revert2.current) !== 'visionCorrection' ? 'notDone' : 'unknown',
      });
      revert2.current = '';
      revert1.current = '';
    }
    if (revert1.current.length > 0) {
      formData.onChange?.({
        [revert1.current]: undefined,
      });
      revert2.current = revert1.current;
    }
    // eslint-disable-next-line
  }, [revert1.current, revert2.current]);

  const handleEdss = (): void => {
    formData.onChange?.({
      calculatedEdssStep: calculateEdss(formData.document),
    });
    setNewEdss(calculateEdss(formData.document));
  };

  React.useEffect(() => {
    if (isMountRender.current) {
      isMountRender.current = false;
      return;
    }
    (exists(newEdss) || !exists(resolvePath('edssStep', formData.document))) &&
      formData.onChange?.({
        edssStep: newEdss,
      });
    setNewEdss(undefined);
    // eslint-disable-next-line
  }, [newEdss]);

  const handleChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];

    const { onChange } = formData;

    if (rootOf(name) + '.score' === name || name === 'ambulatoryScore') {
      onChange && onChange({ [name]: value });
    } else {
      const previousValue = resolvePath(name, formData.document);
      const targetValue = endOf(name);
      if (previousValue === 'notDone' || previousValue === 'unknown') {
        onChange && onChange({ [name]: value });
        if (value === null) {
          revert1.current = name;
        }
      } else {
        if (
          value === null &&
          targetValue.substring(0, 12) !== 'visualAcuity' &&
          targetValue.substring(0, 32) !== 'ableToLiftOnlyOneLegAtATimeGrade'
        ) {
          onChange &&
            onChange?.({
              [name]: targetValue !== 'visionCorrection' ? 'notDone' : 'unknown',
            });
        } else {
          onChange && onChange({ [name]: value });
        }
      }
    }
  };

  const stepSections = Object.keys(options).filter(
    (section) => section !== 'ambulatoryScore' && section !== 'edssStep',
  );

  const refs = makeRefsForSteps(stepSections);

  const activateStep = (id: number) => (): void => {
    const previousStep = activeStep;
    if (id !== activeStep) {
      setActiveStep(id);

      const nextNode = refs[id === 0 ? 0 : id - 1]?.current;

      if (nextNode && !elementInViewport(nextNode)) {
        const scroll = (): void => {
          window.scrollTo({ behavior: 'smooth', top: nextNode.offsetTop - (id === 0 ? 75 : 0) });
        };
        setTimeout(scroll, previousStep === 0 ? 1200 : 800);
      }
    } else {
      setActiveStep(-1);
    }
  };

  return (
    <div style={{ fontSize: '1.6rem !important' }}>
      <FormRow title="general.date" headerWidth={3}>
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <div style={{ borderTop: '1px solid #c8c8c8', margin: '0 -4rem 0 -4rem' }} />
      {!viewing ? (
        <Container alignItems="center" style={{ marginBottom: '4.5rem' }}>
          <Item xs={3} />
          <Item xs={true}>
            <ScoreSelectRow documents={documents} formData={formData} subject="super" normalValue={normalValues} />
          </Item>
        </Container>
      ) : (
        <div style={{ marginBottom: '4.5rem' }} />
      )}
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        style={{ margin: '0', padding: '0' }}
        connector={<Connector />}
      >
        {stepSections.map((section, index) => (
          <Step
            key={section}
            style={{
              marginBottom: '4.5rem',
            }}
            ref={refs[index]}
          >
            <StyledStepLabel
              StepIconComponent={IconChooser}
              style={{ backgroundColor: colors.lightestGray }}
              sx={{
                '& .MuiStepLabel-iconContainer': {
                  paddingRight: 0,
                },
              }}
            >
              <Container
                alignItems="center"
                style={{
                  fontSize: '1.6rem', // MUI screws up font-sizes (doesn't inherit them)
                }}
              >
                <Item style={{ width: headerSpacing + 'rem' }}>
                  <StyledStepButton onClick={activateStep(index)}>
                    <Container alignItems="center" style={{ height: '100%' }}>
                      <Item>{fm(`neurologicalStatusAndEDSS.${section}.title`)}</Item>
                    </Container>
                  </StyledStepButton>
                </Item>
                <Item xs={true} style={{ color: colors.primaryText }}>
                  <ScoreSelectRow
                    documents={documents}
                    formData={{ onChange: handleChange, document: formData.document }}
                    viewing={viewing}
                    subject={`${section}.score`}
                    normalValue={normalValues[section]}
                    maxValue={
                      Array.isArray(resolvePath(section, options).score)
                        ? resolvePath(section, options).score.length - (section === 'sensoryFunctions' ? 2 : 1)
                        : undefined
                    }
                  />
                </Item>
              </Container>
            </StyledStepLabel>
            <StepContent
              style={{
                border: '0',
                margin: '0',
                padding: '0 5rem',
              }}
            >
              <StyledStepSection>
                {section === 'pyramidalFunctions' ? (
                  <React.Fragment>
                    <div style={{ marginTop: '2.5rem' }}>
                      <StyledSubSection
                        header={fm('neurologicalStatusAndEDSS.pyramidalFunctions.reflexes.title')}
                        margin={false}
                      >
                        <RightLeftTitleRow />
                      </StyledSubSection>
                    </div>
                    {Object.keys(options[section]['reflexes']).map((subSection, index) => (
                      <SubScoreSelectRow
                        key={subSection}
                        formData={{
                          onChange: handleChange,
                          document: formData.document,
                        }}
                        viewing={viewing}
                        subject={`pyramidalFunctions.reflexes.${subSection}`}
                        altColor={index % 2 === 1}
                      />
                    ))}

                    <StyledSubSection header={fm('neurologicalStatusAndEDSS.pyramidalFunctions.limbStrength.title')}>
                      <RightLeftTitleRow />
                    </StyledSubSection>
                    {Object.keys(options[section]['limbStrength']).map((subSection, index) => (
                      <SubScoreSelectRow
                        key={subSection}
                        formData={{
                          onChange: handleChange,
                          document: formData.document,
                        }}
                        viewing={viewing}
                        subject={`pyramidalFunctions.limbStrength.${subSection}`}
                        altColor={index % 2 === 1}
                      />
                    ))}
                    <StyledSubSection header={fm('neurologicalStatusAndEDSS.pyramidalFunctions.functionalTests.title')}>
                      <RightLeftTitleRow />
                    </StyledSubSection>
                    {Object.keys(options[section]['functionalTests']).map((subSection, index) => (
                      <SubScoreSelectRow
                        key={subSection}
                        formData={{
                          onChange: handleChange,
                          document: formData.document,
                        }}
                        viewing={viewing}
                        subject={`pyramidalFunctions.functionalTests.${subSection}`}
                        altColor={index % 2 === 1}
                        isNumberField={subSection === 'ableToLiftOnlyOneLegAtATimeGrade'}
                      />
                    ))}

                    <StyledSubSection header={fm('neurologicalStatusAndEDSS.pyramidalFunctions.spasticity.title')}>
                      <RightLeftTitleRow />
                    </StyledSubSection>
                    {Object.keys(options[section]['spasticity']).map((subSection, index) => (
                      <SubScoreSelectRow
                        key={subSection}
                        formData={{
                          onChange: handleChange,
                          document: formData.document,
                        }}
                        viewing={viewing}
                        subject={`pyramidalFunctions.spasticity.${subSection}`}
                        altColor={index % 2 === 1}
                        rightLeft={subSection === 'overallMotorPerformance' ? false : true}
                      />
                    ))}
                  </React.Fragment>
                ) : section === 'cerebellarFunctions' ? (
                  <React.Fragment>
                    {Object.keys(options[section])
                      .slice(1, 3)
                      .map((subSection, index) => (
                        <SubScoreSelectRow
                          key={subSection}
                          formData={{
                            onChange: handleChange,
                            document: formData.document,
                          }}
                          viewing={viewing}
                          subject={`cerebellarFunctions.${subSection}`}
                          altColor={index % 2 === 1}
                          rightLeft={false}
                        />
                      ))}
                    <StyledSubSection>
                      <RightLeftTitleRow />
                    </StyledSubSection>
                    {Object.keys(options[section]['tremor']).map((subSection, index) => (
                      <SubScoreSelectRow
                        key={subSection}
                        formData={{
                          onChange: handleChange,
                          document: formData.document,
                        }}
                        viewing={viewing}
                        subject={`cerebellarFunctions.tremor.${subSection}`}
                        altColor={index % 2 === 1}
                      />
                    ))}
                    {Object.keys(options[section]['rapidAlternatingMovements']).map((subSection, index) => (
                      <SubScoreSelectRow
                        key={subSection}
                        formData={{
                          onChange: handleChange,
                          document: formData.document,
                        }}
                        viewing={viewing}
                        subject={`cerebellarFunctions.rapidAlternatingMovements.${subSection}`}
                        altColor={index % 2 === 1}
                      />
                    ))}
                    {Object.keys(options[section])
                      .slice(5)
                      .map((subSection, index) => (
                        <SubScoreSelectRow
                          key={subSection}
                          formData={{
                            onChange: handleChange,
                            document: formData.document,
                          }}
                          viewing={viewing}
                          subject={`cerebellarFunctions.${subSection}`}
                          altColor={index % 2 === 1}
                          rightLeft={false}
                        />
                      ))}
                  </React.Fragment>
                ) : section === 'brainstemFunctions' ? (
                  <React.Fragment>
                    {Object.keys(options[section])
                      .slice(1)
                      .map((subSection, index) => (
                        <SubScoreSelectRow
                          key={subSection}
                          formData={{
                            onChange: handleChange,
                            document: formData.document,
                          }}
                          viewing={viewing}
                          subject={`brainstemFunctions.${subSection}`}
                          altColor={index % 2 === 1}
                          rightLeft={false}
                        />
                      ))}
                  </React.Fragment>
                ) : section === 'sensoryFunctions' ? (
                  <React.Fragment>
                    <StyledSubSection>
                      <RightLeftTitleRow />
                    </StyledSubSection>
                    {Object.keys(options[section]['superficialSense']).map((subSection, index) => (
                      <SubScoreSelectRow
                        key={subSection}
                        formData={{
                          onChange: handleChange,
                          document: formData.document,
                        }}
                        viewing={viewing}
                        subject={`sensoryFunctions.superficialSense.${subSection}`}
                        altColor={index % 2 === 1}
                      />
                    ))}
                    {Object.keys(options[section]['vibrationSense']).map((subSection, index) => (
                      <SubScoreSelectRow
                        key={subSection}
                        formData={{
                          onChange: handleChange,
                          document: formData.document,
                        }}
                        viewing={viewing}
                        subject={`sensoryFunctions.vibrationSense.${subSection}`}
                        altColor={(index + 1) % 2 === 1}
                      />
                    ))}
                    {Object.keys(options[section]['positionSense']).map((subSection, index) => (
                      <SubScoreSelectRow
                        key={subSection}
                        formData={{
                          onChange: handleChange,
                          document: formData.document,
                        }}
                        viewing={viewing}
                        subject={`sensoryFunctions.positionSense.${subSection}`}
                        altColor={(index + 1) % 2 === 1}
                      />
                    ))}
                    {Object.keys(options[section])
                      .slice(4)
                      .map((subSection, index) => (
                        <SubScoreSelectRow
                          key={subSection}
                          formData={{
                            onChange: handleChange,
                            document: formData.document,
                          }}
                          viewing={viewing}
                          subject={`sensoryFunctions.${subSection}`}
                          altColor={(index + 1) % 2 === 1}
                          rightLeft={false}
                        />
                      ))}
                  </React.Fragment>
                ) : section === 'bowelAndBladderFunctions' ? (
                  <React.Fragment>
                    {Object.keys(options[section])
                      .slice(1)
                      .map((subSection, index) => (
                        <SubScoreSelectRow
                          key={subSection}
                          formData={{
                            onChange: handleChange,
                            document: formData.document,
                          }}
                          viewing={viewing}
                          subject={`bowelAndBladderFunctions.${subSection}`}
                          altColor={index % 2 === 1}
                          rightLeft={false}
                        />
                      ))}
                  </React.Fragment>
                ) : section === 'visualAcuity' ? (
                  <React.Fragment>
                    <SubScoreSelectRow
                      key={'visionCorrection'}
                      formData={{
                        onChange: handleChange,
                        document: formData.document,
                      }}
                      viewing={viewing}
                      subject={'visualAcuity.visionCorrection'}
                      rightLeft={false}
                    />

                    <StyledSubSection>
                      <RightLeftTitleRow />
                    </StyledSubSection>
                    {Object.keys(options[section])
                      .slice(2)
                      .map((subSection, index) => (
                        <SubScoreSelectRow
                          key={subSection}
                          formData={{
                            onChange: handleChange,
                            document: formData.document,
                          }}
                          viewing={viewing}
                          subject={`visualAcuity.${subSection}`}
                          altColor={index % 2 === 1}
                          isNumberField={subSection === 'visualAcuity'}
                        />
                      ))}
                  </React.Fragment>
                ) : section === 'cerebralFunctions' ? (
                  <React.Fragment>
                    {Object.keys(options[section])
                      .slice(1)
                      .map((subSection, index) => (
                        <SubScoreSelectRow
                          key={subSection}
                          formData={{
                            onChange: handleChange,
                            document: formData.document,
                          }}
                          viewing={viewing}
                          subject={`cerebralFunctions.${subSection}`}
                          altColor={index % 2 === 1}
                          rightLeft={false}
                        />
                      ))}
                  </React.Fragment>
                ) : undefined}
              </StyledStepSection>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      <Container style={{ marginBottom: '4.5rem' }}>
        <Item style={{ color: colors.tertiaryText, width: headerSpacing + 5 + 'rem', paddingLeft: '5rem' }}>
          {fm('neurologicalStatusAndEDSS.ambulatoryScore.title')}
        </Item>
        <Item xs={true}>
          <ScoreSelectRow
            documents={documents}
            formData={{
              onChange: handleChange,
              document: formData.document,
            }}
            viewing={viewing}
            subject="ambulatoryScore"
            normalValue=""
            maxValue={15}
          />
        </Item>
      </Container>

      <Container style={{ marginBottom: '4.5rem' }}>
        <Item style={{ width: headerSpacing + 5 + 'rem' }} />
        <Item xs={true}>
          {!viewing && (
            <StyledScoreControls>
              <ActionButton
                text="neurologicalStatusAndEDSS.calculateEdss"
                onClick={handleEdss}
                width={9}
                height={4}
                fontSize={16}
              />
            </StyledScoreControls>
          )}
          <StyledScoreControls style={{ fontWeight: 600, marginTop: '2rem' }}>
            {(exists(formData.document.calculatedEdssStep) || viewing) && (
              <React.Fragment>
                <span style={{ position: 'relative', bottom: '0.1rem' }}>
                  {fm('neurologicalStatusAndEDSS.calculatedEdss')}
                  {': '}
                </span>
                <span style={{ marginBottom: '0.2rem', marginLeft: !viewing ? '1rem' : '2.5rem' }}>
                  <StyledScore>
                    {exists(formData?.document?.calculatedEdssStep) &&
                    !Number.isNaN(formData?.document?.calculatedEdssStep as number)
                      ? edssOptionFormatter(formData.document.calculatedEdssStep as number)
                      : '-'}
                  </StyledScore>
                </span>
              </React.Fragment>
            )}
          </StyledScoreControls>
        </Item>
      </Container>

      <Container style={{ marginBottom: '4.5rem' }}>
        <Item style={{ color: colors.tertiaryText, width: headerSpacing + 5 + 'rem', paddingLeft: '5rem' }}>
          {fm('neurologicalStatusAndEDSS.edssStep.title')}
        </Item>
        <Item xs={true}>
          {viewing && !exists(formData.document.edssStep) ? (
            <StyledValue style={{ fontWeight: 'normal' }}>
              {fm('neurologicalStatusAndEDSS.opts.notEntered')}
            </StyledValue>
          ) : (
            <StyledButtonRowContainer>
              <InputHandler
                type="RadioButtonRow"
                editing={!viewing}
                name="edssStep"
                formData={formData}
                options={resolvePath('edssStep', options)}
                optionFormatter={edssOptionFormatter}
                tooltips={tooltips('edssStep')}
              />
            </StyledButtonRowContainer>
          )}
        </Item>
      </Container>
    </div>
  );
};

interface IOwnProps {
  documents: INeurologicalStatusAndEDSS[] | undefined;
  formData: IFormData<INeurologicalStatusAndEDSS>;
  viewing?: boolean;
}

export default NeurologicalStatusAndEDSSForm;
