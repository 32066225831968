import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import RTMSForm from './RTMS';
import { DoctorsOrdersContext } from '..';
import OtherForm from './Other';
import TDCSForm from './TDCS';
import { getJWTData } from 'Utility/jwtAuthTools';
import { findUserNames, getOrganizationUserData } from 'Routes/DoctorsOrders/utils';
import { INeuroDocument } from 'neuro-data-structures';
import { Container, Item } from 'Components/Grid';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import { isEmpty, isNil, omit } from 'Utility/ramdaReplacement';

const getCreateDate = (allDocs: Array<INeuroDocument>, id: string): number | undefined =>
  allDocs.find((doc) => doc.documentId === id)?.createDate;

const DoctorsOrdersForm = ({ formData, activeView, setActiveView }: IDoctorsOrdersFormProps): React.JSX.Element => {
  const { document, onChange } = formData;
  const [organizationUserData, setOrganizationUserData] = React.useState<Array<{ [key: string]: string | boolean }>>(
    [],
  );
  const [ordererNameInput, setOrdererNameInput] = React.useState<string>('');
  const [registrarNameInput, setRegistrarNameInput] = React.useState<string>('');

  const doctorsOrdersContext = React.useContext(DoctorsOrdersContext);
  const { documents, fm, isEditing } = doctorsOrdersContext;

  const userId = getJWTData()?.useruuid || null;

  // Get all documents from redux to be passed to getCreateDate
  const allDocs = useSelector((s: { documents: IDocumentStore }) => s.documents.documents);

  // Document id isn't available when editing for the first time so find it from documents arr
  const documentId = documents.find((d) => d._editing === true)?._id;

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [deleteDialogData, setDeleteDialogData] = React.useState<{ [key: string]: any } | undefined>(undefined);

  const openDeleteDialog = (data: typeof deleteDialogData): void => {
    setDeleteDialogOpen(true);
    setDeleteDialogData(data);
  };

  const deleteCancelCallback = (): void => {
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };

  const deleteConfirmCallback = (): void => {
    deleteDialogData?.data && onChange?.(deleteDialogData?.data);
    deleteDialogData?.update();
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };

  React.useEffect(() => {
    getOrganizationUserData()
      .then((res) => {
        if (Array.isArray(res)) {
          const users = res;
          // TODO: filter out / separate inactive users (?)
          setOrganizationUserData(users);
          if (userId && onChange) {
            // Set default orderer
            const ordererId = document?.ordererId ? document.ordererId : userId;
            ordererId && onChange({ ordererId: ordererId });
            isEmpty(ordererNameInput) && setOrdererNameInput(findUserNames(ordererId, users));

            // Set default registrar
            const registrarId = document?.registrarId ? document.registrarId : userId;
            registrarId && onChange({ registrarId: registrarId });
            isEmpty(registrarNameInput) && setRegistrarNameInput(findUserNames(registrarId, users));
          }
        }
      })
      .catch(() => {
        return;
      });

    return () => {
      // Reset active view back to subjectOfOrder when unmounted
      // Needed for some automatically generated values to work (eg. date)
      setActiveView('subjectOfOrder');
    };
  }, []);

  const onChangeNameInput =
    (name: 'orderer' | 'registrar') =>
    (e: any, d: string): void => {
      !isNil(e) && (name === 'orderer' ? setOrdererNameInput(d) : setRegistrarNameInput(d));
    };

  // Automatically select view based on existing values
  React.useEffect(() => {
    const d = omit(['date', 'ordererId', 'registrarId', 'treatmentType', 'completed'], document);

    const omitRTMSFields: Array<keyof IRTMSSession> = [
      'doesNotApplyToRMT',
      'doesNotApplyToSubjectOfTreatment',
      'createDate',
    ];

    setActiveView(
      !(Object.keys(d) as (keyof typeof d)[]).some((k) => {
        if (k === 'rtms' || k === 'tdcs') {
          const d2 = d[k] ? omit<Partial<IRTMSSession>, keyof IRTMSSession>(omitRTMSFields, d[k]) : {};
          return (Object.keys(d2) as (keyof typeof d2)[]).some((k2) => {
            return d2[k2] && !isEmpty(d2[k2]);
          });
        }
        return d[k] && !isEmpty(d[k]);
      })
        ? 'subjectOfOrder'
        : 'form',
    );
  }, []);

  const [subjectOfOrder, setSubjectOfOrder] = React.useState<{
    rtms: Array<string>;
    tdcs: Array<string>;
    other: Array<string>;
  }>({ rtms: [], tdcs: [], other: [] });

  const rtmsAndOtherSubjectOfOrder = [...subjectOfOrder.rtms, ...subjectOfOrder.other];

  const changeSubjectOfOrder =
    (name: 'rtms' | 'tdcs' | 'other') =>
    (values: TOnChangeValues): void => {
      const value = values[Object.keys(values)[0]] as string[];

      const updateSubjectOfOrder = (): void => {
        if (
          value?.includes('endTreatment') &&
          ((name === 'rtms' && (subjectOfOrder.tdcs.length === 0 || subjectOfOrder.tdcs.includes('endTreatment'))) ||
            (name === 'tdcs' && (subjectOfOrder.rtms.length === 0 || subjectOfOrder.rtms.includes('endTreatment'))))
        ) {
          setSubjectOfOrder({ ...subjectOfOrder, [name]: value, other: [] });
        } else {
          setSubjectOfOrder({ ...subjectOfOrder, [name]: value });
        }
      };

      // Nullify value(s) if related subject of order is deselected
      if (value.length < subjectOfOrder[name].length) {
        const deleted = subjectOfOrder[name].find((s) => !value.includes(s));

        if (deleted && name !== 'other') {
          const newValues = JSON.parse(JSON.stringify(document[name] ?? {}));

          switch (deleted) {
            case 'rmt':
              newValues.device = null;
              newValues.rmt = null;
              newValues.peelingDepth = null;
              newValues.rmtLocation = null;
              break;
            case 'treatmentProtocolAndBackupSubjectsOfTreatment':
              newValues.subjectOfTreatment = null;
              newValues.backupSubjectOfTreatment = null;
              newValues.additionalInformation = null;
              break;
            case 'treatmentFrequency':
              newValues.treatmentFrequency = null;
              break;
            case 'treatmentMonitoringFrequency':
              newValues.treatmentMonitoringFrequency = null;
              break;
            case 'endTreatment':
              if (name === 'rtms') newValues.endRTMS = null;
              if (name === 'tdcs') newValues.endTDCS = null;
              break;
            case 'other':
              newValues.otherOrder = null;
              break;
          }
          const updateValues = { [name]: newValues };

          if (deleted && isEmpty(subjectOfOrder[name].filter((s) => s !== deleted))) {
            updateValues.treatmentType = (document.treatmentType ?? []).filter((t) => t !== name);
          }

          // Ask confirmation in case data exists
          if (
            Object.keys(newValues).filter((k) => newValues[k]).length <
            Object.keys(document[name] ?? {}).filter((k) => (document[name] as { [key: string]: any })[k]).length
          ) {
            openDeleteDialog({ data: updateValues, update: updateSubjectOfOrder });
          } else {
            onChange?.(updateValues);
            updateSubjectOfOrder();
          }
        } else if (deleted) {
          // Ask confirmation in case data exists
          if (document[deleted as keyof typeof document]) {
            openDeleteDialog({ data: { [deleted]: null }, update: updateSubjectOfOrder });
          } else {
            onChange?.({ [deleted]: null });
            updateSubjectOfOrder();
          }
        }
      } else if (name === 'rtms' || name === 'tdcs') {
        const s = document[name] as IRTMSSession | ITDCSSession;
        const updateValues: { rtms?: IRTMSSession; tdcs?: ITDCSSession; treatmentType?: ('rtms' | 'tdcs')[] } = {};
        if (
          value.includes('treatmentProtocolAndBackupSubjectsOfTreatment') &&
          (!s || !s.createDate) &&
          Array.isArray(allDocs) &&
          documentId
        ) {
          // Record order createDate to RTMS/TDCS
          updateValues[name] = { ...(s ?? {}), createDate: getCreateDate(allDocs, documentId) };
        }
        // Record treatment type
        updateValues.treatmentType = (document.treatmentType ?? []).includes(name)
          ? document.treatmentType
          : [...(document.treatmentType ?? []), name];
        onChange?.(updateValues);
      }

      const otherFields = ['nextRMT', 'nextInterview', 'ninmtMeeting', 'addedToAgenda', 'requestFollowUp'];
      const existingFields = Object.keys(document).filter(
        (field) => otherFields.includes(field) && document[field as keyof typeof document],
      );

      // Selecting endTreatment should nullify 'other' fields
      // Ask confirmation in case data exists
      if (value.includes('endTreatment') && existingFields.length > 0) {
        const nullifiedData = Object.fromEntries(existingFields.map((field) => [field, null]));
        openDeleteDialog({
          data: nullifiedData,
          update: updateSubjectOfOrder,
        });
      } else if (value.length >= subjectOfOrder[name].length) {
        updateSubjectOfOrder();
      }
    };

  // Automatically select subject of order if editing existing order and values exist
  React.useEffect(() => {
    const newSubjectOfOrder: typeof subjectOfOrder = {
      rtms: [],
      tdcs: [],
      other: [],
    };
    const rtmsTdcsArr: Array<'rtms' | 'tdcs'> = ['rtms', 'tdcs'];

    [
      'rmt',
      'treatmentProtocolAndBackupSubjectsOfTreatment',
      'treatmentFrequency',
      'treatmentMonitoringFrequency',
      'endTreatment',
      'other',
      'nextInterview',
      'ninmtMeeting',
      'requestFollowUp',
    ].forEach((subject) => {
      if (['nextInterview', 'ninmtMeeting', 'requestFollowUp'].includes(subject)) {
        if (document[subject as keyof typeof document] && !subjectOfOrder.other.includes(subject)) {
          newSubjectOfOrder.other.push(subject);
        }
      } else {
        rtmsTdcsArr.forEach((name) => {
          switch (subject) {
            case 'rmt': {
              if (
                name === 'rtms' &&
                !document.rtms?.doesNotApplyToRMT &&
                (document.rtms?.device ||
                  document.rtms?.rmt ||
                  document.rtms?.peelingDepth ||
                  document.rtms?.rmtLocation) &&
                !subjectOfOrder.rtms.includes('rmt')
              ) {
                newSubjectOfOrder.rtms.push('rmt');
              }
              break;
            }
            case 'treatmentProtocolAndBackupSubjectsOfTreatment': {
              if (
                !document[name]?.doesNotApplyToSubjectOfTreatment &&
                (document[name]?.subjectOfTreatment || document[name]?.backupSubjectOfTreatment) &&
                !subjectOfOrder[name].includes('treatmentProtocolAndBackupSubjectsOfTreatment')
              ) {
                newSubjectOfOrder[name].push('treatmentProtocolAndBackupSubjectsOfTreatment');
              }
              break;
            }
            case 'treatmentFrequency': {
              if (document[name]?.treatmentFrequency && !newSubjectOfOrder[name].includes('treatmentFrequency')) {
                newSubjectOfOrder[name].push('treatmentFrequency');
              }
              break;
            }
            case 'treatmentMonitoringFrequency': {
              if (
                name === 'tdcs' &&
                document.tdcs?.treatmentMonitoringFrequency &&
                !newSubjectOfOrder.tdcs.includes('treatmentMonitoringFrequency')
              ) {
                newSubjectOfOrder.tdcs.push('treatmentMonitoringFrequency');
              }
              break;
            }
            case 'endTreatment': {
              if (
                ((name === 'rtms' && document.rtms?.endRTMS === true) ||
                  (name === 'tdcs' && document.tdcs?.endTDCS === true)) &&
                !newSubjectOfOrder[name].includes('endTreatment')
              ) {
                newSubjectOfOrder[name].push('endTreatment');
              }
              break;
            }
            case 'other': {
              if (document[name]?.otherOrder && !newSubjectOfOrder[name].includes('other')) {
                newSubjectOfOrder[name].push('other');
              }
              break;
            }
          }
        });
      }
    });
    setSubjectOfOrder(newSubjectOfOrder);
  }, []);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date" headerWidth={3}>
          <InputHandler
            type="PartialDate"
            name="date"
            editing={isEditing && activeView === 'subjectOfOrder'}
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <React.Fragment>
          {['orderer', 'registrar'].map((name) => (
            <FormRow key={name} title={`doctorsOrders.${name}`} headerWidth={3}>
              {isEditing ? (
                <InputHandler
                  type="AutoCompleteSelect"
                  editing={isEditing && activeView === 'subjectOfOrder'}
                  name={`${name}Id`}
                  formData={formData}
                  options={organizationUserData.map((user) => user?.userId)}
                  renderOption={(o: any) =>
                    o && !isEmpty(o) ? (
                      <React.Fragment key={`${findUserNames(o, organizationUserData)}`}>
                        {findUserNames(o, organizationUserData)}
                      </React.Fragment>
                    ) : (
                      ''
                    )
                  }
                  getOptionLabel={(o: string): string =>
                    o && !isEmpty(o) ? findUserNames(o, organizationUserData) : ''
                  }
                  onInputChange={onChangeNameInput(name as 'orderer' | 'registrar')}
                  width={27}
                  placeholder={`doctorsOrders.${name}Placeholder`}
                  groupID={`${name}IdGroup`}
                />
              ) : (
                <span style={{ fontWeight: 600 }}>
                  {typeof document?.[`${name}Id` as keyof IDoctorsOrder] === 'string'
                    ? findUserNames(document?.[`${name}Id` as keyof IDoctorsOrder] as string, organizationUserData)
                    : '-'}
                </span>
              )}
            </FormRow>
          ))}
        </React.Fragment>

        {/**
         * Subject of order view
         */}
        {activeView === 'subjectOfOrder' && (
          <React.Fragment>
            <FormRow title="doctorsOrders.subjectOfOrder.title" headerWidth={3}>
              <Container style={{ fontWeight: 600, marginBottom: '1.5rem' }}>
                <Item xs={4}>{fm('doctorsOrders.subjectOfOrder.rtms')}</Item>
                <Item xs={4}>{fm('doctorsOrders.subjectOfOrder.tdcs')}</Item>
                <Item xs={4}>{fm('doctorsOrders.subjectOfOrder.other')}</Item>
              </Container>
              <Container>
                <Item xs={4}>
                  <InputHandler
                    type="Checkbox"
                    name="subjectOfOrder"
                    editing={isEditing}
                    formData={{
                      document: { subjectOfOrder: subjectOfOrder.rtms },
                      onChange: (values) => changeSubjectOfOrder('rtms')(values),
                    }}
                    options={[
                      'rmt',
                      'treatmentProtocolAndBackupSubjectsOfTreatment',
                      'treatmentFrequency',
                      'endTreatment',
                      'other',
                    ]}
                    optionFormatter={(name: string | number): string => fm(`doctorsOrders.subjectOfOrder.opts.${name}`)}
                    disabledOptions={
                      subjectOfOrder.rtms.some((s) =>
                        ['rmt', 'treatmentProtocolAndBackupSubjectsOfTreatment', 'treatmentFrequency'].includes(s),
                      )
                        ? ['endTreatment']
                        : subjectOfOrder.rtms.some((s) => ['endTreatment'].includes(s))
                          ? ['rmt', 'treatmentProtocolAndBackupSubjectsOfTreatment', 'treatmentFrequency']
                          : undefined
                    }
                  />
                </Item>
                <Item xs={4}>
                  <InputHandler
                    type="Checkbox"
                    name="subjectOfOrder"
                    editing={isEditing}
                    formData={{
                      document: { subjectOfOrder: subjectOfOrder.tdcs },
                      onChange: (values) => changeSubjectOfOrder('tdcs')(values),
                    }}
                    options={[
                      'treatmentProtocolAndBackupSubjectsOfTreatment',
                      'treatmentFrequency',
                      'treatmentMonitoringFrequency',
                      'endTreatment',
                      'other',
                    ]}
                    optionFormatter={(name: string | number): string => fm(`doctorsOrders.subjectOfOrder.opts.${name}`)}
                    disabledOptions={
                      subjectOfOrder.tdcs.some((s) =>
                        [
                          'treatmentProtocolAndBackupSubjectsOfTreatment',
                          'treatmentFrequency',
                          'treatmentMonitoringFrequency',
                        ].includes(s),
                      )
                        ? ['endTreatment']
                        : subjectOfOrder.tdcs.some((s) => ['endTreatment'].includes(s))
                          ? [
                              'treatmentProtocolAndBackupSubjectsOfTreatment',
                              'treatmentFrequency',
                              'treatmentMonitoringFrequency',
                            ]
                          : undefined
                    }
                  />
                </Item>
                <Item xs={4}>
                  <InputHandler
                    type="Checkbox"
                    name="subjectOfOrder"
                    editing={isEditing}
                    formData={{
                      document: { subjectOfOrder: subjectOfOrder.other },
                      onChange: (values) => changeSubjectOfOrder('other')(values),
                    }}
                    options={['nextInterview', 'ninmtMeeting', 'requestFollowUp']}
                    optionFormatter={(name: string | number): string => fm(`doctorsOrders.subjectOfOrder.opts.${name}`)}
                    optionSpecificStyles={[
                      { requestFollowUp: { padding: '0.5rem 0 0.5rem 0' } },
                      { ninmtMeeting: { padding: '0.5rem 0 0.5rem 0' } },
                    ]}
                    disabledOptions={
                      (subjectOfOrder.rtms.includes('endTreatment') && subjectOfOrder.tdcs.includes('endTreatment')) ||
                      (subjectOfOrder.rtms.includes('endTreatment') && subjectOfOrder.tdcs.length === 0) ||
                      (subjectOfOrder.tdcs.includes('endTreatment') && subjectOfOrder.rtms.length === 0)
                        ? ['nextInterview', 'ninmtMeeting', 'requestFollowUp']
                        : undefined
                    }
                  />
                </Item>
              </Container>
            </FormRow>

            <Container>
              <Item xs={3} />
              <Item xs={9}>
                <ActionButtonRounded
                  width={7.5}
                  height={4}
                  fontSize={16}
                  text="general.continue"
                  onClick={() => setActiveView('form')}
                  disabled={
                    subjectOfOrder.rtms.length === 0 &&
                    subjectOfOrder.tdcs.length === 0 &&
                    subjectOfOrder.other.length === 0
                  }
                  filled
                />
              </Item>
            </Container>
          </React.Fragment>
        )}

        {/**
         * Form view
         */}
        {activeView === 'form' && (
          <React.Fragment>
            {subjectOfOrder.rtms.length > 0 && <RTMSForm subjectOfOrder={subjectOfOrder.rtms} />}
            {subjectOfOrder.tdcs.length > 0 && <TDCSForm subjectOfOrder={subjectOfOrder.tdcs} />}
            {rtmsAndOtherSubjectOfOrder.length > 0 && <OtherForm subjectOfOrder={rtmsAndOtherSubjectOfOrder} />}
          </React.Fragment>
        )}
      </FormSection>
      <ConfirmationDialog
        open={deleteDialogOpen}
        text={fm('general.dependentFieldsRemovalWarning')}
        confirm={{ callback: deleteConfirmCallback, text: 'general.continue' }}
        cancel={{ callback: deleteCancelCallback }}
      />
    </React.Fragment>
  );
};

interface IDoctorsOrdersFormProps {
  formData: IFormData<IDoctorsOrder>;
  activeView: 'subjectOfOrder' | 'form';
  setActiveView: React.Dispatch<React.SetStateAction<'subjectOfOrder' | 'form'>>;
}

export default DoctorsOrdersForm;
