import * as React from 'react';

const svgImage = (): React.JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(0.406083,0,0,0.406083,-196.271,-24.1365)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-22.5368,-0.679286)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,52.1857,-22.9719)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-100.518,-2.64215)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-235.193,-3.43873)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-132.675,-18.0021)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-176.197,27.4274)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-232.041,28.6721)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-178.131,-41.2036)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-105.677,84.1694)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-136.957,15.4198)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-22.7947,69.4137)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.0621477,-0.288481,0.425793,0.091729,-205.243,345.341)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(0.366398,-0.175088,0.175088,0.366398,-245.025,17.2765)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.363396,-0.181236,0.181236,0.363396,-346.034,2.5478)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.271717,-0.301783,0.301783,0.271717,-289.64,267.821)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(0.111139,-0.123437,0.123437,0.111139,-84.8417,202.957)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.123734,-0.839981,0.351973,0.0518476,-178.209,740.168)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.916123,0,0,0.0959804,-293.027,116.45)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.661704,-0.301233,0.0397671,0.0873545,-208.078,429.212)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(-0.413229,0.783879,-0.314719,-0.165907,609.224,-245.974)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.541419,-0.0108147,0.0119373,0.597622,-251.917,-203.005)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.845471,-0.141068,0.103032,0.617508,-379.096,-83.7724)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.352662,0.78125,-0.624692,0.281991,411.685,-466.076)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.82317,-0.238984,0.226121,0.778864,-370.799,-226.597)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(-0.484369,-0.242154,0.281642,-0.563355,265.75,727.913)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.856699,0.0280858,-0.0220953,0.67397,-290.112,-313.393)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
  </svg>
);

export default svgImage;
