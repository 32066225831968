import * as React from 'react';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import NotSeizureDialog from '../../../../components/ConfirmationDialog';
import { IFormContext, withFormContext } from '../../../../containers/FormContextHandler';
import { seizureClassifications } from '../../../Diagnosis/utils/definitions';
import { formatSeizureClassification, getOwnSeizureTypesClassifications } from '../../utils';
import FormRow from '../../../../components/FormRow';
import colors from '../../../../config/theme/colors';
import { MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { isPartialDate } from 'neuro-utils';

const GroupHeader = styled('div')({
  position: 'sticky' as const,
  height: '3.6rem',
  fontSize: '1.0rem',

  top: '-1px', // Counter padding and sticky needs this
  paddingTop: '0.7rem',
  color: colors.darkGray,
  backgroundColor: colors.white,
  alignItems: 'center',
  marginTop: '0rem',
  '& .MuiMenuItem-root.Mui-disabled': {
    opacity: 1,
    fontSize: '1.4rem',
  },
  zIndex: 100,
});

/**
 * Indent options that are subclass of another number e.g. 1.0.1 is intended under 1.0
 * @param o
 * @returns Intended option if subnumber else default
 */
const formatOptionIndent = (o: string): React.JSX.Element => {
  const classificationString = o;
  const regex = /^[0-9]\.[0-9]\.[0-9]\.[0-9]/;
  const regex2 = /^[0-9]\.[0-9]\.[0-9]\s/;
  const indentOption = classificationString && classificationString.match(regex);
  const indentOption2 = classificationString && classificationString.match(regex2);
  const splittedAndShifted = indentOption && classificationString.split(' ');
  splittedAndShifted && splittedAndShifted.shift();
  const splittedAndShifted2 = indentOption2 && classificationString.split(' ');
  splittedAndShifted2 && splittedAndShifted2.shift();
  const final = splittedAndShifted && splittedAndShifted.join(' ');
  const final2 = splittedAndShifted2 && splittedAndShifted2.join(' ');
  const indentedOption = splittedAndShifted && `${indentOption} ${final}`;
  const indentedOption2 = splittedAndShifted2 && `${indentOption2} ${final2}`;
  if (indentOption) {
    return (
      <React.Fragment key={classificationString}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {formatSeizureClassification(indentedOption?.split(' ')[0] as TSeizureClassification, 'short')}
      </React.Fragment>
    );
  } else if (indentOption2) {
    return (
      <React.Fragment key={classificationString}>
        &nbsp;&nbsp;&nbsp;
        {formatSeizureClassification(indentedOption2?.split(' ')[0] as TSeizureClassification, 'short')}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment key={classificationString}>
        {formatSeizureClassification(classificationString?.split(' ')[0] as TSeizureClassification, 'short')}
      </React.Fragment>
    );
  }
};

const SeizureForm = ({ fm, formData, editing }: IFormContext<ISeizure>): React.JSX.Element => {
  const controlOpts = ['other', 'notSeizure', 'return'];
  const controlOptsOG = ['other', 'notSeizure'];
  const originalSet = getOwnSeizureTypesClassifications();
  const [classificationOpts, setClassificationOpts] = React.useState<string[]>(originalSet.concat(controlOptsOG));
  const [classificationMenuOpen, setClassificationMenuOpen] = React.useState<boolean>(false);
  const [notSeizureDialogOpen, setNotSeizureDialogOpen] = React.useState<boolean>(false);
  const [timeframeInput, setTimeframeInput] = React.useState<boolean>(
    !!(formData?.document.endDate && isPartialDate(formData?.document.endDate)),
  );

  const onCloseClassification = (e: React.BaseSyntheticEvent): void => {
    if (e.target.dataset.value === 'other' || e.target.dataset.value === 'return') return;
    setClassificationMenuOpen(false);
  };

  // "null check"
  if (!fm || !formData) throw 'fm or formData is undefined';

  const classificationWithHeaders: string[] = [];
  classificationOpts.forEach((clas, i) => {
    // Add headers to all top level classifications that have children
    if (clas.length === 3 && classificationOpts[i + 1].match('^' + clas)) {
      classificationWithHeaders.push('header' + clas);
    }
    classificationWithHeaders.push(clas);
  });

  const handleCancelNotSeizure = (): void => {
    setNotSeizureDialogOpen(false);
  };

  const handleConfirmNotSeizure = (): void => {
    formData.onChange && formData.onChange({ classification: 'notSeizure' });
    setNotSeizureDialogOpen(false);
  };

  return (
    <>
      <FormSection>
        <FormRow title={'seizure.startDate'}>
          <InputHandler name="date" type="PartialDate" isNotCancellable editing={!!editing} formData={formData} />
        </FormRow>
        <FormRow title={'seizure.reportTimeframe'}>
          <InputHandler
            name="reportTimeframe"
            type="CheckboxSingle"
            editing={!!editing}
            option={'true'}
            showLabel={false}
            optionFormatter={(o) => fm(`general.${o}`)}
            formData={{
              document: { reportTimeframe: timeframeInput },
              onChange: () => {
                setTimeframeInput((prev) => !prev);
                formData?.onChange?.({ time: undefined });
                formData?.onChange?.({ endDate: undefined });
              },
            }}
            dependentFieldsList={() => ['time', 'endDate']}
          />
        </FormRow>
        <FormRow title={'seizure.startTime'} condition={!timeframeInput}>
          <InputHandler name="time" type="TimePicker" editing={!!editing} formData={formData} />
        </FormRow>
        <FormRow title={'seizure.endDate'} condition={timeframeInput}>
          <InputHandler name="endDate" type="PartialDate" editing={!!editing} formData={formData} />
        </FormRow>
        <FormRow title={'seizure.classification'}>
          <InputHandler
            name="classification"
            type="Select"
            editing={!!editing}
            formData={{
              document: formData.document || {},
              onChange: (values: TOnChangeValues): void => {
                const name = Object.keys(values)[0];
                const value = values[name];
                if (controlOpts.includes(value as any)) {
                  switch (value) {
                    case 'other':
                      setClassificationOpts([...seizureClassifications, 'notSeizure', 'return']);
                      break;
                    case 'notSeizure':
                      setNotSeizureDialogOpen(true);
                      break;
                    case 'return':
                      setClassificationOpts([...originalSet, 'other', 'notSeizure']);
                      formData.onChange && formData.onChange({ [name]: null });
                      break;
                  }
                } else {
                  formData.onChange && formData.onChange({ [name]: value });
                }
              },
            }}
            options={classificationWithHeaders}
            optionFormatter={(o): string | React.JSX.Element => {
              return controlOpts.includes(`${o}`) ? (
                fm(`seizure.${o}`)
              ) : (
                <span>
                  {classificationMenuOpen && seizureClassifications.every((c) => classificationOpts.includes(c))
                    ? formatOptionIndent(`${o} ${fm(`seizure.opts.${o}`)}`)
                    : classificationMenuOpen
                      ? formatSeizureClassification(o as TSeizureClassification, 'long')
                      : formatSeizureClassification(o as TSeizureClassification, 'short')}
                </span>
              );
            }}
            menuItemComponent={(o: string | number, optFormatter) => {
              const option = `${o}`;
              if (option.substring(0, 6) === 'header') {
                // Different menuitems for group headers
                return (
                  <GroupHeader
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <MenuItem disabled>
                      {optFormatter ? optFormatter(option.substring(6)) : option.substring(6)}
                    </MenuItem>
                  </GroupHeader>
                );
              }
              return (
                <MenuItem value={option} key={option}>
                  {optFormatter ? (
                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {optFormatter(option)}
                      {/** Show parent group for deep child items when big selection is open */}
                      {classificationMenuOpen &&
                      seizureClassifications.every((c) => classificationOpts.includes(c)) &&
                      option.split('.').length > 3 ? (
                        <>
                          <span style={{ padding: '0.4rem 0.5rem 0 0.5rem', fontSize: '1.2rem' }}>-</span>
                          <span style={{ paddingTop: '0.4rem', fontSize: '1.2rem' }}>
                            {fm('seizure.opts.' + option.split('.').slice(0, -1).join('.'))}
                          </span>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    option
                  )}
                </MenuItem>
              );
            }}
            width={40}
            minWidth={18}
            placeholder={'seizure.classificationPlaceholder'}
            open={classificationMenuOpen}
            onOpen={(): void => setClassificationMenuOpen(true)}
            onClose={onCloseClassification}
          />
        </FormRow>
        <FormRow title={'seizure.count'}>
          <InputHandler
            name="count"
            type="NumberField"
            editing={!!editing}
            formData={formData}
            placeholder={'seizure.countPlaceholder'}
            width={18}
          />
        </FormRow>
        <FormRow title={'seizure.medicComplianceShort'}>
          <InputHandler
            name="medicCompliance"
            type="Radio"
            editing={!!editing}
            formData={formData}
            options={['yes', 'no', 'unknown']}
            optionFormatter={(o): string => fm(`general.${o}`)}
          />
        </FormRow>
        <FormRow title={'seizure.seizureMedicationGiven'}>
          <InputHandler
            name="seizureMedicationGiven"
            type="CheckboxSingle"
            editing={!!editing}
            formData={formData}
            option={'true'}
            showLabel={false}
            optionFormatter={(o) => fm(`general.${o}`)}
          />
        </FormRow>
      </FormSection>
      <NotSeizureDialog
        cancel={{ callback: handleCancelNotSeizure }}
        confirm={{ callback: handleConfirmNotSeizure }}
        open={notSeizureDialogOpen}
        text={fm('seizure.confirmNotSeizure')}
      />
    </>
  );
};

export default withFormContext(SeizureForm);
