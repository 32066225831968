/**
 * Context to provide document histories with additional tools to edit (some parts of) locked documents
 * * Can be used to create a dialog to display editable fields and inputs or supply an existing form with data about editable fields
 */

import { assocPath } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { actions } from 'Store/documents/actions';
import { useAppDispatch, useAppSelector } from 'Store/index';
import Dialog from 'Components/_NewElements/Dialog';
import StandAloneFormEditingHandler from 'Containers/StandAloneFormEditingHandler';
import { possibleEditableFields } from './configs';

export const EditingLockedContentContext = React.createContext<{
  openEditing: (document: IControlProps, header?: string) => void;
  allowEditingLockedDocument: (doc: IControlProps) => boolean;
}>({ openEditing: () => '', allowEditingLockedDocument: () => false });

const EditingLockedContent = ({
  currentPage,
  children,
}: {
  currentPage: string;
  children: (React.JSX.Element | null)[];
}): React.JSX.Element => {
  const platform = useAppSelector((s) => s.session.platforms?.selected);
  const dispatch = useAppDispatch();

  const [formDetails, setFormDetails] = React.useState<{
    document: IControlProps;
    header?: string;
  } | null>(null);
  const [formNewData, setFormNewData] = React.useState<Record<string, any>>({});

  const hasEditableFieldsInLockedDocument =
    formDetails?.document._lockedFor &&
    formDetails?.document._source !== null &&
    platform &&
    possibleEditableFields?.[platform]?.[formDetails?.document._type];

  const onChange = (values: TOnChangeValues) => {
    const fieldName = Object.keys(values)[0]; // Only support single {field: value} for now
    const value = values[fieldName];

    let handledValues = {};
    const path = fieldName.split('.'); // Some forms use dots in the field name to assign the value in lower hierarchy objects
    if (path.length > 1) {
      handledValues = {
        // Get previous object from form data and append it with new object data
        [path[0]]: assocPath(path.slice(1), value, {
          ...((formDetails?.document as Record<string, any>)[path[0]] || {}), // New
          ...(formNewData[path[0]] || {}), // Previous
        }),
      };
    } else {
      handledValues = { [fieldName]: value };
    }

    setFormNewData({ ...formNewData, ...handledValues });
  };

  const onSave = () => {
    if (Object.keys(formNewData).length > 0) {
      formDetails &&
        actions.modifyField(
          { name: formDetails?.document._type, id: formDetails?.document._id },
          formDetails?.document,
          formNewData,
          true,
        )(dispatch);
    }
    setFormDetails(null);
    setFormNewData({});
  };
  const formSettings =
    platform && formDetails?.document && possibleEditableFields[platform]?.[formDetails?.document._type];

  return (
    <EditingLockedContentContext.Provider
      value={{
        openEditing: (document, header) => setFormDetails({ document, header }),
        allowEditingLockedDocument: (doc) => {
          if (doc && platform && possibleEditableFields[platform][doc._type]) {
            return true;
          }
          return false;
        },
      }}
    >
      {formDetails && formSettings?.formType === 'normal' ? null : children}
      {formSettings?.formType === 'dialog' && hasEditableFieldsInLockedDocument && (
        <Dialog
          open={!!formDetails}
          title={<FormattedMessage id={formDetails?.header || 'general.edit'} />}
          dialogActions={[
            {
              text: 'general.cancel',
              onClick: () => {
                setFormDetails(null);
                setFormNewData({});
              },
            },
            {
              text: 'general.save',
              alternate: true,
              filled: true,
              onClick: () => {
                onSave();
              },
            },
          ]}
        >
          {formSettings && formSettings.component(onChange, { ...formDetails.document, ...formNewData }, formSettings)}
        </Dialog>
      )}
      {formSettings?.formType === 'normal' && hasEditableFieldsInLockedDocument && (
        <StandAloneFormEditingHandler
          currentPageName={currentPage}
          headerLocaleId={formDetails.header || 'general.edit'}
          cancelEditingAction={() => {
            setFormDetails(null);
            setFormNewData({});
          }}
          saveAction={() => onSave()}
        >
          {formSettings && formSettings.component(onChange, { ...formDetails.document, ...formNewData }, formSettings)}
        </StandAloneFormEditingHandler>
      )}
    </EditingLockedContentContext.Provider>
  );
};

export default EditingLockedContent;
