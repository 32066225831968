import * as React from 'react';
import { styled } from '@mui/system';
import { FormattedMessage } from 'react-intl';

import DocumentWrapper from '../../DocumentWrapper';
import colors from '../../../config/theme/colors';

const CensoredInfoView = styled('div')({
  color: colors.primaryText,
  fontSize: '4.5rem',
  height: '50vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

export const CensoredInfo = (): React.JSX.Element => {
  return (
    <>
      <DocumentWrapper>
        <CensoredInfoView>
          <FormattedMessage id="profile.censoredModeHiddenContentMsg" />
        </CensoredInfoView>
      </DocumentWrapper>
    </>
  );
};
