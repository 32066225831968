import * as React from 'react';
import { exists, formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import EventStepper from '../../../../../../components/EventStepper';
import InputHandler from '../../../../../../components/InputHandler';
import {
  StepperHeaderFormInputPair,
  StepperHeaderValuePair,
} from '../../../../../../components/EventStepper/components';
import { Container, Item } from 'Components/Grid';

type TValueTypes = string | number | PartialDate;

const FormattedValue = ({ name, value }: { name: string; value?: TValueTypes }): React.JSX.Element => (
  <React.Fragment>
    {exists(value) ? (
      name === 'humidifierInUse' ? (
        <span style={{ fontWeight: 600 }}>{fm(`treatment.opts.yesNoUnknown.${value}`)}</span>
      ) : (
        <span style={{ fontWeight: 600 }}>
          {['implementation', 'tubeType'].includes(name) ? fm(`treatment.papTherapy.opts.${value}`) : value}
        </span>
      )
    ) : (
      '-'
    )}
  </React.Fragment>
);

const ImplementationMethods = ({ formData, viewing }: IOwnProps): React.JSX.Element => {
  return (
    <EventStepper
      name="implementationMethods"
      formData={formData}
      stepLabelText={(d: IPapImplementationMethod): string => formatPartialDate(d.date)}
      stepContent={(d: IPapImplementationMethod): React.JSX.Element => (
        <React.Fragment>
          {[
            'implementation',
            'maskBrand',
            'maskSize',
            'additionalInformation',
            'tubeType',
            'tubeName',
            'humidifierInUse',
            'filtersGiven',
          ].map((key, index) => {
            const value = d[key as keyof IPapImplementationMethod];
            if (key === 'tubeName' && d.tubeType !== 'heatTube') return <React.Fragment key={`${key}${index}`} />;
            return (
              <StepperHeaderValuePair
                key={`${key}${index}`}
                header={fm(`treatment.papTherapy.${key === 'implementation' ? 'implementationMethod' : key}`)}
                value={
                  value
                    ? ['maskBrand', 'additionalInformation', 'tubeName', 'filtersGiven', 'maskSize'].includes(key)
                      ? value
                      : fm(`treatment.${key === 'humidifierInUse' ? 'opts.yesNoUnknown' : `papTherapy.opts`}.${value}`)
                    : '-'
                }
              />
            );
          })}
        </React.Fragment>
      )}
      addNewTextHeader="treatment.papTherapy.newImplementationMethod"
      addNewTextButton="treatment.papTherapy.newImplementationMethod"
      previousEventsTextHeader="treatment.papTherapy.previousImplementationMethods"
      noPreviousEventsTextHeader="treatment.papTherapy.noPreviousImplementationMethods"
      editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => (
        <React.Fragment>
          <StepperHeaderFormInputPair
            header={fm('general.date')}
            input={
              <InputHandler
                type="PartialDate"
                editing={true}
                name="date"
                formData={{
                  onChange,
                  document: { date: formData.document.implementationMethods?.[index]?.date || '' },
                }}
                dateDefault="now"
                isNotCancellable={true}
                dateHook={{
                  dateHookFloor: formData.document.date,
                }}
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.treatmentImplementation')}
            input={
              <InputHandler
                type="Radio"
                editing={true}
                name="implementation"
                formData={{
                  onChange,
                  document: { implementation: formData.document.implementationMethods?.[index]?.implementation || '' },
                }}
                options={['tracheostomy', 'fullFaceMask', 'nasalMouthMask', 'nasalMask', 'nasalPlugs', 'mouthpiece']}
                optionFormatter={(name: string | number): React.JSX.Element => fm(`treatment.papTherapy.opts.${name}`)}
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.maskBrand')}
            input={
              <InputHandler
                type="TextField"
                editing={true}
                name="maskBrand"
                formData={{
                  onChange,
                  document: { maskBrand: formData.document.implementationMethods?.[index]?.maskBrand },
                }}
                placeholder={'treatment.papTherapy.brand'}
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.maskSize')}
            input={
              <InputHandler
                type="Select"
                editing={true}
                name="maskSize"
                formData={{
                  onChange,
                  document: { maskSize: formData.document.implementationMethods?.[index]?.maskSize },
                }}
                options={['XS', 'S', 'M', 'L', 'XL']}
                placeholder={fm('treatment.papTherapy.size')}
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.additionalInformation')}
            input={
              <InputHandler
                type="TextArea"
                editing={true}
                name="additionalInformation"
                formData={{
                  onChange,
                  document: {
                    additionalInformation: formData.document.implementationMethods?.[index]?.additionalInformation,
                  },
                }}
                placeholder="treatment.papTherapy.additionalInformation"
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.tubeType')}
            input={
              <InputHandler
                type="Radio"
                editing={true}
                name="tubeType"
                formData={{
                  onChange,
                  document: { tubeType: formData.document.implementationMethods?.[index]?.tubeType },
                }}
                options={['slimline', 'standard', 'heatTube']}
                optionFormatter={(name: string | number): React.JSX.Element => fm(`treatment.papTherapy.opts.${name}`)}
                dependentFieldsList={(): string[] => ['tubeName']}
              />
            }
          />
          {formData.document.implementationMethods?.[index]?.tubeType === 'heatTube' && (
            <StepperHeaderFormInputPair
              header={''}
              input={
                <InputHandler
                  type="TextField"
                  editing={true}
                  name="tubeName"
                  formData={{
                    onChange,
                    document: { tubeName: formData.document.implementationMethods?.[index]?.tubeName },
                  }}
                  placeholder={'treatment.papTherapy.tubeName'}
                />
              }
            />
          )}
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.humidifierInUse')}
            input={
              <InputHandler
                type="Radio"
                editing={true}
                name="humidifierInUse"
                formData={{
                  onChange,
                  document: {
                    humidifierInUse: formData.document.implementationMethods?.[index]?.humidifierInUse || '',
                  },
                }}
                options={['yes', 'no']}
                optionFormatter={(name: string | number): React.JSX.Element =>
                  fm(`treatment.opts.yesNoUnknown.${name}`)
                }
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.filtersGiven')}
            input={
              <InputHandler
                type="NumberField"
                editing={true}
                name="filtersGiven"
                min={0}
                placeholder={'general.amount'}
                formData={{
                  onChange,
                  document: {
                    filtersGiven: formData.document.implementationMethods?.[index]?.filtersGiven,
                  },
                }}
              />
            }
          />
        </React.Fragment>
      )}
      viewing={viewing}
      tableContent={(events: IPapImplementationMethod[]) => (
        <React.Fragment>
          {[
            'implementation',
            'maskBrand',
            'maskSize',
            'additionalInformation',
            'tubeType',
            'tubeName',
            'humidifierInUse',
            'filtersGiven',
          ]
            .filter((field) => (events.some((e) => e?.tubeType === 'heatTube') ? true : field !== 'tubeName'))
            .map((field, index, arr) => (
              <Container
                key={index}
                alignItems="baseline"
                style={{
                  marginBottom: index < arr.length - 1 ? '1rem' : '4.5rem',
                }}
              >
                <Item xs={3} style={{ paddingLeft: '2rem' }}>
                  {fm(`treatment.papTherapy.${field}${field === 'implementation' ? 'Method' : ''}`)}
                </Item>
                {events.map((e: IPapImplementationMethod, i: number, arr) => {
                  const name = field as keyof typeof e;
                  const value = e[field as keyof IPapImplementationMethod];

                  return (
                    <Item key={i} xs={index === 0 && i === arr.length - 1 ? 1 : 2}>
                      {(field !== 'tubeName' || e?.tubeType === 'heatTube') && (
                        <FormattedValue name={name} value={value} />
                      )}
                    </Item>
                  );
                })}
              </Container>
            ))}
        </React.Fragment>
      )}
      tableContentFilter={{
        eventFilter: (events: IPapImplementationMethod[]) => events.slice().reverse(),
      }}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IPAPTherapy>;
  viewing: boolean;
}

export default ImplementationMethods;
