import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import Form from './Form';

import DocumentCreationButton from '../../../components/DocumentCreationButton';
import { FormContextProvider, HistoryContextProvider } from '../../../containers/FormContextHandler';
import ChecklistHistory from './HistoryRowData';

const Checklist = ({ documents }: IOwnProps): React.JSX.Element => (
  <FormEditingHandler name="checklist" documents={documents}>
    {(editing, startEdit, formData, view): React.JSX.Element => (
      <DocumentWrapper>
        <DocumentHeader
          headerId={'checklist.title'}
          infoText={'checklist.infoText'}
          editing={editing || view?.viewing}
          editButtons={
            <div>
              <DocumentCreationButton name="checklist" text={'general.new'} onClick={startEdit({})} />
            </div>
          }
        />
        {!view?.viewing && !editing && documents ? (
          <HistoryContextProvider context={{ documents, startEdit, view }}>
            <ChecklistHistory />
          </HistoryContextProvider>
        ) : undefined}
        {view?.viewing || editing ? (
          <FormContextProvider context={{ documents: documents || [], formData, editing, view }}>
            <Form />
          </FormContextProvider>
        ) : undefined}
      </DocumentWrapper>
    )}
  </FormEditingHandler>
);

interface IOwnProps {
  documents?: ISRChecklist[];
}

export default Checklist;
