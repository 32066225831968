import * as React from 'react';
import { TDocument } from '../../utils';
import GAF from './GAF';
import MADRS from './MADRS';

const InterviewSurveysForm = ({ document }: IOwnProps): React.JSX.Element | null =>
  document._type === 'madrs' ? <MADRS /> : document._type === 'gaf' ? <GAF /> : null;

interface IOwnProps {
  document: TDocument;
}

export default InterviewSurveysForm;
