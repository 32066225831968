import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';

const EchocardiographyForm = ({ formData, disabledFields = [] }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!disabledFields.includes('date')}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="imaging.lvef">
      <InputHandler
        type="NumberField"
        editing={true}
        name="lvef"
        formData={formData}
        placeholder="imaging.lvefPlaceholder"
        width={9}
        height={3}
        maxLength={8}
        min={0}
        max={999}
        precision={2}
      />
    </FormRow>
    <FormRow title="imaging.fs">
      <InputHandler
        type="NumberField"
        editing={true}
        name="fs"
        formData={formData}
        placeholder="imaging.lvefPlaceholder"
        width={9}
        height={3}
        maxLength={8}
        min={0}
        max={999}
        precision={2}
      />
    </FormRow>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IEchocardiography>;
  disabledFields?: string[];
}

export default EchocardiographyForm;
