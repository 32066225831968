import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import OptionChooser from 'Components/OptionChooser';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { des, IDES, isLocaleKey } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from 'Routes/MyService/Document';
import colors from '../../../../../config/theme/colors';

export const Des = (): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing, setViewingObj, setEditingObj } = myServContext;

  const desData = (editing?.data || viewing?.data) as IDES & IControlProps;
  const desLocales = des.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    let value = Object.values(values)[0];

    if (field === 'dyspneaIntensity' && typeof value === 'number') {
      value = {
        1: 'canWalkAtOwnPaceRegardlessOfDistance',
        2: 'dyspneaWhenWalkingAtOwnPaceOver100Meters',
        3: 'dyspneaWhenWalkingAtOwnPaceAtHomeOrInTheWard',
        4: 'dyspneaWhenMovingInBedOrGettingUp',
        5: 'dyspneaWhenTalking',
        6: 'dyspneaAtRest',
      }[value];
    }

    setEditingData?.({
      ...desData,
      [field]: value,
    });
  };

  const fmDES = (id: string) => desLocales[useLocale][id];

  return (
    <>
      {!viewing ? (
        <div style={{ width: '80%', color: colors.tertiaryText, marginBottom: '2rem' }}>
          <p>{desLocales[useLocale]['surveyInfo']}</p>
          <p>{desLocales[useLocale]['surveyFillingInfo']}</p>
        </div>
      ) : null}
      <BlockWrapper
        title={viewing ? formatPartialDate(desData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'des', data: desData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing ? (
          <React.Fragment>
            <FormRow title={'general.date'} headerWidth={2}>
              <InputHandler
                name="date"
                type="PartialDate"
                dateDefault="now"
                formData={{
                  document: { date: desData?.date },
                  onChange: onChangeSurveyForm,
                }}
                editing={!!editing}
              />
            </FormRow>
            <OptionChooser
              name="dyspneaIntensity"
              formData={{
                document: {
                  dyspneaIntensity:
                    typeof desData?.dyspneaIntensity === 'string'
                      ? {
                          canWalkAtOwnPaceRegardlessOfDistance: 1,
                          dyspneaWhenWalkingAtOwnPaceOver100Meters: 2,
                          dyspneaWhenWalkingAtOwnPaceAtHomeOrInTheWard: 3,
                          dyspneaWhenMovingInBedOrGettingUp: 4,
                          dyspneaWhenTalking: 5,
                          dyspneaAtRest: 6,
                        }[desData?.dyspneaIntensity]
                      : undefined,
                },
                onChange: onChangeSurveyForm,
              }}
              opts={[1, 2, 3, 4, 5, 6]}
              optionFormatter={(name: string | number) =>
                typeof name === 'number' ? (
                  <span>
                    {fmDES(
                      `opts.${
                        {
                          1: 'canWalkAtOwnPaceRegardlessOfDistance',
                          2: 'dyspneaWhenWalkingAtOwnPaceOver100Meters',
                          3: 'dyspneaWhenWalkingAtOwnPaceAtHomeOrInTheWard',
                          4: 'dyspneaWhenMovingInBedOrGettingUp',
                          5: 'dyspneaWhenTalking',
                          6: 'dyspneaAtRest',
                        }[name as number]
                      }`,
                    )}
                  </span>
                ) : (
                  <span />
                )
              }
              viewing={!!viewing}
              horizontal
              minHeight={15}
            />
          </React.Fragment>
        ) : desData?.dyspneaIntensity ? (
          <span style={{ fontWeight: 600 }}>{fmDES(`opts.${desData.dyspneaIntensity}`)}</span>
        ) : null}
      </BlockWrapper>
    </>
  );
};
