import DocumentCreationButton from 'Components/DocumentCreationButton';
import { Item } from 'Components/Grid';
import HistoryRowControls from 'Components/HistoryRowControls';
import HistoryRowListing from 'Components/HistoryRowListing';
import HistorySection from 'Components/HistorySection';
import PlatformConditional from 'Components/PlatformConditional';
import { diagnosticExamCodes } from 'Routes/ClpAndCnpTests/utils';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';

const HistoryHeaders = ({ fm }: { fm: (id: string) => string }): React.JSX.Element => {
  return (
    <>
      <Item xs={2}>{fm('general.date')}</Item>
      <Item xs={2}>{fm('clpAndCnpTests.mgravis.findingType')}</Item>
      <Item xs={3}>{fm('clpAndCnpTests.mgravis.abnormalDetails')}</Item>
      <Item xs={2}>{fm('clpAndCnpTests.mgravis.supportDiagnosis')}</Item>
      <Item xs={3} />
    </>
  );
};

const HistoryRow = ({
  d,
  fm,
  startEdit,
  setDiagnosticExamCode,
  code,
}: {
  d: IDiagnosticExamination;
  fm: (id: string) => string;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  setDiagnosticExamCode?: React.Dispatch<React.SetStateAction<TDiagnosticExamKeys | undefined>>;
  code?: TDiagnosticExamKeys;
}): React.JSX.Element => {
  return (
    <>
      <Item xs={2} style={{ fontWeight: 600 }}>
        {formatPartialDate(d?.date)}
      </Item>
      <Item xs={2} style={{ fontWeight: 600 }}>
        {d?.finding?.type ? fm(`clpAndCnpTests.mgravis.opts.${d.finding.type}`) : ''}
      </Item>
      <Item xs={3} style={{ fontWeight: 600 }}>
        {d?.finding?.abnormalDetails}
      </Item>
      <Item xs={2}>{d?.finding?.type === 'abnormal' ? fm(`general.${!!d?.finding?.supportDiagnosis}`) : ''}</Item>
      <Item xs={3}>
        <HistoryRowControls
          document={d}
          startEdit={(d) => (e) => {
            setDiagnosticExamCode?.(code);
            startEdit(d)(e);
          }}
        />
      </Item>
    </>
  );
};

const DiagnosticExaminationHistory = ({
  documents,
  startEdit,
  setDiagnosticExamCode,
}: IOwnProps): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const enmgDocs = documents.filter((d) => d?.code?.code === diagnosticExamCodes?.['enmg']?.code);
  const repeatedNerveStimDocs = documents.filter(
    (d) => d?.code?.code === diagnosticExamCodes?.['repeatedNerveStimulation']?.code,
  );
  const singleFiberEMGDocs = documents.filter((d) => d?.code?.code === diagnosticExamCodes?.['singleFiberEMG']?.code);

  return (
    <PlatformConditional platform={'mgravis'}>
      <HistorySection
        headerText={fm(`clpAndCnpTests.mgravis.${diagnosticExamCodes.enmg?.code}`)}
        newButton={
          <DocumentCreationButton
            name="diagnosticExamination"
            text={`clpAndCnpTests.mgravis.new${diagnosticExamCodes.enmg?.code}`}
            onClick={(e) => {
              setDiagnosticExamCode?.('enmg');
              startEdit({}, 'diagnosticExamination')(e);
            }}
            width={18}
            height={3}
            fontSize={14}
            alternate={true}
          />
        }
      >
        <HistoryRowListing
          documents={enmgDocs}
          headers={<HistoryHeaders fm={fm} />}
          contentFormat={(d: IDiagnosticExamination): React.JSX.Element => (
            <HistoryRow
              d={d}
              fm={fm}
              startEdit={startEdit}
              setDiagnosticExamCode={setDiagnosticExamCode}
              code={'enmg'}
            />
          )}
        />
      </HistorySection>
      <HistorySection
        headerText={fm(`clpAndCnpTests.mgravis.${diagnosticExamCodes.repeatedNerveStimulation?.code}`)}
        newButton={
          <DocumentCreationButton
            name="diagnosticExamination"
            text={`clpAndCnpTests.mgravis.new${diagnosticExamCodes.repeatedNerveStimulation?.code}`}
            onClick={(e) => {
              setDiagnosticExamCode?.('repeatedNerveStimulation');
              startEdit({}, 'diagnosticExamination')(e);
            }}
            width={18}
            height={3}
            fontSize={14}
            alternate={true}
          />
        }
      >
        <HistoryRowListing
          documents={repeatedNerveStimDocs}
          headers={<HistoryHeaders fm={fm} />}
          contentFormat={(d: IDiagnosticExamination): React.JSX.Element => (
            <HistoryRow
              d={d}
              fm={fm}
              startEdit={startEdit}
              setDiagnosticExamCode={setDiagnosticExamCode}
              code={'repeatedNerveStimulation'}
            />
          )}
        />
      </HistorySection>
      <HistorySection
        headerText={fm(`clpAndCnpTests.mgravis.${diagnosticExamCodes.singleFiberEMG?.code}`)}
        newButton={
          <DocumentCreationButton
            name="diagnosticExamination"
            text={`clpAndCnpTests.mgravis.new${diagnosticExamCodes.singleFiberEMG?.code}`}
            onClick={(e) => {
              setDiagnosticExamCode?.('singleFiberEMG');
              startEdit({}, 'diagnosticExamination')(e);
            }}
            width={18}
            height={3}
            fontSize={14}
            alternate={true}
          />
        }
      >
        <HistoryRowListing
          documents={singleFiberEMGDocs}
          headers={<HistoryHeaders fm={fm} />}
          contentFormat={(d: IDiagnosticExamination): React.JSX.Element => (
            <HistoryRow
              d={d}
              fm={fm}
              startEdit={startEdit}
              setDiagnosticExamCode={setDiagnosticExamCode}
              code={'singleFiberEMG'}
            />
          )}
        />
      </HistorySection>
    </PlatformConditional>
  );
};

interface IOwnProps {
  documents: IDiagnosticExamination[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  setDiagnosticExamCode?: React.Dispatch<React.SetStateAction<TDiagnosticExamKeys | undefined>>;
}

export default DiagnosticExaminationHistory;
