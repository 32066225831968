import * as React from 'react';
import { fm } from 'Components/FormatMessage';
import { nowPartialDate, nowTime } from 'neuro-utils';

import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import { calculateBMI, codes, fieldNameToCodeString, getFields, loincCodeToFieldName } from 'Routes/Background/utils';
import { useAppSelector as useSelector } from 'Store/index';
import { Container, Item } from 'Components/Grid';

const optionFormatter = (opt: number | string) => {
  return fm(`background.${opt}`);
};

const getMin = (code: string | null) => {
  if (!code) return undefined;
  const field = loincCodeToFieldName(code)?.name;
  switch (field) {
    default:
      return 0;
  }
};
const getMax = (code: string | null) => {
  if (!code) return undefined;
  const field = loincCodeToFieldName(code)?.name;
  switch (field) {
    case 'gaf':
      return 100;
    case 'bmi':
      return 80;
    case 'madrs':
      return 60;
    case 'ybopa':
    case 'ybopt':
      return 20;
    case 'ybocs':
      return 40;
    default:
      return undefined;
  }
};
const getPrecision = (code: string | null) => {
  if (!code) return undefined;
  const field = loincCodeToFieldName(code)?.name;
  switch (field) {
    default:
      return 2;
  }
};

const MeasurementFormMultiple = ({ formData }: IOwnProps): React.JSX.Element => {
  const platform = useSelector((s: IState) => s.session.platforms?.selected);

  const valueOnChange = (code: string | null) => (values: TOnChangeValues) => {
    if (!code) return;

    const value = values['value'] ? `${values['value']}` : undefined;
    let formDataValues = [...(formData.document.values || [])];
    const valueIndex = formDataValues.findIndex((vl) => vl.code === code);

    if (!value && valueIndex !== -1) {
      formDataValues = formDataValues.filter((fdv) => fdv.code !== code);
    } else if (valueIndex !== -1) {
      formDataValues[valueIndex] = { value, code };
    } else {
      formDataValues = [...formDataValues, { value, code }];
    }
    formData.onChange({ values: formDataValues });
  };

  const getValueFromFormData = (code: string | null) => {
    return formData.document.values?.find((vl) => vl.code === code)?.value;
  };

  React.useEffect(() => {
    // Set date and time automatically when opening the form
    // Do it this way as the inputhandler time/date defaults wont work properly with react component state
    if (Object.keys(formData.document).length === 0) {
      const dateNow = nowPartialDate();
      const timeNow = nowTime();
      formData.onChange({ date: dateNow, time: timeNow });
    }
  }, []);

  if (!platform) return <></>;
  return (
    <FormSection>
      <FormRow title="general.date">
        <InputHandler type="PartialDate" name="date" editing={true} formData={formData} isNotCancellable={true} />
      </FormRow>
      <FormRow title="general.time">
        <InputHandler type="TimePicker" name="time" editing={true} formData={formData} />
      </FormRow>
      <FormRow title="background.values">
        {getFields(platform).map((o) => {
          const code = fieldNameToCodeString(o);
          const weight = getValueFromFormData(fieldNameToCodeString('weight'));
          const height = getValueFromFormData(fieldNameToCodeString('height'));
          const bmi = height && weight ? calculateBMI(parseFloat(height), parseFloat(weight)) : null;
          return (
            <Container key={o} style={{ marginBottom: '2rem' }}>
              <Item xs={7} md={5}>
                <span>{optionFormatter(codes[code ?? ''].name)}</span>
                {codes[code ?? ''].unit && <span> ({codes[code ?? ''].unit})</span>}
              </Item>
              <Item xs={true}>
                {o === 'bmi' && bmi && Number.isFinite(bmi) && !Number.isNaN(bmi) ? (
                  <div style={{ fontWeight: 600 }}>{bmi}</div>
                ) : (
                  <InputHandler
                    type="NumberField"
                    name="value"
                    editing={true}
                    formData={{ document: { value: getValueFromFormData(code) }, onChange: valueOnChange(code) }}
                    placeholder="background.enterValue"
                    min={getMin(code)}
                    max={getMax(code)}
                    precision={getPrecision(code)}
                    width={15}
                    saveAsString // Save the value as string to comply with the interface
                  />
                )}
              </Item>
            </Container>
          );
        })}
      </FormRow>
    </FormSection>
  );
};

export interface INewMeasurements {
  date?: IMeasurement['date'];
  time?: IMeasurement['time'];
  values?: {
    code: IMeasurement['code'];
    value: IMeasurement['value'];
  }[];
}

interface IOwnProps {
  formData: { onChange: (values: TOnChangeValues) => void; document: INewMeasurements };
}

export default MeasurementFormMultiple;
