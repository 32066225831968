import * as React from 'react';

import FormRow from '../../../../../../components/FormRow';
import FormSection from '../../../../../../components/FormSection';
import FormSectionBar from '../../../../../../components/FormSectionBar';
import colors from '../../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { fm } from 'Components/FormatMessage';
import {
  B1,
  B2,
  B3,
  possiblePSPCBS,
  possiblePSPOM,
  possiblePSPPGF,
  possiblePSPRS,
  possiblePSPSL,
  probablePSPF,
  probablePSPP,
  probablePSPPGF,
  probablePSPRS,
  suggestivePSPCBS,
  suggestivePSPF,
  suggestivePSPOM,
  suggestivePSPP,
  suggestivePSPPI,
  suggestivePSPRS,
  suggestivePSPSL,
} from '../../../../utils/PSP';

const DiagnosticCertainty = ({ formData }: IFormContext<IPSP>): React.JSX.Element => {
  const [showFields, setShowFields] = React.useState<boolean>(true);

  React.useEffect(() => {
    setShowFields(
      B1(formData?.document) === 'yes' && B2(formData?.document) !== 'yes' && B3(formData?.document) !== 'yes',
    );
  }, [formData]);

  const MakeFormRow = ({
    title,
    description,
    content,
  }: {
    title: string;
    description?: React.JSX.Element;
    content: React.JSX.Element;
  }): React.JSX.Element => {
    return (
      <FormRow
        headerStyle={{ color: showFields ? colors.primaryText : colors.gray }}
        title={title}
        description={showFields ? description : ''}
      >
        {showFields ? content : ''}
      </FormRow>
    );
  };

  return (
    <React.Fragment>
      <FormSectionBar header={'diagnosis.psp.diagnosticCertainty.title'} />

      <div style={{ margin: '1rem 0' }}>{fm('diagnosis.psp.diagnosticCertainty.note')}</div>
      <FormSection header="diagnosis.psp.diagnosticCertainty.probablePSP" style={{ border: 0 }}>
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.probablePSPRS"
          description={fm('diagnosis.psp.diagnosticCertainty.probablePSPRSInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${probablePSPRS(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.probablePSPPGF"
          description={fm('diagnosis.psp.diagnosticCertainty.probablePSPPGFInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${probablePSPPGF(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.probablePSPP"
          description={fm('diagnosis.psp.diagnosticCertainty.probablePSPPInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${probablePSPP(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.probablePSPF"
          description={fm('diagnosis.psp.diagnosticCertainty.probablePSPFInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${probablePSPF(formData?.document)}`)}</div>}
        />
      </FormSection>
      <FormSection header="diagnosis.psp.diagnosticCertainty.possiblePSP">
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.possiblePSPOM"
          description={fm('diagnosis.psp.diagnosticCertainty.possiblePSPOMInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${possiblePSPOM(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.possiblePSPRS"
          description={fm('diagnosis.psp.diagnosticCertainty.possiblePSPRSInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${possiblePSPRS(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.possiblePSPPGF"
          description={fm('diagnosis.psp.diagnosticCertainty.possiblePSPPGFInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${possiblePSPPGF(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.possiblePSPSL"
          description={fm('diagnosis.psp.diagnosticCertainty.possiblePSPSLInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${possiblePSPSL(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.possiblePSPCBS"
          description={fm('diagnosis.psp.diagnosticCertainty.possiblePSPCBSInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${possiblePSPCBS(formData?.document)}`)}</div>}
        />
      </FormSection>
      <FormSection header="diagnosis.psp.diagnosticCertainty.suggestivePSP">
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.suggestivePSPOM"
          description={fm('diagnosis.psp.diagnosticCertainty.suggestivePSPOMInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${suggestivePSPOM(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.suggestivePSPPI"
          description={fm('diagnosis.psp.diagnosticCertainty.suggestivePSPPIInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${suggestivePSPPI(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.suggestivePSPRS"
          description={fm('diagnosis.psp.diagnosticCertainty.suggestivePSPRSInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${suggestivePSPRS(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.suggestivePSPP"
          description={fm('diagnosis.psp.diagnosticCertainty.suggestivePSPPInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${suggestivePSPP(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.suggestivePSPSL"
          description={fm('diagnosis.psp.diagnosticCertainty.suggestivePSPSLInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${suggestivePSPSL(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.suggestivePSPF"
          description={fm('diagnosis.psp.diagnosticCertainty.suggestivePSPFInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${suggestivePSPF(formData?.document)}`)}</div>}
        />
        <MakeFormRow
          title="diagnosis.psp.diagnosticCertainty.suggestivePSPCBS"
          description={fm('diagnosis.psp.diagnosticCertainty.suggestivePSPCBSInfo')}
          content={<div style={{ fontWeight: 600 }}>{fm(`general.${suggestivePSPCBS(formData?.document)}`)}</div>}
        />
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(DiagnosticCertainty);
