import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import { formatPartialDate, formatTime } from 'neuro-utils';
import { getPatientAgeInMonthsWithOneDecimal } from '../../../../utility/patientInfo';
import { showPatientAgeInMonthsOnForm } from '../../utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const MWT = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'motor.MWT'} />}
      newButton={
        <DocumentCreationButton
          name="mwt"
          text={'motor.newMWT'}
          onClick={startEdit({}, 'mwt')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id={'general.date'} />
            </Item>
            <Item xs={2}>
              <FormattedMessage id="general.timeOfDay" />
            </Item>
            <Item xs={2}>
              <FormattedMessage id={'motor.distanceWithoutUnit'} />
            </Item>
            <Item xs={6}>
              <FormattedMessage id={'motor.additionalInformation'} />
            </Item>
          </>
        }
        contentFormat={(d: I6MWT): React.JSX.Element => (
          <>
            <Item xs={2} id={'mwtDate'}>
              {showPatientAgeInMonthsOnForm(d.date) ? (
                <FormattedMessage
                  id="motor.dateAndMonth"
                  values={{
                    date: formatPartialDate(d.date),
                    months: getPatientAgeInMonthsWithOneDecimal(d.date),
                  }}
                />
              ) : (
                formatPartialDate(d.date)
              )}
            </Item>
            <Item xs={2} id={'mwtTime'}>
              {d.time ? formatTime(d.time) : '-'}
            </Item>
            <Item xs={2} id={'mwtScore'}>
              {d?.meters || d?.meters === 0 ? (
                <FormattedMessage
                  id="motor.meter"
                  values={{
                    meter: d?.meters,
                  }}
                />
              ) : (
                ''
              )}
            </Item>
            <Item xs={6} id={'mwtComments'}>
              {d.comments}
            </Item>
          </>
        )}
        makeControls
        historyRowControls={(d: IControlProps) => (
          <div id={'mwtHistoryControl'}>
            <HistoryRowControls document={d} startEdit={startEdit} view={view} />
          </div>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: I6MWT[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default MWT;
