import * as React from 'react';
import { v4 as createID } from 'uuid';
import { indexOf } from 'ramda';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ActionButton from '../../../../../../../components/ActionButton';

import colors from '../../../../../../../config/theme/colors';

import { formatStrenghtString } from '../utils';

import Row from './Row';
import { FormattedMessage } from 'react-intl';
import { uniq } from 'Utility/ramdaReplacement';

const TableCellStyles = {
  head: {
    backgroundColor: colors.white,
    color: colors.primaryText,
    fontSize: 16,
  },
  body: {
    fontSize: 16,
    fontWeight: 600,
  },
};

const Program = ({ formData, editIndex, availableStrengths }: IProgramProps): React.JSX.Element => {
  const { document, onChange } = formData as IFormData<IMedication>;

  const getRegimens = React.useCallback((doc: IMedication) => (doc as IMedication).regimen?.map((r) => r), []);

  const getDosages = React.useCallback(
    (doc: IMedication) => (getRegimens(doc)?.[editIndex] as IRegimenBasics & IRegimenDefault).dosages ?? [],
    [editIndex, getRegimens],
  );

  const getStrengths = (): Array<number> => {
    const thisRegimen = getRegimens(document)?.[editIndex] || { strengths: undefined };
    return thisRegimen.strengths
      ? uniq(
          thisRegimen.strengths
            .map((d: IStrengths) => indexOf(d, availableStrengths))
            .sort((a: number, b: number) => a - b),
        )
      : [];
  };

  const addDosage = (): void => {
    const regimenArr = getRegimens(document);

    if (regimenArr) {
      // Add new dosage to old ones
      const dosageArr = [...getDosages(document), { id: createID() }];

      regimenArr[editIndex] = { ...regimenArr[editIndex], dosages: dosageArr as Array<IDosage> } as IRegimenBasics &
        IRegimenDefault;

      onChange?.({ regimen: regimenArr });
    }
  };

  return (
    <>
      <Paper square>
        <Table>
          <TableHead>
            <TableRow>
              <React.Fragment>
                <TableCell scope="col" style={{ width: '35%' }} sx={TableCellStyles}>
                  <FormattedMessage id="medication.doses.time" />
                </TableCell>
                {getStrengths().map((s: number) => (
                  <React.Fragment key={JSON.stringify(availableStrengths[s])}>
                    <TableCell scope="col" sx={TableCellStyles}>
                      {formatStrenghtString(availableStrengths[s])}
                    </TableCell>
                  </React.Fragment>
                ))}
                <TableCell sx={TableCellStyles} />
              </React.Fragment>
            </TableRow>
          </TableHead>

          <TableBody>
            {getDosages(document).map((d) => (
              <React.Fragment key={d.id}>
                <Row formData={formData} regimenIndex={editIndex} availableStrengths={availableStrengths} dosage={d} />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <div style={{ marginTop: '2rem' }}>
        <ActionButton text={'medication.doses.addDosage'} onClick={addDosage} width={16} height={3} fontSize={16} />
      </div>
    </>
  );
};

interface IProgramProps {
  formData: IFormData<IMedication>;
  editIndex: number;
  availableStrengths: Array<IStrengths>;
}

export default Program;
