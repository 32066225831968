import * as React from 'react';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import Form from './Form';
import DocumentWrapper from '../../../components/DocumentWrapper';
import DocumentHeader from '../../../components/DocumentHeader';
import DiagnosticTestsHistory from './History';

const getHeaderId = (examKey?: string, editing?: string) => {
  return `diagnosticTests.${editing && examKey ? `${examKey}.` : ''}title`;
};

const DiagnosticTests = ({ documents }: IOwnProps): React.JSX.Element => {
  const [diagnosticExamKey, setDiagnosticExamKey] = React.useState<TDiagnosticExamKeys | undefined>(undefined);

  return (
    <FormEditingHandler name="diagnosticTests" documents={documents}>
      {(editing, startEdit, formData, view): React.JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="diagnosticTests"
            headerId={getHeaderId(diagnosticExamKey, editing)}
            editing={editing}
            editButtons={<div />}
          />
          {editing && diagnosticExamKey ? (
            <Form formData={formData} editing={editing} diagnosticExamKey={diagnosticExamKey} />
          ) : null}
          {!editing && !view?.viewing ? (
            <DiagnosticTestsHistory
              documents={documents}
              startEdit={startEdit}
              view={view}
              setDiagnosticExamKey={setDiagnosticExamKey}
            />
          ) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<IDiagnosticExamination>;
}

export default DiagnosticTests;
