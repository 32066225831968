import { calculateDaysDifference } from 'neuro-utils';
import * as React from 'react';
import FormRow from '../../../../../../components/FormRow';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';

const FirstVisitRespiratoryForm = ({
  editing,
  formData,
  fm,
}: IFormContext<IRespiratoryFirstVisit>): React.JSX.Element => {
  const document = formData?.document;

  return (
    <React.Fragment>
      <FormRow title="diagnosis.sleepApnea.respiratoryFirstVisit.referralOrAcute">
        <InputHandler
          type="Radio"
          editing={!!editing}
          name="referralOrAcute"
          formData={formData}
          options={['referral', 'acute']}
          optionFormatter={(s: string | number): string => fm('diagnosis.sleepApnea.respiratoryFirstVisit.opts.' + s)}
          dependentFieldsList={(): string[] => ['dateOfReferral', 'dateOfFirstVisit']}
        />
      </FormRow>
      {document?.referralOrAcute === 'referral' && (
        <>
          <FormRow title="diagnosis.sleepApnea.respiratoryFirstVisit.dateOfReferral">
            <InputHandler type="PartialDate" editing={!!editing} name="dateOfReferral" formData={formData} />
          </FormRow>
          <FormRow title="diagnosis.sleepApnea.respiratoryFirstVisit.dateOfFirstVisit">
            <InputHandler type="PartialDate" editing={!!editing} name="dateOfFirstVisit" formData={formData} />
          </FormRow>
          <FormRow title="diagnosis.sleepApnea.respiratoryFirstVisit.delayFromArrivalToFirstVisit">
            {document.dateOfReferral && document.dateOfFirstVisit
              ? calculateDaysDifference(document.dateOfReferral, document.dateOfFirstVisit) +
                ' ' +
                fm('diagnosis.sleepApnea.days')
              : '-'}
          </FormRow>
        </>
      )}
    </React.Fragment>
  );
};

export default withFormContext(FirstVisitRespiratoryForm);
