import * as React from 'react';

const svgImage = (): React.JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(0.441517,0,0,0.441517,-122.772,-52.9573)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-102.718,54.5385)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.412838,0.156531,-0.156531,0.412838,73.1914,-96.267)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.320159,0.304031,-0.304031,0.320159,277.188,-104.354)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-96.7195,-52.8086)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-68.7581,7.82414)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-18.6903,-22.9018)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-147.949,-53.1511)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-170.68,62.5635)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-187.533,3.85298)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-230.803,34.0153)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.372567,-0.236919,0.236919,0.372567,-358.702,302.22)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-143.523,54.6985)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.320849,0,0,0.473568,-33.0597,-122.851)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-128.953,-117.726)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.441517,0,0,0.36004,-128.534,-155.625)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.441517,0,0,0.441517,-131.901,-90.8172)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(0.180591,0,0,0.180591,69.5019,27.4716)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.180591,0,0,0.180591,82.8506,27.4716)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.75376,-0.362184,0.167529,0.348654,-396.24,217.036)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.547581,0.519997,-0.0718599,0.0756718,41.0175,-149.759)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.448649,-1.22418,0.0979825,0.0359095,-359.846,1146.71)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.750691,0.560177,-0.231337,0.310014,-215.322,-421.436)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.170476,0.56356,-0.523787,0.158444,425.706,-237.433)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.900853,0.238748,-0.173576,0.654946,-312.482,-375.413)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.693882,-0.622145,0.518436,0.578214,-753.351,256.963)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.675792,0.641749,-0.37069,0.390354,175.592,-447.611)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.492125,-0.323226,0.36361,0.553611,-287.115,12.7486)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.786778,-0.499517,0.347547,0.547414,-487.2,170.531)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
  </svg>
);

export default svgImage;
