import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, Item } from '../../../../components/Grid';
import HistoryRow from '../../../../components/HistoryRow';
import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistorySection from '../../../../components/HistorySection';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const Ventilation = ({ documents, startEdit }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'pulmonary.ventilations'} />}
      newButton={
        <DocumentCreationButton
          name="ventilationAid"
          text={'pulmonary.newVentilation'}
          onClick={startEdit({}, 'ventilationAid')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
      hasHistoryRows={true}
    >
      {documents &&
        documents
          .sort((n1, n2) => n1._cdate - n2._cdate)
          .sort((n1, n2) => sortPartialDate(n1.startDate, n2.startDate))
          .reverse()
          .map((d: IVentilation) => (
            <HistoryRow
              headerText={
                <div id={'ventilationDate'}>
                  {d.startDate
                    ? formatPartialDate(d.startDate) + `${d.endDate ? ' - ' + formatPartialDate(d.endDate) : ''}`
                    : undefined}
                </div>
              }
              key={d._id}
              active={!d.endDate || !d.endDate[0]}
              rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
              controlsMargin={false}
            >
              <React.Fragment>
                <Container alignItems="baseline" key={d._id}>
                  <Item xs={2} id={'ventilationType'}>
                    <HistoryRowBasicItem
                      header={<FormattedMessage id={'pulmonary.ventilationType'} />}
                      value={
                        <span style={{ fontWeight: 600 }}>
                          {d?.type ? <FormattedMessage id={`pulmonary.opts.${d.type}`} /> : '-'}
                        </span>
                      }
                    />
                  </Item>
                  <Item xs={4} id={'ventilationFrequency'}>
                    <HistoryRowBasicItem
                      header={<FormattedMessage id={'pulmonary.ventilationFrequency'} />}
                      value={
                        <span style={{ fontWeight: 600 }}>
                          {d?.frequency ? <FormattedMessage id={`pulmonary.opts.${d.frequency}`} /> : '-'}
                          {d?.frequency === 'partTime' && (
                            <span>
                              :{' '}
                              <FormattedMessage
                                id="pulmonary.hoursPerDayLowercase"
                                values={{ value: d?.hoursPerDay ?? '-' }}
                              />
                            </span>
                          )}
                        </span>
                      }
                    />
                  </Item>
                  <Item xs={3} />
                </Container>
              </React.Fragment>
            </HistoryRow>
          ))}
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IVentilation[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default Ventilation;
