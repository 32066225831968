/**
 * Inject one, or array of children with documents loaded from redux state
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { getEnabledDocuments, getRelevantDocuments } from '../../utility/randomUtil';

type Props = IStateFromProps & IOwnProps;

class DocumentLoader extends React.Component<Props> {
  public render(): React.JSX.Element | React.JSX.Element[] {
    if (Array.isArray(this.props.children)) {
      return this.props.children.map((child) =>
        React.createElement(child, { documents: this.props.documents, name: this.props.name }, null),
      );
    } else {
      return React.createElement(this.props.children, { documents: this.props.documents, name: this.props.name });
    }
  }
}

interface IStateFromProps {
  documents: TAnyObject[];
}

const mapStateToProps = (state: IState, props: IOwnProps): IStateFromProps => {
  const enabledName = getEnabledDocuments(state.session).find(
    (d: { name: string; subTypes?: string[]; secondaryDocs?: string[] }) => d.name === props.name,
  );
  const docArray = getRelevantDocuments(state.documents.sortedAndMergedDocuments ?? [], enabledName, props.name);
  return {
    documents: docArray,
  };
};

interface IOwnProps {
  name: string;
  children:
    | Array<({ documents }: { documents: any; name: string }) => React.JSX.Element>
    | (({ documents }: { documents: any; name: string }) => React.JSX.Element);
}

export default connect(mapStateToProps)(DocumentLoader);
