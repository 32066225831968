import * as React from 'react';
import EventStepper from '../../../../../components/EventStepper';
import { fm } from 'Components/FormatMessage';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import { formatPartialDate } from 'neuro-utils';
import { capitalize } from '../../../../../utility/string';
import { ModeActivationDetails } from '../../../components';
import { getLatestGenerator } from '../../../utils';
import { generatorsWithAutoStimNightAndScheduledSettings, generatorsWithAutoStimSettings } from '../../config';

const ModeActivationEvents = ({ formData, name }: IOwnProps): React.JSX.Element => {
  const d = (formData.document as { [key: string]: any })[`${name}s`];
  return (
    <EventStepper
      name={`${name}s`}
      formData={formData}
      stepLabelText={(d: IVNSModeActivationEvent): string => formatPartialDate(d.date)}
      stepContent={(d: IVNSModeActivationEvent): React.JSX.Element => <ModeActivationDetails modeActivation={d} />}
      addNewTextHeader={`vns.${name}s`}
      addNewTextButton={'vns.newDocument'}
      buttonDisabled={
        !Array.isArray(formData.document?.generators) || !(formData.document.generators?.length > 0)
          ? true
          : name === 'autoStimModeActivation' &&
            !generatorsWithAutoStimNightAndScheduledSettings
              .concat(generatorsWithAutoStimSettings)
              .includes(getLatestGenerator(formData.document)?.generator ?? '')
      }
      buttonDisabledMessage={
        !Array.isArray(formData.document?.generators) || !(formData.document.generators?.length > 0)
          ? fm('vns.addGeneratorFirst')
          : fm('vns.autoStimModeNotInUse')
      }
      latestEventTextHeader={`vns.latest${capitalize(name)}`}
      previousEventsTextHeader={''}
      editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => (
        <React.Fragment>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="PartialDate"
              editing={true}
              name="date"
              formData={{
                onChange,
                document: { date: d?.[index]?.date || '' },
              }}
              dateDefault={d?.length === 1 && formData.document.date ? formData.document.date : 'now'}
              isNotCancellable={true}
              dateHook={{
                dateHookFloor: formData.document.date,
              }}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <div style={{ color: colors.darkGray, marginBottom: '0.5rem' }}>{fm(`vns.${name}sPerDay`)}</div>
            <InputHandler
              type="NumberField"
              editing={true}
              name="activationsPerDay"
              formData={{
                onChange,
                document: { activationsPerDay: d?.[index]?.activationsPerDay || '' },
              }}
              placeholder="vns.activations"
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="TextArea"
              editing={true}
              name="additionalInformation"
              formData={{
                onChange,
                document: {
                  additionalInformation: d?.[index]?.additionalInformation || '',
                },
              }}
              placeholder="vns.additionalInformation"
            />
          </div>
        </React.Fragment>
      )}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IVNS>;
  name: string;
}

export default ModeActivationEvents;
