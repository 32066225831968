import * as React from 'react';
import EventStepper from '../../../../../components/EventStepper';
import { fm } from 'Components/FormatMessage';
import InputHandler from '../../../../../components/InputHandler';
import { formatPartialDate } from 'neuro-utils';
import { AdverseEffectDetails } from '../../../components';

const AdverseEffectEvents = ({ formData, disabled }: IOwnProps): React.JSX.Element => {
  return (
    <EventStepper
      name="adverseEffects"
      formData={formData}
      stepLabelText={(d: IVNSAdverseEffectEvent): string => formatPartialDate(d.date)}
      stepContent={(d: IVNSAdverseEffectEvent): React.JSX.Element => <AdverseEffectDetails adverseEffect={d} />}
      addNewTextHeader="vns.newAdverseEffectEvent"
      addNewTextButton="vns.newAdverseEffectEvent"
      buttonDisabled={disabled}
      buttonDisabledMessage={fm('vns.addGeneratorFirst')}
      previousEventsTextHeader="vns.adverseEffectEvents"
      editingElements={(index: number, onChange: IFormData['onChange']): React.JSX.Element => (
        <React.Fragment>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="PartialDate"
              editing={true}
              name="date"
              formData={{
                onChange,
                document: { date: formData.document.adverseEffects?.[index]?.date || '' },
              }}
              dateDefault="now"
              isNotCancellable={true}
              dateHook={{
                dateHookFloor: formData.document.date,
              }}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="Checkbox"
              editing={true}
              name="effects"
              formData={{
                onChange,
                document: { effects: formData.document.adverseEffects?.[index]?.effects || '' },
              }}
              options={['hoarsenessOfTheVoice', 'cough', 'shortnessOfBreath', 'paresthesia', 'other']}
              optionFormatter={(name: string | number): React.JSX.Element => fm(`vns.opts.adverseEffect.${name}`)}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="TextArea"
              editing={true}
              name="additionalInformation"
              formData={{
                onChange,
                document: {
                  additionalInformation: formData.document.adverseEffects?.[index]?.additionalInformation || '',
                },
              }}
              placeholder="vns.adverseEffectEventAdditionalInformation"
            />
          </div>
        </React.Fragment>
      )}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IVNS>;
  disabled?: boolean;
}

export default AdverseEffectEvents;
