import * as React from 'react';

import { TabContentContext } from 'Components/TabContent';
import { Container, Item } from 'Components/Grid';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { DoctorsOrdersContext } from 'Routes/DoctorsOrders/Document';

const RemoveButton = ({ remove, text = 'general.delete' }: IRemoveButton): React.JSX.Element => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);

  const tabContentContext = React.useContext(TabContentContext);
  const doctorsOrdersContext = React.useContext(DoctorsOrdersContext);

  const { selected, changeSelected } = tabContentContext;
  const { fm } = doctorsOrdersContext;

  const openDeleteDialog = () => (): void => {
    setDeleteDialogOpen(true);
  };
  const deleteCancelCallback = (): void => {
    setDeleteDialogOpen(false);
  };
  const deleteConfirmCallback = (): void => {
    remove(selected, changeSelected)();
    setDeleteDialogOpen(false);
  };

  return (
    <React.Fragment>
      <ActionButtonRounded
        text={text}
        onClick={openDeleteDialog()}
        width={7}
        height={3.5}
        fontSize={16}
        colorScheme="error"
        uppercase={false}
      />
      <ConfirmationDialog
        open={deleteDialogOpen}
        text={fm('general.reallyWantToDelete')}
        confirm={{ callback: deleteConfirmCallback }}
        cancel={{ callback: deleteCancelCallback }}
      />
    </React.Fragment>
  );
};

type TChangeFunction = (i: number) => () => void;
type TRemoveFunction = (i: number, change?: TChangeFunction) => () => void;

interface IRemoveButton {
  remove: TRemoveFunction;
  text?: string;
}

const CancelButton = ({ cancel, text = 'general.cancel' }: ICancelButton): React.JSX.Element => {
  const tabContentContext = React.useContext(TabContentContext);

  const { changeSelected } = tabContentContext;

  return (
    <React.Fragment>
      <ActionButtonRounded
        text={text}
        onClick={() => cancel(changeSelected)()}
        width={7}
        height={3.5}
        fontSize={16}
        colorScheme="cancel"
        uppercase={false}
      />
    </React.Fragment>
  );
};

const AddButton = ({ add, disabled = false, disabledTooltip, text = 'general.add' }: IAddButton): React.JSX.Element => {
  const tabContentContext = React.useContext(TabContentContext);

  const { changeSelected } = tabContentContext;

  return (
    <React.Fragment>
      <ActionButtonRounded
        disabled={disabled}
        disabledTooltip={disabledTooltip}
        text={text}
        onClick={() => add(changeSelected)()}
        width={7}
        height={3.5}
        fontSize={16}
        colorScheme="default"
        filled
        uppercase={false}
      />
    </React.Fragment>
  );
};

type TCancelFunction = (change?: TChangeFunction) => () => void;
type TAddFunction = (change?: TChangeFunction) => () => void;

interface IAddButton {
  add: TAddFunction;
  disabled?: boolean;
  disabledTooltip?: React.JSX.Element;
  text?: string;
}
interface ICancelButton {
  cancel: TCancelFunction;
  text?: string;
}

const ButtonRow = ({ ...props }: TButtonRow): React.JSX.Element => {
  const { type, text } = props;
  switch (type) {
    case 'add':
    case 'select': {
      const { add, cancel, disabled, disabledTooltip } = props;
      return (
        <Container style={{ justifyContent: type === 'add' ? 'space-between' : 'flex-end' }}>
          {type === 'add' && <Item xs={2}>{cancel && <CancelButton cancel={cancel} />}</Item>}
          <Item>
            <AddButton add={add} disabled={disabled} disabledTooltip={disabledTooltip} text={text} />
          </Item>
        </Container>
      );
    }
    case 'remove': {
      const { remove } = props;
      return (
        <Container style={{ justifyContent: 'flex-start' }}>
          <Item>
            <RemoveButton remove={remove} text={text} />
          </Item>
        </Container>
      );
    }
  }
};

interface IButtonRowBasics {
  text?: string;
}

type TButtonRow = IButtonRowBasics &
  (({ type: 'add' | 'select' } & IAddButton & ICancelButton) | ({ type: 'remove' } & IRemoveButton));

export default ButtonRow;
