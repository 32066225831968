import { FormattedMessage } from 'react-intl';
import * as React from 'react';

const instructions = (locale: string): React.JSX.Element => (
  <div style={{ fontStyle: 'italic' }}>
    <FormattedMessage id={'motor.labels.hfmsE.' + locale} />
  </div>
);

export const disabledSteps = [
  'plinthChairSitting',
  'longSitting',
  'oneHandToHeadInSitting',
  'twoHandsToHeadInSitting',
  'supineToSideLying',
  'rollsProneToSupineOverRight',
  'rollsProneToSupineOverLeft',
  'rollsSupineToProneOverRight',
  'rollsSupineToProneOverLeft',
  'sittingToLying',
  'propsOnForearms',
  'liftsHeadFromProne',
  'propOnExtendedArms',
  'lyingToSitting',
  'fourPointKneeling',
  'crawling',
  'liftsHeadFromSupine',
  'supportedStanding',
  'standUnsupported',
  'stepping',
  'rightHipFlexionInSupine',
  'leftHipFlexionInSupine',
  'highKneelingToRightHalfKneel',
  'highKneelingToLeftHalfKneel',
  'highKneelingToStandLeadingWithLeftLeg',
  'highKeelingToStandLeadingWithRightLeg',
  'standToSit',
  'squat',
  'jump12InchForward',
  'ascendsStairsWithRail',
  'descendsStairsWithRail',
  'ascendsStairsWithoutRail',
  'descendsStairsWithoutRail',
] as Array<string>;

export const getDisabledStepContent = (
  stepName: string,
): {
  opts: number[];
  optsLocale: string;
  info?: string;
  assets?: number[];
  height?: number;
  instruction?: React.JSX.Element;
  disabled?: boolean;
} => {
  switch (stepName) {
    case 'plinthChairSitting':
      return {
        opts: [0, 1, 2],
        optsLocale: 'plinthChairSitting',
        info: 'plinthChairSittingInfo',
        instruction: instructions('plinthChairSittingInstruction'),
      };
    case 'longSitting':
      return {
        opts: [0, 1, 2],
        optsLocale: 'longSitting',
        info: 'longSittingInfo',
        instruction: instructions('longSittingInstruction'),
      };
    case 'oneHandToHeadInSitting':
      return {
        opts: [0, 1, 2],
        optsLocale: 'oneHandToHeadInSitting',
        info: 'oneHandToHeadInSittingInfo',
        instruction: instructions('oneHandToHeadInSittingInstruction'),
      };
    case 'twoHandsToHeadInSitting':
      return {
        opts: [0, 1, 2],
        optsLocale: 'twoHandsToHeadInSitting',
        info: 'twoHandsToHeadInSittingInfo',
        instruction: instructions('twoHandsToHeadInSittingInstruction'),
      };
    case 'supineToSideLying':
      return {
        opts: [0, 1, 2],
        optsLocale: 'supineToSideLying',
        instruction: instructions('supineToSideLyingInstruction'),
      };
    case 'rollsProneToSupineOverRight':
      return {
        opts: [0, 1, 2],
        optsLocale: 'rollsProneToSupineOverRight',
        instruction: instructions('rollsProneToSupineOverRightInstruction'),
      };
    case 'rollsProneToSupineOverLeft':
      return {
        opts: [0, 1, 2],
        optsLocale: 'rollsProneToSupineOverLeft',
        instruction: instructions('rollsProneToSupineOverLeftInstruction'),
      };
    case 'rollsSupineToProneOverRight':
      return {
        opts: [0, 1, 2],
        optsLocale: 'rollsSupineToProneOverRight',
        instruction: instructions('rollsSupineToProneOverRightInstruction'),
      };
    case 'rollsSupineToProneOverLeft':
      return {
        opts: [0, 1, 2],
        optsLocale: 'rollsSupineToProneOverLeft',
        instruction: instructions('rollsSupineToProneOverLeftInstruction'),
      };
    case 'sittingToLying':
      return { opts: [0, 1, 2], optsLocale: 'sittingToLying', instruction: instructions('sittingToLyingInstruction') };
    case 'propsOnForearms':
      return {
        opts: [0, 1, 2],
        optsLocale: 'propsOnForearms',
        instruction: instructions('propsOnForearmsInstruction'),
      };
    case 'liftsHeadFromProne':
      return {
        opts: [0, 1, 2],
        optsLocale: 'liftsHeadFromProne',
        instruction: instructions('liftsHeadFromProneInstruction'),
      };
    case 'propOnExtendedArms':
      return {
        opts: [0, 1, 2],
        optsLocale: 'propOnExtendedArms',
        instruction: instructions('propOnExtendedArmsInstruction'),
      };
    case 'lyingToSitting':
      return { opts: [0, 1, 2], optsLocale: 'lyingToSitting', instruction: instructions('lyingToSittingInstruction') };
    case 'fourPointKneeling':
      return {
        opts: [0, 1, 2],
        optsLocale: 'fourPointKneeling',
        instruction: instructions('fourPointKneelingInstruction'),
      };
    case 'crawling':
      return { opts: [0, 1, 2], optsLocale: 'crawling', instruction: instructions('crawlingInstruction') };
    case 'liftsHeadFromSupine':
      return {
        opts: [0, 1, 2],
        optsLocale: 'liftsHeadFromSupine',
        instruction: instructions('liftsHeadFromSupineInstruction'),
      };
    case 'supportedStanding':
      return {
        opts: [0, 1, 2],
        optsLocale: 'supportedStanding',
        instruction: instructions('supportedStandingInstruction'),
        disabled: true,
      };
    case 'standUnsupported':
      return {
        opts: [0, 1, 2],
        optsLocale: 'standUnsupported',
        instruction: instructions('standUnsupportedInstruction'),
        disabled: true,
      };
    case 'stepping':
      return {
        opts: [0, 1, 2],
        optsLocale: 'stepping',
        instruction: instructions('steppingInstruction'),
        disabled: true,
      };
    case 'rightHipFlexionInSupine':
      return {
        opts: [0, 1, 2],
        optsLocale: 'rightHipFlexionInSupine',
        instruction: instructions('rightHipFlexionInSupineInstruction'),
      };
    case 'leftHipFlexionInSupine':
      return {
        opts: [0, 1, 2],
        optsLocale: 'leftHipFlexionInSupine',
        instruction: instructions('leftHipFlexionInSupineInstruction'),
      };
    case 'highKneelingToRightHalfKneel':
      return {
        opts: [0, 1, 2],
        optsLocale: 'highKneelingToRightHalfKneel',
        instruction: instructions('highKneelingToRightHalfKneelInstruction'),
      };
    case 'highKneelingToLeftHalfKneel':
      return {
        opts: [0, 1, 2],
        optsLocale: 'highKneelingToLeftHalfKneel',
        instruction: instructions('highKneelingToLeftHalfKneelInstruction'),
      };
    case 'highKneelingToStandLeadingWithLeftLeg':
      return {
        opts: [0, 1, 2],
        optsLocale: 'highKneelingToStandLeadingWithLeftLeg',
        instruction: instructions('highKneelingToStandLeadingWithLeftLegInstruction'),
        disabled: true,
      };
    case 'highKeelingToStandLeadingWithRightLeg':
      return {
        opts: [0, 1, 2],
        optsLocale: 'highKeelingToStandLeadingWithRightLeg',
        instruction: instructions('highKeelingToStandLeadingWithRightLegInstruction'),
        disabled: true,
      };
    case 'standToSit':
      return {
        opts: [0, 1, 2],
        optsLocale: 'standToSit',
        instruction: instructions('standToSitInstruction'),
        disabled: true,
      };
    case 'squat':
      return { opts: [0, 1, 2], optsLocale: 'squat', instruction: instructions('squatInstruction'), disabled: true };
    case 'jump12InchForward':
      return {
        opts: [0, 1, 2],
        optsLocale: 'jump12InchForward',
        instruction: instructions('jump12InchForwardInstruction'),
        disabled: true,
      };
    case 'ascendsStairsWithRail':
      return {
        opts: [0, 1, 2],
        optsLocale: 'ascendsStairsWithRail',
        instruction: instructions('ascendsStairsWithRailInstruction'),
        disabled: true,
      };
    case 'descendsStairsWithRail':
      return {
        opts: [0, 1, 2],
        optsLocale: 'descendsStairsWithRail',
        instruction: instructions('descendsStairsWithRailInstruction'),
        disabled: true,
      };
    case 'ascendsStairsWithoutRail':
      return {
        opts: [0, 1, 2],
        optsLocale: 'ascendsStairsWithoutRail',
        instruction: instructions('ascendsStairsWithoutRailInstruction'),
        disabled: true,
      };
    case 'descendsStairsWithoutRail':
      return {
        opts: [0, 1, 2],
        optsLocale: 'descendsStairsWithoutRail',
        instruction: instructions('descendsStairsWithoutRailInstruction'),
        disabled: true,
      };
    default:
      return { opts: [0], optsLocale: '' };
  }
};
