import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { statementStyle } from '../Components/Statement';
import { EditingLockedContentContext } from 'Containers/EditingLockedContentContext';

const Pelvis = ({ documents, startEdit }: IOwnProps): React.JSX.Element => {
  const showProcedureCodeHeader = documents.some((d) => d.procedureCode && d.procedureCode.length > 0);
  const showStatementHeader = documents.some((d) => d.statement && d.statement.length > 0);

  const showProcedureCode = (d: IMRI) => d.procedureCode && d.procedureCode.length > 0;
  const showStatement = (d: IMRI) => d.statement && d.statement.length > 0;

  const { openEditing } = React.useContext(EditingLockedContentContext);

  return (
    <HistorySection
      headerText={<FormattedMessage id={'imaging.pelvis'} />}
      newButton={
        <DocumentCreationButton
          name="pelvis"
          text={'imaging.newPelvicRontgen'}
          onClick={startEdit({} as IPelvicRontgen, 'pelvis')}
          width={15}
          height={3}
          fontSize={12}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            {showProcedureCodeHeader && (
              <Item xs={2}>
                <FormattedMessage id="imaging.procedureCode" />
              </Item>
            )}
            {showStatementHeader && (
              <Item xs={3}>
                <FormattedMessage id="imaging.statementText" />
              </Item>
            )}
            <Item xs={4}>
              <FormattedMessage id={'imaging.hipSubluxationLuxation'} />
            </Item>
            <Item xs={3}>
              <FormattedMessage id="imaging.migrationPercentage" />
            </Item>
          </>
        }
        contentFormat={(d: IPelvicRontgen): React.JSX.Element => (
          <>
            {showProcedureCodeHeader && <Item xs={2}>{showProcedureCode(d) ? d.procedureCode : ''}</Item>}
            {showStatementHeader && (
              <Item xs={3} style={statementStyle}>
                {showStatement(d) ? d.statement : ''}
              </Item>
            )}
            <Item xs={4}>
              {d.hipSubluxationLuxation ? <FormattedMessage id={`general.${d.hipSubluxationLuxation}`} /> : '-'}
            </Item>
            <Item xs={3}>
              {d.hipSubluxationLuxation === 'yes' && (d.migrationPercentage || d.migrationPercentage === 0)
                ? `${d.migrationPercentage} %`
                : '-'}
            </Item>
          </>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => (
          <HistoryRowControls
            document={d}
            startEdit={
              d._lockedFor && d._source !== null ? (doc) => () => openEditing(doc, 'imaging.pelvis') : startEdit
            }
          />
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IPelvicRontgen[];
  startEdit: (document: IPelvicRontgen, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default Pelvis;
