import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import InfoPopover from '../../../../components/InfoPopover';
import { formatPartialDate } from 'neuro-utils';
import { countFunctionalAssessmentScore, isFunctionalAssessmentComplete } from '../../utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const UHDRSFunctionalAssessment = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="functionalPerformance.uhdrsFunctionalAssessment" />}
    newButton={
      <DocumentCreationButton
        name="uhdrsFunctionalAssessment"
        text="functionalPerformance.newUhdrsFunctionalAssessment"
        onClick={startEdit({}, 'uhdrsFunctionalAssessment')}
        width={20}
        height={3}
        fontSize={14}
        alternate={true}
      />
    }
  >
    <HistoryRowListing
      documents={documents}
      headers={
        <>
          <Item xs={2}>
            <FormattedMessage id="general.date" />
          </Item>
          <Item xs={2}>
            <FormattedMessage id="functionalPerformance.labels.uhdrsFunctionalAssessment.score" />
          </Item>
          <Item xs={8}>&nbsp;</Item>
        </>
      }
      contentFormat={(d: IUHDRSFunctionalAssessment): React.JSX.Element => (
        <>
          <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
          <Item xs={2}>
            {isFunctionalAssessmentComplete(d) ? (
              countFunctionalAssessmentScore(d)
            ) : (
              <InfoPopover text="general.notFilled" color="primary" />
            )}
          </Item>
          <Item xs={5}>&nbsp;</Item>
          <Item xs={3}>
            <HistoryRowControls document={d} startEdit={startEdit} view={view} />
          </Item>
        </>
      )}
    />
  </HistorySection>
);

interface IOwnProps {
  documents: Array<IUHDRSFunctionalAssessment>;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default UHDRSFunctionalAssessment;
