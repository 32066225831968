import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../../components/FormRow';
import FormSection from '../../../../../../components/FormSection';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { optionFormatter } from '../../../../utils/functions';

const ParkinsonSymptomForm = ({ editing, formData }: IFormContext): React.JSX.Element => {
  return (
    <React.Fragment>
      <FormSection>
        <div style={{ marginBottom: '5rem' }} />
        <FormRow title="diagnosis.symptomOnsetDate">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="symptomOnsetDate"
            formData={formData}
            dateDefault="now"
          />
        </FormRow>
        <FormRow title="diagnosis.parkinsonEarlySymptom.title">
          <InputHandler
            type="Checkbox"
            editing={!!editing}
            name="parkinsonEarlySymptom"
            formData={formData}
            options={[
              'motorSymptom',
              'cognitivePsychiatricSymptom',
              'symptomOfAutonomicNervousSystem',
              'otherNonMotorSymptom',
            ]}
            optionFormatter={(name: string | number): React.JSX.Element => (
              <FormattedMessage id={`diagnosis.parkinsonEarlySymptom.${name}`} />
            )}
          />
        </FormRow>
        <FormRow title="diagnosis.parkinsonSymptomOnsetOther">
          <InputHandler
            type="TextArea"
            name="parkinsonSymptomOnsetOther"
            editing={!!editing}
            formData={formData}
            placeholder={'general.newAdditionalInformation'}
          />
        </FormRow>
        <FormRow title="diagnosis.parkinsonMotoricSymptomOnset.title">
          <InputHandler
            type="Radio"
            name="parkinsonMotoricSymptomOnset"
            editing={!!editing}
            formData={formData}
            options={['tremor', 'muscleTension', 'sluggishness', 'other']}
            optionFormatter={optionFormatter('parkinsonMotoricSymptomOnset.opts')}
          />
        </FormRow>
        <FormRow
          title="diagnosis.parkinsonMotoricSymptomOnset.otherSymptom"
          condition={formData?.document.parkinsonMotoricSymptomOnset === 'other'}
        >
          <InputHandler
            type="TextField"
            name="parkinsonMotoricSymptomOnsetOtherSymptom"
            editing={!!editing}
            formData={formData}
            placeholder={'diagnosis.parkinsonMotoricSymptomOnset.otherSymptomPlaceholder'}
          />
        </FormRow>
        <FormRow title="diagnosis.parkinsonMotoricSymptomOnsetSide.title">
          <InputHandler
            type="Radio"
            name="parkinsonMotoricSymptomOnsetSide"
            editing={!!editing}
            formData={formData}
            options={['left', 'right', 'both', 'unknown']}
            optionFormatter={optionFormatter('parkinsonMotoricSymptomOnsetSide.opts')}
          />
        </FormRow>
        <FormRow title="diagnosis.parkinsonMotoricSymptomOnsetInfo">
          <InputHandler
            type="TextArea"
            name="parkinsonMotoricSymptomOnsetInfo"
            editing={!!editing}
            formData={formData}
            placeholder={'general.newAdditionalInformation'}
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(ParkinsonSymptomForm);
