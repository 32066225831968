import { styled } from '@mui/material';
import colors from '../../config/theme/colors';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';

const StyledValueCell = styled('div')({
  paddingRight: '2rem',
});

const StyledHeader = styled('div')({
  color: colors.primary,
  fontSize: '1.65rem',
  marginBottom: '0.5rem',
});

const StyledTextValue = styled('div')({
  fontWeight: 600,
});

const TitleValueItem = ({
  title,
  value,
}: {
  title?: string;
  value?: string | React.JSX.Element | (React.JSX.Element | undefined)[] | number;
}): React.JSX.Element => {
  return (
    <StyledValueCell>
      <StyledHeader>{title ? <FormattedMessage id={title} /> : ''}</StyledHeader>
      <StyledTextValue>{value ? value : '-'}</StyledTextValue>
    </StyledValueCell>
  );
};

export default TitleValueItem;
