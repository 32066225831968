import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';

const BloodPressureForm = ({ formData }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={true}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="clinicalstudy.bloodPressureSystolic">
      <InputHandler
        type="NumberField"
        editing={true}
        name="bloodPressureSystolic"
        formData={formData}
        placeholder="clinicalstudy.bloodPressurePlaceholder"
      />
    </FormRow>
    <FormRow title="clinicalstudy.bloodPressureDiastolic">
      <InputHandler
        type="NumberField"
        editing={true}
        name="bloodPressureDiastolic"
        formData={formData}
        placeholder={'clinicalstudy.bloodPressurePlaceholder'}
      />
    </FormRow>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IBloodPressure>;
}

export default BloodPressureForm;
