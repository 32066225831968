import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { styled } from '@mui/system';

import FormRow from '../../../../../../components/FormRow';
import FormSectionBar from '../../../../../../components/FormSectionBar';
import FormSectionHistoryAcceptor from '../../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../../components/InputHandler';
import colors from '../../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { fm } from 'Components/FormatMessage';
import { B1, B2, B3 } from '../../../../utils/PSP';

const SubHeader = styled('div')({
  margin: '1rem 0',
  fontWeight: 600,
});

const optFormatterDiag = (s: string | number | boolean): React.JSX.Element =>
  fm(`diagnosis.${typeof s === 'string' && s.length < 1 ? 'empty' : s}`);

const optFormatterBasic = (s: string | number | boolean): React.JSX.Element => fm(`diagnosis.opts.yesNoUnknown.${s}`);

const formFields = [
  'sporadicOccurence',
  'age40OrOlderAtOnsetOfFirstPSPRelatedSymptom',
  'gradualProgressionOfPSPRelatedSymptoms',
  'predominantUnexplainedImpairmentOfEpisodicMemory',
  'predominantUnexplainedAutonomicFailure',
  'predominantUnexplainedVisualHallucinations',
  'predominantUnexplainedMultisegmentalUpperAndLowerMotorNeuronSigns',
  'suddenOnsetOrStepWiseOrRapidProgressionOfSymptoms',
  'historyOfEncephalitis',
  'prominentAppendicularAtaxia',
  'identifiableCauseOfPosturalInstability',
  'severeLeukoencephalopathy',
  'relevantStructuralAbnormality',
  'suggestiveClinicalFindingsOfOtherConditions',
  'inSyndromesWithSuddenOnsetOrStepWiseProgression',
  'inCasesWithVeryRapidProgression',
  'inPatientsWithPSPCBS',
  'wilsonsDisease',
  'niemannPickDiseaseTypeCLessThan45',
  'hypoparathyroidism',
  'neuroacanthocytosis',
  'neurosyphilis',
  'prionDisease',
  'paraneoplasticEncephalitis',
  'inPatientsWithSuggestiveFeatures',
  'atLeastOneFirstOrSecondDegreeRelativeHasPSPLikeSyndrome',
  'MAPTRareVariants',
  'MAPTH2HaplotypeHomozygosity',
  'LRRK2AndParkinRareVariants',
  'nonMAPTAssociatedFrontotemporalDementia',
  'PD',
  'AD',
  'niemannPickDiseaseTypeC',
  'kuforRakebSyndrome',
  'perrySyndrome',
  'mitochondrialDiseases',
  'dentatorubralPallidoluysianAtrophy',
  'prionRelatedDiseases',
  'huntingtonsDisease',
  'spinocerebellarAtaxia',
];

const setAllUnknown = (formData: IFormContext['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData?.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  excludedOpt = false,
  leftPadding = false,
  info,
  bottomMargin = true,
}: {
  title: string;
  description?: React.JSX.Element;
  name: string;
  formData: IFormContext['formData'];
  viewing: boolean;
  excludedOpt?: boolean;
  leftPadding?: boolean;
  info?: React.JSX.Element;
  bottomMargin?: boolean;
}): React.JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={7}
    headerStyle={{ paddingLeft: leftPadding ? '1.5rem' : undefined }}
    info={info}
    bottomMargin={bottomMargin}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      options={excludedOpt ? ['canNotBeExcluded', 'excluded', 'unknown'] : ['yes', 'no', 'unknown']}
      optionFormatter={excludedOpt ? optFormatterDiag : optFormatterBasic}
      height={3.5}
      width={9}
    />
  </FormRow>
);

const MakeSummaryRow = ({
  title,
  content,
  leftPadding = false,
  bottomMargin = true,
}: {
  title: string;
  content: React.JSX.Element;
  leftPadding?: boolean;
  bottomMargin?: boolean;
}): React.JSX.Element => {
  return (
    <FormRow
      title={title}
      headerWidth={7}
      headerStyle={{ paddingLeft: leftPadding ? '1.5rem' : undefined }}
      bottomMargin={bottomMargin}
    >
      {content}
    </FormRow>
  );
};

const BasicFeatures = ({ documents, formData, view, editing }: IFormContext<IPSP>): React.JSX.Element => {
  const isViewing = !editing && !!view?.viewing;
  const docID = editing ?? view?.viewing;
  const [showFindings, setShowFindings] = React.useState<boolean>(false);
  const [showGeneticFindings, setShowGeneticFindings] = React.useState<boolean>(false);

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
    setShowFindings(formData?.document.suggestiveClinicalFindingsOfOtherConditions === 'yes');
    setShowGeneticFindings(
      showFindings && formData?.document.atLeastOneFirstOrSecondDegreeRelativeHasPSPLikeSyndrome === 'yes',
    );
  }, [formData, isViewing, showFindings]);

  return (
    <React.Fragment>
      {formData ? (
        <React.Fragment>
          <FormSectionBar header={'diagnosis.psp.basicFeatures.title'} />
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): React.JSX.Element => fm('general.' + s)}
            header="diagnosis.psp.basicFeatures.mandatoryInclusionCriteria"
            hideCopyButton={isViewing}
          >
            {{
              sporadicOccurence: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.sporadicOccurence"
                    info={fm('diagnosis.psp.basicFeatures.sporadicOccurenceInfo')}
                    name="sporadicOccurence"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              age40OrOlderAtOnsetOfFirstPSPRelatedSymptom: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.age40OrOlderAtOnsetOfFirstPSPRelatedSymptom"
                    info={fm('diagnosis.psp.basicFeatures.age40OrOlderAtOnsetOfFirstPSPRelatedSymptomInfo')}
                    name="age40OrOlderAtOnsetOfFirstPSPRelatedSymptom"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              gradualProgressionOfPSPRelatedSymptoms: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.gradualProgressionOfPSPRelatedSymptoms"
                    info={fm('diagnosis.psp.basicFeatures.gradualProgressionOfPSPRelatedSymptomsInfo')}
                    name="gradualProgressionOfPSPRelatedSymptoms"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): React.JSX.Element => fm('general.' + s)}
            header="diagnosis.psp.basicFeatures.mandatoryExclusionCriteria"
            hideCopyButton={isViewing}
          >
            {{
              clinicalFindings: { header: <SubHeader>{fm('diagnosis.psp.basicFeatures.clinicalFindings')}</SubHeader> },
              predominantUnexplainedImpairmentOfEpisodicMemory: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.predominantUnexplainedImpairmentOfEpisodicMemory"
                    name="predominantUnexplainedImpairmentOfEpisodicMemory"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              predominantUnexplainedAutonomicFailure: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.predominantUnexplainedAutonomicFailure"
                    name="predominantUnexplainedAutonomicFailure"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              predominantUnexplainedVisualHallucinations: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.predominantUnexplainedVisualHallucinations"
                    name="predominantUnexplainedVisualHallucinations"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              predominantUnexplainedMultisegmentalUpperAndLowerMotorNeuronSigns: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.predominantUnexplainedMultisegmentalUpperAndLowerMotorNeuronSigns"
                    name="predominantUnexplainedMultisegmentalUpperAndLowerMotorNeuronSigns"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              suddenOnsetOrStepWiseOrRapidProgressionOfSymptoms: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.suddenOnsetOrStepWiseOrRapidProgressionOfSymptoms"
                    name="suddenOnsetOrStepWiseOrRapidProgressionOfSymptoms"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              historyOfEncephalitis: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.historyOfEncephalitis"
                    name="historyOfEncephalitis"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              prominentAppendicularAtaxia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.prominentAppendicularAtaxia"
                    name="prominentAppendicularAtaxia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              identifiableCauseOfPosturalInstability: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.identifiableCauseOfPosturalInstability"
                    name="identifiableCauseOfPosturalInstability"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              imagingFindings: { header: <SubHeader>{fm('diagnosis.psp.basicFeatures.imagingFindings')}</SubHeader> },
              severeLeukoencephalopathy: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.severeLeukoencephalopathy"
                    name="severeLeukoencephalopathy"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              relevantStructuralAbnormality: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.relevantStructuralAbnormality"
                    name="relevantStructuralAbnormality"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): React.JSX.Element => fm('general.' + s)}
            header="diagnosis.psp.basicFeatures.contentDependentExclusionCriteria"
            hideCopyButton={isViewing}
          >
            {{
              suggestiveOfOtherConditions: {
                header: (
                  <div style={{ marginTop: '0.5rem', marginBottom: '2.5rem' }}>
                    {fm('diagnosis.psp.basicFeatures.suggestiveOfOtherConditions')}
                  </div>
                ),
              },
              suggestiveClinicalFindingsOfOtherConditions: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.suggestiveClinicalFindingsOfOtherConditions"
                    name="suggestiveClinicalFindingsOfOtherConditions"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={showFindings}
                  />
                ),
              },
              imagingFindings: {
                condition: showFindings,
                header: <SubHeader>{fm('diagnosis.psp.basicFeatures.imagingFindings')}</SubHeader>,
              },
              inSyndromesWithSuddenOnsetOrStepWiseProgression: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.inSyndromesWithSuddenOnsetOrStepWiseProgression"
                    name="inSyndromesWithSuddenOnsetOrStepWiseProgression"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              inCasesWithVeryRapidProgression: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.inCasesWithVeryRapidProgression"
                    name="inCasesWithVeryRapidProgression"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              laboratoryFindings: {
                condition: showFindings,
                header: <SubHeader>{fm('diagnosis.psp.basicFeatures.laboratoryFindings')}</SubHeader>,
              },
              inPatientsWithPSPCBS: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.inPatientsWithPSPCBS"
                    name="inPatientsWithPSPCBS"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              inPatientsAgedLessThan45Years: {
                condition: showFindings,
                header: (
                  <div style={{ color: colors.tertiaryText, marginBottom: '0.5rem' }}>
                    {fm('diagnosis.psp.basicFeatures.inPatientsAgedLessThan45Years')}
                  </div>
                ),
              },
              wilsonsDisease: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.wilsonsDisease"
                    name="wilsonsDisease"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              niemannPickDiseaseTypeCLessThan45: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.niemannPickDiseaseTypeCLessThan45"
                    name="niemannPickDiseaseTypeCLessThan45"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              hypoparathyroidism: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.hypoparathyroidism"
                    name="hypoparathyroidism"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              neuroacanthocytosis: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.neuroacanthocytosis"
                    name="neuroacanthocytosis"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              neurosyphilis: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.neurosyphilis"
                    name="neurosyphilis"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              inRapidlyProgressivePatients: {
                condition: showFindings,
                header: (
                  <div style={{ color: colors.tertiaryText, marginBottom: '0.5rem' }}>
                    {fm('diagnosis.psp.basicFeatures.inRapidlyProgressivePatients')}
                  </div>
                ),
              },
              prionDisease: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.prionDisease"
                    name="prionDisease"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              paraneoplasticEncephalitis: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.paraneoplasticEncephalitis"
                    name="paraneoplasticEncephalitis"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              inPatientsWithSuggestiveFeatures: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.inPatientsWithSuggestiveFeatures"
                    name="inPatientsWithSuggestiveFeatures"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              geneticFindings: {
                condition: showFindings,
                header: <SubHeader>{fm('diagnosis.psp.basicFeatures.geneticFindings')}</SubHeader>,
              },
              geneticFindingsInfo: {
                condition: showFindings,
                header: (
                  <div style={{ marginTop: '0.5rem', marginBottom: '2.5rem' }}>
                    {fm('diagnosis.psp.basicFeatures.geneticFindingsInfo')}
                  </div>
                ),
              },
              atLeastOneFirstOrSecondDegreeRelativeHasPSPLikeSyndrome: {
                condition: showFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.atLeastOneFirstOrSecondDegreeRelativeHasPSPLikeSyndrome"
                    name="atLeastOneFirstOrSecondDegreeRelativeHasPSPLikeSyndrome"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={showGeneticFindings}
                  />
                ),
              },
              MAPTRareVariants: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.MAPTRareVariants"
                    name="MAPTRareVariants"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              MAPTH2HaplotypeHomozygosity: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.MAPTH2HaplotypeHomozygosity"
                    name="MAPTH2HaplotypeHomozygosity"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              LRRK2AndParkinRareVariants: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.LRRK2AndParkinRareVariants"
                    name="LRRK2AndParkinRareVariants"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              knownRareVariants: {
                condition: showGeneticFindings,
                header: (
                  <Container>
                    <Item xs={5} style={{ color: colors.tertiaryText, marginBottom: '0.5rem' }}>
                      {fm('diagnosis.psp.basicFeatures.knownRareVariants')}
                    </Item>
                  </Container>
                ),
              },
              nonMAPTAssociatedFrontotemporalDementia: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.nonMAPTAssociatedFrontotemporalDementia"
                    name="nonMAPTAssociatedFrontotemporalDementia"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              PD: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.PD"
                    name="PD"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              AD: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.AD"
                    name="AD"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              niemannPickDiseaseTypeC: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.niemannPickDiseaseTypeC"
                    name="niemannPickDiseaseTypeC"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              kuforRakebSyndrome: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.kuforRakebSyndrome"
                    name="kuforRakebSyndrome"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              perrySyndrome: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.perrySyndrome"
                    name="perrySyndrome"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              mitochondrialDiseases: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.mitochondrialDiseases"
                    name="mitochondrialDiseases"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              dentatorubralPallidoluysianAtrophy: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.dentatorubralPallidoluysianAtrophy"
                    name="dentatorubralPallidoluysianAtrophy"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              prionRelatedDiseases: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.prionRelatedDiseases"
                    name="prionRelatedDiseases"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              huntingtonsDisease: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.huntingtonsDisease"
                    name="huntingtonsDisease"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
              spinocerebellarAtaxia: {
                condition: showGeneticFindings,
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.basicFeatures.spinocerebellarAtaxia"
                    name="spinocerebellarAtaxia"
                    formData={formData}
                    viewing={isViewing}
                    excludedOpt={true}
                    leftPadding
                    bottomMargin={false}
                  />
                ),
                optionFormatter: optFormatterDiag,
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            header="diagnosis.psp.basicFeatures.summary.title"
            hideCopyButton={true}
          >
            {{
              mandatoryInclusionCriteriaIsMet: {
                element: (
                  <MakeSummaryRow
                    title="diagnosis.psp.basicFeatures.summary.mandatoryInclusionCriteriaIsMet"
                    content={fm(`general.${B1(formData.document)}`)}
                  />
                ),
              },
              mandatoryExclusionCriteriaIsMet: {
                element: (
                  <MakeSummaryRow
                    title="diagnosis.psp.basicFeatures.summary.mandatoryExclusionCriteriaIsMet"
                    content={fm(`general.${B2(formData.document)}`)}
                  />
                ),
              },
              contextDependentExclusionCriteriaIsMet: {
                element: (
                  <MakeSummaryRow
                    title="diagnosis.psp.basicFeatures.summary.contextDependentExclusionCriteriaIsMet"
                    content={fm(`general.${B3(formData.document)}`)}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default withFormContext(BasicFeatures);
