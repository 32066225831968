import * as React from 'react';
import { equals } from 'ramda';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import { TabContentContext } from 'Components/TabContent';
import { DoctorsOrdersContext } from 'Routes/DoctorsOrders/Document';
import { subjectOfTreatmentNames } from 'Routes/DoctorsOrders/Document/config';
import { v4 } from 'uuid';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/system';
import colors from '../../../../../../config/theme/colors';
import { isEmpty, omit, path } from 'Utility/ramdaReplacement';

const ClearIconStyled = styled(ClearIcon)({
  display: 'block',
  width: '3rem',
  color: colors.secondaryText,
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

const TabEditor = ({ formData, treatmentType, treatmentName, setPreviousIndex }: ITabEditor): React.JSX.Element => {
  const { document, onChange } = formData;

  const tabContext = React.useContext(TabContentContext);
  const doctorsOrdersContext = React.useContext(DoctorsOrdersContext);

  const selected = tabContext.selected;
  const { fm, isEditing } = doctorsOrdersContext;

  const [prTreatment, setPrTreatment] = React.useState<{ [key: string]: any } | undefined>(undefined);

  let thisTreatment =
    path([treatmentType, treatmentName, selected], document) &&
    JSON.parse(JSON.stringify(document[treatmentType]?.[treatmentName]?.[selected]));

  const equalTreatments = equals(
    omit(['id'], thisTreatment ?? {}),
    prTreatment ? omit<typeof prTreatment, string>(['id'], prTreatment) : undefined,
  );

  React.useEffect(() => {
    if (!equalTreatments) {
      setPrTreatment(undefined);
    }
  }, [equalTreatments]);

  // Keep track of index to navigate back from addTabEditor
  React.useEffect(() => {
    setPreviousIndex && setPreviousIndex(selected);
  }, [selected]);

  const onChangeTreatmentName = (v: TOnChangeValues) => {
    const key = Object.keys(v)[0];
    const value = v[key];
    const newDoc = JSON.parse(JSON.stringify(document));
    // Insert first treatment to array here if it the name is the first change done
    if (!thisTreatment) {
      thisTreatment = { id: v4(), name: '' };
      newDoc[treatmentType][treatmentName] = [thisTreatment];
    }
    if (key === 'name') thisTreatment.name = value;
    newDoc[treatmentType][treatmentName][selected][key] = value;
    onChange && onChange(newDoc);
  };

  const value = (name: string) => (thisTreatment ? thisTreatment[name] : '');

  return (
    <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Item xs={true}>
        <Container style={{ alignItems: 'center', margin: '0.5rem 0 2rem 0' }}>
          <Item xs={5} style={{ color: colors.tertiaryText }}>
            {fm(`doctorsOrders.${treatmentType}.treatments.treatmentName`)}
          </Item>
          <Item xs={true}>
            {isEditing ? (
              <InputHandler
                type="AutoCompleteSelect"
                editing={isEditing}
                name="name"
                formData={{
                  document: { name: value('name') },
                  onChange: onChangeTreatmentName,
                }}
                options={subjectOfTreatmentNames}
                renderOption={(o: any): React.ReactNode =>
                  o && !isEmpty(o) ? (
                    <React.Fragment key={fm(`doctorsOrders.subjectOfTreatmentNames.${o}`)}>
                      {fm(`doctorsOrders.subjectOfTreatmentNames.${o}`)}
                    </React.Fragment>
                  ) : (
                    ''
                  )
                }
                getOptionLabel={(o: string): string =>
                  o && !isEmpty(o) ? fm(`doctorsOrders.subjectOfTreatmentNames.${o}`) : ''
                }
                width={25}
                placeholder={`doctorsOrders.${treatmentType}.treatments.treatmentNamePlaceholder`}
                groupID="group"
              />
            ) : (
              <span style={{ fontWeight: 600 }}>
                {typeof value('name') === 'string' && value('name').length > 0
                  ? fm(`doctorsOrders.subjectOfTreatmentNames.${value('name')}`)
                  : '-'}
              </span>
            )}
          </Item>
          {treatmentType === 'tdcs' && treatmentName === 'subjectOfTreatment' && value('name') !== 'other' && (
            <Item xs={2}>{fm('doctorsOrders.tdcs.treatments.visibleForPatient')}</Item>
          )}
        </Container>
        {value('name') === 'other' && (
          <Container style={{ alignItems: 'center', marginBottom: '2rem' }}>
            <Item xs={5} style={{ color: colors.tertiaryText }}>
              {fm(`doctorsOrders.${treatmentType}.treatments.treatmentNameOther`)}
            </Item>
            <Item xs={true}>
              <InputHandler
                type="TextField"
                editing={isEditing}
                name="nameOther"
                formData={{
                  document: { nameOther: value('nameOther') },
                  onChange: onChangeTreatmentName,
                }}
                width={20}
                placeholder={`doctorsOrders.${treatmentType}.treatments.treatmentNameOther`}
                maxLength={120}
              />
            </Item>
            {treatmentType === 'tdcs' && treatmentName === 'subjectOfTreatment' && value('name') === 'other' && (
              <Item xs={2}>{fm('doctorsOrders.tdcs.treatments.visibleForPatient')}</Item>
            )}
          </Container>
        )}
        <Container style={{ alignItems: 'center', marginBottom: '0.5rem' }}>
          <Item xs={5} style={{ color: colors.tertiaryText }}>
            {fm(`doctorsOrders.${treatmentType}.treatments.treatmentSpecifier`)}
          </Item>
          <Item xs={3}>
            <InputHandler
              type="Select"
              editing={isEditing}
              name="specifier"
              formData={{
                document: { specifier: value('specifier') },
                onChange: onChangeTreatmentName,
              }}
              options={['1', '2', '3']}
              width={15}
              placeholder={`doctorsOrders.${treatmentType}.treatments.treatmentSpecifierPlaceholder`}
            />
          </Item>
          <Item xs={4}>
            {isEditing && value('specifier') && (
              <ClearIconStyled onClick={() => onChangeTreatmentName({ specifier: null })} />
            )}
          </Item>
        </Container>
      </Item>
    </Container>
  );
};

export interface ITabEditor {
  formData: IFormData<IDoctorsOrder>;
  treatmentType: 'rtms' | 'tdcs';
  treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment';
  allPreviousTreatments: Array<IRTMSTreatment | ITDCSTreatment>;
  setPreviousIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export default TabEditor;
