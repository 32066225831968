import * as React from 'react';

const svgImage = (): React.JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(0.749529,0,0,0.749529,-493.83,-48.7641)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-351.911,-47.6323)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-202.693,-43.9802)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-375.268,-185.094)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-343.787,-78.0654)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-238.997,-77.1215)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.414935,0.352855,-0.520808,0.612437,188.428,-465.216)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(0.648296,0.376174,-0.376174,0.648296,-88.3404,-484.145)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(0.265169,0.153865,-0.153865,0.265169,132.498,-123.916)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(1.49948,-0.467887,0.1956,0.626859,-991.148,142.391)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(1.20423,0,0,0.177156,-491.529,175.101)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(1.58211,0,0,1.04896,-729.396,-446.466)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.999527,0,0,1.07893,-368.397,-498.396)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(1.58149,0.0441665,-0.0305024,1.09221,-857.888,-508.543)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-194.551,-967.824)">
      <rect
        x="283.824"
        y="1561.14"
        width="53.863"
        height="148.427"
        style={{ fill: 'rgb(128,128,128)', stroke: 'rgb(128,128,128)', strokeWidth: '9.76px' }}
      />
    </g>
  </svg>
);

export default svgImage;
