import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../../components/FormRow';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { epilepsySyndromesTypes } from '../../../../utils/definitions';

const SyndromeForm = ({ editing, formData }: IFormContext): React.JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="diagnosis.epilepsy.diagnosisDate">
        <InputHandler
          type="PartialDate"
          editing={!!editing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="diagnosis.epilepsy.syndrome.syndrome">
        <InputHandler
          type="Radio"
          editing={!!editing}
          name="syndrome"
          formData={formData}
          options={epilepsySyndromesTypes}
          optionFormatter={(s: string | number): React.JSX.Element => (
            <FormattedMessage id={`diagnosis.epilepsy.syndrome.opts.${s}`} />
          )}
        />
      </FormRow>
      <FormRow
        title="diagnosis.epilepsy.etiology.specification"
        condition={formData?.document.syndrome === 'otherKnownSyndrome'}
      >
        <InputHandler
          type="TextField"
          name="syndromeSpecification"
          editing={!!editing}
          formData={formData}
          placeholder={'diagnosis.epilepsy.etiology.specification'}
        />
      </FormRow>
      <FormRow title="diagnosis.epilepsy.orphaCode">
        <InputHandler
          type="NumberField"
          editing={!!editing}
          name="orphaCode"
          formData={formData}
          placeholder={'diagnosis.epilepsy.orphaCode'}
        />
      </FormRow>
    </React.Fragment>
  );
};

export default withFormContext(SyndromeForm);
