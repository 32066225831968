import * as React from 'react';

import DocumentCreationButton from 'Components/DocumentCreationButton';
import DocumentHeader from 'Components/DocumentHeader';
import DocumentWrapper from 'Components/DocumentWrapper';
import FormEditingHandler from 'Containers/FormEditingHandler';
import DoctorsOrdersForm from './Form';
import DoctorsOrdersHistory from './HistoryRowData';
import { useIntl } from 'react-intl';
import AdditionalDocumentsForm from './Form/AdditionalDocuments';
import { sortPartialDate } from 'neuro-utils';
import ActionButtonRounded from 'Components/ActionButtonRounded';

const EditButton = ({ startEdit }: IEditButton): React.JSX.Element => (
  <div>
    <DocumentCreationButton name="doctorsOrders" text="general.new" onClick={startEdit({})} />
  </div>
);

interface IEditButton {
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
}

export const DoctorsOrdersContext = React.createContext<IDoctorsOrderContext>({
  fm: (m: string) => m,
  isEditing: false,
  view: undefined,
  formData: {
    document: { _id: 'temp-id', _cid: 'temp-cid', _type: 'temp-type', _cdate: 0, _editing: false, _docCreateDate: 0 },
    onChange: () => {
      return;
    },
  },
  startEdit: (d: { _id?: string }) => (e: React.MouseEvent<Element, MouseEvent>) => {
    !!d;
    !!e;
    return;
  },
  documents: [],
  additionalDocuments: [],
  ninmtTreatmentPeriods: [],
});

export interface IDoctorsOrderContext {
  fm: (m: string, values?: { [key: string]: string }) => string;
  isEditing: boolean;
  view?: IView;
  formData: IFormData<IDoctorsOrder>;
  documents: Array<IDoctorsOrder>;
  additionalDocuments?: Array<IDoctorsOrderAdditional>;
  ninmtTreatmentPeriods?: Array<ININMTTreatmentPeriod>;
  startEdit: (document: { _id?: string }) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

const DoctorsOrders = ({ documents }: IDoctorsOrdersProps): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string, values?: { [key: string]: string }) => formatMessage({ id: id }, values);

  const [activeView, setActiveView] = React.useState<'subjectOfOrder' | 'form'>('subjectOfOrder');

  return (
    <FormEditingHandler name="doctorsOrders" documents={documents}>
      {(editing, startEdit, formData, view): React.JSX.Element => {
        const isEditing = !!editing && !view?.viewing;
        const showForm = !!editing || !!view?.viewing;

        const doctorsOrdersDocs = documents.filter((d) => d._type === 'doctorsOrders') as IDoctorsOrder[];
        const doctorsOrdersAdditionalDocs = documents.filter((d) => d._type === 'doctorsOrdersAdditional');
        const ninmtTreatmentPeriodDocs = documents.filter(
          (d) => d._type === 'ninmtTreatmentPeriod',
        ) as ININMTTreatmentPeriod[];

        doctorsOrdersDocs.sort((n1, n2) => n2._cdate - n1._cdate).sort((n1, n2) => sortPartialDate(n2.date, n1.date));

        const document = [...doctorsOrdersDocs, ...doctorsOrdersAdditionalDocs].find((d: any) =>
          [editing, view?.viewing].includes(d._id),
        );

        const editingDocumentType = documents.find((d) => d._id === editing)?._type;

        return (
          <React.Fragment>
            <DoctorsOrdersContext.Provider
              value={{
                fm: fm,
                isEditing: isEditing,
                view: view,
                formData: formData,
                documents: doctorsOrdersDocs,
                additionalDocuments: doctorsOrdersAdditionalDocs,
                ninmtTreatmentPeriods: ninmtTreatmentPeriodDocs,
                startEdit: startEdit,
              }}
            >
              <DocumentWrapper>
                <DocumentHeader
                  name="doctorsOrders"
                  headerId={
                    document && document._type === 'doctorsOrdersAdditional'
                      ? 'doctorsOrders.additionalDocuments.header'
                      : 'doctorsOrders.title'
                  }
                  editing={editing || view?.viewing}
                  editButtons={<EditButton startEdit={startEdit} />}
                  infoText={
                    !isEditing || editingDocumentType === 'doctorsOrders' ? (
                      !isEditing ? (
                        'doctorsOrders.infoText'
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ fontWeight: '600' }}>
                            {activeView === 'subjectOfOrder'
                              ? fm('doctorsOrders.infoTextSubjectOfOrder')
                              : fm('doctorsOrders.infoTextForm')}
                          </div>
                          {activeView === 'form' && (
                            <ActionButtonRounded
                              width={18}
                              height={4}
                              fontSize={16}
                              text="doctorsOrders.subjectOfOrder.goBack"
                              onClick={() => setActiveView('subjectOfOrder')}
                              colorScheme="cancel"
                            />
                          )}
                        </div>
                      )
                    ) : undefined
                  }
                  headerWidth={isEditing && activeView === 'form' ? 12 : 7}
                />
                {showForm && document ? (
                  document._type === 'doctorsOrdersAdditional' ? (
                    <AdditionalDocumentsForm formData={formData} />
                  ) : document._type === 'doctorsOrders' ? (
                    <DoctorsOrdersForm formData={formData} activeView={activeView} setActiveView={setActiveView} />
                  ) : null
                ) : null}
                {!showForm ? <DoctorsOrdersHistory startEdit={startEdit} /> : null}
              </DocumentWrapper>
            </DoctorsOrdersContext.Provider>
          </React.Fragment>
        );
      }}
    </FormEditingHandler>
  );
};

interface IDoctorsOrdersProps {
  documents: Array<IDoctorsOrder | IDoctorsOrderAdditional | ININMTTreatmentPeriod>;
}

export default DoctorsOrders;
