import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import { formatPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const TMT = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id="cognition.tmt.tmt" />}
      newButton={
        <DocumentCreationButton
          name="tmt"
          text="cognition.tmt.newTmt"
          onClick={startEdit({}, 'tmt')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id="general.date" />
            </Item>
            <Item xs={4}>
              <FormattedMessage id="cognition.tmt.tmta" />
            </Item>
            <Item xs={3}>
              <FormattedMessage id="cognition.tmt.tmtb" />
            </Item>
            <Item xs={3}>&nbsp;</Item>
          </>
        }
        contentFormat={(d: ITMT): React.JSX.Element => (
          <>
            <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
            <Item xs={4}>
              {d.tmtaUnableToPerform?.[0] === 'unableToPerform' ? (
                <FormattedMessage id="cognition.tmt.unableToPerform" />
              ) : (
                d.tmtaTime
              )}
            </Item>
            <Item xs={3}>
              {d.tmtbUnableToPerform?.[0] === 'unableToPerform' ? (
                <FormattedMessage id="cognition.tmt.unableToPerform" />
              ) : (
                d.tmtbTime
              )}
            </Item>
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} view={view} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: ITMT[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default TMT;
