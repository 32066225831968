import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

const DescriptionArea = styled('div')({});

const StyledTable = styled('table')({
  width: '100%',
  border: '1px solid black',
  borderCollapse: 'collapse',
  '& tr, & td': {
    padding: '0.5rem 1rem',
  },
});

const fm = (id: string): React.JSX.Element => <FormattedMessage id={id} />;

export const medicationDescriptions = {
  lemtrada: (
    <DescriptionArea>
      <div style={{ fontWeight: 600 }}>{fm('medication.lemtradaDescription.1.header')}</div>
      <ul>
        <li>{fm('medication.lemtradaDescription.1.1')}</li>
        <li>{fm('medication.lemtradaDescription.1.2')}</li>
      </ul>
    </DescriptionArea>
  ),
  ocrevus: (
    <DescriptionArea>
      <div style={{ fontWeight: 600 }}>{fm('medication.ocrevusDescription.1.header')}</div>
      <div style={{ margin: '1rem 0 2rem 0' }}>{fm('medication.ocrevusDescription.1.1')}</div>
      <div style={{ fontWeight: 600 }}>{fm('medication.ocrevusDescription.2.header')}</div>
      <div style={{ margin: '1rem 0 2rem 0' }}>{fm('medication.ocrevusDescription.2.1')}</div>
    </DescriptionArea>
  ),
  tysabri: (
    <DescriptionArea>
      <div style={{ fontWeight: 600 }}>{fm('medication.tysabriDescription.1.header')}</div>
      <div style={{ margin: '1rem 0 2rem 0' }}>{fm('medication.tysabriDescription.1.1')}</div>
    </DescriptionArea>
  ),
  mitoxantroni: (
    <DescriptionArea>
      <div style={{ fontWeight: 600 }}>{fm('medication.mitoxantroniDescription.1.header')}</div>
      <div style={{ margin: '1rem 0 2rem 0' }}>{fm('medication.mitoxantroniDescription.1.1')}</div>
    </DescriptionArea>
  ),
  mavenclad: (
    <DescriptionArea>
      <div style={{ fontWeight: 600 }}>{fm('medication.mavencladDescription.1.header')}</div>
      <div style={{ margin: '1rem 0 2rem 0' }}>{fm('medication.mavencladDescription.1.1')}</div>
      <div>
        <div style={{ margin: '1rem 0 2rem 0' }}>{fm('medication.mavencladDescription.table1.description')}</div>
        <StyledTable>
          <thead style={{ borderBottom: '1px solid black' }}>
            <tr>
              <th>{fm('medication.mavencladDescription.table1.headers1.1')}</th>
              <th style={{ textAlign: 'center' }} colSpan={2}>
                {fm('medication.mavencladDescription.table1.headers1.2')}
              </th>
            </tr>
            <tr>
              <th>{fm('medication.mavencladDescription.table1.headers2.1')}</th>
              <th>{fm('medication.mavencladDescription.table1.headers2.2')}</th>
              <th>{fm('medication.mavencladDescription.table1.headers2.3')}</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'center' }}>
            <tr>
              <td>40 - &lt; 50</td>
              <td>40 mg (4 {fm('medication.mavencladDescription.table1.tablets')})</td>
              <td>40 mg (4 {fm('medication.mavencladDescription.table1.tablets')})</td>
            </tr>
            <tr>
              <td>50 - &lt; 60</td>
              <td>50 mg (5 {fm('medication.mavencladDescription.table1.tablets')})</td>
              <td>50 mg (5 {fm('medication.mavencladDescription.table1.tablets')})</td>
            </tr>
            <tr>
              <td>60 - &lt; 70</td>
              <td>60 mg (6 {fm('medication.mavencladDescription.table1.tablets')})</td>
              <td>60 mg (6 {fm('medication.mavencladDescription.table1.tablets')})</td>
            </tr>
            <tr>
              <td>70 - &lt; 80</td>
              <td>70 mg (7 {fm('medication.mavencladDescription.table1.tablets')})</td>
              <td>70 mg (7 {fm('medication.mavencladDescription.table1.tablets')})</td>
            </tr>
            <tr>
              <td>80 - &lt; 90</td>
              <td>80 mg (8 {fm('medication.mavencladDescription.table1.tablets')})</td>
              <td>70 mg (7 {fm('medication.mavencladDescription.table1.tablets')})</td>
            </tr>
            <tr>
              <td>90 - &lt; 100</td>
              <td>90 mg (9 {fm('medication.mavencladDescription.table1.tablets')})</td>
              <td>80 mg (8 {fm('medication.mavencladDescription.table1.tablets')})</td>
            </tr>
            <tr>
              <td>100 - &lt; 110</td>
              <td>100 mg (10 {fm('medication.mavencladDescription.table1.tablets')})</td>
              <td>90 mg (9 {fm('medication.mavencladDescription.table1.tablets')})</td>
            </tr>
            <tr>
              <td>110 {fm('medication.mavencladDescription.table1.andOver')}</td>
              <td>100 mg (10 {fm('medication.mavencladDescription.table1.tablets')})</td>
              <td>100 mg (10 {fm('medication.mavencladDescription.table1.tablets')})</td>
            </tr>
          </tbody>
        </StyledTable>
      </div>
      <div>
        <div style={{ margin: '2rem 0 2rem 0' }}>{fm('medication.mavencladDescription.table2.description')}</div>
        <StyledTable>
          <thead style={{ borderBottom: '1px solid black' }}>
            <tr>
              <th style={{ width: '30%' }}>{fm('medication.mavencladDescription.table2.headers.1')}</th>
              <th>{fm('medication.mavencladDescription.table2.headers.day')} 1</th>
              <th>{fm('medication.mavencladDescription.table2.headers.day')} 2</th>
              <th>{fm('medication.mavencladDescription.table2.headers.day')} 3</th>
              <th>{fm('medication.mavencladDescription.table2.headers.day')} 4</th>
              <th>{fm('medication.mavencladDescription.table2.headers.day')} 5</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'center' }}>
            <tr>
              <td>4</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>0</td>
            </tr>
            <tr>
              <td>5</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>6</td>
              <td>2</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>7</td>
              <td>2</td>
              <td>2</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>8</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>9</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>1</td>
            </tr>
            <tr>
              <td>10</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
            </tr>
          </tbody>
        </StyledTable>
      </div>
    </DescriptionArea>
  ),
} as { [key: string]: React.JSX.Element };
