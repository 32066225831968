import * as React from 'react';
import SearchDialog from '../../../../../../components/SearchDialog';
import { fetchWithOptions } from '../../../../../../utility/fetch';
import { parseJWTFromCookie } from '../../../../../../utility/jwtAuthTools';

const search = (currentSearchString: string): Promise<any> =>
  fetchWithOptions(
    `/api/medication/search?search=${currentSearchString}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  ).then(
    (res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw { status: res.status, fullResponse: res };
      }
    },
    (error: Error) => {
      throw error;
    },
  );

const MedicationSearchDialog = ({ formData, disabled, disabledTooltip }: IOwnProps): React.JSX.Element => (
  <SearchDialog
    formData={formData}
    searchFn={search}
    resultToDoc={{
      name: 'medicationName',
      atc: 'atc',
      substanceString: 'medicationSubstances',
      dosageForm: 'dosageForm',
      regimen: 'regimen',
    }}
    openCloseButton={{
      disabled: disabled,
      disabledTooltip: disabledTooltip,
    }}
    openCloseButtonText={'medication.chooseMedication'}
    title={'medication.chooseMedication'}
    info={'medication.medDialogInfoText'}
  />
);

interface IOwnProps {
  formData: { document: IMedication; onChange: IFormData['onChange'] };
  disabled?: boolean;
  disabledTooltip?: React.JSX.Element;
}

export default MedicationSearchDialog;
