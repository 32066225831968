import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';
import CheckboxSingle from 'Components/InputHandler/components/CheckboxSingle';
import Selects from 'Components/InputHandler/components/Select';
import ThemedDialog from 'Components/_NewElements/Dialog';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector as useSelector } from 'Store/index';

const Text = styled('div')(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const Header2 = styled('h2')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Header3 = styled('h3')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

/** View1 - Selecting the organization */
const View1 = ({ fm, patientData, selectedOrg, onSelectOrg, accepted, setAccepted }: IView1) => {
  const userOrg = useSelector((s: IState) => s.session.data?.orgid);
  const orgGroupArray = useSelector((s: IState) => s.session.orgGroupArray);
  const usableOrgs = (orgGroupArray || []).filter((o) => o.orgId !== userOrg);

  return (
    <>
      <div>{fm('patientTransfer.dialog.warningText')}</div>

      <Header2 style={{ marginBottom: '1rem' }}>
        {patientData?.lastNames}, {patientData?.firstNames}
      </Header2>
      <Header3 style={{ marginTop: '0' }}>{patientData?.ssn}</Header3>

      <div style={{ margin: '3rem 0' }}>
        <div>{fm('patientTransfer.dialog.fromWhichOrg')}</div>
        <Selects
          type="Select"
          editing={true}
          name="organization"
          options={usableOrgs.map((org) => org.orgId)}
          optionFormatter={(org) => usableOrgs.find((o) => o.orgId === org)?.displayName}
          placeholder={fm('patientTransfer.dialog.chooseOrg')}
          width={30}
          value={selectedOrg || ''}
          onChange={(values) => {
            const org = values['organization'] as string;
            onSelectOrg(org);
          }}
        />
      </div>

      <div style={{ marginTop: '2rem' }}>
        <CheckboxSingle
          type={'CheckboxSingle'}
          editing={true}
          name="acceptCheckbox"
          option={'accepted'}
          value={accepted ? 'accepted' : ''}
          onChange={(values) => {
            const accepted = values['acceptCheckbox'] as string;
            setAccepted(!!accepted);
          }}
          optionFormatter={() => fm('patientTransfer.dialog.acceptText')}
          disabled={false}
          showLabel
          labelColor={''}
        />
      </div>
    </>
  );
};
interface IView1 {
  fm: (s: string) => string;
  patientData: IPatientStore['data'];
  selectedOrg: string | null;
  onSelectOrg: (org: string) => void;
  accepted: boolean;
  setAccepted: (a: boolean) => void;
}

const View2 = ({ fm, patientData }: IView2) => {
  return (
    <Container alignItems={'center'} direction={'column'}>
      <Item>
        <Header2>{fm('patientTransfer.dialog.transferring')}</Header2>
      </Item>
      <Item style={{ fontWeight: 600 }}>
        {patientData?.lastNames}, {patientData?.firstNames}
      </Item>
      <Item style={{ fontWeight: 600, marginTop: '-1rem' }}>{patientData?.ssn}</Item>
    </Container>
  );
};
interface IView2 {
  fm: (s: string) => string;
  patientData: IPatientStore['data'];
}

const View3 = ({ fm, patientData }: IView3) => {
  return (
    <Container alignItems={'center'} direction={'column'}>
      <Item>
        <Header2>{fm('patientTransfer.dialog.transferred')}</Header2>
      </Item>
      <Item style={{ fontWeight: 600 }}>
        {patientData?.lastNames}, {patientData?.firstNames}
      </Item>
      <Item style={{ fontWeight: 600, marginTop: '-1rem' }}>{patientData?.ssn}</Item>
    </Container>
  );
};
interface IView3 {
  fm: (s: string) => string;
  patientData: IPatientStore['data'];
}

const TransferDialog = ({ open, onClose, transferStatus, startTransfer }: ITransferDialog) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const [acceptCheckbox, setAcceptCheckbox] = useState<boolean>(false);

  const onCloseDialog = () => {
    setSelectedOrg(null);
    setAcceptCheckbox(false);
    onClose();
  };

  const navigateToFrontpage = () => {
    window.location.href = '/';
  };

  const patientData = useSelector((s: IState) => s.patient.data);
  if (!patientData) return null;
  return (
    <ThemedDialog
      open={open}
      title={fm('patientTransfer.transferFromAnotherOrg')}
      onClose={transferStatus === 'notStarted' ? onCloseDialog : undefined}
      dialogActions={[
        ...(transferStatus === 'done'
          ? [{ text: 'patientTransfer.toFrontPage', filled: true, onClick: () => navigateToFrontpage(), width: 16 }]
          : []),
        ...(transferStatus === 'notStarted'
          ? [
              { text: 'general.close', onClick: () => onCloseDialog() },
              {
                text: 'general.accept',
                filled: true,
                disabled: !selectedOrg || !acceptCheckbox,
                onClick: () => selectedOrg && startTransfer(selectedOrg),
              },
            ]
          : []),
      ]}
      dialogActionsJustify={transferStatus === 'done' ? 'center' : undefined}
    >
      <Text>
        {transferStatus === 'notStarted' ? (
          <View1
            fm={fm}
            patientData={patientData}
            selectedOrg={selectedOrg}
            onSelectOrg={setSelectedOrg}
            accepted={acceptCheckbox}
            setAccepted={setAcceptCheckbox}
          />
        ) : transferStatus === 'started' ? (
          <View2 fm={fm} patientData={patientData} />
        ) : transferStatus === 'done' ? (
          <View3 fm={fm} patientData={patientData} />
        ) : null}
      </Text>
    </ThemedDialog>
  );
};

interface ITransferDialog {
  open: boolean;
  onClose: () => void;
  transferStatus: 'notStarted' | 'started' | 'done';
  startTransfer: (sourceOrg: string) => void;
}

export default TransferDialog;
