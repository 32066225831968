import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate } from 'neuro-utils';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const ChestCircumference = ({ documents, startEdit }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'pulmonary.chestCircumference'} />}
      newButton={
        <DocumentCreationButton
          name="chestCircumference"
          text={'pulmonary.newMeasurement'}
          onClick={startEdit({}, 'chestCircumference')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={12} style={{ marginBottom: '1rem' }}>
              <FormattedMessage id="pulmonary.forInfants" />
            </Item>
            <Item xs={2} id={'date'}>
              <FormattedMessage id={'general.date'} />
            </Item>
            <Item xs={4} id={'fullExpiration'}>
              <FormattedMessage id={'pulmonary.chestCircumferenceAtFullExpiration'} />
            </Item>
            <Item xs={4} id={'fullInspiration'}>
              <FormattedMessage id={'pulmonary.chestCircumferenceAtFullInspiration'} />
            </Item>
          </>
        }
        contentFormat={(d: IChestCircumference): React.JSX.Element => (
          <>
            <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
            <Item xs={4} style={{ fontWeight: '600' }}>
              {d?.fullExpiration ? <FormattedMessage id="pulmonary.cm" values={{ value: d.fullExpiration }} /> : '-'}
            </Item>
            <Item xs={3} style={{ fontWeight: '600' }}>
              {d?.fullInspiration ? <FormattedMessage id="pulmonary.cm" values={{ value: d.fullInspiration }} /> : '-'}
            </Item>
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IChestCircumference[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default ChestCircumference;
