import * as React from 'react';
import { useIntl } from 'react-intl';
import { field } from '../../../../config/theme/componentTheme';
import { styled } from '@mui/system';

// This one is in separate file so that localizations work

const StyledInput = styled('input', {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }: { width?: number }) => ({
  width: width ? width + 'rem' : field.width,
  height: field.height,
  borderRadius: field.borderRadius,
  fontWeight: field.fontWeight,
  textAlign: 'left',
  fontFamily: 'Titillium Web',
  letterSpacing: field.letterSpacing,
  boxShadow: field.boxShadow,
  border: field.border,
  padding: field.padding,
}));

const StyledTextField = ({ value, onChange, placeholder, width }: ITextfieldInputProps): React.JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <StyledInput
      type="text"
      value={value}
      placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(e.currentTarget.value);
      }}
      width={width}
    />
  );
};

export default StyledTextField;

interface ITextfieldInputProps {
  value?: string;
  onChange: (e: string) => void;
  placeholder?: string;
  width?: number;
  fullWidth?: boolean;
}
