import CollapseElem from 'Components/Collapse';
import { Container, Item } from 'Components/Grid';
import HistorySection from 'Components/HistorySection';
import { isPartialDate, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import colors from '../../../../../../config/theme/colors';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import { newButton } from '../../../../utils/functions';
import { TreatmentPeriodEventListItem } from '../components';

const SymptomsAndLocationsHistory = ({
  documents = [],
  startEdit,
  fm = (): string => '',
  treatmentPeriod,
  setTreatmentId,
}: IHistoryContext & {
  treatmentPeriod: ININMTTreatmentPeriod;
  setTreatmentId: (id: string) => void;
}): React.JSX.Element => {
  const treatmentId = treatmentPeriod?._id;

  const symptomsAndLocations: Array<ISymptomsAndLocations> = documents
    .filter((d: any) => d._type === 'symptomsAndLocations' && d.treatmentPeriodId === treatmentId)
    .sort((n1, n2) => sortPartialDate(n2.startDate, n1.startDate));

  const symptomsAndLocationsEnded = symptomsAndLocations.filter((s) => s.endDate && isPartialDate(s.endDate));
  const symptomsAndLocationsInUse = symptomsAndLocations.filter((s) => !s.endDate || !isPartialDate(s.endDate));

  return (
    <HistorySection
      headerText={<span style={{ fontSize: '2.2rem' }}>{fm('diagnosis.ninmt.symptomsAndLocations.title')}</span>}
      hasHistoryRows={false}
      newButton={
        startEdit
          ? newButton(
              'symptomsAndLocations',
              (e: React.MouseEvent): void => {
                setTreatmentId(treatmentId);
                startEdit({}, 'symptomsAndLocations')(e);
              },
              'general.new',
              15,
              true,
              treatmentPeriod._editing || !treatmentPeriod?.date,
              'diagnosis.ninmt.treatmentPeriodDisabledTooltip',
            )
          : undefined
      }
    >
      <React.Fragment>
        <Container>
          <Item xs={12} lg={3} md={3} style={{ color: colors.tertiaryText, paddingRight: '1rem' }}>
            {fm('diagnosis.ninmt.symptomsAndLocations.description')}
          </Item>
          <Item xs={12} lg={9} md={9}>
            {symptomsAndLocationsInUse.length > 0 && (
              <div>
                {symptomsAndLocationsInUse.map((s, i) => {
                  return (
                    <TreatmentPeriodEventListItem
                      key={`${s}${i}`}
                      treatmentPeriodEvent={s}
                      startEdit={startEdit}
                      fm={fm}
                    />
                  );
                })}
              </div>
            )}
            {symptomsAndLocationsEnded.length > 0 && (
              <CollapseElem
                localeIDs={{
                  showMessage: 'diagnosis.ninmt.symptomsAndLocations.showPastSymptoms',
                  hideMessage: 'diagnosis.ninmt.symptomsAndLocations.hidePastSymptoms',
                }}
                amount={symptomsAndLocationsEnded.length}
                collapseButtonPosition={'flex-start'}
              >
                {symptomsAndLocationsEnded.map((e, i) => (
                  <TreatmentPeriodEventListItem key={i} treatmentPeriodEvent={e} startEdit={startEdit} fm={fm} />
                ))}
              </CollapseElem>
            )}
          </Item>
        </Container>
      </React.Fragment>
    </HistorySection>
  );
};

export default withHistoryContext(SymptomsAndLocationsHistory);
