import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import HistorySection from '../../../../components/HistorySection';
import { IHistoryContext, withHistoryContext } from '../../../../containers/FormContextHandler';
import { DocumentCreationButtonWithContext } from '../../utils';
import { TreatmentHistorySection } from './components';

const OxygenTherapy = ({ documents = [], startEdit, view }: IHistoryContext<IOxygenTherapy>): React.JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="treatment.oxygenTherapy.title" />}
    newButton={
      <DocumentCreationButtonWithContext name="oxygenTherapy" text="treatment.oxygenTherapy.newOxygenTherapy" />
    }
  >
    {/** The component below takes care of rendering of all the documents */}
    <TreatmentHistorySection documents={documents} startEdit={startEdit} type="oxygenTherapy" view={view} />
  </HistorySection>
);

export default withHistoryContext(OxygenTherapy);
