import * as React from 'react';
import { resultPicker } from '../../../../../utils/PPA';
import { fm } from 'Components/FormatMessage';
import { styled } from '@mui/system';
import colors from '../../../../../../../config/theme/colors';
import { Container, Item } from '../../../../../../../components/Grid';
import { formatPartialDate } from 'neuro-utils';
import { IHistoryContext } from '../../../../../../../containers/FormContextHandler';
import HistoryRowControls from '../../../../../../../components/HistoryRowControls';

const StyledHeader = styled('div')({
  color: colors.primary,
  marginBottom: '0.5rem',
  fontWeight: 400,
});

const StyledWrapper = styled(Container)(({ theme }) => ({
  alignItems: 'center',
  '& > *:not(:last-of-type)': {
    marginBottom: theme.spacing(2),
  },
}));

const PPADiagnosticCriteriaHistory = ({ d, startEdit, view }: IOwnProps): React.JSX.Element => {
  const rows1 = [
    'criteriaForDiagnosisSupportedByImagingStudiesForNfvPPAFulfilled',
    'criteriaForNeuropathologicallyConfirmedDiagnosisForNfvPPAFulfilled',
  ];
  const rows2 = [
    'criteriaForDiagnosisSupportedByImagingStudiesForSemanticDementiaFulfilled',
    'criteriaForNeuropathologicallyConfirmedDiagnosisForSemanticDementiaFulfilled',
  ];
  const rows3 = [
    'criteriaForDiagnosisSupportedByImagingStudiesForLogopenicProgressiveAphasiaFulfilled',
    'criteriaForNeuropathologicallyConfirmedDiagnosisForLogopenicProgressiveAphasiaFulfilled',
  ];
  return (
    <React.Fragment>
      <Container alignItems="center">
        <Item xs={2}>{d.date && formatPartialDate(d.date)}</Item>
        <Item xs={7}>
          <StyledHeader style={{ marginBottom: '0.2rem' }}>
            {fm(`diagnosis.ppa.nonfluentVariantPPA.title`)}
          </StyledHeader>
        </Item>
        <Item xs={3}>{startEdit ? <HistoryRowControls document={d} startEdit={startEdit} view={view} /> : null}</Item>
      </Container>
      {rows1.map((row) => (
        <StyledWrapper key={row}>
          <Item xs={2} />
          <Item xs={4}>
            <span style={{ color: colors.tertiaryText, fontWeight: 400 }}>
              {fm(`diagnosis.ppa.logopenicProgressiveAphasia.summary.${row}`)}
            </span>
          </Item>
          <Item xs={3}>
            {fm(`diagnosis.opts.yesNoUnknown.${resultPicker(d, 'logopenicProgressiveAphasia', 'summary', row)}`)}
          </Item>
          <Item xs={3} />
        </StyledWrapper>
      ))}
      <Container>
        <Item xs={2} />
        <Item xs={true}>
          <StyledHeader>{fm(`diagnosis.ppa.semanticDementia.title`)}</StyledHeader>
        </Item>
      </Container>
      {rows2.map((row) => (
        <StyledWrapper key={row}>
          <Item xs={2} />
          <Item xs={4}>
            <span style={{ color: colors.tertiaryText, fontWeight: 400 }}>
              {fm(`diagnosis.ppa.logopenicProgressiveAphasia.summary.${row}`)}
            </span>
          </Item>
          <Item xs={3}>
            {fm(`diagnosis.opts.yesNoUnknown.${resultPicker(d, 'logopenicProgressiveAphasia', 'summary', row)}`)}
          </Item>
          <Item xs={3} />
        </StyledWrapper>
      ))}
      <Container>
        <Item xs={2} />
        <Item xs={true}>
          <StyledHeader>{fm(`diagnosis.ppa.logopenicProgressiveAphasia.title`)}</StyledHeader>
        </Item>
      </Container>
      {rows3.map((row) => (
        <StyledWrapper key={row}>
          <Item xs={2} />
          <Item xs={4}>
            <span style={{ color: colors.tertiaryText, fontWeight: 400 }}>
              {fm(`diagnosis.ppa.logopenicProgressiveAphasia.summary.${row}`)}
            </span>
          </Item>
          <Item xs={3}>
            {fm(`diagnosis.opts.yesNoUnknown.${resultPicker(d, 'logopenicProgressiveAphasia', 'summary', row)}`)}
          </Item>
          <Item xs={3} />
        </StyledWrapper>
      ))}
      {![...rows1, ...rows2, ...rows3].some(
        (row) => resultPicker(d, 'logopenicProgressiveAphasia', 'summary', row) === 'yes',
      ) && (
        <div style={{ marginTop: '4.5rem' }}>
          <Container>
            <Item xs={2} />
            <Item xs={4}>
              <span style={{ color: colors.tertiaryText, fontWeight: 400 }}>
                {fm('diagnosis.ppa.logopenicProgressiveAphasia.summary.generalCriteriaForPPAFulfilled')}
              </span>
            </Item>
            <Item xs={3}>
              {fm(
                `diagnosis.opts.yesNoUnknown.${
                  [
                    'theFollowingThreeCharacteristicsShouldBePresentInTheSyndrome',
                    'theFollowingFourCharacteristicsShouldNotBePresentInTheSyndrome',
                  ].every((subSection) => resultPicker(d, 'general', subSection) === 'yes')
                    ? 'yes'
                    : [
                          'theFollowingThreeCharacteristicsShouldBePresentInTheSyndrome',
                          'theFollowingFourCharacteristicsShouldNotBePresentInTheSyndrome',
                        ].some((subSection) => resultPicker(d, 'general', subSection) === 'no')
                      ? 'no'
                      : 'unknown'
                }`,
              )}
            </Item>
            <Item xs={3} />
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  d: IPPA;
  startEdit: IHistoryContext['startEdit'];
  view: IHistoryContext['view'];
}

export default PPADiagnosticCriteriaHistory;
