import { formatPartialDate, isPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, Item } from '../../../../../components/Grid';
import HistoryRowBasicItem from '../../../../../components/HistoryRowBasicItem';

const PeriodWithoutMedicationHistoryRowData = ({ d }: IOwnProps): React.JSX.Element => {
  const locPath = 'medication.periodWithoutMedication';

  return (
    <Container>
      <Item xs={2}>
        <HistoryRowBasicItem
          header={<FormattedMessage id={locPath + '.date'} />}
          value={
            !isPartialDate(d.endDate) ? (
              <FormattedMessage
                id="medication.startingDate"
                values={{
                  date: `${formatPartialDate(d.startDate)}`,
                }}
              />
            ) : (
              `${formatPartialDate(d.startDate)}` + ' - ' + (d.endDate ? `${formatPartialDate(d.endDate)}` : '')
            )
          }
        />
      </Item>

      <Item xs={3}>
        <HistoryRowBasicItem
          header={<FormattedMessage id={locPath + '.reason'} />}
          value={d.reason && <FormattedMessage id={locPath + '.opts.' + d.reason} />}
        />
      </Item>
      {d.reason === 'other' && (
        <Item xs={7}>
          <HistoryRowBasicItem header={<FormattedMessage id={locPath + '.reasonOther'} />} value={d.reasonOther} />
        </Item>
      )}
    </Container>
  );
};

interface IOwnProps {
  d: IPeriodWithoutMedication;
}

export default PeriodWithoutMedicationHistoryRowData;
