import { Divider as DividerMui } from '@mui/material';

import colors from '../../config/theme/colors';

const Divider = ({ margin = 3, ...props }: IDivider) => {
  return <DividerMui style={{ margin: `${margin}rem 0`, borderColor: colors.gray }} {...props} />;
};

interface IDivider {
  margin?: number; // rems
}

export default Divider;
