import * as React from 'react';

import ActionButton from 'Components/ActionButton';
import { Container, Item } from 'Components/Grid';
import { TabContentContext } from 'Components/TabContent';
import TreatmentDeleteDialog from './TreatmentDeleteDialog';
import { RTMSContext } from 'Routes/Rtms/Document';

const RemoveButton = ({ editIndex, remove, restore, text = 'general.delete' }: IRemoveButton): React.JSX.Element => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);

  const tabContentContext = React.useContext(TabContentContext);
  const rtmsContext = React.useContext(RTMSContext);

  const { selected } = tabContentContext;
  const { formData } = rtmsContext;

  const sessions = formData.document.sessions ?? [];
  const treatments = sessions?.[editIndex].subjectOfTreatment;

  const thisTreatment: { [key: string]: any } | undefined = treatments?.[selected];

  const openDeleteDialog = () => (): void => {
    setDeleteDialogOpen(true);
  };
  const deleteCancelCallback = (): void => {
    setDeleteDialogOpen(false);
  };
  const deleteConfirmCallback = (deleteReason?: string): void => {
    remove(selected, undefined, deleteReason)();
    setDeleteDialogOpen(false);
  };

  return thisTreatment?.deleted ? (
    <ActionButton
      text={'rtms.subjectOfTreatment.treatmentDeleteRestore'}
      onClick={restore(selected)}
      width={18}
      height={3.5}
      fontSize={16}
    />
  ) : (
    <React.Fragment>
      <ActionButton
        text={text}
        onClick={openDeleteDialog()}
        width={10}
        height={3.5}
        fontSize={16}
        colorScheme="error"
      />
      <TreatmentDeleteDialog
        open={deleteDialogOpen}
        editIndex={editIndex}
        selected={selected}
        confirm={{ callback: deleteConfirmCallback }}
        cancel={{ callback: deleteCancelCallback }}
      />
    </React.Fragment>
  );
};

type TChangeFunction = (i: number) => () => void;
type TRemoveFunction = (i: number, change?: TChangeFunction, deleteReason?: string) => () => void;

interface IRemoveButton {
  editIndex: number;
  remove: TRemoveFunction;
  restore: TRemoveFunction;
  text?: string;
}

const ButtonRow = ({ editIndex, remove, restore, text }: IButtonRow): React.JSX.Element => {
  return (
    <Container style={{ display: 'flex', justifyContent: 'start' }}>
      <Item>
        <RemoveButton editIndex={editIndex} remove={remove} restore={restore} text={text} />
      </Item>
    </Container>
  );
};

interface IButtonRow {
  editIndex: number;
  remove: TRemoveFunction;
  restore: TRemoveFunction;
  text?: string;
}

export default ButtonRow;
