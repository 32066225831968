import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../components/FormSectionHistoryAcceptor';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

import {
  absoluteExclusionCriteria,
  clinicallyEstablishedPD,
  countFieldAnswers,
  probablePD,
  redFlags,
  supportiveCriteria,
} from '../../../utils';
import {
  ReferenceText,
  StyledSubQuestionArea,
  StyledDescriptionField,
  StyledDescriptionItems,
} from '../../../utils/styled';
import { generalOptionFormatter } from '../../../utils/functions';
import { descriptionSectionArrayPD, IDescription } from '../../../utils/definitions';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { Container, Item } from 'Components/Grid';

const yesLocale = <FormattedMessage id="general.yes" />;
const noLocale = <FormattedMessage id="general.no" />;
const unKnownLocale = <FormattedMessage id="general.unknown" />;

const unknownAnswers = (un: number): React.JSX.Element => (
  <React.Fragment>
    <span> (</span>
    <FormattedMessage id="diagnosis.numberOfUnknowns" values={{ un }} />
    <span>)</span>
  </React.Fragment>
);

const subQuestionTitle = (number?: number, question?: string): React.JSX.Element => (
  <Container>
    <Item xs={1} style={{ textAlign: 'center' }}>
      {number && `${number}.`}
    </Item>
    <Item xs={11}>{question && <FormattedMessage id={question} />}</Item>
  </Container>
);

const subQuestionAnswerPair = (question: React.JSX.Element, answer: React.JSX.Element): React.JSX.Element => (
  <Container style={{ marginTop: '1.5rem' }}>
    <Item xs={9}>{question}</Item>
    <Item xs={3} style={{ paddingLeft: '2rem' }}>
      {answer}
    </Item>
  </Container>
);

const formFields = [
  'beneficialResponceToDopaminergicTherapy',
  'presenceOfLevodopaDyskinesia',
  'restTremorOfALimb',
  'presenceOfOlfactoryLossOrCardiacSympatheticDenervation',
  'unequivocalCerebellarAbnormalities',
  'downwardVerticalSupranuclearGazePalsy',
  'diagnosisOfProbableBehavioralVariantFrontotemporalDementia',
  'parkinsonianFeaturesRestrictedToTheLowerLimbs',
  'treatmentWithDopamineReceptorBlocker',
  'absenceOfObservableResponseToHighdoseLevodopa',
  'unequivocalCorticalSensoryLoss',
  'normalFunctionalNeuroimagingOfThePresynapticDopaminergicSystem',
  'documentationOfAlternativeConditionKnownToProduceParkinsonism',
  'rapidProgressionOfGaitImpairment',
  'completeAbsenceOfProgressionOfMotorSymptoms',
  'earlyBulbarDysfunctionSevereDysphoniaOrDysarthria',
  'inspiratoryRespiratoryDysfunction',
  'severeAutonomicFailureInFirst5yOfDisease',
  'recurrentFalls',
  'disproportionateAnterocollisOrContracturesOfHandOrFeet',
  'absenceOfCommonNonmotorFeaturesOfDisease',
  'otherwiseUnexplainedPyramidalTractSigns',
  'bilateralSymmetricParkinsonism',
  'parkinsonism',
];

const setAllUnknown = (formData: IFormContext['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData?.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  bottomMargin = true,
}: {
  title: string;
  description?: React.JSX.Element | string;
  name: string;
  formData: IFormContext['formData'];
  viewing: boolean;
  bottomMargin?: boolean;
}): React.JSX.Element => (
  <FormRow title={title} description={description} headerWidth={6} bottomMargin={bottomMargin}>
    <div style={{ marginLeft: '5rem' }}>
      <InputHandler
        editing={!viewing}
        formData={formData}
        name={name}
        type="RadioButtonRow"
        preset="yesNoUnknown"
        height={3.5}
        width={9}
        optionFormatter={generalOptionFormatter}
      />
    </div>
  </FormRow>
);

const PDForm = ({
  documents,
  formData,
  view,
  editing,
  fm = (): string => '',
}: IFormContext<IPD>): React.JSX.Element => {
  const isViewing = !editing && !!view?.viewing;
  const docID = editing ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      {formData ? (
        <React.Fragment>
          <FormRow title="general.date">
            <InputHandler
              type="PartialDate"
              editing={!isViewing}
              name="date"
              formData={formData}
              dateDefault="now"
              isNotCancellable={true}
            />
          </FormRow>
          <Container>
            <Item style={{ padding: '2rem 0' }}>
              <StyledDescriptionField
                sections={descriptionSectionArrayPD}
                sectionPath="diagnosis.pd.description"
                path="diagnosis.pd"
                title="diagnosis.pd.description.description"
                sectionTitle="title"
              />
            </Item>
          </Container>
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={generalOptionFormatter}
            header="diagnosis.pd.supportiveCriteria.title"
            hideCopyButton={isViewing}
          >
            {{
              beneficialResponceToDopaminergicTherapy: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.supportiveCriteria.opts.1"
                    description={
                      <div>
                        {fm('diagnosis.pd.supportiveCriteria.info.1')}
                        <StyledDescriptionItems
                          description={
                            {
                              fields: ['1a', '1b'],
                              section: 'supportiveCriteria.info',
                              marginLeft: 2,
                            } as IDescription
                          }
                          key="beneficialResponceToDopaminergicTherapy"
                          path="diagnosis.pd"
                        />
                      </div>
                    }
                    name="beneficialResponceToDopaminergicTherapy"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              presenceOfLevodopaDyskinesia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.supportiveCriteria.opts.2"
                    name="presenceOfLevodopaDyskinesia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              restTremorOfALimb: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.supportiveCriteria.opts.3"
                    name="restTremorOfALimb"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              presenceOfOlfactoryLossOrCardiacSympatheticDenervation: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.supportiveCriteria.opts.4"
                    name="presenceOfOlfactoryLossOrCardiacSympatheticDenervation"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={generalOptionFormatter}
            header="diagnosis.pd.absoluteExclusionCriteria.title"
            hideCopyButton={isViewing}
          >
            {{
              info: {
                header: <div style={{ marginBottom: '2rem' }}>{fm('diagnosis.pd.absoluteExclusionCriteria.info')}</div>,
              },
              unequivocalCerebellarAbnormalities: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.absoluteExclusionCriteria.opts.1"
                    name="unequivocalCerebellarAbnormalities"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              downwardVerticalSupranuclearGazePalsy: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.absoluteExclusionCriteria.opts.2"
                    name="downwardVerticalSupranuclearGazePalsy"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              diagnosisOfProbableBehavioralVariantFrontotemporalDementia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.absoluteExclusionCriteria.opts.3"
                    name="diagnosisOfProbableBehavioralVariantFrontotemporalDementia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              parkinsonianFeaturesRestrictedToTheLowerLimbs: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.absoluteExclusionCriteria.opts.4"
                    name="parkinsonianFeaturesRestrictedToTheLowerLimbs"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              treatmentWithDopamineReceptorBlocker: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.absoluteExclusionCriteria.opts.5"
                    name="treatmentWithDopamineReceptorBlocker"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              absenceOfObservableResponseToHighdoseLevodopa: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.absoluteExclusionCriteria.opts.6"
                    name="absenceOfObservableResponseToHighdoseLevodopa"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              unequivocalCorticalSensoryLoss: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.absoluteExclusionCriteria.opts.7"
                    name="unequivocalCorticalSensoryLoss"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              normalFunctionalNeuroimagingOfThePresynapticDopaminergicSystem: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.absoluteExclusionCriteria.opts.8"
                    name="normalFunctionalNeuroimagingOfThePresynapticDopaminergicSystem"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              documentationOfAlternativeConditionKnownToProduceParkinsonism: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.absoluteExclusionCriteria.opts.9"
                    name="documentationOfAlternativeConditionKnownToProduceParkinsonism"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={generalOptionFormatter}
            header="diagnosis.pd.redFlags.title"
            hideCopyButton={isViewing}
          >
            {{
              rapidProgressionOfGaitImpairment: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.1"
                    name="rapidProgressionOfGaitImpairment"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              completeAbsenceOfProgressionOfMotorSymptoms: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.2"
                    name="completeAbsenceOfProgressionOfMotorSymptoms"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              earlyBulbarDysfunctionSevereDysphoniaOrDysarthria: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.3"
                    name="earlyBulbarDysfunctionSevereDysphoniaOrDysarthria"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              inspiratoryRespiratoryDysfunction: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.4"
                    name="inspiratoryRespiratoryDysfunction"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              severeAutonomicFailureInFirst5yOfDisease: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.5"
                    name="severeAutonomicFailureInFirst5yOfDisease"
                    description={
                      <div key={2}>
                        {fm('diagnosis.pd.redFlags.info.5')}
                        <StyledDescriptionItems
                          description={
                            {
                              fields: ['5a', '5b'],
                              section: 'redFlags.info',
                              marginLeft: 2,
                            } as IDescription
                          }
                          key="severeAutonomicFailureInFirst5yOfDisease"
                          path="diagnosis.pd"
                        />
                      </div>
                    }
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              recurrentFalls: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.6"
                    name="recurrentFalls"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              disproportionateAnterocollisOrContracturesOfHandOrFeet: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.7"
                    name="disproportionateAnterocollisOrContracturesOfHandOrFeet"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              absenceOfCommonNonmotorFeaturesOfDisease: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.8"
                    name="absenceOfCommonNonmotorFeaturesOfDisease"
                    description={fm('diagnosis.pd.redFlags.info.8')}
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              otherwiseUnexplainedPyramidalTractSigns: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.9"
                    name="otherwiseUnexplainedPyramidalTractSigns"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              bilateralSymmetricParkinsonism: {
                element: (
                  <MakeFormRow
                    title="diagnosis.pd.redFlags.opts.10"
                    name="bilateralSymmetricParkinsonism"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>
          <StyledSubQuestionArea>
            <FormSection header={'diagnosis.criteriaApplication'}>
              {subQuestionAnswerPair(
                subQuestionTitle(1, 'diagnosis.parkinsonism'),
                <InputHandler
                  type="Radio"
                  editing={!isViewing}
                  name="parkinsonism"
                  formData={formData}
                  preset="yesNoUnknown"
                  optionFormatter={generalOptionFormatter}
                />,
              )}
              {formData.document.parkinsonism === 'yes' && (
                <React.Fragment>
                  {subQuestionAnswerPair(
                    subQuestionTitle(2, 'diagnosis.anyAbsoluteExclusionCriteria'),
                    countFieldAnswers(absoluteExclusionCriteria, formData.document).yes > 0
                      ? yesLocale
                      : countFieldAnswers(absoluteExclusionCriteria, formData.document).yes +
                            countFieldAnswers(absoluteExclusionCriteria, formData.document).unknown ===
                          0
                        ? noLocale
                        : unKnownLocale,
                  )}
                  {subQuestionAnswerPair(
                    subQuestionTitle(undefined, 'diagnosis.numberOfAbsoluteExclusionCriteria'),
                    <React.Fragment>
                      {countFieldAnswers(absoluteExclusionCriteria, formData.document).yes}
                      {unknownAnswers(countFieldAnswers(absoluteExclusionCriteria, formData.document).unknown)}
                    </React.Fragment>,
                  )}
                  {countFieldAnswers(absoluteExclusionCriteria, formData.document).yes === 0 && (
                    <React.Fragment>
                      {subQuestionAnswerPair(
                        subQuestionTitle(3, 'diagnosis.numberOfRedFlags'),
                        <React.Fragment>
                          {countFieldAnswers(redFlags, formData.document).yes}
                          {unknownAnswers(countFieldAnswers(redFlags, formData.document).unknown)}
                        </React.Fragment>,
                      )}
                      {subQuestionAnswerPair(
                        subQuestionTitle(4, 'diagnosis.numberOfSupportiveCriterias'),
                        <React.Fragment>
                          {countFieldAnswers(supportiveCriteria, formData.document).yes}
                          {unknownAnswers(countFieldAnswers(supportiveCriteria, formData.document).unknown)}
                        </React.Fragment>,
                      )}
                      {countFieldAnswers(absoluteExclusionCriteria, formData.document).unknown === 0 && (
                        <React.Fragment>
                          {subQuestionAnswerPair(
                            subQuestionTitle(5, 'diagnosis.supportiveCriteriAndNoRedFlags'),
                            countFieldAnswers(supportiveCriteria, formData.document).yes > 1 &&
                              countFieldAnswers(redFlags, formData.document).yes +
                                countFieldAnswers(redFlags, formData.document).unknown ===
                                0
                              ? yesLocale
                              : countFieldAnswers(supportiveCriteria, formData.document).yes +
                                    countFieldAnswers(supportiveCriteria, formData.document).unknown <
                                    2 || countFieldAnswers(redFlags, formData.document).yes > 0
                                ? noLocale
                                : unKnownLocale,
                          )}
                          {countFieldAnswers(supportiveCriteria, formData.document).yes +
                            countFieldAnswers(supportiveCriteria, formData.document).unknown <
                            2 || countFieldAnswers(redFlags, formData.document).yes > 0 ? (
                            <React.Fragment>
                              {subQuestionAnswerPair(
                                subQuestionTitle(6, 'diagnosis.moreRedFlags'),
                                countFieldAnswers(redFlags, formData.document).yes > 2
                                  ? yesLocale
                                  : countFieldAnswers(redFlags, formData.document).yes +
                                        countFieldAnswers(redFlags, formData.document).unknown <=
                                      2
                                    ? noLocale
                                    : unKnownLocale,
                              )}
                              {countFieldAnswers(redFlags, formData.document).yes +
                                countFieldAnswers(redFlags, formData.document).unknown >
                              2
                                ? undefined
                                : subQuestionAnswerPair(
                                    subQuestionTitle(7, 'diagnosis.moreSupportive'),
                                    countFieldAnswers(redFlags, formData.document).yes +
                                      countFieldAnswers(redFlags, formData.document).unknown <=
                                      countFieldAnswers(supportiveCriteria, formData.document).yes
                                      ? yesLocale
                                      : countFieldAnswers(supportiveCriteria, formData.document).yes +
                                            countFieldAnswers(supportiveCriteria, formData.document).unknown <
                                          countFieldAnswers(redFlags, formData.document).yes
                                        ? noLocale
                                        : unKnownLocale,
                                  )}
                            </React.Fragment>
                          ) : undefined}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              <Container style={{ marginTop: '2rem' }}>
                <Container>
                  <Item xs={3} style={{ marginLeft: '3.4rem' }}>
                    <Container>
                      <Item>{fm('diagnosis.pd.clinicallyEstablished')}</Item>
                    </Container>
                    <Container>
                      <Item style={{ fontWeight: 600 }}>
                        {fm(`general.${clinicallyEstablishedPD(formData.document)}`)}
                      </Item>
                    </Container>
                  </Item>
                  <Item xs={4}>
                    <Container>
                      <Item>{fm('diagnosis.pd.probable')}</Item>
                    </Container>
                    <Container>
                      <Item style={{ fontWeight: 600 }}>{fm(`general.${probablePD(formData.document)}`)}</Item>
                    </Container>
                  </Item>
                </Container>
              </Container>
            </FormSection>
          </StyledSubQuestionArea>
          <Container>
            <Item style={{ padding: '2rem 0' }}>
              <ReferenceText>
                Postuma RB, Berg D, Stern M, et al. MDS clinical diagnostic criteria for Parkinson&apos;s disease. Mov
                Disord. 2015 Oct;30(12):1591-601.
              </ReferenceText>
            </Item>
          </Container>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default withFormContext(PDForm);
