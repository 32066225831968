import DocumentCreationButton from 'Components/DocumentCreationButton';
import DocumentHeader from 'Components/DocumentHeader';
import DocumentWrapper from 'Components/DocumentWrapper';
import FormEditingHandler from 'Containers/FormEditingHandler';
import ThalamotomyHifuHistoryRowData from './HistoryRowData';
import * as React from 'react';
import ThalamotomyForm from './Form/Thalamotomy';
import HifuForm from './Form/Hifu';
import { FormContextProvider, IHistoryContext } from 'Containers/FormContextHandler';
import { fm } from 'Components/FormatMessage';
import HistoryRowControls from 'Components/HistoryRowControls';
import HistoryRow from 'Components/HistoryRow';
import HistorySection from 'Components/HistorySection';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';

interface IForm {
  editing: string;
  formData: IFormData<IThalamotomy | IHifuTreatment>;
  document?: IThalamotomy | IHifuTreatment;
}

const Form = ({ editing, formData, document }: IForm): React.JSX.Element => {
  const docType = document?._type;
  return (
    <FormContextProvider context={{ formData, editing }}>
      <>
        {docType === 'thalamotomy' && <ThalamotomyForm />}
        {docType === 'hifu' && <HifuForm />}
      </>
    </FormContextProvider>
  );
};

const docType = (id: string, documents?: Array<IThalamotomy | IHifuTreatment>): string | undefined | false => {
  return Array.isArray(documents) && documents.find((d) => d._id === id)?._type;
};

const ThalamotomyHifu = ({ documents, view }: IOwnProps): React.JSX.Element => {
  return (
    <FormEditingHandler name="thalamotomyHifu" documents={documents}>
      {(editing, startEdit, formData): React.JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'thalamotomyHifu'}
            headerId={
              docType(editing, documents) === 'thalamotomy'
                ? 'thalamotomyHifu.thalamotomy.title'
                : docType(editing, documents) === 'hifu'
                  ? 'thalamotomyHifu.hifu.title'
                  : 'thalamotomyHifu.title'
            }
            editing={editing}
            editButtons={
              <>
                <div style={{ marginBottom: '2rem' }}>
                  <DocumentCreationButton
                    name="thalamotomy"
                    text={'thalamotomyHifu.thalamotomy.new'}
                    onClick={startEdit({}, 'thalamotomy')}
                  />
                </div>
                <DocumentCreationButton name="hifu" text={'thalamotomyHifu.hifu.new'} onClick={startEdit({}, 'hifu')} />
              </>
            }
          />

          {editing ? (
            <Form
              editing={editing}
              formData={formData}
              document={documents.find((d) => d._id === editing, documents)}
            />
          ) : undefined}
          {!editing
            ? documents && (
                <HistorySection headerText={fm('treatment.title')} hasHistoryRows>
                  {documents
                    .sort((n1, n2) => sortPartialDate(n2.date, n1.date))
                    .map((d: IThalamotomy | IHifuTreatment) => (
                      <HistoryRow
                        headerText={
                          <>
                            {formatPartialDate(d.date)} - {fm(`thalamotomyHifu.${d._type}.title`)}
                          </>
                        }
                        key={d._id}
                        rowButton={<HistoryRowControls document={d} startEdit={startEdit} view={view} />}
                        controlsMargin={false}
                      >
                        <ThalamotomyHifuHistoryRowData document={d} />
                      </HistoryRow>
                    ))}
                </HistorySection>
              )
            : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<IThalamotomy | IHifuTreatment>;
  startEdit: IHistoryContext['startEdit'];
  view: IHistoryContext['view'];
}

export default ThalamotomyHifu;
