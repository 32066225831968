import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistorySection from '../../../../components/HistorySection';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import { Item } from 'Components/Grid';
import { calculateFBIMODScore } from 'Routes/DiagnosticSurvey/utils';
import InfoPopover from 'Components/InfoPopover';

const FBIMODHistory = ({ documents, startEdit, view }: IOwnProps): React.JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'diagnosticSurvey.fbimod.title'} />}
      newButton={
        <DocumentCreationButton
          name="fbimod"
          text={'diagnosticSurvey.fbimod.newFBIMOD'}
          onClick={startEdit({}, 'fbimod')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <Item xs={true}>
            <FormattedMessage id={'diagnosticSurvey.fbimod.score'} />
          </Item>
        }
        contentFormat={(d): React.JSX.Element => (
          <>
            <Item xs={true}>
              {calculateFBIMODScore(d) ? (
                <FormattedMessage
                  id={'diagnosticSurvey.fbimod.scoreTotal'}
                  values={{ value: calculateFBIMODScore(d) }}
                />
              ) : (
                <InfoPopover text="diagnosticSurvey.fbimod.notFilled" color="primary" />
              )}
            </Item>
          </>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IFBIMOD[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default FBIMODHistory;
